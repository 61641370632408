<template>
    <traffic-graph
        :data="data"
        :loading="loading"
        :error="error"
        @redo="redo"
    >
        <template #filters>
            <v-flex shrink>
                <select-all
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="campaign"
                    :items="campaignOptions"
                    :disabled="loading"
                    :label="$t('trafficGraph.shop')"
                    :all-label="$t('trafficGraph.allShops')"
                    require-selection
                    @blur="handleCampaignBlur"
                />
            </v-flex>
        </template>
    </traffic-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TrafficGraph from '../../graphs/TrafficGraph.vue';
import campaignFilterMixin from '../../../mixins/campaignFilterMixin';
import SelectAll from '../../common/SelectAll.vue';
import eventBus from '../../../services/eventBus';

export default {
    name: 'product-traffic',
    components: {
        SelectAll,
        TrafficGraph,
    },
    mixins: [campaignFilterMixin],
    props: {
        productId: {
            type: String,
            required: true,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            data: null,
            error: null,
        };
    },
    computed: {
        ...mapState({
            loading: state => state.products.loading.trafficChart,
        }),
        ...mapGetters({
            getterProduct: 'products/getProduct',
            getterProductTrafficChart: 'products/getProductTrafficChart',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                if (this.product) {
                    this.getData();
                }
            }
        },
    },
    created() {
        this.setDefaultCampaign();
        this.product = this.getterProduct(this.productId);

        if (this.product) {
            this.getData();
        }
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCampaign();
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.isDefaultCampaign();
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getTrafficChart(force);
        },
        redo() {
            this.getData(true);
        },
        handleCampaignBlur() {
            this.getData();
        },
        async getTrafficChart(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                await this.fetchTrafficChart({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    type: this.product.type,
                    startDate,
                    endDate,
                    campaign: this.campaign,
                });
                this.data = this.getterProductTrafficChart(this.productId, startDate, endDate, this.campaign);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            fetchTrafficChart: 'products/fetchTrafficChart',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
