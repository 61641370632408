import store from '../../store/store';

const allowedAnonymousRoutes = ['login', 'loginAs', 'loginRedirect', 'resetPassword', 'resetPasswordComplete', 'confirmEmail', 'register'];
const notAllowedAuthenticatedRoutes = ['login', 'loginAs', 'loginRedirect', 'resetPassword', 'resetPasswordComplete'];

/**
 * Get user roles from store
 * @returns {(null|Array)}
 */
const getUserRoles = async () => {
    if (!store.getters['auth/isSessionInitialized']) {
        await store.dispatch('auth/validateSession');
    }

    if (store.state.auth.user && store.state.auth.user.roles && store.state.auth.user.roles.length > 0) {
        return store.state.auth.user.roles;
    }

    return null;
};

/**
 * Check if user has access to current role
 * @param {Array} routeRoles - list of accepted roles
 * @param {Array} userRoles - list of user roles
 * @returns {boolean}
 */
const hasValidRole = (routeRoles, userRoles) => {
    if (!userRoles) {
        return false;
    }
    return routeRoles.some(routeRole => userRoles.includes(routeRole));
};

const hasAccessGuard = async function hasAccessGuard(to, from, next) {
    if (to.name === 'loginAs') {
        store.dispatch('auth/logout', { noRedirect: true });
    }

    const userRoles = await getUserRoles();

    if (!store.getters['auth/isAdmin'] && store.getters['auth/isAuthenticated'] && !store.getters['auth/hasTermsAccepted'] && to.name !== 'acceptTerms') {
        // if user is authenticated and has not accepted terms of service
        next({ name: 'acceptTerms', query: { redirect: to.name } });
    } else if (to.meta.isSecure && to.meta.roles && to.meta.roles.length > 0) {
        // check if route is secure and if user is authenticated with proper roles
        if (hasValidRole(to.meta.roles, userRoles)) {
            // if user have valid role continue
            next();
        } else {
            // if user doesn't have valid role redirect to login page
            next({ name: 'login', query: { redirect: to.name } });
        }
    } else if (!store.getters['auth/isAuthenticated'] && !allowedAnonymousRoutes.includes(to.name)) {
        // if user is not authenticated and route is not in allowed anonymous routes then redirect to login
        next({ name: 'login', query: { redirect: to.name } });
    } else if (store.getters['auth/isAuthenticated'] && notAllowedAuthenticatedRoutes.includes(to.name)) {
        // if user is authenticated and route is in not allowed authenticated routes then redirect to home page
        next('/');
    } else if (to.path === '/' && userRoles.includes('ROLE_PRODUCER')) {
        // if user has producer role and is accessing home page then redirect to producer main page
        next({ name: 'producer' });
    } else {
        // public route, continue
        next();
    }
};

export default hasAccessGuard;
