/*
    Preconditions:
    - tooltip.isHtml: true
    - call in both READY and SELECT events - needed for dynamic action text
*/

export default {
    data() {
        return {
            selections: [],
        };
    },

    methods: {
        addChartActionSelectDate() {
            if (!this.chart) {
                throw new Error('chart must be set first');
            }
            if (!this.data) {
                throw new Error('data must be set first');
            }

            this.selections = this.chart.getSelection();

            const date = this.lastSelectedDate;
            const actionText = this.$t('selectAllFrom', { date });

            this.chart.setAction({
                id: 'date',
                text: actionText,
                action: this.selectAllFromLastSelectedDate,
            });

            // We manually change the text since GCharts won't rerender it immediately
            this.renderActionText('date', actionText);
        },

        selectAllFromLastSelectedDate() {
            const newSelection = this.lastSelectedColumnVisualizationSelectionArray;
            this.chart.setSelection(newSelection);
            this.selections = newSelection;
        },

        renderActionText(action, text) {
            const node = this.$el.querySelector(`[data-logicalname="action#${action}"] span`);
            if (node) {
                node.innerHTML = text;
            }
        },
    },

    computed: {
        lastSelection() {
            const lastSelectionIndex = this.selections.length - 1;
            return this.selections[lastSelectionIndex];
        },

        lastSelectedColumnIndex() {
            if (!this.lastSelection) {
                return undefined;
            }

            // Data that visually look like columns are actually rows in charts' data structure,
            // hence the divergence.
            return this.lastSelection.row;
        },

        lastSelectedColumnData() {
            if (this.lastSelectedColumnIndex === undefined) {
                return undefined;
            }

            return this.data[this.lastSelectedColumnIndex + 1];
        },

        lastSelectedColumnVisualizationSelectionArray() {
            if (!this.lastSelectedColumnData) {
                return [];
            }

            return this.lastSelectedColumnData.map((dataRow, index) => ({
                row: this.lastSelectedColumnIndex,
                column: index,
            }));
        },

        lastSelectedDate() {
            if (!this.lastSelectedColumnData) {
                return undefined;
            }

            return this.lastSelectedColumnData[0];
        },
    },
};
