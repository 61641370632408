/*
    Preconditions:
    - tooltip.isHtml: true
    - call in READY event
*/

export default {
    data() {
        return {
            tooltipLeft: false,
        };
    },
    beforeDestroy() {
        if (this.google) {
            this.google.visualization.events.removeAllListeners(this.chart);
            this.chart.container.removeEventListener('mouseenter', this.handleMouseEnter, true);
        }
    },
    methods: {
        addChartTooltipPosition() {
            if (!this.chart) {
                throw new Error('chart must be set first');
            }
            this.google.visualization.events.addListener(this.chart, 'select', this.handleSelect);
            this.chart.container.addEventListener('mouseenter', this.handleMouseEnter, true);
        },
        handleSelect() {
            this.setTooltipPosition();
        },
        handleMouseEnter(e) {
            if (
                e.target.classList.contains('google-visualization-tooltip-item-list')
                || (e.target.classList.contains('google-visualization-tooltip') && e.target.getElementsByClassName('google-visualization-tooltip-item-list').length === 0)
            ) {
                this.tooltipLeft = !this.tooltipLeft;
                this.setTooltipPosition();
            }
        },
        getTooltip() {
            return this.chart.container.getElementsByClassName('google-visualization-tooltip')[0];
        },
        setTooltipPosition() {
            if (this.tooltipLeft) {
                this.chart.container.classList.remove('google-chart--tooltip-right');
                this.chart.container.classList.add('google-chart--tooltip-left');
            } else {
                this.chart.container.classList.remove('google-chart--tooltip-left');
                this.chart.container.classList.add('google-chart--tooltip-right');
            }
        },
    },
};
