<template>
    <v-card class="elevation-0 grey lighten-4">
        <v-card-title>
            <div>
                <h4 class="subheading font-weight-bold">{{ $t('categoryList') }}</h4>
            </div>
            <v-spacer />
        </v-card-title>
        <v-card-text>
            <v-alert
                v-model="showError"
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>

            <v-data-table
                v-if="items.length"
                class="elevation-0 table--transparent"
                :headers="headers"
                :items="items"
                :no-data-text="$t('noResults')"
                :no-results-text="$t('noResults')"
                :rows-per-page-text="$t('table.rowsPerPage')"
                :rows-per-page-items="rowsPerPageItems"
                :pagination.sync="pagination"
                disable-initial-sort
                @update:pagination="updatePagination"
                sort-icon="fa-angle-up"
                :loading="loading"
            >
                <template v-slot:pageText="props">
                    {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                </template>
                <template v-slot:no-data>
                    <div class="text-xs-center">
                        <span v-if="loading"><v-progress-circular class="ma-2" indeterminate color="primary" /></span>
                        <span v-else>{{ $t('noResults') }}</span>
                    </div>
                </template>
                <template v-slot:items="{ item }">
                    <td style="width:100px;">
                        <VueSkeleton v-if="loading" type="rect" rounded :height="16" :width="50"></VueSkeleton>
                        <span v-else>{{ item.id }}</span>
                    </td>
                    <td>
                        <VueSkeleton v-if="loading" type="rect" rounded :height="16"></VueSkeleton>
                        <v-treeview
                            v-show="!loading"
                            class="products-tree"
                            ref="tree"
                            :items="treeItem(item)"
                            return-object
                            :load-children="fetchItems"
                            active-class="tree-node--active"
                            item-key="id"
                            item-text="text"
                            item-children="children"
                            loading-icon="fa-circle-notch"
                            transition
                            open-on-click
                        >
                            <template v-slot:prepend="{ item, open, active }">
                                <v-icon v-if="item.type === 'category'" class="ml-2 mr-1" :color="active ? 'secondary' : ''" small>{{ open ? 'fa-folder-open' : 'fa-folder' }}</v-icon>
                                <v-icon v-else class="ml-2 mr-1" :color="active ? 'secondary' : ''" small>fa-file</v-icon>
                            </template>
                        </v-treeview>
                    </td>
                    <td style="width:50px;">
                        <VueSkeleton v-if="loading" type="circle" :width="16" :height="16"></VueSkeleton>
                        <template v-else>
                            <div v-if="isCategoryDeleting(`${groupId}-${item.id}`)" class="ma-auto text-xs-center">
                                <v-progress-circular :size="24" indeterminate color="primary" />
                            </div>
                            <v-btn v-else flat icon @click="deleteItem(item.id)" :disabled="isDemo">
                                <v-icon small>fa-trash</v-icon>
                            </v-btn>
                        </template>
                    </td>
                </template>
            </v-data-table>

            <div v-else class="layout ma-4 column text-xs-center">
                <div v-if="loading" class="ma-auto">
                    <v-progress-circular class="ma-4" indeterminate color="primary" />
                </div>
                <h6 v-else class="title">{{ $t('noCategoryAdded') }}</h6>
            </div>

            <v-dialog v-model="showTree" width="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :style="items.length ? 'margin-top:-74px;' : ''"
                        class="mr-auto"
                        color="tertiary"
                        dark
                        round
                        :disabled="loading"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="mr-2" small>fa-plus</v-icon>
                        {{ $t('add') }}
                    </v-btn>
                </template>
                <v-card>
                    <v-toolbar dark>
                        <v-toolbar-title>{{ $t('add') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon color="tertiary" @click="showTree = false">
                            <v-icon>fa-times</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <ProductsTreeSelect :group="group" @productAdded="data => $emit('productAdded', data)" @categoryAdded="data => $emit('categoryAdded', data)" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VueSkeleton from 'skeleton-loader-vue';
import productsService from '@/services/productsService';
import ProductsTreeSelect from '@/components/producer/product-groups/ProductsTreeSelect.vue';

export default {
    name: 'Categories',
    components: {
        VueSkeleton,
        ProductsTreeSelect,
    },
    props: {
        groupId: {
            required: true,
            type: Number,
        },
        group: {
            required: false,
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showError: false,
            error: null,
            headers: [
                {
                    text: this.$t('id'),
                    align: 'left',
                    sortable: true,
                    value: 'id',
                },
                {
                    text: this.$t('name'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
            ],
            paginationPrev: null,
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
            },
            tree: [],
            showTree: false,
        };
    },
    computed: {
        isDemo() {
            return this.$store.getters['space/isDemo'];
        },
        items() {
            return this.group?.categories || [];
        },
        loading() {
            return this.isGroupFetching(this.groupId);
        },
        treeItem() {
            return item => this.tree.filter(treeItem => treeItem.id === item.id);
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            isGroupFetching: 'producerProductGroup/isGroupFetching',
            isCategoryDeleting: 'producerProductGroup/isCategoryDeleting',
        }),
    },
    watch: {
        items: {
            immediate: true,
            handler() {
                this.items.forEach(item => {
                    const currentItem = this.tree.find(treeItem => treeItem.id === item.id);
                    if (!currentItem) {
                        this.tree.push({
                            id: item.id, text: item.name, type: 'category', children: [],
                        });
                    }
                });
            },
        },
        error() {
            if (this.error) this.showError = true;
        },
        showError() {
            if (!this.showError) this.error = null;
        },
    },
    methods: {
        async fetchItems(item) {
            const data = await productsService.fetchProducts(this.spaceId, item.id);
            item.children = data.map(node => ({ ...node, id: this.getRealId(node.id) })); // eslint-disable-line no-param-reassign
        },
        updatePagination(event) {
            this.paginationPrev = {
                descending: event.descending,
                page: event.page,
                rowsPerPage: event.rowsPerPage,
                sortBy: event.sortBy,
            };
        },
        async deleteItem(itemId) {
            try {
                const data = { groupId: this.groupId, categoryId: itemId };
                await this.deleteCategory(data);
                this.$emit('deleted', data);
            } catch (e) {
                this.error = e.message;
            }
        },
        getRealId(id) {
            return Number.parseInt(id.replace('product-', '').replace('category-', ''), 10);
        },
        ...mapActions({
            deleteCategory: 'producerProductGroup/deleteCategory',
        }),
    },
};
</script>
