<template>
    <div class="mb-3">
        <v-layout row wrap align-center>
            <h5 class="subheading mr-3 name" :class="{ clickable: clickable, 'name__marked': marked }" @click="handleNameClick">
                <span :class="{ expandable }">{{ name }} <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1"/></span>
                <v-icon v-if="expandable && expanded === null" class="ml-2" small>fa-chevron-down</v-icon>
                <v-icon v-if="expandable && expanded === 0" class="ml-2" small>fa-chevron-up</v-icon>
            </h5>
            <template v-if="showLineChart">
                <div v-if="!isReady" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" size="22" />
                </div>
                <GChart
                    v-if="hasChange"
                    class="mr-3"
                    type="LineChart"
                    :settings="{ packages: ['corechart'], language: $i18n.locale }"
                    :data="changeChartData"
                    :options="changeChartOptions"
                    :events="changeChartEvents"
                />
            </template>
            <ranking-item-bar
                class="mr-3"
                :total="total"
                :max-total="maxTotal"
                :total-type="totalType"
                :bar-width="barWidth"
                :additionalValueLabel="additionalValueLabel"
            />
            <change-label v-if="hasTotalChange && typeof change !== 'undefined'" :change="change" />
        </v-layout>
        <v-expansion-panel v-if="expandable" v-model="expanded" class="expandable-content">
            <v-expansion-panel-content>
                <slot name="expandableContent" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import ChangeLabel from '../common/ChangeLabel.vue';
import RankingItemBar from './RankingItemBar.vue';

export default {
    name: 'ranking-item-graph',
    components: {
        RankingItemBar,
        ChangeLabel,
        GChart,
        InfoTooltip,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        marked: {
            type: Boolean,
            default: false,
        },
        hasChange: {
            type: Boolean,
            default: false,
        },
        hasTotalChange: {
            type: Boolean,
            default: false,
        },
        changeData: {
            type: Array,
            // required: true,
        },
        change: {
            type: Number,
            // required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        totalType: {
            type: String,
            validator: value => ['decimal', 'percent', 'currency'].includes(value),
            default: 'decimal',
        },
        maxTotal: {
            type: Number,
            required: true,
        },
        showLineChart: {
            type: Boolean,
            default: true,
        },
        // value that will be emitted on click, e.g. row identifier
        clickable: {
            type: [String, Number, Object],
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        barWidth: {
            type: Number,
        },
        additionalValueLabel: {
            type: String,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            changeChartOptions: {
                enableInteractivity: false,
                curveType: 'function',
                lineWidth: 0.5,
                vAxis: {
                    baselineColor: 'transparent',
                    gridlines: {
                        color: 'transparent',
                    },
                },
                vAxes: {
                    0: {
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
                chartArea: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'transparent',
                },
                width: 120,
                height: 27,
                backgroundColor: '#f5f5f5',
            },
            changeChartEvents: {
                ready: () => {
                    this.isReady = true;
                },
            },
            isReady: false,
            expanded: null,
        };
    },
    computed: {
        changeChartData() {
            if (!this.hasChange || !this.changeData || this.changeData.length === 0) {
                return null;
            }

            return [
                ['Date', 'Value'],
                ...this.changeData,
            ];
        },
    },
    methods: {
        handleNameClick() {
            if (this.clickable) {
                this.$emit('select', this.clickable);
            }
            if (this.expandable) {
                this.expanded = this.expanded === null ? 0 : null;
            }
        },
    },
    created() {
        if (!this.hasChange || !this.changeData || this.changeData.length === 0) {
            this.isReady = true;
        }
    },
};
</script>

<style lang="scss" scoped>
    .name {
        display: flex;
        align-items: center;
        width: 200px;
        word-break: break-word;
    }
    .name__marked {
        font-weight: 700;
        color: $primary-color;
    }
    .expandable {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
    .clickable {
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
    }
    .expandable-content {
        width: unset;
        margin: 0 0 0 216px;
        box-shadow: none;

        .v-expansion-panel__container {
            background: none !important;
        }
    }
</style>
