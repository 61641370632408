<template>
    <v-btn v-if="isVisible" @click="goToAdminPanel" flat>
        <v-icon class="mr-2" small>fa-arrow-left</v-icon>
        <span v-if="$vuetify.breakpoint.width >= 600">{{ $t('adminPanelButton.label') }}</span>
    </v-btn>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'admin-panel-button',
    computed: {
        isVisible() {
            return (this.isAuthenticated && this.isAdmin) || this.$route.name === 'loginAs';
        },
        ...mapState({
            user: state => state.user,
        }),
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            isAdmin: 'auth/isAdmin',
        }),
    },
    methods: {
        goToAdminPanel() {
            this.logout().then(() => window.close('', '_parent', ''));
        },
        ...mapActions({
            logout: 'auth/logout',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .change-label {
        font-size: 12px;
        white-space: nowrap;
    }
</style>
