<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <v-layout
                class="mb-4"
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                    {{ title }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="exportData"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.export') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="expand"
                                flat
                                icon
                            >
                                <v-icon small>fa-expand</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.fullScreen') }}</span>
                    </v-tooltip>
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h3>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <slot name="filters" />
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo()"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <graph-container v-model="expanded" :title="title">
                <combo-chart-graph
                    v-if="!loading && chartData"
                    ref="chart"
                    :data="chartData"
                    :options="chartOptions"
                    :expanded="expanded"
                />
            </graph-container>
            <data-placeholder v-if="!loading && !chartData" :title="noResults" />
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{ error }}
            </v-alert>
            <slot name="footer" />
        </v-card-text>
    </div>
</template>

<script>
import { maxBy } from 'lodash';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import ComboChartGraph from './core/ComboChartGraph.vue';
import DataPlaceholder from '../common/DataPlaceholder.vue';
import GraphContainer from './GraphContainer.vue';
import expandGraphMixin from '../../mixins/expandGraphMixin';
import numberService from '../../services/numberService';

export default {
    name: 'traffic-graph',
    components: {
        GraphContainer,
        ComboChartGraph,
        DataPlaceholder,
        InfoTooltip,
    },
    mixins: [
        expandGraphMixin,
    ],
    props: {
        permissionName: {
            type: String,
        },
        data: {
            type: Array,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default() {
                return this.$t('trafficGraph.title');
            },
        },
        noResults: {
            type: String,
            default() {
                return this.$t('trafficGraph.noResults');
            },
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    computed: {
        chartData() {
            if (!this.data || this.data.length === 0) {
                return null;
            }

            const chartData = [
                [this.$t('trafficGraph.date'), this.$t('trafficGraph.clicks'), this.$t('trafficGraph.impressionsOffer')],
            ];
            this.data.forEach(item => {
                chartData.push([
                    this.$d(new Date(item.date), 'short'),
                    { v: item.clicks, f: numberService.formatNumber(item.clicks) },
                    { v: item.impressions, f: numberService.formatNumber(item.impressions) },
                ]);
            });
            return chartData;
        },
        chartOptions() {
            const options = {
                vAxes: {
                    0: {
                        title: this.$t('trafficGraph.impressionsOffer'),
                        format: '#',
                        viewWindow: {
                            min: 0,
                        },
                    },
                    1: {
                        title: this.$t('trafficGraph.clicks'),
                        format: '#',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
            };
            let hasImpressionsOverZero = false;
            let hasClicksOverZero = false;

            if (this.data && this.data.length > 0) {
                hasImpressionsOverZero = this.data.some(item => item.impressions > 0);
                hasClicksOverZero = this.data.some(item => item.clicks > 0);
            }

            if (!hasImpressionsOverZero) {
                options.vAxes[0].viewWindow.max = 1;
            } else {
                const maxImpressions = maxBy(this.data, item => item.impressions).impressions;
                options.vAxes[0].viewWindow.max = null;
                if (maxImpressions >= 1000) {
                    options.vAxes[0].format = 'short';
                }
            }

            if (!hasClicksOverZero) {
                options.vAxes[1].viewWindow.max = 1;
            } else {
                const maxClicks = maxBy(this.data, item => item.clicks).clicks;
                options.vAxes[1].viewWindow.max = null;
                if (maxClicks >= 1000) {
                    options.vAxes[1].format = 'short';
                }
            }

            return options;
        },
    },
    methods: {
        redo() {
            this.$emit('redo');
        },
        exportData() {
            this.$refs.chart?.exportData(this.title);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
