<template>
    <v-card class="elevation-0 pa-5 mb-4">
        <div v-if="accountLoading" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <v-alert
            :value="error"
            dismissible
            type="error"
            transition="scale-transition">
            {{ error }}
        </v-alert>
        <account-settings-personal v-if="!accountLoading && !error" class="mb-5" />
        <account-settings-company v-if="!accountLoading && !error" class="mb-5" />
        <account-settings-password v-if="!accountLoading && !error" />
    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AccountSettingsPersonal from '../../components/accountSettings/AccountSettingsPersonal.vue';
import AccountSettingsCompany from '../../components/accountSettings/AccountSettingsCompany.vue';
import AccountSettingsPassword from '../../components/accountSettings/AccountSettingsPassword.vue';

export default {
    name: 'producer-account-settings',
    components: {
        AccountSettingsPersonal,
        AccountSettingsCompany,
        AccountSettingsPassword,
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        ...mapState({
            accountLoading: state => state.auth.loading.fetchUserFull,
        }),
    },
    created() {
        this.getAccount();
    },
    methods: {
        async getAccount() {
            try {
                this.error = null;
                await this.fetchUserFull();
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchUserFull: 'auth/fetchUserFull',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
