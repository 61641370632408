/* eslint-disable no-unused-vars */
import {
    filterDates,
    filterKeysByCategoryIdNamePair, filterPieChartByCategoryIdNamePair,
    generateDates,
} from '@/services/demo-services/_utils';
import demoService from '@/services/demoService';

export default {
    async fetchAmountReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceCategorySalesAmountReport } = await import('../../mocks/spaceCategorySalesAmountReport.json');
        return {
            chart: filterKeysByCategoryIdNamePair(filterDates(generateDates(spaceCategorySalesAmountReport.chart)), categories),
            pie_chart: filterPieChartByCategoryIdNamePair(spaceCategorySalesAmountReport.pie_chart, categories),
        };
    },

    async fetchOrdersReport(spaceId, startDate, endDate, campaigns, categories) {
        // @todo missing?
    },

    async fetchQuantityReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceCategorySalesQuantityReport } = await import('../../mocks/spaceCategorySalesQuantityReport.json');
        return {
            chart: filterKeysByCategoryIdNamePair(filterDates(generateDates(spaceCategorySalesQuantityReport.chart)), categories),
            pie_chart: filterPieChartByCategoryIdNamePair(spaceCategorySalesQuantityReport.pie_chart, categories),
        };
    },

    async fetchImpressionsReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceCategoryImpressionsReport } = await import('../../mocks/spaceCategoryImpressionsReport.json');
        return {
            chart: filterKeysByCategoryIdNamePair(filterDates(generateDates(spaceCategoryImpressionsReport.chart)), categories),
            pie_chart: filterPieChartByCategoryIdNamePair(spaceCategoryImpressionsReport.pie_chart, categories),
        };
    },

    async fetchClicksReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceCategoryClickReport } = await import('../../mocks/spaceCategoryClickReport.json');
        return {
            chart: filterKeysByCategoryIdNamePair(filterDates(generateDates(spaceCategoryClickReport.chart)), categories),
            pie_chart: filterPieChartByCategoryIdNamePair(spaceCategoryClickReport.pie_chart, categories),
        };
    },

    async fetchImpressionsHeatmap(spaceId, startDate, endDate) {
        const { default: spaceCategoryImpressionsHeatmap } = await import('../../mocks/spaceCategoryImpressionsHeatmap.json');
        return spaceCategoryImpressionsHeatmap;
    },

    async fetchClicksHeatmap(spaceId, startDate, endDate) {
        const { default: spaceCategoryClicksHeatmap } = await import('../../mocks/spaceCategoryClicksHeatmap.json');
        return spaceCategoryClicksHeatmap;
    },

    async fetchQuantityHeatmap(spaceId, startDate, endDate) {
        const { default: spaceCategorySalesQuantityHeatmap } = await import('../../mocks/spaceCategorySalesQuantityHeatmap.json');
        return spaceCategorySalesQuantityHeatmap;
    },

    async fetchAmountHeatmap(spaceId, startDate, endDate) {
        const { default: spaceCategorySalesAmountHeatmap } = await import('../../mocks/spaceCategorySalesAmountHeatmap.json');
        return spaceCategorySalesAmountHeatmap;
    },

    async fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceCategoryReports } = await import('../../mocks/spaceCategoryReports.json');
        return spaceCategoryReports;
    },

    async fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceImpressionsRanking } = await import('../../mocks/spaceImpressionsCategoryRanking.json');
        return spaceImpressionsRanking;
    },

    async fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceClicksRanking } = await import('../../mocks/spaceClicksCategoryRanking.json');
        return spaceClicksRanking;
    },

    async fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceAmountRanking } = await import('../../mocks/spaceAmountCategoryRanking.json');
        return spaceAmountRanking;
    },

    async fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceQuantityRanking } = await import('../../mocks/spaceQuantityCategoryRanking.json');
        return spaceQuantityRanking;
    },

    productMainCategory: demoService.USE_ORIGINAL_PROPERTY,
    productCategoriesToTree: demoService.USE_ORIGINAL_PROPERTY,
};
