<template>
    <v-card class="elevation-0 mb-4">
        <div class="grey lighten-4 pa-4">
            <v-card-title class="price-filters grey lighten-4">
                <v-layout row wrap align-start justify-space-between>
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('availabilityAnalysis.title') }}
                        <div class="left-filters">
                            <v-btn @click="generateAvailabilityReport" flat icon><v-icon small>fa-download</v-icon>
                            </v-btn>
                            <filter-summary :filters="filtersSummary" />
                            <Chip :label="$t('filters.reset')" :active="!isFilterDefault" @click="reset" />
                        </div>
                    </h3>
                    <v-layout column style="flex-grow: 0;">
                        <v-layout wrap align-center justify-end>
                            <v-flex shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="campaign"
                                    :items="campaignOptions"
                                    :label="$t('filters.shop')"
                                    :all-label="$t('filters.allShops')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleCampaignBlur" />
                            </v-flex>
                            <v-flex v-if="!showProductGroupsResults" shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="category"
                                    :items="categoryOptions"
                                    :itemsTree="categoryOptionsTree"
                                    :label="$t('filters.category')"
                                    :all-label="$t('filters.allCategories')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleCategoryBlur" />
                            </v-flex>
                            <v-flex v-if="showProductGroupsResults" shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="group"
                                    :items="groupOptions"
                                    :label="$t('filters.group')"
                                    :all-label="$t('filters.allGroups')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleGroupBlur" />
                            </v-flex>
                            <v-flex shrink>
                                <v-btn v-if="error" @click="redo()" flat icon>
                                    <v-icon small>fa-redo-alt</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loading"
                                @change="handleShowProductGroupsResultsChange" />
                        </v-layout>
                    </v-layout>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert :value="error" dismissible type="error" transition="scale-transition">
                    {{ error }}
                </v-alert>

                <line-graph
                    v-if="!error && !loading && offersInTimeChartData"
                    :title="$t('availabilityAnalysis.offersInTime')"
                    :data="offersInTimeChartData"
                    :options="offersInTimeChartOptions"
                    :tooltip="$t('availabilityAnalysis.offersInTimeTooltip')"
                    type="small" />

                <ranking-graph
                    v-if="!error && !loading"
                    :title="$t('availabilityAnalysis.avgAvailabilityPerShop')"
                    :tooltip="$t('availabilityAnalysis.avgAvailabilityPerShopTooltip')"
                    :data="avgAvailabilityByShopData"
                    id-key="name"
                    name-key="name"
                    total-key="total"
                    total-type="percent"
                    change-key="totalChange"
                    has-total-change
                    :export-header="avgAvailabilityByShopExportHeader"
                    :bar-width="400"
                    type="small">
                    <template #filters>
                        <v-layout align-end justify-start column>
                            <v-layout wrap align-center justify-end>
                                <v-flex shrink>
                                    <v-select
                                        class="d-inline-block mb-3 select--responsive"
                                        v-model="perShopSortBy"
                                        @change="handlePerShopSortByChange"
                                        :items="sortByOptions"
                                        placeholder=" "
                                        :label="$t('filters.sortBy')"
                                        outline
                                        hide-details
                                        dense />
                                </v-flex>
                            </v-layout>
                        </v-layout>
                    </template>
                </ranking-graph>

                <ranking-graph
                    v-if="!error && !loading"
                    :title="$t('availabilityAnalysis.avgAvailabilityPerProduct')"
                    :tooltip="$t('availabilityAnalysis.avgAvailabilityPerProductTooltip')"
                    :firstItemTooltip="$t('availabilityAnalysis.avgAvailabilityPerProductFirstItemTooltip')"
                    :data="avgAvailabilityByProductData"
                    id-key="id"
                    name-key="name"
                    total-key="total"
                    total-type="percent"
                    change-key="totalChange"
                    has-total-change
                    :export-header="avgAvailabilityByProductExportHeader"
                    :bar-width="400"
                    @select="handleByProductItemSelect"
                    type="small">
                    <template #filters>
                        <v-layout align-end justify-start column>
                            <v-layout wrap align-center justify-end>
                                <v-flex shrink>
                                    <v-select
                                        class="d-inline-block mr-3 mb-3 select--rows"
                                        v-model="perProductRowsToShow"
                                        @change="handlePerProductRowsToShowChange"
                                        :items="rowsToShowOptions"
                                        placeholder=" "
                                        :label="$t('rowsToShow')"
                                        outline
                                        hide-details
                                        dense />
                                </v-flex>
                                <v-flex shrink>
                                    <v-select
                                        class="d-inline-block mb-3 select--responsive"
                                        v-model="perProductSortBy"
                                        @change="handlePerProductSortByChange"
                                        :items="sortByOptions"
                                        placeholder=" "
                                        :label="$t('filters.sortBy')"
                                        outline
                                        hide-details
                                        dense />
                                </v-flex>
                            </v-layout>
                            <v-layout wrap align-center justify-end>
                                <v-flex shrink>
                                    <v-text-field
                                        class="ml-3 mb-3 search"
                                        v-model="searchProducts"
                                        :disabled="loading"
                                        :label="$t('availabilityAnalysis.searchProduct')"
                                        placeholder=" "
                                        outline
                                        hide-details
                                        clearable
                                        clear-icon="fa-times"
                                        @keyup="handleSearchProducts"
                                        @click:clear="handleSearchProducts" />
                                </v-flex>
                            </v-layout>
                        </v-layout>
                    </template>
                </ranking-graph>
                <availability-product-modal
                    v-if="detailsProductId"
                    :entity-id="detailsProductId"
                    :date-filter="dateFilter"
                    :parent-campaign="campaign"
                    @close="handleProductDetailsClose" />

                <availability-per-shop
                    v-if="!error && !loading"
                    :data="productsAvailabilityPerShop"
                    :campaign="campaign"
                    :get-campaign-name-from-id="getCampaignNameFromId" />
            </v-card-text>
        </div>
    </v-card>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import campaignFilterMixin from '@/mixins/campaignFilterMixin';
import categoryFilterMixin from '@/mixins/categoryFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import SelectAll from '@/components/common/SelectAll.vue';
import Chip from '@/components/common/Chip.vue';
import FilterSummary from '@/components/common/FilterSummary.vue';
import eventBus from '@/services/eventBus';
import dateService from '@/services/dateService';
import {
    get,
    set,
    orderBy,
    sum,
    trim,
    deburr,
    debounce,
} from 'lodash';
import numberService from '@/services/numberService';
import ganttService from '@/services/ganttService';
import RankingGraph from '@/components/graphs/RankingGraph.vue';
import AvailabilityProductModal from '@/components/producer/reports/availability/AvailabilityProductModal.vue';
import LineGraph from '@/components/graphs/LineGraph.vue';
import AvailabilityPerShop from '@/components/producer/reports/availability/AvailabilityPerShop.vue';
import reportsCategoryService from '@/services/reportsCategoryService';

export default {
    name: 'availability-analysis',
    components: {
        AvailabilityPerShop,
        LineGraph,
        AvailabilityProductModal,
        RankingGraph,
        SelectAll,
        Chip,
        FilterSummary,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isTestEnvironment: process.env.NODE_ENV === 'development',
            showProductGroupsResults: false,
            isFilterDefault: true,
            hasAllCampaignsSelected: false,
            error: null,
            offersInTimeChartData: null,
            avgAvailabilityByProductData: null,
            avgAvailabilityByShopData: null,
            productsAvailabilityPerShop: null,
            rowsToShowOptions: [
                {
                    value: 10,
                    text: '10',
                },
                {
                    value: 20,
                    text: '20',
                },
                {
                    value: 50,
                    text: '50',
                },
                {
                    value: 0,
                    text: this.$t('showAll'),
                },
            ],
            perProductRowsToShow: 10,
            sortByOptions: [
                {
                    value: 'summary-desc',
                    text: this.$t('availabilityAnalysis.sortBySummaryDesc'),
                },
                {
                    value: 'summary-asc',
                    text: this.$t('availabilityAnalysis.sortBySummaryAsc'),
                },
                {
                    value: 'change-desc',
                    text: this.$t('availabilityAnalysis.sortByChangeDesc'),
                },
                {
                    value: 'change-asc',
                    text: this.$t('availabilityAnalysis.sortByChangeAsc'),
                },
            ],
            perShopSortBy: 'summary-desc',
            perProductSortBy: 'summary-desc',
            searchProducts: null,
            detailsProductId: null,
        };
    },
    computed: {
        loading() {
            return this.isAnyGroupProductsFetching() || this.availabilityChartsLoading;
        },
        offersInTimeChartOptions() {
            return {
                vAxes: {
                    0: {
                        title: this.$t('availabilityAnalysis.offers'),
                        format: 'short',
                        viewWindow: {
                            max: !this.hasOffersInTimeValueOverZero() ? 1 : null,
                        },
                    },
                },
            };
        },
        filtersSummary() {
            const summary = [...this.campaignFilterSummary];
            if (this.showProductGroupsResults) {
                summary.push(this.groupFilterSummary);
            } else {
                summary.push(this.categoryFilterSummary);
            }
            return summary;
        },
        avgAvailabilityByProductExportHeader() {
            return [`"${this.$t('availabilityAnalysis.byProductExportProductName')}"`, `"${this.$t('availabilityAnalysis.byProductExportAvgAvailability')} (%)"`];
        },
        avgAvailabilityByShopExportHeader() {
            return [`"${this.$t('availabilityAnalysis.byShopExportShopName')}"`, `"${this.$t('availabilityAnalysis.byShopExportAvgAvailability')} (%)"`];
        },
        ...mapState({
            availabilityChartsLoading: state => state.reportsAvailability.loading.availabilityCharts,
        }),
        ...mapGetters({
            getterAvailabilityCharts: 'reportsAvailability/getAvailabilityCharts',
            getterGroupProducts: 'producerProductGroup/getGroupProducts',
            isAnyGroupProductsFetching: 'producerProductGroup/isAnyGroupProductsFetching',
            getterCurrentProductsAvailability: 'reportsAvailability/getCurrentProductsAvailability',
        }),
    },
    methods: {
        emitFilterDefaultChange() {
            this.$emit('filterDefaultChange', this.isFilterDefault);
        },
        async generateAvailabilityReport() {
            try {
                this.error = null;
                if (this.category?.length || this.products?.length || (this.group?.length && this.showProductGroupsResults)) {
                    const {
                        startDate, endDate, compareStartDate, compareEndDate,
                    } = this.dateFilter;
                    const products = this.showProductGroupsResults ? this.getSelectedGroupsProductsIds() : null;
                    const campaigns = [...this.campaign];
                    const categories = !this.showProductGroupsResults ? [...this.category] : null;
                    const groups = this.showProductGroupsResults ? [...this.group] : [];
                    await this.generateAvailabilityExport({
                        startDate,
                        endDate,
                        compareStartDate,
                        compareEndDate,
                        campaigns,
                        categories,
                        groups,
                        products,
                    });
                    this.$toast.info(this.$t('dataExport.generationFeedback'), { timeout: 7500, color: 'tertiary' });
                    this.$router.push({ name: 'producerDataExport' });
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        handleFilterDefaultChange() {
            this.isFilterDefault = this.isDefaultCategory()
                && this.isDefaultCampaign()
                && this.isDefaultGroup()
                && !this.showProductGroupsResults
                && this.perProductRowsToShow === 10
                && this.perProductSortBy === 'summary-desc'
                && this.perShopSortBy === 'summary-desc'
                && !this.searchProducts;
            this.emitFilterDefaultChange();
            this.setData();
        },
        async handleCampaignBlur() {
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.handleFilterDefaultChange();
            await this.getAvailabilityCharts();
            this.$emit('campaignChange', this.campaign);
        },
        handleCategoryBlur() {
            this.handleFilterDefaultChange();
        },
        handleGroupBlur() {
            this.handleFilterDefaultChange();
            this.getGroupsData();
        },
        handleShowProductGroupsResultsChange() {
            this.handleFilterDefaultChange();
        },
        handlePerProductRowsToShowChange() {
            this.handleFilterDefaultChange();
        },
        handlePerShopSortByChange() {
            this.handleFilterDefaultChange();
        },
        handlePerProductSortByChange() {
            this.handleFilterDefaultChange();
        },
        handleSearchProducts: debounce(function handleSearchProducts() {
            this.handleFilterDefaultChange();
        }, 500),
        async reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign();
            this.setDefaultGroup();
            await this.getGroupsData();
            this.showProductGroupsResults = false;
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.perProductRowsToShow = 10;
            this.perProductSortBy = 'summary-desc';
            this.perShopSortBy = 'summary-desc';
            this.searchProducts = '';
            this.handleFilterDefaultChange();
        },
        async getGroupsData() {
            try {
                await Promise.all(this.group.map(groupId => this.fetchGroupProducts({ groupId })));
                this.setData();
            } catch (e) {
                this.error = e.message;
            }
        },
        redo() {
            this.getAvailabilityCharts(true);
            this.$emit('campaignChange', this.campaign);
        },
        async getAvailabilityCharts(force = false) {
            try {
                const {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                } = this.dateFilter;
                this.error = null;
                await Promise.all([
                    this.fetchAvailabilityCharts({
                        force,
                        startDate,
                        endDate,
                        campaign: this.campaign,
                    }),
                    this.fetchAvailabilityCharts({
                        force,
                        startDate:
                            compareStartDate,
                        endDate: compareEndDate,
                        campaign: this.campaign,
                    }),
                ]);
                this.setData();
            } catch (e) {
                this.error = e.message;
            }
        },
        getSelectedGroupsProductsIds() {
            return this.group.map(groupId => Object.keys(this.getterGroupProducts(groupId) ?? {})).flat();
        },
        setData() {
            const {
                offersInTimeChartData,
                avgAvailabilityByProductData,
                avgAvailabilityByShopData,
                productsAvailabilityPerShop,
            } = this.calculate(this.dateFilter.startDate, this.dateFilter.endDate);
            const {
                avgAvailabilityByProductData: compareAvgAvailabilityByProductData,
                avgAvailabilityByShopData: compareAvgAvailabilityByShopData,
            } = this.calculate(this.dateFilter.compareStartDate, this.dateFilter.compareEndDate);
            this.offersInTimeChartData = offersInTimeChartData;
            this.avgAvailabilityByProductData = this.filterRankingData(this.addCompareData(avgAvailabilityByProductData, compareAvgAvailabilityByProductData), this.perProductSortBy, this.searchProducts, this.perProductRowsToShow);
            this.avgAvailabilityByShopData = this.filterRankingData(this.addCompareData(avgAvailabilityByShopData, compareAvgAvailabilityByShopData), this.perShopSortBy);
            this.productsAvailabilityPerShop = productsAvailabilityPerShop;
        },
        calculate(startDate, endDate) {
            const productsAvailability = this.getterCurrentProductsAvailability(this.campaign) ?? {};
            try {
                const startDateTime = new Date(startDate).valueOf();
                const endDateTime = new Date(endDate).valueOf();
                const products = this.getterAvailabilityCharts(startDate, endDate, this.campaign) ?? {};
                if (!products || Object.keys(products).length === 0) {
                    return {
                        offersInTimeChartData: null,
                        avgAvailabilityByProductData: null,
                        avgAvailabilityByShopData: null,
                        productsAvailabilityPerShop: null,
                    };
                }
                const selectedGroupsProductsIds = this.getSelectedGroupsProductsIds();
                const dates = dateService.findMissingDates([], startDate, endDate);
                const campaigns = new Set();
                const offersInTimeDataByDate = {};
                const avgAvailabilityByProduct = {};
                const productsMillisecondsPerShop = {};

                // loop through products
                Object.keys(products).forEach(productId => {
                    // filter by product group
                    if (this.showProductGroupsResults && !selectedGroupsProductsIds.includes(productId)) {
                        return;
                    }
                    if (!this.showProductGroupsResults) {
                        // filter by category
                        const productCategoryIds = (products[productId].product.categories ?? [])
                            .flat()
                            .map(cat => cat.id);
                        if (!productCategoryIds.some(categoryId => this.category.includes(categoryId))) {
                            return;
                        }
                    }

                    const shops = products[productId].charts ?? {};
                    const shopsIds = this.campaign.map(String) ?? [];
                    const productMillisecondsPerShop = {};

                    // loop through product shops
                    shopsIds.forEach(shopId => {
                        // check if shop has any offers
                        if (shops[shopId]?.length) {
                            // all offers in a shop have same campaign data, so we can take name from first one
                            const shopName = shops[shopId][0].offer.campaign;
                            // add shop name to campaign list
                            campaigns.add(shopName);
                            // put segments from all offers into a single array
                            const segments = shops[shopId].reduce((segmentsArr, offer) => {
                                segmentsArr.push(...offer.chart.segments);
                                return segmentsArr;
                            }, []);
                            // merge overlapping segments and limit to start and end time
                            const mergedSegments = ganttService.limitSegments(ganttService.mergeSegments(segments), startDateTime, endDateTime);
                            let segmentsMilliseconds = 0;

                            mergedSegments.forEach(segment => {
                                // calculate offers in time by date
                                for (let i = 0; i <= dates.length; i += 1) {
                                    const dayStart = dates[i];
                                    const dayEnd = dates[i + 1];
                                    // check if date is in range of segment
                                    if (
                                        (dayStart >= segment.start && dayStart <= segment.end)
                                        || (!dayEnd && dayStart <= segment.end) // special case for last day of range
                                        || (dayEnd && dayEnd >= segment.start && dayEnd <= segment.end)
                                    ) {
                                        const offersCountPath = [dates[i], shopName];
                                        const currentOffersCount = get(offersInTimeDataByDate, offersCountPath, 0);
                                        set(offersInTimeDataByDate, offersCountPath, currentOffersCount + 1);
                                    }
                                }

                                // calculate segments milliseconds
                                const segmentMilliseconds = segment.end - segment.start;
                                segmentsMilliseconds += segmentMilliseconds;
                            });

                            productMillisecondsPerShop[shopName] = segmentsMilliseconds;
                        } else {
                            const shopName = this.getCampaignNameFromId(parseInt(shopId, 10));
                            productMillisecondsPerShop[shopName] = 0;
                        }
                    });

                    productsMillisecondsPerShop[productId] = productMillisecondsPerShop;

                    // calc product seconds avg
                    const productSecondsAvg = Object.keys(productMillisecondsPerShop).length ? sum(Object.keys(productMillisecondsPerShop).map(shopName => productMillisecondsPerShop[shopName])) / Object.keys(productMillisecondsPerShop).length : 0;
                    // calculate product avg availability
                    avgAvailabilityByProduct[productId] = (productSecondsAvg / (endDateTime - startDateTime)) * 100;
                });

                // sort shops
                const campaignsArr = orderBy(Array.from(campaigns), campaign => campaign.toLowerCase());

                // make sure no dates are missing in final dataset
                const offersInTimeData = dates.map(date => {
                    const dataItem = [this.$d(new Date(parseInt(date, 10)), 'short')];
                    campaignsArr.forEach(campaign => {
                        const value = offersInTimeDataByDate[date]?.[campaign] ?? 0;
                        dataItem.push({ v: value, f: numberService.formatNumber(value) });
                    });
                    return dataItem;
                });

                // do not return anything if there is no data, first column is always present date
                const offersInTimeChartData = offersInTimeData[0].length === 1 ? null : [['Date', ...campaignsArr], ...offersInTimeData];

                // calculate average availability by product
                const avgAvailabilityByProductData = Object.keys(avgAvailabilityByProduct).map(productId => ({
                    name: products[productId].product.name,
                    ean: products[productId].product.ean,
                    sku: products[productId].product.sku,
                    total: avgAvailabilityByProduct[productId],
                    clickable: products[productId].product.id,
                    marked: true,
                }));

                // calculate average availability by shop
                const productsCount = Object.keys(products).length;
                const shopsProductsMilliseconds = {};
                Object.keys(productsMillisecondsPerShop).forEach(productId => {
                    Object.keys(productsMillisecondsPerShop[productId]).forEach(shopName => {
                        if (!shopsProductsMilliseconds[shopName]) {
                            shopsProductsMilliseconds[shopName] = [];
                        }
                        shopsProductsMilliseconds[shopName].push(productsMillisecondsPerShop[productId][shopName]);
                    });
                });
                const avgAvailabilityByShop = {};
                Object.keys(shopsProductsMilliseconds).forEach(shopName => {
                    // calc shop products seconds avg
                    const millisecondsAvg = Object.keys(shopsProductsMilliseconds[shopName]).length ? sum(Object.keys(shopsProductsMilliseconds[shopName]).map(productId => shopsProductsMilliseconds[shopName][productId])) / productsCount : 0;
                    // calculate shop avg availability
                    avgAvailabilityByShop[shopName] = (millisecondsAvg / (endDateTime - startDateTime)) * 100;
                });
                const avgAvailabilityByShopData = Object.keys(avgAvailabilityByShop).map(shopName => {
                    const avgAvailabilityByShopQuantitatively = Math.round(productsCount * avgAvailabilityByShop[shopName] / 100);
                    return {
                        name: shopName,
                        total: avgAvailabilityByShop[shopName],
                        additionalValueLabel: avgAvailabilityByShopQuantitatively.toString(),
                    };
                });

                // calculate products availability per shop
                const dateRangeInDays = (endDateTime - startDateTime) / 1000 / 60 / 60 / 24;
                const productsAvailabilityPerShop = Object.keys(productsMillisecondsPerShop).map(productId => {
                    const { product } = products[productId];
                    const availabilityPerShop = {};
                    Object.keys(productsMillisecondsPerShop[productId]).forEach(shopName => {
                        const availabilityInMilliseconds = productsMillisecondsPerShop[productId][shopName];
                        const availabilityInDays = Math.round(availabilityInMilliseconds / 1000 / 60 / 60 / 24);
                        const availabilityInPercentage = availabilityInDays / dateRangeInDays * 100;
                        const availabilityInPercentageFormatted = numberService.formatPercent(availabilityInPercentage);
                        availabilityPerShop[shopName] = { availabilityInDays, availabilityInPercentage, availabilityInPercentageFormatted };
                    });
                    const categoryTree = reportsCategoryService.productCategoriesToTree(product.categories);
                    const mainCategory = reportsCategoryService.productMainCategory(product.categories);
                    const availabilities = productsAvailability[product.id]?.availabilities;
                    return {
                        product,
                        availabilityPerShop,
                        custom: {
                            categoryTree,
                            mainCategory,
                            dateRangeInDays,
                        },
                        availabilities,
                    };
                });

                return {
                    offersInTimeChartData,
                    avgAvailabilityByProductData,
                    avgAvailabilityByShopData,
                    productsAvailabilityPerShop,
                };
            } catch (e) {
                this.error = e.message;
                return {
                    offersInTimeChartData: null,
                    avgAvailabilityByProductData: null,
                    avgAvailabilityByShopData: null,
                    productsAvailabilityPerShop: null,
                };
            }
        },
        addCompareData(data, compareData) {
            return (data ?? []).map(item => {
                const totalCompare = compareData.find(compareItem => compareItem.name === item.name)?.total ?? 0;
                return {
                    ...item,
                    totalChange: numberService.calculateChangePercentage(item.total, totalCompare),
                };
            });
        },
        filterRankingData(data, sortByType, searchQuery, limit = 0) {
            const [type, direction] = sortByType.split('-');
            const sortField = type === 'change' ? 'totalChange' : 'total';
            let parsedData = data;
            if (searchQuery) {
                // user can input multiple search queries separated by commas
                const searchQueries = searchQuery
                    .split(',')
                    .map(query => deburr(trim(query)).toLowerCase())
                    .filter(Boolean);
                parsedData = parsedData.filter(item => searchQueries.some(query => deburr(item.name).toLowerCase().includes(query) || item.ean?.toLowerCase().includes(query) || item.sku?.toLowerCase().includes(query)));
            }
            parsedData = orderBy(parsedData, [sortField], [direction]);
            if (limit > 0) {
                parsedData = parsedData.slice(0, limit);
            }
            return parsedData;
        },
        hasOffersInTimeValueOverZero() {
            if (this.offersInTimeChartData) {
                const hasValueOverZero = this.offersInTimeChartData.some((row, rowIndex) => rowIndex !== 0 && row.some((col, colIndex) => colIndex !== 0 && col.v > 0));
                if (hasValueOverZero) {
                    return true;
                }
            }
            return false;
        },
        handleByProductItemSelect(productId) {
            this.detailsProductId = productId;
        },
        handleProductDetailsClose() {
            this.detailsProductId = null;
        },
        ...mapActions({
            fetchGroupProducts: 'producerProductGroup/fetchGroupProducts',
            fetchAvailabilityCharts: 'reportsAvailability/fetchAvailabilityCharts',
            generateAvailabilityExport: 'reportsAvailability/generateAvailabilityExport',
        }),
    },
    async created() {
        this.setDefaultCategory();
        this.setDefaultCampaign();
        this.setDefaultGroup();
        await this.getGroupsData();
        await this.getAvailabilityCharts();
        this.$emit('campaignChange', this.campaign);
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
};

</script>

<style lang="scss" scoped>
.select--rows {
    width: 190px;
}
</style>
