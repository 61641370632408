export default {
    data() {
        return {
            expanded: false,
        };
    },
    watch: {
        expanded(newValue, oldValue) {
            if (newValue !== oldValue && this.$refs.chart) {
                window.dispatchEvent(new Event('resize'));
            }
        },
    },
    methods: {
        expand() {
            this.expanded = true;
        },
    },
};
