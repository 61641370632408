<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('accountSettingsCompany.title') }}</h3>
        </v-card-title>
        <v-card-text>
            <v-form @submit.prevent="handleSubmit" novalidate>
                <v-layout row wrap>
                    <v-flex
                        class="pr-4"
                        xs12
                        sm6>
                        <v-text-field
                            v-model.trim="model.companyName"
                            :error-messages="companyNameErrors"
                            @blur="$v.model.companyName.$touch()"
                            name="companyName"
                            :label="$t('accountSettingsCompany.companyName')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.street"
                            :error-messages="streetErrors"
                            @blur="$v.model.street.$touch()"
                            name="street"
                            :label="$t('accountSettingsCompany.street')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.streetNumber"
                            :error-messages="streetNumberErrors"
                            @blur="$v.model.streetNumber.$touch()"
                            name="streetNumber"
                            :label="$t('accountSettingsCompany.streetNumber')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.apartmentNumber"
                            @blur="$v.model.apartmentNumber.$touch()"
                            name="apartmentNumber"
                            :label="$t('accountSettingsCompany.apartmentNumber')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.city"
                            :error-messages="cityErrors"
                            @blur="$v.model.city.$touch()"
                            name="city"
                            :label="$t('accountSettingsCompany.city')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.postcode"
                            :error-messages="postcodeErrors"
                            @blur="$v.model.postcode.$touch()"
                            name="postcode"
                            :label="$t('accountSettingsCompany.postcode')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="10"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-select
                            :items="countryOptions"
                            v-model="model.country"
                            :error-messages="countryErrors"
                            @blur="$v.model.country.$touch()"
                            name="country"
                            :label="$t('accountSettingsCompany.country')"
                            :disabled="submitting"
                            required
                            placeholder=" "
                            outline
                            dense
                        />
                    </v-flex>
                    <v-flex
                        class="pr-4"
                        xs12
                        sm6>
                        <v-text-field
                            v-model.trim="model.companyNip"
                            :error-messages="companyNipErrors"
                            @blur="$v.model.companyNip.$touch()"
                            name="companyNip"
                            :label="$t('accountSettingsCompany.companyNip')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="20"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                </v-layout>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <v-btn
                    class="btn-wide"
                    type="submit"
                    color="tertiary"
                    :loading="submitting"
                    :disabled="submitting"
                    dark
                    round
                >
                    {{ $t('accountSettingsCompany.submit') }}
                </v-btn>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import countryService from '../../services/countryService';

export default {
    name: 'account-settings-company',
    validations: {
        model: {
            companyName: { required },
            companyNip: { required },
            street: { required },
            streetNumber: { required },
            city: { required },
            postcode: { required },
            country: { required },
        },
    },
    data() {
        return {
            model: {
                companyName: '',
                companyNip: '',
                street: '',
                streetNumber: '',
                apartmentNumber: '',
                city: '',
                postcode: '',
                country: '',
            },
            error: null,
        };
    },
    computed: {
        countryOptions() {
            return countryService.getCountryOptions();
        },
        companyNameErrors() {
            const errors = [];
            if (!this.$v.model.companyName.$dirty) {
                return errors;
            }
            if (!this.$v.model.companyName.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        streetErrors() {
            const errors = [];
            if (!this.$v.model.street.$dirty) {
                return errors;
            }
            if (!this.$v.model.street.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        streetNumberErrors() {
            const errors = [];
            if (!this.$v.model.streetNumber.$dirty) {
                return errors;
            }
            if (!this.$v.model.streetNumber.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.model.city.$dirty) {
                return errors;
            }
            if (!this.$v.model.city.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        postcodeErrors() {
            const errors = [];
            if (!this.$v.model.postcode.$dirty) {
                return errors;
            }
            if (!this.$v.model.postcode.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        countryErrors() {
            const errors = [];
            if (!this.$v.model.country.$dirty) {
                return errors;
            }
            if (!this.$v.model.country.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        companyNipErrors() {
            const errors = [];
            if (!this.$v.model.companyNip.$dirty) {
                return errors;
            }
            if (!this.$v.model.companyNip.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        ...mapState({
            user: state => state.auth.user,
            submitting: state => state.auth.loading.updateCompanySettings,
        }),
    },
    created() {
        this.setInitialValues();
    },
    methods: {
        setInitialValues() {
            const {
                companyName, companyNip, street, streetNumber, apartmentNumber, city, postcode, country,
            } = this.user;
            this.model = {
                companyName,
                companyNip,
                street,
                streetNumber,
                apartmentNumber,
                city,
                postcode,
                country,
            };
        },
        async handleSubmit() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.updateCompanySettings({ ...this.model });
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            updateCompanySettings: 'auth/updateCompanySettings',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
