import demoService from '@/services/demoService';
import reportsProductsServiceDemo from '@/services/demo-services/reportsProductsServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const reportsProductsService = {
    async fetchSalesChart(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/product-reports`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCartAnalysis(spaceId, startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/other-products-sales`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    conversionTime: conversion,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/impressions-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/clicks-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/amount-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/quantity-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(reportsProductsService, reportsProductsServiceDemo, 'reportsProductsService');
export default reportsProductsService;
