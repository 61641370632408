import { deburr, orderBy } from 'lodash';

export default {
    data() {
        return {
            products: [],
        };
    },
    computed: {
        productsFilterSummary() {
            let items = this.products.map(productOption => productOption.text);
            items = orderBy(items, [item => deburr(item).toLowerCase()], ['asc']);
            return {
                name: this.$t('filterSummary.types.products'),
                items,
            };
        },
    },
    methods: {
        /**
         * Set default product items
         */
        setDefaultProducts() {
            this.products = [];
        },
        /**
         * Returns if currently selected products are matching default list
         * @return {boolean}
         */
        isDefaultProducts() {
            return this.products.length === 0;
        },
    },
};
