/* eslint-disable no-unused-vars */
import { filterDates, generateDates } from '@/services/demo-services/_utils';

export default {
    async fetchSalesChart(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceSalesChart } = await import('../../mocks/spaceSalesChart.json');
        return filterDates(generateDates(spaceSalesChart));
    },

    async fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceProductReports } = await import('../../mocks/spaceProductReports.json');
        return spaceProductReports;
    },

    async fetchCartAnalysis(spaceId, startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns) {
        const { default: spaceCartAnalysis } = await import('../../mocks/spaceCartAnalysis.json');
        return spaceCartAnalysis;
    },

    async fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceImpressionsRanking } = await import('../../mocks/spaceImpressionsRanking.json');
        return spaceImpressionsRanking;
    },

    async fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceClicksRanking } = await import('../../mocks/spaceClicksRanking.json');
        return spaceClicksRanking;
    },

    async fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceAmountRanking } = await import('../../mocks/spaceAmountRanking.json');
        return spaceAmountRanking;
    },

    async fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceQuantityRanking } = await import('../../mocks/spaceQuantityRanking.json');
        return spaceQuantityRanking;
    },
};
