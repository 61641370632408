import demoService from '@/services/demoService';
import dashboardServiceDemo from '@/services/demo-services/dashboardServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const dashboardService = {
    async fetchDashboardClicksStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/clicks-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardSalesStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    ...(sources?.length ? { sources } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardOwnSalesStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/own-sales-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardOrdersStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/orders-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    ...(sources?.length ? { sources } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardOwnOrdersStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/own-orders-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardImpressionsStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/impressions-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardSalesChart(spaceId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchDashboardTrafficChart(spaceId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/traffic-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(dashboardService, dashboardServiceDemo, 'dashboardService');
export default dashboardService;
