<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <div class="full-width">
                <v-layout row wrap align-center justify-space-between>
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('overview') }}
                    </h3>
                    <v-layout
                        wrap
                        align-center
                        justify-end
                    >
                        <v-flex shrink>
                            <SelectAll
                                v-if="hasPermissions"
                                class="d-inline-block ml-3 mb-3 select--responsive"
                                v-model="sources"
                                @blur="getData()"
                                :items="sourcesOptions"
                                :disabled="loading || filtersLoading"
                                placeholder=" "
                                :label="$t('filters.sources')"
                                outline
                                hide-details
                                dense
                                multiple
                                :require-selection="false"
                                :loading="uniqueSourcesLoading"
                            />
                            <FormSourcesPlaceholder v-else />
                        </v-flex>
                    </v-layout>

                    <div class="funnel-container mb-5 justify-center">

                        <div class="funnel-box funnel-box-1">
                            <div class="funnel-content">
                                <div v-if="impressionsStatsLoading || filtersLoading" class="mt-3 text-xs-center">
                                    <v-progress-circular indeterminate color="white" />
                                </div>
                                <template v-else>
                                    <div class="title">
                                        {{ numberService.formatNumber(impressionsStats.impressions) }}
                                        <InfoTooltip :text="$t('producerReportsSales.impressionsStatsTooltip')" class="ml-1" />
                                    </div>
                                    <div class="caption">&nbsp;</div>
                                    <div class="caption">
                                        {{ $t('impressionsWidget') }}
                                        <template v-if="hasPermissions">({{ $t('percent_of_total', { percent: numberService.formatPercent(impressionsStats.impressions * 100 / impressionsStatsAllSources.impressions) }) }})</template>
                                    </div>
                                </template>
                            </div>
                            <div class="funnel-bg funnel-bg-1">
                                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="286.68px" height="169px">
                                    <path fill="#00e08f" d="M0,0 L265.88,16.75 L286.68,84.5 L265.88,152.25 L0,169 Z"></path>
                                </svg>
                            </div>
                        </div>

                        <div class="funnel-box funnel-box-2">
                            <div class="funnel-content">
                                <div v-if="clicksStatsLoading || filtersLoading" class="mt-3 text-xs-center">
                                    <v-progress-circular indeterminate color="white" />
                                </div>
                                <template v-else>
                                    <div class="title">
                                        {{ numberService.formatNumber(clicksStats.clicks) }}
                                        <InfoTooltip :text="$t('producerReportsSales.clicksStatsTooltip')" class="ml-1" />
                                    </div>
                                    <div class="caption">CTR {{ numberService.formatPercent(clicksStats.clicks * 100 / impressionsStats.impressions) }}</div>
                                    <div class="caption">
                                        {{ $t('productsSummary.clicks') }}
                                        <template v-if="hasPermissions">({{ $t('percent_of_total', { percent: numberService.formatPercent(clicksStats.clicks * 100 / clicksStatsAllSources.clicks) }) }})</template>
                                    </div>
                                </template>
                            </div>
                            <div class="funnel-bg funnel-bg-2">
                                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="228.89px" height="135.5px">
                                    <path fill="#02c881" d="M0,0 L208.08,13.5 L228.89,67.5 L208.08,122 L0,135.5 L18,67.5 Z"></path>
                                </svg>
                            </div>
                        </div>

                        <div class="funnel-box funnel-box-3">
                            <div class="funnel-content">
                                <div v-if="ordersStatsLoading || filtersLoading" class="mt-3 text-xs-center">
                                    <v-progress-circular indeterminate color="white" />
                                </div>
                                <template v-else>
                                    <div class="title">
                                        {{ numberService.formatNumber(ordersStats.orders) }}
                                        <InfoTooltip :text="$t('producerReportsSales.ordersStatsTooltip')" class="ml-1" />
                                    </div>
                                    <div class="caption">CR {{ numberService.formatPercent(ordersStats.orders * 100 / clicksStats.clicks) }}</div>
                                    <div class="caption">
                                        {{ hasPermissions ? $t('transactions') : $t('allTransactions') }}
                                        <template v-if="hasPermissions">({{ $t('percent_of_total', { percent: numberService.formatPercent(ordersStats.orders * 100 / ordersStatsAllSources.orders) }) }})</template>
                                    </div>
                                </template>
                            </div>
                            <div class="funnel-bg funnel-bg-3">
                                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="182.65px" height="108.5px">
                                    <path fill="#02ac6e" d="M0,0 L161.84,11.5 L182.65,54.25 L161.84,97 L0,108.5 L18,54.25 Z"></path>
                                </svg>
                            </div>
                        </div>

                    </div>

                    <v-layout
                        class="mb-2 stat-box__container justify-center"
                        row
                        wrap>
                        <stat-box
                            :title="$t('producerDashboard.quantityOwnProducts')"
                            :tooltip="$t('producerDashboard.quantityOwnProductsTooltip')"
                            :loading="salesStatsLoading || filtersLoading"
                            :value="salesStats.quantity"
                            :change="salesStats.quantityChange"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.quantityOtherProducts')"
                            :tooltip="$t('producerDashboard.quantityOtherProductsTooltip')"
                            :loading="salesStatsLoading || filtersLoading"
                            :value="salesStats.quantityOther"
                            :change="salesStats.quantityOtherChange"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.quantityUnknownProducts')"
                            :tooltip="$t('producerDashboard.quantityUnknownProductsTooltip')"
                            :loading="salesStatsLoading || filtersLoading"
                            :value="salesStats.quantityUnknown"
                            :change="salesStats.quantityUnknownChange"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                    </v-layout>

                    <v-layout
                        class="mb-2 stat-box__container justify-center"
                        row
                        wrap>
                        <stat-box
                            :title="$t('producerDashboard.salesAmountOwn')"
                            :tooltip="$t('producerDashboard.salesAmountOwnTooltip')"
                            :loading="salesStatsLoading || filtersLoading"
                            :value="salesStats.amount"
                            :change="salesStats.amountChange"
                            :allow-reload="!!salesStatsError"
                            value-type="currency"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.salesAmountOtherProducts')"
                            :tooltip="$t('producerDashboard.salesAmountOtherProductsTooltip')"
                            :loading="salesStatsLoading || filtersLoading"
                            :value="salesStats.amountOther"
                            :change="salesStats.amountOtherChange"
                            :allow-reload="!!salesStatsError"
                            value-type="currency"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.salesAmountUnknownProducts')"
                            :tooltip="$t('producerDashboard.salesAmountUnknownProductsTooltip')"
                            :loading="salesStatsLoading || filtersLoading"
                            :value="salesStats.amountUnknown"
                            :change="salesStats.amountUnknownChange"
                            :allow-reload="!!salesStatsError"
                            value-type="currency"
                            @reload="redoSalesStats"
                            size="small"
                        />
                    </v-layout>

                </v-layout>
            </div>
        </v-card-title>

    </div>
</template>

<script>
import { sortBy } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import spaceService from '@/services/spaceService';
import numberService from '@/services/numberService';
import sourcesFilterMixin from '@/mixins/sourcesFilterMixin';
import SelectAll from '@/components/common/SelectAll.vue';
import FormSourcesPlaceholder from '@/components/common/FormSourcesPlaceholder.vue';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import StatBox from '@/components/common/StatBox.vue';

export default {
    name: 'SalesOverview',
    components: {
        SelectAll,
        FormSourcesPlaceholder,
        InfoTooltip,
        StatBox,
    },
    mixins: [
        sourcesFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            spaceService,
            numberService,
            clicksStatsError: null,
            salesStatsError: null,
            ordersStatsError: null,
            impressionsStatsError: null,
            clicksStats: {},
            salesStats: {},
            ordersStats: {},
            impressionsStats: {},
            clicksStatsAllSources: {},
            ordersStatsAllSources: {},
            impressionsStatsAllSources: {},
        };
    },
    computed: {
        hasPermissions() {
            return spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS);
        },
        filtersLoading() {
            return this.uniqueSourcesLoading;
        },
        loading() {
            return this.filtersLoading;
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            clicksStatsLoading: state => state.dashboard.loading.clicksStats,
            salesStatsLoading: state => state.dashboard.loading.salesStats,
            ordersStatsLoading: state => state.dashboard.loading.ordersStats,
            impressionsStatsLoading: state => state.dashboard.loading.impressionsStats,
        }),
        ...mapGetters({
            getterDashboardClicksStats: 'dashboard/getDashboardClicksStats',
            getterDashboardSalesStats: 'dashboard/getDashboardSalesStats',
            getterDashboardOrdersStats: 'dashboard/getDashboardOrdersStats',
            getterDashboardImpressionsStats: 'dashboard/getDashboardImpressionsStats',
            getterDashboardCTRStats: 'dashboard/getDashboardCTRStats',
            getterDashboardCRStats: 'dashboard/getDashboardCRStats',
        }),
    },
    watch: {
        // sourcesOptions: {
        //     immediate: true,
        //     handler: 'setDefaultSources',
        // },
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        filtersLoading(loading) {
            if (!loading) this.reset();
        },
    },
    methods: {
        reset() {
            // this.setDefaultSources();
            this.getData();
        },
        getData() {
            if (this.filtersLoading) return;
            this.getClicksStats();
            this.getSalesStats();
            this.getOrdersStats();
            this.getImpressionsStats();
        },
        redo() {
            this.getData(true);
        },
        async getClicksStats(force = false) {
            if (this.filtersLoading) return;
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.clicksStatsError = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchClicksStats(params);
                this.clicksStats = this.getterDashboardClicksStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};
                this.ctrStats = this.getterDashboardCTRStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};
                this.crStats = this.getterDashboardCRStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};

                /* ALL sources */
                if (this.hasPermissions) {
                    if (this.sourcesOptions.length) params.sources = sortBy(this.sourcesOptions).join(',');
                    await this.fetchClicksStats(params);
                    this.clicksStatsAllSources = this.getterDashboardClicksStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};
                }
            } catch (e) {
                this.clicksStatsError = e.message;
            }
        },
        async getSalesStats(force = false) {
            if (this.filtersLoading) return;
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.salesStatsError = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                await this.fetchSalesStats(params);
                this.salesStats = this.getterDashboardSalesStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources) || {};
            } catch (e) {
                this.salesStatsError = e.message;
            }
        },
        async getOrdersStats(force = false) {
            if (this.filtersLoading) return;
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.ordersStatsError = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                await this.fetchOrdersStats(params);
                this.ordersStats = this.getterDashboardOrdersStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources) || {};
                this.crStats = this.getterDashboardCRStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};

                /* ALL sources */
                if (this.hasPermissions) {
                    if (this.sourcesOptions.length) params.sources = sortBy(this.sourcesOptions).join(',');
                    await this.fetchOrdersStats(params);
                    this.ordersStatsAllSources = this.getterDashboardOrdersStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources) || {};
                }
            } catch (e) {
                this.ordersStatsError = e.message;
            }
        },
        async getImpressionsStats(force = false) {
            if (this.filtersLoading) return;
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.impressionsStatsError = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchImpressionsStats(params);
                this.impressionsStats = this.getterDashboardImpressionsStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};
                this.ctrStats = this.getterDashboardCTRStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};

                /* ALL sources */
                if (this.hasPermissions) {
                    if (this.sourcesOptions.length) params.sources = sortBy(this.sourcesOptions).join(',');
                    await this.fetchImpressionsStats(params);
                    this.impressionsStatsAllSources = this.getterDashboardImpressionsStats(params.startDate, params.endDate, params.compareStartDate, params.compareEndDate, params.sources, params.group) || {};
                }
            } catch (e) {
                this.impressionsStatsError = e.message;
            }
        },
        redoImpressionsStats() {
            this.getImpressionsStats(true);
        },
        redoClicksStats() {
            this.getClicksStats(true);
        },
        redoSalesStats() {
            this.getSalesStats(true);
        },
        redoOrdersStats() {
            this.getOrdersStats(true);
        },
        ...mapActions({
            fetchClicksStats: 'dashboard/fetchClicksStats',
            fetchSalesStats: 'dashboard/fetchSalesStats',
            fetchOrdersStats: 'dashboard/fetchOrdersStats',
            fetchImpressionsStats: 'dashboard/fetchImpressionsStats',
        }),
    },
    created() {
        this.getData();
    },
};
</script>

<style lang="scss" scoped>
    .stat-box__container {
        display: flex;
        align-items: center;

        margin-left: 0px;
        margin-right: 0px;

        ::v-deep {
            .stat-box {
                border-radius: 3px;
                box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
                max-width: 300px;
                align-self: stretch;
            }
        }
    }
    .funnel-container {
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        gap: 5px;

        .funnel-box {
            width: 25%;
            height: 100%;
            position: relative;

            .funnel-content {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;

                .title {
                    margin-bottom: 5px;
                    display: flex;
                }
            }

            .funnel-bg {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                display: flex;
                align-items: center;
            }

            &.funnel-box-1 {
                width: 286.68px;
            }

            &.funnel-box-2 {
                width: 228.89px;
            }

            &.funnel-box-3 {
                width: 182.65px;
            }

            &.funnel-box-4 {
                width: 148px;
            }
        }
    }
</style>
