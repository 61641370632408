import demoService from '@/services/demoService';
import reportsCategoryServiceDemo from '@/services/demo-services/reportsCategoryServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const reportsCategoryService = {
    async fetchAmountReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-sales-amount-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchOrdersReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-sales-orders-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchQuantityReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-sales-quantity-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchImpressionsReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-impressions-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchClicksReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-clicks-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchImpressionsHeatmap(spaceId, startDate, endDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-impressions-heatmap`,
                {
                    startDate,
                    endDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchClicksHeatmap(spaceId, startDate, endDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-clicks-heatmap`,
                {
                    startDate,
                    endDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchQuantityHeatmap(spaceId, startDate, endDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-sales-quantity-heatmap`,
                {
                    startDate,
                    endDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAmountHeatmap(spaceId, startDate, endDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-sales-amount-heatmap`,
                {
                    startDate,
                    endDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/category-reports`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/impressions-category-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/clicks-category-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/amount-category-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/quantity-category-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    productMainCategory(categories) {
        const firstCategoryBranch = categories?.[0];
        return firstCategoryBranch?.length ? firstCategoryBranch[firstCategoryBranch.length - 1].name : '';
    },

    productCategoriesToTree(categories) {
        return categories?.[0].reduceRight((tree, category) => {
            const treeItem = {
                id: `${category.lvl}-${category.name}`,
                name: category.name,
            };
            if (categories.length === 0) {
                return [treeItem];
            }
            if (tree) {
                treeItem.children = tree;
            }
            return [treeItem];
        }, null);
    },
};

demoService.demoProxy(reportsCategoryService, reportsCategoryServiceDemo, 'reportsCategoryService');
export default reportsCategoryService;
