<template>
    <v-dialog v-model="isOpen">
        <v-system-bar dark>
            <v-icon @click="close()" small class="ml-auto">fa-times</v-icon>
        </v-system-bar>
        <NoAccessContactForm :permission="permission" @sent="close()" />
    </v-dialog>
</template>

<script>
import NoAccessContactForm from '@/components/common/NoAccessContactForm.vue';

export default {
    name: 'NoAccessContactFormModal',
    components: {
        NoAccessContactForm,
    },
    props: {
        permission: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        },
    },
    created() {
        this.$root.$on('open-no-access-contact-form', this.open);
    },
    beforeDestroy() {
        this.$root.$off('open-no-access-contact-form', this.open);
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-dialog {
            width: auto;
        }
    }
</style>
