<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <v-layout class="mb-4" row wrap align-center justify-space-between>
                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                    {{ $t('productsCartAnalysis.title') }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error || !items || items.length === 0"
                                @click="exportData"
                                flat
                                icon>
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                </h3>
                <v-layout wrap align-center justify-end>
                    <v-flex shrink>
                        <v-switch
                            v-model="showComparePreviousPeriod"
                            :label="$t('dateRangePicker.comparePrevRange')"
                            :disabled="loading" />
                    </v-flex>

                    <v-flex shrink>
                        <v-select
                            class="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="conversion"
                            @change="handleConversionChange"
                            :items="conversionOptions"
                            :disabled="loading"
                            placeholder=" "
                            :label="$t('productsCartAnalysis.conversion')"
                            outline
                            hide-details
                            dense />
                    </v-flex>
                    <v-flex shrink>
                        <select-all
                            v-if="region !== 'PL'"
                            class-name="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="campaign"
                            :items="campaignOptions"
                            :disabled="loading"
                            :label="$t('productsCartAnalysis.shop')"
                            :all-label="$t('productsCartAnalysis.allShops')"
                            require-selection
                            @blur="handleCampaignBlur" />
                    </v-flex>
                    <v-flex shrink>
                        <v-select
                            class="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="brand"
                            @change="handleBrandChange"
                            :items="brandOptions"
                            :disabled="loading"
                            placeholder=" "
                            :label="$t('productsCartAnalysis.brand')"
                            outline
                            hide-details
                            dense />
                    </v-flex>

                    <v-btn v-if="error" class="mb-3" @click="redo" flat icon>
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>

            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert :value="error" dismissible type="error" transition="scale-transition">
                {{ error }}
            </v-alert>
            <div v-if="!loading && !error">
                <v-layout justify-end>
                    <v-text-field
                        class="mb-3 search"
                        v-model="search"
                        :label="$t('productsCartAnalysis.search')"
                        placeholder=" "
                        outline
                        hide-details
                        clearable
                        clear-icon="fa-times"
                        @keyup="handleSearch"
                        @click:clear="handleSearch" />
                </v-layout>
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="headers"
                    :items="items"
                    :no-data-text="$t('productsCartAnalysis.noResults')"
                    :no-results-text="$t('productsCartAnalysis.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :pagination.sync="pagination"
                    :rows-per-page-items="rowsPerPageItems"
                    disable-initial-sort>
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="(header, index) in props.headers"
                                :key="header.text"
                                :class="[
                                    'column sortable',
                                    header.value === pagination.sortBy ? 'active' : '',
                                    header.align === 'left' ? 'text-xs-left' : '',
                                    header.align === 'right' ? 'text-xs-right' : '',
                                ]"
                                @click="changeSort(header.value)">
                                <v-layout align-center>
                                    <v-flex>
                                        {{ header.text }}
                                        <div>
                                            <span v-if="index === 0 || index === 1 || index === 2">&nbsp;</span>
                                            <span v-if="index === 3">{{ headerSummary.quantity }}</span>
                                            <span v-if="index === 4">{{ headerSummary.amount }}</span>
                                        </div>
                                    </v-flex>
                                    <sort-order :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', {
                            pageStart: props.pageStart, pageStop: props.pageStop, itemsLength:
                                props.itemsLength
                        }) }}
                    </template>

                    <template v-slot:items="props">
                        <td>
                            {{ props.item.name }}
                        </td>
                        <td>
                            {{ props.item.sku }}
                        </td>
                        <td class="text-xs-left">
                            {{ props.item.brand }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.quantityFormatted }}
                            <change-label :change="props.item.quantityChange" v-if="showComparePreviousPeriod" />
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.amountFormatted }}
                            <change-label :change="props.item.amountChange" v-if="showComparePreviousPeriod" />
                        </td>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce, deburr } from 'lodash';
import numberService from '../../../../services/numberService';
import exportService from '../../../../services/exportService';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import ChangeLabel from '../../../common/ChangeLabel.vue';
import SortOrder from '../../../common/SortOrder.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';

export default {
    name: 'products-cart-analysis',
    components: { SelectAll, SortOrder, ChangeLabel },
    mixins: [campaignFilterMixin],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            region: process.env.VUE_APP_REGION_MODE,
            showComparePreviousPeriod: false,
            headers: [
                {
                    text: this.$t('productsCartAnalysis.productName'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('productsCartAnalysis.sku'),
                    align: 'left',
                    sortable: true,
                    value: 'sku',
                },
                {
                    text: this.$t('productsCartAnalysis.brand'),
                    align: 'left',
                    sortable: true,
                    value: 'brand',
                },
                {
                    text: this.$t('productsCartAnalysis.quantity'),
                    align: 'right',
                    sortable: true,
                    value: 'quantity',
                },
                {
                    text: this.$t('productsCartAnalysis.amount'),
                    align: 'right',
                    sortable: true,
                    value: 'amount',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
            },
            conversionOptions: [
                {
                    value: 'all',
                    text: this.$t('productsCartAnalysis.allConversion'),
                },
                {
                    value: 24,
                    text: this.$t('productsCartAnalysis.nConversion', { hours: 24 }),
                },
            ],
            data: null,
            items: [],
            conversion: 'all',
            brand: 'all',
            search: null,
            error: null,
        };
    },
    computed: {
        loading() {
            return this.cartAnalysisLoading;
        },
        headerSummary() {
            const summary = this.items.reduce((acc, cur) => ({
                quantity: acc.quantity + cur.quantity,
                amount: acc.amount + cur.amount,
            }), {
                quantity: 0,
                amount: 0,
            });

            return {
                quantity: numberService.formatNumber(summary.quantity),
                amount: numberService.formatCurrency(summary.amount),
            };
        },
        brandOptions() {
            const allBrandsOption = {
                value: 'all',
                text: this.$t('productsCartAnalysis.allBrands'),
            };

            const filteredBrandOptions = this.getterBrandOptions.filter(brand => typeof brand.value === 'string' && typeof brand.text === 'string');

            return [allBrandsOption, ...filteredBrandOptions];
        },
        ...mapState({
            cartAnalysisLoading: state => state.reportsProducts.loading.cartAnalysis,
        }),
        ...mapGetters({
            getterBrandOptions: 'reportsProducts/getBrandOptions',
            getterCartAnalysis: 'reportsProducts/getCartAnalysis',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.setDefaultCampaign(['PL']);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCampaign(['PL']);
            this.conversion = 'all';
            this.brand = 'all';
            this.search = null;
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = !this.search && this.conversion === 'all' && this.brand === 'all' && this.isDefaultCampaign(['PL']);
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getCartAnalysis(force);
        },
        handleCampaignBlur() {
            this.getData();
        },
        handleConversionChange() {
            this.getData();
        },
        handleBrandChange() {
            this.setItems();
        },
        redo() {
            this.getData(true);
        },
        async getCartAnalysis(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.error = null;
                await this.fetchCartAnalysis({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    conversion: this.conversion,
                    campaigns: this.campaign,
                });
                let data = this.getterCartAnalysis(startDate, endDate, compareStartDate, compareEndDate, this.conversion, this.campaign);
                if (data) {
                    data = data.map(item => ({
                        ...item,
                        quantityFormatted: numberService.formatNumber(item.quantity),
                        amountFormatted: numberService.formatCurrency(item.amount),
                    }));
                }
                this.data = data;
                this.setItems();
            } catch (e) {
                console.error(e);
                this.error = e.message;
            }
        },
        handleSearch: debounce(function handleSearch() {
            this.setItems();
        }, 500),
        setItems() {
            this.emitFilterDefaultChange();
            let { data } = this;
            if (!data) {
                this.items = [];
                return;
            }

            if (this.brand !== 'all') {
                data = data.filter(item => item.brand === this.brand);
            }

            if (this.search) {
                const search = deburr(this.search).toLowerCase();
                data = data.filter(item => deburr(item.name).toLowerCase().includes(search) || deburr(item.sku).toLowerCase().includes(search));
            }

            this.items = data;
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('dataFor'))}"`);
            header.push(`"${exportService.parseText(this.$t('exportingOn'))}"`);
            header.push(`"${exportService.parseText(this.$t('selectedStores'))}"`);

            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.productName'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.sku'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.brand'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.quantity'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.quantity'))} (% ${exportService.parseText(this.$t('change'))})"`);
            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.amount'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('productsCartAnalysis.amount'))} (% ${exportService.parseText(this.$t('change'))})"`);

            const rows = [];
            this.items.forEach((item, index) => {
                const row = [];
                if (index === 0) {
                    row.push(`"${exportService.parseText(this.dateFilter.startDate)} - ${exportService.parseText(this.dateFilter.endDate)}"`);
                    row.push(`"${exportService.parseText(new Date().toLocaleDateString())}"`);
                    row.push(`"${exportService.parseText(this.campaign.map(id => this.getCampaignNameFromId(id)).join(', '))}"`);
                } else {
                    row.push('""');
                    row.push('""');
                    row.push('""');
                }
                row.push(`"${exportService.parseText(item.name)}"`);
                row.push(`"${exportService.parseText(item.sku)}"`);
                row.push(`"${exportService.parseText(item.brand)}"`);
                row.push(`"${numberService.formatNumber(item.quantity, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.quantityChange, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.amount, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.amountChange, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('productsCartAnalysis.title'));
        },
        ...mapActions({
            fetchCartAnalysis: 'reportsProducts/fetchCartAnalysis',
        }),
    },
};
</script>

<style lang="scss" scoped>
.search {
    max-width: 250px;
}
</style>
