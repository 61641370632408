<template>
    <div class="splash-container">
        <img src="../../assets/logo.png" alt="100shoppers" class="logo mb-3" />
        <v-progress-linear indeterminate color="primary" class="progress" />
    </div>
</template>

<script>
export default {
    name: 'splash-screen',
};
</script>

<style lang="scss" scoped>
    .splash-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .logo {
        max-width: 90%;
        max-height: 40px;
    }

    .progress {
        width: 200px;
        max-width: 90%;
    }
</style>
