import { sortBy } from 'lodash';
import dashboardService from '../../services/dashboardService';
import utilsService from '../../services/utilsService';
import numberService from '../../services/numberService';
import dateService from '../../services/dateService';
import {
    CLEAN_DASHBOARD,
    FETCH_DASHBOARD_CLICKS_STATS_FAILURE,
    FETCH_DASHBOARD_CLICKS_STATS_REQUEST,
    FETCH_DASHBOARD_CLICKS_STATS_SUCCESS,
    FETCH_DASHBOARD_SALES_STATS_FAILURE,
    FETCH_DASHBOARD_SALES_STATS_REQUEST,
    FETCH_DASHBOARD_SALES_STATS_SUCCESS,
    FETCH_DASHBOARD_OWN_SALES_STATS_FAILURE,
    FETCH_DASHBOARD_OWN_SALES_STATS_REQUEST,
    FETCH_DASHBOARD_OWN_SALES_STATS_SUCCESS,
    FETCH_DASHBOARD_IMPRESSIONS_STATS_FAILURE,
    FETCH_DASHBOARD_IMPRESSIONS_STATS_REQUEST,
    FETCH_DASHBOARD_IMPRESSIONS_STATS_SUCCESS,
    FETCH_DASHBOARD_SALES_CHART_FAILURE,
    FETCH_DASHBOARD_SALES_CHART_REQUEST,
    FETCH_DASHBOARD_SALES_CHART_SUCCESS,
    FETCH_DASHBOARD_TRAFFIC_CHART_FAILURE,
    FETCH_DASHBOARD_TRAFFIC_CHART_REQUEST,
    FETCH_DASHBOARD_TRAFFIC_CHART_SUCCESS,
    FETCH_DASHBOARD_ORDERS_STATS_REQUEST,
    FETCH_DASHBOARD_ORDERS_STATS_FAILURE,
    FETCH_DASHBOARD_ORDERS_STATS_SUCCESS,
    FETCH_DASHBOARD_OWN_ORDERS_STATS_REQUEST,
    FETCH_DASHBOARD_OWN_ORDERS_STATS_FAILURE,
    FETCH_DASHBOARD_OWN_ORDERS_STATS_SUCCESS,
} from '../mutationTypes';

const initialState = {
    clicksStats: {},
    salesStats: {},
    ownSalesStats: {},
    ordersStats: {},
    ownOrdersStats: {},
    impressionsStats: {},
    salesChart: {},
    trafficChart: {},
    loading: {
        clicksStats: false,
        salesStats: false,
        ownSalesStats: false,
        ordersStats: false,
        ownOrdersStats: false,
        impressionsStats: false,
        salesChart: false,
        trafficChart: false,
    },
};

const getters = {
    getDashboardClicksStats: state => (startDate, endDate, compareStartDate, compareEndDate, sources, group) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        return state.clicksStats[dateRangeKey];
    },

    getDashboardSalesStats: state => (startDate, endDate, compareStartDate, compareEndDate, sources) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources);
        return state.salesStats[dateRangeKey];
    },

    getDashboardOwnSalesStats: state => (startDate, endDate, compareStartDate, compareEndDate, sources, group) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        return state.ownSalesStats[dateRangeKey];
    },

    getDashboardOrdersStats: state => (startDate, endDate, compareStartDate, compareEndDate, sources) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources);
        return state.ordersStats[dateRangeKey];
    },

    getDashboardOwnOrdersStats: state => (startDate, endDate, compareStartDate, compareEndDate, sources, group) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        return state.ownOrdersStats[dateRangeKey];
    },

    getDashboardImpressionsStats: state => (startDate, endDate, compareStartDate, compareEndDate, sources, group) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        return state.impressionsStats[dateRangeKey];
    },

    // eslint-disable-next-line no-shadow
    getDashboardCTRStats: (state, getters) => (startDate, endDate, compareStartDate, compareEndDate, sources, group) => {
        const clicksStats = getters.getDashboardClicksStats(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        const impressionsStats = getters.getDashboardImpressionsStats(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        if (!clicksStats || !impressionsStats) {
            return null;
        }
        const ctr = impressionsStats.impressions ? clicksStats.clicks / impressionsStats.impressions * 100 : 0;
        const ctrCompare = impressionsStats.impressionsCompare ? clicksStats.clicksCompare / impressionsStats.impressionsCompare * 100 : 0;
        const ctrChange = numberService.calculateChangePercentage(ctr, ctrCompare);
        return {
            ctr: Number.isNaN(ctr) ? null : ctr,
            ctrChange,
        };
    },

    // eslint-disable-next-line no-shadow
    getDashboardCRStats: (state, getters) => (startDate, endDate, compareStartDate, compareEndDate, sources, group) => {
        const clicksStats = getters.getDashboardClicksStats(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        const ordersStats = getters.getDashboardOrdersStats(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        if (!clicksStats || !ordersStats) {
            return null;
        }
        const cr = clicksStats.clicks ? ordersStats.orders / clicksStats.clicks * 100 : 0;
        const crCompare = clicksStats.clicksCompare ? ordersStats.ordersCompare / clicksStats.clicksCompare * 100 : 0;
        const crChange = numberService.calculateChangePercentage(cr, crCompare);
        return {
            cr: Number.isNaN(cr) ? null : cr,
            crChange,
        };
    },

    getDashboardSalesChart: state => (startDate, endDate, campaign) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
        return state.salesChart[dateRangeKey];
    },

    getDashboardTrafficChart: state => (startDate, endDate, campaign) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
        return state.trafficChart[dateRangeKey];
    },
};

const actions = {
    cleanDashboard({ commit }) {
        commit(CLEAN_DASHBOARD);
    },

    async fetchClicksStats({
        commit, state, rootState,
    }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
            if (!force && state.clicksStats && state.clicksStats[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_CLICKS_STATS_REQUEST);
            const clicksStats = await dashboardService.fetchDashboardClicksStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group);

            commit(FETCH_DASHBOARD_CLICKS_STATS_SUCCESS, {
                clicksStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_CLICKS_STATS_FAILURE);
            throw e;
        }
    },

    async fetchSalesStats({
        commit, state, rootState,
    }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, sources,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources);
            if (!force && state.salesStats && state.salesStats[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_SALES_STATS_REQUEST);
            const salesStats = await dashboardService.fetchDashboardSalesStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources);

            commit(FETCH_DASHBOARD_SALES_STATS_SUCCESS, {
                salesStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                sources,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_SALES_STATS_FAILURE);
            throw e;
        }
    },

    async fetchOwnSalesStats({
        commit, state, rootState,
    }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
            if (!force && state.ownSalesStats && state.ownSalesStats[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_OWN_SALES_STATS_REQUEST);
            const ownSalesStats = await dashboardService.fetchDashboardOwnSalesStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group);

            commit(FETCH_DASHBOARD_OWN_SALES_STATS_SUCCESS, {
                ownSalesStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_OWN_SALES_STATS_FAILURE);
            throw e;
        }
    },

    async fetchOrdersStats({
        commit, state, rootState,
    }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, sources,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources);
            if (!force && state.ordersStats && state.ordersStats[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_ORDERS_STATS_REQUEST);
            const ordersStats = await dashboardService.fetchDashboardOrdersStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources);

            commit(FETCH_DASHBOARD_ORDERS_STATS_SUCCESS, {
                ordersStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                sources,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_ORDERS_STATS_FAILURE);
            throw e;
        }
    },

    async fetchOwnOrdersStats({
        commit, state, rootState,
    }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
            if (!force && state.ownOrdersStats && state.ownOrdersStats[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_OWN_ORDERS_STATS_REQUEST);
            const ownOrdersStats = await dashboardService.fetchDashboardOwnOrdersStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group);

            commit(FETCH_DASHBOARD_OWN_ORDERS_STATS_SUCCESS, {
                ownOrdersStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_OWN_ORDERS_STATS_FAILURE);
            throw e;
        }
    },

    async fetchImpressionsStats({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
            if (!force && state.impressionsStats && state.impressionsStats[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_IMPRESSIONS_STATS_REQUEST);
            const impressionsStats = await dashboardService.fetchDashboardImpressionsStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group);

            commit(FETCH_DASHBOARD_IMPRESSIONS_STATS_SUCCESS, {
                impressionsStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_IMPRESSIONS_STATS_FAILURE);
            throw e;
        }
    },

    async fetchSalesChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
            if (!force && state.salesChart && state.salesChart[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_SALES_CHART_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            let salesChartData = await dashboardService.fetchDashboardSalesChart(spaceId, startDate, endDate, reqCampaign);
            const missingDates = dateService.findMissingDates(salesChartData, startDate, endDate);
            salesChartData = utilsService.fillArray(salesChartData, missingDates, 'date', [{ key: 'amount', value: 0 }, { key: 'quantity', value: 0 }]);
            salesChartData = sortBy(salesChartData, ['date']);

            commit(FETCH_DASHBOARD_SALES_CHART_SUCCESS, {
                salesChartData,
                startDate,
                endDate,
                campaign,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_SALES_CHART_FAILURE);
            throw e;
        }
    },

    async fetchTrafficChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
            if (!force && state.trafficChart && state.trafficChart[dateRangeKey]) {
                return;
            }

            commit(FETCH_DASHBOARD_TRAFFIC_CHART_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            let trafficChartData = await dashboardService.fetchDashboardTrafficChart(spaceId, startDate, endDate, reqCampaign);
            const missingDates = dateService.findMissingDates(trafficChartData, startDate, endDate);
            trafficChartData = utilsService.fillArray(trafficChartData, missingDates, 'date', [{ key: 'clicks', value: 0 }, { key: 'impressions', value: 0 }]);
            trafficChartData = sortBy(trafficChartData, ['date']);

            commit(FETCH_DASHBOARD_TRAFFIC_CHART_SUCCESS, {
                trafficChartData,
                startDate,
                endDate,
                campaign,
            });
        } catch (e) {
            commit(FETCH_DASHBOARD_TRAFFIC_CHART_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_DASHBOARD
    // -----------------------------------------
    [CLEAN_DASHBOARD](state) {
        state.clicksStats = {};
        state.salesStats = {};
        state.ordersStats = {};
        state.ownOrdersStats = {};
        state.impressionsStats = {};
        state.salesChart = {};
        state.trafficChart = {};
        state.loading.clicksStats = false;
        state.loading.salesStats = false;
        state.loading.ordersStats = false;
        state.loading.ownOrdersStats = false;
        state.loading.impressionsStats = false;
        state.loading.salesChart = false;
        state.loading.trafficChart = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_CLICKS_STATS
    // -----------------------------------------
    [FETCH_DASHBOARD_CLICKS_STATS_REQUEST](state) {
        state.loading.clicksStats = true;
    },
    [FETCH_DASHBOARD_CLICKS_STATS_SUCCESS](state, payload) {
        const {
            clicksStats, startDate, endDate, compareStartDate, compareEndDate, sources, group,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        const clicks = parseFloat(clicksStats.clicks);
        const clicksCompare = parseFloat(clicksStats.clicks_compare);
        const clicksChange = numberService.calculateChangePercentage(clicks, clicksCompare);

        state.loading.clicksStats = false;
        state.clicksStats = {
            ...state.clicksStats,
            [dateRangeKey]: {
                clicks: Number.isNaN(clicks) ? null : clicks,
                clicksCompare: Number.isNaN(clicksCompare) ? null : clicksCompare,
                clicksChange,
            },
        };
    },
    [FETCH_DASHBOARD_CLICKS_STATS_FAILURE](state) {
        state.loading.clicksStats = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_SALES_STATS
    // -----------------------------------------
    [FETCH_DASHBOARD_SALES_STATS_REQUEST](state) {
        state.loading.salesStats = true;
    },
    [FETCH_DASHBOARD_SALES_STATS_SUCCESS](state, payload) {
        const {
            salesStats, startDate, endDate, compareStartDate, compareEndDate, sources,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources);

        /* quantity */
        const quantity = parseFloat(salesStats.quantity);
        const quantityCompare = parseFloat(salesStats.quantity_compare);
        const quantityChange = numberService.calculateChangePercentage(quantity, quantityCompare);

        /* quantityOther */
        const quantityOther = parseFloat(salesStats.quantity_other);
        const quantityOtherCompare = parseFloat(salesStats.quantity_other_compare);
        const quantityOtherChange = numberService.calculateChangePercentage(quantityOther, quantityOtherCompare);

        /* quantityUnknown */
        const quantityUnknown = parseFloat(salesStats.quantity_unknown);
        const quantityUnknownCompare = parseFloat(salesStats.quantity_unknown_compare);
        const quantityUnknownChange = numberService.calculateChangePercentage(quantityUnknown, quantityUnknownCompare);

        /* amount */
        const amount = parseFloat(salesStats.amount);
        const amountCompare = parseFloat(salesStats.amount_compare);
        const amountChange = numberService.calculateChangePercentage(amount, amountCompare);

        /* amountOther */
        const amountOther = parseFloat(salesStats.amount_other);
        const amountOtherCompare = parseFloat(salesStats.amount_other_compare);
        const amountOtherChange = numberService.calculateChangePercentage(amountOther, amountOtherCompare);
        const amountUnknown = parseFloat(salesStats.amount_unknown);
        const amountUnknownCompare = parseFloat(salesStats.amount_unknown_compare);
        const amountUnknownChange = numberService.calculateChangePercentage(amountUnknown, amountUnknownCompare);

        state.loading.salesStats = false;
        state.salesStats = {
            ...state.salesStats,
            [dateRangeKey]: {
                quantity: Number.isNaN(quantity) ? null : quantity,
                quantityCompare: Number.isNaN(quantityCompare) ? null : quantityCompare,
                quantityChange,

                quantityOther: Number.isNaN(quantityOther) ? null : quantityOther,
                quantityOtherCompare: Number.isNaN(quantityOtherCompare) ? null : quantityOtherCompare,
                quantityOtherChange,

                quantityUnknown: Number.isNaN(quantityUnknown) ? null : quantityUnknown,
                quantityUnknownCompare: Number.isNaN(quantityUnknownCompare) ? null : quantityUnknownCompare,
                quantityUnknownChange,

                amount: Number.isNaN(amount) ? null : amount,
                amountCompare: Number.isNaN(amountCompare) ? null : amountCompare,
                amountChange,

                amountOther: Number.isNaN(amountOther) ? null : amountOther,
                amountOtherCompare: Number.isNaN(amountOtherCompare) ? null : amountOtherCompare,
                amountOtherChange,
                amountUnknown: Number.isNaN(amountUnknown) ? null : amountUnknown,
                amountUnknownCompare: Number.isNaN(amountUnknownCompare) ? null : amountUnknownCompare,
                amountUnknownChange,
            },
        };
    },
    [FETCH_DASHBOARD_SALES_STATS_FAILURE](state) {
        state.loading.salesStats = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_OWN_SALES_STATS
    // -----------------------------------------
    [FETCH_DASHBOARD_OWN_SALES_STATS_REQUEST](state) {
        state.loading.ownSalesStats = true;
    },
    [FETCH_DASHBOARD_OWN_SALES_STATS_SUCCESS](state, payload) {
        const {
            ownSalesStats, startDate, endDate, compareStartDate, compareEndDate, sources, group,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        const quantity = parseFloat(ownSalesStats.quantity);
        const quantityCompare = parseFloat(ownSalesStats.quantity_compare);
        const quantityChange = numberService.calculateChangePercentage(quantity, quantityCompare);
        const amount = parseFloat(ownSalesStats.amount);
        const amountCompare = parseFloat(ownSalesStats.amount_compare);
        const amountChange = numberService.calculateChangePercentage(amount, amountCompare);
        const amountOther = parseFloat(ownSalesStats.amount_other);
        const amountOtherCompare = parseFloat(ownSalesStats.amount_other_compare);
        const amountOtherChange = numberService.calculateChangePercentage(amountOther, amountOtherCompare);

        state.loading.ownSalesStats = false;
        state.ownSalesStats = {
            ...state.ownSalesStats,
            [dateRangeKey]: {
                quantity: Number.isNaN(quantity) ? null : quantity,
                quantityCompare: Number.isNaN(quantityCompare) ? null : quantityCompare,
                quantityChange,
                amount: Number.isNaN(amount) ? null : amount,
                amountCompare: Number.isNaN(amountCompare) ? null : amountCompare,
                amountChange,
                amountOther: Number.isNaN(amountOther) ? null : amountOther,
                amountOtherCompare: Number.isNaN(amountOtherCompare) ? null : amountOtherCompare,
                amountOtherChange,
            },
        };
    },
    [FETCH_DASHBOARD_SALES_STATS_FAILURE](state) {
        state.loading.salesStats = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_ORDERS_STATS
    // -----------------------------------------
    [FETCH_DASHBOARD_ORDERS_STATS_REQUEST](state) {
        state.loading.ordersStats = true;
    },
    [FETCH_DASHBOARD_ORDERS_STATS_SUCCESS](state, payload) {
        const {
            ordersStats, startDate, endDate, compareStartDate, compareEndDate, sources,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources);
        const orders = parseFloat(ordersStats.orders);
        const ordersCompare = parseFloat(ordersStats.orders_compare);
        const ordersChange = numberService.calculateChangePercentage(orders, ordersCompare);

        state.loading.ordersStats = false;
        state.ordersStats = {
            ...state.ordersStats,
            [dateRangeKey]: {
                orders: Number.isNaN(orders) ? null : orders,
                ordersCompare: Number.isNaN(ordersCompare) ? null : ordersCompare,
                ordersChange,
            },
        };
    },
    [FETCH_DASHBOARD_ORDERS_STATS_FAILURE](state) {
        state.loading.ordersStats = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_OWN_ORDERS_STATS
    // -----------------------------------------
    [FETCH_DASHBOARD_OWN_ORDERS_STATS_REQUEST](state) {
        state.loading.ownOrdersStats = true;
    },
    [FETCH_DASHBOARD_OWN_ORDERS_STATS_SUCCESS](state, payload) {
        const {
            ownOrdersStats, startDate, endDate, compareStartDate, compareEndDate, sources, group,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        const orders = parseFloat(ownOrdersStats.orders);
        const ordersCompare = parseFloat(ownOrdersStats.orders_compare);
        const ordersChange = numberService.calculateChangePercentage(orders, ordersCompare);

        state.loading.ownOrdersStats = false;
        state.ownOrdersStats = {
            ...state.ownOrdersStats,
            [dateRangeKey]: {
                orders: Number.isNaN(orders) ? null : orders,
                ordersCompare: Number.isNaN(ordersCompare) ? null : ordersCompare,
                ordersChange,
            },
        };
    },
    [FETCH_DASHBOARD_OWN_ORDERS_STATS_FAILURE](state) {
        state.loading.ownOrdersStats = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_IMPRESSIONS_STATS
    // -----------------------------------------
    [FETCH_DASHBOARD_IMPRESSIONS_STATS_REQUEST](state) {
        state.loading.impressionsStats = true;
    },
    [FETCH_DASHBOARD_IMPRESSIONS_STATS_SUCCESS](state, payload) {
        const {
            impressionsStats, startDate, endDate, compareStartDate, compareEndDate, sources, group,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, sources, group);
        const impressions = parseFloat(impressionsStats.impressions);
        const impressionsCompare = parseFloat(impressionsStats.impressions_compare);
        const impressionsChange = numberService.calculateChangePercentage(impressions, impressionsCompare);
        const impressionsOffer = parseFloat(impressionsStats.impressions_offer);
        const impressionsOfferCompare = parseFloat(impressionsStats.impressions_offer_compare);
        const impressionsOfferChange = numberService.calculateChangePercentage(impressionsOffer, impressionsOfferCompare);

        state.loading.impressionsStats = false;
        state.impressionsStats = {
            ...state.impressionsStats,
            [dateRangeKey]: {
                impressions: Number.isNaN(impressions) ? null : impressions,
                impressionsCompare: Number.isNaN(impressionsCompare) ? null : impressionsCompare,
                impressionsChange,
                impressionsOffer: Number.isNaN(impressionsOffer) ? null : impressionsOffer,
                impressionsOfferCompare: Number.isNaN(impressionsOfferCompare) ? null : impressionsOfferCompare,
                impressionsOfferChange,
            },
        };
    },
    [FETCH_DASHBOARD_IMPRESSIONS_STATS_FAILURE](state) {
        state.loading.impressionsStats = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_SALES_CHART
    // -----------------------------------------
    [FETCH_DASHBOARD_SALES_CHART_REQUEST](state) {
        state.loading.salesChart = true;
    },
    [FETCH_DASHBOARD_SALES_CHART_SUCCESS](state, payload) {
        const {
            salesChartData, startDate, endDate, campaign,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);

        state.loading.salesChart = false;
        state.salesChart = {
            ...state.salesChart,
            [dateRangeKey]: salesChartData,
        };
    },
    [FETCH_DASHBOARD_SALES_CHART_FAILURE](state) {
        state.loading.salesChart = false;
    },

    // -----------------------------------------
    // FETCH_DASHBOARD_TRAFFIC_CHART
    // -----------------------------------------
    [FETCH_DASHBOARD_TRAFFIC_CHART_REQUEST](state) {
        state.loading.trafficChart = true;
    },
    [FETCH_DASHBOARD_TRAFFIC_CHART_SUCCESS](state, payload) {
        const {
            trafficChartData, startDate, endDate, campaign,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);

        state.loading.trafficChart = false;
        state.trafficChart = {
            ...state.trafficChart,
            [dateRangeKey]: trafficChartData,
        };
    },
    [FETCH_DASHBOARD_TRAFFIC_CHART_FAILURE](state) {
        state.loading.trafficChart = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
