<template>
    <v-layout
        class="ma-4"
        :class="{
            'text-xs-left': textPosition === 'left',
            'text-xs-center': textPosition === 'center',
            'text-xs-right': textPosition === 'right',
        }"
        column
    >
        <v-icon v-if="icon" class="mb-3" size="72">{{ icon }}</v-icon>
        <h6 class="title">{{ title }}</h6>
        <p v-if="subtitle" class="body-1 mt-3">{{ subtitle }}</p>
    </v-layout>
</template>

<script>
export default {
    name: 'data-placeholder',
    props: {
        icon: {
            type: String,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
        },
        textPosition: {
            validator: value => ['left', 'center', 'right'].includes(value),
            default: 'center',
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
