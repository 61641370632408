<template>
    <base-nav-layout :homePath="homePath">
        <template #topNavBar>
            <v-toolbar-items>
                <v-menu
                    transition="scale-transition"
                    origin="top right"
                    bottom
                    left>
                    <template #activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon large>fa-user-circle</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-tile v-if="username" disabled>
                            <v-list-tile-title>
                                {{ username }} ({{ fullName }})
                            </v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile :to="{ name: 'producerAccountSettings' }" :active-class="linkActiveClass">
                            <v-list-tile-action>
                                <v-icon small>fa-user</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-title>
                                {{ $t('topNavBar.accountSettings') }}
                            </v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile @click="handleLogout" :active-class="linkActiveClass">
                            <v-list-tile-action>
                                <v-icon small>fa-power-off</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-title>
                                {{ $t('topNavBar.logout') }}
                            </v-list-tile-title>
                        </v-list-tile>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </template>

        <template #sidebar>
            <producer-sidebar />
        </template>

        <router-view />
    </base-nav-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseNavLayout from '../../components/layout/BaseNavLayout.vue';
import ProducerSidebar from '../../components/producer/ProducerSidebar.vue';

export default {
    name: 'producer-main',
    components: {
        BaseNavLayout,
        ProducerSidebar,
    },
    data() {
        return {
            linkActiveClass: 'font-weight-black',
            homePath: { name: 'producer' },
        };
    },
    computed: {
        ...mapState({
            username: state => (state.auth.user ? state.auth.user.username : null),
            fullName: state => (state.auth.user ? state.auth.user.fullName : null),
        }),
    },
    methods: {
        handleLogout() {
            this.logout();
        },
        ...mapActions({
            logout: 'auth/logout',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
