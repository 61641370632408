<template>
    <div :class="containerClassName">
        <v-card-title>
            <v-layout :class="{ 'mb-4': type === 'normal' }" row wrap justify-space-between>
                <h3 v-if="type === 'normal'" class="title font-weight-bold mb-3 d-flex align-center">
                    {{ title }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error || !data || data.length === 0"
                                @click="exportData"
                                flat
                                icon>
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                    <slot name="leftFilters" />
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h3>
                <h4 v-if="type === 'small'" class="subheading font-weight-bold">
                    {{ title }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="exportData"
                                flat
                                icon>
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.export') }}</span>
                    </v-tooltip>
                    <slot name="leftFilters" />
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h4>
                <v-layout wrap align-center justify-end>
                    <slot name="filters" />
                    <v-btn v-if="error" class="mb-3" @click="redo()" flat icon>
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="!loading && data && data.length > 0">
                <ranking-item-graph
                    v-for="(item, index) in items"
                    :has-change="hasChange"
                    :has-total-change="hasTotalChange"
                    :tooltip="index === 0 ? firstItemTooltip : ''"
                    :key="item[idKey]"
                    :name="item[nameKey]"
                    :marked="Boolean(item[markedKey])"
                    :change-data="item.changeData"
                    :change="item[changeKey]"
                    :total="item[totalKey]"
                    :total-type="totalType"
                    :max-total="maxTotal"
                    :show-line-chart="showLineChart"
                    :clickable="item.clickable"
                    :expandable="expandable"
                    :bar-width="barWidth"
                    :additionalValueLabel="item.additionalValueLabel"
                    @select="handleItemSelect"
                >
                    <template #expandableContent>
                        <slot name="expandableContent" :item="item" />
                    </template>
                </ranking-item-graph>
            </div>
            <data-placeholder v-if="!loading && (!data || data.length === 0)" :title="noResults" />
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <v-alert :value="error" dismissible type="error" transition="scale-transition">
                {{ error }}
            </v-alert>
            <slot name="footer" />
        </v-card-text>
    </div>
</template>

<script>
import { maxBy } from 'lodash';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import numberService from '../../services/numberService';
import exportService from '../../services/exportService';
import DataPlaceholder from '../common/DataPlaceholder.vue';
import RankingItemGraph from './RankingItemGraph.vue';

export default {
    name: 'ranking-graph',
    components: {
        DataPlaceholder,
        RankingItemGraph,
        InfoTooltip,
    },
    props: {
        permissionName: {
            type: String,
        },
        type: {
            validator: value => ['small', 'normal'].includes(value),
            default: 'normal',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        noResults: {
            type: String,
            default() {
                return this.$t('noResults');
            },
        },
        title: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        hasChange: {
            type: Boolean,
            default: false,
        },
        hasTotalChange: {
            type: Boolean,
            default: false,
        },
        idKey: {
            type: String,
            required: true,
        },
        nameKey: {
            type: String,
            required: true,
        },
        markedKey: {
            type: String,
            required: false,
        },
        totalKey: {
            type: String,
            required: true,
        },
        totalType: {
            type: String,
            validator: value => ['decimal', 'percent', 'currency'].includes(value),
            default: 'decimal',
        },
        changeKey: {
            type: String,
            // required: true,
        },
        changeDataKey: {
            type: String,
            // required: true,
        },
        changeDataDateKey: {
            type: String,
            // required: true,
        },
        changeDataValueKey: {
            type: String,
            // required: true,
        },
        exportHeader: {
            type: Array,
            default: () => [],
        },
        exportRowsUnshift: {
            type: Array,
            default: () => [],
        },
        showLineChart: {
            type: Boolean,
            default: true,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        barWidth: {
            type: Number,
        },
        tooltip: {
            type: String,
            default: null,
        },
        firstItemTooltip: {
            type: String,
            default: null,
        },
    },
    computed: {
        containerClassName() {
            if (this.type === 'normal') {
                return 'grey lighten-4 mb-4 pa-4';
            }
            if (this.type === 'small') {
                return '';
            }
            return '';
        },
        items() {
            return this.data;
        },
        maxTotal() {
            return maxBy(this.data, this.totalKey)?.[this.totalKey];
        },
    },
    methods: {
        redo() {
            this.$emit('redo');
        },
        exportData() {
            const header = this.exportHeader.map(item => `"${exportService.parseText(item)}"`);

            const rows = this.items.map((item, index) => {
                const row = [
                    ...(index === 0 ? this.exportRowsUnshift : new Array(this.exportRowsUnshift.length).fill('""')),
                    `"${exportService.parseText(item[this.nameKey])}"`,
                    `"${numberService.formatNumber(item[this.totalKey], 2, undefined, true)}"`,
                ];
                if (this.hasTotalChange) {
                    row.push(`"${numberService.formatNumber(item[this.changeKey], 2, undefined, true)}"`);
                }
                return row;
            });
            exportService.exportDataCsv([header, ...rows], this.title);
        },
        handleItemSelect(data) {
            this.$emit('select', data);
        },
    },
};
</script>

<style lang="scss" scoped></style>
