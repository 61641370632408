<!-- eslint-disable linebreak-style -->
<template>
    <v-container fluid fill-height class="pa-0">
        <v-layout>
            <v-flex v-show="!$vuetify.breakpoint.xsOnly" md4 class="col-sidebar">
                <v-container class="d-flex justify-space-between align-center flex-grow-0 flex-shrink-0">
                    <div>
                        <img src="../assets/logo-white.png" alt="100shoppers" class="logo flex-grow-0 flex-shrink-0 " />
                    </div>

                    <div>
                        <language-switch dark class="flex-grow-0" />
                    </div>
                </v-container>

                <v-container>
                    <testimonial-carousel />
                </v-container>
            </v-flex>

            <v-flex fill-height xs="12" md8 class="col-form d-flex justify-center align-center">
                <v-card class="elevation-0 pa-4 " outlined color="transparent">
                    <v-card-text class="text-xs-center ">
                        <v-container>
                            <p class="mb-0">{{ $t('register.subheadline') }}</p>
                            <h1 class="font-weight-bold">
                                {{ $t('register.headline') }} <span class="text-green">{{ $t('register.headlineSpan') }}</span>
                            </h1>
                            <p class="text-gray mt-2">{{ $t('register.label') }}</p>
                        </v-container>
                    </v-card-text>
                    <v-card-text class="text-xs-left d-flex justify-center">
                        <v-form @submit.prevent="handleSubmit" novalidate class="max-w-500">
                            <v-text-field
                                v-model.trim="model.profile.firstName"
                                :error-messages="firstNameErrors"
                                @blur="$v.model.profilefirstName.$touch()"
                                name="firstName"
                                :label="$t('register.firstName')"
                                type="text"
                                required
                                placeholder=" "
                                outline
                            />
                            <v-text-field
                                v-model.trim="model.profile.lastName"
                                :error-messages="lastNameErrors"
                                @blur="$v.model.profile.lastName.$touch()"
                                name="lastName"
                                :label="$t('register.lastName')"
                                type="text"
                                required
                                placeholder=" "
                                outline
                            />
                            <v-text-field
                                v-model.trim="model.user.email"
                                :error-messages="businessEmailErrors"
                                @blur="$v.model.user.email.$touch()"
                                name="email"
                                :label="$t('register.businessEmail')"
                                type="email"
                                required
                                placeholder=" "
                                outline
                            />
                            <v-text-field
                                v-model.trim="model.user.password"
                                :error-messages="passwordErrors"
                                @blur="$v.model.user.password.$touch()"
                                name="password"
                                :label="$t('register.password')"
                                type="password"
                                required
                                placeholder=" "
                                outline
                            />
                            <v-select
                                v-model="model.profile.country"
                                :items="countries"
                                item-text="title"
                                item-value="value"
                                :error-messages="countryErrors"
                                @blur="$v.model.profile.country.$touch()"
                                name="country"
                                :label="$t('register.country')"
                                required
                                outline
                            ></v-select>

                            <v-checkbox
                                v-model="model.terms"
                                name="terms"
                                outline
                                required
                                class="mb-0"
                                :error-messages="termsErrors"
                                @blur="$v.model.terms.$touch()">
                                <template v-slot:label>
                                    <p class="mt-3">
                                        {{ $t('register.iAgreeTo') }}
                                        <a @click.stop :href="$t('register.links.tos')" target="_blank" rel="nofollow">{{ $t('register.termsOfService') }}</a>
                                        {{ $t('register.and') }}
                                        <a @click.stop :href="$t('register.links.privacy')" target="_blank" rel="nofollow">{{ $t('register.privacyPolicy') }}</a>
                                    </p>
                                </template>
                            </v-checkbox>

                            <v-alert :value="registerError" dismissible type="error" transition="scale-transition">
                                {{ registerError }}
                            </v-alert>

                            <div class="mb-3 text-xs-center">
                                <v-btn
                                    type="submit"
                                    color="tertiary"
                                    :loading="loading"
                                    :disabled="loading"
                                    dark
                                    round>
                                    <span class="text-green font-weight-bold ">{{ $t('register.createAccount') }}</span>
                                </v-btn>
                            </div>

                            <div class="divider-container">
                                <v-divider class="divider"></v-divider>
                                <span class="divider-text">OR</span>
                                <v-divider class="divider"></v-divider>
                            </div>

                            <p class="text-xs-center mt-4">
                                {{ $t('register.loginBefore') }}
                                <a href="https://100shoppers.com/login"> {{ $t('register.loginLink') }}</a>
                            </p>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<!-- eslint-disable linebreak-style -->
<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import LanguageSwitch from '../components/common/LanguageSwitch.vue';
import TestimonialCarousel from '../components/common/TestimonialCarousel.vue';
import authService from '../services/authService';

export default {
    name: 'register',
    components: {
        LanguageSwitch,
        TestimonialCarousel,
    },

    validations: {
        model: {
            profile: {
                firstName: { required },
                lastName: { required },
                country: { required },
            },
            user: {
                email: { required, email },
                password: { required, minLength: minLength(8) },
            },
            terms: { required: value => value === true },
        },
    },
    data() {
        return {
            registerError: null,
            loading: false,

            model: {
                profile: {
                    firstName: '',
                    lastName: '',
                    country: null,
                },
                user: {
                    email: '',
                    password: '',
                },
                terms: false,
            },
        };
    },

    computed: {
        countries() {
            return [{ title: this.$t('register.countries.pl'), value: 'pl' }, { title: this.$t('register.countries.at'), value: 'at' }, { title: this.$t('register.countries.ee'), value: 'ee' }, { title: this.$t('register.countries.lt'), value: 'lt' }, { title: this.$t('register.countries.lv'), value: 'lv' }];
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.model.user.password.$dirty) {
                return errors;
            }
            if (!this.$v.model.user.password.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.user.password.minLength) {
                errors.push(this.$t('validation.minLength', { limit: 8 }));
            }
            return errors;
        },
        firstNameErrors() {
            const errors = [];
            if (!this.$v.model.profile.firstName.$dirty) {
                return errors;
            }
            if (!this.$v.model.profile.firstName.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        lastNameErrors() {
            const errors = [];
            if (!this.$v.model.profile.lastName.$dirty) {
                return errors;
            }
            if (!this.$v.model.profile.lastName.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        businessEmailErrors() {
            const errors = [];
            if (!this.$v.model.user.email.$dirty) {
                return errors;
            }
            if (!this.$v.model.user.email.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.user.email.email) {
                errors.push(this.$t('validation.email'));
            }
            return errors;
        },
        countryErrors() {
            const errors = [];
            if (!this.$v.model.profile.country.$dirty) {
                return errors;
            }
            if (!this.$v.model.profile.country.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        termsErrors() {
            const errors = [];
            if (!this.$v.model.terms.$dirty) {
                return errors;
            }
            if (!this.$v.model.terms.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
    },

    methods: {
        async handleSubmit() {
            this.registerError = null;

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if (!this.model.terms) {
                return;
            }

            const payload = { ...this.model };
            delete payload.terms;
            payload.profile.language = this.$i18n.locale === 'en-GB' ? 'en' : 'pl';

            try {
                this.loading = true;
                await authService.register(payload);

                window.location.href = 'https://100shoppers.com/login';
            } catch (e) {
                this.registerError = e.message;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<!-- eslint-disable linebreak-style -->
<style lang="scss" scoped>
.logo {
    height: 18px;
    vertical-align: middle;
}

.col-form {
    background-image: url('https://100shoppers.com/wp-content/uploads/2023/03/signup-bg.png');
    background-position: top right;
    background-repeat: no-repeat;
}
.col-sidebar {
    background-color: #2d3136;
    background-image: url('https://100shoppers.com/wp-content/uploads/2023/03/signup-bg.png');
    background-repeat: no-repeat;
    background-position: 0 100%;
    color: #fff;

    padding: 3rem !important;
}
.divider-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.divider {
    flex-grow: 1;
    height: 1px;
    margin: 0 5px;
}

.divider-text {
    font-size: 16px;
    font-weight: bold;
    color: #9a9a9a;
    font-size: 12px;
    margin-left: 12px;
    margin-right: 12px;
}
.max-w-500 {
    max-width: 500px;
}

.text-white {
    color: #fff;
}

.text-gray {
    color: #9a9a9a;
    font-size: 12px;
}

.text-green {
    color: $primary-color;
}
</style>
