<template>
    <div class="d-inline-block">
        <v-menu
            content-class="date-range-picker__container"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            origin="top right"
            bottom
            left>
            <template #activator="{ on }">
                <v-btn class="text-none" flat v-on="on">
                    {{ selectedStartDateFormatted }} - {{ selectedEndDateFormatted }}
                    <v-icon small right>fa-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-text class="date-range-picker__content">
                    <v-layout column>
                        <v-layout class="date-range-picker__header" row wrap>
                            <v-flex xs="12">
                                <v-radio-group class="mt-0" v-model="compareRange" @change="handleCompareRangeChange">
                                    <v-radio value="prevRange">
                                        <template v-slot:label>
                                            {{ $t('dateRangePicker.comparePrevRange') }}
                                        </template>
                                    </v-radio>
                                    <v-radio value="lastYear">
                                        <template v-slot:label>
                                            {{ $t('dateRangePicker.compareLastYear') }}
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-flex>
                            <v-flex class="text-xs-right" xs="12">
                                <v-select
                                    class="d-inline-block"
                                    v-model="range"
                                    @change="handleRangeChange"
                                    :items="rangeOptions"
                                    placeholder=" "
                                    :label="$t('dateRangePicker.range')"
                                    outline
                                    dense
                                />
                            </v-flex>
                        </v-layout>
                        <v-flex d-flex>
                            <v-layout row wrap>
                                <v-flex class="text-xs-center ma-2" xs="12">
                                    <div class="d-inline-block mb-2 pa-1 date-range-picker__title">{{ $t('dateRangePicker.startDateLabel') }}</div>
                                    <v-flex>
                                        <v-date-picker
                                            class="date-range-picker__datepicker"
                                            v-model="startDate"
                                            :events="daysInRange.start"
                                            event-color="accent"
                                            :max="startDateMax"
                                            :locale="locale"
                                            first-day-of-week="1"
                                            no-title
                                            @change="handleChange"
                                        />
                                    </v-flex>
                                </v-flex>
                                <v-flex class="text-xs-center ma-2" xs="12">
                                    <div class="d-inline-block mb-2 pa-1 date-range-picker__title">{{ $t('dateRangePicker.endDateLabel') }}</div>
                                    <v-flex>
                                        <v-date-picker
                                            class="date-range-picker__datepicker"
                                            v-model="endDate"
                                            :events="daysInRange.end"
                                            event-color="accent"
                                            :min="endDateMin"
                                            :max="endDateMax"
                                            :locale="locale"
                                            first-day-of-week="1"
                                            no-title
                                            @change="handleChange"
                                        />
                                    </v-flex>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex v-if="!isValidRange" xs="24">
                            <v-alert
                                :value="!isValidRange"
                                type="error"
                                transition="scale-transition">
                                {{ $t('dateRangePicker.invalidRange') }}
                            </v-alert>
                        </v-flex>
                        <v-flex class="text-xs-right" xs="24">
                            <v-btn round @click="handleCancel">{{ $t('dateRangePicker.cancel') }}</v-btn>
                            <v-btn
                                color="tertiary"
                                dark
                                round
                                @click="applySelections(true)">{{ $t('dateRangePicker.apply') }}</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isAfter, eachDay, differenceInCalendarDays } from 'date-fns';
import { i18n } from '../../i18n/i18n';
import dateService from '../../services/dateService';
import eventBus from '../../services/eventBus';

export default {
    name: 'date-range-picker',
    props: {
        value: {
            type: Object,
            default: () => ({
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            }),
        },
        gaPage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            menu: false,
            startDate: null, // picker model
            endDate: null, // picker model
            selectedStartDate: null, // component model
            selectedEndDate: null, // component model
            compareStartDate: null,
            compareEndDate: null,
            selectedRange: null,
            startDateMax: dateService.getToday(),
            endDateMax: dateService.getToday(),
            range: '30days',
            compareRange: 'prevRange',
            rangeOptions: [
                {
                    value: '',
                    text: this.$t('dateRangePicker.custom'),
                },
                {
                    value: '7days',
                    text: this.$t('dateRangePicker.days7'),
                },
                {
                    value: '14days',
                    text: this.$t('dateRangePicker.days14'),
                },
                {
                    value: '30days',
                    text: this.$t('dateRangePicker.days30'),
                },
                {
                    value: 'today',
                    text: this.$t('dateRangePicker.today'),
                },
                {
                    value: 'yesterday',
                    text: this.$t('dateRangePicker.yesterday'),
                },
                {
                    value: 'currentWeek',
                    text: this.$t('dateRangePicker.currentWeek'),
                },
                {
                    value: 'lastWeek',
                    text: this.$t('dateRangePicker.lastWeek'),
                },
                {
                    value: 'currentMonth',
                    text: this.$t('dateRangePicker.currentMonth'),
                },
                {
                    value: 'lastMonth',
                    text: this.$t('dateRangePicker.lastMonth'),
                },
                {
                    value: 'currentQuarter',
                    text: this.$t('dateRangePicker.currentQuarter'),
                },
                {
                    value: 'lastQuarter',
                    text: this.$t('dateRangePicker.lastQuarter'),
                },
                {
                    value: 'currentYear',
                    text: this.$t('dateRangePicker.currentYear'),
                },
                {
                    value: 'lastYear',
                    text: this.$t('dateRangePicker.lastYear'),
                },
            ],
            isValidRange: true,
            locale: i18n.locale,
        };
    },
    computed: {
        endDateMin() {
            return this.startDate;
        },
        selectedStartDateFormatted() {
            if (this.selectedStartDate) {
                return this.$d(new Date(this.selectedStartDate), 'short');
            }
            return this.$t('dateRangePicker.notAvailable');
        },
        selectedEndDateFormatted() {
            if (this.selectedEndDate) {
                return this.$d(new Date(this.selectedEndDate), 'short');
            }
            return this.$t('dateRangePicker.notAvailable');
        },
        daysInRange() {
            if (this.startDate && this.endDate && this.validateRange(false)) {
                const days = eachDay(this.startDate, this.endDate).map(date => dateService.formatDate(date));
                return {
                    start: days.slice(1, days.length),
                    end: days.slice(0, -1),
                };
            }
            return {
                start: [],
                end: [],
            };
        },
        ...mapGetters({
            getRange: 'dateRangePicker/getRange',
            getStartDate: 'dateRangePicker/getStartDate',
            getEndDate: 'dateRangePicker/getEndDate',
            getCompareRange: 'dateRangePicker/getCompareRange',
        }),
    },
    watch: {
        menu(newMenu) {
            if (newMenu) {
                if (this.selectedStartDate) {
                    this.startDate = this.selectedStartDate;
                }

                if (this.selectedEndDate) {
                    this.endDate = this.selectedEndDate;
                }

                if (this.selectedRange) {
                    this.range = this.selectedRange;
                }

                this.compareRange = this.getCompareRange;
            }
        },
    },
    created() {
        this.range = this.getRange;
        this.compareRange = this.getCompareRange;

        if (!this.range && this.getStartDate && this.getEndDate) {
            this.startDate = this.getStartDate;
            this.endDate = this.getEndDate;
        } else if (this.value.startDate && this.value.endDate) {
            this.startDate = this.value.startDate;
            this.endDate = this.value.endDate;
        } else {
            const { startDate, endDate } = dateService.getDatesByRange(this.range);
            this.startDate = startDate;
            this.endDate = endDate;
        }
        this.calculateDatesFromRange();
        this.applySelections();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.range = '30days';
            this.compareRange = 'prevRange';
            this.calculateDatesFromRange();
            this.applySelections(true);
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.range === '30days' && this.compareRange === 'prevRange';
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        calculateDatesFromRange() {
            if (!this.range) {
                const { compareStartDate, compareEndDate } = dateService.getDatesByCompareRange(this.startDate, this.endDate, this.compareRange);
                this.compareStartDate = compareStartDate;
                this.compareEndDate = compareEndDate;
                return;
            }

            const {
                startDate, endDate, compareStartDate, compareEndDate,
            } = dateService.getDatesByRange(this.range, this.compareRange);
            this.startDate = startDate;
            this.endDate = endDate;
            this.compareStartDate = compareStartDate;
            this.compareEndDate = compareEndDate;
        },
        handleRangeChange() {
            this.calculateDatesFromRange();
        },
        handleCompareRangeChange() {
            this.calculateDatesFromRange();
        },
        validateRange(updateValue = true) {
            const isValidRange = !isAfter(this.startDate, this.endDate);
            if (updateValue) {
                this.isValidRange = isValidRange;
            }
            return isValidRange;
        },
        applySelections(sendGaEvent = false) {
            if (!this.validateRange()) {
                return;
            }
            this.menu = false;
            this.selectedStartDate = this.startDate;
            this.selectedEndDate = this.endDate;
            this.selectedRange = this.range;
            this.$emit('input', {
                startDate: this.startDate,
                endDate: this.endDate,
                compareStartDate: this.compareStartDate,
                compareEndDate: this.compareEndDate,
            });
            this.$emit('change');
            this.setRange({
                range: this.range,
                startDate: this.startDate,
                endDate: this.endDate,
            });
            this.setCompareRange({
                compareRange: this.compareRange,
            });
            this.emitFilterDefaultChange();
            if (sendGaEvent) {
                this.sendGaEvent();
            }
        },
        handleCancel() {
            this.menu = false;
        },
        handleChange() {
            this.range = '';

            // validate range on change only if it was previously incorrect to reduce UI clutter
            if (!this.isValidRange) {
                this.validateRange();
            }

            if (this.isValidRange) {
                this.calculateDatesFromRange();
            }
        },
        sendGaEvent() {
            const daysDiff = differenceInCalendarDays(new Date(), this.startDate);
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Zakres dat',
                eventAction: this.gaPage,
                eventLabel: daysDiff,
            });
        },
        ...mapActions({
            setRange: 'dateRangePicker/setRange',
            setCompareRange: 'dateRangePicker/setCompareRange',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .date-range-picker__container {
        @media only screen and (max-width: 440px) {
            left: 5px !important;
            right: 5px !important;
            max-width: 100% !important;
        }
    }

    .date-range-picker__content {
        @media only screen and (max-width: 440px) {
            padding-left: 5px;
            padding-right: 5px;
        }
        @media only screen and (max-width: 830px) {
            max-width: 340px;
        }
    }

    .date-range-picker__title {
        min-width: 290px;
        background-color: $tertiary-color;
        color: white;
    }

    .date-range-picker__header {
        padding-left: 7px;
        padding-right: 7px;
    }
</style>

<style lang="scss">
    .date-range-picker__datepicker .v-date-picker-table__events {
        height: 100%;
        bottom: 0;

        > div {
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0.25;
        }
    }
</style>
