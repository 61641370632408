<template>
    <ranking-graph
        :loading="loading"
        :error="error"
        @redo="redo"
        :title="$t('shopsRanking.title')"
        :data="items"
        has-change
        has-total-change
        id-key="id"
        name-key="name"
        total-key="summary"
        :total-type="totalType"
        change-key="summaryChange"
        change-data-key="chart"
        change-data-date-key="date"
        :change-data-value-key="rankingType"
        :export-header="exportHeader"
        :export-rows-unshift="exportRows"
        :show-line-chart="false"
    >
        <template #filters>
            <v-layout align-end justify-start column>
                <v-layout wrap align-center justify-end>
                    <v-flex shrink>
                        <v-select
                            class="d-inline-block mb-3 select--rows"
                            v-model="rowsToShow"
                            @change="handleRowsToShowChange"
                            :items="rowsToShowOptions"
                            :disabled="loading"
                            placeholder=" "
                            :label="$t('productsRanking.rowsToShow')"
                            outline
                            hide-details
                            dense
                        />
                    </v-flex>
                    <v-flex shrink>
                        <select-all
                            class-name="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="campaign"
                            :items="campaignOptions"
                            :disabled="loading"
                            :label="$t('productsRanking.shop')"
                            :all-label="$t('productsRanking.allShops')"
                            require-selection
                            @blur="handleCampaignBlur"
                        />
                    </v-flex>
                    <v-flex v-if="!showProductGroupsResults" shrink>
                        <select-all
                            class-name="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="category"
                            :items="categoryOptions"
                            :itemsTree="categoryOptionsTree"
                            :disabled="loading"
                            :label="$t('productsSummary.category')"
                            :all-label="$t('productsSummary.allCategories')"
                            require-selection
                            @blur="handleCategoryBlur"
                        />
                    </v-flex>
                    <v-flex v-if="showProductGroupsResults" shrink>
                        <select-all
                            class-name="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="group"
                            :items="groupOptions"
                            :disabled="loading"
                            :label="$t('productsRanking.group')"
                            :all-label="$t('productsRanking.allGroups')"
                            @blur="handleGroupBlur"
                        />
                    </v-flex>
                    <v-flex shrink>
                        <v-select
                            class="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="sortBy"
                            @change="handleSortByChange"
                            :items="sortByOptions"
                            :disabled="loading"
                            placeholder=" "
                            :label="$t('productsRanking.sortBy')"
                            outline
                            hide-details
                            dense
                        />
                    </v-flex>
                    <v-flex shrink>
                        <v-select
                            class="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="rankingType"
                            @change="handleTypeChange"
                            :items="rankingTypeOptions"
                            :disabled="loading"
                            placeholder=" "
                            :label="$t('productsRanking.rankingType')"
                            outline
                            hide-details
                            dense
                        />
                    </v-flex>
                </v-layout>
                <v-layout wrap align-center justify-end>
                    <v-flex shrink>
                        <v-switch
                            v-model="showProductGroupsResults"
                            :label="$t('filters.showProductGroupsResults')"
                            :disabled="loading"
                            @change="handleShowProductGroupsResultsChange"
                        />
                    </v-flex>
                </v-layout>
            </v-layout>
        </template>
    </ranking-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce, deburr, orderBy } from 'lodash';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import RankingGraph from '../../../graphs/RankingGraph.vue';
import eventBus from '../../../../services/eventBus';
import SelectAll from '../../../common/SelectAll.vue';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import categoryFilterMixin from '../../../../mixins/categoryFilterMixin';
import exportService from '../../../../services/exportService';

export default {
    name: 'shops-ranking',
    components: {
        SelectAll,
        RankingGraph,
    },
    mixins: [campaignFilterMixin, categoryFilterMixin, groupsFilterMixin],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        const region = process.env.VUE_APP_REGION_MODE;
        return {
            isTestEnvironment: process.env.NODE_ENV === 'development',
            region,
            data: null,
            items: [],
            search: null,
            rowsToShow: 10,
            rowsToShowOptions: [
                {
                    value: 10,
                    text: '10',
                },
                {
                    value: 20,
                    text: '20',
                },
                {
                    value: 50,
                    text: '50',
                },
            ],
            rankingType: 'impressions',
            rankingTypeOptions: [
                {
                    value: 'impressions',
                    text: this.$t('productsRanking.impressionsOffer'),
                },
                {
                    value: 'clicks',
                    text: this.$t('productsRanking.clicks'),
                },
                region !== 'PL' && {
                    value: 'quantity',
                    text: this.$t('productsRanking.quantity'),
                },
                region !== 'PL' && {
                    value: 'amount',
                    text: this.$t('productsRanking.amount'),
                },
            ].filter(item => !!item),
            totalType: 'decimal',
            sortBy: 'summary',
            sortByOptions: [
                {
                    value: 'summary',
                    text: this.$t('productsRanking.sortBySummary'),
                },
                {
                    value: 'change',
                    text: this.$t('productsRanking.sortByChange'),
                },
            ],
            showProductGroupsResults: false,
            error: null,
        };
    },
    computed: {
        loading() {
            return this.rankingLoading;
        },
        exportRows() {
            return [
                `"${exportService.parseText(this.dateFilter.startDate)} - ${exportService.parseText(this.dateFilter.endDate)}"`,
                `"${exportService.parseText(new Date().toLocaleDateString())}"`,
                `"${exportService.parseText(this.campaign.map(id => this.getCampaignNameFromId(id)).join(', '))}"`,
            ];
        },

        exportHeader() {
            const valueLabel = this.rankingTypeOptions.find(option => option.value === this.rankingType).text;
            const valueLabelPostfix = this.rankingType === 'amount' ? ` [${process.env.VUE_APP_SYSTEM_CURRENCY}]` : '';

            return [
                `${this.$t('dataFor')}`,
                `${this.$t('exportingOn')}`,
                `${this.$t('selectedStores')}`,
                `${this.$t('productsRanking.exportName')}`,
                `${valueLabel}${valueLabelPostfix}`,
                `${valueLabel} (% ${this.$t('change')})`,
            ];
        },
        ...mapState({
            rankingLoading: state => state.reportsShops.loading.ranking,
        }),
        ...mapGetters({
            getterRanking: 'reportsShops/getRanking',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    async created() {
        await this.getGroups();
        this.setDefaultCategory();
        this.setDefaultCampaign();
        this.setDefaultGroup();
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign();
            this.setDefaultGroup();
            this.showProductGroupsResults = false;
            this.rowsToShow = 10;
            this.search = null;
            this.rankingType = 'impressions';
            this.sortBy = 'summary';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = !this.search
                && this.rankingType === 'impressions'
                && this.rowsToShow === 10
                && this.sortBy === 'summary'
                && this.isDefaultCampaign(['PL'])
                && this.isDefaultCategory()
                && this.isDefaultGroup()
                && !this.showProductGroupsResults;
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        async getData(force = false) {
            this.emitFilterDefaultChange();
            await this.getRanking(force);
        },
        setDefaultCategory() {
            this.category = this.getterCategoryOptions.map(category => category.value);
        },
        handleCategoryBlur() {
            this.getData();
        },
        handleGroupBlur() {
            this.getData();
        },
        handleShowProductGroupsResultsChange() {
            this.getData();
        },
        handleRowsToShowChange() {
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        handleTypeChange() {
            this.getData();
            this.totalType = this.rankingType === 'amount' ? 'currency' : 'decimal';
        },
        handleSortByChange() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        handleSearch: debounce(function handleSearch() {
            this.setItems();
        }, 500),
        setItems() {
            this.emitFilterDefaultChange();
            if (!this.data) {
                this.items = [];
            }

            let { data } = this;

            if (this.search) {
                const search = deburr(this.search).toLowerCase();
                data = data.filter(item => deburr(item.name)
                    .toLowerCase()
                    .includes(search));
            }

            const sortField = this.sortBy === 'summary' ? 'summary' : 'summaryChange';
            data = orderBy(data, [sortField], ['desc']);

            this.items = data.slice(0, this.rowsToShow);
        },
        async getRanking(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                const category = this.showProductGroupsResults ? [] : this.category;
                const group = this.showProductGroupsResults ? this.group : [];
                this.error = null;
                await this.fetchRanking({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    rankingType: this.rankingType,
                    campaign: this.campaign,
                    category,
                    group,
                });
                this.data = this.getterRanking(startDate, endDate, compareStartDate, compareEndDate, this.rankingType, this.campaign, category, group);
                this.setItems();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getGroups(force) {
            try {
                this.error = null;
                await this.fetchGroups(force);
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchRanking: 'reportsShops/fetchRanking',
            fetchGroups: 'producerProductGroup/fetchGroups',
        }),
    },
};
</script>

<style lang="scss" scoped>
.select--rows {
    width: 120px;
}
</style>
