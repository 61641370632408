<template>
    <v-combobox
        :placeholder="$t('findOrSelect')"
        :search-input.sync="search"
        :value="search"
        persistent-hint
        persistent-placeholder
        clearable
        clear-icon="fa-times"
        autocomplete="off"
        @keyup="handleSearch"
        @click:clear="fetchItems(null, true)"
    >
        <template v-slot:no-data>
            <v-list class="px-3 py-0">
                <div v-if="itemsLoading" class="text-xs-center overflow-hidden">
                    <v-progress-circular indeterminate color="primary" />
                </div>
                <v-treeview
                    v-if="!itemsLoading"
                    class="products-tree"
                    ref="tree"
                    :items="treeProducts"
                    :open.sync="open"
                    return-object
                    :load-children="fetchItems"
                    :multiple-active="true"
                    :active.sync="active"
                    active-class="tree-node--active"
                    item-key="id"
                    item-text="text"
                    item-children="items"
                    loading-icon="fa-circle-notch"
                    transition
                >
                    <template v-slot:prepend="{ item, open, active }">
                        <v-icon v-if="item.type === 'category'" class="ml-2 mr-1" :color="active ? 'secondary' : ''" small>{{ open ? 'fa-folder-open' : 'fa-folder' }}</v-icon>
                        <v-icon v-else class="ml-2 mr-1" :color="active ? 'secondary' : ''" small>fa-file</v-icon>
                    </template>
                    <template v-slot:label="{ item }">
                        <div class="d-flex justify-space-around align-center" style="cursor:default;" @click.stop.prevent>
                            <div>{{ item.text }}</div>
                            <div v-if="item.id !== 'all'" class="align-self-end text-xs-right">
                                <v-progress-circular v-if="isItemAdding(item)" :size="24" indeterminate color="primary" />
                                <div v-else-if="isItemSelected(item)" class="ma-0 v-chip v-chip--small">
                                    <v-avatar class="ma-0">
                                        <v-icon small color="success" class="pa-0">fa-check</v-icon>
                                    </v-avatar>
                                </div>
                                <v-btn
                                    v-else
                                    small
                                    fab
                                    color="success"
                                    :disabled="isGroupFetching(groupId) || isAnyItemAdding() || isItemLoading(item)"
                                    class="ma-0"
                                    style="width:24px; height:24px;"
                                    @click="addItemToGroup(item)"><v-icon small class="pa-0">fa-plus</v-icon></v-btn>
                            </div>
                        </div>
                    </template>
                </v-treeview>
            </v-list>
        </template>
    </v-combobox>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import trim from 'lodash/trim';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'ProductsTreeSelect',
    props: {
        group: {
            required: false,
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            search: '',
            error: null,
            open: ['all'],
            active: [],
        };
    },
    computed: {
        itemsLoading() {
            return this.rootProductsLoading || this.searchProductsLoading || !this.group;
        },
        treeProducts() {
            const tree = [
                {
                    id: 'all',
                    text: 'All',
                    type: 'category',
                    path: [{ index: 0, id: 'all' }],
                    items: this.getterProductsTreeMap,
                },
            ];
            return cloneDeep(tree);
        },
        getterProductsTreeMap() {
            const cb = (itemParent, item, index) => {
                item.path = [...itemParent.path, { index, id: item.id }]; // eslint-disable-line no-param-reassign
                if (item.items) item.items.map((itemChild, indexChild) => cb(item, itemChild, indexChild));
                item.selected = this.isItemSelected(item); // eslint-disable-line no-param-reassign
                return item;
            };
            return this.getterProductsTree.map((itemChild, index) => cb({ path: [{ index: 0, id: 'all' }] }, itemChild, index));
        },
        isItemAdding() {
            return item => {
                if (item.type === 'product') return this.isProductAdding(`${this.groupId}-${this.getRealId(item.id)}`);
                if (item.type === 'category') return this.isCategoryAdding(`${this.groupId}-${this.getRealId(item.id)}`);
                return false;
            };
        },
        isAnyItemAdding() {
            return () => this.isAnyProductAdding() || this.isAnyCategoryAdding();
        },
        groupId() {
            return this.group?.id;
        },
        ...mapState({
            rootProductsLoading: state => state.products.loading.rootProducts,
            searchProductsLoading: state => state.products.loading.searchProducts,
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            isProductAdding: 'producerProductGroup/isProductAdding',
            isCategoryAdding: 'producerProductGroup/isCategoryAdding',
            isGroupFetching: 'producerProductGroup/isGroupFetching',
            getterProductsTree: 'products/getProductsTree',
            isAnyProductAdding: 'producerProductGroup/isAnyProductAdding',
            isAnyCategoryAdding: 'producerProductGroup/isAnyCategoryAdding',
        }),
    },
    watch: {
        active(to) {
            if (to && to.length > 0 && to[0].type === 'category') {
                const isOpen = this.open.find(item => item.id === to[0].id);

                if (!isOpen) {
                    this.open.push(to[0]);
                }
            }
        },
        spaceId: {
            immediate: true,
            handler() {
                this.fetchItems();
            },
        },
    },
    methods: {
        handleSearch: debounce(function handleSearch() {
            this.searchItems();
        }, 750),
        async searchItems() {
            const search = trim(this.search);

            // if there is no search query then force fetch basic root level tree
            // otherwise API will return full depth tree
            if (!search) {
                this.fetchItems(null, true);
                return;
            }
            if (search.length < 3) {
                return;
            }

            try {
                await this.fetchSearchProducts({ search });
                if (this.$refs.tree) {
                    this.$refs.tree.updateAll(true);
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async fetchItems(item, force = false) {
            try {
                if (!item) {
                    await this.fetchProducts({ force });
                    if (this.$refs.tree) {
                        this.$refs.tree.updateSelected('all');
                    }
                } else {
                    if (item.id === 'all') {
                        return;
                    }
                    await this.fetchProducts({ categoryId: item.id, entityId: item.entityId });
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        getRealId(id) {
            return Number.parseInt(id.replace('product-', '').replace('category-', ''), 10);
        },
        isItemSelected(item) {
            if (item.type === 'product') return this.isProductSelected(this.getRealId(item.id));
            if (item.type === 'category') return this.isCategorySelected(this.getRealId(item.id));
            return false;
        },
        isItemParentSelected(item) {
            if (item.type === 'product') return item.path.some(itemChild => this.isCategorySelected(this.getRealId(itemChild.id))); // check only for product
            if (item.type === 'category') return false;
            return false;
        },
        isItemLoading(item) {
            return this.$refs.tree?.nodes[item.id]?.vnode.isLoading;
        },
        isProductSelected(id) {
            return !!this.group?.products?.find(item => item.id === id);
        },
        isCategorySelected(id) {
            return !!this.group?.categories?.find(item => item.id === id);
        },
        async addItemToGroup(item) {
            if (item.type === 'product') return this.addProductToGroup(item);
            if (item.type === 'category') return this.addCategoryToGroup(item);
            return false;
        },
        async addProductToGroup(item) {
            try {
                const isItemParentSelected = this.isItemParentSelected(item);
                if (isItemParentSelected) {
                    this.$toast.warning(this.$t('productGroupsAddProductCategoryExistError', { name: item.text }), { timeout: 10000 });
                } else {
                    const id = this.getRealId(item.id);
                    const data = { groupId: this.groupId, productId: id };
                    await this.addProduct(data);
                    this.$emit('productAdded', data);
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async hasNestedCategoryInGroup(group) {
            const groupDetails = await this.fetchGroup({ id: this.groupId });
            const groupCategoriesIds = groupDetails.categories.map(category => category.id);

            const productCategoriesChildrenCategories = group.items.filter(item => item.type === 'category');

            const categoriesAlreadyInGroup = [];
            const categoriesNotInGroup = [];

            productCategoriesChildrenCategories.forEach(productChildren => {
                if (groupCategoriesIds.includes(productChildren.entityId)) {
                    // Detects if the category is already in the group
                    categoriesAlreadyInGroup.push(productChildren);
                } else {
                    // Detects if the category is not in the group
                    categoriesNotInGroup.push(productChildren);
                }
            });
            if (categoriesAlreadyInGroup.length > 0) {
                return {
                    categoriesAlreadyInGroup,
                    categoriesNotInGroup,
                };
            }
            return false;
        },
        async addCategoryToGroup(item) {
            try {
                const key = item.id;
                await this.loadTreeChildren(key);
                let nestedCategories;

                const isChildAdded = this.checkIsAnyChildAdded(key);
                if (isChildAdded) {
                    this.$toast.warning(this.$t('productGroupsAddCategoryChildExistError', { name: item.text }), { timeout: 10000 });
                } else {
                    nestedCategories = await this.hasNestedCategoryInGroup(item);

                    if (nestedCategories && nestedCategories.categoriesAlreadyInGroup) {
                        const toastVariableAdded = nestedCategories.categoriesNotInGroup.map(category => category.text).join(', ');
                        const toastVariableExisting = nestedCategories.categoriesAlreadyInGroup.map(category => category.text).join(', ');
                        console.log(nestedCategories.categoriesNotInGroup);

                        nestedCategories.categoriesNotInGroup.forEach(async (category, index, array) => {
                            const data = {
                                groupId: this.groupId, categoryId: category.entityId, customToast: this.$t('producerProductGroup.addCategoryNestedSuccess', { added: toastVariableAdded, existing: toastVariableExisting }), hideToast: (index !== array.length - 1),
                            };

                            await this.addCategory(data);
                            this.$emit('categoryAdded', data);
                        });
                    } else {
                        const id = this.getRealId(item.id);
                        const data = { groupId: this.groupId, categoryId: id };
                        await this.addCategory(data);
                        this.$emit('categoryAdded', data);
                    }
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async loadTreeChildren(key) {
            const node = this.$refs.tree?.nodes[key];
            if (node.vnode && !node.vnode.hasLoaded) {
                return node.vnode.checkChildren().then(() => {
                    this.$refs.tree?.updateOpen(key, false);
                });
            }
            return Promise.resolve();
        },
        checkIsAnyChildAdded(key) {
            const node = this.$refs.tree?.nodes[key];
            return node?.children?.some(item => this.isProductSelected(this.getRealId(item)));
        },
        ...mapActions({
            fetchSearchProducts: 'products/fetchSearchProducts',
            fetchProducts: 'products/fetchProducts',
            fetchGroup: 'producerProductGroup/fetchGroup',
            addProduct: 'producerProductGroup/addProduct',
            addCategory: 'producerProductGroup/addCategory',
        }),
    },
};
</script>
