<template>
    <div>
        <v-alert
            :value="error"
            dismissible
            type="error"
            transition="scale-transition">
            {{ error }}
        </v-alert>
        <timeline-graph
            ref="timelineRef"
            :title="$t('competitionShopAvailability.title')"
            :data="items"
            type="small"
            @ready="chartReady"
        />
        <graph-container v-if="!!productAvailability" :value="true" :title="productAvailability.name" @input="hideProductAvailability">
            <div v-if="loadingProductAvailability" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <product-availability
                v-if="!loadingProductAvailability"
                :product-id="productAvailability.id"
                :global-entity-id="globalEntityId"
                :date-filter="dateFilter"
                :parentCampaign="campaign"
                :show-more-button="false"
            />
        </graph-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ganttService from '@/services/ganttService';
import TimelineGraph from '@/components/graphs/TimelineGraph.vue';
import GraphContainer from '@/components/graphs/GraphContainer.vue';
import ProductAvailability from '@/components/producer/products/ProductAvailability.vue';

const rowLabelRegex = /(.+) \(ID: (.+)\)$/;

export default {
    name: 'competition-shop-availability',
    components: {
        ProductAvailability,
        GraphContainer,
        TimelineGraph,
    },
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        set: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
        },
        campaign: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            productAvailability: null,
            globalEntityId: null,
            loadingProductAvailability: false,
            error: null,
        };
    },
    computed: {
        items() {
            if (!this.set || !this.data) {
                return null;
            }
            const header = [
                { type: 'string', label: 'Product', id: 'Product' },
                { type: 'string', id: 'Empty label' },
                { type: 'string', id: 'style', role: 'style' },
                {
                    type: 'string', id: 'tooltip', role: 'tooltip', p: { html: true },
                },
                { type: 'date', label: 'From', id: 'From' },
                { type: 'date', label: 'To', id: 'To' },
            ];
            const items = [];
            const emptyProducts = [];
            const emptyItems = [];
            let lowestStartDate = null;

            [this.set.producerProduct, ...this.set.producerProducts, ...this.set.globalProducts].forEach(setProduct => {
                const productData = this.data.aggregations[setProduct.id] ?? this.data.products[setProduct.id];
                const productSegments = [];
                Object.keys(productData.availability).forEach(campaignId => {
                    productData.availability[campaignId].forEach(offer => {
                        offer.chart.segments.forEach(segment => {
                            // add segments to array
                            productSegments.push(segment);
                            // get the smallest start date from whole data set
                            lowestStartDate = lowestStartDate === null ? segment.start : Math.min(lowestStartDate, segment.start);
                        });
                    });
                });
                if (productSegments.length) {
                    const itemSegments = ganttService.mergeSegments(productSegments).map(segment => [
                        `${productData.aggregation?.name ?? productData.product?.name} (ID: ${setProduct.id})`,
                        '',
                        null, // display original style
                        null, // display original tooltip
                        new Date(segment.start),
                        new Date(segment.end),
                    ]);
                    items.push(...itemSegments);
                } else {
                    // create list of campaigns without segments
                    emptyProducts.push({ id: setProduct.id, name: productData.aggregation?.name ?? productData.product?.name });
                }
            });

            if (emptyProducts.length) {
                // in case all campaigns had no segments, we need to set dummy range that looks good on chart
                // otherwise timeline chart will break
                let lowestEndDate = lowestStartDate;
                if (lowestStartDate === null) {
                    lowestEndDate = (new Date()).valueOf();
                    lowestStartDate = lowestEndDate - 604800000;
                }
                emptyItems.push(...emptyProducts.map(product => [
                    `${product.name} (ID: ${product.id})`,
                    '',
                    'opacity: 0', // hide bar, it's displayed even if range is 0
                    '', // hide tooltip
                    lowestStartDate,
                    lowestEndDate,
                ]));
            }
            return [header, ...[...items, ...emptyItems]];
        },
    },
    methods: {
        chartReady() {
            if (!this.items) {
                return;
            }
            const productIds = Object.keys(this.data.products ?? {});
            const productNames = productIds.map(id => this.data.products[id].product.name);
            const aggregationIds = Object.keys(this.data.aggregations ?? {});
            const aggregationNames = aggregationIds.map(id => this.data.aggregations[id].aggregation.name);
            const timelineEl = this.$refs.timelineRef.$el.getElementsByClassName('TimelineGraph__timeline')?.[0];

            if (timelineEl) {
                let rowIndex = 0;
                // remove duplicates from items (by name in [0]), fixes issue that shows more buttons than needed
                const uniqueItems = this.items.filter((item, index, array) => array.findIndex(item2 => (item2[0] === item[0])) === index);
                uniqueItems.forEach((item, itemIndex) => {
                    // first item is column config
                    if (itemIndex === 0) {
                        return;
                    }
                    const labelMatch = item[0].match(rowLabelRegex);
                    if (labelMatch) {
                        const name = labelMatch[1];
                        const id = labelMatch[2];
                        const isProduct = productIds.includes(id) && productNames.includes(name);
                        const isAggregation = aggregationIds.includes(id) && aggregationNames.includes(name);

                        if (isProduct || isAggregation) {
                            this.createDetailsButton(timelineEl, rowIndex, id, name, isProduct);
                        }
                    }
                    rowIndex += 1;
                });
            }
        },
        createDetailsButton(timelineEl, index, id, name, isProduct) {
            const icon = document.createElement('i');
            icon.className = 'v-icon fa fa-chart-line theme--light';
            icon.style.fontSize = '20px';
            if (isProduct) {
                icon.style.color = this.$vuetify.theme.primary;
            }

            const btnContent = document.createElement('div');
            btnContent.className = 'v-btn__content';
            btnContent.appendChild(icon);

            const btn = document.createElement('button');
            btn.className = 'v-btn v-btn--flat v-btn--icon theme--light competition__showAvailabilityChart';
            btn.style.position = 'absolute';
            btn.style.top = `${3 + (41 * index)}px`;
            btn.style.left = '-38px';
            btn.style.margin = '0';
            btn.setAttribute('data-id', id);
            btn.setAttribute('data-name', name);
            btn.addEventListener('click', this.chartRowLabelClickHandler);
            btn.appendChild(btnContent);

            timelineEl.appendChild(btn);
        },
        async chartRowLabelClickHandler(e) {
            try {
                const name = e.currentTarget.getAttribute('data-name');
                const id = parseInt(e.currentTarget.getAttribute('data-id'), 10);
                this.loadingProductAvailability = true;
                this.productAvailability = { name, id: `product-${id}` };
                const isProducerProduct = this.set.producerProduct.id === id || this.set.producerProducts.some(product => product.id === id);
                if (isProducerProduct) {
                    this.globalEntityId = undefined;
                    await this.fetchSearchProducts({ search: name });
                } else {
                    this.globalEntityId = id;
                }
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loadingProductAvailability = false;
            }
        },
        hideProductAvailability() {
            this.productAvailability = null;
        },
        ...mapActions({
            fetchSearchProducts: 'products/fetchSearchProducts',
        }),
    },
    beforeDestroy() {
        const chartButtons = document.getElementsByClassName('competition__showAvailabilityChart');
        chartButtons.forEach(button => {
            button.removeEventListener('click');
        });
    },
};
</script>

<style lang="scss" scoped>
</style>
