import store from '@/store/store';
import { deburr, pick } from 'lodash';
import {
    addDays,
    addMonths,
    addQuarters, addYears, format, getTime, lastDayOfMonth, lastDayOfQuarter, lastDayOfYear,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subDays,
} from 'date-fns';

export const DEMO_DAYS_RANGE = 427;

export const rewriteArray = (array, newArrayValues) => {
    array.splice(0, array.length);
    array.push(...newArrayValues);
};

export const getLang = () => (store.state.auth.user && store.state.auth.user.language ? store.state.auth.user.language : 'en');

export const getProductsTree = (data, category) => {
    if (!category) {
        return data.map(node => ({ ...node, items: undefined }));
    }
    const categoryNode = data.find(node => node.entityId === category);
    if (categoryNode) {
        return categoryNode.items;
    }
    return [];
};

export const searchProductsTree = (data, query) => data
    .map(node => {
        let { items } = node;
        if (node.children === true && items && items.length > 0) {
            items = items.filter(item => deburr(item.text).toLowerCase().includes(deburr(query).toLowerCase()));
        }
        return { ...node, items };
    })
    .filter(node => (node.children === true && node.items && node.items.length > 0) || deburr(node.text).toLowerCase().includes(deburr(query).toLowerCase()));

export const searchProducts = (data, query) => {
    const filteredResults = data.items.filter(item => (item.name && item.name.toLocaleLowerCase().includes(query.search.toLocaleLowerCase())) || (item.sku && item.sku.includes(query.search)) || (item.ean && item.ean.includes(query.search)) || (item.productId && item.productId.toString().includes(query.search)));
    return {
        items: filteredResults,
        max: filteredResults.length,
    };
};

export const generateDates = (data, outputFormat, intervalType = 'day') => {
    const startDate = subDays(new Date(), DEMO_DAYS_RANGE);
    return data.map((item, index) => {
        let date;
        if (intervalType === 'month') {
            date = startOfMonth(addMonths(startDate, index));
        } else if (intervalType === 'quarter') {
            date = startOfQuarter(addQuarters(startDate, index));
        } else if (intervalType === 'year') {
            date = startOfYear(addYears(startDate, index));
        } else {
            date = addDays(startDate, index);
        }
        if (outputFormat) {
            date = format(date, outputFormat);
        } else {
            date = getTime(date);
        }
        return { ...item, date };
    });
};

export const generateSegmentsDates = data => {
    const startDate = subDays(new Date(), DEMO_DAYS_RANGE);
    return data.map(item => {
        let segments = [];
        if (item.segments.length > 0) {
            const blockLength = DEMO_DAYS_RANGE / (item.segments.length * 2);
            segments = item.segments.map((segment, index) => {
                const start = getTime(addDays(startDate, index * 2 * blockLength));
                const end = getTime(addDays(startDate, ((index * 2) + 1) * blockLength));
                return { start, end };
            });
        }
        return { ...item, segments };
    });
};

export const generateProductOffersSameDates = (data, outputFormat) => {
    let date = subDays(new Date(), 1).setHours(7, 30, 0, 0);
    if (outputFormat) {
        date = format(date, outputFormat);
    } else {
        date = getTime(date);
    }
    return {
        max: data.max,
        items: data.items.map(item => ({ ...item, date })),
    };
};

export const filterDates = (data, dateFormat, period) => {
    const startDate = getTime(store.getters['dateRangePicker/getStartDate']);
    const endDate = getTime(store.getters['dateRangePicker/getEndDate']);
    return data.filter(item => {
        if (dateFormat) {
            return getTime(item.date) >= startDate && getTime(item.date) <= endDate;
        }
        if (period) {
            let firstPeriodDay;
            let lastPeriodDay;
            if (period === 'month') {
                firstPeriodDay = startOfMonth(item.date);
                lastPeriodDay = lastDayOfMonth(item.date);
            }
            if (period === 'quarter') {
                firstPeriodDay = startOfQuarter(item.date);
                lastPeriodDay = lastDayOfQuarter(item.date);
            }
            if (period === 'year') {
                firstPeriodDay = startOfYear(item.date);
                lastPeriodDay = lastDayOfYear(item.date);
            }
            return lastPeriodDay >= startDate && firstPeriodDay <= endDate;
        }
        return item.date >= startDate && item.date <= endDate;
    });
};

export const filterSegmentDates = data => {
    const startDate = getTime(store.getters['dateRangePicker/getStartDate']);
    const endDate = getTime(store.getters['dateRangePicker/getEndDate']);
    return data.map(item => {
        let segments = [];
        if (item.segments.length > 0) {
            segments = item.segments
                .filter(segment => (segment.start > startDate || segment.end > startDate) && (segment.start < endDate || segment.end < endDate))
                .map(segment => {
                    const start = segment.start < startDate ? startDate : segment.start;
                    const end = segment.end > endDate ? endDate : segment.end;
                    return { start, end };
                });
        }
        return { ...item, segments };
    });
};

export const campaigns = [];
export const availableCampaigns = [];
export const inPluginCampaignsIds = [];
export const inPluginCampaignsNames = [];
export const notInPluginCampaignsIds = [];
export const notInPluginCampaignsNames = [];

export const getCampaignNames = ids => {
    if (!ids) return availableCampaigns.map(c => c.name);
    const idsArray = ids.split(',').map(id => Number.parseInt(id, 10));
    return availableCampaigns
        .filter(c => idsArray.includes(c.id))
        .map(c => c.name);
};

export const setProducerCampaigns = async () => {
    const { default: producerCampaigns } = await import('../../mocks/producerCampaigns.json');
    rewriteArray(campaigns, producerCampaigns);
    rewriteArray(inPluginCampaignsIds, producerCampaigns.map(c => c.campaign.id));
    rewriteArray(inPluginCampaignsNames, producerCampaigns.map(c => c.campaign.name));
};

export const setProducerAvailableCampaigns = async () => {
    await setProducerCampaigns();
    const { default: producerAvailableCampaigns } = await import('../../mocks/producerAvailableCampaigns.json');
    const producerAvailableCampaignsArr = Object.keys(producerAvailableCampaigns).map(campaignId => producerAvailableCampaigns[campaignId]);
    rewriteArray(availableCampaigns, producerAvailableCampaignsArr);
    rewriteArray(notInPluginCampaignsIds, producerAvailableCampaignsArr.map(c => c.id).filter(id => !inPluginCampaignsIds.includes(id)));
    rewriteArray(notInPluginCampaignsNames, producerAvailableCampaignsArr.map(c => c.name).filter(name => !inPluginCampaignsNames.includes(name)));
};

export const filterProductAvailabilityByCampaignName = (data, filter) => [
    ...(!filter || filter === 'in' ? data.filter(item => inPluginCampaignsNames.includes(item.label)) : []),
    ...(!filter || filter === 'notIn' ? data.filter(item => notInPluginCampaignsNames.includes(item.label)) : []),
];

export const filterProductAvailabilityByCampaignId = (data, campaignIds = []) => {
    const campaignNames = getCampaignNames(campaignIds);
    return data.filter(item => campaignNames.includes(item.label));
};

export const filterProductPriceByCampaignName = (data, filter) => data.map(item => pick(item, [
    'date',
    ...(!filter || filter === 'in' ? inPluginCampaignsNames : []),
    ...(!filter || filter === 'notIn' ? notInPluginCampaignsNames : []),
]));

export const filterProductOffersByCampaignId = data => ({
    max: data.max,
    items: [
        ...data.items.filter(item => inPluginCampaignsIds.includes(item.campaignId)),
        ...data.items.filter(item => notInPluginCampaignsIds.includes(item.campaignId)),
    ],
});

export const filterKeysByCampaignName = (data, campaignIds) => {
    const campaignNames = getCampaignNames(campaignIds);
    return data.map(item => {
        const parsedItem = {};
        Object
            .keys(item)
            .forEach(key => {
                if (key === 'date' || campaignNames.includes(key)) {
                    parsedItem[key] = item[key];
                }
            });
        return parsedItem;
    });
};

export const spaceCategoriesData = [];

export const getCategoryNameIdPairs = ids => {
    if (!ids) return spaceCategoriesData.map(c => `${c.id} ${c.name}`);
    const idsArray = ids.split(',').map(id => Number.parseInt(id, 10));
    return spaceCategoriesData
        .filter(c => idsArray.includes(c.id))
        .map(c => `${c.id} ${c.name}`);
};

export const filterKeysByCategoryIdNamePair = (data, categoryIds) => {
    const categoryNameIdPairs = getCategoryNameIdPairs(categoryIds);
    return data.map(item => {
        const parsedItem = {};
        Object
            .keys(item)
            .forEach(key => {
                if (key === 'date' || categoryNameIdPairs.includes(key) || !categoryIds) {
                    parsedItem[key] = item[key];
                }
            });
        return parsedItem;
    });
};

export const filterPieChartByCategoryIdNamePair = (data, categoryIds) => {
    if (!categoryIds) return data;
    const categoryNameIdPairs = getCategoryNameIdPairs(categoryIds);
    return data.filter(item => categoryNameIdPairs.includes(item[0]));
};

export const filterPieChartByCampaignName = (data, campaignIds) => {
    const campaignNames = getCampaignNames(campaignIds);
    return data.filter(item => campaignNames.includes(item[0]));
};

export const filterArrayByCampaignName = (data, campaignIds, propertyName) => {
    const campaignNames = getCampaignNames(campaignIds);
    return data.filter(item => campaignNames.includes(item[propertyName]));
};

export const filterArrayByProductId = (data, productsIds, propertyName) => data.filter(item => productsIds.includes(item[propertyName]));

export const addToArray = (arr, item) => {
    const newSet = new Set([...arr]);
    newSet.add(item);
    return Array.from(newSet);
};

export const deleteFromArray = (arr, item) => {
    const newSet = new Set([...arr]);
    newSet.delete(item);
    return Array.from(newSet);
};
