import demoService from '@/services/demoService';
import reportsAvailabilityServiceDemo from '@/services/demo-services/reportsAvailabilityServiceDemo';
import apiService from './apiService';
import errorService from './errorService';
import exportService from './exportService';

const reportsAvailabilityService = {
    async fetchProductAvailability(entityId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/availability-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAvailabilityCharts(spaceId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/availability-charts`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCurrentUnavailableProducts(spaceId, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/current-unavailable-products`,
                {
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCurrentProductsAvailability(spaceId, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/current-products-availability`,
                {
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async downloadAvailabilityReport(report) {
        try {
            const reportId = report.id;
            const formattedFilename = `availability-report-${report.startDate}-${report.endDate}-${report.space.name}`;
            await exportService.exportDataAuthorized(
                `${process.env.VUE_APP_API_URL}/api/v1/products-availability-report-export/${reportId}/download`,
                formattedFilename,
                'xlsx',
            );
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
    async generateAvailabilityReport(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups, products) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-availability-report-export`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                    groups,
                    products,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
    async fetchAvailabilityReports(spaceId, pagination) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-availability-report-export`,
                { perPage: pagination.perPage, page: pagination.page },
            );
            response.data.items.map(item => {
                // eslint-disable-next-line no-param-reassign
                item.max = response.data.max;
                return item;
            });

            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(reportsAvailabilityService, reportsAvailabilityServiceDemo, 'reportsAvailabilityService');
export default reportsAvailabilityService;
