<template>
    <sidebar :items="items" />
</template>

<script>
import Sidebar from '../common/Sidebar.vue';

export default {
    name: 'producer-sidebar',
    components: {
        Sidebar,
    },
    data() {
        return {
            linkActiveClass: 'font-weight-black',
            groupActiveClass: 'font-weight-black',
            items: [
                {
                    type: 'item',
                    icon: 'fa-tachometer-alt',
                    label: 'producerSidebar.dashboard',
                    to: { name: 'producerDashboard' },
                },
                {
                    type: 'item',
                    icon: 'fa-shopping-cart',
                    label: 'producerSidebar.productGroups',
                    to: { name: 'producerProductGroups' },
                },
                {
                    type: 'item',
                    icon: 'fa-shopping-cart',
                    label: 'producerSidebar.products',
                    to: { name: 'producerProducts' },
                },
                {
                    type: 'group',
                    icon: 'fa-chart-pie',
                    activator: {
                        type: 'item',
                        label: 'producerSidebar.reports',
                    },
                    group: '/producer/reports/',
                    items: [
                        {
                            type: 'item',
                            label: 'producerSidebar.reportsShops',
                            to: { name: 'producerReportsShops' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.reportsCategory',
                            to: { name: 'producerReportsCategory' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.reportsProducts',
                            to: { name: 'producerReportsProducts' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.reportsAvailability',
                            to: { name: 'producerReportsAvailability' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.reportsPriceDistribution',
                            to: { name: 'producerReportsPriceDistribution' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.reportsSales',
                            to: { name: 'producerReportsSales' },
                        },
                    ],
                },
                // {
                //     type: 'item',
                //     icon: 'fa-chart-bar',
                //     label: 'producerSidebar.campaignAnalytics',
                //     chip: 'producerSidebar.soon',
                //     disabled: true,
                // },
                {
                    type: 'item',
                    icon: 'fa-exchange-alt',
                    label: 'producerSidebar.competitiveAnalysis',
                    to: { name: 'producerCompetitiveAnalysis' },
                    chip: 'new',
                },
                // {
                //     type: 'item',
                //     icon: 'fa-chart-bar',
                //     label: 'producerSidebar.campaignAnalysis',
                //     to: { name: 'producerCampaignAnalysis' },
                //     chip: 'new',
                //     color: 'hsla(0,0%,100%,.5)',
                // },
                {
                    type: 'item',
                    icon: 'fa-plug',
                    label: 'producerSidebar.plugin',
                    to: { name: 'producerPlugin' },
                },
                {
                    type: 'group',
                    icon: 'fa-cog',
                    activator: {
                        type: 'item',
                        label: 'producerSidebar.settings',
                    },
                    group: '/producer/spaces|/producer/subscriptions|/producer/webhooks',
                    items: [
                        {
                            type: 'item',
                            label: 'producerSidebar.yourPages',
                            to: { name: 'producerSpaces' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.subscriptions',
                            to: { name: 'producerSubscriptions' },
                        },
                        {
                            type: 'item',
                            label: 'producerSidebar.webhook',
                            to: { name: 'producerWebhooks' },
                        },
                        // {
                        //     type: 'item',
                        //     label: 'producerSidebar.api',
                        // },
                    ],
                },
                {
                    type: 'item',
                    icon: 'fa-database',
                    label: 'producerSidebar.dataExport',
                    to: { name: 'producerDataExport' },
                },
                // {
                //     type: 'item',
                //     icon: 'fa-download',
                //     label: 'producerSidebar.dataExport',
                // },
                {
                    type: 'item',
                    icon: 'fa-question-circle',
                    label: 'producerSidebar.help',
                    href: this.$t('producerSidebar.helpUrl'),
                    target: '_blank',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
