<template>
    <v-content class="grey lighten-4 main">
        <v-container fluid>
            <top-nav-bar :hasDrawer="false" :homePath="homePath">
                <template>
                    <slot name="topNavBar" />
                </template>
            </top-nav-bar>
            <slot />
        </v-container>
    </v-content>
</template>

<script>
import TopNavBar from '../common/TopNavBar.vue';

export default {
    name: 'base-layout',
    components: {
        TopNavBar,
    },
    props: {
        homePath: [String, Object],
    },
};
</script>

<style lang="scss" scoped>
    .main {
        height: 100%;
    }
</style>
