import { sortBy, upperFirst } from 'lodash';
import utilsService from '../../services/utilsService';
import dateService from '../../services/dateService';
import reportsShopsService from '../../services/reportsShopsService';
import numberService from '../../services/numberService';
import {
    CLEAN_REPORTS_SHOPS,
    FETCH_REPORTS_SHOPS_AMOUNT_FAILURE,
    FETCH_REPORTS_SHOPS_AMOUNT_REQUEST,
    FETCH_REPORTS_SHOPS_AMOUNT_SUCCESS,
    FETCH_REPORTS_SHOPS_CLICKS_FAILURE,
    FETCH_REPORTS_SHOPS_CLICKS_REQUEST,
    FETCH_REPORTS_SHOPS_CLICKS_SUCCESS,
    FETCH_REPORTS_SHOPS_IMPRESSIONS_FAILURE,
    FETCH_REPORTS_SHOPS_IMPRESSIONS_REQUEST,
    FETCH_REPORTS_SHOPS_IMPRESSIONS_SUCCESS,
    FETCH_REPORTS_SHOPS_ORDERS_FAILURE,
    FETCH_REPORTS_SHOPS_ORDERS_REQUEST,
    FETCH_REPORTS_SHOPS_ORDERS_SUCCESS,
    FETCH_REPORTS_SHOPS_QUANTITY_FAILURE,
    FETCH_REPORTS_SHOPS_QUANTITY_REQUEST,
    FETCH_REPORTS_SHOPS_QUANTITY_SUCCESS,
    FETCH_REPORTS_SHOPS_SUMMARY_FAILURE,
    FETCH_REPORTS_SHOPS_SUMMARY_REQUEST,
    FETCH_REPORTS_SHOPS_SUMMARY_SUCCESS,
    FETCH_REPORTS_SHOPS_RANKING_REQUEST,
    FETCH_REPORTS_SHOPS_RANKING_SUCCESS,
    FETCH_REPORTS_SHOPS_RANKING_FAILURE,
} from '../mutationTypes';

const getRankingStoreName = rankingType => `ranking${upperFirst(rankingType)}`;

const parseChartData = (data, startDate, endDate) => {
    const chartData = data.chart || [];

    // get uniq chart campaigns, backend always sends all campaigns for each row
    const chartCampaigns = chartData.length > 0
        ? Object.keys(chartData[0]).filter(key => key !== 'date')
        : [];

    let chart = null;

    if (chartCampaigns && chartCampaigns.length) {
        // fill missing dates
        const chartMissingDates = dateService.findMissingDates(chartData, startDate, endDate);
        const additionalProperties = chartCampaigns.map(campaign => ({ key: campaign, value: 0 }));
        chart = utilsService.fillArray(chartData, chartMissingDates, 'date', additionalProperties);
        chart = sortBy(chart, ['date']);
    }

    return { chart, chartCampaigns };
};

const initialState = {
    impressionsChart: {},
    clicksChart: {},
    ordersChart: {},
    quantityChart: {},
    amountChart: {},
    summary: {},
    rankingImpressions: {},
    rankingClicks: {},
    rankingQuantity: {},
    rankingAmount: {},
    loading: {
        impressionsChart: false,
        clicksChart: false,
        ordersChart: false,
        quantityChart: false,
        amountChart: false,
        summary: false,
        ranking: false,
    },
};

const getters = {
    getImpressionsChart: state => (startDate, endDate, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
        return state.impressionsChart[storageKey];
    },

    getClicksChart: state => (startDate, endDate, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
        return state.clicksChart[storageKey];
    },

    getOrdersChart: state => (startDate, endDate, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
        return state.ordersChart[storageKey];
    },

    getQuantityChart: state => (startDate, endDate, conversion, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, ...category);
        return state.quantityChart[storageKey];
    },

    getAmountChart: state => (startDate, endDate, conversion, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, ...category);
        return state.amountChart[storageKey];
    },

    getSummary: state => (startDate, endDate, compareStartDate, compareEndDate, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category);
        return state.summary[storageKey];
    },

    getRanking: state => (startDate, endDate, compareStartDate, compareEndDate, rankingType, campaign, category, group) => {
        const rankingStoreName = getRankingStoreName(rankingType);
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category, ...group);
        return state[rankingStoreName][storageKey];
    },
};

const actions = {
    clean({ commit }) {
        commit(CLEAN_REPORTS_SHOPS);
    },

    async fetchImpressionsChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
            if (!force && state.impressionsChart && state.impressionsChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_IMPRESSIONS_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const responseData = await reportsShopsService.fetchImpressionsReport(spaceId, startDate, endDate, reqCampaign, reqCategory);
            const { chart, chartCampaigns } = parseChartData(responseData, startDate, endDate);

            commit(FETCH_REPORTS_SHOPS_IMPRESSIONS_SUCCESS, {
                chart,
                chartCampaigns,
                pieChart: responseData.pie_chart,
                startDate,
                endDate,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_IMPRESSIONS_FAILURE);
            throw e;
        }
    },

    async fetchClicksChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
            if (!force && state.clicksChart && state.clicksChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_CLICKS_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const responseData = await reportsShopsService.fetchClicksReport(spaceId, startDate, endDate, reqCampaign, reqCategory);
            const { chart, chartCampaigns } = parseChartData(responseData, startDate, endDate);

            commit(FETCH_REPORTS_SHOPS_CLICKS_SUCCESS, {
                chart,
                chartCampaigns,
                pieChart: responseData.pie_chart,
                startDate,
                endDate,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_CLICKS_FAILURE);
            throw e;
        }
    },

    async fetchOrdersChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
            if (!force && state.ordersChart && state.ordersChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_ORDERS_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const responseData = await reportsShopsService.fetchOrdersReport(spaceId, startDate, endDate, reqCampaign, reqCategory);
            const { chart, chartCampaigns } = parseChartData(responseData, startDate, endDate);

            commit(FETCH_REPORTS_SHOPS_ORDERS_SUCCESS, {
                chart,
                chartCampaigns,
                pieChart: responseData.pie_chart,
                startDate,
                endDate,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_ORDERS_FAILURE);
            throw e;
        }
    },

    async fetchQuantityChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, conversion, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, ...category);
            if (!force && state.quantityChart && state.quantityChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_QUANTITY_REQUEST);
            const reqConversion = conversion === 'all' ? null : Number.parseInt(conversion, 10);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const responseData = await reportsShopsService.fetchQuantityReport(spaceId, startDate, endDate, reqConversion, reqCampaign, reqCategory);
            const { chart, chartCampaigns } = parseChartData(responseData, startDate, endDate);

            commit(FETCH_REPORTS_SHOPS_QUANTITY_SUCCESS, {
                chart,
                chartCampaigns,
                pieChart: responseData.pie_chart,
                startDate,
                endDate,
                conversion,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_QUANTITY_FAILURE);
            throw e;
        }
    },

    async fetchAmountChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, conversion, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, ...category);
            if (!force && state.amountChart && state.amountChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_AMOUNT_REQUEST);
            const reqConversion = conversion === 'all' ? null : Number.parseInt(conversion, 10);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const responseData = await reportsShopsService.fetchAmountReport(spaceId, startDate, endDate, reqConversion, reqCampaign, reqCategory);
            const { chart, chartCampaigns } = parseChartData(responseData, startDate, endDate);

            commit(FETCH_REPORTS_SHOPS_AMOUNT_SUCCESS, {
                chart,
                chartCampaigns,
                pieChart: responseData.pie_chart,
                startDate,
                endDate,
                conversion,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_AMOUNT_FAILURE);
            throw e;
        }
    },

    async fetchSummary({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category);
            if (!force && state.summary && state.summary[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_SUMMARY_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const summaryData = await reportsShopsService.fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory);

            commit(FETCH_REPORTS_SHOPS_SUMMARY_SUCCESS, {
                summaryData,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_SUMMARY_FAILURE);
            throw e;
        }
    },

    async fetchRanking({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, rankingType, campaign, category, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate || !rankingType) {
                return;
            }

            campaign.sort();
            category.sort();
            group.sort();
            const rankingStoreName = getRankingStoreName(rankingType);
            const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category, ...group);
            if (!force && state[rankingStoreName] && state[rankingStoreName][storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SHOPS_RANKING_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const reqGroup = group.length > 0 ? group.join(',') : null;

            let rankingData;
            switch (rankingType) {
                case 'impressions':
                    rankingData = await reportsShopsService.fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                case 'clicks':
                    rankingData = await reportsShopsService.fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                case 'quantity':
                    rankingData = await reportsShopsService.fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                case 'amount':
                    rankingData = await reportsShopsService.fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                default:
                    break;
            }

            commit(FETCH_REPORTS_SHOPS_RANKING_SUCCESS, {
                rankingData,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                rankingType,
                campaign,
                category,
                group,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SHOPS_RANKING_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_REPORTS_SHOPS
    // -----------------------------------------
    [CLEAN_REPORTS_SHOPS](state) {
        state.impressionsChart = {};
        state.clicksChart = {};
        state.ordersChart = {};
        state.quantityChart = {};
        state.amountChart = {};
        state.summary = {};
        state.rankingImpressions = {};
        state.rankingClicks = {};
        state.rankingQuantity = {};
        state.rankingAmount = {};
        state.loading.impressionsChart = false;
        state.loading.clicksChart = false;
        state.loading.ordersChart = false;
        state.loading.quantityChart = false;
        state.loading.amountChart = false;
        state.loading.summary = false;
        state.loading.ranking = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_SUMMARY
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_SUMMARY_REQUEST](state) {
        state.loading.summary = true;
    },
    [FETCH_REPORTS_SHOPS_SUMMARY_SUCCESS](state, payload) {
        const {
            summaryData, startDate, endDate, compareStartDate, compareEndDate, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category);
        const summaryParsed = summaryData.map(product => {
            const impressionsChange = numberService.calculateChangePercentage(product.impressions, product.compareImpressions);
            const clicksChange = numberService.calculateChangePercentage(product.clicks, product.compareClicks);
            const quantityChange = numberService.calculateChangePercentage(product.quantity, product.compareQuantity);
            const amountChange = numberService.calculateChangePercentage(product.amount, product.compareAmount);

            return {
                ...product,
                impressionsChange,
                clicksChange,
                quantityChange,
                amountChange,
            };
        });

        state.loading.summary = false;
        state.summary = {
            ...state.summary,
            [storageKey]: summaryParsed,
        };
    },
    [FETCH_REPORTS_SHOPS_SUMMARY_FAILURE](state) {
        state.loading.summary = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_IMPRESSIONS
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_IMPRESSIONS_REQUEST](state) {
        state.loading.impressionsChart = true;
    },
    [FETCH_REPORTS_SHOPS_IMPRESSIONS_SUCCESS](state, payload) {
        const {
            chart, chartCampaigns, pieChart, startDate, endDate, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);

        state.loading.impressionsChart = false;
        state.impressionsChart = {
            ...state.impressionsChart,
            [storageKey]: {
                chart,
                chartCampaigns,
                pieChart,
            },
        };
    },
    [FETCH_REPORTS_SHOPS_IMPRESSIONS_FAILURE](state) {
        state.loading.impressionsChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_CLICKS
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_CLICKS_REQUEST](state) {
        state.loading.clicksChart = true;
    },
    [FETCH_REPORTS_SHOPS_CLICKS_SUCCESS](state, payload) {
        const {
            chart, chartCampaigns, pieChart, startDate, endDate, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);

        state.loading.clicksChart = false;
        state.clicksChart = {
            ...state.clicksChart,
            [storageKey]: {
                chart,
                chartCampaigns,
                pieChart,
            },
        };
    },
    [FETCH_REPORTS_SHOPS_CLICKS_FAILURE](state) {
        state.loading.clicksChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_ORDERS
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_ORDERS_REQUEST](state) {
        state.loading.ordersChart = true;
    },
    [FETCH_REPORTS_SHOPS_ORDERS_SUCCESS](state, payload) {
        const {
            chart, chartCampaigns, pieChart, startDate, endDate, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);

        state.loading.ordersChart = false;
        state.ordersChart = {
            ...state.ordersChart,
            [storageKey]: {
                chart,
                chartCampaigns,
                pieChart,
            },
        };
    },
    [FETCH_REPORTS_SHOPS_ORDERS_FAILURE](state) {
        state.loading.ordersChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_QUANTITY
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_QUANTITY_REQUEST](state) {
        state.loading.quantityChart = true;
    },
    [FETCH_REPORTS_SHOPS_QUANTITY_SUCCESS](state, payload) {
        const {
            chart, chartCampaigns, pieChart, startDate, endDate, conversion, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, ...category);

        state.loading.quantityChart = false;
        state.quantityChart = {
            ...state.quantityChart,
            [storageKey]: {
                chart,
                chartCampaigns,
                pieChart,
            },
        };
    },
    [FETCH_REPORTS_SHOPS_QUANTITY_FAILURE](state) {
        state.loading.quantityChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_AMOUNT
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_AMOUNT_REQUEST](state) {
        state.loading.amountChart = true;
    },
    [FETCH_REPORTS_SHOPS_AMOUNT_SUCCESS](state, payload) {
        const {
            chart, chartCampaigns, pieChart, startDate, endDate, conversion, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, ...category);

        state.loading.amountChart = false;
        state.amountChart = {
            ...state.amountChart,
            [storageKey]: {
                chart,
                chartCampaigns,
                pieChart,
            },
        };
    },
    [FETCH_REPORTS_SHOPS_AMOUNT_FAILURE](state) {
        state.loading.amountChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SHOPS_RANKING
    // -----------------------------------------
    [FETCH_REPORTS_SHOPS_RANKING_REQUEST](state) {
        state.loading.ranking = true;
    },
    [FETCH_REPORTS_SHOPS_RANKING_SUCCESS](state, payload) {
        const {
            rankingData, startDate, endDate, compareStartDate, compareEndDate, rankingType, campaign, category, group,
        } = payload;
        const rankingStoreName = getRankingStoreName(rankingType);
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category, ...group);
        const parsedRankingData = rankingData.map(item => {
            const summary = parseFloat(item.summary);
            const summaryComparison = parseFloat(item.summaryComparison);
            const summaryChange = numberService.calculateChangePercentage(summary, summaryComparison);

            return {
                ...item,
                summary: Number.isNaN(summary) ? null : summary,
                summaryComparison: Number.isNaN(summaryComparison) ? null : summaryComparison,
                summaryChange,
            };
        });

        state.loading.ranking = false;
        state[rankingStoreName] = {
            ...state[rankingStoreName],
            [storageKey]: parsedRankingData,
        };
    },
    [FETCH_REPORTS_SHOPS_RANKING_FAILURE](state) {
        state.loading.ranking = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
