/*
    Preconditions:
    - tooltip.isHtml: true
    - call in READY event
*/

export default {
    methods: {
        addChartActionReset() {
            if (!this.chart) {
                throw new Error('chart must be set first');
            }

            this.chart.setAction({
                id: 'reset',
                text: this.$t('resetSelection'),
                action: this.resetSelection,
            });
        },

        resetSelection() {
            if (this.chart) {
                this.chart.setSelection([]);
            }
        },
    },
};
