const API_KEY = process.env.VUE_APP_USERCOM_API_KEY;
const SCRIPT_URL = process.env.VUE_APP_USERCOM_SCRIPT_URL;

export default {
    user: {},
    isUserComAvailable() {
        return !!(API_KEY && SCRIPT_URL && window.UE && !(this.user?.isAuthenticated && this.user?.isAdmin));
    },
    getUserDataFromUser(user) {
        const userData = {};
        const userIdPrefix = process.env.VUE_APP_REGION !== 'pl' ? `${process.env.VUE_APP_REGION}_` : '';

        if (user?.fullName) userData.name = user.fullName;
        if (user?.id) userData.user_id = `${userIdPrefix}${user.id}`;
        if (user?.email) userData.email = user.email;

        if (user?.isCompany) {
            userData.company = {};
            if (user?.companyName) userData.company.name = user.companyName;
            if (user?.companyNip) userData.company.nip = user.companyNip;
        }

        return userData;
    },
    pageHit(data = {}) {
        if (!this.isUserComAvailable()) return;
        window.UE.pageHit({
            apiKey: API_KEY,
            date_attr: new Date().toISOString(),
            ...data,
        });
    },
    resetAuth(data = {}) {
        if (!this.isUserComAvailable()) return;
        window.UE.resetAuth({
            apiKey: API_KEY,
            date_attr: new Date().toISOString(),
            ...data,
        });
    },
    clientUpdate(data = {}) {
        if (!this.isUserComAvailable()) return;
        window.userengage('client.update', {
            apiKey: API_KEY,
            date_attr: new Date().toISOString(),
            ...data,
        });
    },
    userengage(eventName, data = {}) {
        if (!this.isUserComAvailable()) return;
        window.userengage(eventName, {
            apiKey: API_KEY,
            date_attr: new Date().toISOString(),
            ...data,
        });
    },
    destroy() {
        if (!this.isUserComAvailable()) return;
        window.UE.destroy();
    },
};
