import spaceService from '../services/spaceService';

export default {
    data() {
        return {
            hasPermission: false,
        };
    },
    methods: {
        checkPermission(permissionName) {
            this.hasPermission = spaceService.hasPermission(permissionName);
        },
    },
};
