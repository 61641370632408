<template>
    <div class="widget-preview">
        <div
            class="bb-widget"
            :data-bb-name="name"
            id="bb-widget-preview">
            <div class="buybox-widget bb-size-lg bb-ready bb-buybox-noshopname" id="bb-widget-bb-widget-preview">
                <div class="bb-product-panel">
                    <img :alt="name" class="bb-product-image" :src="image" />
                    <div>
                        <h3 class="bb-product-name">{{ name }}</h3>
                        <h4 class="bb-product-info">Demo</h4>
                    </div>
                </div>
                <div class="bb-table-container" data-bb-content="5.1">
                    <table aria-label="Offers" class="bb-table">
                        <tbody class="bb-table-content active">
                            <tr class="bb-row bb-store-203 bb-unsorted bb-first-unsorted">
                                <td class="bb-cell bb-store">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neo24.pl">
                                        <img alt="Neo24.pl" src="https://bb-core-dev-api.azurewebsites.net/uploads/campaign/2eb26a36466e55568e498bacec51993db430a123.png" />
                                    </a>
                                </td>
                                <td class="bb-cell bb-type">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neo24.pl">
                                        <span class="bb-overflow-text bb-format-null">&nbsp;</span>
                                    </a>
                                </td>
                                <td class="bb-cell bb-price">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neo24.pl">
                                        <div>
                                            <span>1,438.01&nbsp;zł</span>
                                        </div>
                                    </a>
                                </td>
                                <td class="bb-cell bb-go-to-store">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neo24.pl">
                                        <span style="background-color: rgb(239, 0, 45); border-color: rgb(239, 0, 45);">Idź do sklepu&gt;&gt;</span>
                                    </a>
                                </td>
                            </tr>
                            <tr class="bb-row bb-store-152 bb-unsorted">
                                <td class="bb-cell bb-store">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="euro.com.pl">
                                        <img alt="euro.com.pl" src="https://bb-core-dev-api.azurewebsites.net/uploads/campaign/702a351561ae13758f70cbd23c89f5c4723be208.png" />
                                    </a>
                                </td>
                                <td class="bb-cell bb-type">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="euro.com.pl">
                                        <span class="bb-overflow-text bb-format-null">&nbsp;</span>
                                    </a>
                                </td>
                                <td class="bb-cell bb-price">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="euro.com.pl">
                                        <div>
                                            <span>1,499.00&nbsp;zł</span>
                                        </div>
                                    </a>
                                </td>
                                <td class="bb-cell bb-go-to-store">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="euro.com.pl">
                                        <span style="background-color: rgb(239, 0, 45); border-color: rgb(239, 0, 45);">Idź do sklepu&gt;&gt;</span>
                                    </a>
                                </td>
                            </tr>
                            <tr class="bb-row bb-store-204 bb-unsorted">
                                <td class="bb-cell bb-store">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neonet.pl">
                                        <img alt="Neonet.pl" src="https://bb-core-dev-api.azurewebsites.net/uploads/campaign/b0cf5aba1f77a82e0172dab44e2b5da7a21cec4c.png" />
                                    </a>
                                </td>
                                <td class="bb-cell bb-type">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neonet.pl">
                                        <span class="bb-overflow-text bb-format-null">&nbsp;</span>
                                    </a>
                                </td>
                                <td class="bb-cell bb-price">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neonet.pl">
                                        <div>
                                            <span>1,499.00&nbsp;zł</span>
                                        </div>
                                    </a>
                                </td>
                                <td class="bb-cell bb-go-to-store">
                                    <a
                                        href="#"
                                        rel="nofollow"
                                        target="_blank"
                                        title="Neonet.pl">
                                        <span style="background-color: rgb(239, 0, 45); border-color: rgb(239, 0, 45);">Idź do sklepu&gt;&gt;</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bb-message"></div>
                <div class="bb-footer">
                    <a href="https://getbuybox.com/?utm_medium=footer-link&amp;utm_campaign=bb-widget" rel="nofollow" target="_blank">© BUY.BOX</a>
                </div>
            </div>
        </div>
        <div class="title text-xs-center" id="bb-alt" style="display: none;">Brak ofert</div>
    </div>
</template>

<script>
export default {
    name: 'WidgetDemo',
    props: {
        name: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
/*===== WIDGET MAIN CONTAINER ======*/
.buybox-widget {
  max-width: 800px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 20px 20px 10px;
  margin: 10px auto;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  white-space: nowrap;
}
/*=============================*/

/*===== PRODUCT PREVIEW ======*/
/** Preview container **/
.buybox-widget .bb-product-panel {
  max-width: 100%;
  margin-bottom: 15px;
  padding: 0 0 8px;
  white-space: normal;
  overflow: hidden;
}

/** Product image **/
.buybox-widget .bb-product-panel .bb-product-image {
  float: left;
  max-width: 30%;
  max-height: 8em;
  padding-right: 1em;
}

/** Product name **/
.buybox-widget .bb-product-panel .bb-product-name {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}

/** Product info **/
.buybox-widget .bb-product-panel .bb-product-info {
  font-size: 14px;
  font-weight: 300;
}
/*=============================*/

/*===== TABLE ======*/
.buybox-widget .bb-table-container {
  width: 100%;
  overflow-x: auto;
}
.buybox-widget .bb-table {
  width: 100%;
  border: none;
  border-spacing: 0;
  border-collapse: collapse;
}

/** Rows **/
.buybox-widget .bb-row {
  min-height: 16px;
  box-sizing: unset;
  overflow: hidden;
}

/** Cells **/
.buybox-widget .bb-cell {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.buybox-widget .bb-cell a {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 32px;
  padding: 4px 6px;
  text-decoration: none;
}
.buybox-widget.bb-buybox-noshopname .bb-cell a {
  min-height: 59px;
}
.buybox-widget .bb-table-content.bb-fade .bb-row:last-child .bb-cell {
  border-bottom: none;
}
.buybox-widget .bb-table-content.bb-fade .bb-row:last-child .bb-cell:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 33px;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 1) 100%
  );
}
.buybox-widget.bb-buybox-noshopname .bb-table-content.bb-fade .bb-row:last-child .bb-cell:after {
  height: 67px;
}

/** Store **/
.buybox-widget .bb-store {
  text-align: left;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Store logo **/
.buybox-widget .bb-store img {
  float: left;
  padding: 0;
  margin: 0 6px 0 0 !important;
  border: 0;
  box-shadow: 0 0 0 #fff;
  width: 16px;
  height: 16px;
  min-width: 16px;
  line-height: 16px;
}
.buybox-widget.bb-buybox-noshopname .bb-store img {
  width: auto;
  height: 100%;
  max-width: 100%;
  max-height: 50px;
  line-height: 50px;
}

/** Price **/
.buybox-widget .bb-price a {
  justify-content: flex-end;
}

/** Additional column **/
.buybox-widget .bb-additional a {
  justify-content: flex-end;
}

/** Go to store **/
.buybox-widget .bb-go-to-store a {
  justify-content: flex-end;
}
.buybox-widget .bb-go-to-store span {
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
}
/*=============================*/

/*=====  TABS ABOVE THE TABLE ======*/
/** Tabs container **/
.buybox-widget .bb-table-filters {
  min-height: 16px;
  box-sizing: unset;
  overflow: hidden;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 4px 0 0;
  margin: 0;
  overflow-x: auto;
}

.buybox-widget .bb-tabs {
  font-size: 11px;
}
.buybox-widget .bb-tabs .active {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-radius: 3px 3px 0 0;
}
.buybox-widget .bb-tabs a {
  text-decoration: none;
  background: none;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  cursor: pointer;
}
.buybox-widget .bb-tab {
  position: relative;
  top: 1px;
  display: inline-block;
  padding: 4px 14px;
  text-transform: capitalize;
}
.buybox-widget .bb-tab.bb-fade:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 33px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 1) 100%
  );
  bottom: 0;
}
/*=============================*/

/*=====  WIDGET FOOTER ======*/
.buybox-widget .bb-footer {
  padding: 3px 10px 3px;
  font-size: 9px;
  text-align: right;
}
.buybox-widget .bb-footer a {
  text-decoration: none;
  text-transform: capitalize;
}
.buybox-widget .bb-footer a:hover {
  text-decoration: underline;
}
/*=============================*/

/*=====  SHOW MORE BUTTON ======*/
.buybox-widget .bb-show-more {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.buybox-widget .bb-show-more span {
  padding: 6px 48px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  line-height: 18px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}
/*=============================*/

/*=====  VARIANTS ======*/
.buybox-widget .bb-variants {
  margin: 5px 0 15px;
}
.buybox-widget .bb-variants-option-name {
  font-weight: 600;
}
/*=============================*/

/*=====  DROPDOWN  ======*/
.buybox-widget .bb-dropdown {
  position: relative;
  display: inline-block;
  max-width: 100%;
  text-transform: capitalize;
  cursor: pointer;
}
.buybox-widget .bb-dropdown-disabled {
  opacity: 0.7;
  pointer-events: none;
}
.buybox-widget .bb-dropdown-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100px;
  max-width: 100%;
  height: 31px;
  padding: 0 30px 0 15px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
}
.buybox-widget .bb-dropdown-trigger-label {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.buybox-widget .bb-dropdown-trigger:after {
  content: "\25be";
  position: absolute;
  right: 10px;
  display: block;
  transition: transform 0.15s linear;
}
.buybox-widget .bb-dropdown.open .bb-dropdown-trigger:after {
  transform: rotateZ(180deg);
}
.buybox-widget .bb-dropdown-options {
  position: absolute;
  top: 34px;
  left: 0;
  right: 0;
  display: block;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.15s, opacity 0.15s linear;
}
.buybox-widget .bb-dropdown.open .bb-dropdown-options {
  visibility: visible;
  opacity: 1;
}
.buybox-widget .bb-dropdown-item {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  padding: 8px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.buybox-widget .bb-dropdown-item.active,
.buybox-widget .bb-dropdown-item:hover {
  background: rgba(52, 58, 64, 0.15);
}
/*=============================*/

/*=====  FORMAT DROPDOWN ======*/
.buybox-widget .bb-format-dropdown {
  position: relative;
  display: none;
  text-transform: capitalize;
  cursor: pointer;
}
.buybox-widget .bb-format-dropdown .bb-dropdown-trigger {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-radius: 3px 3px 0 0;
}
/*=============================*/

/*===== UTILS ======*/
.buybox-widget .bb-overflow-text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*=============================*/

/*===== COLORS ======*/
/** Widget box border color **/
.buybox-widget {
  border-color: rgba(72, 94, 144, 0.16);
  color: #4b4b5a;
}

/** Product name **/
.buybox-widget .bb-product-panel .bb-product-name {
  color: #4b4b5a;
}

/** Product information **/
.buybox-widget .bb-product-panel .bb-product-info {
  color: #a6a6a6;
}

/** Table footer - format filter tabs **/
.buybox-widget .bb-table-filters {
  border-bottom-color: #eee;
}
.buybox-widget .bb-tabs .active {
  background: #f4f4f4;
}
.buybox-widget .bb-tab {
  color: #4b4b5a;
}
.buybox-widget .bb-tab.active,
.buybox-widget .bb-tab:hover {
  color: #4b4b5a
}

/** Table content **/
.buybox-widget .bb-table-content,
.buybox-widget .bb-table-content a {
  color: #4b4b5a;
}

/** Table row **/
.buybox-widget .bb-row {
  padding: 11px 6px 8px;
  color: #4b4b5a;
}

/** Table row hover color **/
.buybox-widget .bb-row:hover .bb-cell {
  background: #f8f8f8;
}

/** Table Rows border color **/
.buybox-widget .bb-cell {
  border-bottom-color: #eee;
}

/** Price **/
.buybox-widget .bb-table-content .bb-price span {
  color: red;
  font-weight: 400;
}

/** Go to store text **/
.buybox-widget .bb-table-content .bb-go-to-store span {
  color: #ffffff;
}

/** Show more button **/
.buybox-widget .bb-show-more {
  color: #fff;
}
.buybox-widget .bb-show-more span {
  color: #343a40;
}

/** Footer **/
.buybox-widget .bb-footer a {
  color: #637388;
}

/** Dropdown **/
.buybox-widget .bb-dropdown-trigger {
  border-color: #eee;
}
.buybox-widget .bb-dropdown-options {
  background: #fff;
  border-color: #eee;
}
/*=============================*/

/*===== RESPONSIVE SIZES ======*/
.bb-popover-container .buybox-widget .bb-table-filters,
.bb-overlay-container .buybox-widget .bb-table-filters,
.buybox-widget.bb-size-md .bb-table-filters,
.buybox-widget.bb-size-sm .bb-table-filters,
.buybox-widget.bb-size-xs .bb-table-filters {
  overflow: visible;
}
.bb-popover-container .buybox-widget .bb-tabs,
.bb-overlay-container .buybox-widget .bb-tabs,
.buybox-widget.bb-size-md .bb-tabs,
.buybox-widget.bb-size-sm .bb-tabs,
.buybox-widget.bb-size-xs .bb-tabs {
  display: none;
}
.bb-popover-container .buybox-widget .bb-format-dropdown,
.bb-overlay-container .buybox-widget .bb-format-dropdown,
.buybox-widget.bb-size-md .bb-format-dropdown,
.buybox-widget.bb-size-sm .bb-format-dropdown,
.buybox-widget.bb-size-xs .bb-format-dropdown {
  display: inline-block;
}

/** Size: EXTRA LARGE **/
.buybox-widget.bb-size-xlg {}

/** Size: LARGE **/
.buybox-widget.bb-size-lg:not(.bb-buybox-noformat):not(.bb-buybox-noprices) .bb-additional {
  display: none;
}

/** Size: MEDIUM **/
.buybox-widget.bb-size-md .bb-additional,
.buybox-widget.bb-size-md:not(.bb-100shoppers):not(.bb-buybox-noformat):not(.bb-buybox-noprices) .bb-go-to-store,
.buybox-widget.bb-size-md.bb-100shoppers:not(.bb-buybox-noprices) .bb-type {
  display: none;
}

/** Size: SMALL **/
.buybox-widget.bb-size-sm .bb-additional,
.buybox-widget.bb-size-sm:not(.bb-100shoppers):not(.bb-buybox-noprices) .bb-type,
.buybox-widget.bb-size-sm:not(.bb-100shoppers):not(.bb-buybox-noformat) .bb-go-to-store,
.buybox-widget.bb-size-sm:not(.bb-100shoppers):not(.bb-buybox-noprices) .bb-go-to-store,
.buybox-widget.bb-size-sm.bb-100shoppers .bb-type,
.buybox-widget.bb-size-sm.bb-100shoppers .bb-price {
  display: none;
}

/** Size: EXTRA SMALL **/
.buybox-widget.bb-size-xs .bb-type,
.buybox-widget.bb-size-xs .bb-price,
.buybox-widget.bb-size-xs .bb-additional,
.buybox-widget.bb-size-xs .bb-go-to-store {
  display: none;
}
/*=============================*/

/*===== * PLEASE DO NOT CHANGE * SPECIAL WIDGET BEHAVIOUR * PLEASE DO NOT CHANGE * ======*/
.bb-hidden,
.bb-widget-progress {
  display: none !important;
}
.bb-shown {
  display: block !important;
}
/*=============================*/
</style>
