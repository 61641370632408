<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <div class="full-width mb-4">
                <v-layout
                    row
                    wrap
                    align-center
                    justify-space-between
                >
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('salesSummary.title') }}
                        <v-tooltip v-if="!loading" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    type="button"
                                    class="mb-2"
                                    color="tertiary"
                                    :disabled="!!error || !items || items.length === 0"
                                    @click="exportData"
                                    flat
                                    icon
                                >
                                    <v-icon small>fa-download</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('export') }}</span>
                        </v-tooltip>
                    </h3>
                    <v-layout
                        wrap
                        align-center
                        justify-end
                    >
                        <v-flex shrink>
                            <SelectAll
                                v-if="spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)"
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="sources"
                                @blur="handleSourcesChange"
                                :items="sourcesOptions"
                                :disabled="loading"
                                placeholder=" "
                                :label="$t('filters.sources')"
                                outline
                                hide-details
                                dense
                                multiple
                                :loading="uniqueSourcesLoading"
                            />
                            <FormSourcesPlaceholder v-else />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="conversion"
                                @change="handleConversionChange"
                                :items="conversionOptions"
                                :disabled="loading"
                                placeholder=" "
                                :label="$t('salesSummary.conversion')"
                                outline
                                hide-details
                                dense
                            />
                        </v-flex>
                        <v-flex shrink>
                            <select-all
                                v-if="region !== 'PL'"
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="campaign"
                                :items="campaignOptions"
                                :disabled="loading"
                                :label="$t('salesSummary.shop')"
                                :all-label="$t('salesSummary.allShops')"
                                require-selection
                                @blur="handleCampaignBlur"
                            />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="period"
                                @change="handlePeriodChange"
                                :items="periodOptions"
                                :disabled="loading"
                                placeholder=" "
                                :label="$t('filters.period')"
                                outline
                                hide-details
                                dense
                            />
                        </v-flex>
                        <v-flex v-show="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="loading"
                                :label="$t('productsRanking.group')"
                                :all-label="$t('productsRanking.allGroups')"
                                require-selection
                                @blur="getData()"
                            />
                        </v-flex>
                        <v-btn
                            v-if="error"
                            class="mb-3"
                            @click="redo"
                            flat
                            icon
                        >
                            <v-icon small>fa-redo-alt</v-icon>
                        </v-btn>
                    </v-layout>
                </v-layout>
                <div>
                    <v-layout wrap align-center justify-end>
                        <v-flex class="mb-3 ml-3" shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loading"
                                @change="getData()"
                            />
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>
            <div v-if="!loading && !error">
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="headers"
                    :items="items"
                    :no-data-text="$t('salesSummary.noResults')"
                    :no-results-text="$t('salesSummary.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :pagination.sync="pagination"
                    :rows-per-page-items="rowsPerPageItems"
                    disable-initial-sort
                    sort-icon="fa-angle-up"
                >
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="header in props.headers"
                                :key="header.value"
                                :class="[
                                    'column sortable',
                                    header.value === pagination.sortBy ? 'active' : '',
                                ]"
                                @click="changeSort(header.value)"
                            >
                                <v-layout
                                    :justify-start="header.align === 'left'"
                                    :justify-end="header.align === 'right'"
                                    align-center
                                >
                                    {{ header.text }}
                                    <sort-order :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <td class="no-wrap-text">
                            {{ formatDate(props.item.date) }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.viewsFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.clicksFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.ctrFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.transactionsFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.crFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.quantityFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.valueFormatted }}
                        </td>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import { sortBy } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import spaceService from '@/services/spaceService';
import FormSourcesPlaceholder from '@/components/common/FormSourcesPlaceholder.vue';
import sourcesFilterMixin from '@/mixins/sourcesFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import numberService from '../../../../services/numberService';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import dateService from '../../../../services/dateService';
import SortOrder from '../../../common/SortOrder.vue';
import exportService from '../../../../services/exportService';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';

export default {
    name: 'sales-summary',
    components: {
        SelectAll,
        SortOrder,
        FormSourcesPlaceholder,
    },
    mixins: [
        campaignFilterMixin,
        sourcesFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            spaceService,
            region: process.env.VUE_APP_REGION_MODE,
            headers: [
                {
                    text: this.$t('salesSummary.date'),
                    align: 'left',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: this.$t('salesSummary.views'),
                    align: 'right',
                    sortable: true,
                    value: 'views',
                },
                {
                    text: this.$t('salesSummary.clicks'),
                    align: 'right',
                    sortable: true,
                    value: 'clicks',
                },
                {
                    text: this.$t('salesSummary.ctr'),
                    align: 'right',
                    sortable: true,
                    value: 'ctr',
                },
                {
                    text: this.$t('salesSummary.transaction'),
                    align: 'right',
                    sortable: true,
                    value: 'transactions',
                },
                {
                    text: this.$t('salesSummary.cr'),
                    align: 'right',
                    sortable: true,
                    value: 'cr',
                },
                {
                    text: this.$t('salesSummary.quantity'),
                    align: 'right',
                    sortable: true,
                    value: 'quantity',
                },
                {
                    text: this.$t('salesSummary.amount'),
                    align: 'right',
                    sortable: true,
                    value: 'value',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'date',
                descending: true,
            },
            data: null,
            conversion: 24,
            period: 'daily',
            conversionOptions: [
                {
                    value: 'all',
                    text: this.$t('salesSummary.allConversion'),
                },
                {
                    value: 24,
                    text: this.$t('salesSummary.nConversion', { hours: 24 }),
                },
            ],
            periodOptions: [
                {
                    value: 'daily',
                    text: this.$t('filters.periodDaily'),
                },
                {
                    value: 'monthly',
                    text: this.$t('filters.periodMonthly'),
                },
                {
                    value: 'quarterly',
                    text: this.$t('filters.periodQuarterly'),
                },
                {
                    value: 'annually',
                    text: this.$t('filters.periodAnnually'),
                },
            ],
            error: null,
            showProductGroupsResults: false,
        };
    },
    computed: {
        loading() {
            return this.summaryLoading || this.filtersLoading;
        },
        items() {
            if (!this.data) {
                return [];
            }

            return this.data;
        },
        dateFormatName() {
            return dateService.getFormatFromPeriod(this.period);
        },
        sourcesOptions() {
            return this.uniqueSources || [];
        },
        filtersLoading() {
            return this.uniqueSourcesLoading || this.groupsLoading;
        },
        ...mapState({
            summaryLoading: state => state.reportsSales.loading.summary,
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            getterSummary: 'reportsSales/getSummary',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        filtersLoading(loading) {
            if (!loading) this.reset();
        },
    },
    created() {
        this.setDefaultCampaign(['PL']);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
        eventBus.$on('change:conversion', this.setConversionFilter);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
        eventBus.$off('change:conversion', this.setConversionFilter);
    },
    methods: {
        reset() {
            this.conversion = 24;
            this.setDefaultCampaign(['PL']);
            this.period = 'daily';
            this.setDefaultGroup();
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.period === 'daily' && this.conversion === 24 && this.isDefaultCampaign(['PL']);
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getSummary(force);
        },
        handleCampaignBlur() {
            this.getData();
        },
        formatDate(date) {
            return dateService.formatI18nDate(date, this.dateFormatName);
        },
        handleConversionChange() {
            this.getData();
            eventBus.$emit('change:conversion', {
                conversion: this.conversion,
                // eslint-disable-next-line no-underscore-dangle
                uid: this._uid,
            });
        },
        handlePeriodChange() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        setConversionFilter(e) {
            // eslint-disable-next-line no-underscore-dangle
            if (e.uid === this._uid) {
                return;
            }
            this.conversion = e.conversion;
            this.getData();
        },
        async getSummary(force = false) {
            if (this.filtersLoading) return;
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    conversion: this.conversion,
                    campaign: this.campaign,
                    period: this.period,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchSummary(params);
                let data = this.getterSummary(params.startDate, params.endDate, params.conversion, params.campaign, params.period, params.sources, params.group);
                if (data) {
                    data = data.map(item => ({
                        ...item,
                        viewsFormatted: numberService.formatNumber(item.views),
                        clicksFormatted: numberService.formatNumber(item.clicks),
                        ctrFormatted: numberService.formatPercent(item.ctr),
                        transactionsFormatted: numberService.formatNumber(item.transactions),
                        crFormatted: numberService.formatPercent(item.cr),
                        quantityFormatted: numberService.formatNumber(item.quantity),
                        valueFormatted: numberService.formatCurrency(item.value),
                    }));
                }
                this.data = data;
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('dataFor'))}"`);
            header.push(`"${exportService.parseText(this.$t('exportingOn'))}"`);
            header.push(`"${exportService.parseText(this.$t('selectedStores'))}"`);

            header.push(`"${exportService.parseText(this.$t('salesSummary.date'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.views'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.clicks'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.ctr'))} [%]"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.transaction'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.cr'))} [%]"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.quantity'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesSummary.value'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);

            const rows = [];
            this.items.forEach((item, index) => {
                const row = [];
                if (index === 0) {
                    row.push(`"${exportService.parseText(this.dateFilter.startDate)} - ${exportService.parseText(this.dateFilter.endDate)}"`);
                    row.push(`"${exportService.parseText(new Date().toLocaleDateString())}"`);
                    row.push(`"${exportService.parseText(this.campaign.map(id => this.getCampaignNameFromId(id)).join(', '))}"`);
                } else {
                    row.push('""');
                    row.push('""');
                    row.push('""');
                }

                row.push(`"${exportService.parseText(item.date && this.$d(new Date(item.date), 'short'))}"`);
                row.push(`"${numberService.formatNumber(item.views, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.clicks, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.ctr, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.transactions, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.cr, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.quantity, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.value, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('salesSummary.title'));
        },
        handleSourcesChange() {
            this.getData();
        },
        ...mapActions({
            fetchSummary: 'reportsSales/fetchSummary',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
