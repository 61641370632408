<template>
    <div class="grey lighten-4 pa-4 mb-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('productCode.title') }}</h3>
        </v-card-title>
        <v-card-text>
            <p class="caption description">{{ $t('productCode.description') }}</p>
            <p class="caption">
                <a :href="helperUrl" target="_blank">{{ helperText }}</a>
            </p>

            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-tabs v-if="!loading && !error && getterPlugin" v-model="activeTab" hide-slider>
                <v-tab key="html">
                    {{ $t('productCode.html') }}
                </v-tab>
                <v-tab key="json">
                    {{ $t('productCode.json') }}
                </v-tab>
                <v-tab-item key="html" lazy>
                    <v-card class="tab-content" flat>
                        <v-card-text>
                            <div
                                v-if="htmlSnippet"
                                ref="htmlCode"
                                class="code-block"
                                v-html="htmlSnippet.html"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="json" lazy>
                    <v-card class="tab-content" flat>
                        <v-card-text>
                            <div v-if="jsonSnippet" class="code-block" v-html="jsonSnippet.html" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>

            <v-alert
                :value="errorMsg"
                dismissible
                type="error"
                transition="scale-transition">
                {{errorMsg}}
            </v-alert>

            <div v-if="!loading && !error && getterPlugin" class="mt-4 text-xs-center">
                <v-btn
                    class="btn-wide"
                    color="tertiary"
                    dark
                    round
                    @click="copy"
                >
                    {{ $t('productCode.copy') }}
                </v-btn>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: 'product-code',
    props: {
        product: {
            type: Object,
            default() {
                return undefined;
            },
        },
        productDetails: {
            type: Object,
            default() {
                return undefined;
            },
        },
    },
    data() {
        return {
            activeTab: 0,
            error: null,
            copyError: null,
        };
    },
    computed: {
        loading() {
            return this.fetchPluginsLoading;
        },
        errorMsg() {
            return this.error || this.copyError;
        },
        name() {
            if (!this.product || !this.product.text) {
                return this.$t('productCode.namePlaceholder');
            }
            return window.encodeURIComponent(this.product.text);
        },
        htmlName() {
            if (!this.product || !this.product.text) {
                return `<mark>${this.name}</mark>`;
            }
            return this.name;
        },
        ean() {
            if (!this.productDetails || !this.productDetails.ean) {
                return this.$t('productCode.gtinPlaceholder');
            }
            return window.encodeURIComponent(this.productDetails.ean);
        },
        htmlEan() {
            if (!this.productDetails || !this.productDetails.ean) {
                return `<mark>${this.ean}</mark>`;
            }
            return this.ean;
        },
        sku() {
            if (!this.productDetails || !this.productDetails.sku) {
                return this.$t('productCode.skuPlaceholder');
            }
            return window.encodeURIComponent(this.productDetails.sku);
        },
        htmlSku() {
            if (!this.productDetails || !this.productDetails.sku) {
                return `<mark>${this.sku}</mark>`;
            }
            return this.sku;
        },
        suggestedPrice() {
            if (!this.productDetails || !this.productDetails.price) {
                return this.$t('productCode.pricePlaceholder');
            }
            return window.encodeURIComponent(this.productDetails.price);
        },
        htmlSuggestedPrice() {
            if (!this.productDetails || !this.productDetails.price) {
                return `<mark>${this.suggestedPrice}</mark>`;
            }
            return this.suggestedPrice;
        },
        description() {
            if (!this.productDetails || !this.productDetails.description) {
                return this.$t('productCode.descriptionPlaceholder');
            }
            return window.encodeURIComponent(this.productDetails.description);
        },
        htmlDescription() {
            if (!this.productDetails || !this.productDetails.description) {
                return `<mark>${this.description}</mark>`;
            }
            return this.description;
        },
        image() {
            if (!this.productDetails || !this.productDetails.images || this.productDetails.images.length === 0) {
                return this.$t('productCode.imagePlaceholder');
            }
            return this.productDetails.images[0];
        },
        htmlImage() {
            if (!this.productDetails || !this.productDetails.images || this.productDetails.images.length === 0) {
                return `<mark>${this.image}</mark>`;
            }
            return this.image;
        },
        category() {
            if (!this.productDetails || !this.productDetails.categories || this.productDetails.categories.length === 0) {
                return this.$t('productCode.categoryPlaceholder');
            }
            return window.encodeURIComponent(this.productDetails.categories.map(category => category.name).join(' > '));
        },
        htmlCategory() {
            if (!this.productDetails || !this.productDetails.categories || this.productDetails.categories.length === 0) {
                return `<mark>${this.category}</mark>`;
            }
            return this.category;
        },
        htmlSnippet() {
            if (this.getterPlugin) {
                const domElementId = Math.random().toString(36).substring(2, 6);
                return {
                    // eslint-disable-next-line no-useless-escape
                    value: `<div class="bb-widget" id="buybox-${domElementId}" data-bb-id="${this.getterPlugin.id}" data-bb-number="${this.ean}" data-bb-sku="${this.sku}" data-bb-name="${this.name}" data-bb-category="${this.category}" data-bb-price="${this.suggestedPrice}" data-bb-description="${this.description}" data-bb-image="${this.image}"></div>`,
                    // eslint-disable-next-line no-useless-escape
                    html: `&#60;div class="bb-widget" id="buybox-${domElementId}" data-bb-id="${this.getterPlugin.id}" data-bb-number="${this.htmlEan}" data-bb-sku="${this.htmlSku}" data-bb-name="${this.htmlName}" data-bb-category="${this.htmlCategory}" data-bb-price="${this.htmlSuggestedPrice}" data-bb-description="${this.htmlDescription}" data-bb-image="${this.htmlImage}"&#62;&#60;/div&#62;`,
                };
            }
            return null;
        },
        jsonSnippet() {
            if (this.getterPlugin) {
                return {
                    value: `${process.env.VUE_APP_WIDGET_API_URL}/${this.getterPlugin.id}/buybox.json?number=${this.ean}&sku=${this.sku}&name=${this.name}&category=${this.category}&price=${this.suggestedPrice}&description=${this.description}&image=${this.image}`,
                    html: `${process.env.VUE_APP_WIDGET_API_URL}/${this.getterPlugin.id}/buybox.json?number=${this.htmlEan}&sku=${this.htmlSku}&name=${this.htmlName}&category=${this.htmlCategory}&price=${this.htmlSuggestedPrice}&description=${this.htmlDescription}&image=${this.htmlImage}`,
                };
            }
            return null;
        },
        helperText() {
            return this.activeTab === 0
                ? this.$t('productCode.htmlHelper')
                : this.$t('productCode.jsonHelper');
        },
        helperUrl() {
            return this.activeTab === 0
                ? this.$t('productCode.htmlHelperUrl')
                : this.$t('productCode.jsonHelperUrl');
        },
        ...mapState({
            fetchPluginsLoading: state => state.plugin.loading.fetchPlugins,
        }),
        ...mapGetters({
            getterPlugin: 'plugin/plugin',
        }),
    },
    created() {
        this.getPlugins();
    },
    methods: {
        async copy() {
            try {
                this.copyError = null;
                const message = this.activeTab === 0 ? this.htmlSnippet.value : this.jsonSnippet.value;
                await this.$copyText(message);
                this.$toast.info(this.$t('productCode.copySuccess'), { color: 'success' });
            } catch (e) {
                this.copyError = e.message;
            }
        },
        async getPlugins() {
            try {
                this.error = null;
                await this.fetchPlugins();
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchPlugins: 'plugin/fetchPlugins',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .description {
        margin-bottom: 6px;
    }

    .tab-content {
        border: 1px solid #e8e8e8;
        border-radius: 0 5px 5px 0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    .code-block {
        font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
        font-size: 14px;
        white-space: pre-wrap;
        word-break: break-word;
    }
</style>
