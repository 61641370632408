/* eslint-disable no-unused-vars */
import demoService from '@/services/demoService';
import {
    campaigns,
    rewriteArray,
    setProducerCampaigns,
    spaceCategoriesData,
} from '@/services/demo-services/_utils';

export default {
    permissions: demoService.USE_ORIGINAL_PROPERTY,

    demoSpace: demoService.USE_ORIGINAL_PROPERTY,

    hasPermission: demoService.USE_ORIGINAL_PROPERTY,

    fetchSpaces: demoService.USE_ORIGINAL_PROPERTY,

    createSpace: demoService.USE_ORIGINAL_PROPERTY,

    updateSpace: demoService.USE_ORIGINAL_PROPERTY,

    async fetchAllowedCampaigns(spaceId) {
        const { default: allowedCampaigns } = await import('../../mocks/allowedCampaigns.json');
        return allowedCampaigns;
    },

    async fetchProducerProductCategories(spaceId) {
        const { default: spaceCategories } = await import('../../mocks/spaceCategories.json');
        rewriteArray(spaceCategoriesData, spaceCategories);
        return spaceCategories;
    },

    activatePermission: demoService.USE_ORIGINAL_PROPERTY,

    deactivatePermission: demoService.USE_ORIGINAL_PROPERTY,

    async fetchPermissions(producerId, statuses) {
        const { default: producersSubscriptions } = await import('../../mocks/producersSubscriptions.json');
        return producersSubscriptions;
    },

    getStatusLabelType: demoService.USE_ORIGINAL_PROPERTY,

    getPermissionStatusLabelType: demoService.USE_ORIGINAL_PROPERTY,

    getPermissionContentKey: demoService.USE_ORIGINAL_PROPERTY,

    async fetchUniqueSources(spaceId, startDate, endDate) {
        const { default: data } = await import('../../mocks/uniqueSources.json');
        return data?.items;
    },
};
