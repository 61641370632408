import { i18n, getLocaleFromLanguage, loadLanguageAsync } from '../../i18n/i18n';
import router from '../../router/router';
import authService from '../../services/authService';
import {
    AUTHENTICATE_REQUEST,
    AUTHENTICATE_FAILURE,
    AUTHENTICATE_SUCCESS,
    LOGOUT,
    INITIALIZE_AUTH,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
    FETCH_USER_FULL_REQUEST,
    FETCH_USER_FULL_SUCCESS,
    FETCH_USER_FULL_FAILURE,
    UPDATE_PERSONAL_SETTINGS_REQUEST,
    UPDATE_PERSONAL_SETTINGS_SUCCESS,
    UPDATE_PERSONAL_SETTINGS_FAILURE,
    UPDATE_COMPANY_SETTINGS_REQUEST,
    UPDATE_COMPANY_SETTINGS_SUCCESS,
    UPDATE_COMPANY_SETTINGS_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    FETCH_USER_LIGHT_REQUEST,
    FETCH_USER_LIGHT_SUCCESS,
    FETCH_USER_LIGHT_FAILURE,
    AUTHENTICATE_AS_REQUEST,
    AUTHENTICATE_AS_SUCCESS,
    AUTHENTICATE_AS_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_COMPLETE_REQUEST,
    RESET_PASSWORD_COMPLETE_SUCCESS,
    RESET_PASSWORD_COMPLETE_FAILURE,
    ACCEPT_TERMS_REQUEST,
    ACCEPT_TERMS_SUCCESS,
    ACCEPT_TERMS_FAILURE,
    CONFIRM_EMAIL_REQUEST,
    CONFIRM_EMAIL_SUCCESS,
    CONFIRM_EMAIL_FAILURE,
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_REQUEST,
    FETCH_USER_PROFILE_FAILURE,
} from '../mutationTypes';

const initialState = {
    initialized: false,
    exp: null,
    user: null,
    isAdmin: window.localStorage.getItem('isAdmin') === 'true' || false,
    loading: {
        authenticate: false,
        authenticateAs: false,
        refreshToken: false,
        fetchUserLight: false,
        fetchUserProfile: false,
        fetchUserFull: false,
        updatePersonalSettings: false,
        updateCompanySettings: false,
        changePassword: false,
        resetPassword: false,
        resetPasswordComplete: false,
        acceptTerms: false,
        confirmEmail: false,
    },
};

const getters = {
    getUser: state => state.user,
    isSessionInitialized: state => state.initialized,
    isAuthenticated: state => !!state.user,
    isAdmin: state => state.isAdmin,
    isProducer: state => (!!(state.user && state.user.roles.includes('ROLE_PRODUCER'))),
    hasTermsAccepted: state => (!!(state.user && state.user.policies)),
};

const actions = {
    async validateSession({ commit }) {
        try {
            const tokenData = await authService.validateToken(window.localStorage.getItem('accessToken'));

            if (tokenData) {
                const {
                    exp, id, username, roles,
                } = tokenData;

                const [userLightResponse, userProfileResponse] = await Promise.all([
                    authService.fetchUserLight(),
                    authService.fetchUserProfile(id),
                ]);
                authService.checkIfUserAllowed(userLightResponse);
                const newLocale = getLocaleFromLanguage(userLightResponse.language);
                await loadLanguageAsync(newLocale);

                commit(AUTHENTICATE_SUCCESS, {
                    exp,
                    id,
                    username,
                    roles,
                });
                commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
                commit(FETCH_USER_PROFILE_SUCCESS, userProfileResponse);
            } else {
                commit(LOGOUT);
            }
        } catch (e) {
            commit(LOGOUT);
        }

        commit(INITIALIZE_AUTH);
    },

    async authenticate({ commit, dispatch }, payload) {
        try {
            commit(AUTHENTICATE_REQUEST);
            const { username, password, redirect } = payload;
            const authResponse = await authService.authenticate(username, password);
            const tokenData = await authService.parseToken(authResponse.token);

            if (!tokenData) {
                authService.logout();
                throw new Error('error.default');
            }

            const {
                exp, id, username: tokenUsername, roles,
            } = tokenData;

            const [userLightResponse, userProfileResponse] = await Promise.all([
                authService.fetchUserLight(),
                authService.fetchUserProfile(id),
            ]);
            authService.checkIfUserAllowed(userLightResponse);
            const newLocale = getLocaleFromLanguage(userLightResponse.language);
            await loadLanguageAsync(newLocale);

            dispatch('cleanStore');

            commit(AUTHENTICATE_SUCCESS, {
                exp,
                id,
                username: tokenUsername,
                roles,
            });
            commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
            commit(FETCH_USER_PROFILE_SUCCESS, userProfileResponse);

            authService.scheduleRefreshToken(exp);

            if (redirect) {
                router.push({ name: redirect });
            } else if (roles.includes('ROLE_PRODUCER')) {
                router.push({ name: 'producer' });
            } else {
                router.push({ path: '/' });
            }
        } catch (e) {
            commit(AUTHENTICATE_FAILURE);
            throw e;
        }
    },

    async authenticateAs({ commit, dispatch }, payload) {
        try {
            commit(AUTHENTICATE_AS_REQUEST);
            const { token, isAdmin } = payload;
            const authResponse = await authService.authenticateAs(token, isAdmin);
            const tokenData = await authService.parseToken(authResponse.token);

            if (!tokenData) {
                authService.logout();
                throw new Error('error.default');
            }

            const {
                exp, id, username: tokenUsername, roles,
            } = tokenData;

            const [userLightResponse, userProfileResponse] = await Promise.all([
                authService.fetchUserLight(),
                authService.fetchUserProfile(id),
            ]);
            authService.checkIfUserAllowed(userLightResponse);
            const newLocale = getLocaleFromLanguage(userLightResponse.language);
            await loadLanguageAsync(newLocale);

            dispatch('cleanStore');

            commit(AUTHENTICATE_SUCCESS, {
                exp,
                id,
                username: tokenUsername,
                roles,
            });
            commit(AUTHENTICATE_AS_SUCCESS, { isAdmin });
            commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
            commit(FETCH_USER_PROFILE_SUCCESS, userProfileResponse);

            authService.scheduleRefreshToken(exp);

            if (roles.includes('ROLE_PRODUCER')) {
                router.push({ name: 'producer' });
            } else {
                router.push({ path: '/' });
            }
        } catch (e) {
            commit(AUTHENTICATE_AS_FAILURE);
            throw e;
        }
    },

    async refreshToken({ commit }) {
        try {
            if (window.localStorage.getItem('refreshToken')) {
                commit(REFRESH_TOKEN_REQUEST);
                const authResponse = await authService.refreshToken();
                const tokenData = await authService.parseToken(authResponse.token);

                if (!tokenData) {
                    authService.logout();
                    throw new Error('error.default');
                }

                const { exp } = tokenData;

                commit(REFRESH_TOKEN_SUCCESS, {
                    exp,
                });

                authService.scheduleRefreshToken(exp);
                return tokenData;
            }
        } catch (e) {
            commit(REFRESH_TOKEN_FAILURE);
            throw e;
        }

        return null;
    },

    logout({ commit, dispatch }, payload = {}) {
        const { redirectUrl, noRedirect } = payload;
        authService.logout();
        commit(LOGOUT);
        dispatch('cleanStore');
        dispatch('cleanLocalStorage');
        if (noRedirect !== true) router.push({ name: 'login' });

        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    },

    cleanStore({ dispatch }) {
        dispatch('dictionary/cleanDictionary', null, { root: true });
        dispatch('space/cleanSpaces', null, { root: true });
        dispatch('products/cleanProducts', null, { root: true });
        dispatch('dashboard/cleanDashboard', null, { root: true });
        dispatch('plugin/cleanPlugin', null, { root: true });
        dispatch('reportsProducts/clean', null, { root: true });
        dispatch('reportsCategory/clean', null, { root: true });
        dispatch('reportsSales/clean', null, { root: true });
        dispatch('reportsShops/clean', null, { root: true });
        dispatch('reportsAvailability/clean', null, { root: true });
        dispatch('reportsPriceDistribution/clean', null, { root: true });
        dispatch('dataExport/clean', null, { root: true });
        dispatch('producerProductGroup/clearGroups', null, { root: true });
    },

    cleanLocalStorage() {
        const appKeys = ['locale', 'accessToken', 'refreshToken', 'isAdmin', 'dateRangePickerRange', 'dateRangePickerStartDate', 'dateRangePickerEndDate', 'spaceId'];
        Object.keys(appKeys).forEach(key => window.localStorage.removeItem(key));
    },

    async fetchUserFull({ commit, state }) {
        try {
            commit(FETCH_USER_FULL_REQUEST);
            const userId = state.user.id;
            const [userAccountResponse, userNotificationSettingsResponse, userProfileResponse] = await Promise.all([
                authService.fetchUserAccount(userId),
                authService.fetchUserNotificationSettings(userId),
                authService.fetchUserProfile(userId),
            ]);

            commit(FETCH_USER_FULL_SUCCESS, {
                userAccount: userAccountResponse,
                userNotifications: userNotificationSettingsResponse,
                userProfile: userProfileResponse,
            });
        } catch (e) {
            commit(FETCH_USER_FULL_FAILURE);
            throw e;
        }
    },

    async updatePersonalSettings({ commit, state }, payload) {
        try {
            commit(UPDATE_PERSONAL_SETTINGS_REQUEST);
            const {
                firstName, lastName, email, phone, notifications, language, demo,
            } = payload;
            const userId = state.user.id;
            await authService.updatePersonalSettings(userId, {
                email,
                demo,
                news: notifications,
                profile: {
                    language,
                    firstName,
                    lastName,
                    phone,
                },
            });

            const newLocale = getLocaleFromLanguage(language);
            await loadLanguageAsync(newLocale);
            const hasLanguageChanged = state.user.language !== language;
            const hasDemoChanged = state.user.demo !== demo;

            commit(UPDATE_PERSONAL_SETTINGS_SUCCESS, {
                email,
                notifications,
                language,
                firstName,
                lastName,
                phone,
                demo,
            });
            this._vm.$toast.info(i18n.t('accountSettingsPersonal.saveSuccess'), { color: 'success' });

            // TODO: remove hasDemoChanged and directly update spaces to add/remove demo space
            // reload page if language has changed
            // it's required because google charts can be loaded only in a single version/language
            if (hasLanguageChanged || hasDemoChanged) {
                window.setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (e) {
            commit(UPDATE_PERSONAL_SETTINGS_FAILURE);
            throw e;
        }
    },

    async updateCompanySettings({ commit, state }, payload) {
        try {
            commit(UPDATE_COMPANY_SETTINGS_REQUEST);
            const {
                companyName, companyNip, street, streetNumber, apartmentNumber, city, postcode, country,
            } = payload;
            const userId = state.user.id;
            await authService.updateCompanySettings(userId, {
                companyName,
                companyNip,
                street,
                streetNumber,
                apartmentNumber,
                city,
                postcode,
                country,
            });

            commit(UPDATE_COMPANY_SETTINGS_SUCCESS, {
                companyName,
                companyNip,
                street,
                streetNumber,
                apartmentNumber,
                city,
                postcode,
                country,
            });
            this._vm.$toast.info(i18n.t('accountSettingsCompany.saveSuccess'), { color: 'success' });
        } catch (e) {
            commit(UPDATE_COMPANY_SETTINGS_FAILURE);
            throw e;
        }
    },

    async changePassword({ commit }, payload) {
        try {
            commit(CHANGE_PASSWORD_REQUEST);
            const { oldPassword, newPassword, confirmPassword } = payload;
            await authService.changePassword({
                oldPassword,
                newPassword: {
                    first: newPassword,
                    second: confirmPassword,
                },
            });

            commit(CHANGE_PASSWORD_SUCCESS);
            this._vm.$toast.info(i18n.t('accountSettingsPassword.saveSuccess'), { color: 'success' });
        } catch (e) {
            commit(CHANGE_PASSWORD_FAILURE);
            throw e;
        }
    },

    async resetPassword({ commit }, payload) {
        try {
            commit(RESET_PASSWORD_REQUEST);
            const { email } = payload;
            await authService.resetPassword({ email });
            commit(RESET_PASSWORD_SUCCESS);
        } catch (e) {
            commit(RESET_PASSWORD_FAILURE);
            throw e;
        }
    },

    async resetPasswordComplete({ commit }, payload) {
        try {
            commit(RESET_PASSWORD_COMPLETE_REQUEST);
            const { token, password } = payload;
            await authService.resetPasswordComplete({ token, password });
            commit(RESET_PASSWORD_COMPLETE_SUCCESS);
            router.push({ name: 'login' });
            this._vm.$toast.info(i18n.t('resetPasswordComplete.success'), { color: 'success', timeout: 5000 });
        } catch (e) {
            commit(RESET_PASSWORD_COMPLETE_FAILURE);
            throw e;
        }
    },

    async acceptTerms({ commit, state }, payload) {
        try {
            commit(ACCEPT_TERMS_REQUEST);
            const { redirect } = payload;
            const { user } = state;
            await authService.acceptTerms();
            commit(ACCEPT_TERMS_SUCCESS);
            if (redirect) {
                router.push({ name: redirect });
            } else if (user.roles.includes('ROLE_PRODUCER')) {
                router.push({ name: 'producer' });
            } else {
                router.push({ path: '/' });
            }
        } catch (e) {
            commit(ACCEPT_TERMS_FAILURE);
            throw e;
        }
    },

    async confirmEmail({ commit, state }, payload) {
        try {
            commit(CONFIRM_EMAIL_REQUEST);
            const { token } = payload;
            await authService.confirmEmail(token);
            commit(CONFIRM_EMAIL_SUCCESS);
            this._vm.$toast.info(i18n.t('confirmEmail.success'), { color: 'success', timeout: 5000 });
            if (state.user) {
                const userLightResponse = await authService.fetchUserLight();
                authService.checkIfUserAllowed(userLightResponse);
                commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
            }
            router.push({ path: '/' });
        } catch (e) {
            commit(CONFIRM_EMAIL_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // INITIALIZE
    // -----------------------------------------
    [INITIALIZE_AUTH](state) {
        state.initialized = true;
    },

    // -----------------------------------------
    // AUTHENTICATE
    // -----------------------------------------
    [AUTHENTICATE_REQUEST](state) {
        state.loading.authenticate = true;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },
    [AUTHENTICATE_SUCCESS](state, payload) {
        const {
            exp, id, username, roles,
        } = payload;
        state.loading.authenticate = false;
        state.exp = exp;
        state.user = {
            id, username, roles,
        };
    },
    [AUTHENTICATE_FAILURE](state) {
        state.loading.authenticate = false;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // AUTHENTICATE_AS
    // -----------------------------------------
    [AUTHENTICATE_AS_REQUEST](state) {
        state.loading.authenticateAs = true;
        state.isAdmin = false;
    },
    [AUTHENTICATE_AS_SUCCESS](state, payload) {
        const { isAdmin } = payload;
        state.loading.authenticateAs = false;
        state.isAdmin = !!isAdmin;
    },
    [AUTHENTICATE_AS_FAILURE](state) {
        state.loading.authenticateAs = false;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // REFRESH_TOKEN
    // -----------------------------------------
    [REFRESH_TOKEN_REQUEST](state) {
        state.loading.refreshToken = true;
    },
    [REFRESH_TOKEN_SUCCESS](state, payload) {
        const { exp } = payload;
        state.loading.refreshToken = false;
        state.exp = exp;
    },
    [REFRESH_TOKEN_FAILURE](state) {
        state.loading.refreshToken = false;
    },

    // -----------------------------------------
    // LOGOUT
    // -----------------------------------------
    [LOGOUT](state) {
        state.loading.authenticate = false;
        state.loading.authenticateAs = false;
        state.loading.refreshToken = false;
        state.loading.fetchUserLight = false;
        state.loading.fetchUserProfile = false;
        state.loading.fetchUserFull = false;
        state.loading.updatePersonalSettings = false;
        state.loading.updateCompanySettings = false;
        state.loading.changePassword = false;
        state.loading.resetPassword = false;
        state.loading.resetPasswordComplete = false;
        state.loading.acceptTerms = false;
        state.loading.confirmEmail = false;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // FETCH_USER_LIGHT
    // -----------------------------------------
    [FETCH_USER_LIGHT_REQUEST](state) {
        state.loading.fetchUserLight = true;
    },
    [FETCH_USER_LIGHT_SUCCESS](state, payload) {
        const {
            id, username, fullName, email, language, policies, demo, createdAt,
        } = payload;
        state.loading.fetchUserLight = false;
        state.user = {
            ...state.user,
            id,
            username,
            fullName,
            email,
            language,
            policies,
            demo,
            createdAt,
        };
    },
    [FETCH_USER_LIGHT_FAILURE](state) {
        state.loading.fetchUserLight = false;
    },

    // -----------------------------------------
    // FETCH_USER_PROFILE
    // -----------------------------------------
    [FETCH_USER_PROFILE_REQUEST](state) {
        state.loading.fetchUserProfile = true;
    },
    [FETCH_USER_PROFILE_SUCCESS](state, payload) {
        const {
            language,
            firstName,
            lastName,
            phone,
            country,
            city,
            postcode,
            street,
            streetNumber,
            apartmentNumber,
            isCompany,
            companyName,
            companyNip,
        } = payload;
        state.loading.fetchUserProfile = false;
        state.user = {
            ...state.user,
            language,
            firstName,
            lastName,
            fullName: `${firstName} ${lastName}`,
            phone,
            country,
            city,
            postcode,
            street,
            streetNumber,
            apartmentNumber,
            isCompany,
            companyName,
            companyNip,
        };
    },
    [FETCH_USER_PROFILE_FAILURE](state) {
        state.loading.fetchUserProfile = false;
    },

    // -----------------------------------------
    // FETCH_USER_FULL
    // -----------------------------------------
    [FETCH_USER_FULL_REQUEST](state) {
        state.loading.fetchUserFull = true;
    },
    [FETCH_USER_FULL_SUCCESS](state, payload) {
        const { userAccount, userNotifications, userProfile } = payload;
        state.loading.fetchUserFull = false;
        state.user = {
            ...state.user,
            demo: userAccount.demo,
            email: userAccount.email,
            notifications: userNotifications,
            language: userProfile.language,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            fullName: `${userProfile.firstName} ${userProfile.lastName}`,
            phone: userProfile.phone,
            country: userProfile.country,
            city: userProfile.city,
            postcode: userProfile.postcode,
            street: userProfile.street,
            streetNumber: userProfile.streetNumber,
            apartmentNumber: userProfile.apartmentNumber,
            isCompany: userProfile.isCompany,
            companyName: userProfile.companyName,
            companyNip: userProfile.companyNip,
        };
    },
    [FETCH_USER_FULL_FAILURE](state) {
        state.loading.fetchUserFull = false;
    },

    // -----------------------------------------
    // UPDATE_PERSONAL_SETTINGS
    // -----------------------------------------
    [UPDATE_PERSONAL_SETTINGS_REQUEST](state) {
        state.loading.updatePersonalSettings = true;
    },
    [UPDATE_PERSONAL_SETTINGS_SUCCESS](state, payload) {
        const {
            firstName, lastName, email, phone, notifications, language, demo,
        } = payload;
        state.loading.updatePersonalSettings = false;
        state.user = {
            ...state.user,
            firstName,
            lastName,
            fullName: `${firstName} ${lastName}`,
            email,
            phone,
            notifications: {
                ...state.user.notifications,
                news: notifications,
            },
            language,
            demo,
        };
    },
    [UPDATE_PERSONAL_SETTINGS_FAILURE](state) {
        state.loading.updatePersonalSettings = false;
    },

    // -----------------------------------------
    // UPDATE_COMPANY_SETTINGS
    // -----------------------------------------
    [UPDATE_COMPANY_SETTINGS_REQUEST](state) {
        state.loading.updateCompanySettings = true;
    },
    [UPDATE_COMPANY_SETTINGS_SUCCESS](state, payload) {
        const {
            companyName, companyNip, street, streetNumber, apartmentNumber, city, postcode, country,
        } = payload;
        state.loading.updateCompanySettings = false;
        state.user = {
            ...state.user,
            companyName,
            companyNip,
            street,
            streetNumber,
            apartmentNumber,
            city,
            postcode,
            country,
        };
    },
    [UPDATE_COMPANY_SETTINGS_FAILURE](state) {
        state.loading.updateCompanySettings = false;
    },

    // -----------------------------------------
    // CHANGE_PASSWORD
    // -----------------------------------------
    [CHANGE_PASSWORD_REQUEST](state) {
        state.loading.changePassword = true;
    },
    [CHANGE_PASSWORD_SUCCESS](state) {
        state.loading.changePassword = false;
    },
    [CHANGE_PASSWORD_FAILURE](state) {
        state.loading.changePassword = false;
    },

    // -----------------------------------------
    // RESET_PASSWORD
    // -----------------------------------------
    [RESET_PASSWORD_REQUEST](state) {
        state.loading.resetPassword = true;
    },
    [RESET_PASSWORD_SUCCESS](state) {
        state.loading.resetPassword = false;
    },
    [RESET_PASSWORD_FAILURE](state) {
        state.loading.resetPassword = false;
    },

    // -----------------------------------------
    // RESET_PASSWORD_COMPLETE
    // -----------------------------------------
    [RESET_PASSWORD_COMPLETE_REQUEST](state) {
        state.loading.resetPasswordComplete = true;
    },
    [RESET_PASSWORD_COMPLETE_SUCCESS](state) {
        state.loading.resetPasswordComplete = false;
    },
    [RESET_PASSWORD_COMPLETE_FAILURE](state) {
        state.loading.resetPasswordComplete = false;
    },

    // -----------------------------------------
    // ACCEPT_TERMS
    // -----------------------------------------
    [ACCEPT_TERMS_REQUEST](state) {
        state.loading.acceptTerms = true;
    },
    [ACCEPT_TERMS_SUCCESS](state) {
        state.loading.acceptTerms = false;
        state.user.policies = true;
    },
    [ACCEPT_TERMS_FAILURE](state) {
        state.loading.acceptTerms = false;
    },

    // -----------------------------------------
    // CONFIRM_EMAIL
    // -----------------------------------------
    [CONFIRM_EMAIL_REQUEST](state) {
        state.loading.confirmEmail = true;
    },
    [CONFIRM_EMAIL_SUCCESS](state) {
        state.loading.confirmEmail = false;
    },
    [CONFIRM_EMAIL_FAILURE](state) {
        state.loading.confirmEmail = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
