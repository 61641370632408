import { render, staticRenderFns } from "./ShopsSales.vue?vue&type=template&id=813b1186&scoped=true&"
import script from "./ShopsSales.vue?vue&type=script&lang=js&"
export * from "./ShopsSales.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "813b1186",
  null
  
)

export default component.exports