<template>
    <div>
        <div v-if="!isReady" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <GChart
            type="Timeline"
            :settings="{ packages: ['timeline'], language: $i18n.locale }"
            :data="data"
            :options="chartOptions"
            :events="chartEvents"
            @ready="onChartReady"
        />
    </div>
</template>

<script>
import { merge } from 'lodash';
import { GChart } from 'vue-google-charts';

export default {
    name: 'timeline',
    components: {
        GChart,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            chartHeight: 0,
            baseOptions: {
                timeline: {
                    singleColor: this.$vuetify.theme.primary,
                },
                backgroundColor: '#f5f5f5',
            },
            chartEvents: {
                ready: () => {
                    this.isReady = true;
                    this.$emit('ready', { chart: this.chart });
                },
            },
            isReady: false,
            chart: null,
            google: null,
        };
    },
    computed: {
        chartOptions() {
            return merge(this.baseOptions, { height: this.chartHeight }, this.options);
        },
    },
    watch: {
        data() {
            this.calculateContainerHeight();
        },
    },
    created() {
        this.calculateContainerHeight();
    },
    methods: {
        onChartReady(chart, google) {
            this.chart = chart;
            this.google = google;
        },
        calculateContainerHeight() {
            const rowsNames = [];
            this.data.forEach((row, index) => {
                // ignore first row which contains columns configuration
                if (index > 0) {
                    rowsNames.push(row[0]);
                }
            });
            const uniqueRows = rowsNames.filter((value, index, arr) => arr.indexOf(value) === index).length;
            this.chartHeight = (uniqueRows * 41) + 50;
        },
    },
};
</script>

<style lang="scss">
    .timeline-row-label--marked {
        font-weight: 700;
        fill: $primary-color;
    }

    .timeline-row-label--clickable {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
</style>
