<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Dashboard" />
            </template>
        </page-header-filters>
        <v-card class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('producerDashboard.noSpace')" />

                <div v-if="!loading && spaceId">
                    <v-layout
                        class="mb-2 stat-box__container"
                        justify-space-between
                        row
                        wrap>
                        <stat-box
                            :title="$t('producerDashboard.impressionsWidget')"
                            :tooltip="$t('producerDashboard.impressionsWidgetTooltip')"
                            :loading="impressionsStatsLoading"
                            :value="impressionsStats.impressions"
                            :change="impressionsStats.impressionsChange"
                            :allow-reload="!!impressionsStatsError"
                            @reload="redoImpressionsStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.clicks')"
                            :tooltip="$t('producerDashboard.clicksTooltip')"
                            :loading="clicksStatsLoading"
                            :value="clicksStats.clicks"
                            :change="clicksStats.clicksChange"
                            :allow-reload="!!clicksStatsError"
                            @reload="redoClicksStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.ctr')"
                            :tooltip="$t('producerDashboard.ctrTooltip')"
                            :loading="clicksStatsLoading || impressionsStatsLoading"
                            :value="ctrStats.ctr"
                            :change="ctrStats.ctrChange"
                            value-type="percent"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.transactions')"
                            :tooltip="$t('producerDashboard.transactionsTooltip')"
                            :loading="ordersStatsLoading"
                            :value="ordersStats.orders"
                            :change="ordersStats.ordersChange"
                            :allow-reload="!!ordersStatsError"
                            @reload="redoOrdersStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.cr')"
                            :tooltip="$t('producerDashboard.crTooltip')"
                            :loading="clicksStatsLoading || ordersStatsLoading"
                            :value="crStats.cr"
                            :change="crStats.crChange"
                            value-type="percent"
                            size="small"
                        />
                    </v-layout>

                    <v-layout
                        class="mb-2 stat-box__container"
                        justify-space-between
                        row
                        wrap>
                        <stat-box
                            :title="$t('producerDashboard.quantity')"
                            :tooltip="$t('producerDashboard.quantityTooltip')"
                            :loading="salesStatsLoading"
                            :value="salesStats.quantity"
                            :change="salesStats.quantityChange"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.salesAmountOwn')"
                            :tooltip="$t('producerDashboard.salesAmountOwnTooltip')"
                            :loading="salesStatsLoading"
                            :value="salesStats.amount"
                            :change="salesStats.amountChange"
                            value-type="currency"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.salesAmountOther')"
                            :tooltip="$t('producerDashboard.salesAmountOtherTooltip')"
                            :loading="salesStatsLoading"
                            :value="salesStats.amountOther"
                            :change="salesStats.amountOtherChange"
                            value-type="currency"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                        <stat-box
                            :title="$t('producerDashboard.salesAmountUnknown')"
                            :tooltip="$t('producerDashboard.salesAmountUnknownTooltip')"
                            :loading="salesStatsLoading"
                            :value="salesStats.amountUnknown"
                            :change="salesStats.amountUnknownChange"
                            value-type="currency"
                            :allow-reload="!!salesStatsError"
                            @reload="redoSalesStats"
                            size="small"
                        />
                    </v-layout>

                    <v-alert
                        class="mb-3"
                        :value="simpleStatsError"
                        dismissible
                        type="error"
                        transition="scale-transition"
                    >{{ simpleStatsError }}</v-alert>

                    <dashboard-traffic :space-id="spaceId" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('traffic', $event)" />
                    <dashboard-sales :space-id="spaceId" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('sales', $event)" />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import StatBox from '../../components/common/StatBox.vue';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DateRangePicker from '../../components/common/DateRangePicker.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import DashboardTraffic from '../../components/producer/dashboard/DashbaordTraffic.vue';
import DashboardSales from '../../components/producer/dashboard/DashbaordSales.vue';
import ResetFiltersButton from '../../components/common/ResetFiltersButton.vue';

export default {
    name: 'producer-dashboard',
    components: {
        ResetFiltersButton,
        DataPlaceholder,
        DashboardTraffic,
        DashboardSales,
        DateRangePicker,
        PageHeaderFilters,
        SpaceSwitch,
        StatBox,
    },
    data() {
        return {
            spaceInitialized: false,
            loadingConfig: false,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            clicksStats: {},
            salesStats: {},
            ordersStats: {},
            impressionsStats: {},
            ctrStats: {},
            crStats: {},
            clicksStatsError: null,
            salesStatsError: null,
            ordersStatsError: null,
            impressionsStatsError: null,
            isDatepickerFilterDefault: true,
            isTrafficFilterDefault: true,
            isSalesFilterDefault: true,
        };
    },
    computed: {
        simpleStatsError() {
            // display error only from a single simple stats endpoint to reduce bloat in UI
            if (this.clicksStatsError) {
                return this.clicksStatsError;
            }
            if (this.salesStatsError) {
                return this.salesStatsError;
            }
            if (this.ordersStatsError) {
                return this.ordersStatsError;
            }
            if (this.impressionsStatsError) {
                return this.impressionsStatsError;
            }
            return null;
        },
        loading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isTrafficFilterDefault || !this.isSalesFilterDefault;
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            clicksStatsLoading: state => state.dashboard.loading.clicksStats,
            salesStatsLoading: state => state.dashboard.loading.salesStats,
            ordersStatsLoading: state => state.dashboard.loading.ordersStats,
            impressionsStatsLoading: state => state.dashboard.loading.impressionsStats,
        }),
        ...mapGetters({
            getterDashboardClicksStats: 'dashboard/getDashboardClicksStats',
            getterDashboardSalesStats: 'dashboard/getDashboardSalesStats',
            getterDashboardOrdersStats: 'dashboard/getDashboardOrdersStats',
            getterDashboardImpressionsStats: 'dashboard/getDashboardImpressionsStats',
            getterDashboardCTRStats: 'dashboard/getDashboardCTRStats',
            getterDashboardCRStats: 'dashboard/getDashboardCRStats',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                if (this.spaceInitialized) {
                    this.getData();
                }
            }
        },
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'traffic') {
                this.isTrafficFilterDefault = isDefault;
            } else if (type === 'sales') {
                this.isSalesFilterDefault = isDefault;
            }
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        async handleSpaceChange() {
            await this.getData();
            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
        },
        redoImpressionsStats() {
            this.getImpressionsStats(true);
        },
        redoClicksStats() {
            this.getClicksStats(true);
        },
        redoSalesStats() {
            this.getSalesStats(true);
        },
        redoOrdersStats() {
            this.getOrdersStats(true);
        },
        async getData() {
            this.loadingConfig = true;
            this.getClicksStats();
            this.getSalesStats();
            this.getOrdersStats();
            this.getImpressionsStats();
            await this.getCampaigns();
            this.loadingConfig = false;
        },
        async getClicksStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.clicksStatsError = null;
                await this.fetchClicksStats({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.clicksStats = this.getterDashboardClicksStats(startDate, endDate, compareStartDate, compareEndDate) || {};
                this.ctrStats = this.getterDashboardCTRStats(startDate, endDate, compareStartDate, compareEndDate) || {};
                this.crStats = this.getterDashboardCRStats(startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.clicksStatsError = e.message;
            }
        },
        async getSalesStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.salesStatsError = null;
                await this.fetchSalesStats({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.salesStats = this.getterDashboardSalesStats(startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.salesStatsError = e.message;
            }
        },
        async getOrdersStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.ordersStatsError = null;
                await this.fetchOrdersStats({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.ordersStats = this.getterDashboardOrdersStats(startDate, endDate, compareStartDate, compareEndDate) || {};
                this.crStats = this.getterDashboardCRStats(startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.ordersStatsError = e.message;
            }
        },
        async getImpressionsStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.impressionsStatsError = null;
                await this.fetchImpressionsStats({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.impressionsStats = this.getterDashboardImpressionsStats(startDate, endDate, compareStartDate, compareEndDate) || {};
                this.ctrStats = this.getterDashboardCTRStats(startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.impressionsStatsError = e.message;
            }
        },
        ...mapActions({
            fetchClicksStats: 'dashboard/fetchClicksStats',
            fetchSalesStats: 'dashboard/fetchSalesStats',
            fetchOrdersStats: 'dashboard/fetchOrdersStats',
            fetchImpressionsStats: 'dashboard/fetchImpressionsStats',
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
