<template>
    <v-data-table
        class="table--transparent"
        :headers="headers"
        :items="items"
        :no-data-text="$t('noResults')"
        :no-results-text="$t('noResults')"
        hide-actions
        :custom-sort="customSort"
    >
        <template v-slot:items="props">
            <td :class="{ 'competition-aggregation0table__producer-product': props.item.custom.isProducerProduct }">
                {{ props.item.product ? props.item.product.name : props.item.aggregation.name }}
            </td>
            <td>{{ props.item.product ? props.item.product.sku : props.item.aggregation.sku }}</td>
            <td>{{ props.item.product ? props.item.product.ean : props.item.aggregation.ean }}</td>
            <td class="text-xs-right">{{ props.item.custom.priceAvgFormatted }}</td>
            <td>
                {{ props.item.custom.priceRankingFormatted }}
                <InfoTooltip v-if="!props.item.custom.priceRanking" bottom :text="$t('competitiveAnalysis.productUnavailable')" class="ml-1" />
            </td>
            <td>
                <div class="d-flex align-center justify-end">
                    {{ props.item.custom.priceMinFormatted }}
                    <competition-price-details priceType="min" :product="props.item" />
                </div>
            </td>
            <td>
                <div class="d-flex align-center justify-end">
                    {{ props.item.custom.priceMaxFormatted }}
                    <competition-price-details priceType="max" :product="props.item" />
                </div>
            </td>
            <td>{{ props.item.custom.offersCountFormatted }}</td>
            <td>
                {{ props.item.custom.offersRankingFormatted }}
                <RankingItemBar
                    class="ml-2"
                    hideLabel
                    v-if="props.item.custom.offersRanking"
                    :maxTotal="parseInt(props.item.custom.offersRankingFormatted.split(' / ')[1])"
                    :total="parseInt(props.item.custom.offersRankingFormatted.split(' / ')[1]) - parseInt(props.item.custom.offersRankingFormatted.split(' / ')[0]) * 0.9"
                    :barWidth="100" />
                <InfoTooltip v-if="!props.item.custom.offersRanking" bottom :text="$t('competitiveAnalysis.productUnavailable')" class="ml-1" />
            </td>
        </template>
    </v-data-table>
</template>

<script>
import {
    orderBy,
    findIndex,
    findLastIndex,
} from 'lodash';
import numberService from '@/services/numberService';
import exportService from '@/services/exportService';
import CompetitionPriceDetails from '@/components/producer/reports/competitiveAnalysis/CompetitionPriceDetails.vue';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import RankingItemBar from '@/components/graphs/RankingItemBar.vue';

export default {
    name: 'competition-aggregations-list',
    components: { CompetitionPriceDetails, RankingItemBar, InfoTooltip },
    props: {
        set: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('competitionSets.selectedCompetitiveProducts'),
                    align: 'left',
                    sortable: true,
                    value: 'product.name',
                },
                {
                    text: this.$t('sku'),
                    align: 'left',
                    sortable: true,
                    value: 'product.sku',
                },
                {
                    text: this.$t('ean'),
                    align: 'left',
                    sortable: true,
                    value: 'product.ean',
                },
                {
                    text: this.$t('avgPrice'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.priceAvg',
                },
                {
                    text: this.$t('competitionSets.priceRanking'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.priceRanking',
                },
                {
                    text: this.$t('minPrice'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.priceMin',
                },
                {
                    text: this.$t('maxPrice'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.priceMax',
                },
                {
                    text: this.$t('competitionSets.offersCount'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.offersCount',
                },
                {
                    text: this.$t('competitionSets.offersRanking'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.offersRanking',
                },
            ],
        };
    },
    computed: {
        items() {
            if (!this.set || !this.data) {
                return [];
            }
            const productData = this.data.products[this.set.producerProduct.id];
            const producerProductsData = this.set.producerProducts.map(product => this.data.products[product.id]);
            const globalProductsData = this.set.globalProducts.map(product => this.data.aggregations[product.id]);
            const productForRanking = [productData, ...producerProductsData, ...globalProductsData].filter(product => Boolean(product.custom.priceAvg));
            const competitionAvgPricesOrdered = this.orderProductsByAvgPrice(productForRanking);
            const competitionOffersCountOrdered = this.orderProductsByOrderCount(productForRanking);

            // offset of all offers count, manages / x in offers count
            let competitionOffersCountOrderedOffset = 0;
            competitionOffersCountOrdered.map((item, index) => {
                const offer = item;
                if (index === 0) {
                    return offer;
                }
                const previousItem = competitionOffersCountOrdered[index - 1];

                // detect if there is more than one item with the same offer count
                // if offer count is the same as previous item, add offset
                if (offer.custom.offersCount === previousItem?.custom.offersCount) {
                    offer.custom.offersCountOffset = 1 + competitionOffersCountOrderedOffset;

                    competitionOffersCountOrderedOffset += 1;
                } else {
                    offer.custom.offersCountOffset = competitionOffersCountOrderedOffset;
                }
                return offer;
            });
            return [...this.set.producerProducts, ...this.set.globalProducts].map(setAggregation => {
                const aggregationData = this.data.aggregations[setAggregation.id] ?? this.data.products[setAggregation.id];
                const isAvailable = Boolean(aggregationData.custom.priceAvg);
                const priceRanking = isAvailable ? findIndex(competitionAvgPricesOrdered, item => item.custom.id === aggregationData.custom.id) + 1 : 0;
                const offersRanking = isAvailable ? findLastIndex(competitionOffersCountOrdered, item => item.custom.id === aggregationData.custom.id) + 1 : 0;
                const offersRankingOffset = competitionOffersCountOrdered[offersRanking - 1]?.custom.offersCountOffset || 0;

                return {
                    ...aggregationData,
                    custom: {
                        ...aggregationData.custom,
                        priceRanking,
                        priceRankingFormatted: isAvailable ? `${numberService.formatNumber(priceRanking, 0)} / ${numberService.formatNumber(competitionAvgPricesOrdered.length, 0)}` : '- / -',
                        offersRanking,
                        offersRankingWithOffset: offersRanking - offersRankingOffset,
                        offersRankingFormatted: isAvailable ? `${numberService.formatNumber(offersRanking - offersRankingOffset, 0)} / ${numberService.formatNumber(competitionOffersCountOrdered.length, 0)}` : '- / -',
                    },
                };
            });
        },
    },
    methods: {
        customSort(items, index, isDesc) {
            // custom sorting for price ranking, sort by offersRanking and then by name
            if (index === 'custom.offersRanking') {
                return orderBy(items, ['custom.offersRankingWithOffset', 'aggregation.name'], [isDesc ? 'desc' : 'asc']);
            }
            return orderBy(items, [index], [isDesc ? 'desc' : 'asc']);
        },
        orderProductsByAvgPrice(productsData = []) {
            return orderBy(productsData, ['custom.priceAvg', 'custom.isProducerProduct'], ['asc', 'desc']);
        },
        orderProductsByOrderCount(productsData = []) {
            return orderBy(productsData, ['custom.offersCount', 'custom.isProducerProduct'], ['desc', 'desc']);
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('name'))}"`);
            header.push(`"${exportService.parseText(this.$t('sku'))}"`);
            header.push(`"${exportService.parseText(this.$t('ean'))}"`);
            header.push(`"${exportService.parseText(this.$t('avgPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('competitionSets.priceRanking'))}"`);
            header.push(`"${exportService.parseText(this.$t('minPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('maxPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('competitionSets.offersCount'))}"`);
            header.push(`"${exportService.parseText(this.$t('competitionSets.offersRanking'))}"`);

            const rows = [];
            this.items.forEach(item => {
                const row = [];
                row.push(`"${exportService.parseText(item.product.name)}"`);
                row.push(`"${exportService.parseText(item.product.sku)}"`);
                row.push(`"${exportService.parseText(item.product.ean)}"`);
                row.push(`"${numberService.formatNumber(item.custom.priceAvg, 2, undefined, true)}"`);
                row.push(`"${exportService.parseText(item.custom.priceRankingFormatted)}"`);
                row.push(`"${numberService.formatNumber(item.custom.priceMin, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.custom.priceMax, 2, undefined, true)}"`);
                row.push(`"${exportService.parseText(item.custom.offersCountFormatted)}"`);
                row.push(`"${exportService.parseText(item.custom.offersRankingFormatted)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.title);
        },
    },
};
</script>

<style lang="scss" scoped>
    .competition-aggregation0table__producer-product {
        font-weight: 700;
        color: $primary-color;
    }
</style>
