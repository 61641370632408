<template>
    <div class="d-inline-block">
        <v-menu
            content-class="date-range-picker__container"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
        >
            <template #activator="{ on }">
                <v-btn class="text-none" flat v-on="on">
                    {{ selectedMonthFormatted }}
                    <v-icon small right>fa-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-date-picker
                class="month-picker-date-picker"
                first-day-of-week="1"
                v-model="date"
                type="month"
                :locale="$i18n.locale"
                header-color="tertiary"
                event-color="accent"
                :max="max"
                :min="min"
                :disabled="disabled"
            >
                <v-flex class="text-xs-right mt-2">
                    <v-btn
                        class="mr-2"
                        round
                        @click="handleCancel"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        color="tertiary"
                        dark
                        round
                        @click="handleApply"
                    >
                        {{ $t('apply') }}
                    </v-btn>
                </v-flex>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'month-picker',
    props: {
        value: {
            type: String,
            required: true,
        },
        max: {
            type: String,
        },
        min: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
    },
    model: {
        event: 'change',
    },
    data() {
        return {
            menu: false,
            date: this.value,
        };
    },
    methods: {
        handleApply() {
            this.$emit('change', this.date);
            this.menu = false;
        },
        handleCancel() {
            this.menu = false;
        },
    },
    computed: {
        selectedMonthFormatted() {
            return this.$d(new Date(this.value), 'month');
        },
    },
    watch: {
        menu(val) {
            if (val === false) {
                this.date = this.value;
            }
        },
    },
};
</script>

<style lang="scss">
    .month-picker-date-picker {
        .v-date-picker-table .v-btn {
            height: 55px;
            width: 55px;
            border-radius: 50%
        }
    }
</style>
