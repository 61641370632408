import { mapGetters } from 'vuex';
import { deburr, difference, orderBy } from 'lodash';
import { VCheckbox } from 'vuetify/lib';

VCheckbox.computed.computedColor = function computedColor() { return this.color; };

export default {
    data() {
        return {
            campaign: [],
            campaignSelectDisabled: false,
        };
    },
    computed: {
        allActiveCampaignsAreSelected() {
            return this.activeCampaigns.map(item => item.value).every(id => this.campaign.includes(id));
        },
        allAvailableCampaignsAreSelected() {
            return this.availableCampaigns.map(item => item.value).every(id => this.campaign.includes(id));
        },
        someActiveCampaignsAreSelected() {
            return this.activeCampaigns.map(item => item.value).some(id => this.campaign.includes(id));
        },
        someAvailableCampaignsAreSelected() {
            return this.availableCampaigns.map(item => item.value).some(id => this.campaign.includes(id));
        },
        activeCampaigns() {
            return this.getterActiveCampaigns.map(item => ({ value: item.id, text: item.name }));
        },
        availableCampaigns() {
            return this.getterAvailableCampaigns.map(item => ({ value: item.id, text: item.name }));
        },
        campaignOptions() {
            const findSelectAllComponent = function findSelectAllComponent(el) {
                if (el?.$options?.name === 'select-all') return el;
                if (el?.constructor?.name === 'VueComponent') return findSelectAllComponent(el.$parent);
                if (el?.__vue__) return findSelectAllComponent(el.__vue__); // eslint-disable-line no-underscore-dangle
                if (el?.parentElement) return findSelectAllComponent(el.parentElement);
                return null;
            };
            const disableBlur = function disableBlur(e) {
                const selectAllComponent = findSelectAllComponent(e.target);
                if (selectAllComponent) {
                    selectAllComponent.skipBlur = true;
                    setTimeout(() => { selectAllComponent.skipBlur = false; }, 50);
                }
            };
            const createHeader = (label, checked = false, indeterminate = false, disabled = false, onChange) => [
                this.$createElement(
                    'div',
                    {
                        class: 'v-list__tile__action v-list__tile pa-0',
                        on: {
                            click: disableBlur,
                        },
                    },
                    [
                        this.$createElement(
                            VCheckbox,
                            {
                                props: {
                                    color: 'primary darken-4',
                                    inputValue: checked,
                                    indeterminate,
                                    disabled,
                                },
                                on: {
                                    change: onChange || (() => {}),
                                },
                            },
                            [
                                this.$createElement(
                                    'p',
                                    {
                                        slot: 'label',
                                        class: `v-subheader font-italic font-weight-bold pa-0 ma-0 ${disabled ? 'text--disabled' : ''}`,
                                    },
                                    label,
                                ),
                            ],
                        )],
                ),
            ];
            return [
                { header: createHeader(this.$t('filters.pluginShops'), this.allActiveCampaignsAreSelected, this.someActiveCampaignsAreSelected && !this.allActiveCampaignsAreSelected, this.campaignSelectDisabled, this.toggleActiveCampaigns) },
                ...this.activeCampaigns,
                { header: createHeader(this.$t('filters.otherShops'), this.allAvailableCampaignsAreSelected, this.someAvailableCampaignsAreSelected && !this.allAvailableCampaignsAreSelected, this.campaignSelectDisabled, this.toggleAvailableCampaigns) },
                ...this.availableCampaigns,
            ];
        },
        campaignFilterSummary() {
            const items = [];
            let activeItems = [];
            let availableItems = [];
            this.campaign.forEach(campaignId => {
                const activeItem = this.activeCampaigns.find(campaign => campaign.value === campaignId);
                const availableItem = this.availableCampaigns.find(campaign => campaign.value === campaignId);
                if (activeItem) {
                    activeItems.push(activeItem.text);
                }
                if (availableItem) {
                    availableItems.push(availableItem.text);
                }
            });
            activeItems = orderBy(activeItems, [item => deburr(item).toLowerCase()], ['asc']);
            availableItems = orderBy(availableItems, [item => deburr(item).toLowerCase()], ['asc']);
            if (activeItems.length > 0) {
                items.push({
                    name: this.$t('filterSummary.types.activeCampaigns'),
                    items: activeItems,
                });
            }
            if (activeItems.length > 0) {
                items.push({
                    name: this.$t('filterSummary.types.availableCampaigns'),
                    items: availableItems,
                });
            }
            return items;
        },
        campaignFilterNames() {
            const campaignNames = this.campaign.reduce((names, campaignId) => {
                const activeItem = this.activeCampaigns.find(campaign => campaign.value === campaignId);
                if (activeItem) {
                    return [...names, activeItem.text];
                }
                const availableItem = this.availableCampaigns.find(campaign => campaign.value === campaignId);
                if (availableItem) {
                    return [...names, availableItem.text];
                }
                return names;
            }, []);
            return orderBy(campaignNames, [item => deburr(item).toLowerCase()], ['asc']);
        },
        ...mapGetters({
            getterActiveCampaigns: 'plugin/activeCampaignsByName',
            getterAvailableCampaigns: 'plugin/availableCampaignsNotActive',
        }),
    },
    methods: {
        /**
         * Get default campaign items based on disabled regions or provided data groups
         * @param {string[]} [disabledRegions] - List of disabled regions
         */
        getDefaultCampaign(disabledRegions) {
            if (disabledRegions && disabledRegions.includes(process.env.VUE_APP_REGION_MODE)) {
                return [];
            }
            if (this.activeCampaigns.length > 0) {
                return this.activeCampaigns.map(campaign => campaign.value);
            }
            if (this.availableCampaigns.length > 0) {
                return this.availableCampaigns.map(campaign => campaign.value);
            }
            return [];
        },
        /**
         * Get all campaign items based on disabled regions or provided data groups
         * @param {string[]} [disabledRegions] - List of disabled regions
         */
        getAllCampaigns(disabledRegions) {
            if (disabledRegions && disabledRegions.includes(process.env.VUE_APP_REGION_MODE)) {
                return [];
            }
            const campaigns = [];
            if (this.activeCampaigns.length > 0) {
                this.activeCampaigns.forEach(campaign => campaigns.push(campaign.value));
            }
            if (this.availableCampaigns.length > 0) {
                this.availableCampaigns.map(campaign => campaigns.push(campaign.value));
            }
            return campaigns;
        },
        /**
         * Set default campaign items based on disabled regions or provided data groups
         * @param {string[]} [disabledRegions] - List of disabled regions
         */
        setDefaultCampaign(disabledRegions) {
            this.campaign = this.getDefaultCampaign(disabledRegions);
        },
        /**
         * Set all campaign items based on disabled regions or provided data groups
         * @param {string[]} [disabledRegions] - List of disabled regions
         */
        setAllCampaigns(disabledRegions) {
            this.campaign = this.getAllCampaigns(disabledRegions);
        },
        /**
         * Returns if currently selected campaigns are matching default list
         * @param {string[]} [disabledRegions] - List of disabled regions
         * @return {boolean}
         */
        isDefaultCampaign(disabledRegions, defaultCampaignOverride) {
            const defaultCampaigns = defaultCampaignOverride ?? this.getDefaultCampaign(disabledRegions);
            const diff1 = difference(defaultCampaigns, this.campaign).length;
            const diff2 = difference(this.campaign, defaultCampaigns).length;
            return diff1 === 0 && diff2 === 0;
        },
        /**
         * Returns if currently selected campaigns is matching list of all
         * @param {string[]} [disabledRegions] - List of disabled regions
         * @return {boolean}
         */
        hasAllCampaigns(disabledRegions) {
            const allCampaigns = this.getAllCampaigns(disabledRegions);
            return this.campaign.length === allCampaigns.length;
        },
        toggleActiveCampaigns() {
            if (this.allActiveCampaignsAreSelected) {
                this.campaign = this.campaign.filter(id => !this.activeCampaigns.map(item => item.value).includes(id));
            } else {
                this.campaign = [...new Set([...this.campaign, ...this.activeCampaigns.map(item => item.value)])];
            }
        },
        toggleAvailableCampaigns() {
            if (this.allAvailableCampaignsAreSelected) {
                this.campaign = this.campaign.filter(id => !this.availableCampaigns.map(item => item.value).includes(id));
            } else {
                this.campaign = [...new Set([...this.campaign, ...this.availableCampaigns.map(item => item.value)])];
            }
        },
        getCampaignNameFromId(campaignId) {
            const campaign = this.getterActiveCampaigns.find(item => item.id === campaignId) || this.getterAvailableCampaigns.find(item => item.id === campaignId);
            return campaign?.name;
        },
    },
};
