/* eslint-disable no-unused-vars */
import {
    filterDates,
    filterProductAvailabilityByCampaignName,
    filterProductOffersByCampaignId,
    filterProductPriceByCampaignName,
    filterSegmentDates,
    generateDates, generateProductOffersSameDates,
    generateSegmentsDates,
    getProductsTree,
    searchProducts,
    searchProductsTree,
} from '@/services/demo-services/_utils';
import demoService from '@/services/demoService';

export default {
    async fetchProducts(spaceId, categoryId) {
        const { default: productsTree } = await import('../../mocks/productsTree.json');
        return getProductsTree(productsTree, categoryId);
    },

    async fetchSearchProducts(spaceId, search) {
        const { default: productsTree } = await import('../../mocks/productsTree.json');
        return searchProductsTree(productsTree, search);
    },

    async fetchFindProducts(spaceId, search, fetchFindProducts) {
        const { default: producerProducts } = await import('../../mocks/producerProducts.json');
        return searchProducts(producerProducts, { spaceId, search });
    },

    async fetchProductDetails(entityId) {
        const { default: producerProduct } = await import(`../../mocks/producerProduct${entityId}.json`);
        return producerProduct;
    },

    async fetchProductClicksStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        const { default: producerProductsClicksStats } = await import(`../../mocks/producerProductsClicksStats${entityId}.json`);
        return producerProductsClicksStats;
    },

    async fetchCategoryClicksStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        const { default: producerProductsClicksStats } = await import(`../../mocks/producerProductCategoriesClicksStats${entityId}.json`);
        return producerProductsClicksStats;
    },

    async fetchProductSalesStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        const { default: producerProductsSalesStats } = await import(`../../mocks/producerProductsSalesStats${entityId}.json`);
        return producerProductsSalesStats;
    },

    async fetchCategorySalesStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        const { default: producerProductsSalesStats } = await import(`../../mocks/producerProductCategoriesSalesStats${entityId}.json`);
        return producerProductsSalesStats;
    },

    async fetchProductImpressionsStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        const { default: producerProductsImpressionsStats } = await import(`../../mocks/producerProductsImpressionsStats${entityId}.json`);
        return producerProductsImpressionsStats;
    },

    async fetchCategoryImpressionsStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        const { default: producerProductsImpressionsStats } = await import(`../../mocks/producerProductCategoriesImpressionsStats${entityId}.json`);
        return producerProductsImpressionsStats;
    },

    async fetchProductSalesChart(entityId, startDate, endDate, campaigns) {
        const { default: producerProductsSalesChart } = await import(`../../mocks/producerProductsSalesChart${entityId}.json`);
        return filterDates(generateDates(producerProductsSalesChart));
    },

    async fetchCategorySalesChart(entityId, startDate, endDate, campaigns) {
        const { default: producerProductsSalesChart } = await import(`../../mocks/producerProductCategoriesSalesChart${entityId}.json`);
        return filterDates(generateDates(producerProductsSalesChart));
    },

    async fetchProductTrafficChart(entityId, startDate, endDate, campaigns) {
        const { default: producerProductsTrafficChart } = await import(`../../mocks/producerProductsTrafficChart${entityId}.json`);
        return filterDates(generateDates(producerProductsTrafficChart));
    },

    async fetchCategoryTrafficChart(entityId, startDate, endDate, campaigns) {
        const { default: producerProductsTrafficChart } = await import(`../../mocks/producerProductCategoriesTrafficChart${entityId}.json`);
        return filterDates(generateDates(producerProductsTrafficChart));
    },

    async fetchProductPriceDistribution(entityId, startDate, endDate, pluginCampaigns) {
        const { default: producerProductsPriceChart } = await import(`../../mocks/producerProductsPriceChart${entityId}.json`);
        return filterDates(generateDates(filterProductPriceByCampaignName(producerProductsPriceChart, pluginCampaigns), 'YYYY-MM-DD'), 'YYYY-MM-DD');
    },

    async fetchCategoryPriceDistribution(entityId, startDate, endDate, pluginCampaigns) {
        // @todo file missing!
        // const { default: producerProductsPriceChart } = await import(`../../mocks/producerProductCategoriesPriceChart${entityId}.json`);
        // return filterDates(generateDates(filterProductPriceByCampaignName(producerProductsPriceChart, pluginCampaigns), 'YYYY-MM-DD'), 'YYYY-MM-DD');
    },

    async fetchProductAvailability(entityId, startDate, endDate, pluginCampaigns, campaigns) {
        const { default: producerProductsAvailabilityChart } = await import(`../../mocks/producerProductsAvailabilityChart${entityId}.json`);
        return filterSegmentDates(generateSegmentsDates(filterProductAvailabilityByCampaignName(producerProductsAvailabilityChart, pluginCampaigns)));
    },

    async fetchGlobalProductAvailability(spaceId, entityId, startDate, endDate, pluginCampaigns, campaigns) {
        const { default: producerProductsAvailabilityChart } = await import(`../../mocks/producerProductsAvailabilityChart${entityId}.json`);
        return filterSegmentDates(generateSegmentsDates(filterProductAvailabilityByCampaignName(producerProductsAvailabilityChart, pluginCampaigns)));
    },

    async fetchCategoryAvailability(entityId, startDate, endDate, pluginCampaigns) {
        // @todo file missing!
        // const { default: producerProductsAvailabilityChart } = await import(`../../mocks/producerProductCategoriesAvailabilityChart${entityId}.json`);
        // return filterSegmentDates(generateSegmentsDates(filterProductAvailabilityByCampaignName(producerProductsAvailabilityChart, pluginCampaigns)));
    },

    // eslint-disable-next-line consistent-return
    async fetchProductOffers(entityId, pluginOffers) {
        if (pluginOffers === 'in') {
            const { default: producerProductsOffersIn } = await import(`../../mocks/producerProductsOffersIn${entityId}.json`);
            return generateProductOffersSameDates(filterProductOffersByCampaignId(producerProductsOffersIn));
        }
        if (pluginOffers === 'notIn') {
            const { default: producerProductsOffersNotIn } = await import(`../../mocks/producerProductsOffersNotIn${entityId}.json`);
            return generateProductOffersSameDates(filterProductOffersByCampaignId(producerProductsOffersNotIn));
        }
    },

    deleteProduct(entityId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    updateProduct(entityId, data) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    createProduct(spaceId, data) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    flattenProductsTree: demoService.USE_ORIGINAL_PROPERTY,
};
