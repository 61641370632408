<template>
    <div>
        <v-card-title>
            <v-layout row wrap align-center justify-space-between>
                <h4 class="subheading font-weight-bold">
                    {{ $t('currentUnavailableProducts.title') }}
                    <v-tooltip v-if="data && data.length" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                @click="exportData"
                                flat
                                icon>
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.export') }}</span>
                    </v-tooltip>
                </h4>
            </v-layout>
        </v-card-title>

        <v-data-table
            class="elevation-0 table--transparent"
            :headers="headers"
            :items="items"
            :no-data-text="$t('noResults')"
            :no-results-text="$t('noResults')"
            :rows-per-page-text="$t('table.rowsPerPage')"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            sort-icon="fa-angle-up"
        >
            <template v-slot:pageText="props">
                {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
            </template>

            <template v-slot:items="props">
                <td>
                    {{ props.item.product.name }}
                </td>
                <td>
                    {{ props.item.product.sku }}
                </td>
                <td>
                    {{ props.item.product.ean }}
                </td>
                <td>
                    {{ props.item.custom.lastAvailableAtFormatted || $t('na') }}
                </td>
                <td>
                    {{ props.item.custom.unavailableDaysFormatted || $t('na') }}

                    <v-menu v-if="!props.item.custom.unavailableDaysFormatted" transition="scale-transition" origin="top right" bottom left>
                        <template #activator="{ on }">
                            <v-btn small flat icon v-on="on" @click.native.stop.prevent>
                                <v-icon small>fa-search</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <span>{{ $t('currentUnavailableProducts.productUnavailableTooltip') }}</span>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import exportService from '@/services/exportService';

export default {
    name: 'current-unavailable-products',
    components: {},
    mixins: [],
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('product'),
                    align: 'left',
                    sortable: true,
                    value: 'product.name',
                },
                {
                    text: this.$t('model'),
                    align: 'left',
                    sortable: true,
                    value: 'product.sku',
                },
                {
                    text: this.$t('ean'),
                    align: 'left',
                    sortable: true,
                    value: 'product.ean',
                },
                {
                    text: this.$t('currentUnavailableProducts.lastAvailableAt'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.lastAvailableAt',
                },
                {
                    text: this.$t('currentUnavailableProducts.unavailableFor'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.unavailableDays',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'custom.unavailableDays',
                descending: true,
            },
        };
    },
    computed: {
        items() {
            return (this.data ?? []).filter(product => product.custom.isUnavailable);
        },
    },
    methods: {
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('product'))}"`);
            header.push(`"${exportService.parseText(this.$t('category'))}"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 1"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 2"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 3"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 4"`);
            header.push(`"${exportService.parseText(this.$t('model'))}"`);
            header.push(`"${exportService.parseText(this.$t('ean'))}"`);
            header.push(`"${exportService.parseText(this.$t('currentUnavailableProducts.lastAvailableAt'))}"`);
            header.push(`"${exportService.parseText(this.$t('currentUnavailableProducts.unavailableFor'))}"`);

            const rows = [];
            this.items.forEach(item => {
                const row = [];
                row.push(`"${exportService.parseText(item.product.name)}"`);
                row.push(`"${exportService.parseText(item.custom.mainCategory)}"`);
                const categories = item.product?.categories?.[0] || [];

                for (let i = 0; i < 4; i += 1) {
                    row.push(`"${exportService.parseText(categories[i]?.name || '')}"`);
                }

                row.push(`"${exportService.parseText(item.product.sku)}"`);
                // eslint-disable-next-line no-tabs
                row.push(`"${exportService.parseText(item.product.ean)}	"`);
                row.push(`"${item.custom.lastAvailableAtFormatted || this.$t('na')}"`);
                row.push(`"${item.custom.unavailableDaysFormatted || this.$t('na')}"`);

                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('currentUnavailableProducts.title'));
        },
    },
};
</script>

<style lang="scss" scoped></style>
