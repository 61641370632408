<template>
    <v-card class="elevation-0 mb-4">
        <v-card-title>
            <div>
                <h3 class="title">{{ $t('producerWebhooks.subtitle') }}</h3>
                <span class="font-weight-medium grey--text text--darken-1">{{ $t('producerWebhooks.total') }}: {{ itemsCount }}</span>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>

            <v-data-table
                v-if="!loading"
                class="elevation-0"
                :headers="headers"
                :items="items"
                :no-data-text="$t('producerWebhooks.noResults')"
                :no-results-text="$t('producerWebhooks.noResults')"
                :rows-per-page-text="$t('table.rowsPerPage')"
                :rows-per-page-items="rowsPerPageItems"
                :pagination.sync="pagination"
                disable-initial-sort
                @update:pagination="updatePagination"
                sort-icon="fa-angle-up"
            >
                <template v-slot:pageText="props">
                    {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                </template>

                <template v-slot:items="props">
                    <td style="width: 27%;">
                        {{ props.item.name }}
                    </td>

                    <td style="width: 27%;" v-if="!editModel || (editModel && editModel.id !== props.item.id)">
                        <span v-if="props.item.webhook">{{ props.item.webhook }}</span>
                        <a v-else href="#" :disabled="submitting" @click.prevent="handleUpdate(props.item)">
                            {{ $t('add').toLowerCase() }}
                        </a>
                    </td>
                    <td style="width: 27%;" v-if="editModel && editModel.id === props.item.id">
                        <v-text-field
                            v-model.trim="editModel.webhook"
                            :error-messages="urlErrors"
                            @blur="$v.editModel.webhook.$touch()"
                            @keyup.native.enter="handleEditConfirm"
                            ref="webhook"
                            name="webhook"
                            type="url"
                            required
                            maxlength="255"
                            placeholder=" "
                            :disabled="submitting && editModel.id === props.item.id"
                        />
                    </td>
                    <td>
                        <v-layout justify-end>
                            <v-btn
                                v-if="!editModel || (editModel && editModel.id !== props.item.id)"
                                :disabled="submitting"
                                @click="handleUpdate(props.item)"
                                flat
                                icon
                            >
                                <v-icon small>fa-pen</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="editModel && editModel.id === props.item.id && !submitting"
                                @click="handleEditConfirm()"
                                flat
                                icon
                            >
                                <v-icon small>fa-check</v-icon>
                            </v-btn>
                            <v-progress-circular
                                v-if="editModel && editModel.id === props.item.id && submitting"
                                class="mt-2"
                                indeterminate
                                color="primary"
                            />
                            <v-btn
                                v-if="editModel && editModel.id === props.item.id"
                                :disabled="submitting"
                                @click="handleEditCancel()"
                                flat
                                icon
                            >
                                <v-icon small>fa-times</v-icon>
                            </v-btn>
                        </v-layout>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { url } from 'vuelidate/lib/validators';
import spaceService from '../../services/spaceService';

export default {
    name: 'producer-webhooks',
    validations: {
        editModel: {
            webhook: { url },
        },
    },
    data() {
        return {
            demoSpaceId: spaceService.demoSpace.id,
            headers: [
                {
                    text: this.$t('producerWebhooks.table.name'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('producerWebhooks.table.url'),
                    align: 'left',
                    sortable: true,
                    value: 'webhook',
                },
                {
                    text: '',
                    align: 'left',
                    sortable: false,
                    value: null,
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
            },
            error: null,
            editType: null,
            editModel: null,
            paginationPrev: null,
        };
    },
    computed: {
        loading() {
            return this.spacesLoading;
        },
        submitting() {
            return this.updateSubmitting;
        },
        items() {
            return Object.values(this.spaces || {}).filter(item => item.id !== this.demoSpaceId);
        },
        itemsCount() {
            return this.items.length;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.editModel.name.$dirty) {
                return errors;
            }
            return errors;
        },
        urlErrors() {
            const errors = [];
            if (!this.$v.editModel.webhook.$dirty) {
                return errors;
            }
            if (!this.$v.editModel.webhook.url) {
                errors.push(this.$t('validation.url'));
            }
            return errors;
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaces: state => state.space.spaces,
            updateSubmitting: state => state.space.loading.updateSpace,
        }),
    },
    created() {
        this.getSpaces();
        document.addEventListener('keyup', this.keyListener);
    },
    destroyed() {
        document.removeEventListener('keyup', this.keyListener);
    },
    methods: {
        async getSpaces() {
            try {
                await this.fetchSpaces();
            } catch (e) {
                this.error = e.message;
            }
        },
        keyListener(e) {
            if ((e.key === 'Escape' || e.key === 'Esc') && (this.editType || this.editModel)) {
                this.handleEditCancel();
            }
        },
        updatePagination(event) {
            this.editType = null;
            this.editModel = null;
            this.paginationPrev = {
                descending: event.descending,
                page: event.page,
                rowsPerPage: event.rowsPerPage,
                sortBy: event.sortBy,
            };
        },
        handleUpdate(item) {
            this.$v.$reset();
            this.editType = 'update';
            this.editModel = {
                id: item.id,
                webhook: item.webhook || '',
            };
            this.$nextTick(() => {
                this.$refs.webhook.focus();
            });
        },
        handleEditCancel() {
            this.editType = null;
            this.editModel = null;
        },
        async handleEditConfirm() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                const existWebhook = this.spaces[this.editModel.id].webhook || '';
                const editWebhook = this.editModel.webhook;
                if (existWebhook !== editWebhook) {
                    await this.updateSpaceWebhook(this.editModel);
                }
                this.editType = null;
                this.editModel = null;
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            updateSpaceWebhook: 'space/updateSpaceWebhook',
            fetchSpaces: 'space/fetchSpaces',
        }),
    },
};
</script>
