<template>
    <div class="mt-4">
        <v-card-title>
            <div class="full-width mb-4">
                <v-layout
                    row
                    wrap
                    align-center
                    justify-space-between
                >
                    <h3 class="subheading font-weight-bold">
                        <template v-if="singleTransactionView && transactionFilter">
                            {{ $t('salesProductDetails.transactionId') }} {{ transactionFilter.id }} –
                        </template>
                        {{ $t('salesProductDetails.title') }}
                        <v-tooltip v-if="!loading && !filtersLoading && !singleTransactionView" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    type="button"
                                    class="mb-2"
                                    color="tertiary"
                                    :disabled="!!error || !items || items.length === 0"
                                    @click="exportData"
                                    flat
                                    icon
                                >
                                    <v-icon small>fa-download</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('export') }}</span>
                        </v-tooltip>
                        <div v-if="!singleTransactionView" class="left-filters">
                            <filter-summary :filters="filtersSummary" />
                            <Chip
                                :disabled="loading || filtersLoading"
                                :label="$t('filters.reset')"
                                :active="!isFilterDefault"
                                @click="reset"
                            />
                        </div>
                    </h3>
                    <v-layout
                        wrap
                        align-center
                        justify-end
                        v-if="!singleTransactionView"
                    >
                        <v-flex shrink>
                            <SelectAll
                                v-if="spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)"
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="sources"
                                @blur="handleSourcesChange"
                                :items="sourcesOptions"
                                :disabled="loading || filtersLoading"
                                placeholder=" "
                                :label="$t('filters.sources')"
                                outline
                                hide-details
                                dense
                                multiple
                                :loading="uniqueSourcesLoading"
                            />
                            <FormSourcesPlaceholder v-else />
                        </v-flex>
                        <v-flex shrink>
                            <select-all
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="brands"
                                :items="brandOptions"
                                :disabled="loading || filtersLoading"
                                :label="$t('salesProductDetails.brand')"
                                :all-label="$t('salesProductDetails.allBrands')"
                                require-selection
                                @blur="handleBrandChange"
                            />
                        </v-flex>
                        <v-flex v-show="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="loading || filtersLoading"
                                :label="$t('productsRanking.group')"
                                :all-label="$t('productsRanking.allGroups')"
                                require-selection
                                @blur="getData()"
                            />
                        </v-flex>
                        <v-btn
                            v-if="error"
                            class="mb-3"
                            @click="redo"
                            flat
                            icon
                        >
                            <v-icon small>fa-redo-alt</v-icon>
                        </v-btn>
                    </v-layout>
                    <slot name="topRight" />
                </v-layout>
                <div>
                    <v-layout wrap align-center justify-end>
                        <v-flex class="mb-3 ml-3" shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loading"
                                @change="getData()"
                            />
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="loading || filtersLoading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>
            <div v-if="!loading && !filtersLoading && !error">
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="headers"
                    :items="items"
                    item-key="itemKey"
                    :no-data-text="$t('salesProductDetails.noResults')"
                    :no-results-text="$t('salesProductDetails.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :pagination.sync="pagination"
                    :rows-per-page-items="rowsPerPageItems"
                    disable-initial-sort
                >
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="(header) in props.headers"
                                :key="header.text"
                                :class="[
                                    'column sortable',
                                    header.value === pagination.sortBy ? 'active' : '',
                                ]"
                                @click="changeSort(header.value)"
                            >
                                <v-layout
                                    :justify-start="header.align === 'left'"
                                    :justify-end="header.align === 'right'"
                                    align-center
                                >
                                    <div class="header-label-wrapper">
                                        {{ header.text }}
                                    </div>
                                    <sort-order :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <tr
                            :active="props.selected"
                            @click="props.selected = !props.selected"
                            :class="{
                                'sales-product-details__row-group-first': isFirstGroupItem(props.item),
                                'sales-product-details__row-group-middle': isMiddleGroupItem(props.item),
                                'sales-product-details__row-group-last': isLastGroupItem(props.item),
                            }"
                        >
                            <td class="no-wrap-text">
                                <span v-if="isVisibleGroupedColumn(props.item)">{{ props.item.date && $d(new Date(props.item.date), 'short') }}</span>
                            </td>
                            <td>
                                <span v-if="isVisibleGroupedColumn(props.item)">{{ props.item.id }}</span>
                            </td>
                            <td :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.product }}</span>
                                <span v-if="props.item.type === 'not_matched' || props.item.type === 'filtered'" class="text-no-wrap">
                                    <span v-if="props.item.type === 'not_matched'">{{ $t('salesProductDetails.unknownProduct') }}</span>
                                    <span v-if="props.item.type === 'filtered'">{{ $t('salesProductDetails.otherProducerProduct') }}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="ml-1" small>fa-question-circle</v-icon>
                                        </template>
                                        <span v-if="props.item.type === 'not_matched'">{{ $t('salesProductDetails.unknownProductDescription') }}</span>
                                        <span v-if="props.item.type === 'filtered'">{{ $t('salesProductDetails.otherProducerProductDescription') }}</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.sku }}</span>
                            </td>
                            <td :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.ean }}</span>
                            </td>
                            <td :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.brand}}</span>
                            </td>
                            <td class="no-wrap-text" :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.conversionTimeFormatted }}</span>
                            </td>
                            <td class="text-xs-right" :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.quantityFormatted }}</span>
                            </td>
                            <td class="text-xs-right" :class="{'sales-product-details__cell-border': isFirstGroupItem(props.item) || isMiddleGroupItem(props.item)}">
                                <span v-if="props.item.type !== 'not_matched' && props.item.type !== 'filtered'">{{ props.item.valueFormatted }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { difference, groupBy, sortBy } from 'lodash';
import spaceService from '@/services/spaceService';
import FormSourcesPlaceholder from '@/components/common/FormSourcesPlaceholder.vue';
import sourcesFilterMixin from '@/mixins/sourcesFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import utilsService from '@/services/utilsService';
import numberService from '../../../../services/numberService';
import dateService from '../../../../services/dateService';
import SortOrder from '../../../common/SortOrder.vue';
import exportService from '../../../../services/exportService';
import eventBus from '../../../../services/eventBus';
import Chip from '../../../common/Chip.vue';
import FilterSummary from '../../../common/FilterSummary.vue';
import SelectAll from '../../../common/SelectAll.vue';

export default {
    name: 'sales-product-details',
    components: {
        FilterSummary,
        Chip,
        SortOrder,
        SelectAll,
        FormSourcesPlaceholder,
    },
    mixins: [
        sourcesFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        conversion: {
            type: [String, Number],
            required: true,
        },
        campaign: {
            type: Array,
            default: () => [],
        },
        transactionsFiltersSummary: {
            type: Array,
            default: () => [],
        },
        singleTransactionView: {
            type: Boolean,
        },
    },
    data() {
        return {
            spaceService,
            region: process.env.VUE_APP_REGION_MODE,
            headers: [
                {
                    text: this.$t('salesProductDetails.date'),
                    align: 'left',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: this.$t('salesProductDetails.transactionIdShort'),
                    align: 'left',
                    sortable: true,
                    value: 'id',
                },
                {
                    text: this.$t('salesProductDetails.productName'),
                    align: 'left',
                    sortable: true,
                    value: 'product',
                },
                {
                    text: this.$t('salesProductDetails.sku'),
                    align: 'left',
                    sortable: true,
                    value: 'sku',
                },
                {
                    text: this.$t('salesProductDetails.ean'),
                    align: 'left',
                    sortable: true,
                    value: 'ean',
                },
                {
                    text: this.$t('salesProductDetails.brand'),
                    align: 'left',
                    sortable: true,
                    value: 'brand',
                },
                {
                    text: this.$t('salesProductDetails.conversionTime'),
                    align: 'left',
                    sortable: true,
                    value: 'conversionTime',
                },
                {
                    text: this.$t('salesProductDetails.quantity'),
                    align: 'right',
                    sortable: true,
                    value: 'quantity',
                },
                {
                    text: this.$t('salesProductDetails.amount'),
                    align: 'right',
                    sortable: true,
                    value: 'value',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                descending: true,
                rowsPerPage: 10,
                sortBy: 'date',
            },
            data: null,
            items: [],
            brands: [],
            transactionFilter: null,
            error: null,
            isFilterDefault: true,
            loading: false,
            showProductGroupsResults: false,
        };
    },
    computed: {
        brandOptions() {
            const { startDate, endDate } = this.dateFilter;
            const params = {
                startDate,
                endDate,
                conversion: this.conversion,
                campaign: this.campaign,
            };
            if (this.sources.length) params.sources = sortBy(this.sources).join(',');
            if (this.showProductGroupsResults) {
                if (this.group.length) params.group = sortBy(this.group).join(',');
            }

            const storageKey = utilsService.getStorageKey(params.startDate, params.endDate, params.conversion, ...params.campaign, params.sources, params.group);
            const lackOfLabel = this.$t('lackOf').toLowerCase();
            return [
                { value: '', text: lackOfLabel, html: `<i>${lackOfLabel}</i>` },
                ...this.getterBrandByKeyOptions(storageKey),
            ];
        },
        brandFilterSummary() {
            const option = this.brandOptions.filter(brandOption => this.brands.includes(brandOption.value));
            const items = option ? [option.text] : [];
            return {
                name: this.$t('filterSummary.types.brand'),
                items,
            };
        },
        filtersSummary() {
            return [
                this.brandFilterSummary,
            ];
        },
        isDefaultBrands() {
            return this.brands.length === this.brandOptions.length;
        },
        filtersLoading() {
            return this.uniqueSourcesLoading || this.groupsLoading;
        },
        ...mapState({
            productDetailsLoading: state => state.reportsSales.loading.productDetails,
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            getterBrandOptions: 'reportsSales/getBrandOptions',
            getterBrandByKeyOptions: 'reportsSales/getBrandByKeyOptions',
            getterProductDetails: 'reportsSales/getProductDetails',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        conversion(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getData();
            }
        },
        campaign(newValue, oldValue) {
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 !== 0 || diff2 !== 0) {
                this.getData();
            }
        },
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('loadingChange', this.loading);
            }
        },
        brandOptions() {
            this.setDefaultBrands();
        },
        filtersLoading(loading) {
            if (!loading) this.reset();
        },
    },
    created() {
        this.getData();
        eventBus.$on('resetFilters', this.reset);
        eventBus.$on('setProductDetailsTransaction', this.setTransactionFilter);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
        eventBus.$off('setProductDetailsTransaction', this.setTransactionFilter);
    },
    methods: {
        reset() {
            this.setDefaultBrands();
            this.setDefaultGroup();
            this.getData();
        },
        setDefaultBrands() {
            this.brands = this.brandOptions.map(item => item.value);
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.isDefaultBrands;
            this.isFilterDefault = isFilterDefault;
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getProductDetails(force);
        },
        handleBrandChange() {
            this.setItems();
        },
        redo() {
            this.getData(true);
        },
        setTransactionFilter(e) {
            this.transactionFilter = e;
            this.setItems();
        },
        async getProductDetails(force = false) {
            if (this.filtersLoading) return;
            this.loading = true;
            try {
                const {
                    startDate, endDate,
                } = this.dateFilter;
                this.error = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    conversion: this.conversion,
                    campaign: this.campaign,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchProductDetails(params);
                let data = this.getterProductDetails(params.startDate, params.endDate, params.conversion, params.campaign, params.sources, params.group);
                if (data) {
                    data = data.map((item, index) => {
                        let { product } = item;
                        if (item.type === 'not_matched') {
                            product = this.$t('salesProductDetails.unknownProduct');
                        } else if (item.type === 'filtered') {
                            product = this.$t('salesProductDetails.otherProducerProduct');
                        }
                        return {
                            ...item,
                            product,
                            conversionTimeFormatted: dateService.secondsToString(item.conversionTime / 1000), // conversionTime is in ms
                            quantityFormatted: numberService.formatNumber(item.quantity),
                            valueFormatted: numberService.formatCurrency(item.value),
                            // ID in data represents transaction ID which can be same for multiple products
                            // we need unique key for each item to properly render table rows
                            itemKey: index,
                        };
                    });
                }
                this.data = data;
                this.setItems();
            } catch (e) {
                this.error = e.message;
            }
            this.loading = false;
        },
        setItems() {
            this.emitFilterDefaultChange();
            if (!this.data) {
                this.items = [];
            }

            let { data } = this;

            if (this.singleTransactionView && this.transactionFilter) {
                data = data?.filter(item => item.id === this.transactionFilter.id);
            }

            if (this.brands.length) {
                data = data?.filter(item => this.brands.includes(item.brand));
            }

            this.items = this.groupItems(data);
        },
        groupItems(data) {
            // group products only if there is no sorting enabled on any column or if they are sorted by common property
            if (this.pagination.descending === null || ['date', 'id'].includes(this.pagination.sortBy)) {
                const groupedByKey = groupBy(data, item => item.id);
                return data?.map(item => {
                    const hasGroup = (groupedByKey[item.id].length > 1);
                    const isGroupFirst = (groupedByKey[item.id][0].itemKey === item.itemKey);
                    const isGroupLast = (groupedByKey[item.id][groupedByKey[item.id].length - 1].itemKey === item.itemKey);
                    return {
                        ...item,
                        hasGroup,
                        isGroupFirst,
                        isGroupLast,
                    };
                });
            }
            return data?.map(item => ({
                ...item,
                hasGroup: false,
                isGroupFirst: false,
                isGroupLast: false,
            }));
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
            this.items = this.groupItems(this.items);
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        isVisibleGroupedColumn(item) {
            return !item.hasGroup || (item.hasGroup && item.isGroupFirst);
        },
        isFirstGroupItem(item) {
            return item.hasGroup && item.isGroupFirst;
        },
        isMiddleGroupItem(item) {
            return item.hasGroup && !item.isGroupFirst && !item.isGroupLast;
        },
        isLastGroupItem(item) {
            return item.hasGroup && item.isGroupLast;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('dataFor'))}"`);
            header.push(`"${exportService.parseText(this.$t('exportingOn'))}"`);
            header.push(`"${exportService.parseText(this.$t('selectedStores'))}"`);

            header.push(`"${exportService.parseText(this.$t('salesProductDetails.date'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.transactionIdShort'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.productName'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.ean'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.brand'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.conversionTime'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.quantity'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesProductDetails.amount'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);

            const rows = [];
            this.items.forEach((item, index) => {
                const row = [];
                if (index === 0) {
                    row.push(`"${exportService.parseText(this.dateFilter.startDate)} - ${exportService.parseText(this.dateFilter.endDate)}"`);
                    row.push(`"${exportService.parseText(new Date().toLocaleDateString())}"`);
                    row.push(`"${exportService.parseText(this.campaign.map(id => this.getCampaignNameFromId(id)).join(', '))}"`);
                } else {
                    row.push('""');
                    row.push('""');
                    row.push('""');
                }

                row.push(`"${exportService.parseText(this.$d(new Date(item.date), 'short'))}"`);
                row.push(`"${exportService.parseText(item.id)}"`);
                row.push(`"${exportService.parseText(item.product)}"`);
                row.push(`"${exportService.parseText(item.ean)}"`);
                row.push(`"${exportService.parseText(item.brand)}"`);
                row.push(`"${exportService.parseText(item.conversionTimeFormatted)}"`);
                row.push(`"${numberService.formatNumber(item.quantity, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.value, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('salesProductDetails.title'));
        },
        handleSourcesChange() {
            this.getData();
        },
        ...mapActions({
            fetchProductDetails: 'reportsSales/fetchProductDetails',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .header-label-wrapper {
        display: flex;
        flex-direction: column;
    }
    .header-label-wrapper--has-reset {
        padding-top: 22px;
        margin-bottom: 5px;
    }
</style>

<style lang="scss">
    .clear-transaction-filter .v-icon {
        margin-left: 0 !important;
        opacity: 1 !important;
    }

    .sales-product-details__row-group-first,
    .sales-product-details__row-group-middle {
        border-bottom: none !important;
    }
    .sales-product-details__cell-border {
        border-bottom: 1px solid rgba(0,0,0,.12);
    }
</style>
