<template>
    <div :class="containerClassName">
        <v-card-title>
            <v-layout
                :class="{'mb-4': type === 'normal'}"
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 v-if="type === 'normal' || type === 'no-frame'" class="title font-weight-bold mb-3 d-flex align-center">
                    {{ title }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="exportData"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.export') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="expand"
                                flat
                                icon
                            >
                                <v-icon small>fa-expand</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.fullScreen') }}</span>
                    </v-tooltip>
                    <slot name="leftFilters" />
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h3>
                <h4 v-if="type === 'small'" class="subheading font-weight-bold">
                    {{ title }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="exportData"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.export') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="expand"
                                flat
                                icon
                            >
                                <v-icon small>fa-expand</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.fullScreen') }}</span>
                    </v-tooltip>
                    <slot name="leftFilters" />
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h4>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <slot name="filters" />
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo()"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
                <slot name="secondaryFilters" />
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{ error }}
            </v-alert>

            <graph-container v-model="expanded" :title="title">
                <line-chart-graph
                    v-if="!loading && data && data.length > 0"
                    ref="chart"
                    :data="data"
                    :options="options"
                    :expanded="expanded"
                />
            </graph-container>
            <data-placeholder v-if="!loading && (!data || data.length === 0)" :title="noResultsTitle" />
            <slot name="footer" />
        </v-card-text>
    </div>
</template>

<script>
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import LineChartGraph from './core/LineChartGraph.vue';
import DataPlaceholder from '../common/DataPlaceholder.vue';
import GraphContainer from './GraphContainer.vue';
import expandGraphMixin from '../../mixins/expandGraphMixin';

export default {
    name: 'line-graph',
    components: {
        GraphContainer,
        DataPlaceholder,
        LineChartGraph,
        InfoTooltip,
    },
    mixins: [
        expandGraphMixin,
    ],
    props: {
        type: {
            validator: value => ['small', 'normal', 'no-frame'].includes(value),
            default: 'normal',
        },
        title: {
            type: String,
            required: true,
        },
        permissionName: {
            type: String,
        },
        data: {
            type: Array,
            default: null,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        noResultsTitle: {
            type: String,
            default() {
                return this.$t('noResults');
            },
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    computed: {
        containerClassName() {
            if (this.type === 'normal') {
                return 'grey lighten-4 mb-4 pa-4';
            }
            if (this.type === 'small' || this.type === 'no-frame') {
                return '';
            }
            return '';
        },
    },
    methods: {
        redo() {
            this.$emit('redo');
        },
        exportData() {
            this.$refs.chart?.exportData(this.title);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
