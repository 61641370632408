<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
        </page-header-filters>
        <v-card class="elevation-0 mb-4">
            <v-card-title>
                <h3 class="title">{{ $t('producerSubscriptions.title') }}</h3>
            </v-card-title>
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <v-data-table
                    v-if="!loading"
                    class="elevation-0"
                    :headers="headers"
                    :items="items"
                    :no-data-text="$t('producerSubscriptions.noResults')"
                    :no-results-text="$t('producerSubscriptions.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :rows-per-page-items="rowsPerPageItems"
                    :pagination.sync="pagination"
                    disable-initial-sort
                    sort-icon="fa-angle-up"
                >
                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <td>
                            {{ $t(`permissionContent.name.${props.item.permission}`) }}
                        </td>
                        <td>
                            <v-layout align-center row>
                                <status-label :type="props.item.statusType">
                                    {{ $t(`permissionContent.status.${props.item.status}`) }}
                                </status-label>
                                <activate-report-modal
                                    v-if="props.item.status === 'inactive'"
                                    class="ml-3 d-inline-flex"
                                    :permissionName="props.item.permission"
                                    :title="$t(props.item.activateTitle)"
                                    :description="$t(props.item.activateDescription)"
                                    :cta-label="`${$t('activateReportModal.ctaLabel')} >`"
                                    cta-small
                                />
                            </v-layout>
                        </td>
                        <td>
                            <v-layout justify-end>
                                <cancel-subscription-modal
                                    v-if="props.item.canCancel"
                                    :permission-id="props.item.id"
                                    :permission-name="props.item.permission"
                                />
                            </v-layout>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import StatusLabel from '../../components/common/StatusLabel.vue';
import spaceService from '../../services/spaceService';
import CancelSubscriptionModal from '../../components/common/CancelSubscriptionModal.vue';
import ActivateReportModal from '../../components/common/ActivateReportModal.vue';

export default {
    name: 'producer-subscriptions',
    components: {
        ActivateReportModal,
        CancelSubscriptionModal,
        StatusLabel,
        SpaceSwitch,
        PageHeaderFilters,
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('producerSubscriptions.table.permissionName'),
                    align: 'left',
                    sortable: true,
                    value: 'permission',
                },
                {
                    text: this.$t('producerSubscriptions.table.status'),
                    align: 'left',
                    sortable: true,
                    value: 'status',
                },
                {
                    text: '',
                    align: 'left',
                    sortable: false,
                    value: 'id',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
            },
            spaceInitialized: false,
            error: null,
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.permissionsLoading;
        },
        items() {
            return this.permissionsIds.map(permission => {
                const permissionContentKeys = spaceService.getPermissionContentKey(permission);
                const hasActivePermission = this.getterCurrentSpace.permissions.includes(permission);
                const status = (this.permissions[permission].status === 'active' || hasActivePermission) && this.permissions[permission].status !== 'canceled' ? 'active' : this.permissions[permission].status;
                const canCancel = this.permissions[permission].status === 'active' && hasActivePermission && !this.permissions[permission].agreement;
                return {
                    ...this.permissions[permission],
                    status,
                    statusType: spaceService.getPermissionStatusLabelType(status),
                    activateTitle: permissionContentKeys.title,
                    activateDescription: permissionContentKeys.description,
                    canCancel,
                };
            });
        },
        ...mapState({
            permissionsLoading: state => state.space.loading.fetchPermissions,
            permissionsIds: state => state.space.permissionsIds,
            permissions: state => state.space.permissions,
        }),
        ...mapGetters({
            getterCurrentSpace: 'space/currentSpace',
        }),
    },
    methods: {
        async handleSpaceChange() {
            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
            await this.getData();
        },
        async getData() {
            try {
                this.error = null;
                await this.fetchPermissions();
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchPermissions: 'space/fetchPermissions',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
