import jwtDecode from 'jwt-decode';
import store from '../store/store';
import apiService from './apiService';
import errorService from './errorService';
import { i18n } from '../i18n/i18n';

export default {
    refreshTokenTimer: -1,

    async register(form) {
        try {
            const baseURL = process.env.VUE_APP_API_URL;
            const urlParts = baseURL.split('.');
            // Check if the current environment is 'dev'
            const isDev = urlParts[0].endsWith('dev');
            let apiUrl;
            // If the environment is 'dev', use the base API URL for all countries
            if (isDev) {
                apiUrl = baseURL;
            // If the selected country is 'pl', use the default base API URL
            } else if (form.profile.country === 'pl') {
                apiUrl = baseURL.replace(/api(-[a-z]{2})?/, 'api');
            // For other countries, update the base API URL with the country code
            } else {
                apiUrl = baseURL.replace(/api(-[a-z]{2})?/, `api-${form.profile.country}`);
            }
            if (!apiUrl) {
                throw new Error('Invalid country code or API URL.');
            }

            const response = await apiService.post(
                `${apiUrl}/api/v1/register/producer`,
                form,
                { withAuth: false },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async authenticate(username, password) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/login_check`,
                { username, password },
                { withAuth: false },
            );
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem('accessToken', token);
            window.localStorage.setItem('refreshToken', refresh_token);
            window.localStorage.removeItem('isAdmin');
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async authenticateAs(loginToken, isAdmin) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/users/login-as/${loginToken}`,
                null,
                { withAuth: false },
            );
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem('accessToken', token);
            window.localStorage.setItem('refreshToken', refresh_token);
            if (isAdmin) {
                window.localStorage.setItem('isAdmin', 'true');
            } else {
                window.localStorage.removeItem('isAdmin');
            }
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async refreshToken() {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/token/refresh`,
                { refresh_token: window.localStorage.getItem('refreshToken') },
                { withAuth: false },
            );
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem('accessToken', token);
            window.localStorage.setItem('refreshToken', refresh_token);
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            if (formattedError.statusCode === 401) {
                window.localStorage.removeItem('refreshToken');
            }
            throw new Error(formattedError.message);
        }
    },

    logout() {
        this.cleanStorage();
        this.cancelScheduledRefreshToken();
    },

    cleanStorage() {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('isAdmin');
        window.localStorage.removeItem('spaceId');
        window.localStorage.removeItem('dateRangePickerRange');
        window.localStorage.removeItem('dateRangePickerStartDate');
        window.localStorage.removeItem('dateRangePickerEndDate');
        window.localStorage.removeItem('dateRangePickerCompareRange');
    },

    parseToken(token) {
        try {
            const decoded = jwtDecode(token);
            const {
                exp, id, username, roles, language,
            } = decoded;
            const expTime = exp * 1000; // convert to milliseconds

            return {
                exp: expTime,
                id,
                username,
                roles,
                language,
            };
        } catch (e) {
            return null;
        }
    },

    async validateToken(token) {
        try {
            let tokenData = this.parseToken(token);

            if (tokenData) {
                const { exp } = tokenData;
                const currentTime = new Date().getTime();

                if (exp > currentTime) {
                    this.scheduleRefreshToken(exp);
                } else {
                    tokenData = await store.dispatch('auth/refreshToken');
                }

                return tokenData;
            }

            return null;
        } catch (e) {
            return null;
        }
    },

    cancelScheduledRefreshToken() {
        if (this.refreshTokenTimer) {
            window.clearTimeout(this.refreshTokenTimer);
        }
    },

    scheduleRefreshToken(exp) {
        const currentTime = new Date().getTime();

        // cancel existing refresh timer
        this.cancelScheduledRefreshToken();

        if (!exp || exp <= currentTime) {
            return;
        }

        // refresh access token 3min before it expires
        const refreshTime = exp - currentTime - 180000;

        this.refreshTokenTimer = window.setTimeout(() => {
            store.dispatch('auth/refreshToken');
        }, refreshTime);
    },

    checkIfUserAllowed(user) {
        const allowedUserIds = (process.env.VUE_APP_ALLOWED_USERS || '')
            .replace(/ /g, '')
            .split(',')
            .filter(Boolean)
            .map(Number);

        if (!allowedUserIds.length) {
            return;
        }

        if (allowedUserIds.includes(user.id)) {
            return;
        }

        throw new Error(i18n.t('error.unauthorized'));
    },

    async fetchUserLight() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/users/myself`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserAccount(userId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/users/${userId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserNotificationSettings(userId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/notification-settings`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserProfile(userId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/profile`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updatePersonalSettings(userId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/users/${userId}`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateCompanySettings(userId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/profile`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async changePassword(data) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/users/password`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async resetPassword(data) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/users/send-reset-password-email`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async resetPasswordComplete(data) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/users/reset-password`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async acceptTerms() {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/users/policies`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async confirmEmail(token) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/users/confirm-email`,
                { token },
                { withAuth: false },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            const message = formattedError.statusCode === 404 ? i18n.t('confirmEmail.invalidToken') : formattedError.message;
            throw new Error(message);
        }
    },
};
