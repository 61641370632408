import { deburr, orderBy } from 'lodash';
import {
    CLEAN_PLUGIN,
    FETCH_AVAILABLE_CAMPAIGNS_FAILURE,
    FETCH_AVAILABLE_CAMPAIGNS_REQUEST,
    FETCH_AVAILABLE_CAMPAIGNS_SUCCESS,
    FETCH_PLUGINS_FAILURE,
    FETCH_PLUGINS_REQUEST,
    FETCH_PLUGINS_SUCCESS,
    FETCH_SORT_TYPES_FAILURE,
    FETCH_SORT_TYPES_REQUEST,
    FETCH_SORT_TYPES_SUCCESS,
    UPDATE_PLUGIN_APPEARANCE_FAILURE,
    UPDATE_PLUGIN_APPEARANCE_REQUEST,
    UPDATE_PLUGIN_APPEARANCE_SUCCESS,
    CREATE_PLUGIN_CAMPAIGN_FAILURE,
    CREATE_PLUGIN_CAMPAIGN_REQUEST,
    CREATE_PLUGIN_CAMPAIGN_SUCCESS,
    DELETE_PLUGIN_CAMPAIGN_REQUEST,
    DELETE_PLUGIN_CAMPAIGN_SUCCESS,
    DELETE_PLUGIN_CAMPAIGN_FAILURE,
    UPDATE_PLUGIN_CAMPAIGN_REQUEST,
    UPDATE_PLUGIN_CAMPAIGN_SUCCESS,
    UPDATE_PLUGIN_CAMPAIGN_FAILURE,
    FETCH_AVAILABLE_EXTENSIONS_REQUEST,
    FETCH_AVAILABLE_EXTENSIONS_SUCCESS,
    FETCH_AVAILABLE_EXTENSIONS_FAILURE,
    UPDATE_PLUGIN_EXTENSION_REQUEST,
    UPDATE_PLUGIN_EXTENSION_SUCCESS,
    UPDATE_PLUGIN_EXTENSION_FAILURE,
    FETCH_ACTIVE_CAMPAIGNS_REQUEST,
    FETCH_ACTIVE_CAMPAIGNS_SUCCESS,
    FETCH_ACTIVE_CAMPAIGNS_FAILURE,
    FETCH_AVAILABILITIES_REQUEST,
    FETCH_AVAILABILITIES_SUCCESS,
    FETCH_AVAILABILITIES_FAILURE,
} from '../mutationTypes';
import pluginService from '../../services/pluginService';
import { i18n } from '../../i18n/i18n';
import numberService from '../../services/numberService';

const initialState = {
    pluginsIds: [],
    plugins: {},
    activeCampaignsIds: [],
    activeCampaigns: {},
    availableCampaignsIds: [],
    availableCampaigns: {},
    availableExtensions: [],
    availabilities: [],
    sortTypesIds: [],
    sortTypes: {},
    loading: {
        fetchPlugins: false,
        fetchAvailabilities: false,
        fetchActiveCampaigns: false,
        fetchAvailableCampaigns: false,
        fetchSortTypes: false,
        fetchAvailableExtensions: false,
        updateExtension: false,
        updateAppearance: false,
        createCampaign: [],
        updateCampaign: [],
        deleteCampaign: [],
    },
};

const getters = {
    plugin: state => (state.pluginsIds && state.pluginsIds.length > 0 ? state.plugins[state.pluginsIds[0]] : null),
    activeCampaigns: state => (state.activeCampaignsIds ? state.activeCampaignsIds.map(id => state.activeCampaigns[id]) : []),
    availableCampaigns: state => (state.availableCampaignsIds ? state.availableCampaignsIds.map(id => state.availableCampaigns[id]) : []),
    activeCampaignsByName: state => (state.activeCampaignsIds ? orderBy(state.activeCampaignsIds.map(id => state.activeCampaigns[id]), [item => deburr(item.name.toLowerCase())], 'asc') : []),
    // eslint-disable-next-line no-shadow
    availableCampaignsNotActive: (state, getters) => {
        if (!state.availableCampaignsIds || state.availableCampaignsIds.length === 0) {
            return [];
        }
        return state.availableCampaignsIds
            .filter(item => !getters.activeCampaignsByName.some(activeItem => activeItem.id === item))
            .map(id => state.availableCampaigns[id]);
    },
    availabilities: state => state.availabilities,
};

const actions = {
    cleanPlugin({ commit }) {
        commit(CLEAN_PLUGIN);
    },

    async fetchPlugins({ commit, state, rootState }, payload = {}) {
        try {
            const { force } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if (!force && state.pluginsIds && state.pluginsIds.length > 0) {
                return;
            }

            commit(FETCH_PLUGINS_REQUEST);

            const plugin = await pluginService.fetchProducerPlugin(spaceId);

            commit(FETCH_PLUGINS_SUCCESS, {
                items: plugin ? [plugin] : [],
            });
        } catch (e) {
            commit(FETCH_PLUGINS_FAILURE);
            throw e;
        }
    },
    async fetchAvailabilities({ commit }) {
        try {
            commit(FETCH_AVAILABILITIES_REQUEST);
            const availabilities = await pluginService.fetchAvailabilities();
            commit(FETCH_AVAILABILITIES_SUCCESS, {
                items: availabilities.items,
            });
        } catch (e) {
            commit(FETCH_AVAILABILITIES_FAILURE);
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async fetchActiveCampaigns({ commit, state, getters }, payload = {}) {
        try {
            const { force } = payload;
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId) {
                return;
            }

            if (!force && state.activeCampaignsIds && state.activeCampaignsIds.length > 0) {
                return;
            }

            commit(FETCH_ACTIVE_CAMPAIGNS_REQUEST);

            const activeCampaigns = await pluginService.fetchActiveCampaigns(buyboxId);

            commit(FETCH_ACTIVE_CAMPAIGNS_SUCCESS, {
                items: activeCampaigns,
            });
        } catch (e) {
            commit(FETCH_ACTIVE_CAMPAIGNS_FAILURE);
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async fetchAvailableCampaigns({ commit, state, getters }, payload = {}) {
        try {
            const { force } = payload;
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId) {
                return;
            }

            if (!force && state.availableCampaignsIds && state.availableCampaignsIds.length > 0) {
                return;
            }

            commit(FETCH_AVAILABLE_CAMPAIGNS_REQUEST);

            const availableCampaigns = await pluginService.fetchAvailableCampaigns(buyboxId);

            commit(FETCH_AVAILABLE_CAMPAIGNS_SUCCESS, {
                items: availableCampaigns,
            });
        } catch (e) {
            commit(FETCH_AVAILABLE_CAMPAIGNS_FAILURE);
            throw e;
        }
    },
    async fetchAvailableExtensions({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.availableExtensions && state.availableExtensions.length > 0) {
                return;
            }

            commit(FETCH_AVAILABLE_EXTENSIONS_REQUEST);

            const availableExtensions = await pluginService.fetchAvailableExtensions();

            commit(FETCH_AVAILABLE_EXTENSIONS_SUCCESS, {
                items: availableExtensions,
            });
        } catch (e) {
            commit(FETCH_AVAILABLE_EXTENSIONS_FAILURE);
            throw e;
        }
    },
    async fetchSortTypes({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.sortTypesIds && state.sortTypesIds.length > 0) {
                return;
            }

            commit(FETCH_SORT_TYPES_REQUEST);

            const sortTypes = await pluginService.fetchSortTypes();

            commit(FETCH_SORT_TYPES_SUCCESS, {
                items: sortTypes,
            });
        } catch (e) {
            commit(FETCH_SORT_TYPES_FAILURE);
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async updateExtensionSettings({ commit, getters }, payload = {}) {
        try {
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId) {
                return;
            }

            commit(UPDATE_PLUGIN_EXTENSION_REQUEST);

            const plugin = await pluginService.updatePlugin(buyboxId, {
                extensionType: payload.extension,
                ...(payload.extension
                    ? { extension: { type: payload.extension, ...payload.settings } }
                    : { extension: null }),
            });

            commit(UPDATE_PLUGIN_EXTENSION_SUCCESS, {
                plugin,
            });

            this._vm.$toast.info(i18n.t('pluginExtension.saveSuccess'), { color: 'success' });
        } catch (e) {
            commit(UPDATE_PLUGIN_EXTENSION_FAILURE);
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async updateAppearance({ commit, getters }, payload = {}) {
        try {
            const {
                sortType, displayedShops, leadColor, buttonLabel, showProduct, showPrices, shopStyle, additionalColumn, additionalColumnOnlyForSorted, version, language, availabilities,
            } = payload;
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId) {
                return;
            }

            commit(UPDATE_PLUGIN_APPEARANCE_REQUEST);

            const data = {
                rowCount: numberService.zeroOrInteger(displayedShops),
                leadColor,
                buttonLabel,
                showProduct,
                showPrices,
                shopStyle,
                additionalColumn,
                additionalColumnOnlyForSorted,
                version,
                language,
                availabilities,
            };
            if (sortType) {
                data.sortType = sortType;
            }
            const plugin = await pluginService.updatePlugin(buyboxId, data);

            commit(UPDATE_PLUGIN_APPEARANCE_SUCCESS, {
                plugin,
            });

            this._vm.$toast.info(i18n.t('pluginAppearance.saveSuccess'), { color: 'success' });
        } catch (e) {
            commit(UPDATE_PLUGIN_APPEARANCE_FAILURE);
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async createCampaign({ commit, getters }, payload = {}) {
        const { campaign } = payload;

        try {
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId || !campaign) {
                return;
            }

            commit(CREATE_PLUGIN_CAMPAIGN_REQUEST, {
                campaignId: campaign.campaign.id,
            });

            await pluginService.createCampaign(buyboxId, campaign.campaign.id);

            commit(CREATE_PLUGIN_CAMPAIGN_SUCCESS, {
                pluginId: buyboxId,
                campaign,
            });

            this._vm.$toast.info(i18n.t('pluginCampaignsList.createSuccess', { name: campaign.campaign.name }), { color: 'success' });
        } catch (e) {
            commit(CREATE_PLUGIN_CAMPAIGN_FAILURE, {
                campaignId: campaign && campaign.campaign ? campaign.campaign.id : null,
            });
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async updateCampaign({ commit, getters }, payload = {}) {
        const { campaign } = payload;

        try {
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId || !campaign) {
                return;
            }

            commit(UPDATE_PLUGIN_CAMPAIGN_REQUEST, {
                campaignId: campaign.campaign.id,
            });

            await pluginService.updateCampaign(buyboxId, campaign.campaign.id, { priority: campaign.priority });

            commit(UPDATE_PLUGIN_CAMPAIGN_SUCCESS, {
                pluginId: buyboxId,
                campaign,
            });

            this._vm.$toast.info(i18n.t('pluginCampaignsList.updateSuccess', { name: campaign.campaign.name }), { color: 'success' });
        } catch (e) {
            commit(UPDATE_PLUGIN_CAMPAIGN_FAILURE, {
                campaignId: campaign && campaign.campaign ? campaign.campaign.id : null,
            });
            throw e;
        }
    },

    // eslint-disable-next-line no-shadow
    async deleteCampaign({ commit, getters }, payload = {}) {
        const { campaign } = payload;

        try {
            const buyboxId = getters.plugin ? getters.plugin.id : null;

            if (!buyboxId || !campaign) {
                return;
            }

            commit(DELETE_PLUGIN_CAMPAIGN_REQUEST, {
                campaignId: campaign.campaign.id,
            });

            await pluginService.deleteCampaign(buyboxId, campaign.campaign.id);

            commit(DELETE_PLUGIN_CAMPAIGN_SUCCESS, {
                pluginId: buyboxId,
                campaign,
            });

            this._vm.$toast.info(i18n.t('pluginCampaignsList.deleteSuccess', { name: campaign.campaign.name }), { color: 'success' });
        } catch (e) {
            commit(DELETE_PLUGIN_CAMPAIGN_FAILURE, {
                campaignId: campaign && campaign.campaign ? campaign.campaign.id : null,
            });
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_PLUGIN
    // -----------------------------------------
    [CLEAN_PLUGIN](state) {
        state.pluginsIds = [];
        state.plugins = {};
        state.activeCampaignsIds = [];
        state.activeCampaigns = {};
        state.availableCampaignsIds = [];
        state.availableCampaigns = {};
        state.availableExtensions = [];
        state.sortTypesIds = [];
        state.sortTypes = {};
        state.loading.fetchPlugins = false;
        state.loading.fetchAvailableCampaigns = false;
        state.loading.fetchAvailableExtensions = false;
        state.loading.updateExtension = false;
        state.loading.fetchActiveCampaigns = false;
        state.loading.fetchSortTypes = false;
        state.loading.updateAppearance = false;
        state.loading.createCampaign = [];
        state.loading.updateCampaign = [];
        state.loading.deleteCampaign = [];
    },

    // -----------------------------------------
    // FETCH_PLUGINS
    // -----------------------------------------
    [FETCH_PLUGINS_REQUEST](state) {
        state.loading.fetchPlugins = true;
    },
    [FETCH_PLUGINS_SUCCESS](state, payload) {
        const { items } = payload;
        const pluginsIds = [];
        const plugins = {};
        items.forEach(campaign => {
            pluginsIds.push(campaign.id);
            plugins[campaign.id] = campaign;
        });
        state.loading.fetchPlugins = false;
        state.pluginsIds = pluginsIds;
        state.plugins = plugins;
    },
    [FETCH_PLUGINS_FAILURE](state) {
        state.loading.fetchPlugins = false;
    },

    // -----------------------------------------
    // FETCH_ACTIVE_CAMPAIGNS
    // -----------------------------------------
    [FETCH_ACTIVE_CAMPAIGNS_REQUEST](state) {
        state.loading.fetchActiveCampaigns = true;
    },
    [FETCH_ACTIVE_CAMPAIGNS_SUCCESS](state, payload) {
        const { items } = payload;
        const activeCampaignsIds = [];
        const activeCampaigns = {};
        items.forEach(campaign => {
            activeCampaignsIds.push(campaign.campaign.id);
            // unify data structure with available campaigns to simplify template logic
            activeCampaigns[campaign.campaign.id] = { ...campaign, id: campaign.campaign.id, name: campaign.campaign.name };
        });
        state.loading.fetchActiveCampaigns = false;
        state.activeCampaignsIds = activeCampaignsIds;
        state.activeCampaigns = activeCampaigns;
    },
    [FETCH_ACTIVE_CAMPAIGNS_FAILURE](state) {
        state.loading.fetchActiveCampaigns = false;
    },

    // -----------------------------------------
    // FETCH_AVAILABLE_CAMPAIGNS
    // -----------------------------------------
    [FETCH_AVAILABLE_CAMPAIGNS_REQUEST](state) {
        state.loading.fetchAvailableCampaigns = true;
    },
    [FETCH_AVAILABLE_CAMPAIGNS_SUCCESS](state, payload) {
        const { items } = payload;
        const availableCampaignsIds = [];
        const availableCampaigns = {};
        Object.keys(items).forEach(campaignId => {
            const campaign = items[campaignId];
            availableCampaignsIds.push(campaign.id);
            availableCampaigns[campaign.id] = campaign;
        });
        state.loading.fetchAvailableCampaigns = false;
        state.availableCampaignsIds = availableCampaignsIds;
        state.availableCampaigns = availableCampaigns;
    },
    [FETCH_AVAILABLE_CAMPAIGNS_FAILURE](state) {
        state.loading.fetchAvailableCampaigns = false;
    },

    // -----------------------------------------
    // FETCH_SORT_TYPES
    // -----------------------------------------
    [FETCH_SORT_TYPES_REQUEST](state) {
        state.loading.fetchSortTypes = true;
    },
    [FETCH_SORT_TYPES_SUCCESS](state, payload) {
        const { items } = payload;
        const sortTypesIds = [];
        const sortTypes = {};
        items.forEach(sortType => {
            // unify data structure, as plugin data has sort type ID as string
            const sortTypeId = sortType.value.toString();
            sortTypesIds.push(sortTypeId);
            sortTypes[sortType.value] = {
                ...sortType,
                value: sortTypeId,
            };
        });
        state.loading.fetchSortTypes = false;
        state.sortTypesIds = sortTypesIds;
        state.sortTypes = sortTypes;
    },
    [FETCH_SORT_TYPES_FAILURE](state) {
        state.loading.fetchSortTypes = false;
    },

    // -----------------------------------------
    // FETCH_AVAILABLE_EXTENSIONS
    // -----------------------------------------
    [FETCH_AVAILABLE_EXTENSIONS_REQUEST](state) {
        state.loading.fetchAvailableExtensions = true;
    },
    [FETCH_AVAILABLE_EXTENSIONS_SUCCESS](state, payload) {
        const { items } = payload;
        state.loading.fetchAvailableExtensions = false;
        state.availableExtensions = items;
    },
    [FETCH_AVAILABLE_EXTENSIONS_FAILURE](state) {
        state.loading.fetchAvailableExtensions = false;
    },

    // -----------------------------------------
    // UPDATE_PLUGIN_APPEARANCE
    // -----------------------------------------
    [UPDATE_PLUGIN_APPEARANCE_REQUEST](state) {
        state.loading.updateAppearance = true;
    },
    [UPDATE_PLUGIN_APPEARANCE_SUCCESS](state, payload) {
        state.loading.updateAppearance = false;
        const { plugin } = payload;
        state.plugins = {
            ...state.plugins,
            [plugin.id]: {
                ...state.plugins[plugin.id],
                sortType: plugin.sortType,
                rowCount: plugin.rowCount,
                leadColor: plugin.leadColor,
                buttonLabel: plugin.buttonLabel,
                showProduct: plugin.showProduct,
                showPrices: plugin.showPrices,
                shopStyle: plugin.shopStyle,
                additionalColumn: plugin.additionalColumn,
                additionalColumnOnlyForSorted: plugin.additionalColumnOnlyForSorted,
                version: plugin.version,
                language: plugin.language,
            },
        };
    },
    [UPDATE_PLUGIN_APPEARANCE_FAILURE](state) {
        state.loading.updateAppearance = false;
    },

    // -----------------------------------------
    // UPDATE_PLUGIN_EXTENSION
    // -----------------------------------------
    [UPDATE_PLUGIN_EXTENSION_REQUEST](state) {
        state.loading.updateExtension = true;
    },
    [UPDATE_PLUGIN_EXTENSION_SUCCESS](state, payload) {
        state.loading.updateExtension = false;
        const { plugin } = payload;
        state.plugins = {
            ...state.plugins,
            [plugin.id]: {
                ...state.plugins[plugin.id],
                extensionType: plugin.extensionType,
                extension: plugin.extension,
            },
        };
    },
    [UPDATE_PLUGIN_EXTENSION_FAILURE](state) {
        state.loading.updateExtension = false;
    },

    // -----------------------------------------
    // CREATE_PLUGIN_CAMPAIGN
    // -----------------------------------------
    [CREATE_PLUGIN_CAMPAIGN_REQUEST](state, payload) {
        const { campaignId } = payload;
        state.loading.createCampaign.push(campaignId);
    },
    [CREATE_PLUGIN_CAMPAIGN_SUCCESS](state, payload) {
        const { campaign } = payload;
        state.loading.createCampaign = state.loading.createCampaign.filter(item => item !== campaign.campaign.id);
        state.activeCampaigns = {
            ...state.activeCampaigns,
            [campaign.campaign.id]: { ...campaign, id: campaign.campaign.id, name: campaign.campaign.name },
        };
        state.activeCampaignsIds.push(campaign.campaign.id);
    },
    [CREATE_PLUGIN_CAMPAIGN_FAILURE](state, payload) {
        const { campaignId } = payload;
        state.loading.createCampaign = state.loading.createCampaign.filter(item => item !== campaignId);
    },

    // -----------------------------------------
    // UPDATE_PLUGIN_CAMPAIGN
    // -----------------------------------------
    [UPDATE_PLUGIN_CAMPAIGN_REQUEST](state, payload) {
        const { campaignId } = payload;
        state.loading.updateCampaign.push(campaignId);
    },
    [UPDATE_PLUGIN_CAMPAIGN_SUCCESS](state, payload) {
        const { campaign } = payload;
        state.loading.updateCampaign = state.loading.updateCampaign.filter(item => item !== campaign.campaign.id);
        state.activeCampaigns = {

            ...state.activeCampaigns,
            [campaign.campaign.id]: {
                ...campaign,
                id: campaign.campaign.id,
                name: campaign.campaign.name,
                priority: campaign.priority,
            },
        };
    },
    [UPDATE_PLUGIN_CAMPAIGN_FAILURE](state, payload) {
        const { campaignId } = payload;
        state.loading.updateCampaign = state.loading.updateCampaign.filter(item => item !== campaignId);
    },

    // -----------------------------------------
    // DELETE_PLUGIN_CAMPAIGN
    // -----------------------------------------
    [DELETE_PLUGIN_CAMPAIGN_REQUEST](state, payload) {
        const { campaignId } = payload;
        state.loading.deleteCampaign.push(campaignId);
    },
    [DELETE_PLUGIN_CAMPAIGN_SUCCESS](state, payload) {
        const { campaign } = payload;
        state.loading.deleteCampaign = state.loading.deleteCampaign.filter(item => item !== campaign.campaign.id);
        const { [campaign.campaign.id]: omit, ...activeCampaigns } = state.activeCampaigns;
        state.activeCampaigns = activeCampaigns;

        state.activeCampaignsIds = state.activeCampaignsIds.filter(campaignId => campaignId !== campaign.campaign.id);
    },
    [DELETE_PLUGIN_CAMPAIGN_FAILURE](state, payload) {
        const { campaignId } = payload;
        state.loading.deleteCampaign = state.loading.deleteCampaign.filter(item => item !== campaignId);
    },
    // -----------------------------------------
    // FETCH_AVAILABILITIES
    // -----------------------------------------
    [FETCH_AVAILABILITIES_REQUEST](state) {
        state.loading.fetchAvailabilities = true;
    },
    [FETCH_AVAILABILITIES_SUCCESS](state, payload) {
        const { items } = payload;
        state.loading.fetchAvailabilities = false;
        state.availabilities = items;
    },
    [FETCH_AVAILABILITIES_FAILURE](state) {
        state.loading.fetchAvailabilities = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
