import Vue from 'vue';
import VueI18n from 'vue-i18n';
import countryService from '../services/countryService';
import messagesEnGB from './en-GB';

Vue.use(VueI18n);

const supportedLanguages = [
    {
        language: 'en',
        locale: 'en-GB',
        name: 'English',
    },
    {
        language: 'pl',
        locale: 'pl',
        name: 'Polski',
    },
    {
        language: 'lt',
        locale: 'lt',
        name: 'Lietuvių',
        widgetOnly: true,
    },
    {
        language: 'lv',
        locale: 'lv',
        name: 'Latviešu',
        widgetOnly: true,
    },
    {
        language: 'et',
        locale: 'et',
        name: 'Eesti',
        widgetOnly: true,
    },
    {
        language: 'de',
        locale: 'de',
        name: 'Deutsch',
        widgetOnly: true,
    },
    {
        language: 'dk',
        locale: 'dk',
        name: 'Dansk',
        widgetOnly: true,
    },
    {
        language: 'fi',
        locale: 'fi',
        name: 'Suomalainen',
        widgetOnly: true,
    },
    {
        language: 'fr',
        locale: 'fr',
        name: 'Français',
        widgetOnly: true,
    },
    {
        language: 'nl',
        locale: 'nl',
        name: 'Nederlands',
        widgetOnly: true,
    },
];

const defaultLocale = window.localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LOCALE || 'en-GB';

export const getLocaleFromLanguage = language => {
    const selectedLanguage = supportedLanguages.find(lang => lang.language === language);
    return selectedLanguage ? selectedLanguage.locale : defaultLocale;
};
export const getLanguageFromLocale = locale => {
    const selectedLocale = supportedLanguages.find(lang => lang.locale === locale);
    return selectedLocale ? selectedLocale.language : defaultLocale;
};

const loadedLanguages = ['en-GB'];
const messages = {
    'en-GB': messagesEnGB,
};
const dateTimeFormats = {
    'en-GB': {
        basic: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        },
        longNumeric: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        },
        month: {
            year: 'numeric',
            month: 'long',
        },
        monthName: {
            month: 'long',
        },
        year: {
            year: 'numeric',
        },
    },
    pl: {
        basic: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        },
        longNumeric: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        },
        month: {
            year: 'numeric',
            month: 'long',
        },
        monthName: {
            month: 'long',
        },
        year: {
            year: 'numeric',
        },
    },
};
export const i18n = new VueI18n({
    locale: 'en-GB',
    fallbackLocale: 'en-GB',
    messages,
    dateTimeFormats,
});

document.querySelector('html').setAttribute('lang', 'en-GB');

function setI18nLanguage(locale) {
    i18n.locale = locale;
    document.querySelector('html').setAttribute('lang', locale);
    window.localStorage.setItem('locale', locale);
    return locale;
}

export async function loadLanguageAsync(locale) {
    await countryService.loadCountriesLanguage(locale);

    if (i18n.locale !== locale) {
        if (!loadedLanguages.includes(locale)) {
            return import(/* webpackChunkName: "lang-[request]" */ `./${locale}`)
                .then(async msgs => {
                    i18n.setLocaleMessage(locale, msgs.default);
                    loadedLanguages.push(locale);
                    return setI18nLanguage(locale);
                })
                .catch(() => {
                    console.error(`Locale file for "${locale}" not found.`);
                    window.localStorage.setItem('locale', i18n.locale);
                });
        }
        return Promise.resolve(setI18nLanguage(locale));
    }
    return Promise.resolve(locale);
}

if (defaultLocale !== 'en-GB') {
    loadLanguageAsync(defaultLocale);
}

countryService.loadCountriesLanguage(defaultLocale);

export function getSupportedLanguages(includeWidgetLanguages = false) {
    return supportedLanguages.filter(option => includeWidgetLanguages || !option.widgetOnly);
}

export function getLanguageOptions(includeWidgetLanguages = false) {
    return supportedLanguages
        .filter(option => includeWidgetLanguages || !option.widgetOnly)
        .map(option => ({
            value: option.language,
            text: i18n.t(`language.${option.language}`),
        }));
}
