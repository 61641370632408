<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Katalog produktów" />
            </template>
        </page-header-filters>
        <v-card class="elevation-0 mb-4">
            <v-card-text>
                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">{{error}}</v-alert>

                <div v-if="configLoading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-layout v-if="!configLoading" row wrap>
                    <v-flex
                        class="mb-4 list-container"
                        xs12
                        sm4
                        md4
                        lg4
                        xl4>
                        <v-flex xs12>
                            <v-btn class="mb-3 ml-0" :loading="createProductLoading" @click="createProductModalOpen = true">
                                <v-icon left small>fa-plus</v-icon>
                                {{ $t('producerProducts.createProduct') }}
                            </v-btn>
                            <update-product-modal v-model="createProductModalOpen" />
                        </v-flex>
                        <v-flex xs11>
                            <v-text-field
                                name="filterText"
                                v-model="search"
                                :label="$t('producerProducts.filter')"
                                type="text"
                                required
                                maxlength="255"
                                :placeholder="$t('producerProducts.filterPlaceholder')"
                                outline
                                clearable
                                clear-icon="fa-times"
                                autocomplete="off"
                                @keyup="handleSearch"
                                @click:clear="fetchItems(null, true)"
                            />
                        </v-flex>
                        <div v-if="itemsLoading" class="text-xs-center overflow-hidden">
                            <v-progress-circular indeterminate color="primary" />
                        </div>
                        <v-treeview
                            v-if="!itemsLoading"
                            class="products-tree"
                            ref="tree"
                            :items="treeProducts"
                            :open.sync="open"
                            return-object
                            :load-children="fetchItems"
                            :multiple-active="false"
                            :active.sync="active"
                            active-class="tree-node--active"
                            item-key="id"
                            item-text="text"
                            item-children="items"
                            loading-icon="fa-circle-notch"
                            transition
                            activatable
                        >
                            <template v-slot:prepend="{ item, open, active }">
                                <v-icon
                                    v-if="item.type === 'category'"
                                    class="ml-2 mr-1"
                                    :color="active ? 'secondary' : ''"
                                    small
                                >{{ open ? 'fa-folder-open' : 'fa-folder' }}</v-icon>
                                <v-icon
                                    v-else
                                    class="ml-2 mr-1"
                                    :color="active ? 'secondary' : ''"
                                    small>fa-file</v-icon>
                            </template>
                        </v-treeview>
                    </v-flex>
                    <v-flex
                        v-if="!itemsLoading && getterProductsTree.length > 0"
                        class="mb-4 pl-4"
                        xs12
                        sm8
                        md8
                        lg8
                        xl8>
                        <v-scroll-y-transition mode="out-in">
                            <div v-if="!selected || (selected && !selected.id)">
                                {{ $t('producerProducts.noSelectedItem') }}
                            </div>
                            <div v-else :key="selected.id">
                                <producer-product-details
                                    :product-id="selected.id"
                                    :selected-product="selected"
                                    :date-filter="dateFilter"
                                    @filterDefaultChange="handleFilterDefaultChange"
                                    @deletedProduct="handleDeletedProduct"
                                />
                            </div>
                        </v-scroll-y-transition>
                    </v-flex>
                </v-layout>

                <data-placeholder
                    v-if="!configLoading && !itemsLoading && getterProductsTree.length === 0"
                    :title="$t('producerProducts.noResults')"
                    :subtitle="$t('producerProducts.noResultsDescription')"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { cloneDeep, trim, debounce } from 'lodash';
import UpdateProductModal from '@/components/producer/products/UpdateProductModal.vue';
import ProducerProductDetails from '../../components/producer/products/ProducerProductDetails.vue';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DateRangePicker from '../../components/common/DateRangePicker.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import ResetFiltersButton from '../../components/common/ResetFiltersButton.vue';

export default {
    name: 'producer-products',
    components: {
        UpdateProductModal,
        ResetFiltersButton,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
        ProducerProductDetails,
    },
    data() {
        return {
            spaceInitialized: false,
            loadingConfig: false,
            error: null,
            active: [],
            open: ['all'],
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            search: null,
            isDatepickerFilterDefault: true,
            isProductTrafficFilterDefault: true,
            isProductSalesFilterDefault: true,
            isProductAvailabilityFilterDefault: true,
            isProductPriceDistributionFilterDefault: true,
            createProductModalOpen: false,
        };
    },
    computed: {
        configLoading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        itemsLoading() {
            return this.rootProductsLoading || this.searchProductsLoading;
        },
        selected() {
            if (!this.active.length || this.active[0].id === 'all') {
                return undefined;
            }
            return this.active[0];
        },
        treeProducts() {
            const tree = [
                {
                    id: 'all',
                    text: 'All',
                    type: 'category',
                    items: this.getterProductsTree,
                },
            ];
            return cloneDeep(tree);
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isProductTrafficFilterDefault || !this.isProductSalesFilterDefault || !this.isProductAvailabilityFilterDefault || !this.isProductPriceDistributionFilterDefault;
        },
        ...mapState({
            rootProductsLoading: state => state.products.loading.rootProducts,
            searchProductsLoading: state => state.products.loading.searchProducts,
            createProductLoading: state => state.products.loading.createProduct,
        }),
        ...mapGetters({
            getterProductsTree: 'products/getProductsTree',
        }),
    },
    watch: {
        active(to) {
            if (to && to.length > 0 && to[0].type === 'category') {
                const isOpen = this.open.find(item => item.id === to[0].id);

                if (!isOpen) {
                    this.open.push(to[0]);
                }
            }
        },
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'productTraffic') {
                this.isProductTrafficFilterDefault = isDefault;
            } else if (type === 'productSales') {
                this.isProductSalesFilterDefault = isDefault;
            } else if (type === 'productAvailability') {
                this.isProductAvailabilityFilterDefault = isDefault;
            } else if (type === 'productPriceDistribution') {
                this.isProductPriceDistributionFilterDefault = isDefault;
            }
        },
        async getData() {
            this.loadingConfig = true;
            this.fetchItems();
            await this.getCampaigns();
            this.loadingConfig = false;
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        prepareCategoryTour() {
            if (this.treeProducts[0].items.length) {
                this.$nextTick(() => {
                    this.$set(this.active, 0, this.treeProducts[0].items[0]);
                });
            }
        },
        prepareProductTour() {
            this.prepareCategoryTour();
            if (this.active.length) {
                this.$nextTick(() => {
                    const firstProduct = this.treeProducts[0].items.find(category => category.id === this.active[0].id);
                    if (firstProduct && firstProduct.items.length) {
                        this.$set(this.active, 0, firstProduct.items[0]);
                    }
                });
            }
        },
        async fetchItems(item, force = false) {
            try {
                if (!item) {
                    await this.fetchProducts({ force });
                    if (this.$refs.tree) {
                        this.$refs.tree.updateSelected('all');
                    }
                } else {
                    if (item.id === 'all') {
                        return;
                    }
                    await this.fetchProducts({ categoryId: item.id, entityId: item.entityId });
                }
                if (this.$route.meta.isCategoryTour) {
                    this.prepareCategoryTour();
                }
                if (this.$route.meta.isProductTour) {
                    this.prepareProductTour();
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async handleSpaceChange() {
            this.search = null;
            await this.getData();
            if (!this.spaceInitialized) {
                this.spaceInitialized = true;

                if (this.$route.query.search) {
                    this.search = this.$route.query.search;
                    await this.searchItems();
                    this.$nextTick(() => {
                        const treeItem = this.findTreeItem(this.treeProducts[0], parseInt(this.$route.query.searchId, 10));
                        if (treeItem) {
                            this.active.push(treeItem);
                        }
                    });
                }
            }
        },
        findTreeItem(treeNode, entityId) {
            let matchingNode;
            treeNode?.items.forEach(node => {
                if (node.entityId === entityId) {
                    matchingNode = node;
                    return;
                }
                matchingNode = this.findTreeItem(node, entityId);
            });
            return matchingNode;
        },
        handleSearch: debounce(function handleSearch() {
            this.searchItems();
        }, 750),
        async searchItems() {
            const search = trim(this.search);

            // if there is no search query then force fetch basic root level tree
            // otherwise API will return full depth tree
            if (!search) {
                this.fetchItems(null, true);
                return;
            }
            if (search.length < 3) {
                return;
            }

            try {
                await this.fetchSearchProducts({ search });
                if (this.$refs.tree) {
                    this.$refs.tree.updateAll(true);
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        handleDeletedProduct() {
            this.active = [];
        },
        ...mapActions({
            fetchProducts: 'products/fetchProducts',
            fetchSearchProducts: 'products/fetchSearchProducts',
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
        }),
    },
};
</script>

<style lang="scss">
    .list-container {
        overflow-x: auto;
    }

    .tree-node--active {
        font-weight: 700;
        color: $secondary-color;
    }

    .products-tree {
        .v-treeview-node__label {
            font-size: 1rem;

            &:hover {
                cursor: pointer;
            }
        }
    }
</style>
