<template>
    <div>
        <span class="subheading">{{ $t('spaceSwitch.label') }}:</span>
        <v-menu
            transition="scale-transition"
            origin="top right"
            bottom
            left>
            <template #activator="{ on }">
                <v-btn
                    flat
                    :loading="loading"
                    :disabled="loading || spaceCount === 0 || (spaceCount < 2 && !!currentSpace) || error"
                    v-on="on"
                >
                    <span v-if="spaceCount === 0 || !currentSpace || error" class="subheading font-weight-black">{{ $t('spaceSwitch.noResults') }}</span>
                    <span v-if="spaceCount > 0 && currentSpace && !error" class="subheading primary--text font-weight-black">{{ currentSpace.name }}</span>
                    <v-icon v-if="spaceCount > 1 && !error" small right>fa-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-tile
                    v-for="space in orderedSpaces"
                    :key="space.id"
                    @click="changeSpace(space.id)"
                    active-class="font-weight-black"
                    primary>
                    <v-list-tile-title>
                        {{ space.name }}
                    </v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { orderBy } from 'lodash';

export default {
    name: 'space-switch',
    data() {
        return {
            error: null,
        };
    },
    computed: {
        orderedSpaces() {
            const items = this.spacesIds.map(id => this.spaces[id]);
            return orderBy(items, ['name'], ['asc']);
        },
        ...mapState({
            loading: state => state.space.loading.fetchSpaces,
            spacesIds: state => state.space.spacesIds,
            spaces: state => state.space.spaces,
        }),
        ...mapGetters({
            currentSpace: 'space/currentSpace',
            spaceCount: 'space/spaceCount',
        }),
    },
    created() {
        this.getSpaces();
    },
    methods: {
        async getSpaces() {
            try {
                await this.fetchSpaces();
                const spaceId = this.currentSpace ? this.currentSpace.id : null;
                this.$emit('change', spaceId);
            } catch (e) {
                this.error = e.message;
            }
        },
        changeSpace(spaceId) {
            this.setCurrentSpace({ spaceId });
            this.$emit('change', spaceId);
        },
        ...mapActions({
            fetchSpaces: 'space/fetchSpaces',
            setCurrentSpace: 'space/setCurrentSpace',
        }),
    },
};
</script>

<style lang="scss" scoped>

</style>
