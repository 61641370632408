/* eslint-disable no-unused-vars */

import demoService from '@/services/demoService';
import reportsPriceDistributionServiceDemo
    from '@/services/demo-services/reportsPriceDistributionServiceDemo';
import apiService from './apiService';
import errorService from './errorService';
import exportService from './exportService';

const reportsPriceDistributionService = {
    async fetchProductsPriceAggregatedReport(spaceId, startDate, endDate, groupBy, campaigns, categories, groups, products) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-price-aggregated-report`,
                {
                    startDate,
                    endDate,
                    groupBy,
                    campaigns,
                    categories,
                    groups,
                    products,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async downloadPriceReport(report) {
        try {
            const reportId = report.id;
            const formattedFilename = `price-report-${report.startDate}-${report.endDate}-${report.space.name}`;
            await exportService.exportDataAuthorized(
                `${process.env.VUE_APP_API_URL}/api/v1/products-price-report-export/${reportId}/download`,
                formattedFilename,
                'xlsx',
            );
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async generatePriceReport(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups, products) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-price-report-export`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                    groups,
                    products,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchPriceReports(spaceId, pagination) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-price-report-export`,
                { perPage: pagination.perPage, page: pagination.page },
            );
            response.data.items.map(item => {
                // eslint-disable-next-line no-param-reassign
                item.max = response.data.max;
                return item;
            });
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProducerTransactionsItemsQuantityPrice(spaceId, startDate, endDate, campaigns, categories, groups, products) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-transactions-items-quantity-price`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                    groups,
                    products,
                },
            );
            return response.data.reduce((acc, cur) => {
                const timeWithoutHours = new Date(cur.createdAt).setHours(0, 0, 0, 0);
                const existItem = acc.find(item => item.date === timeWithoutHours);
                if (existItem) {
                    existItem.quantity += cur.quantity;
                    existItem.summary.push({
                        quantity: cur.quantity,
                        price: cur.price,
                    });
                    return acc;
                }
                const currentItem = {
                    createdAt: cur.createdAt,
                    date: timeWithoutHours,
                    quantity: cur.quantity,
                    summary: [{
                        quantity: cur.quantity,
                        price: cur.price,
                    }],
                };
                acc.push(currentItem);
                return acc;
            }, []);
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductsPriceChart(spaceId, startDate, endDate, campaigns, categories, groups, products) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-price-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                    groups,
                    products,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(reportsPriceDistributionService, reportsPriceDistributionServiceDemo, 'reportsPriceDistributionService');
export default reportsPriceDistributionService;
