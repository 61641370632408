<template>
    <div :class="{'mb-3': !!title}">
        <v-layout
            :class="{'mb-4': !!title}"
            row
            wrap
            align-center
            justify-space-between
        >
            <h4 v-if="title" class="subheading">
                {{ title }}
                <v-tooltip v-if="!loading" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            type="button"
                            class="mb-3"
                            color="tertiary"
                            :disabled="!!error || !items || items.length === 0"
                            @click="exportData"
                            flat
                            icon
                        >
                            <v-icon small>fa-download</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('export') }}</span>
                </v-tooltip>
            </h4>
            <v-layout
                v-if="!loading"
                wrap
                align-center
                justify-end
            >
                <v-btn
                    v-if="error"
                    class="mb-3"
                    @click="redo()"
                    flat
                    icon
                >
                    <v-icon small>fa-redo-alt</v-icon>
                </v-btn>
            </v-layout>
        </v-layout>

        <div v-if="loading" class="mt-2 text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>

        <v-alert
            :value="error"
            dismissible
            type="error"
            transition="scale-transition">
            {{ error }}
        </v-alert>

        <v-data-table
            v-if="items"
            class="elevation-0 table--transparent table--fixed"
            :headers="headers"
            :items="items"
            item-key="itemKey"
            :no-data-text="$t('productOffers.noResults')"
            :no-results-text="$t('productOffers.noResults')"
            :pagination.sync="pagination"
            disable-initial-sort
            sort-icon="fa-angle-up"
            hide-actions
        >
            <template v-slot:headers="props">
                <tr>
                    <th
                        v-for="(header, index) in props.headers"
                        :key="header.value + '-' + index"
                        :class="[
                            'column sortable',
                            header.value === pagination.sortBy ? 'active' : '',
                            index !== Object.keys(props.headers).length - 1 ? 'pr-0' : '',
                        ]"
                        :style="{ width: header.width ? `${header.width}px` : 'auto' }"
                        @click="changeSort(header.value)"
                    >
                        <v-layout
                            :justify-start="header.align === 'left'"
                            :justify-end="header.align === 'right'"
                            align-center
                        >
                            {{ header.text }}
                            <sort-order :direction="getSortOrder(header.value)" />
                        </v-layout>
                    </th>
                </tr>
            </template>

            <template v-slot:pageText="props">
                {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
            </template>

            <template v-slot:items="props">
                <td class="no-wrap-text">
                    <a class="product-offers-table__link" :href="props.item.campaignUrl" target="_blank">
                        <img
                            v-if="props.item.campaignIcon"
                            class="product-offers-table__campaign-icon"
                            :src="props.item.campaignIcon"
                            alt=""
                        />
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="text--overflow-ellipsis">
                                    {{ props.item.campaign }}
                                </div>
                            </template>
                            {{ props.item.campaign }}
                        </v-tooltip>
                    </a>
                </td>
                <td>
                    <a class="product-offers-table__link" :href="props.item.url" target="_blank">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="text--overflow-ellipsis">
                                    {{ props.item.name }}
                                </div>
                            </template>
                            {{ props.item.name }}
                        </v-tooltip>
                        <v-icon class="ml-1 external-link-icon">fa-external-link-alt</v-icon>
                    </a>
                </td>
                <td class="no-wrap-text text-xs-right">
                    {{ props.item.priceFormatted }}
                    <div class="grey--text product-offers-table__secondary-info">
                        {{ props.item.date && $d(new Date(props.item.date), 'longNumeric') }}
                    </div>
                </td>
            </template>
        </v-data-table>

        <div v-if="hasMoreOffers" class="text-xs-center">
            <v-btn
                class="btn-wide mt-4"
                type="submit"
                color="tertiary"
                dark
                round
                @click="showAll"
            >
                {{ $t('productOffers.showAll') }}
            </v-btn>
        </div>

        <data-placeholder v-if="!loading && (!items || items.length === 0)" :title="$t('productOffers.noResults')" />
    </div>
</template>

<script>
import DataPlaceholder from '../../common/DataPlaceholder.vue';
import SortOrder from '../../common/SortOrder.vue';
import exportService from '../../../services/exportService';
import numberService from '../../../services/numberService';

export default {
    name: 'product-offers-table',
    components: {
        SortOrder,
        DataPlaceholder,
    },
    props: {
        title: {
            type: String,
        },
        data: {
            type: Array,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        rowsToShow: {
            type: Number,
            default: null,
        },
        maxCampaignLength: {
            type: Number,
            default: 15,
        },
        itemKey: {
            type: String,
            default: 'id',
        },
    },
    data() {
        return {
            pagination: {
                rowsPerPage: -1,
            },
            showingAll: false,
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('productOffers.campaign'),
                    align: 'left',
                    width: (this.maxCampaignLength * 11) + 36,
                    sortable: true,
                    value: 'campaign',
                },
                {
                    text: this.$t('productOffers.offer'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('productOffers.price'),
                    align: 'right',
                    width: 160,
                    sortable: true,
                    value: 'price',
                },
            ];
        },
        items() {
            if (!this.data || this.data.length === 0) {
                return null;
            }
            if (!this.showingAll && this.rowsToShow) {
                return this.data.slice(0, this.rowsToShow);
            }
            return this.data;
        },
        hasMoreOffers() {
            return !this.showingAll && this.rowsToShow && this.data && this.data.length > this.items.length;
        },
    },
    methods: {
        showAll() {
            this.showingAll = true;
        },
        redo() {
            this.$emit('redo');
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('productOffers.campaign'))}"`);
            header.push(`"${exportService.parseText(this.$t('productOffers.offer'))}"`);
            header.push(`"${exportService.parseText(this.$t('productOffers.date'))}"`);
            header.push(`"${exportService.parseText(this.$t('productOffers.price'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);

            const rows = [];
            this.items.forEach(item => {
                const row = [];
                row.push(`"${exportService.parseText(item.campaign)}"`);
                row.push(`"${exportService.parseText(item.name)}"`);
                row.push(`"${exportService.parseText(item.date && this.$d(new Date(item.date), 'longNumeric'))}"`);
                row.push(`"${numberService.formatNumber(item.price, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.title);
        },
    },
};
</script>

<style lang="scss" scoped>
    .product-offers-table__campaign-icon {
        width: auto;
        height: 14px;
        margin-right: 4px;
    }

    .product-offers-table__secondary-info {
        font-size: 11px;
    }

    .product-offers-table__link {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: $text-dark-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
</style>
