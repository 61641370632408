<template>
    <div>
        <v-card-title>
            <v-layout row wrap align-center justify-space-between>
                <h4 class="subheading font-weight-bold">
                    {{ $t('availabilityPerShop.title') }}
                    <v-tooltip v-if="data && data.length" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                @click="exportData"
                                flat
                                icon>
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('graph.export') }}</span>
                    </v-tooltip>
                </h4>
                <div class="d-inline-flex" style="gap:1rem;">
                    <div class="d-flex align-center" style="gap:0.3rem;">
                        <div class="green lighten-3 success--text text--darken-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="caption">{{ $t('availableCurrently') }}</div>
                    </div>
                    <div class="d-flex align-center" style="gap:0.3rem;">
                        <div class="red lighten-3 error--text text--darken-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="caption">{{ $t('notAvailableCurrently') }}</div>
                    </div>
                </div>
            </v-layout>
        </v-card-title>

        <v-data-table
            class="elevation-0 table--transparent"
            :headers="headers"
            :items="items || []"
            :no-data-text="$t('noResults')"
            :no-results-text="$t('noResults')"
            :rows-per-page-text="$t('table.rowsPerPage')"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            sort-icon="fa-angle-up"
        >
            <template v-slot:headers="props">
                <tr class="header-no-border">
                    <td colspan="4"></td>
                    <td :colspan="campaign.length" class="header-bottom-border">{{ $t('availabilityPerShop.availabilityInShops') }}</td>
                </tr>
                <tr>
                    <th
                        v-for="header in props.headers"
                        :key="header.text"
                        :class="['column sortable', header.value === pagination.sortBy ? 'active' : '']"
                        @click="changeSort(header)"
                    >
                        <v-layout :justify-start="header.align === 'left'" :justify-end="header.align === 'right'" align-center>
                            <v-tooltip v-if="header.iconPath" top>
                                <template v-slot:activator="{ on }">
                                    <img v-on="on" class="header-campaign-logo" :src="header.iconPath" :alt="header.text" />
                                </template>
                                {{ header.text }}
                            </v-tooltip>
                            <span v-else>{{ header.text }}</span>

                            <sort-order v-if="header.sortable" :direction="getSortOrder(header.value)" />
                        </v-layout>
                    </th>
                </tr>
            </template>

            <template v-slot:pageText="props">
                {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
            </template>

            <template v-slot:items="props">
                <tr>
                    <td :key="props.item.product.id">
                        <v-tooltip v-if="props.item.custom.categoryTree" bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{ props.item.custom.mainCategory }}</span>
                            </template>
                            <v-treeview :items="props.item.custom.categoryTree" class="category-tree" open-all open-on-click dark></v-treeview>
                        </v-tooltip>
                    </td>
                    <td>
                        {{ props.item.product.name }}
                    </td>
                    <td>
                        {{ props.item.product.sku }}
                    </td>
                    <td>
                        {{ props.item.product.ean }}
                    </td>
                    <td v-for="campaignId in campaign" :key="campaignId" class="text-xs-center">
                        <div
                            class="availability-item"
                            :class="{
                                'green lighten-3 success--text text--darken-2': isAvailable(props.item.product.id, campaignId) === true,
                                'red lighten-3 error--text text--darken-2': isAvailable(props.item.product.id, campaignId) === false,
                            }"
                        >
                            <v-tooltip v-if="props.item.custom.categoryTree" bottom>
                                <template v-slot:activator="{ on }">
                                    <span
                                        v-on="on"
                                    >{{ props.item.availabilityPerShop[campaignIdNameMap[campaignId]].availabilityInDays }} / {{ props.item.custom.dateRangeInDays }}</span
                                    >
                                </template>
                                <div>
                                    {{ props.item.availabilityPerShop[campaignIdNameMap[campaignId]].availabilityInPercentageFormatted }}
                                </div>
                            </v-tooltip>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SortOrder from '@/components/common/SortOrder.vue';
import exportService from '@/services/exportService';
import { camelCase } from 'lodash';

export default {
    name: 'availability-per-shop',
    components: { SortOrder },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        campaign: {
            type: Array,
            default: () => [],
        },
        getCampaignNameFromId: {
            type: Function,
        },
    },
    data() {
        return {
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'custom.mainCategory',
                descending: false,
            },
        };
    },
    computed: {
        campaignIdNameMap() {
            return this.campaign.reduce(
                (map, campaignId) => ({
                    ...map,
                    [campaignId]: this.getCampaignNameFromId(campaignId),
                }),
                {},
            );
        },
        items() {
            return this.data.map(item => ({
                ...item,
                availabilityPerShopSimplified: Object.keys(item.availabilityPerShop).reduce(
                    (map, key) => ({
                        ...map,
                        [camelCase(key)]: item.availabilityPerShop[key].availabilityInDays,
                    }),
                    {},
                ),
            }));
        },
        headers() {
            const headers = [
                {
                    text: this.$t('category'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.mainCategory',
                },
                {
                    text: this.$t('product'),
                    align: 'left',
                    sortable: true,
                    value: 'product.name',
                },
                {
                    text: this.$t('model'),
                    align: 'left',
                    sortable: true,
                    value: 'product.sku',
                },
                {
                    text: this.$t('ean'),
                    align: 'left',
                    sortable: true,
                    value: 'product.ean',
                },
            ];
            this.campaign.forEach(campaignId => {
                const campaignDetails = this.getterActiveCampaigns.find(campaignItem => campaignItem.id === campaignId)
                    ?? this.getterAvailableCampaigns.find(campaignItem => campaignItem.id === campaignId);
                headers.push({
                    text: campaignDetails.name,
                    iconPath: campaignDetails.iconPath,
                    align: 'center',
                    sortable: true,
                    value: `availabilityPerShopSimplified.${camelCase(campaignDetails.name)}`,
                });
            });
            return headers;
        },
        ...mapGetters({
            getterActiveCampaigns: 'plugin/activeCampaignsByName',
            getterAvailableCampaigns: 'plugin/availableCampaignsNotActive',
        }),
    },
    methods: {
        isAvailable(productId, campaignId) {
            const available = this.data.find(item => item.product.id === productId)?.availabilities?.[campaignId];
            return typeof available === 'boolean' ? available : null;
        },
        changeSort(header) {
            if (!header.sortable) {
                return;
            }

            let sortBy = null;
            let descending = true;

            if (this.pagination.sortBy !== header.value) {
                sortBy = header.value;
                descending = true;
            } else if (this.pagination.sortBy === header.value && this.pagination.descending === true) {
                sortBy = header.value;
                descending = false;
            }

            this.pagination = {
                ...this.pagination,
                page: 1,
                sortBy,
                descending,
            };
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('category'))}"`);
            header.push(`"${exportService.parseText(this.$t('product'))}"`);
            header.push(`"${exportService.parseText(this.$t('model'))}"`);
            header.push(`"${exportService.parseText(this.$t('ean'))}"`);

            header.push(`"${exportService.parseText(this.$t('subcategory'))} 1"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 2"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 3"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 4"`);

            this.campaign.forEach(campaignId => {
                const campaignDetails = this.getterActiveCampaigns.find(campaignItem => campaignItem.id === campaignId)
                    ?? this.getterAvailableCampaigns.find(campaignItem => campaignItem.id === campaignId);
                header.push(`"${exportService.parseText(campaignDetails.name)}"`);
            });

            const rows = [];
            (this.data || []).forEach(item => {
                const row = [];
                const categories = [...(item.product?.categories?.[0] || [])]; // Make a shallow copy

                // Sort by `lvl` in ascending order
                categories.sort((a, b) => a.lvl - b.lvl);

                row.push(`"${exportService.parseText(categories[0]?.name || item.custom.mainCategory)}"`);
                row.push(`"${exportService.parseText(item.product.name)}"`);
                row.push(`"${exportService.parseText(item.product.sku)}"`);
                row.push(`"${exportService.parseText(item.product.ean)}"`);

                for (let i = 0; i < 4; i += 1) {
                    row.push(`"${exportService.parseText(categories[i + 1]?.name || '')}"`);
                }

                this.campaign.forEach(campaignId => {
                    const availabilityInShop = item.availabilityPerShop[this.campaignIdNameMap[campaignId]];
                    const availabilityInShopText = `${availabilityInShop.availabilityInDays} / ${item.custom.dateRangeInDays} (${availabilityInShop.availabilityInPercentageFormatted})`;
                    row.push(`"${exportService.parseText(availabilityInShopText)}"`);
                });
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('availabilityPerShop.title'));
        },
    },
};
</script>

<style lang="scss" scoped>
.header-campaign-logo {
    height: 32px;
}

.header-no-border {
    border-bottom: none !important;
}

.header-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.availability-item {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1px 3px;
}
</style>

<style lang="scss">
.category-tree {
    .v-treeview-node__root {
        min-height: 24px !important;
        height: 24px !important;
    }

    .v-treeview-node--leaf {
        margin-left: 25px;
    }

    .v-treeview-node__toggle {
        font-size: 16px !important;
    }

    .v-treeview-node__label {
        font-size: 13px !important;
    }
}
</style>
