var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'mb-3': !!_vm.title}},[_c('v-layout',{class:{'mb-4': !!_vm.title},attrs:{"row":"","wrap":"","align-center":"","justify-space-between":""}},[(_vm.title)?_c('h4',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.loading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-3",attrs:{"type":"button","color":"tertiary","disabled":!!_vm.error || !_vm.items || _vm.items.length === 0,"flat":"","icon":""},on:{"click":_vm.exportData}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-download")])],1)]}}],null,false,3527094921)},[_c('span',[_vm._v(_vm._s(_vm.$t('export')))])]):_vm._e()],1):_vm._e(),(!_vm.loading)?_c('v-layout',{attrs:{"wrap":"","align-center":"","justify-end":""}},[(_vm.error)?_c('v-btn',{staticClass:"mb-3",attrs:{"flat":"","icon":""},on:{"click":function($event){return _vm.redo()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-redo-alt")])],1):_vm._e()],1):_vm._e()],1),(_vm.loading)?_c('div',{staticClass:"mt-2 text-xs-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-alert',{attrs:{"value":_vm.error,"dismissible":"","type":"error","transition":"scale-transition"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),(_vm.items)?_c('v-data-table',{staticClass:"elevation-0 table--transparent table--fixed",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"itemKey","no-data-text":_vm.$t('productOffers.noResults'),"no-results-text":_vm.$t('productOffers.noResults'),"pagination":_vm.pagination,"disable-initial-sort":"","sort-icon":"fa-angle-up","hide-actions":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header,index){return _c('th',{key:header.value + '-' + index,class:[
                        'column sortable',
                        header.value === _vm.pagination.sortBy ? 'active' : '',
                        index !== Object.keys(props.headers).length - 1 ? 'pr-0' : '' ],style:({ width: header.width ? ((header.width) + "px") : 'auto' }),on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-layout',{attrs:{"justify-start":header.align === 'left',"justify-end":header.align === 'right',"align-center":""}},[_vm._v(" "+_vm._s(header.text)+" "),_c('sort-order',{attrs:{"direction":_vm.getSortOrder(header.value)}})],1)],1)}),0)]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }))+" ")]}},{key:"items",fn:function(props){return [_c('td',{staticClass:"no-wrap-text"},[_c('a',{staticClass:"product-offers-table__link",attrs:{"href":props.item.campaignUrl,"target":"_blank"}},[(props.item.campaignIcon)?_c('img',{staticClass:"product-offers-table__campaign-icon",attrs:{"src":props.item.campaignIcon,"alt":""}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({staticClass:"text--overflow-ellipsis"},on),[_vm._v(" "+_vm._s(props.item.campaign)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(props.item.campaign)+" ")])],1)]),_c('td',[_c('a',{staticClass:"product-offers-table__link",attrs:{"href":props.item.url,"target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({staticClass:"text--overflow-ellipsis"},on),[_vm._v(" "+_vm._s(props.item.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(props.item.name)+" ")]),_c('v-icon',{staticClass:"ml-1 external-link-icon"},[_vm._v("fa-external-link-alt")])],1)]),_c('td',{staticClass:"no-wrap-text text-xs-right"},[_vm._v(" "+_vm._s(props.item.priceFormatted)+" "),_c('div',{staticClass:"grey--text product-offers-table__secondary-info"},[_vm._v(" "+_vm._s(props.item.date && _vm.$d(new Date(props.item.date), 'longNumeric'))+" ")])])]}}],null,false,457968168)}):_vm._e(),(_vm.hasMoreOffers)?_c('div',{staticClass:"text-xs-center"},[_c('v-btn',{staticClass:"btn-wide mt-4",attrs:{"type":"submit","color":"tertiary","dark":"","round":""},on:{"click":_vm.showAll}},[_vm._v(" "+_vm._s(_vm.$t('productOffers.showAll'))+" ")])],1):_vm._e(),(!_vm.loading && (!_vm.items || _vm.items.length === 0))?_c('data-placeholder',{attrs:{"title":_vm.$t('productOffers.noResults')}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }