<template>
    <div class="grey lighten-4 mt-3 pa-4">
        <v-card-text class="grey lighten-4">
            <v-card-title>
                <v-layout
                    row
                    wrap
                    align-center
                    justify-space-between
                >
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('priceDeviations.title') }}
                        <div class="left-filters">
                            <filter-summary :filters="filtersSummary" />
                            <Chip
                                :disabled="loading"
                                :label="$t('filters.reset')"
                                :active="!isLocalFilterDefault"
                                @click="reset"
                            />
                        </div>
                    </h3>
                    <v-layout
                        wrap
                        align-center
                        justify-end
                    >
                        <v-flex shrink>
                            <select-all
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="campaign"
                                :items="campaignOptions"
                                :disabled="loadingData"
                                :label="$t('productPrices.shop')"
                                :all-label="$t('productPrices.allShops')"
                                require-selection
                                @blur="handleCampaignBlur"
                            />
                        </v-flex>
                        <v-flex v-if="!showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="category"
                                :items="categoryOptions"
                                :itemsTree="categoryOptionsTree"
                                :disabled="loadingData"
                                :label="$t('productPrices.category')"
                                :all-label="$t('productPrices.allCategories')"
                                @blur="handleCategoryBlur"
                            />
                        </v-flex>
                        <v-flex v-if="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="loadingData"
                                :label="$t('productPrices.group')"
                                :all-label="$t('productPrices.allGroups')"
                                @blur="handleGroupBlur"
                            />
                        </v-flex>
                        <v-btn
                            v-if="error"
                            class="mb-3"
                            @click="redo()"
                            flat
                            icon
                        >
                            <v-icon small>fa-redo-alt</v-icon>
                        </v-btn>
                    </v-layout>
                    <v-layout
                        class="full-width"
                        wrap
                        align-center
                        justify-end
                    >
                        <v-flex shrink>
                            <product-selector
                                class-name="mr-3 mb-3"
                                v-model="products"
                                :default-items="defaultProducts"
                                component-id="productPrices"
                                multiple
                                :disabled="loadingData"
                                @blur="handleProductsBlur"
                            />
                        </v-flex>
                        <v-flex class="ml-3" shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loadingData"
                                @change="handleShowProductGroupsResultsChange"
                            />
                        </v-flex>
                    </v-layout>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <div v-if="loading" class="mt-2 text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{ error }}
                </v-alert>

                <div v-if="!error">
                    <v-data-table
                        v-if="!loading"
                        class="elevation-0 table--transparent"
                        :headers="headers"
                        :items="items"
                        item-key="itemKey"
                        :no-data-text="noResultsTitle"
                        :no-results-text="noResultsTitle"
                        :rows-per-page-text="$t('table.rowsPerPage')"
                        :pagination.sync="pagination"
                        :rows-per-page-items="rowsPerPageItems"
                    >
                        <template v-slot:headers="props">
                            <tr>
                                <th
                                    v-for="(header) in props.headers"
                                    :key="header.text"
                                    :class="[
                                        'column sortable',
                                        header.value === pagination.sortBy ? 'active' : '',
                                    ]"
                                    @click="changeSort(header.value)"
                                >
                                    <v-layout
                                        :justify-start="header.align === 'left'"
                                        :justify-end="header.align === 'right'"
                                        align-center
                                    >
                                        {{ header.text }}
                                        <sort-order v-if="header.sortable" :direction="getSortOrder(header.value)" />
                                    </v-layout>
                                </th>
                            </tr>
                        </template>

                        <template v-slot:pageText="props">
                            {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                        </template>

                        <template v-slot:items="props">
                            <td>
                                {{ props.item.product.name }}
                                <br/>
                                <span class="price-deviations__ean">{{ props.item.product.ean }}</span>
                            </td>
                            <td class="text-xs-right">
                                <v-btn flat @click="openUpdateModal(props.item.product.id)" :loading="updateProductLoading(props.item.product.id)" :disabled="!!updateProductsLoading.length">
                                    <v-icon class="mr-2" small>fa-pen</v-icon>
                                    {{ props.item.custom.formattedPrice }}
                                </v-btn>
                            </td>
                            <td>
                                <combo-chart-graph
                                    :data="props.item.custom.chart"
                                    :options="props.item.custom.chartOptions"
                                    @ready="onChartReady"
                                />
                            </td>
                        </template>
                    </v-data-table>

                    <price-deviation-in-time
                        class="mt-5"
                        :loading="loading"
                        :date-filter="dateFilter"
                        :campaign="campaign"
                        :data="items"
                        :common-filters-summary="filtersSummary"
                        :is-common-filter-default="isLocalFilterDefault"
                        :no-results-title="noResultsTitle"
                        @resetLocalFilters="reset()"
                    />
                </div>
            </v-card-text>
        </v-card-text>

        <update-product-price-modal
            v-if="currentProduct"
            v-model="updateModalOpen"
            :product="currentProduct"
            :callback="handleSavedProduct"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { min, max, sum } from 'lodash';
import campaignFilterMixin from '@/mixins/campaignFilterMixin';
import categoryFilterMixin from '@/mixins/categoryFilterMixin';
import productsFilterMixin from '@/mixins/productsFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import reportsPriceDistributionService from '@/services/reportsPriceDistributionService';
import numberService from '@/services/numberService';
import Chip from '@/components/common/Chip.vue';
import FilterSummary from '@/components/common/FilterSummary.vue';
import SortOrder from '@/components/common/SortOrder.vue';
import SelectAll from '@/components/common/SelectAll.vue';
import ProductSelector from '@/components/common/ProductSelector.vue';
import ComboChartGraph from '@/components/graphs/core/ComboChartGraph.vue';
import PriceDeviationInTime from '@/components/producer/reports/priceDistribution/PriceDeviationInTime.vue';
import UpdateProductPriceModal from '@/components/producer/products/UpdateProductPriceModal.vue';

export default {
    name: 'price-deviations',
    components: {
        PriceDeviationInTime,
        ComboChartGraph,
        ProductSelector,
        SelectAll,
        SortOrder,
        Chip,
        FilterSummary,
        UpdateProductPriceModal,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
        productsFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isTestEnvironment: process.env.NODE_ENV === 'development',
            isLocalFilterDefault: true,
            hasAllCampaignsSelected: false,
            defaultProducts: [],
            showProductGroupsResults: false,
            error: null,
            loadingData: false,
            items: [],
            headers: [
                {
                    text: this.$t('priceDeviations.product'),
                    align: 'left',
                    sortable: true,
                    value: 'product.name',
                }, {
                    text: this.$t('priceDeviations.recommendedPrice', { currency: process.env.VUE_APP_SYSTEM_CURRENCY }),
                    align: 'right',
                    sortable: true,
                    value: 'product.price',
                }, {
                    text: this.$t('priceDeviations.deviationFromRecommendedPrice'),
                    align: 'left',
                    sortable: false,
                },
            ],
            pagination: {
                descending: true,
                rowsPerPage: 10,
                sortBy: 'product.price',
            },
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
            ],
            updateModalOpen: false,
            productId: null,
        };
    },
    computed: {
        currentProduct() {
            return this.items.find(item => item.product.id === this.productId)?.product || null; // eslint-disable-line no-underscore-dangle
        },
        loading() {
            return this.loadingData || this.isAnyGroupProductsFetching();
        },
        filtersSummary() {
            const summary = [...this.campaignFilterSummary];
            if (this.showProductGroupsResults) {
                summary.push(this.groupFilterSummary);
            } else {
                summary.push(this.categoryFilterSummary);
            }
            summary.push(this.productsFilterSummary);
            return summary;
        },
        isNoProductSelected() {
            return (!this.products || this.products.length === 0) && (!this.group || this.group.length === 0) && (!this.category || this.category.length === 0);
        },
        noResultsTitle() {
            const transTag = this.isNoProductSelected ? 'noProductSelected' : 'noResults';
            return this.$t(transTag);
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            updateProductsLoading: state => state.products.loading.updateProducts,
        }),
        ...mapGetters({
            isAnyGroupProductsFetching: 'producerProductGroup/isAnyGroupProductsFetching',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
            ) {
                this.getReportData();
            }
        },
        updateModalOpen(isOpen) {
            if (!isOpen) this.productId = null;
        },
    },
    methods: {
        openUpdateModal(productId) {
            this.productId = productId;
            this.updateModalOpen = true;
        },
        handleSavedProduct(data) {
            /* Update products */
            const productIndex = this.products.findIndex(item => item.__raw?.id === data.id); // eslint-disable-line no-underscore-dangle
            if (productIndex !== -1) this.$set(this.products[productIndex], '__raw', data); // eslint-disable-line no-underscore-dangle

            /* Update items */
            this.getReportData();
        },
        updateProductLoading(productId) {
            return this.updateProductsLoading.includes(productId);
        },
        handleFilterDefaultChange(type) {
            if (type === 'current') {
                this.isLocalFilterDefault = !this.category.length && this.isDefaultCampaign() && this.isDefaultProducts() && this.isDefaultGroup(true) && !this.showProductGroupsResults;
            }
            if (this.showProductGroupsResults) {
                this.category = [];
            } else {
                this.group = [];
            }
        },
        handleCampaignBlur() {
            this.handleFilterDefaultChange('current');
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.getReportData();
        },
        handleCategoryBlur() {
            this.handleFilterDefaultChange('current');
            this.getReportData();
        },
        handleProductsBlur() {
            this.handleFilterDefaultChange('current');
            this.getReportData();
        },
        handleGroupBlur() {
            this.handleFilterDefaultChange('current');
            this.getReportData();
        },
        handleShowProductGroupsResultsChange() {
            this.handleFilterDefaultChange('current');
            this.getReportData();
        },
        reset() {
            this.category = [];
            this.setDefaultCampaign();
            this.setDefaultProducts();
            this.group = [];
            this.handleFilterDefaultChange('current');
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.showProductGroupsResults = false;
            this.getReportData();
        },
        redo() {
            this.getReportData();
        },
        async getReportData() {
            try {
                this.error = null;
                if (this.isNoProductSelected) {
                    this.items = [];
                    return;
                }
                this.loadingData = true;
                const { startDate, endDate } = this.dateFilter;
                const products = this.products.length ? this.products.map(product => product.entityId).join(',') : undefined;
                const campaigns = this.campaign.join(',');
                const categories = !this.showProductGroupsResults ? this.category.join(',') : undefined;
                const groups = this.showProductGroupsResults ? this.group.join(',') : undefined;
                const data = await reportsPriceDistributionService.fetchProductsPriceChart(
                    this.spaceId,
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                    groups,
                    products,
                );
                this.items = this.calculate(data);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
            this.loadingData = false;
        },
        onChartReady() {
            // table layout is fluid, so on initial render chart column might be smaller than once all rows are fully rendered
            // charts fit size during first render and on page resize, that's why it's required to force chart resize
            this.$nextTick(() => {
                window.dispatchEvent(new Event('resize'));
            });
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        calculate(data) {
            if (!data || !data.products) {
                return [];
            }
            const items = Object.keys(data.products).map(productId => {
                const item = data.products[productId];
                const statsByShop = {};
                if (item.price && item.price.length > 0) {
                    const priceByShop = {};
                    item.price.forEach(priceItem => {
                        Object.keys(priceItem).forEach(campaignId => {
                            if (campaignId === 'date') {
                                return;
                            }
                            if (!priceByShop[campaignId]) {
                                priceByShop[campaignId] = [];
                            }
                            priceByShop[campaignId].push(priceItem[campaignId]);
                        });
                    });
                    Object.keys(priceByShop).forEach(campaignId => {
                        const avg = sum(priceByShop[campaignId]) / (priceByShop[campaignId].filter(price => price > 0).length || 1);
                        const deviation = item.product.price ? (avg - item.product.price) / item.product.price * 100 : null;
                        statsByShop[campaignId] = {
                            min: min(priceByShop[campaignId]),
                            max: max(priceByShop[campaignId]),
                            avg,
                            deviation,
                        };
                    });
                }

                return {
                    ...item,
                    custom: {
                        formattedPrice: item.product.price == null ? this.$t('priceDeviations.editPrice') : numberService.formatCurrency(item.product.price),
                        statsByShop,
                        chart: this.getChartData(statsByShop, item.product.price),
                        chartOptions: this.getChartOptions(statsByShop, item.product.price),
                    },
                };
            });
            return items;
        },
        calculateChartPointSize(value, minValue, maxValue) {
            const pointSizeMin = 5;
            const pointSizeMax = 15;
            if (value === null) {
                return pointSizeMin;
            }
            const percentage = ((Math.abs(value) - minValue) * 100) / (maxValue - minValue);
            return Math.floor(((pointSizeMax - pointSizeMin) * percentage / 100) + pointSizeMin);
        },
        getChartData(statsByShop, recommendedPrice) {
            const absoluteDeviations = Object.keys(statsByShop)
                .map(campaignId => {
                    const { deviation } = statsByShop[campaignId];
                    if (!deviation) {
                        return deviation;
                    }
                    return Math.abs(deviation);
                })
                .filter(deviation => deviation);
            const minDeviation = min(absoluteDeviations) || 0;
            const maxDeviation = max(absoluteDeviations) || 0;
            const header = [
                { type: 'string', label: 'Shop', id: 'Shop' },
                { type: 'number', label: `${this.$t('priceDeviations.deviation', { currency: process.env.VUE_APP_SYSTEM_CURRENCY })} %`, id: 'avgPrice' },
                { type: 'string', role: 'tooltip', p: { html: true } },
                { type: 'string', role: 'style' },
                { type: 'number', label: this.$t('priceDeviations.recommendedPrice', { currency: process.env.VUE_APP_SYSTEM_CURRENCY }), id: 'recommendedPrice' },
            ];
            const items = Object.keys(statsByShop).map(campaignId => {
                const itemData = [campaignId];
                const stats = statsByShop[campaignId];
                const tooltip = this.createTooltip(campaignId, stats, recommendedPrice);
                itemData.push({ v: stats.avg || 0, f: numberService.formatCurrency(stats.avg) });
                itemData.push(tooltip);
                if (stats.avg) {
                    itemData.push(`point { size: ${this.calculateChartPointSize(stats.deviation, minDeviation, maxDeviation)}; }`);
                } else {
                    // hide point if item is unavailable
                    itemData.push('point { visible: false; }');
                }
                itemData.push({ v: recommendedPrice, f: recommendedPrice !== null ? numberService.formatCurrency(recommendedPrice) : this.$t('priceDeviations.noRecommendedPrice') });
                return itemData;
            });
            return [header, ...items];
        },
        getChartOptions(statsByShop, recommendedPrice) {
            const series = [
                {
                    type: 'scatter',
                    pointShape: 'circle',
                    pointSize: 22,
                    color: this.$vuetify.theme.primary,
                },
                {
                    type: 'line',
                    color: this.$vuetify.theme.tertiary,
                    enableInteractivity: false,
                },
            ];
            const options = {
                selectionMode: 'single',
                tooltip: {
                    isHtml: true,
                },
                vAxes: {
                    0: {
                        title: `${this.$t('priceDeviations.avgPrice')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]`,
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
                series,
            };

            let hasValueOverZero = false;
            if (recommendedPrice) {
                hasValueOverZero = true;
            } else if (statsByShop && Object.keys(statsByShop).length > 0) {
                hasValueOverZero = Object.keys(statsByShop).some(campaignId => statsByShop[campaignId].avg > 0);
            }
            options.vAxes[0].viewWindow.max = hasValueOverZero ? null : 1;

            return options;
        },
        createTooltip(campaignId, stats, recommendedPrice) {
            const deviation = recommendedPrice
                ? `${stats.deviation > 0 ? '+' : ''}${numberService.formatPercent(stats.deviation)} (${numberService.formatCurrency(Math.abs(stats.avg - recommendedPrice))})`
                : `<span style="font-style: italic;">${this.$t('priceDeviations.noRecommendedPrice')}</span>`;
            return `<div style="padding: 5px; white-space: nowrap;">
                <strong>${campaignId}</strong>
                <div>${this.$t('priceDeviations.avgPrice')}: <strong>${numberService.formatCurrency(stats.avg)}</strong></div>
                <div>${this.$t('priceDeviations.deviation')}: <strong>${deviation}</strong></div>
                <div>${this.$t('priceDeviations.minPrice')}: <strong>${numberService.formatCurrency(stats.min)}</strong></div>
                <div>${this.$t('priceDeviations.maxPrice')}: <strong>${numberService.formatCurrency(stats.max)}</strong></div>

            </div>`;
        },
    },
    async mounted() {
        this.category = [];
        this.setDefaultCampaign();
        this.setDefaultProducts();
        this.group = [];
        this.hasAllCampaignsSelected = this.hasAllCampaigns();
    },
};
</script>

<style lang="scss">
    .price-deviations__ean {
        font-size: 12px;
    }
</style>
