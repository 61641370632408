<template>
    <v-card class="elevation-0 mb-4">
        <div class="grey lighten-4 pa-4">
            <v-card-title class="price-filters grey lighten-4">
                <v-layout row wrap align-start justify-space-between>
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('currentAvailability.title') }}
                        <div class="left-filters">
                            <v-tooltip v-if="data && data.length" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        type="button"
                                        class="mb-2"
                                        color="tertiary"
                                        @click="exportData"
                                        flat
                                        icon>
                                        <v-icon small>fa-download</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('graph.export') }}</span>
                            </v-tooltip>
                            <filter-summary :filters="filtersSummary" />
                            <Chip :label="$t('filters.reset')" :active="!isFilterDefault" @click="reset" />
                        </div>
                        <InfoTooltip :text="$t('currentAvailability.titleTooltip')" class="ml-4" />
                    </h3>
                    <v-layout column style="flex-grow: 0;">
                        <v-layout wrap align-center justify-end>
                            <v-flex shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="filterAvailabilities"
                                    :items="availabilityOptions"
                                    :label="$t('filters.availability')"
                                    :all-label="$t('filters.allAvailability')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleAvailabilityBlur"
                                />
                            </v-flex>
                            <v-flex shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="campaign"
                                    :items="campaignOptions"
                                    :label="$t('filters.shop')"
                                    :all-label="$t('filters.allShops')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleCampaignBlur"
                                />
                            </v-flex>
                            <v-flex v-if="!showProductGroupsResults" shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="category"
                                    :items="categoryOptions"
                                    :itemsTree="categoryOptionsTree"
                                    :label="$t('filters.category')"
                                    :all-label="$t('filters.allCategories')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleCategoryBlur"
                                />
                            </v-flex>
                            <v-flex v-if="showProductGroupsResults" shrink>
                                <select-all
                                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                                    v-model="group"
                                    :items="groupOptions"
                                    :label="$t('filters.group')"
                                    :all-label="$t('filters.allGroups')"
                                    :disabled="loading"
                                    require-selection
                                    @blur="handleGroupBlur"
                                />
                            </v-flex>
                            <v-flex shrink>
                                <v-btn v-if="error" @click="redo()" flat icon>
                                    <v-icon small>fa-redo-alt</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loading"
                                @change="handleShowProductGroupsResultsChange"
                            />

                            <v-switch :label="$t('filters.showAvailability')" :disabled="loading" v-model="showAvailability" />
                        </v-layout>
                    </v-layout>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert :value="error" dismissible type="error" transition="scale-transition">
                    {{ error }}
                </v-alert>

                <current-products-availability
                    v-if="!error && !loading && availabilities"
                    ref="currentProductsAvailability"
                    :data="data"
                    :showAvailability="showAvailability"
                    :availabilityStatuses="availabilities"
                    :campaign="campaign"
                />
                <current-unavailable-products v-if="!error && !loading" :data="data" />
            </v-card-text>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import campaignFilterMixin from '@/mixins/campaignFilterMixin';
import categoryFilterMixin from '@/mixins/categoryFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import SelectAll from '@/components/common/SelectAll.vue';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import Chip from '@/components/common/Chip.vue';
import FilterSummary from '@/components/common/FilterSummary.vue';
import eventBus from '@/services/eventBus';
import CurrentUnavailableProducts from '@/components/producer/reports/availability/CurrentUnavailableProducts.vue';
import { differenceInCalendarDays } from 'date-fns';
import { flattenDeep } from 'lodash';
import dateService from '@/services/dateService';
import numberService from '@/services/numberService';
import CurrentProductsAvailability from '@/components/producer/reports/availability/CurrentProductsAvailability.vue';
import reportsCategoryService from '@/services/reportsCategoryService';

export default {
    name: 'current-availability',
    components: {
        CurrentProductsAvailability,
        CurrentUnavailableProducts,
        SelectAll,
        Chip,
        FilterSummary,
        InfoTooltip,
    },
    mixins: [campaignFilterMixin, categoryFilterMixin, groupsFilterMixin],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showProductGroupsResults: false,
            showAvailability: false,
            isFilterDefault: true,
            hasAllCampaignsSelected: false,
            error: null,
            data: null,
            filterAvailabilities: [],
        };
    },
    computed: {
        loading() {
            return this.isAnyGroupProductsFetching() || this.currentUnavailableProductsLoading || this.currentProductsAvailabilityLoading;
        },
        filtersSummary() {
            const summary = [...this.campaignFilterSummary];
            if (this.showProductGroupsResults) {
                summary.push(this.groupFilterSummary);
            } else {
                summary.push(this.categoryFilterSummary);
            }
            return summary;
        },
        availabilityOptions() {
            return this.availabilities.map(availability => ({
                value: availability.value,
                text: `${availability.value}: ${availability.label}`,
            }));
        },
        ...mapState({
            currentUnavailableProductsLoading: state => state.reportsAvailability.loading.currentUnavailableProducts,
            currentProductsAvailabilityLoading: state => state.reportsAvailability.loading.currentProductsAvailability,
        }),
        ...mapGetters({
            getterGroupProducts: 'producerProductGroup/getGroupProducts',
            isAnyGroupProductsFetching: 'producerProductGroup/isAnyGroupProductsFetching',
            getterCurrentUnavailableProducts: 'reportsAvailability/getCurrentUnavailableProducts',
            getterCurrentProductsAvailability: 'reportsAvailability/getCurrentProductsAvailability',
            availabilities: 'plugin/availabilities',
        }),
    },
    watch: {
        availabilities: {
            handler() {
                this.filterAvailabilities = this.availabilities.map(availability => availability.value);
            },
            immediate: true,
        },
    },
    methods: {
        emitFilterDefaultChange() {
            this.$emit('filterDefaultChange', this.isFilterDefault);
        },
        handleFilterDefaultChange() {
            this.isFilterDefault = this.isDefaultCategory() && this.isDefaultCampaign() && this.isDefaultGroup() && !this.showProductGroupsResults;
            this.emitFilterDefaultChange();
            this.setData();
        },
        async handleCampaignBlur() {
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.handleFilterDefaultChange();
            await this.getCurrentAvailability();
        },
        async handleAvailabilityBlur() {
            this.handleFilterDefaultChange();
            await this.getCurrentAvailability();
        },
        handleCategoryBlur() {
            this.handleFilterDefaultChange();
        },
        handleGroupBlur() {
            this.handleFilterDefaultChange();
            this.getGroupsData();
        },
        handleShowProductGroupsResultsChange() {
            this.handleFilterDefaultChange();
        },
        async reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign();
            this.setDefaultGroup();
            await this.getGroupsData();
            this.showProductGroupsResults = false;
            this.filterAvailabilities = [];
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.handleFilterDefaultChange();
        },
        async getGroupsData() {
            try {
                await Promise.all(this.group.map(groupId => this.fetchGroupProducts({ groupId })));
                this.setData();
            } catch (e) {
                this.error = e.message;
            }
        },
        redo() {
            this.getCurrentAvailability(true);
        },
        async getCurrentAvailability(force = false) {
            try {
                this.error = null;
                await Promise.all([
                    this.fetchCurrentUnavailableProducts({
                        force,
                        campaign: this.campaign,
                    }),
                    this.fetchCurrentProductsAvailability({
                        force,
                        campaign: this.campaign,
                    }),
                ]);
                this.setData();
            } catch (e) {
                this.data = [];
                this.error = e.message;
            }
        },
        getSelectedGroupsProductsIds() {
            return this.group.map(groupId => Object.keys(this.getterGroupProducts(groupId) ?? {})).flat();
        },
        setData() {
            const unavailableProducts = this.getterCurrentUnavailableProducts(this.campaign) ?? {};
            const productsAvailability = this.getterCurrentProductsAvailability(this.campaign) ?? {};
            const selectedGroupsProductsIds = this.getSelectedGroupsProductsIds();
            const today = new Date();
            this.data = Object.keys(productsAvailability)
                .filter(productId => {
                    // filter by product group
                    if (this.showProductGroupsResults) {
                        return selectedGroupsProductsIds.includes(productId);
                    }
                    // filter by category
                    const productCategoryIds = flattenDeep(productsAvailability[productId].product.categories).map(category => category.id);
                    return productCategoryIds.some(categoryId => this.category.includes(categoryId));
                })
                .map(productId => {
                    const parsedProduct = {
                        ...productsAvailability[productId],
                        custom: {},
                    };
                    parsedProduct.custom.isUnavailable = !Object.values(productsAvailability[productId].availabilities).some(Boolean);
                    if (parsedProduct.custom.isUnavailable) {
                        const lastAvailableAt = unavailableProducts[productId]?.lastAvailableAt;
                        if (lastAvailableAt) {
                            parsedProduct.custom.lastAvailableAt = lastAvailableAt;
                            parsedProduct.custom.lastAvailableAtFormatted = dateService.formatDate(parsedProduct.custom.lastAvailableAt);
                            parsedProduct.custom.unavailableDays = differenceInCalendarDays(today, lastAvailableAt);
                            parsedProduct.custom.unavailableDaysFormatted = numberService.formatNumber(parsedProduct.custom.unavailableDays, 0);
                        }
                    }
                    parsedProduct.custom.categoryTree = reportsCategoryService.productCategoriesToTree(parsedProduct.product.categories);
                    parsedProduct.custom.mainCategory = reportsCategoryService.productMainCategory(parsedProduct.product.categories);
                    return parsedProduct;
                })
                .filter(product => {
                    if (!this.filterAvailabilities?.length) {
                        return true;
                    }
                    const flatStatuses = flattenDeep(Object.values(product.availabilityStatuses));
                    return Object.values(flatStatuses).some(status => this.filterAvailabilities.includes(status));
                });
        },
        exportData() {
            this.$refs.currentProductsAvailability?.exportData();
        },
        ...mapActions({
            fetchGroupProducts: 'producerProductGroup/fetchGroupProducts',
            fetchCurrentUnavailableProducts: 'reportsAvailability/fetchCurrentUnavailableProducts',
            fetchCurrentProductsAvailability: 'reportsAvailability/fetchCurrentProductsAvailability',
            fetchAvailabilities: 'plugin/fetchAvailabilities',
        }),
    },
    async created() {
        this.setDefaultCategory();
        this.setDefaultCampaign();
        this.setDefaultGroup();
        await this.getGroupsData();
        await this.getCurrentAvailability();
        eventBus.$on('resetFilters', this.reset);
        this.fetchAvailabilities();
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
};
</script>

<style lang="scss" scoped></style>
