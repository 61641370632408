import { deburr, sortBy } from 'lodash';
import { getName, registerLocale } from 'i18n-iso-countries';
import { i18n, getLanguageFromLocale } from '../i18n/i18n';

export default {
    supportedCountries: process.env.VUE_APP_SUPPORTED_COUNTRIES.split(','),

    async loadCountriesLanguage(locale) {
        const language = getLanguageFromLocale(locale);

        return import(/* webpackChunkName: "country-lang-[request]" */ `i18n-iso-countries/langs/${language}.json`)
            .then(async countryLangJson => registerLocale(countryLangJson.default))
            .catch(() => {
                console.error(`Country locale file for "${language}" not found.`);
            });
    },

    getCountryOptions() {
        const language = getLanguageFromLocale(i18n.locale);
        const options = this.supportedCountries
            .map(countryCode => ({
                value: countryCode,
                text: getName(countryCode, language) || countryCode,
            }));

        // deburr is used to handle accented characters during sort
        return sortBy(options, option => deburr(option.text));
    },
};
