<template>
    <div class="ranking-item-bar" :style="{ width: `${barWidth}px` }">
        <div class="ranking-item-bar__bar" :style="{ width: `${barValueWidth}px` }">
            <span v-if="!isLabelOutside" class="ranking-item-bar__label ranking-item-bar__label--inside" :style="{'visibility': hideLabel ? 'hidden' : 'inherit'}">{{ formattedValue }}</span>
        </div>
        <span v-if="isLabelOutside" class="ranking-item-bar__label ranking-item-bar__label--outside" >-- {{ formattedValue }}</span>
    </div>
</template>

<script>
import numberService from '../../services/numberService';

export default {
    name: 'ranking-item-bar',
    props: {
        total: {
            type: Number,
            required: true,
        },
        totalType: {
            type: String,
            validator: value => ['decimal', 'percent', 'currency'].includes(value),
            default: 'decimal',
        },
        maxTotal: {
            type: Number,
            required: true,
        },
        barWidth: {
            type: Number,
            default: 250,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        additionalValueLabel: {
            type: String,
        },
    },
    computed: {
        barValueWidth() {
            const valuePercent = this.total === 0 ? 0 : this.total / this.maxTotal * 100;
            let width = this.barWidth * valuePercent / 100;
            if (width === 0) {
                width = 1;
            }
            return Math.min(Math.round(width), this.barWidth);
        },
        formattedValue() {
            const value = numberService.format(this.totalType, this.total);
            return this.additionalValueLabel ? `${value} (${this.additionalValueLabel})` : value;
        },
        isLabelOutside() {
            if (this.hideLabel) {
                return false;
            }

            // character count * single character assumed width + additional spacing > available space
            return (this.formattedValue.length * 7) + 10 > this.barValueWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
    .ranking-item-bar {
        display: inline-flex;
        width: 250px;
        height: 27px;
    }

    .ranking-item-bar__bar {
        height: 100%;
        background: $primary-color;
        text-align: right;
    }

    .ranking-item-bar__label {
        display: inline-block;
        margin: 0 5px;
        line-height: 27px;
        font-size: 13px;
        font-weight: 700;
        color: $text-dark-color;
    }

    .ranking-item-bar__label--inside {
        color: #ffffff;
    }

    .ranking-item-bar__label--outside {
        position: relative;
        margin-left: 5px;
    }
</style>
