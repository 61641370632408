<template>
    <div>
        <div v-if="product">
            <v-layout
                class="mb-4"
                justify-space-between
                row
                wrap
            >
                <v-flex class="mb-3" grow>
                    <v-layout
                        class="mb-3"
                        row
                        align-center
                        justify-space-between
                    >
                        <h3 class="title font-weight-bold product-name">
                            {{ product.text }}
                            <v-tooltip v-if="product.type === 'product'" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        class="ml-3 update-product-btn"
                                        :loading="updateProductLoading"
                                        :disabled="detailsLoading"
                                        @click="updateModalOpen = true"
                                    >
                                        {{ $t('edit') }}
                                    </v-btn>
                                </template>
                                <span>{{ $t('producerProductDetails.editProduct') }}</span>
                            </v-tooltip>
                        </h3>
                        <div class="header-actions-right">
                            <v-btn
                                v-if="detailsError && product.type === 'product'"
                                @click="redoProductDetails"
                                flat
                                icon
                            >
                                <v-icon small>fa-redo-alt</v-icon>
                            </v-btn>
                            <v-menu
                                v-model="groupSelectorOpen"
                                :close-on-content-click="false"
                                min-width="700"
                                max-width="700"
                                left
                                bottom
                                offset-y
                            >
                                <template #activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        class=""
                                        color="tertiary"
                                        dark
                                        round
                                        :loading="false"
                                    >
                                        <v-icon class="mr-2" small>fa-plus</v-icon>
                                        {{ $t('producerProductDetails.addToGroup') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        <Groups :product-id="productId" :selected-product="selectedProduct" />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                    </v-layout>

                    <ul class="list--unstyled">
                        <li v-if="product.type === 'category'">
                            <strong>{{ $t('producerProductDetails.id') }}</strong>
                            {{ product.entityId }}
                        </li>
                        <li v-if="product.type === 'product' && !detailsLoading">
                            <strong>{{ $t('producerProductDetails.gtin') }}</strong>
                            {{ ean }}
                        </li>
                        <li v-if="product.type === 'product' && !detailsLoading">
                            <strong>{{ $t('producerProductDetails.sku') }}</strong>
                            {{ sku }}
                        </li>
                        <li v-if="product.type === 'product' && !detailsLoading">
                            <strong>{{ $t('producerProductDetails.suggestedPrice') }}</strong>
                            {{ suggestedPrice }}
                        </li>
                    </ul>

                    <div v-if="product.type === 'product'">
                        <a href="#product-landing-page" class="product-landing-page-link primary--text d-inline-flex align-center">
                            {{ $t('productLandingPage.title') }} <v-icon class="ml-1 icon-smallest primary--text">fa-chevron-right</v-icon>
                        </a>
                    </div>

                    <div v-if="detailsLoading" class="mt-2 text-xs-center">
                        <v-progress-circular indeterminate color="primary" />
                    </div>

                    <v-alert
                        :value="detailsError"
                        dismissible
                        type="error"
                        transition="scale-transition">
                        {{ detailsError }}
                    </v-alert>
                </v-flex>
                <img
                    v-if="image"
                    class="mb-3 product-image"
                    :src="image"
                    :alt="product.text" />
            </v-layout>

            <v-layout
                class="mb-2 stat-box__container"
                justify-space-between
                row
                wrap
            >
                <stat-box
                    :title="$t('producerProductDetails.impressionsWidget')"
                    :tooltip="$t('producerProductDetails.impressionsWidgetTooltip')"
                    :loading="impressionsStatsLoading"
                    :value="impressionsStats.impressions"
                    :change="impressionsStats.impressionsChange"
                    :allow-reload="!!impressionsStatsError"
                    @reload="redoImpressionsStats"
                />
                <stat-box
                    :title="$t('producerProductDetails.clicks')"
                    :tooltip="$t('producerProductDetails.clicksTooltip')"
                    :loading="clicksStatsLoading"
                    :value="clicksStats.clicks"
                    :change="clicksStats.clicksChange"
                    :allow-reload="!!clicksStatsError"
                    @reload="redoClicksStats"
                />
                <stat-box
                    :title="$t('producerProductDetails.quantity')"
                    :tooltip="$t('producerProductDetails.quantityTooltip')"
                    :loading="salesStatsLoading"
                    :value="salesStats.quantity"
                    :change="salesStats.quantityChange"
                    :allow-reload="!!salesStatsError"
                    @reload="redoSalesStats"
                />
            </v-layout>
            <v-layout
                class="mb-2 stat-box__container"
                justify-space-between
                row
                wrap
            >
                <stat-box
                    :title="$t('producerProductDetails.ctr')"
                    :tooltip="$t('producerProductDetails.ctrTooltip')"
                    :loading="clicksStatsLoading || impressionsStatsLoading"
                    :value="ctrStats.ctr"
                    :change="ctrStats.ctrChange"
                    value-type="percent"
                    size="small"
                />
                <stat-box
                    :title="$t('producerProductDetails.cr')"
                    :tooltip="$t('producerProductDetails.crTooltip')"
                    :loading="clicksStatsLoading || salesStatsLoading"
                    :value="crStats.cr"
                    :change="crStats.crChange"
                    value-type="percent"
                    size="small"
                />
                <stat-box
                    :title="$t('producerProductDetails.salesAmount')"
                    :tooltip="$t('producerProductDetails.salesAmountTooltip')"
                    :loading="salesStatsLoading"
                    :value="salesStats.amount"
                    :change="salesStats.amountChange"
                    value-type="currency"
                    :allow-reload="!!salesStatsError"
                    @reload="redoSalesStats"
                />
            </v-layout>

            <v-alert
                class="mb-3"
                :value="simpleStatsError"
                dismissible
                type="error"
                transition="scale-transition">
                {{ simpleStatsError }}
            </v-alert>

            <no-access-placeholder
                v-if="!hasPermission"
                :permission-name="permissionName"
                :image="require('../../../assets/noAccessPlaceholders/product-traffic-sales.jpg')"
                cta-position="top"
                :cta-url="permissionCtaUrl"
                @activated="checkPermission(permissionName)"
            />
            <product-traffic
                v-if="hasPermission"
                :product-id="productId"
                :date-filter="dateFilter"
                @filterDefaultChange="handleFilterDefaultChange('productTraffic', $event)"
            />
            <product-sales
                v-if="hasPermission"
                :product-id="productId"
                :date-filter="dateFilter"
                @filterDefaultChange="handleFilterDefaultChange('productSales', $event)"
            />
            <!-- <shop-ranking /> -->
            <!-- <product-ranking v-if="product.type === 'category'" /> -->
            <!-- <category-availability v-if="product.type === 'category'" :product-id="productId" :date-filter="dateFilter" /> -->
            <product-availability
                v-if="product.type === 'product'"
                :product-id="productId"
                :date-filter="dateFilter"
                @filterDefaultChange="handleFilterDefaultChange('productAvailability', $event)"
            />
            <price-distribution
                v-if="product.type === 'product'"
                :product-id="productId"
                :date-filter="dateFilter"
                @filterDefaultChange="handleFilterDefaultChange('productPriceDistribution', $event)"
            />
            <product-offers v-if="product.type === 'product'" :product-id="productId" :product-details="productDetails" />
            <product-code v-if="isTestEnvironment && product.type === 'product'" :product="product" :product-details="productDetails" />
            <product-landing-page v-if="product.type === 'product'" :product-details="productDetails" :loading="detailsLoading" />
            <!--<v-btn
                v-if="product.type === 'product'"
                class="mb-0 mt-4"
                color="error"
                round
                :loading="deleteProductLoading"
                @click="confirmDeleteModalOpen = true"
            >
                <v-icon class="mr-2" small>fa-trash</v-icon>
                {{ $t('producerProductDetails.deleteProduct') }}
            </v-btn>-->

            <v-dialog
                v-if="product.type === 'product'"
                v-model="confirmDeleteModalOpen"
                max-width="500"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        {{ $t('producerProductDetails.confirmDelete', { name: product.text }) }}
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat :disabled="deleteProductLoading" @click="confirmDeleteModalOpen = false">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            color="error"
                            round
                            :loading="deleteProductLoading"
                            @click="handleDeleteProduct"
                        >
                            {{ $t('delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <update-product-modal
                v-if="product.type === 'product'"
                v-model="updateModalOpen"
                :productId="productId"
                @savedProduct="handleSavedProduct"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import UpdateProductModal from '@/components/producer/products/UpdateProductModal.vue';
import Groups from '@/components/producer/product-groups/Groups.vue';
import numberService from '../../../services/numberService';
import StatBox from '../../common/StatBox.vue';
import ProductTraffic from './ProductTraffic.vue';
import ProductSales from './ProductSales.vue';
import ProductCode from './ProductCode.vue';
import PriceDistribution from './PriceDistribution.vue';
import ProductAvailability from './ProductAvailability.vue';
import spaceService from '../../../services/spaceService';
import permissionMixin from '../../../mixins/permissionMixin';
import NoAccessPlaceholder from '../../common/NoAccessPlaceholder.vue';
import ProductOffers from './ProductOffers.vue';
import ProductLandingPage from './ProductLandingPage.vue';
// import CategoryAvailability from './CategoryAvailability.vue';
// import ShopRanking from './products/ShopRanking.vue';
// import ProductRanking from './products/ProductRanking.vue';

export default {
    name: 'producer-product-details',
    components: {
        Groups,
        UpdateProductModal,
        ProductOffers,
        NoAccessPlaceholder,
        // CategoryAvailability,
        PriceDistribution,
        ProductAvailability,
        ProductCode,
        ProductLandingPage,
        // ProductRanking,
        ProductSales,
        ProductTraffic,
        // ShopRanking,
        StatBox,
    },
    mixins: [
        permissionMixin,
    ],
    props: {
        productId: {
            type: String,
            required: true,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
        selectedProduct: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            permissionName: '',
            permissionCtaUrl: '',
            product: null,
            productDetails: null,
            clicksStats: {},
            salesStats: {},
            impressionsStats: {},
            ctrStats: {},
            crStats: {},
            detailsError: null,
            clicksStatsError: null,
            salesStatsError: null,
            impressionsStatsError: null,
            confirmDeleteModalOpen: false,
            updateModalOpen: false,
            groupSelectorOpen: false,
        };
    },
    computed: {
        isTestEnvironment() {
            return process.env.NODE_ENV === 'development';
        },
        ean() {
            if (!this.productDetails || !this.productDetails.ean) {
                return '-';
            }
            return this.productDetails.ean;
        },
        sku() {
            if (!this.productDetails || !this.productDetails.sku) {
                return '-';
            }
            return this.productDetails.sku;
        },
        suggestedPrice() {
            if (!this.productDetails || !this.productDetails.price) {
                return this.$t('noDefinedPrice').toUpperCase();
            }
            return numberService.formatCurrency(this.productDetails.price);
        },
        image() {
            if (!this.productDetails || !this.productDetails.images || this.productDetails.images.length === 0) {
                return null;
            }
            return this.productDetails.images[0];
        },
        simpleStatsError() {
            // display error only from a single simple stats endpoint to reduce bloat in UI
            if (this.clicksStatsError) {
                return this.clicksStatsError;
            }
            if (this.salesStatsError) {
                return this.salesStatsError;
            }
            if (this.impressionsStatsError) {
                return this.impressionsStatsError;
            }
            return null;
        },
        deleteProductLoading() {
            return this.deleteProductsLoading.includes(this.product.entityId);
        },
        updateProductLoading() {
            return this.updateProductsLoading.includes(this.product.entityId);
        },
        ...mapState({
            detailsLoading: state => state.products.loading.productDetails,
            clicksStatsLoading: state => state.products.loading.clicksStats,
            salesStatsLoading: state => state.products.loading.salesStats,
            impressionsStatsLoading: state => state.products.loading.impressionsStats,
            deleteProductsLoading: state => state.products.loading.deleteProducts,
            updateProductsLoading: state => state.products.loading.updateProducts,
        }),
        ...mapGetters({
            getterProduct: 'products/getProduct',
            getterProductDetails: 'products/getProductDetails',
            getterProductClicksStats: 'products/getProductClicksStats',
            getterProductSalesStats: 'products/getProductSalesStats',
            getterProductImpressionsStats: 'products/getProductImpressionsStats',
            getterProductCTRStats: 'products/getProductCTRStats',
            getterProductCRStats: 'products/getProductCRStats',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.product = this.getterProduct(this.productId);
        if (this.product) {
            this.permissionName = this.product.type === 'product' ? spaceService.permissions.STATS_PRODUCTS : spaceService.permissions.STATS_CATEGORIES;
            this.permissionCtaUrl = this.product.type === 'product' ? 'producerReportsProducts' : 'producerReportsCategory';
        }
        this.getData();
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            this.$emit('filterDefaultChange', type, isDefault);
        },
        getData() {
            this.checkPermission(this.permissionName);
            if (this.product && this.product.type === 'product') {
                this.getProductDetails();
            }
            if (this.product) {
                this.getClicksStats();
                this.getSalesStats();
                this.getImpressionsStats();
            }
        },
        redoProductDetails() {
            this.getProductDetails(true);
        },
        redoImpressionsStats() {
            this.getImpressionsStats(true);
        },
        redoClicksStats() {
            this.getClicksStats(true);
        },
        redoSalesStats() {
            this.getSalesStats(true);
        },
        async getProductDetails(force = false) {
            try {
                this.detailsError = null;
                await this.fetchProductDetails({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                });
                this.productDetails = this.getterProductDetails(this.productId);
            } catch (e) {
                this.detailsError = e.message;
            }
        },
        async getClicksStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.clicksStatsError = null;
                await this.fetchClicksStats({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    type: this.product.type,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.clicksStats = this.getterProductClicksStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
                this.ctrStats = this.getterProductCTRStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
                this.crStats = this.getterProductCRStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.clicksStatsError = e.message;
            }
        },
        async getSalesStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.salesStatsError = null;
                await this.fetchSalesStats({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    type: this.product.type,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.salesStats = this.getterProductSalesStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
                this.crStats = this.getterProductCRStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.salesStatsError = e.message;
            }
        },
        async getImpressionsStats(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.impressionsStatsError = null;
                await this.fetchImpressionsStats({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    type: this.product.type,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                });
                this.impressionsStats = this.getterProductImpressionsStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
                this.ctrStats = this.getterProductCTRStats(this.productId, startDate, endDate, compareStartDate, compareEndDate) || {};
            } catch (e) {
                this.impressionsStatsError = e.message;
            }
        },
        async handleDeleteProduct() {
            try {
                const { entityId, text } = this.product;
                await this.deleteProduct({ entityId });
                this.$emit('deletedProduct');
                this.confirmDeleteModalOpen = false;
                this.$toast.success(this.$t('producerProductDetails.deleteSuccess', { name: text }));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },
        handleSavedProduct() {
            this.product = this.getterProduct(this.productId);
            this.productDetails = this.getterProductDetails(this.productId);
        },
        ...mapActions({
            fetchProductDetails: 'products/fetchProductDetails',
            fetchClicksStats: 'products/fetchClicksStats',
            fetchSalesStats: 'products/fetchSalesStats',
            fetchImpressionsStats: 'products/fetchImpressionsStats',
            deleteProduct: 'products/deleteProduct',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .header-actions-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .product-name {
        display: flex;
        align-items: center;
    }

    .update-product-btn {
        position: relative;
        top: -2px;
    }

    .product-image {
        max-width: 128px;
        max-height: 128px;
    }

    .product-landing-page-link {
        text-decoration: none;
    }
</style>
