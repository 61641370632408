import { i18n } from '@/i18n/i18n';
import apiService from './apiService';
import errorService from './errorService';
import demoService from './demoService';
import pluginServiceDemo from './demo-services/pluginServiceDemo';

export const widgetVersions = ['latest', '5.1', '5.0'];

export const translateWidgetVersion = version => {
    switch (version) {
        case 'latest':
            return i18n.t('pluginAppearance.latestVersion');
        default:
            return version;
    }
};

const pluginService = {
    async fetchProducerPlugin(spaceId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-plugin`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAvailabilities() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/offer-availabilities`,
            );
            return response.data;
        } catch (e) {
            throw new Error(e);
        }
    },

    async fetchActiveCampaigns(buyboxId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAvailableCampaigns(buyboxId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/available-campaigns`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAvailableExtensions() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/extensions`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSortTypes() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/buybox-sort-types`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updatePlugin(buyboxId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createCampaign(buyboxId, campaignId) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns/${campaignId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateCampaign(buyboxId, campaignId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns/${campaignId}`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteCampaign(buyboxId, campaignId) {
        try {
            const response = await apiService.delete(
                `${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns/${campaignId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(pluginService, pluginServiceDemo, 'pluginService');
export default pluginService;
