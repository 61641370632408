<template>
    <div class="grey lighten-4 mb-3 mx-3 pa-3 stat-box" :class="[`stat-box--${size}`]">
        <v-layout
            row
            align-start
            justify-space-between
        >
            <h4 class="name">{{ title }} <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" /></h4>
            <v-btn
                v-if="allowReload && !loading"
                class="reload-button"
                @click="onReload"
                flat
                icon
            >
                <v-icon small>fa-redo-alt</v-icon>
            </v-btn>
        </v-layout>
        <div v-if="!loading" class="value">{{ formattedValue }}</div>
        <change-label v-if="!loading" :change="change" :change-type="changeType" />
        <div v-if="loading" class="mt-3 text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
    </div>
</template>

<script>
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import numberService from '../../services/numberService';
import ChangeLabel from './ChangeLabel.vue';

export default {
    name: 'stat-box',
    components: { ChangeLabel, InfoTooltip },
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
        },
        valueType: {
            validator: value => ['decimal', 'percent', 'currency'].includes(value),
            default: 'decimal',
        },
        change: {
            type: Number,
        },
        changeType: {
            validator: value => ['decimal', 'percent'].includes(value),
            default: 'percent',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        size: {
            validator: value => ['medium', 'small'].includes(value),
            default: 'medium',
        },
        allowReload: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    computed: {
        formattedValue() {
            if (Number.isNaN(Number.parseInt(this.value, 10))) {
                return '-';
            }

            if (this.valueType === 'currency') {
                return numberService.formatCurrency(this.value);
            }

            if (this.valueType === 'percent') {
                return numberService.formatPercent(this.value);
            }

            return numberService.formatNumber(this.value);
        },
    },
    methods: {
        onReload() {
            this.$emit('reload');
        },
    },
};
</script>

<style lang="scss" scoped>
    .stat-box {
        flex-grow: 1;
        flex-basis: 0;
    }

    .name {
        font-size: 14px;
        font-weight: 300;
    }

    .value {
        font-weight: 300;
        white-space: nowrap;
    }

    .stat-box--medium {
        .value {
            font-size: 32px;
        }
    }

    .stat-box--small {
        .value {
            font-size: 20px;
        }
    }

    .reload-button {
        position: relative;
        top: -14px;
    }
</style>

<style lang="scss">
    .stat-box__container {
        margin-left: -16px;
        margin-right: -16px;
    }
</style>
