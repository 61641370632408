<template>
    <base-layout>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md5>
                    <v-card class="elevation-0 pa-4">
                        <v-card-text class="text-xs-left">
                            <h3 class="mb-4 title font-weight-bold">{{ $t('login.title') }}</h3>

                            <v-form @submit.prevent="handleSubmit" novalidate>
                                <v-text-field
                                    v-model.trim="model.username"
                                    :error-messages="usernameErrors"
                                    @blur="$v.model.username.$touch()"
                                    name="username"
                                    :label="$t('login.username')"
                                    type="text"
                                    required
                                    placeholder=" "
                                    outline
                                />
                                <v-text-field
                                    v-model.trim="model.password"
                                    :error-messages="passwordErrors"
                                    @blur="$v.model.password.$touch()"
                                    name="password"
                                    :label="$t('login.password')"
                                    type="password"
                                    required
                                    placeholder=" "
                                    outline
                                />

                                <v-alert
                                    :value="loginError"
                                    dismissible
                                    type="error"
                                    transition="scale-transition">
                                    {{loginError}}
                                </v-alert>

                                <div class="mb-3 text-xs-right">
                                    <v-btn
                                        class="btn-wide"
                                        type="submit"
                                        color="tertiary"
                                        :loading="loading"
                                        :disabled="loading"
                                        dark
                                        round
                                    >
                                        {{ $t('login.submit') }}
                                    </v-btn>
                                </div>
                            </v-form>

                            <div class="pt-3">
                                <p>
                                    {{ $t('login.registerBefore') }}
                                    <router-link :to="{ name: 'register' }">{{ $t('login.registerLink') }}</router-link>
                                </p>
                            </div>

                            <div class="reset-password mb-3 pt-3">
                                {{ $t('login.resetPassword') }} <router-link :to="{ name: 'resetPassword' }">{{ $t('login.resetPasswordLink') }}</router-link>
                            </div>
                        </v-card-text>
                    </v-card>
                    <p class="font-weight-bold grey--text text--darken-1 mt-2 pa-2 text-xs-center">
                        <language-switch />
                    </p>
                </v-flex>
            </v-layout>
        </v-container>
    </base-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import BaseLayout from '../components/layout/BaseLayout.vue';
import LanguageSwitch from '../components/common/LanguageSwitch.vue';

export default {
    name: 'login',
    components: {
        LanguageSwitch,
        BaseLayout,
    },
    validations: {
        model: {
            username: { required },
            password: { required },
        },
    },
    data() {
        return {
            model: {
                username: '',
                password: '',
            },
            loginError: null,
        };
    },
    computed: {
        usernameErrors() {
            const errors = [];
            if (!this.$v.model.username.$dirty) {
                return errors;
            }
            if (!this.$v.model.username.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.model.password.$dirty) {
                return errors;
            }
            if (!this.$v.model.password.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        ...mapState({
            loading: state => state.auth.loading.authenticate,
        }),
    },
    methods: {
        async handleSubmit() {
            this.loginError = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.authenticate({
                    username: this.model.username,
                    password: this.model.password,
                    redirect: this.$route.query.redirect,
                });
            } catch (e) {
                this.loginError = e.message;
            }
        },
        ...mapActions({
            authenticate: 'auth/authenticate',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .reset-password {
        border-top: 1px solid #eee;
    }
</style>
