<template>
    <div>
        <v-card-title>
            <v-layout
                row
                wrap
                align-center
                justify-space-between
            >
                <v-tooltip v-if="!isCommonLoading && data" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            type="button"
                            class="mb-2"
                            color="tertiary"
                            @click="exportData"
                            flat
                            icon
                        >
                            <v-icon small>fa-download</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('export') }}</span>
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </v-tooltip>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="isCommonLoading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <div v-if="!isCommonLoading">
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="headers"
                    :items="pricesItems"
                    item-key="itemKey"
                    :no-data-text="noResultsTitle"
                    :no-results-text="noResultsTitle"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :pagination.sync="pagination"
                    :rows-per-page-items="rowsPerPageItems"
                    disable-initial-sort
                >
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="(header) in props.headers"
                                :key="header.text"
                                :class="[
                                    'column sortable',
                                    header.value === pagination.sortBy ? 'active' : '',
                                ]"
                                @click="changeSort(header.value)"
                            >
                                <v-layout
                                    :justify-start="header.align === 'left'"
                                    :justify-end="header.align === 'right'"
                                    align-center
                                >
                                    {{ header.text }}
                                    <sort-order :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <td>
                            {{ props.item.product.name }}
                        </td>
                        <td>
                            {{ props.item.product.ean }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.custom.minPriceFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.custom.avgPriceFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.custom.maxPriceFormatted }}
                        </td>
                        <!--<td class="text-xs-right">
                            {{ props.item.custom.quantityFormatted }}
                        </td>-->
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import exportService from '../../../../services/exportService';
import numberService from '../../../../services/numberService';
import SortOrder from '../../../common/SortOrder.vue';

export default {
    name: 'product-prices',
    components: {
        SortOrder,
        InfoTooltip,
    },
    props: {
        data: {
            type: Object,
        },
        isCommonLoading: {
            type: Boolean,
            default: false,
        },
        isCommonFilterDefault: {
            type: Boolean,
            default: true,
        },
        commonFiltersSummary: {
            type: Array,
            default: () => [],
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('productPrices.name'),
                    align: 'left',
                    sortable: true,
                    value: 'product.name',
                },
                {
                    text: this.$t('productPrices.ean'),
                    align: 'left',
                    sortable: true,
                    value: 'product.ean',
                },
                {
                    text: this.$t('productPrices.minPrice'),
                    align: 'right',
                    sortable: true,
                    value: 'custom.minPrice',
                },
                {
                    text: this.$t('productPrices.avgPrice'),
                    align: 'right',
                    sortable: true,
                    value: 'custom.avgPrice',
                },
                {
                    text: this.$t('productPrices.maxPrice'),
                    align: 'right',
                    sortable: true,
                    value: 'custom.maxPrice',
                },
                // {
                //     text: this.$t('productPrices.quantity'),
                //     align: 'right',
                //     sortable: true,
                //     value: 'custom.quantity',
                // },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                descending: true,
                rowsPerPage: 10,
                sortBy: 'date',
            },
        };
    },
    computed: {
        filtersSummary() {
            return [
                ...this.commonFiltersSummary,
            ];
        },
        pricesItems() {
            if (!this.data) {
                return [];
            }
            return Object.keys(this.data).map(productId => this.data[productId]);
        },
        noResultsTitle() {
            const transTag = !this.products || this.products.length === 0 ? 'productPrices.noProductSelected' : 'productPrices.noResults';
            return this.$t(transTag);
        },
    },
    methods: {
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('productPrices.product.name'))}"`);
            header.push(`"${exportService.parseText(this.$t('productPrices.product.ean'))}"`);
            header.push(`"${exportService.parseText(this.$t('productPrices.custom.minPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('productPrices.custom.avgPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('productPrices.custom.maxPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('productPrices.custom.quantity'))}"`);

            const rows = [];
            this.pricesItems.forEach(item => {
                const row = [];
                row.push(`"${exportService.parseText(item.name)}"`);
                row.push(`"${exportService.parseText(item.ean)}"`);
                row.push(`"${numberService.formatNumber(item.minPrice, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.avgPrice, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.maxPrice, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.quantity, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('productPrices.title'));
        },
    },
};
</script>

<style lang="scss" scoped>
    .left-filters {
        display: inline-flex;
        align-items: center;
    }
</style>
