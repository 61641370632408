<template>
    <div>
        <v-treeview
            v-if="!itemsLoading"
            class="products-tree"
            ref="tree"
            :items="treeCategories"
            :open.sync="open"
            :load-children="fetchItems"
            :multiple-active="false"
            :active.sync="active"
            active-class="tree-node--active"
            item-key="id"
            item-text="text"
            item-children="items"
            loading-icon="fa-circle-notch"
            transition
            activatable
        >
            <template v-slot:prepend="{ item, open, active }">
                <v-icon
                    v-if="item.type === 'category'"
                    class="ml-2 mr-1"
                    :color="active ? 'secondary' : ''"
                    small
                >{{ open ? 'fa-folder-open' : 'fa-folder' }}</v-icon>
                <v-icon
                    v-else
                    class="ml-2 mr-1"
                    :color="active ? 'secondary' : ''"
                    small>fa-file</v-icon>
            </template>
        </v-treeview>
        <v-messages color="error" :value="errors" />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
    name: 'category-select-tree',
    props: {
        productId: {
            type: String,
        },
        errors: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            error: null,
            active: [],
            open: ['all'],
        };
    },
    computed: {
        itemsLoading() {
            return this.rootProductsLoading;
        },
        selected() {
            if (!this.active.length || this.active[0].id === 'all') {
                return undefined;
            }
            return this.active[0];
        },
        treeCategories() {
            const tree = [
                {
                    id: 'all',
                    text: 'All',
                    type: 'category',
                    items: this.getterCategoryTree,
                },
            ];
            return cloneDeep(tree);
        },
        ...mapState({
            rootProductsLoading: state => state.products.loading.rootProducts,
            productsIdsByParentId: state => state.products.productsIdsByParentId,
            products: state => state.products.products,
        }),
        ...mapGetters({
            getterCategoryTree: 'products/getCategoryTree',
        }),
    },
    watch: {
        active(to) {
            if (to && to.length > 0 && to[0].type === 'category') {
                const isOpen = this.open.find(item => item.id === to[0].id);

                if (!isOpen) {
                    this.open.push(to[0]);
                }
            }
            this.$emit('change', to[0] !== 'all' ? this.products[to[0]].entityId : undefined);
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            await this.fetchItems();
            if (this.productId) {
                this.selectProductCategoryNode();
            }
        },
        async fetchItems(item, force = false) {
            try {
                if (!item) {
                    await this.fetchProducts({ force });
                    if (this.$refs.tree) {
                        this.$refs.tree.updateSelected('all');
                    }
                } else {
                    if (item.id === 'all') {
                        return;
                    }
                    await this.fetchProducts({ categoryId: item.id, entityId: item.entityId });
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        selectProductCategoryNode() {
            const productCategoryTree = this.getProductCategoryTree(this.productId);
            if (productCategoryTree.length > 0) {
                this.open = ['all', ...productCategoryTree];
                this.active = [productCategoryTree[0]];
            }
        },
        getProductCategoryTree(nodeId, tree = []) {
            Object.keys(this.productsIdsByParentId).forEach(categoryId => {
                if (this.productsIdsByParentId[categoryId].includes(nodeId)) {
                    if (categoryId !== 'root') {
                        tree.push(categoryId);
                        this.getProductCategoryTree(categoryId, tree);
                    }
                }
            });
            return tree;
        },
        ...mapActions({
            fetchProducts: 'products/fetchProducts',
        }),
    },
};
</script>

<style lang="scss">

</style>
