<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
        </page-header-filters>
        <v-card class="elevation-0 pa-3">
            <div class="grey lighten-4 pa-4 mb-4">
                <v-card-title>
                    <h3 class="title font-weight-bold">{{ $t('dataExport.title') }}</h3>
                </v-card-title>
                <v-card-text>
                    <p class="data-export-description">{{ $t('dataExport.description') }}</p>
                    <v-flex class="mt-4 mb-3">
                        <month-picker v-model="selectedMonth" :disabled="generateRequestLoading" :min="currentSpaceCreatedAtMonth" :max="maxDate" />
                        <v-btn @click="startGeneratingReport" dark round :loading="generateRequestLoading">
                            {{ $t('dataExport.generate') }}
                        </v-btn>
                    </v-flex>
                    <v-alert :value="error" dismissible type="error" transition="scale-transition">
                        {{ error }}
                    </v-alert>
                </v-card-text>
                <v-card-text>
                    <div v-if="loading" class="text-xs-center">
                        <v-progress-circular indeterminate color="primary" />
                    </div>
                    <div v-else>
                        <h3>{{ $t('dataExport.availableReports') }}</h3>
                        <v-data-table
                            class="reports-table mt-3"
                            :items="getterReports"
                            item-key="id"
                            hide-actions
                            :no-data-text="$t('dataExport.noAvailableReports')"
                            :no-results-text="$t('dataExport.noAvailableReports')"
                        >
                            <template slot="items" slot-scope="props">
                                <tr :id="`data-export-report-${props.item.id}`" :class="{ 'is-report-highlighted': props.item.id === highlightedReportId }">
                                    <td>
                                        {{ props.item.spaceName }}
                                    </td>
                                    <td>
                                        {{ getMonthName(props.item.month) }}
                                    </td>
                                    <td>
                                        {{ props.item.year }}
                                    </td>
                                    <td class="text-xs-right progress-td">
                                        <v-progress-linear v-if="isReportPending(props.item)" :value="props.item.progress" />
                                    </td>
                                    <td class="text-xs-right">
                                        <v-tooltip bottom v-if="isReportCorrupted(props.item)">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" color="tertiary lighten-5" small class="mr-2">fas fa-exclamation-triangle</v-icon>
                                            </template>
                                            <span>{{ $t('dataExport.errorTooltip') }}</span>
                                        </v-tooltip>

                                        <v-btn
                                            :disabled="!isReportGenerated(props.item)"
                                            @click="downloadReport(props.item)"
                                            color="primary"
                                            round
                                            :loading="isDownloadInProgress(props.item)"
                                        >
                                            {{ $t('dataExport.download') }}
                                        </v-btn>
                                    </td>
                                    <td v-if="showDelete">
                                        <v-btn dark icon flat color="tertiary" @click.native="deleteReport(props.item.id)">
                                            <v-icon small>fa-times</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>

                <!-- Producer Price Report Exports -->
                <v-card-text>
                    <div v-if="loading" class="text-xs-center">
                        <v-progress-circular indeterminate color="primary" />
                    </div>
                    <div v-else>
                        <h3>{{ $t('dataExport.availablePriceReports') }}</h3>
                        <v-data-table
                            class="reports-table mt-3"
                            :items="getterPriceReports"
                            item-key="id"
                            :no-data-text="$t('dataExport.noAvailableReports')"
                            :no-results-text="$t('dataExport.noAvailableReports')"
                            :rows-per-page-text="$t('table.rowsPerPage')"
                            :total-items="getTotalItems(getterPriceReports)"
                            :rows-per-page-items="rowsPerPageItems"
                            :pagination.sync="paginationPriceReports"
                        >
                            <template slot="items" slot-scope="props">
                                <tr>
                                    <td>
                                        {{ props.item.id }}
                                    </td>
                                    <td>
                                        {{ props.item.space.name }}
                                    </td>
                                    <td>
                                        {{ props.item.startDate }}
                                    </td>
                                    <td>
                                        {{ props.item.endDate }}
                                    </td>
                                    <td class="text-xs-right">
                                        <v-btn color="primary" @click="downloadPriceReport(props.item)" :disabled="!isReportDownloadable(props.item)" round>
                                            {{ $t('dataExport.download') }}
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>

                <!-- Producer Competition Report Exports -->
                <v-card-text>
                    <div v-if="loading" class="text-xs-center">
                        <v-progress-circular indeterminate color="primary" />
                    </div>
                    <div v-else>
                        <h3>{{ $t('dataExport.availableCompetitionReports') }}</h3>
                        <v-data-table
                            class="reports-table mt-3"
                            :items="getterCompetitionReports"
                            item-key="id"
                            :no-data-text="$t('dataExport.noAvailableReports')"
                            :no-results-text="$t('dataExport.noAvailableReports')"
                            :rows-per-page-text="$t('table.rowsPerPage')"
                            :total-items="getTotalItems(getterCompetitionReports)"
                            :rows-per-page-items="rowsPerPageItems"
                            :pagination.sync="paginationCompetitionReports"
                        >
                            <template slot="items" slot-scope="props">
                                <tr>
                                    <td>
                                        {{ props.item.id }}
                                    </td>
                                    <td>
                                        {{ props.item.space.name }}
                                    </td>
                                    <td>
                                        {{ props.item.startDate }}
                                    </td>
                                    <td>
                                        {{ props.item.endDate }}
                                    </td>
                                    <td class="text-xs-right">
                                        <v-btn color="primary" @click="downloadCompetitionReport(props.item)" :disabled="!isReportDownloadable(props.item)" round>
                                            {{ $t('dataExport.download') }}
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>

                <!-- Producer Availability Report Exports -->
                <v-card-text>
                    <div v-if="loading" class="text-xs-center">
                        <v-progress-circular indeterminate color="primary" />
                    </div>
                    <div v-else>
                        <h3>{{ $t('dataExport.availableAvailabilityReports') }}</h3>
                        <v-data-table
                            class="reports-table mt-3"
                            :items="getterAvailabilityReports"
                            item-key="id"
                            :no-data-text="$t('dataExport.noAvailableReports')"
                            :no-results-text="$t('dataExport.noAvailableReports')"
                            :rows-per-page-text="$t('table.rowsPerPage')"
                            :total-items="getTotalItems(getterAvailabilityReports)"
                            :rows-per-page-items="rowsPerPageItems"
                            :pagination.sync="paginationAvailabilityReports"
                        >
                            <template slot="items" slot-scope="props">
                                <tr>
                                    <td>
                                        {{ props.item.id }}
                                    </td>
                                    <td>
                                        {{ props.item.space.name }}
                                    </td>
                                    <td>
                                        {{ props.item.startDate }}
                                    </td>
                                    <td>
                                        {{ props.item.endDate }}
                                    </td>
                                    <td class="text-xs-right">
                                        <v-btn color="primary" @click="downloadAvailabilityReport(props.item)" :disabled="!isReportDownloadable(props.item)" round>
                                            {{ $t('dataExport.download') }}
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </div>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import MonthPicker from '../../components/common/MonthPicker.vue';
import dateService from '../../services/dateService';
import dataExportService, { ExportReportStatus } from '../../services/dataExportService';
import reportsPriceDistributionService from '../../services/reportsPriceDistributionService';
import reportsCompetitionService from '../../services/reportsCompetitionService';
import reportsAvailabilityService from '../../services/reportsAvailabilityService';

const DELETE_ENABLED = false;

export default {
    name: 'producer-data-export',
    components: {
        MonthPicker,
        PageHeaderFilters,
        SpaceSwitch,
    },
    data() {
        return {
            showDelete: DELETE_ENABLED && process.env.NODE_ENV !== 'production',
            spaceInitialized: false,
            selectedMonth: dateService.previoustMonth(),
            maxDate: dateService.currentMonth(),
            highlightedReportId: null,
            downloadingReportsIds: [],
            queryPendingReportsTimer: null,
            error: null,

            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: null },
            ],
            paginationPriceReports: {
                page: 1,
            },
            paginationCompetitionReports: {
                page: 1,
            },
            paginationAvailabilityReports: {
                page: 1,
            },
        };
    },
    mounted() {
        this.queryPendingReportsTimer = setInterval(() => this.queryPendingReports(), 10 * 1000);
    },
    beforeDestroy() {
        clearInterval(this.queryPendingReportsTimer);
    },
    methods: {
        buildParams(pagination) {
            const params = {};
            if (pagination.page && pagination.rowsPerPage !== null) {
                params.page = pagination.page.value || pagination.page;
            }
            if (pagination.rowsPerPage !== null) {
                params.perPage = pagination.rowsPerPage.value || pagination.rowsPerPage;
            }
            return params;
        },

        async getData() {
            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }

            try {
                this.error = null;
                await this.fetchReports();
                await this.fetchProductsPriceExport(this.buildParams(this.paginationPriceReports));
                await this.fetchCompetitionExport(this.buildParams(this.paginationCompetitionReports));
                await this.fetchAvailabilityExport(this.buildParams(this.paginationAvailabilityReports));
            } catch (e) {
                this.error = e.message;
            }
        },
        getTotalItems(items) {
            return items.length === 0 ? 0 : items[0].max;
        },
        getMonthName(monthNumber) {
            const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
            return dateService.formatI18nDate(`2020-${month}`, 'monthName');
        },
        formatReportName(report) {
            return dataExportService.formatReportName(report);
        },
        isDownloadInProgress(report) {
            return this.downloadingReportsIds.includes(report.id);
        },
        isReportPending(report) {
            return report.status === ExportReportStatus.PENDING || report.status === ExportReportStatus.GENERATING;
        },
        isReportGenerated(report) {
            return report.status === ExportReportStatus.GENERATED || report.status === ExportReportStatus.PARTIAL;
        },
        isReportDownloadable(report) {
            return report.downloadable;
        },
        isReportCorrupted(report) {
            return report.status === ExportReportStatus.CORRUPTED;
        },
        async startGeneratingReport() {
            try {
                this.error = null;

                await this.generateReport(this.generationTime);
                const requestedReport = this.reportForSelectedMonth;

                if (requestedReport) {
                    this.highlightedReportId = requestedReport.id;
                    this.$toast.info(this.$t('dataExport.generationFeedback'), { timeout: 7500, color: 'tertiary' });
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async queryPendingReports() {
            this.getterPendingReports.forEach(report => {
                this.fetchReport(report.id);
            });

            if (this.getterPendingAvailabilityReports.length > 0) {
                await this.fetchAvailabilityExport(this.buildParams(this.paginationAvailabilityReports));
            }

            if (this.getterPendingPriceReports.length > 0) {
                await this.fetchProductsPriceExport(this.buildParams(this.paginationPriceReports));
            }

            if (this.getterPendingCompetitionReports.length > 0) {
                await this.fetchCompetitionExport(this.buildParams(this.paginationCompetitionReports));
            }
        },
        async downloadReport(report) {
            this.downloadingReportsIds.push(report.id);
            try {
                await dataExportService.downloadReport(report);
            } catch (e) {
                this.$toast.info(e.message, { color: 'error' });
            }
            this.downloadingReportsIds = this.downloadingReportsIds.filter(id => id !== report.id);
        },
        async downloadPriceReport(report) {
            this.downloadingReportsIds.push(report.id);
            try {
                await reportsPriceDistributionService.downloadPriceReport(report);
            } catch (e) {
                this.$toast.info(e.message, { color: 'error' });
            }
            this.downloadingReportsIds = this.downloadingReportsIds.filter(id => id !== report.id);
        },
        async downloadCompetitionReport(report) {
            this.downloadingReportsIds.push(report.id);
            try {
                await reportsCompetitionService.downloadCompetitionReport(report);
            } catch (e) {
                this.$toast.info(e.message, { color: 'error' });
            }
            this.downloadingReportsIds = this.downloadingReportsIds.filter(id => id !== report.id);
        },
        async downloadAvailabilityReport(report) {
            this.downloadingReportsIds.push(report.id);
            try {
                await reportsAvailabilityService.downloadAvailabilityReport(report);
            } catch (e) {
                this.$toast.info(e.message, { color: 'error' });
            }
            this.downloadingReportsIds = this.downloadingReportsIds.filter(id => id !== report.id);
        },
        handleSpaceChange() {
            this.getData();
        },
        ...mapActions({
            generateReport: 'dataExport/generateReport',
            fetchReport: 'dataExport/fetchReport',
            fetchReports: 'dataExport/fetchReports',
            deleteReport: 'dataExport/deleteReport',

            fetchProductsPriceExport: 'reportsPriceDistribution/fetchProductsPriceExport',
            fetchCompetitionExport: 'reportsCompetition/fetchCompetitionExport',
            fetchAvailabilityExport: 'reportsAvailability/fetchAvailabilityExport',
        }),
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.fetchReportsLoading;
        },
        generationTime() {
            if (!this.selectedMonth) {
                return {};
            }
            const [year, month] = this.selectedMonth.split('-').map(part => parseInt(part, 10));
            return { year, month };
        },
        reportForSelectedMonth() {
            const { month, year } = this.generationTime;
            return this.getterReports.find(report => report.year === year && report.month === month);
        },
        currentSpaceCreatedAtMonth() {
            const currentSpaceCreatedAt = this.currentSpace && this.currentSpace.createdAt;
            const userCreatedAt = this.user && this.user.createdAt;
            const createdAt = currentSpaceCreatedAt || userCreatedAt || undefined;

            return dateService.formatDate(createdAt, 'YYYY-MM');
        },
        ...mapGetters({
            getterReports: 'dataExport/getReports',
            getterPendingReports: 'dataExport/getPendingReports',
            currentSpace: 'space/currentSpace',

            getterPriceReports: 'reportsPriceDistribution/getProductsPriceExport',
            getterPendingPriceReports: 'reportsPriceDistribution/getPendingProductsPriceExport',

            getterCompetitionReports: 'reportsCompetition/getCompetitionExport',
            getterPendingCompetitionReports: 'reportsCompetition/getPendingCompetitionExport',

            getterAvailabilityReports: 'reportsAvailability/getAvailabilityExport',
            getterPendingAvailabilityReports: 'reportsAvailability/getPendingAvailabilityExport',
        }),
        ...mapState({
            fetchReportsLoading: state => state.dataExport.loading.reports,
            generateRequestLoading: state => state.dataExport.loading.generateRequest,
            user: state => state.auth.user,
        }),
    },
    watch: {
        highlightedReportId(id) {
            const highlightedEl = this.$el.querySelector(`#data-export-report-${id}`);
            if (highlightedEl) {
                this.$vuetify.goTo(highlightedEl, { offset: 200 });
            }
        },
        paginationPriceReports: {
            handler(value) {
                this.fetchProductsPriceExport(this.buildParams(value));
            },
            deep: true,
        },
        paginationCompetitionReports: {
            handler(value) {
                this.fetchCompetitionExport(this.buildParams(value));
            },
            deep: true,
        },
        paginationAvailabilityReports: {
            handler(value) {
                this.fetchAvailabilityExport(this.buildParams(value));
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.reports-table {
    thead {
        display: none;
    }

    table,
    .v-datatable,
    .v-datatable__actions {
        background-color: transparent !important;
    }

    .progress-td {
        min-width: 20vw;
    }
}

.data-export-description {
    font-size: 16px;
}

.is-report-highlighted {
    background-color: rgba($primary-color, 0.15);
}
</style>
