<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <div class="full-width mb-4">
                <v-layout
                    row
                    wrap
                    align-center
                    justify-space-between
                >
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('salesTransactions.title') }}
                        <v-tooltip v-if="!loading" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    type="button"
                                    class="mb-2"
                                    color="tertiary"
                                    :disabled="!!error || !items || items.length === 0"
                                    @click="exportData"
                                    flat
                                    icon
                                >
                                    <v-icon small>fa-download</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('export') }}</span>
                        </v-tooltip>
                        <div class="left-filters">
                            <filter-summary :filters="filtersSummary" />
                            <Chip
                                :disabled="loading"
                                :label="$t('filters.reset')"
                                :active="!isFilterDefault"
                                @click="reset"
                            />
                        </div>
                    </h3>
                    <v-layout
                        wrap
                        align-center
                        justify-end
                    >
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="conversion"
                                @change="handleConversionChange"
                                :items="conversionOptions"
                                :disabled="loading"
                                placeholder=" "
                                :label="$t('salesTransactions.conversion')"
                                outline
                                hide-details
                                dense
                            />
                        </v-flex>
                        <v-flex shrink>
                            <select-all
                                v-if="region !== 'PL'"
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="campaign"
                                :items="campaignOptions"
                                :disabled="loading"
                                :label="$t('salesTransactions.shop')"
                                :all-label="$t('salesTransactions.allShops')"
                                require-selection
                                @blur="handleCampaignBlur"
                            />
                        </v-flex>
                        <v-flex v-show="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="loading"
                                :label="$t('productsRanking.group')"
                                :all-label="$t('productsRanking.allGroups')"
                                require-selection
                                @blur="getData()"
                            />
                        </v-flex>
                        <v-btn
                            v-if="error"
                            class="mb-3"
                            @click="redo"
                            flat
                            icon
                        >
                            <v-icon small>fa-redo-alt</v-icon>
                        </v-btn>
                    </v-layout>
                </v-layout>
                <div>
                    <v-layout wrap align-center justify-end>
                        <v-flex class="mb-3 ml-3" shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loading"
                                @change="getData()"
                            />
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="transactionsLoading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>
            <div v-if="!transactionsLoading && !error">
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="filteredHeaders"
                    :items="items"
                    :total-items="totalItems"
                    :no-data-text="$t('salesTransactions.noResults')"
                    :no-results-text="$t('salesTransactions.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :rows-per-page-items="rowsPerPageItems"
                    :pagination.sync="pagination"
                    disable-initial-sort
                    sort-icon="fa-angle-up"
                >
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="(header) in props.headers"
                                :key="header.text"
                                :class="[
                                    'column sortable',
                                    header.value === pagination.sortBy ? 'active' : '',
                                ]"
                                @click="changeSort(header)"
                            >
                                <v-layout
                                    :justify-start="header.align === 'left'"
                                    :justify-end="header.align === 'right'"
                                    align-center
                                >
                                    <span v-if="header.html" v-html="header.html"></span>
                                    <template v-else>{{ header.text }}</template>
                                    <sort-order v-if="header.sortable" :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <tr>
                            <td class="no-wrap-text">
                                {{ props.item.createdAt && $d(new Date(props.item.createdAt), 'short') }}
                            </td>
                            <td>
                                {{ props.item.id }}
                            </td>
                            <td class="text-xs-right">
                                <v-btn
                                    @click="handleConversionPathClick(props.item)"
                                    flat
                                    icon
                                >
                                    <v-icon small>fa-shopping-cart</v-icon>
                                </v-btn>
                            </td>
                            <td v-if="region !== 'PL'">
                                {{ props.item.campaign && props.item.campaign.name }}
                            </td>
                            <td>
                                {{ props.item.conversionTimeFormatted }}
                            </td>
                            <td>
                                {{ props.item.offerPosition && props.item.offersCount ? `${props.item.offerPosition} / ${props.item.offersCount}` : '-' }}
                            </td>
                            <td v-if="spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)">
                                {{ props.item.source }}
                            </td>
                            <td class="text-xs-right">
                                {{ props.item.valueFormatted }}
                            </td>
                            <td class="text-xs-right">
                                {{ props.item.quantityFormatted }}
                            </td>
                            <td class="text-xs-right">
                                <share-multiple
                                    :values="[props.item.ownQuantity, props.item.otherQuantity, props.item.unknownQuantity]"
                                    :labels="[
                                        `${$t('quantity2')}: {{valueFormatted}} ${$t('salesSummaryQuantityItem')} ({{valuePercentFormatted}}) ${$t('ownProducts').toLowerCase()}`,
                                        `${$t('quantity2')}: {{valueFormatted}} ${$t('salesSummaryQuantityItem')} ({{valuePercentFormatted}}) ${$t('otherProducts').toLowerCase()}`,
                                        `${$t('quantity2')}: {{valueFormatted}} ${$t('salesSummaryQuantityItem')} ({{valuePercentFormatted}}) ${$t('unknownProducts').toLowerCase()}`,
                                    ]"
                                    :colors="colors"
                                    :valueFormatter="numberService.formatNumber"
                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>

            <v-dialog v-model="isTransactionModalOpen">
                <v-card>
                    <v-card-text>
                        <sales-product-details
                            :date-filter="dateFilter"
                            :conversion="conversion"
                            :campaign="campaign"
                            single-transaction-view
                        >
                            <template slot="topRight">
                                <v-btn
                                    color="darken-1"
                                    flat
                                    @click.native="isTransactionModalOpen = false"
                                >
                                    <v-icon small>fa-times</v-icon>
                                </v-btn>
                            </template>
                        </sales-product-details>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="isConversionPathModalOpen">
                <v-card>
                    <v-card-text>
                        <conversion-path
                            v-if="conversionPathTransaction"
                            :date-filter="dateFilter"
                            :conversion="conversion"
                            :campaign="campaign"
                            :transaction="conversionPathTransaction"
                            @close="isConversionPathModalOpen = false"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>

            <sales-product-details
                :date-filter="dateFilter"
                :conversion="conversion"
                :campaign="campaign"
                :is-transactions-filter-default="isFilterDefault"
                :transactions-filters-summary="filtersSummary"
                @filterDefaultChange="handleFilterDefaultChange('productDetails', $event)"
                @loadingChange="handleLoadingChange('productDetails', $event)"
            />
        </v-card-text>
    </div>
</template>

<script>
import { sortBy } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import spaceService from '@/services/spaceService';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import ShareMultiple from '@/components/common/ShareMultiple.vue';
import numberService from '../../../../services/numberService';
import exportService from '../../../../services/exportService';
import dateService from '../../../../services/dateService';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import SortOrder from '../../../common/SortOrder.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';
import SalesProductDetails from './SalesProductDetails.vue';
import Chip from '../../../common/Chip.vue';
import FilterSummary from '../../../common/FilterSummary.vue';
import ConversionPath from './ConversionPath.vue';

export default {
    name: 'sales-transactions',
    components: {
        ConversionPath,
        FilterSummary,
        Chip,
        SalesProductDetails,
        SelectAll,
        SortOrder,
        ShareMultiple,
    },
    mixins: [campaignFilterMixin, groupsFilterMixin],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            numberService,
            spaceService,
            region: process.env.VUE_APP_REGION_MODE,
            headers: [
                {
                    text: this.$t('salesTransactions.date'),
                    align: 'left',
                    sortable: true,
                    value: 'createdAt',
                },
                {
                    text: this.$t('salesTransactions.transactionId'),
                    align: 'left',
                    sortable: true,
                    value: 'id',
                },
                {
                    text: this.$t('salesTransactions.details3'),
                    align: 'right',
                    sortable: false,
                },
                {
                    text: this.$t('salesTransactions.campaign'),
                    align: 'left',
                    sortable: true,
                    value: 'campaign',
                    disabledRegions: ['PL'],
                },
                {
                    text: this.$t('salesTransactions.conversionTime'),
                    align: 'left',
                    sortable: true,
                    value: 'conversionTime',
                },
                {
                    text: this.$t('salesTransactions.offerPosition'),
                    align: 'left',
                    sortable: false,
                    value: 'offerPosition',
                },
                ...(spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS) ? [{
                    text: this.$t('salesTransactions.source'),
                    align: 'left',
                    sortable: false,
                    value: 'source',
                }] : []),
                {
                    text: this.$t('salesTransactions.amount'),
                    align: 'right',
                    sortable: true,
                    value: 'value',
                },
                {
                    text: this.$t('salesTransactions.quantity'),
                    align: 'right',
                    sortable: true,
                    value: 'quantity',
                },
                {
                    text: this.$t('salesTransactions.share'),
                    align: 'right',
                    sortable: false,
                    value: 'share',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
            ],
            pagination: {
                descending: true,
                page: 1,
                rowsPerPage: 10,
                sortBy: 'createdAt',
                totalItems: 0,
            },
            data: null,
            totalItems: 0,
            conversion: 24,
            conversionOptions: [
                {
                    value: 'all',
                    text: this.$t('salesTransactions.allConversion'),
                },
                {
                    value: 24,
                    text: this.$t('salesTransactions.nConversion', { hours: 24 }),
                },
            ],
            error: null,
            isFilterDefault: true,
            isProductDetailsFilterDefault: true,
            isProductDetailsLoading: false,
            isTransactionModalOpen: false,
            isConversionPathModalOpen: false,
            conversionPathTransaction: null,
            showProductGroupsResults: false,
            colors: ['#00e08f', '#FF0059', '#2d3136'],
        };
    },
    computed: {
        loading() {
            return this.transactionsLoading || this.isProductDetailsLoading;
        },
        conversionFilterSummary() {
            const option = this.conversionOptions.find(conversionOption => conversionOption.value === this.conversion);
            const items = option ? [option.text] : [];
            return {
                name: this.$t('filterSummary.types.conversion'),
                items,
            };
        },
        filtersSummary() {
            return [
                ...this.campaignFilterSummary,
                this.conversionFilterSummary,
            ];
        },
        filteredHeaders() {
            return this.headers.filter(header => !(header.disabledRegions && header.disabledRegions.includes(this.region)));
        },
        items() {
            if (!this.data) {
                return [];
            }

            return this.data;
        },
        ...mapState({
            transactionsLoading: state => state.reportsSales.loading.transactions,
        }),
        ...mapGetters({
            getterTransactions: 'reportsSales/getTransactions',
            getterProductDetails: 'reportsSales/getProductDetails',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        pagination(newValue, oldValue) {
            if (
                !this.transactionsLoading
                && (
                    newValue.descending !== oldValue.descending
                    || newValue.page !== oldValue.page
                    || newValue.rowsPerPage !== oldValue.rowsPerPage
                    || newValue.sortBy !== oldValue.sortBy
                    || newValue.totalItems !== oldValue.totalItems
                )
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.setDefaultCampaign(['PL']);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
        eventBus.$on('change:conversion', this.setConversionFilter);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
        eventBus.$off('change:conversion', this.setConversionFilter);
    },
    methods: {
        reset() {
            this.conversion = 24;
            this.setDefaultCampaign(['PL']);
            this.setDefaultGroup();
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.conversion === 24 && this.isDefaultCampaign(['PL']);
            this.isFilterDefault = isFilterDefault;
            this.$emit('filterDefaultChange', isFilterDefault && !this.isProductDetailsFilterDefault);
        },
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'productDetails') {
                this.isProductDetailsFilterDefault = isDefault;
            }
        },
        handleLoadingChange(type, isLoading) {
            if (type === 'productDetails') {
                this.isProductDetailsLoading = isLoading;
            }
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getTransactions(force);
        },
        handleCampaignBlur() {
            this.pagination.page = 1;
            this.getData();
        },
        handleConversionChange() {
            this.pagination.page = 1;
            this.getData();
            eventBus.$emit('change:conversion', {
                conversion: this.conversion,
                // eslint-disable-next-line no-underscore-dangle
                uid: this._uid,
            });
        },
        redo() {
            this.getData(true);
        },
        setConversionFilter(e) {
            // eslint-disable-next-line no-underscore-dangle
            if (e.uid === this._uid) {
                return;
            }
            this.conversion = e.conversion;
            this.pagination.page = 1;
            this.getData();
        },
        changeSort(header) {
            if (!header.sortable) {
                return;
            }

            let sort = null;
            let descending = true;

            if (this.pagination.sortBy !== header.value) {
                sort = header.value;
                descending = true;
            } else if (this.pagination.sortBy === header.value && this.pagination.descending === true) {
                sort = header.value;
                descending = false;
            }

            this.pagination = {
                ...this.pagination,
                page: 1,
                sortBy: sort,
                descending,
            };
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        async getTransactions(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                const {
                    page, rowsPerPage, sortBy: sort, descending,
                } = this.pagination;
                this.error = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    conversion: this.conversion,
                    campaign: this.campaign,
                    page,
                    perPage: rowsPerPage,
                    sort: sort === 'value' ? 'transAmount' : sort,
                    order: descending ? 'DESC' : 'ASC',
                };
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchTransactions(params);
                const data = this.getterTransactions(startDate, endDate, this.conversion, this.campaign, params.group);
                this.totalItems = data && data.total ? data.total : 0;

                if (data && data.pages && data.pages[page]) {
                    this.data = data.pages[page].map(item => ({
                        ...item,
                        conversionTimeFormatted: dateService.secondsToString(item.conversionTime / 1000), // conversionTime is in ms
                        valueFormatted: numberService.formatCurrency(item.value),
                        quantityFormatted: numberService.formatNumber(item.quantity),
                    }));
                } else {
                    this.data = null;
                }
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('dataFor'))}"`);
            header.push(`"${exportService.parseText(this.$t('exportingOn'))}"`);
            header.push(`"${exportService.parseText(this.$t('selectedStores'))}"`);

            header.push(`"${exportService.parseText(this.$t('salesTransactions.date'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesTransactions.transactionId'))}"`);
            if (this.region !== 'PL') {
                header.push(`"${exportService.parseText(this.$t('salesTransactions.campaign'))}"`);
            }
            header.push(`"${exportService.parseText(this.$t('salesTransactions.conversionTime'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesTransactions.value'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('salesTransactions.quantity'))}"`);
            header.push(`"${exportService.parseText(this.$t('salesTransactions.shareExport'))}"`);

            const rows = [];
            this.items.forEach((item, index) => {
                const row = [];
                if (index === 0) {
                    row.push(`"${exportService.parseText(this.dateFilter.startDate)} - ${exportService.parseText(this.dateFilter.endDate)}"`);
                    row.push(`"${exportService.parseText(new Date().toLocaleDateString())}"`);
                    row.push(`"${exportService.parseText(this.campaign.map(id => this.getCampaignNameFromId(id)).join(', '))}"`);
                } else {
                    row.push('""');
                    row.push('""');
                    row.push('""');
                }

                row.push(`"${exportService.parseText(item.createdAt && this.$d(new Date(item.createdAt), 'short'))}"`);
                row.push(`"${exportService.parseText(item.id)}"`);
                if (this.region !== 'PL') {
                    row.push(`"${exportService.parseText(item.campaign && item.campaign.name)}"`);
                }
                row.push(`"${exportService.parseText(item.conversionTimeFormatted)}"`);
                row.push(`"${numberService.formatNumber(item.value, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.quantity, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.share, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('salesTransactions.title'));
        },
        handleTransactionClick(item) {
            eventBus.$emit('setProductDetailsTransaction', item);
            this.isTransactionModalOpen = true;
        },
        handleConversionPathClick(item) {
            this.isConversionPathModalOpen = true;
            this.conversionPathTransaction = item;
        },
        ...mapActions({
            fetchTransactions: 'reportsSales/fetchTransactions',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
