<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Analiza Konkurencji" />
            </template>
        </page-header-filters>
        <no-access-placeholder
            v-if="!hasPermission && !spacesLoading"
            :permission-name="permissionName"
            :title="$t('subscriptionNotIncludeCompetitiveAnalytics')"
            :description="$t('leaveUsYourDetailsAndWeWillContactYou')"
            :image="require('../../assets/noAccessPlaceholders/competitive-analysis.png')"
            cta-position="top"
            contact-form
            usercom-report-type="Konkurencja"
            @activate="onActivate"
        />
        <v-card v-if="hasPermission" class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{ error }}
                </v-alert>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('spaceSwitch.noResults')" />

                <competition-products-selector
                    v-if="!loading && currentView === 'wizard'"
                    :sets="sets"
                    :set-id="editSetId"
                    @change="handleSetChange"
                    @close="handleSetsClose"
                    @resetLocalFilters="reset"
                />

                <div v-if="!loading && currentView === 'main'">
                    <div class="grey lighten-4 mb-5 pa-4">
                        <v-card-title class="price-filters grey lighten-4">
                            <v-layout
                                row
                                wrap
                                align-center
                                justify-space-between
                            >
                                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                                    {{ $t('competitiveAnalysis.selectedProducts') }}
                                    <div class="left-filters">
                                        <v-btn
                                            @click="generateCompetitionReport"
                                            flat
                                            icon
                                        ><v-icon small>fa-download</v-icon>
                                        </v-btn>

                                        <filter-summary :filters="filtersSummary" />
                                        <Chip
                                            :label="$t('filters.reset')"
                                            :active="isResetActive"
                                            @click="reset"
                                        />
                                    </div>
                                </h3>
                                <v-layout
                                    wrap
                                    align-center
                                    justify-end
                                >
                                    <v-flex shrink>
                                        <select-all
                                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                                            v-model="campaign"
                                            :items="campaignOptions"
                                            :label="$t('filters.shop')"
                                            :all-label="$t('filters.allShops')"
                                            require-selection
                                            @blur="handleCampaignBlur"
                                        />
                                    </v-flex>
                                    <v-flex shrink>
                                        <v-btn
                                            class="btn-wide mb-3"
                                            color="tertiary"
                                            dark
                                            round
                                            @click="createSet"
                                        >
                                            {{ $t('competitiveAnalysis.createSet') }}
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-layout>
                        </v-card-title>
                        <v-card-text>
                            <competition-sets
                                ref="sets"
                                :sets="sets"
                                :data="data"
                                :compareData="compareData"
                                :date-filter="dateFilter"
                                :dates-in-range="datesInRange"
                                :is-common-filter-default="isLocalFilterDefault"
                                :common-filters-summary="filtersSummary"
                                :campaign-names="campaignFilterNames"
                                :campaign="campaign"
                                @editSet="editSet"
                                @removeSet="removeSet"
                                @resetLocalFilters="reset()"
                            />
                        </v-card-text>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="confirmDeleteModalOpen"
            max-width="500"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('competitiveAnalysis.confirmDelete') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="cancelDelete" :disabled="deleteSetLoading">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        color="error"
                        round
                        :loading="deleteSetLoading"
                        @click="handleDelete()"
                    >
                        {{ $t('delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
    cloneDeep,
    difference,
    groupBy,
    max,
    min,
    sortBy,
    sum,
    uniq,
} from 'lodash';
import permissionMixin from '@/mixins/permissionMixin';
import campaignFilterMixin from '@/mixins/campaignFilterMixin';
import usercomService from '@/services/usercomService';
import spaceService from '@/services/spaceService';
import dateService from '@/services/dateService';
import utilsService from '@/services/utilsService';
import numberService from '@/services/numberService';
import PageHeaderFilters from '@/components/common/PageHeaderFilters.vue';
import SpaceSwitch from '@/components/common/SpaceSwitch.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import DataPlaceholder from '@/components/common/DataPlaceholder.vue';
import ResetFiltersButton from '@/components/common/ResetFiltersButton.vue';
import NoAccessPlaceholder from '@/components/common/NoAccessPlaceholder.vue';
import SelectAll from '@/components/common/SelectAll.vue';
import CompetitionProductsSelector from '@/components/producer/reports/competitiveAnalysis/CompetitionProductsSelector.vue';
import CompetitionSets from '@/components/producer/reports/competitiveAnalysis/CompetitionSets.vue';
import reportsCompetitionService from '@/services/reportsCompetitionService';
import eventBus from '@/services/eventBus';
import FilterSummary from '@/components/common/FilterSummary.vue';
import Chip from '@/components/common/Chip.vue';
import demoService from '@/services/demoService';

export default {
    name: 'CompetitiveAnalysis',
    components: {
        Chip,
        FilterSummary,
        SelectAll,
        CompetitionSets,
        CompetitionProductsSelector,
        ResetFiltersButton,
        NoAccessPlaceholder,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
    },
    mixins: [
        permissionMixin,
        campaignFilterMixin,
    ],
    data() {
        return {
            permissionName: spaceService.permissions.COMPETITIVE_ANALYSIS,
            spaceInitialized: false,
            loadingConfig: false,
            loadingReport: false,
            isDatepickerFilterDefault: true,
            isLocalFilterDefault: true,
            error: null,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            currentView: 'wizard',
            sets: [],
            editSetId: null,
            deleteSetId: null,
            deleteSetLoading: false,
            confirmDeleteModalOpen: false,
            data: null,
            compareData: null,
            prevCampaign: null,
            setChanged: false,
        };
    },
    computed: {
        isDemo() {
            return this.$store.getters['space/isDemo'];
        },
        loading() {
            return !this.spaceInitialized || this.loadingConfig || this.loadingReport;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isLocalFilterDefault;
        },
        datesInRange() {
            return dateService.getDaysInRange(this.dateFilter.startDate, this.dateFilter.endDate);
        },
        datesInRangeCompare() {
            return dateService.getDaysInRange(this.dateFilter.compareStartDate, this.dateFilter.compareEndDate);
        },
        filtersSummary() {
            return [...this.campaignFilterSummary];
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaceId: state => state.space.currentSpaceId,
            spaces: state => state.space.spaces,
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getReport();
            }
        },
    },
    methods: {
        onActivate() {
            usercomService.userengage(`event.menu-${this.$t('producerSidebar.competitiveAnalysis')}`, { 'space-type': this.spaces?.[this.spaceId]?.name });
        },
        async reset() {
            this.setDefaultCampaign(['PL']);
            this.handleFilterDefaultChange('current');
        },
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'current') {
                this.isLocalFilterDefault = this.isDefaultCampaign();
            }
        },
        createSet() {
            if (this.isDemo) {
                this.$toast.error(demoService.unavailableInDemoMsg);
                return;
            }
            this.currentView = 'wizard';
            this.editSetId = null;
        },
        editSet(id) {
            if (this.isDemo) {
                this.$toast.error(demoService.unavailableInDemoMsg);
                return;
            }
            this.currentView = 'wizard';
            this.editSetId = id;
        },
        removeSet(id) {
            if (this.isDemo) {
                this.$toast.error(demoService.unavailableInDemoMsg);
                return;
            }
            this.deleteSetId = id;
            this.confirmDeleteModalOpen = true;
        },
        cancelDelete() {
            this.deleteSetId = null;
            this.confirmDeleteModalOpen = false;
        },
        async handleDelete() {
            try {
                this.deleteSetLoading = true;
                await reportsCompetitionService.removeSet(this.deleteSetId);
                this.sets = this.sets.filter(set => set.id !== this.deleteSetId);
                this.deleteSetId = null;
                this.confirmDeleteModalOpen = false;
            } catch (e) {
                this.error = e.message;
            }
            this.deleteSetLoading = false;
        },
        async handleSetChange(newSet) {
            try {
                const setIndex = this.sets.findIndex(setItem => setItem.id === newSet.id);
                if (setIndex !== -1) {
                    this.setChanged = reportsCompetitionService.hasSetChanged(this.sets[setIndex], newSet);
                    this.sets[setIndex] = newSet;
                } else {
                    this.setChanged = true;
                    this.sets.push(newSet);
                }
                this.$toast.success(this.$t('competitiveAnalysis.saveSetSuccess'));
            } catch (e) {
                this.error = e.message;
            }
        },
        handleSetsClose() {
            this.currentView = 'main';
            this.editSetId = null;
            if (this.setChanged) {
                this.getReport();
            }
            this.setChanged = false;
        },
        hasCampaignChanged() {
            // first campaign change
            if (!this.prevCampaign) {
                return true;
            }
            // check if campaign changed
            const diff1 = difference(this.prevCampaign, this.campaign).length;
            const diff2 = difference(this.campaign, this.prevCampaign).length;
            return diff1 !== 0 || diff2 !== 0;
        },
        handleCampaignBlur() {
            this.handleFilterDefaultChange('current');
            if (this.hasCampaignChanged()) {
                this.getReport();
            }
            this.prevCampaign = cloneDeep(this.campaign);
        },
        async handleSpaceChange() {
            await this.getData();
            this.setDefaultCampaign(['PL']);

            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
            await this.getReport();
        },
        async getData() {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) {
                return;
            }
            this.loadingConfig = true;
            await this.getCampaigns();
            await this.getSets();
            this.loadingConfig = false;
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getSets() {
            try {
                const sets = await reportsCompetitionService.fetchSets(this.spaceId);
                this.sets = sets.items;
                this.currentView = sets.items.length > 0 ? 'main' : 'wizard';
            } catch (e) {
                this.error = e.message;
                this.sets = [];
            }
        },
        async generateCompetitionReport() {
            try {
                this.error = null;
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                const campaigns = [...this.campaign];

                const sets = this.sets.map(set => set.id);
                await this.generateCompetitionExport({
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    sets,
                });
                this.$toast.info(this.$t('dataExport.generationFeedback'), { timeout: 7500, color: 'tertiary' });
                this.$router.push({ name: 'producerDataExport' });
            } catch (e) {
                this.error = e.message;
            }
        },
        async getReport() {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) {
                return;
            }
            try {
                if (!this.sets.length) {
                    return;
                }
                this.loadingReport = true;
                const {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                } = this.dateFilter;
                const { products, aggregations } = reportsCompetitionService.getSetsProductsAggregationsIds(this.sets);
                const campaigns = this.campaign.join(',');
                const productsReq = products.join(',');
                const aggregationsReq = aggregations.join(',');
                let data = await reportsCompetitionService.fetchCompetitionReport(this.spaceId, startDate, endDate, productsReq, aggregationsReq, campaigns);
                data = this.parseData(data, this.datesInRange);
                let compareData = await reportsCompetitionService.fetchCompetitionReport(this.spaceId, compareStartDate, compareEndDate, productsReq, aggregationsReq, campaigns);
                compareData = this.parseData(compareData, this.datesInRangeCompare);
                this.data = data;
                this.compareData = compareData;
            } catch (e) {
                this.error = e.message;
            }
            this.loadingReport = false;
        },
        parseData(data, datesInRange) {
            // fill missing prices and calculate price summary
            const completeData = {
                products: {},
                aggregations: {},
            };
            if (data.products) {
                Object.keys(data.products).forEach(productId => {
                    const product = {
                        ...data.products[productId],
                        price: this.fillProductMissingPrices(data.products[productId].price, datesInRange),
                    };
                    product.custom = this.calculateProductPriceSummary(product, datesInRange, true);
                    completeData.products[productId] = product;
                });
            }
            if (data.aggregations) {
                Object.keys(data.aggregations).forEach(aggregationId => {
                    const aggregation = {
                        ...data.aggregations[aggregationId],
                        price: this.fillProductMissingPrices(data.aggregations[aggregationId].price, datesInRange),
                    };
                    aggregation.custom = this.calculateProductPriceSummary(aggregation, datesInRange, false);
                    completeData.aggregations[aggregationId] = aggregation;
                });
            }
            return completeData;
        },
        fillProductMissingPrices(priceItemsByDay, datesInRange) {
            const availableDays = priceItemsByDay.map(priceItem => ({ date: new Date(priceItem.date).valueOf() }));
            const missingDays = dateService.findMissingDates(availableDays, undefined, undefined, datesInRange, undefined, undefined, true);
            const additionalProperties = this.campaignFilterNames.map(campaign => ({ key: campaign, value: null }));
            let populatedPricesByDay = utilsService.fillArray(priceItemsByDay, missingDays, 'date', additionalProperties);
            populatedPricesByDay = sortBy(populatedPricesByDay, ['date']);

            const lastCertainValues = {};
            return populatedPricesByDay.reduce((completeItemsByDay, priceItem) => {
                const completeItem = Object.keys(priceItem).reduce((item, key) => {
                    let price = priceItem[key];
                    if (key === 'date') {
                        // do nothing, leave value as it is
                    } else if (price != null) {
                        lastCertainValues[key] = price;
                    } else {
                        // use assumed price if available
                        price = lastCertainValues[key] ?? null;
                    }
                    return {
                        ...item,
                        [key]: price,
                    };
                }, {});
                return [
                    ...completeItemsByDay,
                    completeItem,
                ];
            }, []);
        },
        calculateProductPriceSummary(product, datesInRange, isProducerProduct = false) {
            const lastRangeDay = datesInRange[this.datesInRange.length - 1];
            const prices = [];
            const groupedByPrice = {};
            let priceOpen = 0;
            let priceClose = 0;
            product.price.forEach(dayData => {
                const dayPrices = [];
                Object.keys(dayData).forEach(key => {
                    // ignore shops with no price or price 0
                    if (key !== 'date' && dayData[key]) {
                        dayPrices.push(dayData[key]);

                        if (!groupedByPrice[dayData[key]]) {
                            groupedByPrice[dayData[key]] = [];
                        }
                        groupedByPrice[dayData[key]].push({
                            date: dayData.date,
                            campaign: key,
                            price: dayData[key],
                        });
                    }
                });
                prices.push(...dayPrices);
                if (dayPrices.length) {
                    if (dayData.date === this.datesInRange[0]) {
                        priceOpen = sum(dayPrices) / dayPrices.length;
                    } else if (dayData.date === this.datesInRange[this.datesInRange.length - 1]) {
                        priceClose = sum(dayPrices) / dayPrices.length;
                    }
                }
            });
            const priceMin = min(prices) ?? 0;
            const priceMax = max(prices) ?? 0;
            const priceAvg = prices.length ? sum(prices) / prices.length : 0;

            // price range consists of 2nd lowest and 2nd highest price
            let priceRangeMin = 0;
            let priceRangeMax = 0;

            const uniquePricesCount = Object.keys(groupedByPrice).length;
            if (uniquePricesCount > 0 && uniquePricesCount <= 2) {
                // there is only min and max price available, so they are price range edges
                priceRangeMin = priceMin;
                priceRangeMax = priceMax;
            } else if (uniquePricesCount > 2) {
                // get 2nd lowest and 2nd highest price as edge values of price range
                const sortedUniquePrices = sortBy(uniq(prices));
                // eslint-disable-next-line prefer-destructuring
                priceRangeMin = sortedUniquePrices[1];
                priceRangeMax = sortedUniquePrices[sortedUniquePrices.length - 2];
            }
            const priceMinDetails = groupedByPrice[priceMin] ? this.formatPriceDetails(groupedByPrice[priceMin]) : [];
            const priceMaxDetails = groupedByPrice[priceMax] ? this.formatPriceDetails(groupedByPrice[priceMax]) : [];

            // count only offers available on last day of range
            let offersCount = 0;
            Object.keys(product.availability).forEach(campaignId => {
                product.availability[campaignId].forEach(offer => {
                    offer.chart.segments.forEach(segment => {
                        if (dateService.formatDate(segment.start) <= lastRangeDay && dateService.formatDate(segment.end) >= lastRangeDay) {
                            offersCount += 1;
                        }
                    });
                });
            });

            return {
                id: product.product?.id ?? product.aggregation?.id,
                isProducerProduct,
                priceMin,
                priceMinDetails,
                priceMax,
                priceMaxDetails,
                priceAvg,
                priceOpen,
                priceClose,
                priceRangeMin,
                priceRangeMax,
                offersCount,
                priceAvgFormatted: numberService.formatCurrency(priceAvg),
                priceMinFormatted: numberService.formatCurrency(priceMin),
                priceMaxFormatted: numberService.formatCurrency(priceMax),
                priceOpenFormatted: numberService.formatCurrency(priceOpen),
                priceCloseFormatted: numberService.formatCurrency(priceClose),
                priceRangeMinFormatted: numberService.formatCurrency(priceRangeMin),
                priceRangeMaxFormatted: numberService.formatCurrency(priceRangeMax),
                offersCountFormatted: numberService.formatNumber(offersCount, 0),
            };
        },
        formatPriceDetails(details) {
            const grouped = groupBy(details, item => item.campaign);
            return Object.keys(grouped).map(campaign => ({
                campaign,
                segments: dateService.mergeDates(grouped[campaign].map(item => item.date)),
            }));
        },
        exportSets() {
            this.$refs.sets?.exportData();
        },
        ...mapActions({
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
            generateCompetitionExport: 'reportsCompetition/generateCompetitionExport',
        }),
    },
    created() {
        this.setDefaultCampaign(['PL']);
        this.prevCampaign = cloneDeep(this.campaign);
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
};
</script>

<style lang="scss" scoped>
</style>
