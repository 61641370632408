<template>
    <v-card class="elevation-0 grey lighten-4" :class="{'mb-4': editModelId}">
        <v-card-title v-if="productId">
            <v-btn
                class="ml-auto"
                color="tertiary"
                @click="handleCreate()"
                dark
                round
                :disabled="!!processingId || loading || isGroupCreating() || isAnyGroupUpdating()"
            >
                <v-icon class="mr-2" small>fa-plus</v-icon>
                {{ $t('addProductGroup') }}
            </v-btn>
        </v-card-title>
        <v-card-title v-else>
            <div>
                <h3 class="title font-weight-bold mb-3 d-flex align-center">{{ $t('groups.title') }}</h3>
            </div>
            <v-spacer />
            <v-btn
                class="ml-auto"
                color="tertiary"
                @click="handleCreate()"
                dark
                round
                :disabled="!!processingId || loading || isGroupCreating() || isAnyGroupUpdating()"
            >
                <v-icon class="mr-2" small>fa-plus</v-icon>
                {{ $t('addProductGroup') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-alert
                v-model="showError"
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>

            <v-data-table
                class="elevation-0 table--transparent"
                :headers="headers"
                :items="items"
                :total-items="totalItems"
                :no-data-text="$t('noResults')"
                :no-results-text="$t('noResults')"
                :rows-per-page-text="$t('table.rowsPerPage')"
                :rows-per-page-items="rowsPerPageItems"
                :pagination.sync="pagination"
                disable-initial-sort
                :loading="loading"
                @update:pagination="updatePagination"
            >
                <template v-slot:pageText="props">
                    {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                </template>
                <template v-slot:no-data>
                    <div class="text-xs-center">
                        <span v-if="loading"><v-progress-circular class="ma-2" indeterminate color="primary" /></span>
                        <span v-else>{{ $t('noResults') }}</span>
                    </div>
                </template>
                <template v-slot:items="props">
                    <td :class="{'grey lighten-3': isItemEdited(props.item.id)}">
                        <VueSkeleton v-if="loading" type="rect" rounded :height="16"></VueSkeleton>
                        <template v-else-if="isItemEdited(props.item.id)">
                            <v-text-field
                                ref="name"
                                v-model.trim="editModel.name"
                                :error-messages="nameErrors"
                                @blur="$v.editModel.name.$touch()"
                                @keyup.native.enter="handleEditConfirm"
                                :disabled="!!processingId || isGroupCreating() || isGroupUpdating(props.item.id)"
                                name="name"
                                type="text"
                                required
                                maxlength="255"
                                placeholder=" "
                            />
                        </template>
                        <span v-else>{{ props.item.name }}</span>
                    </td>
                    <td :class="{'grey lighten-3': isItemEdited(props.item.id)}">
                        <VueSkeleton v-if="loading" type="rect" rounded :height="16"></VueSkeleton>
                        <template v-else-if="isItemEdited(props.item.id)">
                            <v-text-field
                                v-model.trim="editModel.description"
                                name="description"
                                :disabled="!!processingId || isGroupCreating() || isGroupUpdating(props.item.id)"
                                type="text"
                                required
                                maxlength="255"
                                placeholder=" "
                            />
                        </template>
                        <span v-else>{{ props.item.description }}</span>
                    </td>
                    <td v-if="!productId" :class="{'grey lighten-3': isItemEdited(props.item.id)}">
                        <template v-if="!loading && editModelId === props.item.id">
                            <ProductsTreeSelect :group="group" @productAdded="data => $emit('productAdded', data)" @categoryAdded="data => $emit('categoryAdded', data)" />
                        </template>
                        <span v-else></span>
                    </td>
                    <td v-if="productId" :class="{'grey lighten-3': isItemEdited(props.item.id)}">
                        <VueSkeleton v-if="loading" type="rect" rounded :height="16"></VueSkeleton>
                        <span v-else-if="isItemEdited(props.item.id) && editType === 'create'"></span>
                        <template v-else>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">{{ props.item.productsNo + props.item.categoriesNo }}</div>
                                </template>
                                <div>{{ $t('groups.containsCategories') }}: {{ props.item.categoriesNo }}</div>
                                <div>{{ $t('groups.containsProducts') }}: {{ props.item.productsNo }}</div>
                            </v-tooltip>
                        </template>
                    </td>
                    <td :class="{'grey lighten-3': isItemEdited(props.item.id)}" style="width:50px;">
                        <v-layout align-center justify-end>
                            <VueSkeleton v-if="loading" type="circle" :width="16" :height="16"></VueSkeleton>
                            <template v-else>
                                <div v-if="processingId === props.item.id || isGroupDeleting(props.item.id) || isGroupUpdating(props.item.id) || (isGroupCreating() && !props.item.id)" class="ma-auto text-xs-center">
                                    <v-progress-circular :size="24" indeterminate color="primary" />
                                </div>
                                <template v-else>
                                    <template v-if="isItemEdited(props.item.id)">
                                        <v-btn @click="handleEditConfirm()" flat icon><v-icon small>fa-check</v-icon></v-btn>
                                        <v-btn  @click="handleEditCancel()" flat icon><v-icon small>fa-times</v-icon></v-btn>
                                    </template>
                                    <template v-else>
                                        <v-tooltip v-if="productId && !isProductInGroup(props.item.id)" top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    class="select-btn"
                                                    :disabled="!!processingId || isGroupCreating() || isAnyGroupUpdating()"
                                                    @click="selectGroup(props.item)"
                                                    color="success"
                                                    small
                                                    fab
                                                    icon
                                                >
                                                    <v-icon small class="pa-0">fa-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('groups.addToGroup') }}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="productId && isProductInGroup(props.item.id)" top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    class="select-btn"
                                                    :disabled="!!processingId || isGroupCreating() || isAnyGroupUpdating()"
                                                    @click="unselectGroup(props.item)"
                                                    color="error"
                                                    small
                                                    fab
                                                    icon
                                                >
                                                    <v-icon small class="pa-0">fa-minus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('groups.removeFromGroup') }}</span>
                                        </v-tooltip>
                                        <v-btn @click="handleUpdate(props.item)" flat icon :disabled="!!processingId || isGroupCreating() || isAnyGroupUpdating()"><v-icon small>fa-pen</v-icon></v-btn>
                                        <v-btn @click="confirmDelete(props.item)" flat icon :disabled="!!processingId || isGroupCreating() || isAnyGroupUpdating() || isDemo"><v-icon small>fa-trash</v-icon></v-btn>
                                    </template>
                                </template>
                            </template>
                        </v-layout>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
        <v-dialog
            v-model="confirmDeleteModalOpen"
            max-width="500"
            persistent
        >
            <v-card v-if="deleteGroupData" >
                <v-card-title class="text-h5">
                    {{ $t('confirmDelete', { name: deleteGroupData.name }) }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat :disabled="isGroupDeleting(deleteGroupData.id)" @click="cancelDelete">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        color="error"
                        round
                        :loading="isGroupDeleting(deleteGroupData.id)"
                        @click="handleDelete()"
                    >
                        {{ $t('delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VueSkeleton from 'skeleton-loader-vue';
import ProductsTreeSelect from '@/components/producer/product-groups/ProductsTreeSelect.vue';
import producerProductGroupService from '@/services/producerProductGroupService';

export default {
    name: 'Groups',
    components: {
        VueSkeleton,
        ProductsTreeSelect,
    },
    props: {
        group: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        productId: {
            type: String,
        },
        selectedProduct: {
            type: Object,
        },
    },
    validations: {
        editModel: {
            name: { required },
        },
    },
    data() {
        return {
            headers: [],
            showError: false,
            error: null,
            editType: null,
            editModel: null,
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
            ],
            pagination: {
                page: 1,
                rowsPerPage: 10,
                totalItems: 0,
            },
            totalItems: 0,
            data: [],
            showTree: false,
            processingId: null,
            confirmDeleteModalOpen: false,
            deleteGroupData: null,
            productGroups: [],
            loadingProductGroups: false,
            product: null,
        };
    },
    computed: {
        isDemo() {
            return this.$store.getters['space/isDemo'];
        },
        items() {
            const items = [...(this.data || [])].map(item => ({
                ...item,
                productsNo: item.productsNo ?? 0,
                categoriesNo: item.categoriesNo ?? 0,
            }));

            if (this.editType === 'create') {
                items.unshift(this.editModel);
            }

            return items;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.editModel.name.$dirty) {
                return errors;
            }
            if (!this.$v.editModel.name.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        productsExist() {
            return !!this.editModel?.products?.length;
        },
        loading() {
            return this.isGroupsFetching() || this.loadingProductGroups;
        },
        editModelId() {
            return this.editModel?.id || null;
        },
        isItemEdited() {
            return id => !!(this.editModel && this.editModel.id === id);
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            getGroups: 'producerProductGroup/getGroups',
            isGroupsFetching: 'producerProductGroup/isGroupsFetching',
            isGroupCreating: 'producerProductGroup/isGroupCreating',
            isGroupUpdating: 'producerProductGroup/isGroupUpdating',
            isGroupDeleting: 'producerProductGroup/isGroupDeleting',
            isProductAdding: 'producerProductGroup/isProductAdding',
            isCategoryAdding: 'producerProductGroup/isCategoryAdding',
            isAnyGroupUpdating: 'producerProductGroup/isAnyGroupUpdating',
            getterProduct: 'products/getProduct',
            getterProductDetails: 'products/getProductDetails',
        }),
    },
    watch: {
        editModelId(id) {
            this.$emit('change', id);
            this.setTableHeader();
        },
        spaceId: {
            immediate: true,
            handler() {
                this.clearGroups();
                if (this.spaceId) this.loadGroups();
            },
        },
        pagination(newValue, oldValue) {
            if (
                !this.loading
                && (
                    newValue.page !== oldValue.page
                    || newValue.rowsPerPage !== oldValue.rowsPerPage
                    || newValue.totalItems !== oldValue.totalItems
                )
            ) {
                this.loadGroups();
            }
        },
        error() {
            if (this.error) this.showError = true;
        },
        showError() {
            if (!this.showError) this.error = null;
        },
    },
    methods: {
        async loadGroups(force) {
            try {
                this.handleEditCancel();
                const { page, rowsPerPage } = this.pagination;
                await this.fetchGroups({ force, page, perPage: rowsPerPage });

                const data = this.getGroups();
                this.totalItems = data && data.total ? data.total : 0;
                if (data && data.pages && data.pages[page]) {
                    this.data = data.pages[page];
                } else {
                    this.data = null;
                }

                if (this.productId) {
                    this.loadingProductGroups = true;
                    await this.fetchProductGroups();
                }
            } catch (e) {
                this.error = e.message;
            } finally {
                this.loadingProductGroups = false;
            }
        },
        async fetchProductGroups() {
            const productGroups = this.product.type === 'product'
                ? await producerProductGroupService.fetchProductGroups(this.product.entityId)
                : await producerProductGroupService.fetchCategoryGroups(this.product.entityId);
            this.productGroups = productGroups.items?.map(group => group.id) || [];
        },
        isProductInGroup(groupId) {
            if (this.productId) {
                return this.productGroups.includes(groupId);
            }
            return false;
        },
        updatePagination() {
            this.editType = null;
            this.editModel = null;
        },
        async handleCreate() {
            this.$v.$reset();
            this.pagination.page = 1;
            await this.loadGroups();
            this.editType = 'create';
            this.editModel = {
                name: null,
                description: null,
            };
            this.$nextTick(() => {
                this.$refs.name.focus();
            });
        },
        handleUpdate(item) {
            this.$v.$reset();
            this.editType = 'update';
            this.editModel = {
                id: item.id,
                name: item.name,
                description: item.description,
            };
            this.$nextTick(() => {
                this.$refs.name.focus();
            });
        },
        handleEditCancel() {
            this.editType = null;
            this.editModel = null;
        },
        async handleEditConfirm() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                if (this.editType === 'create') {
                    await this.createGroup(this.editModel);
                } else if (this.editType === 'update') {
                    await this.updateGroup(this.editModel);
                }
                this.editType = null;
                this.editModel = null;
                this.loadGroups(true);
            } catch (e) {
                this.error = e.message;
            }
        },
        async confirmDelete(item) {
            this.deleteGroupData = item;
            this.confirmDeleteModalOpen = true;
        },
        async cancelDelete() {
            this.deleteGroupData = null;
            this.confirmDeleteModalOpen = false;
        },
        async handleDelete() {
            try {
                this.handleEditCancel();
                await this.deleteGroup({ id: this.deleteGroupData.id });
                this.deleteGroupData = null;
                this.confirmDeleteModalOpen = false;
                this.loadGroups(true);
            } catch (e) {
                this.error = e.message;
            }
        },
        initTableHeaders() {
            this.headers = [
                {
                    text: this.$t('groupName'),
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('description'),
                    align: 'left',
                    sortable: false,
                    value: 'description',
                },
                ...(this.productId ? [
                    {
                        text: this.$t('groups.contains'),
                        align: 'left',
                        sortable: false,
                        value: 'contains',
                    },
                ] : [
                    {
                        text: '',
                        align: 'left',
                        sortable: false,
                        value: 'products',
                    },
                ]),
                {
                    text: '',
                    align: 'left',
                    sortable: false,
                    value: 'action',
                },
            ];
        },
        setTableHeader() {
            const index = this.headers.findIndex(item => item.value === 'products');
            const label = this.editModelId ? this.$t('add') : '';
            if (index !== -1) this.$set(this.headers[index], 'text', label);
        },
        async hasNestedProductInGroup(group) {
            const groupDetails = await this.fetchGroup({ id: group.id });
            const groupProductsIds = groupDetails.products.map(product => product.id);
            // eslint-disable-next-line no-restricted-syntax
            for (const groupProductId of groupProductsIds) {
                // eslint-disable-next-line no-await-in-loop
                await this.fetchProductDetails({
                    productId: `product-${groupProductId}`,
                    entityId: groupProductId,
                });
                const groupProductDetails = this.getterProductDetails(`product-${groupProductId}`);
                const belongsToCurrentCategory = groupProductDetails.categories.some(categoryBatch => categoryBatch.some(category => category.id === this.product.entityId));
                if (belongsToCurrentCategory) {
                    return true;
                }
            }
            return false;
        },
        async hasNestedCategoryInGroup(group) {
            const groupDetails = await this.fetchGroup({ id: group.id });
            const groupCategoriesIds = groupDetails.categories.map(category => category.id);

            const productCategoriesChildrenCategories = this.selectedProduct.items.filter(item => item.type === 'category');

            const categoriesAlreadyInGroup = [];
            const categoriesNotInGroup = [];

            productCategoriesChildrenCategories.forEach(productChildren => {
                if (groupCategoriesIds.includes(productChildren.entityId)) {
                    // Detects if the category is already in the group
                    categoriesAlreadyInGroup.push(productChildren);
                } else {
                    // Detects if the category is not in the group
                    categoriesNotInGroup.push(productChildren);
                }
            });
            if (categoriesAlreadyInGroup.length > 0) {
                return {
                    categoriesAlreadyInGroup,
                    categoriesNotInGroup,
                };
            }
            return false;
        },
        async selectGroup(group) {
            this.processingId = group.id;
            try {
                let nestedCategories = null;
                if (this.product?.type === 'product') {
                    await this.addProduct({ groupId: group.id, productId: this.product.entityId });
                } else if (this.product?.type === 'category') {
                    if (await this.hasNestedProductInGroup(group)) {
                        this.$toast.warning(this.$t('productGroupsAddCategoryChildExistError', { name: this.product.text }), { timeout: 10000 });
                        this.processingId = null;
                        return;
                    }

                    nestedCategories = await this.hasNestedCategoryInGroup(group);
                    if (nestedCategories && nestedCategories.categoriesAlreadyInGroup) {
                        const toastVariableAdded = nestedCategories.categoriesNotInGroup.map(category => category.text).join(', ');
                        const toastVariableExisting = nestedCategories.categoriesAlreadyInGroup.map(category => category.text).join(', ');

                        // custom toast message on last iteration
                        nestedCategories.categoriesNotInGroup.forEach(async (category, index, array) => {
                            await this.addCategory({
                                groupId: group.id, categoryId: category.entityId, customToast: this.$t('producerProductGroup.addCategoryNestedSuccess', { added: toastVariableAdded, existing: toastVariableExisting }), hideToast: (index !== array.length - 1),
                            });
                        });
                    } else {
                        await this.addCategory({ groupId: group.id, categoryId: this.product.entityId });
                    }
                }
                this.productGroups = [...this.productGroups, group.id];
                this.data = this.data.map(item => {
                    if (item.id === group.id) {
                        const countKey = this.product?.type === 'product' ? 'productsNo' : 'categoriesNo';
                        return {
                            ...item,
                            [countKey]: item[countKey] + (nestedCategories ? nestedCategories.categoriesNotInGroup.length : 1),
                        };
                    }
                    return item;
                });
            } catch (e) {
                this.error = e.message;
            }
            this.processingId = null;
        },
        async unselectGroup(group) {
            this.processingId = group.id;
            try {
                if (this.product?.type === 'product') {
                    const groupDetails = await this.fetchGroup({ id: group.id });
                    const groupCategoriesIds = groupDetails.categories.map(category => category.id);
                    const productDetails = this.getterProductDetails(this.productId);
                    const hasParentCategoryInGroup = productDetails.categories.some(categoryBatch => categoryBatch.some(category => groupCategoriesIds.includes(category.id)));
                    if (hasParentCategoryInGroup) {
                        this.$toast.warning(this.$t('productGroupsRemoveProductCategoryExistError', { name: this.product.text }), { timeout: 10000 });
                        this.processingId = null;
                        return;
                    }
                    await this.deleteProduct({ groupId: group.id, productId: this.product.entityId });
                } else if (this.product?.type === 'category') {
                    await this.deleteCategory({ groupId: group.id, categoryId: this.product.entityId });
                }
                this.productGroups = this.productGroups.filter(productGroup => productGroup !== group.id);
                this.data = this.data.map(item => {
                    if (item.id === group.id) {
                        const countKey = this.product?.type === 'product' ? 'productsNo' : 'categoriesNo';
                        return {
                            ...item,
                            [countKey]: item[countKey] - 1,
                        };
                    }
                    return item;
                });
            } catch (e) {
                this.error = e.message;
            }
            this.processingId = null;
        },
        ...mapActions({
            clearGroups: 'producerProductGroup/clearGroups',
            fetchGroups: 'producerProductGroup/fetchGroups',
            fetchGroup: 'producerProductGroup/fetchGroup',
            createGroup: 'producerProductGroup/createGroup',
            updateGroup: 'producerProductGroup/updateGroup',
            deleteGroup: 'producerProductGroup/deleteGroup',
            removeGroup: 'producerProductGroup/removeGroup',
            addProduct: 'producerProductGroup/addProduct',
            addCategory: 'producerProductGroup/addCategory',
            deleteProduct: 'producerProductGroup/deleteProduct',
            deleteCategory: 'producerProductGroup/deleteCategory',
            fetchProductDetails: 'products/fetchProductDetails',
        }),
    },
    created() {
        this.initTableHeaders();
        this.product = this.getterProduct(this.productId);
    },
};
</script>

<style lang="scss" scoped>
.select-btn {
    width: 24px;
    height: 24px;
}
</style>
