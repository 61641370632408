<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <v-layout
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="title font-weight-bold">
                    {{ $t('productOffers.title') }}
                </h3>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <product-offers-table
                class="mb-5"
                :title="$t('productOffers.displayedOffersHeader')"
                :data="displayedOffers"
                :max-campaign-length="maxCampaignLength"
                :loading="displayedOffersLoading"
                :error="errorDisplayedOffers"
                @redo="redoDisplayedOffers"
            />
            <product-offers-table
                :title="$t('productOffers.nonDisplayedOffersHeader')"
                :data="nonDisplayedOffers"
                :max-campaign-length="maxCampaignLength"
                :rows-to-show="4"
                :loading="nonDisplayedOffersLoading"
                :error="errorNonDisplayedOffers"
                @redo="redoNonDisplayedOffers"
            />
        </v-card-text>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ProductOffersTable from './ProductOffersTable.vue';
import numberService from '../../../services/numberService';

export default {
    name: 'product-offers',
    components: {
        ProductOffersTable,
    },
    props: {
        productId: {
            type: String,
            required: true,
        },
        productDetails: {
            type: Object,
        },
    },
    data() {
        return {
            product: null,
            displayedOffers: null,
            nonDisplayedOffers: null,
            errorDisplayedOffers: null,
            errorNonDisplayedOffers: null,
        };
    },
    computed: {
        loading() {
            return this.displayedOffersLoading || this.nonDisplayedOffersLoading;
        },
        maxCampaignLength() {
            const maxDisplayedOffers = this.displayedOffers ? this.displayedOffers.reduce((max, item) => Math.max(max, item.campaign.length), 0) : 0;
            const maxNonDisplayedOffers = this.nonDisplayedOffers ? this.nonDisplayedOffers.reduce((max, item) => Math.max(max, item.campaign.length), 0) : 0;
            return Math.max(maxDisplayedOffers, maxNonDisplayedOffers);
        },
        ...mapState({
            displayedOffersLoading: state => state.products.loading.productDisplayedOffers,
            nonDisplayedOffersLoading: state => state.products.loading.productNonDisplayedOffers,
        }),
        ...mapGetters({
            getterProduct: 'products/getProduct',
            getterProductOffers: 'products/getProductOffers',
        }),
    },
    created() {
        this.product = this.getterProduct(this.productId);
        this.getData();
    },
    methods: {
        reset() {
            this.getData();
        },
        getData(force = false) {
            if (this.product) {
                this.getDisplayedOffers(force);
                this.getNonDisplayedOffers(force);
            }
        },
        redoDisplayedOffers() {
            this.getDisplayedOffers(true);
        },
        redoNonDisplayedOffers() {
            this.getNonDisplayedOffers(true);
        },
        parseData(data) {
            if (!data || data.length === 0) {
                return null;
            }
            return data.map(item => ({
                ...item,
                priceFormatted: numberService.formatCurrency(item.price),
            }));
        },
        async getDisplayedOffers(force = false) {
            try {
                const pluginOffers = 'in';
                this.errorDisplayedOffers = null;
                await this.fetchProductOffers({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    pluginOffers,
                });
                this.displayedOffers = this.parseData(this.getterProductOffers(this.productId, pluginOffers));
            } catch (e) {
                this.errorDisplayedOffers = e.message;
                this.displayedOffers = null;
            }
        },
        async getNonDisplayedOffers(force = false) {
            try {
                const pluginOffers = 'notIn';
                this.errorNonDisplayedOffers = null;
                await this.fetchProductOffers({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    pluginOffers,
                });
                this.nonDisplayedOffers = this.parseData(this.getterProductOffers(this.productId, pluginOffers));
            } catch (e) {
                this.errorNonDisplayedOffers = e.message;
                this.nonDisplayedOffers = null;
            }
        },
        ...mapActions({
            fetchProductOffers: 'products/fetchProductOffers',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
