import apiService from './apiService';
import errorService from './errorService';
import dateService from './dateService';
import exportService from './exportService';
import demoService from './demoService';
import dataExportServiceDemo from './demo-services/dataExportServiceDemo';

export const ExportReportStatus = Object.freeze({
    PENDING: 'pending',
    GENERATING: 'generating',
    GENERATED: 'generated',
    PARTIAL: 'partial',
    CORRUPTED: 'corrupted',
});

const dataExportService = {
    async generateReport(spaceId, region, year, month) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/export-reports`,
                {
                    year,
                    month,
                    region,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchReports(spaceId, year, month) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/export-reports`,
                {
                    year,
                    month,
                    perPage: 1000,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchReport(reportId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/export-reports/${reportId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteReport(reportId) {
        try {
            const response = await apiService.delete(
                `${process.env.VUE_APP_API_URL}/api/v1/export-reports/${reportId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async downloadReport(report) {
        try {
            const reportId = report.id;
            const reportName = this.formatReportName(report);
            await exportService.exportDataAuthorized(
                `${process.env.VUE_APP_API_URL}/api/v1/export-reports/${reportId}/download`,
                reportName,
                'xlsx',
            );
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    formatReportName(report) {
        const year = parseInt(report.year, 10);
        const month = parseInt(report.month, 10) - 1; // Adjust for zero-based index

        if (Number.isNaN(year) || Number.isNaN(month)) {
            throw new Error('Invalid year or month in report data');
        }

        const formattedDate = dateService.formatI18nDate(
            new Date(year, month),
            'month',
        );
        return `${report.spaceId || ''} ${formattedDate}`.trim();
    },
};

demoService.demoProxy(dataExportService, dataExportServiceDemo, 'dataExportService');
export default dataExportService;
