<template>
    <line-graph
        class="mt-3"
        :title="$t('competitionAveragePriceOverTime.title')"
        type="small"
        :data="items"
        :options="options"
        :no-results-title="$t('noResults')"
    >
        <template #leftFilters>
            <div class="left-filters">
                <filter-summary :filters="filtersSummary" />
                <Chip
                    :label="$t('filters.reset')"
                    :active="isResetActive"
                    @click="reset"
                />
            </div>
        </template>
        <template #filters>
            <v-flex shrink>
                <v-select
                    class="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="period"
                    :items="periodOptions"
                    placeholder=" "
                    :label="$t('filters.period')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
        </template>
    </line-graph>
</template>

<script>
import { max, sum } from 'lodash';
import numberService from '@/services/numberService';
import FilterSummary from '@/components/common/FilterSummary.vue';
import Chip from '@/components/common/Chip.vue';
import LineGraph from '@/components/graphs/LineGraph.vue';
import dateService from '@/services/dateService';

export default {
    name: 'competition-avg-price-in-time',
    components: {
        Chip,
        FilterSummary,
        LineGraph,
    },
    props: {
        set: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
        datesInRange: {
            type: Array,
            default: () => [],
        },
        campaignNames: {
            type: Array,
            default: () => [],
        },
        isCommonFilterDefault: {
            type: Boolean,
            default: true,
        },
        commonFiltersSummary: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            period: 'daily',
            periodOptions: [
                {
                    value: 'daily',
                    text: this.$t('filters.periodDaily'),
                },
                {
                    value: 'monthly',
                    text: this.$t('filters.periodMonthly'),
                },
                {
                    value: 'quarterly',
                    text: this.$t('filters.periodQuarterly'),
                },
                {
                    value: 'annually',
                    text: this.$t('filters.periodAnnually'),
                },
            ],
        };
    },
    computed: {
        periodItemsInRange() {
            const formatName = dateService.getFormatFromPeriod(this.period);
            const periodItems = new Set();
            this.datesInRange.forEach(date => {
                periodItems.add(dateService.formatI18nDate(new Date(date).valueOf(), formatName));
            });
            return Array.from(periodItems);
        },
        items() {
            if (!this.set || !this.data) {
                return null;
            }
            let hasAnyData = false;
            const dailyAvgPriceByProduct = {};

            [this.set.producerProduct, ...this.set.producerProducts, ...this.set.globalProducts].forEach(setProduct => {
                const productData = this.data.aggregations[setProduct.id] ?? this.data.products[setProduct.id];
                const productDailyAvgPrice = this.calculateDailyAvgPrice(productData.price);
                dailyAvgPriceByProduct[setProduct.id] = productDailyAvgPrice;
            });
            const formatName = dateService.getFormatFromPeriod(this.period);
            const periodAvgPriceByProduct = this.convertToPeriod(dailyAvgPriceByProduct, formatName);
            const header = ['Date'];
            Object.keys(periodAvgPriceByProduct).forEach(id => {
                const entityName = this.data.aggregations[id]?.aggregation?.name ?? this.data.products[id]?.product?.name;
                header.push({ type: 'number', label: entityName });
            });
            const items = this.periodItemsInRange.map(date => {
                const row = [date];
                Object.keys(periodAvgPriceByProduct).forEach(productId => {
                    if (periodAvgPriceByProduct[productId][date] !== null) {
                        hasAnyData = true;
                    }
                    const value = periodAvgPriceByProduct[productId][date] ?? 0;
                    row.push({ v: value || null, f: value ? numberService.formatCurrency(value) : '-' });
                });
                return row;
            });
            if (!hasAnyData || !items) {
                return null;
            }
            return [header, ...items];
        },
        options() {
            const options = {
                selectionMode: 'multiple',
                tooltip: {
                    isHtml: true,
                },
                vAxes: {
                    0: {
                        title: `${this.$t('price')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]`,
                        format: '#',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
            };

            let hasValueOverZero = false;

            if (this.items && this.items.length > 0) {
                // ignore first row with columns definition
                const filteredData = this.items.slice(1).map(row => row.slice(1).map(rowItem => rowItem.v));
                // flatten array and get max value
                const flatData = [].concat(...filteredData);
                hasValueOverZero = flatData.some(value => value > 0);
                const maxValue = max(flatData);
                if (maxValue > 0) {
                    options.vAxes[0].format = 'short';
                }
            }

            options.vAxes[0].viewWindow.max = hasValueOverZero ? null : 1;

            return options;
        },
        filtersSummary() {
            const period = this.periodOptions.find(option => option.value === this.period);
            return [
                ...this.commonFiltersSummary,
                {
                    name: this.$t('filters.period'),
                    items: [
                        period && period.text,
                    ],
                },
            ];
        },
        isResetActive() {
            return !this.isCommonFilterDefault || this.period !== 'daily';
        },
    },
    methods: {
        reset() {
            this.period = 'daily';
            this.$emit('resetLocalFilters');
        },
        calculateDailyAvgPrice(priceItemsByDay) {
            return priceItemsByDay.reduce((avgPriceByDay, priceItem) => {
                const dayPrices = Object.keys(priceItem).reduce((prices, key) => {
                    if (key === 'date') {
                        return prices;
                    }
                    return [...prices, priceItem[key]];
                }, []);
                const nonNullDayPrices = dayPrices.filter(price => price !== null);
                const avgPrice = nonNullDayPrices.length ? sum(nonNullDayPrices) / nonNullDayPrices.length : null;
                return {
                    ...avgPriceByDay,
                    [priceItem.date]: avgPrice,
                };
            }, {});
        },
        convertToPeriod(dailyAvgPriceByProduct, formatName) {
            const periodPricesByProduct = {};
            Object.keys(dailyAvgPriceByProduct).forEach(productId => {
                if (!periodPricesByProduct[productId]) {
                    periodPricesByProduct[productId] = {};
                }
                Object.keys(dailyAvgPriceByProduct[productId]).forEach(day => {
                    const period = dateService.formatI18nDate(new Date(day).valueOf(), formatName);
                    if (!periodPricesByProduct[productId][period]) {
                        periodPricesByProduct[productId][period] = [];
                    }
                    periodPricesByProduct[productId][period].push(dailyAvgPriceByProduct[productId][day]);
                });
            });
            const periodAvgPriceByProduct = {};
            Object.keys(periodPricesByProduct).forEach(productId => {
                Object.keys(periodPricesByProduct[productId]).forEach(period => {
                    const nonNullPrices = periodPricesByProduct[productId][period].filter(price => price !== null);
                    if (!periodAvgPriceByProduct[productId]) {
                        periodAvgPriceByProduct[productId] = {};
                    }
                    periodAvgPriceByProduct[productId][period] = nonNullPrices.length ? sum(nonNullPrices) / nonNullPrices.length : 0;
                });
            });
            return periodAvgPriceByProduct;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
