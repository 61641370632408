/* eslint-disable no-unused-vars */
import demoService from '@/services/demoService';

export default {
    async fetchGroups(spaceId, data) {
        const { default: producerProductGroups } = await import('../../mocks/producerProductGroups.json');
        return producerProductGroups;
    },

    async fetchGroup(groupId) {
        const { default: producerProductGroup } = await import(`../../mocks/producerProductGroup${groupId}.json`);
        return producerProductGroup;
    },

    deleteGroup(groupId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    createGroup(spaceId, data) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    updateGroup(groupId, data) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    addProduct(groupId, productId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    deleteProduct(groupId, productId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    addCategory(groupId, categoryId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    deleteCategory(groupId, categoryId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    fetchGroupProducts(groupId) {
        return [];
    },

    async fetchProductGroups(productId) {
        const { default: producerProductGroups } = await import('../../mocks/producerProductGroups.json');
        return producerProductGroups;
    },

    async fetchCategoryGroups(categoryId) {
        const { default: producerProductGroups } = await import('../../mocks/producerProductGroups.json');
        return producerProductGroups;
    },
};
