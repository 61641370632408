/* eslint-disable no-unused-vars */
import { filterDates, generateDates } from '@/services/demo-services/_utils';

export default {
    async fetchDashboardClicksStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        const { default: spaceClicksStats } = await import('../../mocks/spaceClicksStats.json');
        return spaceClicksStats;
    },

    async fetchDashboardSalesStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources) {
        const { default: spaceSalesStats } = await import('../../mocks/spaceSalesStats.json');
        return spaceSalesStats;
    },

    async fetchDashboardOwnSalesStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        const { default: spaceOwnSalesStats } = await import('../../mocks/spaceOwnSalesStats.json');
        return spaceOwnSalesStats;
    },

    async fetchDashboardOrdersStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources) {
        const { default: spaceOrdersStats } = await import('../../mocks/spaceOrdersStats.json');
        return spaceOrdersStats;
    },

    async fetchDashboardOwnOrdersStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        const { default: spaceOwnOrdersStats } = await import('../../mocks/spaceOwnOrdersStats.json');
        return spaceOwnOrdersStats;
    },

    async fetchDashboardImpressionsStats(spaceId, startDate, endDate, compareStartDate, compareEndDate, sources, group) {
        const { default: spaceImpressionsStats } = await import('../../mocks/spaceImpressionsStats.json');
        return spaceImpressionsStats;
    },

    async fetchDashboardSalesChart(spaceId, startDate, endDate, campaigns) {
        const { default: spaceSalesChart } = await import('../../mocks/spaceSalesChart.json');
        return filterDates(generateDates(spaceSalesChart));
    },

    async fetchDashboardTrafficChart(spaceId, startDate, endDate, campaigns) {
        const { default: spaceTrafficChart } = await import('../../mocks/spaceTrafficChart.json');
        return filterDates(generateDates(spaceTrafficChart));
    },
};
