<template>
    <span :class="className">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'status-label',
    props: {
        type: {
            validator: value => ['default', 'info', 'success', 'warning', 'error'].includes(value),
        },
    },
    computed: {
        className() {
            if (this.type === 'default') {
                return 'grey--text';
            }
            if (this.type === 'success') {
                return 'primary--text font-weight-bold';
            }
            return `${this.type}--text`;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
