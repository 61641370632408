<template>
    <div :class="containerClassName">
        <v-card-title>
            <v-layout
                :class="{'mb-4': type === 'normal'}"
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 v-if="type === 'normal'" class="title font-weight-bold mb-3 d-flex align-center">
                    {{ title }}
                    <slot name="leftFilters" />
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h3>
                <h4 v-if="type === 'small'" class="subheading font-weight-bold">
                    {{ title }}
                    <slot name="leftFilters" />
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h4>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <slot name="filters" />
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo()"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{ error }}
            </v-alert>

            <timeline v-if="!loading && data && data.length > 0" class="TimelineGraph__timeline" :data="data" :options="options" @ready="ready" />
            <data-placeholder v-if="!loading && (!data || data.length === 0)" :title="noResultsTitle" />
            <slot name="footer" />
        </v-card-text>
    </div>
</template>

<script>
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import Timeline from './core/Timeline.vue';
import DataPlaceholder from '../common/DataPlaceholder.vue';

export default {
    name: 'timeline-graph',
    components: {
        Timeline,
        DataPlaceholder,
        InfoTooltip,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        permissionName: {
            type: String,
        },
        type: {
            validator: value => ['small', 'normal'].includes(value),
            default: 'normal',
        },
        data: {
            type: Array,
            default: null,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        noResultsTitle: {
            type: String,
            default() {
                return this.$t('noResults');
            },
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    computed: {
        containerClassName() {
            if (this.type === 'normal') {
                return 'grey lighten-4 mb-4 pa-4';
            }
            if (this.type === 'small') {
                return '';
            }
            return '';
        },
    },
    methods: {
        ready(data) {
            this.$emit('ready', data);
        },
        redo() {
            this.$emit('redo');
        },
    },
};
</script>

<style lang="scss" scoped>
    .TimelineGraph__timeline {
        position: relative;
    }
</style>
