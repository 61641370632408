import { addToArray, deleteFromArray } from '@/services/demo-services/_utils';
import { i18n } from '@/i18n/i18n';
import producerProductGroupService from '@/services/producerProductGroupService';
import {
    CLEAR_PRODUCER_PRODUCT_GROUPS,
    FETCH_PRODUCER_PRODUCT_GROUPS_REQUEST,
    FETCH_PRODUCER_PRODUCT_GROUPS_SUCCESS,
    FETCH_PRODUCER_PRODUCT_GROUPS_FAILURE,
    CREATE_PRODUCER_PRODUCT_GROUP_REQUEST,
    CREATE_PRODUCER_PRODUCT_GROUP_SUCCESS,
    CREATE_PRODUCER_PRODUCT_GROUP_FAILURE,
    UPDATE_PRODUCER_PRODUCT_GROUP_REQUEST,
    UPDATE_PRODUCER_PRODUCT_GROUP_SUCCESS,
    UPDATE_PRODUCER_PRODUCT_GROUP_FAILURE,
    ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST,
    ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS,
    ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE,
    DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST,
    DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS,
    DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE,
    ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST,
    ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS,
    ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE,
    DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST,
    DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS,
    DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE,
    FETCH_PRODUCER_PRODUCT_GROUP_REQUEST,
    FETCH_PRODUCER_PRODUCT_GROUP_SUCCESS,
    FETCH_PRODUCER_PRODUCT_GROUP_FAILURE,
    DELETE_PRODUCER_PRODUCT_GROUP_REQUEST,
    DELETE_PRODUCER_PRODUCT_GROUP_SUCCESS,
    DELETE_PRODUCER_PRODUCT_GROUP_FAILURE,
    FETCH_PRODUCER_GROUP_PRODUCTS_REQUEST,
    FETCH_PRODUCER_GROUP_PRODUCTS_SUCCESS,
    FETCH_PRODUCER_GROUP_PRODUCTS_FAILURE,
} from '../mutationTypes';

const initialState = {
    groups: {},
    groupProducts: {},
    loading: {
        fetchGroups: false,
        createGroup: false,
        fetchGroup: [],
        fetchGroupProducts: [],
        updateGroup: [],
        deleteGroup: [],
        addProduct: [],
        deleteProduct: [],
        addCategory: [],
        deleteCategory: [],
    },
};

const getters = {
    getGroups: state => () => state.groups,
    isGroupsFetching: state => () => state.loading.fetchGroups,
    isGroupCreating: state => () => state.loading.createGroup,
    isGroupUpdating: state => id => state.loading.updateGroup.includes(id),
    isAnyGroupUpdating: state => () => !!state.loading.updateGroup.length,
    isGroupFetching: state => id => state.loading.fetchGroup.includes(id),
    isAnyGroupFetching: state => () => !!state.loading.fetchGroup.length,
    isGroupDeleting: state => id => state.loading.deleteGroup.includes(id),
    isAnyGroupDeleting: state => () => !!state.loading.deleteGroup.length,
    isProductAdding: state => id => state.loading.addProduct.includes(id),
    isAnyProductAdding: state => () => !!state.loading.addProduct.length,
    isProductDeleting: state => id => state.loading.deleteProduct.includes(id),
    isAnyProductDeleting: state => () => !!state.loading.deleteProduct.length,
    isCategoryAdding: state => id => state.loading.addCategory.includes(id),
    isAnyCategoryAdding: state => () => !!state.loading.addCategory.length,
    isCategoryDeleting: state => id => state.loading.deleteCategory.includes(id),
    isAnyCategoryDeleting: state => () => !!state.loading.deleteCategory.length,
    getGroupOptions: state => (state.groups.pages?.undefined || []).map(group => ({
        value: group.id,
        text: group.name,
    })),
    getGroupProducts: state => id => state.groupProducts[id],
    isAnyGroupProductsFetching: state => () => !!state.loading.fetchGroupProducts.length,
};

const actions = {
    async clearGroups({ commit }) {
        commit(CLEAR_PRODUCER_PRODUCT_GROUPS);
    },

    async fetchGroups({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, spaceId, page, perPage,
            } = payload;
            const cleanKeyResults = force || (state.groups && state.groups.perPage !== perPage);

            if (
                !force
                && !cleanKeyResults
                && state.groups
                && state.groups.pages
                && state.groups.pages[page]
            ) {
                return;
            }

            commit(FETCH_PRODUCER_PRODUCT_GROUPS_REQUEST);
            const reqSpaceId = spaceId || rootState.space.currentSpaceId;
            const { items, max } = await producerProductGroupService.fetchGroups(reqSpaceId, { page, perPage });

            commit(FETCH_PRODUCER_PRODUCT_GROUPS_SUCCESS, {
                items,
                max,
                page,
                perPage,
                cleanKeyResults,
            });
        } catch (e) {
            commit(FETCH_PRODUCER_PRODUCT_GROUPS_FAILURE);
            throw e;
        }
    },

    async fetchGroup({ commit }, payload = {}) {
        try {
            commit(FETCH_PRODUCER_PRODUCT_GROUP_REQUEST, payload.id);

            const response = await producerProductGroupService.fetchGroup(payload.id);

            commit(FETCH_PRODUCER_PRODUCT_GROUP_SUCCESS, payload.id);

            return response;
        } catch (e) {
            commit(FETCH_PRODUCER_PRODUCT_GROUP_FAILURE, payload.id);
            throw e;
        }
    },

    async fetchGroupProducts({ commit, state }, payload = {}) {
        const { groupId, force } = payload;
        try {
            if (!force && state.groupProducts[groupId]) {
                return state.groupProducts[groupId];
            }
            commit(FETCH_PRODUCER_GROUP_PRODUCTS_REQUEST, { groupId });
            const response = await producerProductGroupService.fetchGroupProducts(groupId);
            commit(FETCH_PRODUCER_GROUP_PRODUCTS_SUCCESS, { groupId, products: response.items });
            return response.items;
        } catch (e) {
            commit(FETCH_PRODUCER_GROUP_PRODUCTS_FAILURE, { groupId });
            throw e;
        }
    },

    async deleteGroup({ commit }, payload = {}) {
        try {
            commit(DELETE_PRODUCER_PRODUCT_GROUP_REQUEST, payload.id);

            const response = await producerProductGroupService.deleteGroup(payload.id);

            commit(DELETE_PRODUCER_PRODUCT_GROUP_SUCCESS, payload.id);

            return response;
        } catch (e) {
            commit(DELETE_PRODUCER_PRODUCT_GROUP_FAILURE, payload.id);
            throw e;
        }
    },

    async createGroup({ commit, rootState }, payload = {}) {
        try {
            commit(CREATE_PRODUCER_PRODUCT_GROUP_REQUEST);

            const spaceId = payload.spaceId || rootState.space.currentSpaceId;
            await producerProductGroupService.createGroup(spaceId, payload);

            commit(CREATE_PRODUCER_PRODUCT_GROUP_SUCCESS);

            this._vm.$toast.info(i18n.t('producerProductGroup.createSuccess', { name: payload.name }), { color: 'success' });
        } catch (e) {
            commit(CREATE_PRODUCER_PRODUCT_GROUP_FAILURE);
            throw e;
        }
    },

    async updateGroup({ commit }, payload = {}) {
        try {
            commit(UPDATE_PRODUCER_PRODUCT_GROUP_REQUEST, payload.id);

            const { id, ...data } = payload;

            const response = await producerProductGroupService.updateGroup(id, data);

            commit(UPDATE_PRODUCER_PRODUCT_GROUP_SUCCESS, payload.id);

            this._vm.$toast.info(i18n.t('producerProductGroup.updateSuccess', { name: payload.name }), { color: 'success' });

            return response;
        } catch (e) {
            commit(UPDATE_PRODUCER_PRODUCT_GROUP_FAILURE, payload.id);
            throw e;
        }
    },

    async addProduct({ commit }, payload = {}) {
        const { groupId, productId } = payload;
        try {
            commit(ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST, `${groupId}-${productId}`);

            await producerProductGroupService.addProduct(groupId, productId);

            commit(ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS, `${groupId}-${productId}`);

            this._vm.$toast.info(i18n.t('producerProductGroup.addProductSuccess'), { color: 'success' });
        } catch (e) {
            commit(ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE, `${groupId}-${productId}`);
            throw e;
        }
    },

    async deleteProduct({ commit }, payload = {}) {
        const { groupId, productId } = payload;
        try {
            commit(DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST, `${groupId}-${productId}`);

            await producerProductGroupService.deleteProduct(groupId, productId);

            commit(DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS, `${groupId}-${productId}`);

            this._vm.$toast.info(i18n.t('producerProductGroup.deleteProductSuccess'), { color: 'success' });
        } catch (e) {
            commit(DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE, `${groupId}-${productId}`);
            throw e;
        }
    },

    async addCategory({ commit }, payload = {}) {
        const {
            groupId, categoryId, customToast, hideToast,
        } = payload;
        try {
            commit(ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST, `${groupId}-${categoryId}`);

            await producerProductGroupService.addCategory(groupId, categoryId);

            commit(ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS, `${groupId}-${categoryId}`);

            if (!customToast && !hideToast) {
                this._vm.$toast.info(i18n.t('producerProductGroup.addCategorySuccess'), { color: 'success' });
            } else {
                this._vm.$toast.info(customToast, { color: 'success', autoHeight: true, multiLine: true });
            }
        } catch (e) {
            commit(ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE, `${groupId}-${categoryId}`);
            throw e;
        }
    },

    async deleteCategory({ commit }, payload = {}) {
        const { groupId, categoryId } = payload;
        try {
            commit(DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST, `${groupId}-${categoryId}`);

            await producerProductGroupService.deleteCategory(groupId, categoryId);

            commit(DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS, `${groupId}-${categoryId}`);

            this._vm.$toast.info(i18n.t('producerProductGroup.deleteCategorySuccess'), { color: 'success' });
        } catch (e) {
            commit(DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE, `${groupId}-${categoryId}`);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAR_PRODUCER_PRODUCT_GROUPS
    // -----------------------------------------
    [CLEAR_PRODUCER_PRODUCT_GROUPS](state) {
        state.groups = {};
        state.groupProducts = {};
    },

    // -----------------------------------------
    // FETCH_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [FETCH_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.fetchGroup = addToArray(state.loading.fetchGroup, id);
    },
    [FETCH_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.fetchGroup = deleteFromArray(state.loading.fetchGroup, id);
    },
    [FETCH_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.fetchGroup = deleteFromArray(state.loading.fetchGroup, id);
    },

    // -----------------------------------------
    // FETCH_PRODUCER_GROUP_PRODUCTS
    // -----------------------------------------
    [FETCH_PRODUCER_GROUP_PRODUCTS_REQUEST](state, payload) {
        const { groupId } = payload;
        state.loading.fetchGroupProducts = addToArray(state.loading.fetchGroupProducts, groupId);
    },
    [FETCH_PRODUCER_GROUP_PRODUCTS_SUCCESS](state, payload) {
        const { groupId, products } = payload;
        state.groupProducts = {
            ...state.groupProducts,
            [groupId]: products,
        };
        state.loading.fetchGroupProducts = deleteFromArray(state.loading.fetchGroupProducts, groupId);
    },
    [FETCH_PRODUCER_GROUP_PRODUCTS_FAILURE](state, payload) {
        const { groupId } = payload;
        state.loading.fetchGroupProducts = deleteFromArray(state.loading.fetchGroupProducts, groupId);
    },

    // -----------------------------------------
    // DELETE_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [DELETE_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.deleteGroup = addToArray(state.loading.deleteGroup, id);
    },
    [DELETE_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.deleteGroup = deleteFromArray(state.loading.deleteGroup, id);
    },
    [DELETE_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.deleteGroup = deleteFromArray(state.loading.deleteGroup, id);
    },

    // -----------------------------------------
    // FETCH_PRODUCER_PRODUCT_GROUPS
    // -----------------------------------------
    [FETCH_PRODUCER_PRODUCT_GROUPS_REQUEST](state) {
        state.loading.fetchGroups = true;
    },
    [FETCH_PRODUCER_PRODUCT_GROUPS_SUCCESS](state, payload) {
        const {
            items, max, page, perPage, cleanKeyResults,
        } = payload;
        const groups = (cleanKeyResults || !state.groups) ? {} : state.groups;
        groups.total = max;
        groups.perPage = perPage;
        groups.pages = groups.pages ? groups.pages : {};
        groups.pages[page] = items;
        state.groups = { ...groups };
        state.loading.fetchGroups = false;
    },
    [FETCH_PRODUCER_PRODUCT_GROUPS_FAILURE](state) {
        state.loading.fetchGroups = false;
    },

    // -----------------------------------------
    // CREATE_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [CREATE_PRODUCER_PRODUCT_GROUP_REQUEST](state) {
        state.loading.createGroup = true;
    },
    [CREATE_PRODUCER_PRODUCT_GROUP_SUCCESS](state) {
        state.loading.createGroup = false;
    },
    [CREATE_PRODUCER_PRODUCT_GROUP_FAILURE](state) {
        state.loading.createGroup = false;
    },

    // -----------------------------------------
    // UPDATE_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [UPDATE_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.updateGroup = addToArray(state.loading.updateGroup, id);
    },
    [UPDATE_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.updateGroup = deleteFromArray(state.loading.updateGroup, id);
    },
    [UPDATE_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.updateGroup = deleteFromArray(state.loading.updateGroup, id);
    },

    // -----------------------------------------
    // ADD_PRODUCT_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.addProduct = addToArray(state.loading.addProduct, id);
    },
    [ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.addProduct = deleteFromArray(state.loading.addProduct, id);
        state.groupProducts = {};
    },
    [ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.addProduct = deleteFromArray(state.loading.addProduct, id);
    },

    // -----------------------------------------
    // DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.deleteProduct = addToArray(state.loading.deleteProduct, id);
    },
    [DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.deleteProduct = deleteFromArray(state.loading.deleteProduct, id);
        state.groupProducts = {};
    },
    [DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.deleteProduct = deleteFromArray(state.loading.deleteProduct, id);
    },

    // -----------------------------------------
    // ADD_CATEGORY_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.addCategory = addToArray(state.loading.addCategory, id);
    },
    [ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.addCategory = deleteFromArray(state.loading.addCategory, id);
        state.groupProducts = {};
    },
    [ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.addCategory = deleteFromArray(state.loading.addCategory, id);
    },

    // -----------------------------------------
    // DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP
    // -----------------------------------------
    [DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST](state, id) {
        state.loading.deleteCategory = addToArray(state.loading.deleteCategory, id);
    },
    [DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS](state, id) {
        state.loading.deleteCategory = deleteFromArray(state.loading.deleteCategory, id);
        state.groupProducts = {};
    },
    [DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE](state, id) {
        state.loading.deleteCategory = deleteFromArray(state.loading.deleteCategory, id);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
