<template>
    <div>
        <no-access-placeholder
            v-if="!hasPermission"
            :permission-name="permissionName"
            :image="require('../../../assets/noAccessPlaceholders/product-availability.jpg')"
            cta-url="producerReportsAvailability"
            @activated="redo"
        />
        <timeline-graph
            v-if="hasPermission"
            :title="$t('productAvailability.title')"
            :permissionName="permissionName"
            :data="items"
            :loading="loading"
            :error="error"
            @redo="redo"
        >
            <template #filters>
                <v-flex shrink>
                    <v-select
                        v-if="!campaign"
                        class="d-inline-block mr-3 mb-3 select--responsive"
                        v-model="pluginCampaigns"
                        @change="handlePluginCampaignsChange"
                        @input="handlePluginCampaignsChange"
                        :items="pluginCampaignsOptions"
                        :disabled="loading"
                        placeholder=" "
                        :label="$t('productAvailability.pluginCampaigns')"
                        outline
                        hide-details
                        dense
                    />

                    <select-all
                        class-name="d-inline-block mr-3 mb-3 select--responsive"
                        :label="$t('filters.shop')"
                        :all-label="$t('filters.allShops')"
                        require-selection
                        :items="campaignOptions"
                        v-model="campaign"
                        @input="handlePluginCampaignsChange"
                        @blur="handlePluginCampaignsChange"
                    />
                </v-flex>

            </template>
            <template #footer>
                <more-button v-if="showMoreButton && productEntityId" :to="{ name: 'producerReportsAvailability', query: { product: productEntityId } }" />

                <div v-if="hasMoreCampaigns" class="text-xs-center">
                    <v-btn
                        class="btn-wide mt-4"
                        type="submit"
                        color="tertiary"
                        dark
                        round
                        @click="showAll"
                    >
                        {{ $t('showAll') }}
                    </v-btn>
                </div>
            </template>
        </timeline-graph>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import spaceService from '@/services/spaceService';
import eventBus from '@/services/eventBus';
import permissionMixin from '@/mixins/permissionMixin';
import TimelineGraph from '@/components/graphs/TimelineGraph.vue';
import NoAccessPlaceholder from '@/components/common/NoAccessPlaceholder.vue';
import MoreButton from '@/components/common/MoreButton.vue';
import campaignFilterMixin from '@/mixins/campaignFilterMixin';
import SelectAll from '@/components/common/SelectAll.vue';

export default {
    name: 'product-availability',
    components: {
        NoAccessPlaceholder,
        TimelineGraph,
        MoreButton,
        SelectAll,
    },
    mixins: [
        permissionMixin,
        campaignFilterMixin,
    ],
    props: {
        productId: {
            type: String,
            required: true,
        },
        // used for global products
        globalEntityId: {
            type: Number,
            required: false,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
        parentCampaign: {
            type: Array,
        },
        showMoreButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            permissionName: spaceService.permissions.STATS_AVAILABILITY,
            product: null,
            data: null,
            error: null,
            pluginCampaigns: 'in',
            pluginCampaignsOptions: [
                {
                    value: 'in',
                    text: this.$t('productAvailability.inPluginCampaigns'),
                },
                {
                    value: 'notIn',
                    text: this.$t('productAvailability.notInPluginCampaigns'),
                },
                {
                    value: 'all',
                    text: this.$t('productAvailability.allPluginCampaigns'),
                },
            ],
        };
    },
    computed: {
        productEntityId() {
            return (this.product && this.product.entityId) || null;
        },
        hasMoreCampaigns() {
            if (this.loading || this.error || !this.data || this.data.length === 0) {
                return false;
            }
            return !this.hasAllCampaigns();
        },
        loading() {
            return this.productAvailabilityLoading;
        },
        items() {
            if (!this.data || this.data.length === 0) {
                return null;
            }

            const header = [
                { type: 'string', label: 'Shop', id: 'Shop' },
                { type: 'string', id: 'Empty label' },
                { type: 'string', id: 'style', role: 'style' },
                {
                    type: 'string', id: 'tooltip', role: 'tooltip', p: { html: true },
                },
                { type: 'date', label: 'From', id: 'From' },
                { type: 'date', label: 'To', id: 'To' },
            ];
            const items = [];
            const emptyCampaigns = [];
            let emptyItems = [];
            let lowestStartDate = null;
            this.data.forEach(row => {
                if (row.segments && row.segments.length > 0) {
                    // add segments to items array
                    row.segments.forEach(segment => {
                        items.push([
                            row.label,
                            '',
                            null, // display original style
                            null, // display original tooltip
                            new Date(segment.start),
                            new Date(segment.end),
                        ]);
                        // get the smallest start date from whole data set
                        lowestStartDate = lowestStartDate === null ? segment.start : Math.min(lowestStartDate, segment.start);
                    });
                } else {
                    // create list of campaigns without segments
                    emptyCampaigns.push(row.label);
                }
            });
            if (emptyCampaigns.length > 0) {
                // in case all campaigns had no segments, we need to set dummy range that looks good on chart
                // otherwise timeline chart will break
                let lowestEndDate = lowestStartDate;
                if (lowestStartDate === null) {
                    lowestEndDate = (new Date()).valueOf();
                    lowestStartDate = lowestEndDate - 604800000;
                }
                emptyItems = emptyCampaigns.map(campaign => [
                    campaign,
                    '',
                    'opacity: 0', // hide bar, it's displayed even if range is 0
                    '', // hide tooltip
                    lowestStartDate,
                    lowestEndDate,
                ]);
            }

            return [header, ...[...items, ...emptyItems]];
        },
        ...mapState({
            productAvailabilityLoading: state => state.products.loading.productAvailability,
        }),
        ...mapGetters({
            getterProduct: 'products/getProduct',
            getterProductAvailability: 'products/getProductAvailability',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
            ) {
                this.getData();
            }
        },
    },
    async created() {
        this.campaign = this.parentCampaign;
        this.product = this.getterProduct(this.productId);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        async showAll() {
            await this.setAllCampaigns();
            this.getData();
        },
        reset() {
            this.pluginCampaigns = 'in';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.pluginCampaigns === 'in';
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.checkPermission(this.permissionName);
            if ((this.globalEntityId || this.product) && this.hasPermission) {
                this.getAvailability(force);
            }
        },
        handlePluginCampaignsChange() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        async getAvailability(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                const pluginCampaigns = this.campaign ? undefined : this.pluginCampaigns;
                await this.fetchProductAvailability({
                    force,
                    productId: this.productId,
                    entityId: this.product?.entityId,
                    globalEntityId: this.globalEntityId,
                    pluginCampaigns,
                    campaign: this.campaign,
                    startDate,
                    endDate,
                });
                this.data = this.getterProductAvailability(this.productId, startDate, endDate, pluginCampaigns, this.campaign);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            fetchProductAvailability: 'products/fetchProductAvailability',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
