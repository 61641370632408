import demoService from '@/services/demoService';
import producerProductGroupServiceDemo from '@/services/demo-services/producerProductGroupServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const producerProductGroupService = {
    async fetchGroups(spaceId, data) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-product-groups`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchGroup(groupId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteGroup(groupId) {
        try {
            const response = await apiService.delete(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createGroup(spaceId, data) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-product-groups`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateGroup(groupId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async addProduct(groupId, productId) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}/products/${productId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteProduct(groupId, productId) {
        try {
            const response = await apiService.delete(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}/products/${productId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async addCategory(groupId, categoryId) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}/categories/${categoryId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteCategory(groupId, categoryId) {
        try {
            const response = await apiService.delete(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}/categories/${categoryId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchGroupProducts(groupId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-groups/${groupId}/products`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductGroups(productId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${productId}/producer-product-groups`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategoryGroups(categoryId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${categoryId}/producer-product-groups`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(producerProductGroupService, producerProductGroupServiceDemo, 'producerProductGroupService');
export default producerProductGroupService;
