<template>
    <v-dialog
        v-model="isOpen"
        max-width="1000"
    >
        <v-form v-if="isOpen" @submit.prevent="handleSubmit" novalidate>
            <v-card>
                <v-card-title class="headline">
                    {{ isCreateMode ? $t('updateProductModal.createTitle') : $t('updateProductModal.updateTitle', { name: product.text }) }}
                </v-card-title>
                <v-card-text>
                    <v-flex class="pr-4" xs12>
                        <v-text-field
                            v-model.trim="model.name"
                            :error-messages="nameErrors"
                            @blur="$v.model.name.$touch()"
                            name="name"
                            :label="$t('updateProductModal.name')"
                            :disabled="submitting"
                            type="text"
                            required
                            :maxlength="textFieldMaxLength"
                            :counter="textFieldMaxLength"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12>
                        <v-text-field
                            v-model.trim="model.description"
                            :error-messages="descriptionErrors"
                            @blur="$v.model.description.$touch()"
                            name="description"
                            :label="$t('updateProductModal.description')"
                            :disabled="submitting"
                            type="text"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex v-if="isCreateMode" class="pr-4" xs12>
                        <v-text-field
                            v-model.trim="model.ean"
                            :error-messages="eanErrors"
                            @blur="$v.model.ean.$touch()"
                            name="ean"
                            :label="$t('updateProductModal.ean')"
                            :disabled="submitting"
                            type="text"
                            :maxlength="textFieldMaxLength"
                            :counter="textFieldMaxLength"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex v-if="isCreateMode" class="pr-4" xs12>
                        <v-text-field
                            v-model.trim="model.sku"
                            :error-messages="skuErrors"
                            @blur="$v.model.sku.$touch()"
                            name="sku"
                            :label="$t('updateProductModal.sku')"
                            :disabled="submitting"
                            type="text"
                            :maxlength="textFieldMaxLength"
                            :counter="textFieldMaxLength"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12>
                        <v-text-field
                            v-model.trim="model.price"
                            :error-messages="priceErrors"
                            @blur="$v.model.price.$touch()"
                            name="price"
                            :label="$t('updateProductModal.price')"
                            :disabled="submitting"
                            type="number"
                            min="0"
                            placeholder=" "
                            outline
                        />
                    </v-flex>

                    <h3 class="title mb-3">{{ $t('updateProductModal.images') }}</h3>
                    <v-flex
                        v-for="(v, index) in $v.model.images.$each.$iter"
                        :key="index"
                        class="pr-4"
                        d-flex
                        xs12
                    >
                        <v-text-field
                            v-model.trim="v.image.$model"
                            :error-messages="getImageErrors(v.image)"
                            @blur="v.image.$touch()"
                            :name="`image-${Number.parseInt(index, 10) + 1}`"
                            :label="`${$t('updateProductModal.image')} ${Number.parseInt(index, 10) + 1}`"
                            :disabled="submitting"
                            type="text"
                            :maxlength="textFieldMaxLength"
                            :counter="textFieldMaxLength"
                            placeholder=" "
                            outline
                        />
                        <v-btn
                            class="button ml-3 image-remove"
                            flat
                            icon
                            :disabled="submitting"
                            @click="removeImage(index)"
                        >
                            <v-icon small>fa-times-circle</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-btn
                        v-if="showAddImageButton"
                        class="button"
                        :disabled="submitting"
                        @click="addImage"
                    >
                        {{ $t('updateProductModal.addImage') }}
                    </v-btn>

                    <h3 class="title mt-3 mb-3">{{ $t('updateProductModal.category') }}</h3>
                    <CategorySelectTree :product-id="productId" @change="handleCategoryChange" :errors="categoryErrors" />

                    <v-alert
                        :value="error"
                        dismissible
                        type="error"
                        transition="scale-transition">
                        {{ error }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat :disabled="submitting" @click="isOpen = false">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        type="submit"
                        color="tertiary"
                        dark
                        round
                        :loading="submitting"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
    decimal,
    maxLength,
    minValue,
    required,
    requiredIf,
} from 'vuelidate/lib/validators';
import CategorySelectTree from '@/components/producer/products/CategorySelectTree.vue';

const TEXT_FIELD_MAX_LENGTH = 255;

export default {
    name: 'update-product-modal',
    components: { CategorySelectTree },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(TEXT_FIELD_MAX_LENGTH),
            },
            description: {},
            ean: {
                required: requiredIf(function eanRequiredFn() {
                    return this.isCreateMode;
                }),
                maxLength: maxLength(TEXT_FIELD_MAX_LENGTH),
            },
            sku: {
                required: requiredIf(function skuRequiredFn() {
                    return this.isCreateMode;
                }),
                maxLength: maxLength(TEXT_FIELD_MAX_LENGTH),
            },
            price: {
                decimal,
                minValue: minValue(0),
            },
            images: {
                $each: {
                    image: {
                        required,
                        maxLength: maxLength(TEXT_FIELD_MAX_LENGTH),
                    },
                },
            },
            category: {
                required,
            },
        },
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        productId: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            model: {
                name: '',
                description: '',
                ean: '',
                sku: '',
                price: null,
                images: [],
                category: undefined,
            },
            textFieldMaxLength: TEXT_FIELD_MAX_LENGTH,
            product: null,
            productDetails: null,
            error: null,
        };
    },
    computed: {
        isCreateMode() {
            return !this.productId;
        },
        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        showAddImageButton() {
            return this.model.images.length < 10;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.model.name.$dirty) {
                return errors;
            }
            if (!this.$v.model.name.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.name.maxLength) {
                errors.push(this.$t('validation.maxLength', { limit: TEXT_FIELD_MAX_LENGTH }));
            }
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.model.description.$dirty) {
                return errors;
            }
            return errors;
        },
        eanErrors() {
            const errors = [];
            if (!this.$v.model.ean.$dirty) {
                return errors;
            }
            if (!this.$v.model.ean.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.ean.maxLength) {
                errors.push(this.$t('validation.maxLength', { limit: TEXT_FIELD_MAX_LENGTH }));
            }
            return errors;
        },
        skuErrors() {
            const errors = [];
            if (!this.$v.model.sku.$dirty) {
                return errors;
            }
            if (!this.$v.model.sku.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.sku.maxLength) {
                errors.push(this.$t('validation.maxLength', { limit: TEXT_FIELD_MAX_LENGTH }));
            }
            return errors;
        },
        priceErrors() {
            const errors = [];
            if (!this.$v.model.price.$dirty) {
                return errors;
            }
            if (!this.$v.model.price.decimal) {
                errors.push(this.$t('validation.decimal'));
            }
            if (!this.$v.model.price.minValue) {
                errors.push(this.$t('validation.minValue', { min: 0 }));
            }
            return errors;
        },
        categoryErrors() {
            const errors = [];
            if (!this.$v.model.category.$dirty) {
                return errors;
            }
            if (!this.$v.model.category.required) {
                errors.push(this.$t('updateProductModal.categoryRequired'));
            }
            return errors;
        },
        submitting() {
            return this.updateProductsLoading.includes(this.product?.entityId) || this.createProductLoading;
        },
        ...mapState({
            updateProductsLoading: state => state.products.loading.updateProducts,
            createProductLoading: state => state.products.loading.createProduct,
        }),
        ...mapGetters({
            getterProduct: 'products/getProduct',
            getterProductDetails: 'products/getProductDetails',
            getterProductsTree: 'products/getProductsTree',
        }),
    },
    watch: {
        isOpen(newValue) {
            if (newValue === true) {
                this.product = this.productId ? this.getterProduct(this.productId) : null;
                this.productDetails = this.productId ? this.getterProductDetails(this.productId) : null;
                this.resetForm();
            }
        },
    },
    methods: {
        getImageErrors(v) {
            const errors = [];
            if (!v.$dirty) {
                return errors;
            }
            if (!v.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!v.maxLength) {
                errors.push(this.$t('validation.maxLength', { limit: TEXT_FIELD_MAX_LENGTH }));
            }
            return errors;
        },
        addImage() {
            this.model = {
                ...this.model,
                images: [...this.model.images, { image: '' }],
            };
        },
        removeImage(index) {
            const idx = Number.parseInt(index, 10);
            this.model = {
                ...this.model,
                images: this.model.images.filter((image, imageIndex) => imageIndex !== idx),
            };
        },
        handleCategoryChange(category) {
            this.model = {
                ...this.model,
                category,
            };
        },
        resetForm() {
            this.model = {
                name: this.productDetails?.name ?? '',
                description: this.productDetails?.description ?? '',
                price: this.productDetails?.price ?? null,
                images: this.productDetails?.images ? this.productDetails.images.map(image => ({ image })) : [],
            };
            this.$v.$reset();
        },
        async handleSubmit() {
            try {
                this.error = null;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                const { entityId } = this.product ?? {};
                const {
                    ean, sku, price, images, category, ...model
                } = this.model;
                let numericPrice = Number.parseFloat(price);
                if (Number.isNaN(numericPrice)) {
                    numericPrice = null;
                }
                const saveData = {
                    entityId,
                    data: {
                        ...model,
                        price: numericPrice,
                        images: images.map(image => image.image),
                        categories: [category],
                    },
                };
                if (entityId) {
                    await this.updateProduct(saveData);
                    this.$toast.success(this.$t('updateProductModal.updateSuccess', { name: model.name }));
                } else {
                    saveData.data.ean = ean;
                    saveData.data.sku = sku;
                    await this.createProduct(saveData);
                    this.$toast.success(this.$t('updateProductModal.createSuccess', { name: model.name }));
                }
                this.$emit('savedProduct');
                this.isOpen = false;
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            updateProduct: 'products/updateProduct',
            createProduct: 'products/createProduct',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .image-remove {
        flex-grow: 0 !important;
        position: relative;
        top: 5px;
    }
</style>
