import Vue from 'vue';
import Router from 'vue-router';
import usercomService from '@/services/usercomService';
import { i18n } from '../i18n/i18n';
import store from '../store/store';
import hasAccessGuard from './guards/hasAccessGuard';
// import WidgetGenerator from '../views/widgetGenerator/WidgetGenerator.vue';
// import WidgetGeneratorPreview from '../components/widgetGenerator/WidgetGeneratorPreview.vue';
import AbstractRoute from '../components/common/AbstractRoute.vue';
import Register from '../views/Register.vue';
import Login from '../views/Login.vue';
import LoginAs from '../views/LoginAs.vue';
import AcceptTerms from '../views/AcceptTerms.vue';
import ResetPassword from '../views/ResetPassword.vue';
import ResetPasswordComplete from '../views/ResetPasswordComplete.vue';
import ProducerMain from '../views/producer/ProducerMain.vue';
import ProducerDashboard from '../views/producer/ProducerDashboard.vue';
import ProducerProducts from '../views/producer/ProducerProducts.vue';
import ProducerProductGroups from '../views/producer/ProducerProductGroups.vue';
import ProducerSpaces from '../views/producer/ProducerSpaces.vue';
import ProducerAccountSettings from '../views/producer/ProducerAccountSettings.vue';
import StatusPage404 from '../views/StatusPage404.vue';
import ProducerPlugin from '../views/producer/ProducerPlugin.vue';
import ProducerReportsProducts from '../views/producer/ProducerReportsProducts.vue';
import ProducerReportsCategory from '../views/producer/ProducerReportsCategory.vue';
import ProducerReportsSales from '../views/producer/ProducerReportsSales.vue';
import ProducerReportsShops from '../views/producer/ProducerReportsShops.vue';
import ProducerReportsAvailability from '../views/producer/ProducerReportsAvailability.vue';
import ProducerSubscriptions from '../views/producer/ProducerSubscriptions.vue';
import ProducerWebhooks from '../views/producer/ProducerWebhooks.vue';
import ProducerReportsPriceDistribution from '../views/producer/ProducerReportsPriceDistribution.vue';
import ProducerDataExport from '../views/producer/ProducerDataExport.vue';
import ConfirmEmail from '../views/ConfirmEmail.vue';
import ProducerCompetitiveAnalysis from '../views/producer/CompetitiveAnalysis.vue';
import ProducerCampaignAnalysis from '../views/producer/CampaignAnalysis.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                title: 'register.title',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: 'login.title',
            },
        },
        {
            path: '/login-as/:token?',
            name: 'loginAs',
            component: LoginAs,
            props: route => ({
                token: route.params.token,
                isAdmin: true,
            }),
            meta: {
                title: 'loginAs.title',
            },
        },
        {
            path: '/redirect-login/:token?',
            name: 'loginRedirect',
            component: LoginAs,
            props: route => ({
                token: route.params.token,
                isAdmin: false,
            }),
            meta: {
                title: 'loginAs.title',
            },
        },
        {
            path: '/accept-terms',
            name: 'acceptTerms',
            component: AcceptTerms,
            meta: {
                title: 'acceptTerms.title',
                isSecure: true,
            },
        },
        {
            path: '/reset-password',
            name: 'resetPassword',
            component: ResetPassword,
            meta: {
                title: 'resetPassword.title',
            },
        },
        {
            path: '/reset-password/:token',
            name: 'resetPasswordComplete',
            component: ResetPasswordComplete,
            props: true,
            meta: {
                title: 'resetPasswordComplete.title',
            },
        },
        {
            path: '/confirm-email/:token?',
            name: 'confirmEmail',
            component: ConfirmEmail,
            props: true,
            meta: {
                title: 'confirmEmail.title',
            },
        },
        {
            path: '/producer',
            name: 'producer',
            component: ProducerMain,
            meta: {
                title: 'producer.title',
                isSecure: true,
                roles: ['ROLE_PRODUCER'],
            },
            redirect: {
                name: 'producerDashboard',
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'producerDashboard',
                    component: ProducerDashboard,
                    meta: {
                        title: 'producerDashboard.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'products',
                    name: 'producerProducts',
                    component: ProducerProducts,
                    meta: {
                        title: 'producerProducts.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'product-groups',
                    name: 'producerProductGroups',
                    component: ProducerProductGroups,
                    meta: {
                        title: 'producerProductGroup.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'product-tour',
                    name: 'productsTour',
                    component: ProducerProducts,
                    meta: {
                        title: 'producerProducts.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                        isProductTour: true,
                    },
                },
                {
                    path: 'category-tour',
                    name: 'categoryTour',
                    component: ProducerProducts,
                    meta: {
                        title: 'producerProducts.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                        isCategoryTour: true,
                    },
                },
                {
                    path: 'reports',
                    name: 'reports',
                    component: AbstractRoute,
                    redirect: {
                        name: 'producerReportsProducts',
                    },
                    children: [
                        {
                            path: 'shops',
                            name: 'producerReportsShops',
                            component: ProducerReportsShops,
                            meta: {
                                title: 'producerReportsShops.title',
                                isSecure: true,
                                roles: ['ROLE_PRODUCER'],
                            },
                        },
                        {
                            path: 'category',
                            name: 'producerReportsCategory',
                            component: ProducerReportsCategory,
                            meta: {
                                title: 'producerReportsCategory.title',
                                isSecure: true,
                                roles: ['ROLE_PRODUCER'],
                            },
                        },
                        {
                            path: 'products',
                            name: 'producerReportsProducts',
                            component: ProducerReportsProducts,
                            meta: {
                                title: 'producerReportsProducts.title',
                                isSecure: true,
                                roles: ['ROLE_PRODUCER'],
                            },
                        },
                        {
                            path: 'availibility',
                            name: 'producerReportsAvailability',
                            component: ProducerReportsAvailability,
                            meta: {
                                title: 'producerReportsAvailability.title',
                                isSecure: true,
                                roles: ['ROLE_PRODUCER'],
                            },
                        },
                        {
                            path: 'price-distribution',
                            name: 'producerReportsPriceDistribution',
                            component: ProducerReportsPriceDistribution,
                            meta: {
                                title: 'producerReportsPriceDistribution.title',
                                isSecure: true,
                                roles: ['ROLE_PRODUCER'],
                            },
                        },
                        {
                            path: 'sales',
                            name: 'producerReportsSales',
                            component: ProducerReportsSales,
                            meta: {
                                title: 'producerReportsSales.title',
                                isSecure: true,
                                roles: ['ROLE_PRODUCER'],
                            },
                        },
                    ],
                },
                {
                    path: 'plugin',
                    name: 'producerPlugin',
                    component: ProducerPlugin,
                    meta: {
                        title: 'producerPlugin.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'spaces',
                    name: 'producerSpaces',
                    component: ProducerSpaces,
                    meta: {
                        title: 'producerSpaces.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'subscriptions',
                    name: 'producerSubscriptions',
                    component: ProducerSubscriptions,
                    meta: {
                        title: 'producerSubscriptions.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'webhooks',
                    name: 'producerWebhooks',
                    component: ProducerWebhooks,
                    meta: {
                        title: 'producerWebhooks.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'account-settings',
                    name: 'producerAccountSettings',
                    component: ProducerAccountSettings,
                    meta: {
                        title: 'producerAccountSettings.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'data-export',
                    name: 'producerDataExport',
                    component: ProducerDataExport,
                    meta: {
                        title: 'dataExport.title',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'competitive-analysis',
                    name: 'producerCompetitiveAnalysis',
                    component: ProducerCompetitiveAnalysis,
                    meta: {
                        title: 'producerSidebar.competitiveAnalysis',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
                {
                    path: 'campaign-analysis',
                    name: 'producerCampaignAnalysis',
                    component: ProducerCampaignAnalysis,
                    meta: {
                        title: 'producerSidebar.campaignAnalysis',
                        isSecure: true,
                        roles: ['ROLE_PRODUCER'],
                    },
                },
            ],
        },
        // {
        //     path: '/generator/:id/:token',
        //     name: 'WidgetGenerator',
        //     component: WidgetGenerator,
        //     props: true,
        // },
        // {
        //     path: '/preview',
        //     name: 'WidgetGeneratorPreview',
        //     component: WidgetGeneratorPreview,
        // },
        {
            path: '*',
            component: StatusPage404,
            meta: {
                title: 'status404.title',
            },
        },
    ],
});

router.beforeEach(hasAccessGuard);
router.afterEach(to => {
    const brand = '100shoppers';
    if (to.meta.title) {
        document.title = `${i18n.t(to.meta.title)} | ${brand}`;
    } else {
        document.title = brand;
    }
    if (usercomService.isUserComAvailable()) usercomService.pageHit(usercomService.getUserDataFromUser(store.getters['auth/getUser']));
});

router.afterEach(to => {
    if (window.ga) {
        window.ga('set', 'page', to.path);
        window.ga('send', 'pageview');
    }
});

export default router;
