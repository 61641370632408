import demoService from '@/services/demoService';
import spaceServiceDemo from '@/services/demo-services/spaceServiceDemo';
import usercomService from '@/services/usercomService';
import apiService from './apiService';
import errorService from './errorService';
import store from '../store/store';

const permissionsMap = {
    STATS_AVAILABILITY: 'stats_availability',
    STATS_PRICE_DISTRIBUTION: 'stats_price_distribution',
    STATS_SALES: 'stats_sales',
    STATS_CATEGORIES: 'stats_categories',
    STATS_PRODUCTS: 'stats_products',
    STATS_SHOPS: 'stats_shops',
    COMPETITIVE_ANALYSIS: 'stats_competition_report',
    CAMPAIGN_ANALYSIS: 'campaign_analysis',
};

const spaceService = {
    permissions: permissionsMap,

    demoSpace: {
        publisher: 'DEMO',
        categories: [
            {
                id: 6,
                name: 'Electronics',
                isActive: true,
                dateCreate: '2016-02-09T00:00:00+00:00',
                dateUpdate: '2016-02-09T00:00:00+00:00',
            },
        ],
        id: 1,
        name: 'DEMO',
        url: 'http://100shoppers.com',
        description: '',
        type: '1',
        status: '2',
        createdAt: '2016-10-28T12:00:53+00:00',
        isDelete: false,
        trackingFlag: true,
        permissions: Object.values(permissionsMap),
    },

    /**
     * Check if user has access to given resource
     * @param {string} permissionName - Name of permission
     * @return {boolean}
     */
    hasPermission(permissionName) {
        // if no permission name is not provided then resource is available
        if (!permissionName) {
            return true;
        }
        const permissions = store.getters['space/currentSpacePermissions'];
        // if there are no permissions available then resource is not available
        if (!permissions && permissions.length === 0) {
            return false;
        }
        // check if resource is in permissions list
        return permissions.includes(permissionName);
    },

    async fetchSpaces(publisherId, data) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/publishers/${publisherId}/spaces`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createSpace(publisherId, data) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/publishers/${publisherId}/spaces`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateSpace(spaceId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAllowedCampaigns(spaceId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/allowed-campaigns`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProducerProductCategories(spaceId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-product-categories`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async activatePermission(spaceId, permission) {
        try {
            if (permission === permissionsMap.CAMPAIGN_ANALYSIS) {
                usercomService.userengage('event.platne-raporty', { 'raport-type': 'Sprzedaż' });
                return true;
            }
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/subscriptions`,
                {
                    permission,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deactivatePermission(subscriptionId) {
        try {
            const response = await apiService.put(
                `${process.env.VUE_APP_API_URL}/api/v1/subscriptions/${subscriptionId}/cancel`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchPermissions(producerId, statuses) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producers/${producerId}/subscriptions`,
                {
                    statuses,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    getStatusLabelType(statusId) {
        switch (Number.parseInt(statusId, 10)) {
            case 1:
                return 'info';
            case 2:
                return 'success';
            case 3:
                return 'error';
            default:
                return null;
        }
    },

    getPermissionStatusLabelType(status) {
        switch (status) {
            case 'active':
                return 'success';
            case 'canceled':
                return 'warning';
            case 'inactive':
                return 'default';
            default:
                return null;
        }
    },

    getPermissionContentKey(permission) {
        switch (permission) {
            case 'stats_availability':
                return {
                    title: 'permissionContent.title.reportsAvailability',
                    description: 'permissionContent.description.reportsAvailability',
                    url: 'producerReportsAvailability',
                };
            case 'stats_price_distribution':
                return {
                    title: 'permissionContent.title.reportsPriceDistribution',
                    description: 'permissionContent.description.reportsPriceDistribution',
                    // url: 'producerReportsPriceDistribution',
                };
            case 'stats_sales':
                return {
                    title: 'permissionContent.title.reportsSales',
                    description: 'permissionContent.description.reportsSales',
                    url: 'producerReportsSales',
                };
            case 'stats_categories':
                return {
                    title: 'permissionContent.title.reportsCategories',
                    description: 'permissionContent.description.reportsCategories',
                    url: 'producerReportsCategory',
                };
            case 'stats_products':
                return {
                    title: 'permissionContent.title.reportsProducts',
                    description: 'permissionContent.description.reportsProducts',
                    url: 'producerReportsProducts',
                };
            case 'stats_shops':
                return {
                    title: 'permissionContent.title.reportsShops',
                    description: 'permissionContent.description.reportsShops',
                    url: 'producerReportsShops',
                };
            default:
                return {};
        }
    },

    async fetchUniqueSources(spaceId, startDate, endDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/unique-sources`,
                { startDate, endDate },
            );
            return response.data?.items;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(spaceService, spaceServiceDemo, 'spaceService');
export default spaceService;
