import { render, staticRenderFns } from "./ProducerMain.vue?vue&type=template&id=dc376de6&scoped=true&"
import script from "./ProducerMain.vue?vue&type=script&lang=js&"
export * from "./ProducerMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc376de6",
  null
  
)

export default component.exports