<template>
    <div class="grey lighten-4" :class="{'graph-container--expanded': value}">
        <div v-if="value" class="graph-container__toolbar">
            <v-btn
                type="button"
                color="tertiary"
                @click="exitFullScreen"
                flat
            >
                <v-icon class="mr-2" small>fa-arrow-left</v-icon>
                {{ $t('graph.exitFullScreen') }}
            </v-btn>
            <span v-if="title" class="graph-container__title">{{ title }}</span>
        </div>
        <div class="graph-container__graph">
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: 'graph-container',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
        },
    },
    methods: {
        exitFullScreen() {
            this.$emit('input', false);
        },
    },
};
</script>

<style lang="scss" scoped>
    .graph-container--expanded {
        position: fixed;
        overflow: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;

        .graph-container__graph {
            position: absolute;
            top: 60px;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .graph-container__toolbar {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .graph-container__title {
        padding-left: 20px;
        border-left: 1px solid $text-dark-color;
        font-weight: 700;
    }
</style>
