<template>
    <div class="v-menu-wrapper">
        <v-menu
            transition="scale-transition"
            origin="top right"
            bottom
            left
        >
            <template #activator="{ on }">
                <v-btn small flat icon v-on="on" @click.native.stop.prevent>
                    <v-icon small>fa-search</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-text>
                    <template v-if="items.length">
                        <div v-for="item in items" :key="item.campaign">
                            <strong>{{ item.campaign }}</strong>
                            <ul>
                                <li v-for="(segment, index) in item.segments" :key="`${item.campaign}-${index}`">{{ segment.start }} - {{ segment.end }}</li>
                            </ul>
                        </div>
                    </template>
                    <div v-else>
                        {{ $t('na') }}
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>

import { upperFirst } from 'lodash';

export default {
    name: 'competition-price-details',
    props: {
        priceType: {
            validator: value => ['min', 'max'].includes(value),
        },
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        items() {
            return this.product.custom[`price${upperFirst(this.priceType)}Details`] ?? [];
        },
    },
};
</script>

<style lang="scss" scoped>
.v-menu-wrapper {
    flex-grow: 0 !important;
}
</style>
