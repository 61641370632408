import Vue from 'vue';
import { orderBy } from 'lodash';
import dataExportService from '../../services/dataExportService';
import {
    CLEAN_EXPORT_REPORTS,
    FETCH_EXPORT_REPORTS_FAILURE,
    FETCH_EXPORT_REPORTS_REQUEST,
    FETCH_EXPORT_REPORTS_SUCCESS,
    GENERATE_EXPORT_REPORT_FAILURE,
    GENERATE_EXPORT_REPORT_REQUEST,
    GENERATE_EXPORT_REPORT_SUCCESS,
    FETCH_EXPORT_REPORT_SUCCESS,
    DELETE_EXPORT_REPORT_REQUEST,
    DELETE_EXPORT_REPORT_SUCCESS,
    DELETE_EXPORT_REPORT_FAILURE,
    FETCH_EXPORT_REPORT_FAILURE,
} from '../mutationTypes';

const sortFromNewest = array => orderBy(array, ['year', 'month'], ['desc', 'desc']);

const updateOrPushReport = (state, item) => {
    const existingItemIndex = state.reports.findIndex(report => report.id === item.id);
    if (existingItemIndex !== -1) {
        Vue.set(state.reports, existingItemIndex, item);
    } else {
        state.reports.push(item);
    }
};

const initialState = {
    reports: [],
    loading: {
        generateRequest: false,
        reports: false,
    },
};

const getters = {
    getReports: state => sortFromNewest(state.reports),
    getPendingReports: state => state.reports.filter(report => report.status.toUpperCase() === 'PENDING' || report.status.toUpperCase() === 'GENERATING'),
};

const mutations = {
    [CLEAN_EXPORT_REPORTS](state) {
        state.reports = [];
        state.loading = {
            generateRequest: false,
            reports: false,
        };
    },
    [FETCH_EXPORT_REPORTS_REQUEST](state) {
        state.loading.reports = true;
    },
    [FETCH_EXPORT_REPORTS_SUCCESS](state, payload) {
        state.reports = payload;
        state.loading.reports = false;
    },
    [FETCH_EXPORT_REPORTS_FAILURE](state) {
        state.loading.reports = false;
    },
    [GENERATE_EXPORT_REPORT_REQUEST](state) {
        state.loading.generateRequest = true;
    },
    [GENERATE_EXPORT_REPORT_SUCCESS](state, item) {
        updateOrPushReport(state, item);
        state.loading.generateRequest = false;
    },
    [GENERATE_EXPORT_REPORT_FAILURE](state) {
        state.loading.generateRequest = false;
    },
    [FETCH_EXPORT_REPORT_SUCCESS](state, item) {
        updateOrPushReport(state, item);
    },
    [FETCH_EXPORT_REPORT_FAILURE]() {},
    [DELETE_EXPORT_REPORT_REQUEST]() {},
    [DELETE_EXPORT_REPORT_SUCCESS](state, reportId) {
        state.reports = state.reports.filter(report => report.id !== reportId);
    },
    [DELETE_EXPORT_REPORT_FAILURE]() {},
};

const actions = {
    clean({ commit }) {
        commit(CLEAN_EXPORT_REPORTS);
    },

    async generateReport({ commit, rootState }, { year, month }) {
        try {
            commit(GENERATE_EXPORT_REPORT_REQUEST);

            const spaceId = rootState.space.currentSpaceId;
            const region = process.env.VUE_APP_REGION_MODE.toLowerCase();

            const item = await dataExportService.generateReport(spaceId, region, year, month);

            commit(GENERATE_EXPORT_REPORT_SUCCESS, item);
        } catch (e) {
            commit(GENERATE_EXPORT_REPORT_FAILURE);
            throw e;
        }
    },

    async fetchReports({ commit, rootState }) {
        try {
            commit(FETCH_EXPORT_REPORTS_REQUEST);
            const spaceId = rootState.space.currentSpaceId;
            const { items } = await dataExportService.fetchReports(spaceId);
            commit(FETCH_EXPORT_REPORTS_SUCCESS, items);
        } catch (e) {
            commit(FETCH_EXPORT_REPORTS_FAILURE);
            throw e;
        }
    },

    async fetchReport({ commit }, reportId) {
        try {
            const item = await dataExportService.fetchReport(reportId);
            commit(FETCH_EXPORT_REPORT_SUCCESS, item);
        } catch (e) {
            commit(FETCH_EXPORT_REPORT_FAILURE);
            throw e;
        }
    },

    async deleteReport({ commit }, reportId) {
        try {
            commit(DELETE_EXPORT_REPORT_REQUEST);
            await dataExportService.deleteReport(reportId);
            commit(DELETE_EXPORT_REPORT_SUCCESS, reportId);
        } catch (e) {
            commit(DELETE_EXPORT_REPORT_FAILURE);
            throw e;
        }
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
