<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)"  ga-page="Raport Dostępności" />
            </template>
        </page-header-filters>
        <no-access-placeholder
            v-if="!hasPermission && !spacesLoading"
            :permission-name="permissionName"
            :title="$t('permissionContent.title.reportsAvailability')"
            :description="$t('permissionContent.description.reportsAvailability')"
            :image="require('../../assets/noAccessPlaceholders/reports-availability.jpg')"
            cta-position="top"
            @activated="getData"
        />
        <v-card v-if="hasPermission" class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('producerReportsAvailability.noSpace')" />

                <div v-if="!loading && spaceId">
                    <availability-stats :date-filter="dateFilter" :campaign="campaign" />
                    <availability-analysis
                        :date-filter="dateFilter"
                        :campaign="campaign"
                        @filterDefaultChange="handleFilterDefaultChange('availabilityAnalysis', $event)"
                        @campaignChange="handleCampaignChange"
                    />
                    <current-availability :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('currentAvailability', $event)" />
                </div>
            </v-card-text>

        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import spaceService from '@/services/spaceService';
import permissionMixin from '@/mixins/permissionMixin';
import PageHeaderFilters from '@/components/common/PageHeaderFilters.vue';
import SpaceSwitch from '@/components/common/SpaceSwitch.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import DataPlaceholder from '@/components/common/DataPlaceholder.vue';
import ResetFiltersButton from '@/components/common/ResetFiltersButton.vue';
import NoAccessPlaceholder from '@/components/common/NoAccessPlaceholder.vue';
import AvailabilityStats from '@/components/producer/reports/availability/AvailabilityStats.vue';
import AvailabilityAnalysis from '@/components/producer/reports/availability/AvailabilityAnalysis.vue';
import CurrentAvailability from '@/components/producer/reports/availability/CurrentAvailability.vue';

export default {
    name: 'producer-reports-availability',
    components: {
        CurrentAvailability,
        AvailabilityAnalysis,
        AvailabilityStats,
        NoAccessPlaceholder,
        ResetFiltersButton,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
    },
    mixins: [
        permissionMixin,
    ],
    data() {
        return {
            permissionName: spaceService.permissions.STATS_AVAILABILITY,
            spaceInitialized: false,
            loadingConfig: false,
            error: null,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            isDatepickerFilterDefault: true,
            isAvailabilityAnalysisFilterDefault: true,
            isCurrentAvailabilityFilterDefault: true,
            campaign: [],
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isAvailabilityAnalysisFilterDefault || !this.isCurrentAvailabilityFilterDefault;
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaceId: state => state.space.currentSpaceId,
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            const valueChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue);
            if (this.spaceInitialized && valueChanged) this.getData();
        },
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'availabilityAnalysis') {
                this.isAvailabilityAnalysisFilterDefault = isDefault;
            } else if (type === 'currentAvailability') {
                this.isCurrentAvailabilityFilterDefault = isDefault;
            }
        },
        handleCampaignChange(campaign) {
            this.campaign = campaign;
        },
        async getData() {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) {
                return;
            }
            this.loadingConfig = true;
            await Promise.all([
                this.getCategories(),
                this.getCampaigns(),
                this.getGroups(),
            ]);
            this.loadingConfig = false;
        },
        async getCategories() {
            try {
                await this.fetchProducerProductCategories();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getGroups() {
            try {
                this.error = null;
                await this.fetchGroups();
            } catch (e) {
                this.error = e.message;
            }
        },
        handleSpaceChange() {
            this.getData();

            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
        },
        ...mapActions({
            fetchProducerProductCategories: 'space/fetchProducerProductCategories',
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
            fetchGroups: 'producerProductGroup/fetchGroups',
        }),
    },
};
</script>

<style lang="scss">
</style>
