<template>
    <div>
        <div v-if="!isReady" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <GChart
            ref="chartContainer"
            type="CandlestickChart"
            :settings="{ packages: ['corechart'], language: $i18n.locale }"
            :data="data"
            :options="chartOptions"
            :events="chartEvents"
            @ready="onChartReady"
        />
    </div>
</template>

<script>
import { merge } from 'lodash';
import { GChart } from 'vue-google-charts';
import exportService from '@/services/exportService';

export default {
    name: 'candlestick-chart-graph',
    components: {
        GChart,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        styleTextNode: {
            type: Function,
        },
    },
    data() {
        return {
            baseOptions: {
                legend: 'none',
                vAxis: {
                    format: 'short',
                },
                chartArea: {
                    top: 35,
                    left: 75,
                    right: 50,
                    width: '90%',
                    height: '65%',
                },
                backgroundColor: '#f5f5f5',
                selectionMode: 'single',
                tooltip: {
                    trigger: 'hover',
                },
                candlestick: {
                    risingColor: {
                        strokeWidth: 0,
                        fill: this.$vuetify.theme.primary,
                    },
                    fallingColor: {
                        strokeWidth: 0,
                        fill: this.$vuetify.theme.secondary,
                    },
                },
                series: [
                    { color: 'gray' },
                ],
            },
            chartEvents: {
                ready: () => {
                    this.isReady = true;
                    this.setSeriesColor();
                    this.setXAxisLabelStyle();
                },
                onmouseover: () => {
                    this.setSeriesColor();
                },
                onmouseout: () => {
                    this.setSeriesColor();
                },
            },
            isReady: false,
            chart: null,
            google: null,
        };
    },
    computed: {
        chartOptions() {
            const baseHeight = this.options.height ? this.options.height : 300;
            const height = this.expanded ? window.outerHeight - 60 : baseHeight;
            return merge(this.baseOptions, this.options, { height });
        },
    },
    methods: {
        onChartReady(chart, google) {
            this.chart = chart;
            this.google = google;
        },
        setSeriesColor() {
            /* eslint-disable no-param-reassign */
            // google charts doesn't allow setting series' color (vertical lines showing start/end values) based on rising or failing state
            const fallingCandles = this.chart.container.querySelectorAll(`rect[fill="${this.$vuetify.theme.secondary}"]`);
            fallingCandles.forEach(node => {
                node.previousSibling.style.fill = this.$vuetify.theme.secondary;
                node.previousSibling.style.width = '10px';
            });
            const risingCandles = this.chart.container.querySelectorAll(`rect[fill="${this.$vuetify.theme.primary}"]`);
            risingCandles.forEach(node => {
                node.previousSibling.style.fill = this.$vuetify.theme.primary;
                node.previousSibling.style.width = '10px';
            });
            /* eslint-enable no-param-reassign */
        },
        setXAxisLabelStyle() {
            if (typeof this.styleTextNode === 'function') {
                const nodes = this.chart.container.querySelectorAll('text');
                nodes.forEach(node => this.styleTextNode(node));
            }
        },
        exportData(title = 'export') {
            exportService.exportDataImage(this.chart.getImageURI(), title);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
