<template>
    <base-layout>
        <v-container fluid fill-height class="error-404">
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md5>
                    <v-card class="elevation-1 pa-4">
                        <v-card-text class="text-xs-left">
                            <h5 class="mb-4 headline font-weight-thin">
                                {{ $t('status404.title') }}
                            </h5>
                            <p>
                                {{ $t('status404.description') }}
                            </p>
                        </v-card-text>
                        <v-card-actions class="justify-center action-buttons">
                            <v-btn
                                :to="homeUrl"
                                color="primary"
                                class="mb-2"
                            >
                                {{ $t('status404.goHome') }}
                            </v-btn>
                            <v-btn
                                v-if="!isAuthenticated"
                                :to="{ name: 'login' }"
                                color="primary"
                                class="mb-2"
                            >
                                {{ $t('status404.login') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <p class="font-weight-bold grey--text text--darken-1 mt-2 pa-2 text-xs-center">
                        <language-switch v-if="!isAuthenticated" />
                    </p>
                </v-flex>
            </v-layout>
        </v-container>
    </base-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseLayout from '../components/layout/BaseLayout.vue';
import LanguageSwitch from '../components/common/LanguageSwitch.vue';

export default {
    name: 'status-page-404',
    components: {
        LanguageSwitch,
        BaseLayout,
    },
    computed: {
        homeUrl() {
            if (this.user && this.user.roles && this.user.roles.includes('ROLE_PRODUCER')) {
                return { name: 'producer' };
            }
            return '/';
        },
        ...mapState({
            user: (state) => state.auth.user,
        }),
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .action-buttons {
        flex-wrap: wrap;
    }
</style>
