<template>
    <base-layout>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md5>
                    <v-card class="elevation-0 pa-4">
                        <v-card-text class="text-xs-left">
                            <h3 class="mb-4 title font-weight-bold">{{ $t('confirmEmail.title') }}</h3>

                            <div v-if="loading" class="text-xs-center">
                                <v-progress-circular indeterminate color="primary" />
                            </div>

                            <v-alert
                                :value="error"
                                dismissible
                                type="error"
                                transition="scale-transition">
                                {{error}}
                            </v-alert>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </base-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseLayout from '../components/layout/BaseLayout.vue';

export default {
    name: 'confirm-email',
    components: {
        BaseLayout,
    },
    props: {
        token: String,
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        ...mapState({
            loading: state => state.auth.loading.confirmEmail,
        }),
    },
    created() {
        if (this.token) {
            this.submit();
        } else {
            this.error = this.$t('confirmEmail.missingToken');
        }
    },
    methods: {
        async submit() {
            try {
                this.error = null;
                await this.confirmEmail({
                    token: this.token,
                });
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            confirmEmail: 'auth/confirmEmail',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
