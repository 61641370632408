<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <img
                v-bind="attrs"
                v-on="on"
                class="v-input mr-3 mb-3"
                style="cursor:pointer;"
                :src="require('../../assets/noAccessPlaceholders/source-input.jpg')"
                @click="$root.$emit('open-no-access-contact-form')">
        </template>
        <span v-html="$t('subscriptionNotIncludeSourceAnalytics')"></span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'FormSourcesPlaceholder',
};
</script>
