<template>
    <div class="sort-order">
        <v-icon class="sort-order__arrow" :class="{'sort-order__arrow--asc': direction === 'asc'}" small>fa-angle-up</v-icon>
        <v-icon class="sort-order__arrow" :class="{'sort-order__arrow--desc': direction === 'desc'}" small>fa-angle-down</v-icon>
    </div>
</template>

<script>
export default {
    name: 'sort-order',
    props: {
        direction: {
            validator: value => ['asc', 'desc', null].includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
    .sort-order {
        display: inline-flex;
        flex-direction: column;
        margin-left: 10px;
    }

    .sort-order__arrow {
        opacity: .25 !important;
        color: #000000 !important;
    }

    .sort-order__arrow--asc,
    .sort-order__arrow--desc {
        opacity: 1 !important;;
    }
</style>
