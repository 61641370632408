<template>
    <v-card v-if="!spaceService.hasPermission(permissionName)" elevation="5" class="source-no-permissions">
        <v-card-title>
            <span v-html="textFirstLine"></span>.
            <br>
            <span v-html="textSecondLine"></span>.
        </v-card-title>
        <div>
            <v-text-field v-model="contactData.email" :label="$t('yourEmail')" outline />
            <v-text-field v-model="contactData.phone" :label="$t('yourPhoneNumber')" outline />
        </div>
        <v-card-actions>
            <v-btn round color="tertiary" dark class="ma-auto" @click="contactSalesDepartment()">
                {{ $t('pleaseContact') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import spaceService from '@/services/spaceService';
import usercomService from '@/services/usercomService';
import { i18n } from '@/i18n/i18n';

export default {
    name: 'ActivateReportContactForm',
    props: {
        permissionName: {
            type: String,
            required: true,
        },
        textFirstLine: {
            type: String,
            default: i18n.t('yourSubscriptionDoesNotIncludeThisSection'),
        },
        textSecondLine: {
            type: String,
            default: i18n.t('leaveUsYourDetailsAndWeWillContactYou'),
        },
        usercomReportType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            spaceService,
            contactData: {
                email: this.$store.state.auth.user.email,
                phone: this.$store.state.auth.user.phone,
            },
        };
    },
    methods: {
        contactSalesDepartment() {
            if (this.usercomReportType) usercomService.userengage('event.platne-raporty', { 'raport-type': this.usercomReportType, email: this.contactData.email, telefon: this.contactData.phone });
            this.$toast.success(this.$t('pleaseContactSent'));
        },
    },
};
</script>

<style lang="scss" scoped>
.source-no-permissions {
    padding: 15px 20px;
}
</style>
