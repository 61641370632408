/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import store from '../store/store';
import { i18n } from '../i18n/i18n';

export default {
    demoProxy(originalObject, demoObject, objectName) {
        const label = objectName ? `Demo Proxy of ${objectName}` : 'Demo Proxy';

        for (const property in originalObject) {
            if (originalObject.hasOwnProperty(property)) {
                if (!demoObject.hasOwnProperty(property)) {
                    throw new Error(
                        `${label} - property "${property}" is defined in the original object but not in the demo object.
Both of these object must have exactly the same properties.
In order to always use the original property and not mock it, assign demoService.USE_ORIGINAL_PROPERTY as its demo value.`,
                    );
                }

                const originalProperty = originalObject[property];
                const demoProperty = demoObject[property];

                if (demoProperty === this.USE_ORIGINAL_PROPERTY) {
                    // eslint-disable-next-line no-continue
                    continue;
                }

                if (typeof originalProperty === 'function' && typeof demoProperty === 'function') {
                    if (originalProperty.length !== demoProperty.length) {
                        throw new Error(
                            `${label} - method "${property}" in original and demo objects has different number of arguments.
For the sake of consistency they should have exactly the same number of arguments.`,
                        );
                    }
                }

                Object.defineProperty(originalObject, property, {
                    get() {
                        if (store.getters['space/isDemo']) {
                            return demoProperty;
                        }
                        return originalProperty;
                    },
                });
            }
        }
    },

    USE_ORIGINAL_PROPERTY: Symbol('Use original prop for demo'),

    get unavailableInDemoMsg() {
        return i18n.t('error.unavailableInDemo');
    },
};
