<template>
    <v-layout row wrap>
        <v-flex xs12>
            <v-alert
                :value="!pluginHasCampaignPriority"
                type="error"
                transition="scale-transition">
                {{ $t('pluginExtension.rotatingGroups.noPrioritiesWarning') }}
            </v-alert>
        </v-flex>
        <template v-if="pluginHasCampaignPriority">
            <v-flex xs12>
                <v-layout row>
                    <p class="caption">{{ $t('pluginExtension.rotatingGroups.extensionHint') }}</p>
                </v-layout>
                <v-layout row>
                    <v-flex xs12 sm6 class="extension-controls">
                        <v-btn
                            round
                            small
                            color="tertiary"
                            dark
                            @click="addGroupDefinition">{{ $t('pluginExtension.rotatingGroups.addGroup') }}</v-btn>
                    </v-flex>
                    <v-flex xs12 sm6 class="extension-controls">
                        <v-switch
                            :label="$t('pluginExtension.rotatingGroups.excludeNonStrategicCampaigns')"
                            color="accent"
                            v-model="excludeNonStrategicCampaigns"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12>
                <v-layout
                    row
                    class="groups-list-item"
                    align-center
                    v-for="(definition, index) in definitions"
                    :key="`group-definition-${index}`">
                    <v-flex xs2><span class="group-title">{{ $t('pluginExtension.rotatingGroups.nthGroup') }} {{ index + 1 }}</span></v-flex>
                    <v-flex xs4>
                        <v-text-field
                            outline
                            hide-details
                            type="number"
                            min="1"
                            step="1"
                            :label="$t('pluginExtension.rotatingGroups.positionFrom')"
                            :rules="[isValid]"
                            v-model="definition.from"
                            @change="rangeInputValueChange(index, 'from')"
                        />
                    </v-flex>
                    <v-flex xs4>
                        <v-text-field
                            outline
                            hide-details
                            type="number"
                            min="1"
                            step="1"
                            :label="$t('pluginExtension.rotatingGroups.positionTo')"
                            v-model="definition.to"
                            :readonly="definition.from === null || definition.from === ''"
                            @change="rangeInputValueChange(index, 'to')"
                        />
                    </v-flex>
                    <v-flex xs2>
                        <v-btn
                            color="error"
                            round
                            small
                            @click="removeGroupDefinition(index)">{{ $t('pluginExtension.rotatingGroups.removeGroup') }}</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>
import { cloneDeep, find } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    name: 'RotatingGroupsExtension',
    props: {
        settings: {
            type: Object,
            default: () => ({}),
        },
    },
    mounted() {
        this.loadSettings();
    },
    computed: {
        ...mapGetters({
            activeCampaigns: 'plugin/activeCampaigns',
        }),
        pluginHasCampaignPriority() {
            return !!find(this.activeCampaigns, campaign => campaign.priority > 0);
        },
    },
    data() {
        return {
            excludeNonStrategicCampaigns: false,
            definitions: [],
        };
    },
    methods: {
        loadSettings() {
            if (this.settings !== null) {
                this.excludeNonStrategicCampaigns = this.settings.excludeNonStrategicCampaigns || this.excludeNonStrategicCampaigns;
                this.definitions = this.settings.definitions ? cloneDeep(this.settings.definitions) : [];
            }
        },
        addGroupDefinition() {
            this.definitions.push({
                from: null,
                to: null,
            });
        },
        removeGroupDefinition(index) {
            this.$delete(this.definitions, index);
        },
        rangeInputValueChange(index, parameter) {
            let value = parseInt(this.definitions[index][parameter], 10);
            value = (Number.isNaN(value) || value < 1) ? 1 : value;
            if (parameter === 'to' && value < this.definitions[index].from) {
                value = this.definitions[index].from;
            }
            this.$set(this.definitions, index, Object.assign({}, this.definitions[index], { [parameter]: value }));
        },
        isValid(value) {
            return !(Number.isNaN(value) || value < 1);
        },
        getExtensionSettings() {
            return {
                excludeNonStrategicCampaigns: this.excludeNonStrategicCampaigns,
                definitions: this.definitions,
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .extension-controls {
        padding-bottom: 20px;
        .v-btn {
            padding: 0 20px;
        }

        .v-input--selection-controls {
            margin-top: 0;
        }
    }
    .groups-list-item {
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #e8e8e8;
        min-height: 70px;
        .v-input {
            padding: 0 10px 0 10px;
        }
        .group-title {
            font-weight: bold;
        }
    }
</style>
