import demoService from '@/services/demoService';
import reportsSalesServiceDemo from '@/services/demo-services/reportsSalesServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const reportsSalesService = {
    async fetchSalesChart(spaceId, startDate, endDate, groupBy, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-chart`,
                {
                    startDate,
                    endDate,
                    groupBy,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAllSalesChart(spaceId, startDate, endDate, groupBy, sources, group, feature, conversionTime) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/all-sales-chart`,
                {
                    startDate,
                    endDate,
                    groupBy,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                    feature,
                    conversionTime,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSummary(spaceId, startDate, endDate, conversion, campaigns, groupBy, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-summary`,
                {
                    startDate,
                    endDate,
                    conversionTime: conversion,
                    campaigns,
                    groupBy,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductDetails(spaceId, startDate, endDate, conversion, campaigns, sources, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-transactions-items`,
                {
                    startDate,
                    endDate,
                    conversionTime: conversion,
                    campaigns,
                    ...(sources?.length ? { sources } : {}),
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchTransactions(spaceId, startDate, endDate, conversion, campaigns, page, perPage, sort, order, group) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-transactions`,
                {
                    from: startDate,
                    to: endDate,
                    conversionTime: conversion,
                    campaigns,
                    page,
                    perPage,
                    sort,
                    order: sort ? order : null,
                    ...(group ? { groups: group } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchFeatureBySourceChart(spaceId, startDate, endDate, sources, feature = 'amount', category, group, products) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/feature-by-source-chart`,
                {
                    startDate,
                    endDate,
                    feature,
                    ...(sources?.length ? { sources } : {}),
                    ...(category ? { categories: category } : {}),
                    ...(group ? { groups: group } : {}),
                    ...(products ? { products } : {}),
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(reportsSalesService, reportsSalesServiceDemo, 'reportsSalesService');
export default reportsSalesService;
