<template>
    <sales-graph
        :data="data"
        :loading="loading"
        :error="error"
        @redo="redo"
    >
        <template #filters>
            <v-flex shrink>
                <select-all
                    v-if="region !== 'PL'"
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="campaign"
                    :items="campaignOptions"
                    :disabled="loading"
                    :label="$t('productsSales.shop')"
                    :all-label="$t('productsSales.allShops')"
                    require-selection
                    @blur="handleCampaignBlur"
                />
            </v-flex>
            <v-flex shrink>
                <select-all
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="category"
                    :items="categoryOptions"
                    :itemsTree="categoryOptionsTree"
                    :disabled="loading"
                    :label="$t('productsSales.category')"
                    :all-label="$t('productsSales.allCategories')"
                    require-selection
                    @blur="handleCategoryBlur"
                />
            </v-flex>
        </template>
    </sales-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import SalesGraph from '../../../graphs/SalesGraph.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';
import categoryFilterMixin from '../../../../mixins/categoryFilterMixin';

export default {
    name: 'products-sales',
    components: {
        SelectAll,
        SalesGraph,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            region: process.env.VUE_APP_REGION_MODE,
            data: null,
            error: null,
        };
    },
    computed: {
        loading() {
            return this.salesChartLoading;
        },
        ...mapState({
            salesChartLoading: state => state.reportsProducts.loading.salesChart,
        }),
        ...mapGetters({
            getterSalesChart: 'reportsProducts/getSalesChart',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.setDefaultCategory();
        this.setDefaultCampaign(['PL']);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign(['PL']);
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.isDefaultCategory() && this.isDefaultCampaign(['PL']);
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getSalesChart(force);
        },
        setDefaultCategory() {
            this.category = this.getterCategoryOptions.map(category => category.value);
        },
        handleCategoryBlur() {
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        async getSalesChart(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                await this.fetchSalesChart({
                    force,
                    startDate,
                    endDate,
                    campaign: this.campaign,
                    category: this.category,
                });
                this.data = this.getterSalesChart(startDate, endDate, this.campaign, this.category);
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchSalesChart: 'reportsProducts/fetchSalesChart',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
