import apiService from './apiService';
import errorService from './errorService';

export default {
    async fetchCategories() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/categories`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSpaceTypes() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/space-types`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSpaceStatuses() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/space-statuses`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};
