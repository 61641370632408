import {
    filterProductAvailabilityByCampaignId,
    filterSegmentDates,
    generateSegmentsDates,
} from '@/services/demo-services/_utils';
import demoService from '../demoService';

export default {
    async fetchProductAvailability(entityId, startDate, endDate, campaigns) {
        const { default: producerProductsAvailabilityChart } = await import(`../../mocks/producerProductsAvailabilityChart${entityId}.json`);
        return filterSegmentDates(generateSegmentsDates(filterProductAvailabilityByCampaignId(producerProductsAvailabilityChart, campaigns)));
    },

    // eslint-disable-next-line no-unused-vars
    async fetchAvailabilityCharts(spaceId, startDate, endDate, campaigns) {
        // TODO: create proper mock
        // const { default: producerProductsAvailabilityChart } = await import(`../../mocks/producerAvailabilityCharts.json`);
        // return producerProductsAvailabilityChart;
        return {};
    },

    // eslint-disable-next-line no-unused-vars
    async fetchCurrentUnavailableProducts(spaceId, campaigns) {
        // TODO: create proper mock
        return {};
    },

    // eslint-disable-next-line no-unused-vars
    async fetchCurrentProductsAvailability(spaceId, campaigns) {
        // TODO: create proper mock
        return {};
    },

    generateAvailabilityReport: demoService.USE_ORIGINAL_PROPERTY,
    fetchAvailabilityReports: demoService.USE_ORIGINAL_PROPERTY,
    downloadAvailabilityReport: demoService.USE_ORIGINAL_PROPERTY,
};
