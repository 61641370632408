import {
    sortBy,
} from 'lodash';
import reportsPriceDistributionService from '../../services/reportsPriceDistributionService';
import utilsService from '../../services/utilsService';
import {
    CLEAN_REPORTS_PRICE_DISTRIBUTION,
    FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_REQUEST,
    FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_SUCCESS,
    FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_FAILURE,
    FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_REQUEST,
    FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_SUCCESS,
    FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_FAILURE,
    GENERATE_PRICE_EXPORT_REPORT_REQUEST,
    GENERATE_PRICE_EXPORT_REPORT_SUCCESS,
    GENERATE_PRICE_EXPORT_REPORT_FAILURE,
    FETCH_PRICE_EXPORT_REPORTS_REQUEST,
    FETCH_PRICE_EXPORT_REPORTS_SUCCESS,
    FETCH_PRICE_EXPORT_REPORTS_FAILURE,
} from '../mutationTypes';
import dateService from '../../services/dateService';

const initialState = {
    productPrices: {},
    averagePrice: {},
    productsAggregatedReport: {},
    averagePriceOverTime: {},
    quantityOverTime: {},
    producerTransactionsItemsQuantityPrice: {},
    loading: {
        productPrices: false,
        averagePrice: false,
        productsAggregatedReport: false,
        averagePriceOverTime: false,
        quantityOverTime: false,
        producerTransactionsItemsQuantityPrice: false,
    },
    priceExportReports: [],
};

const getters = {
    getProductPrices: state => (startDate, endDate, campaign, category, group, products) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products);
        return state.productPrices[storageKey];
    },
    getAveragePrice: state => (startDate, endDate, campaign, category, group, products) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products);
        return state.averagePrice[storageKey];
    },
    getProductsAggregatedReport: state => (startDate, endDate, groupBy, campaign, category, group, products) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, groupBy, ...campaign, ...category, ...group, ...products);
        return state.productsAggregatedReport[storageKey];
    },
    getAveragePriceOverTime: state => (startDate, endDate, campaign, category, group, products, period) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products, period);
        return state.averagePriceOverTime[storageKey];
    },
    getQuantityOverTime: state => (startDate, endDate, campaign, category, group, products) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products);
        return state.quantityOverTime[storageKey];
    },
    getProducerTransactionsItemsQuantityPrice: state => (startDate, endDate, campaign, category, group, products) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products);
        return state.producerTransactionsItemsQuantityPrice[storageKey];
    },
    getProductsPriceExport: state => state.priceExportReports,
    getPendingProductsPriceExport: state => state.priceExportReports.filter(report => report.status.toUpperCase() === 'PENDING' || report.status.toUpperCase() === 'GENERATING'),
};

const actions = {
    clean({ commit }) {
        commit(CLEAN_REPORTS_PRICE_DISTRIBUTION);
    },

    async fetchProductsPriceAggregatedReport({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, groupBy, campaign, category, group, products, entities,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !groupBy) {
                return;
            }

            campaign.sort();
            category.sort();
            group.sort();
            products.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, groupBy, ...campaign, ...category, ...group, ...products);
            if (!force && state.productsAggregatedReport && state.productsAggregatedReport[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const reqGroup = group.length > 0 ? group.join(',') : null;
            const reqProducts = entities.length > 0 ? entities.join(',') : null;
            const reportData = await reportsPriceDistributionService.fetchProductsPriceAggregatedReport(spaceId, startDate, endDate, groupBy, reqCampaign, reqCategory, reqGroup, reqProducts);

            commit(FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_SUCCESS, {
                reportData,
                startDate,
                endDate,
                groupBy,
                campaign,
                category,
                group,
                products,
            });
        } catch (e) {
            commit(FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_FAILURE);
            throw e;
        }
    },

    async fetchProducerTransactionsItemsQuantityPrice({
        commit, state, rootState,
    }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign, category, group, products, entities,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            group.sort();
            products.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products);
            if (!force && state.producerTransactionsItemsQuantityPrice && state.producerTransactionsItemsQuantityPrice[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const reqGroup = group.length > 0 ? group.join(',') : null;
            const reqProducts = entities.length > 0 ? entities.join(',') : null;
            const producerTransactionsItemsQuantityPriceData = await reportsPriceDistributionService.fetchProducerTransactionsItemsQuantityPrice(spaceId, startDate, endDate, reqCampaign, reqCategory, reqGroup, reqProducts);

            let chartData = [];
            const missingDates = dateService.findMissingDates(producerTransactionsItemsQuantityPriceData, startDate, endDate);
            chartData = utilsService.fillArray(producerTransactionsItemsQuantityPriceData, missingDates, 'date', [{ key: 'quantity', value: 0 }, { key: 'price', value: 0 }, { key: 'summary', value: [] }]);
            chartData = sortBy(chartData, ['date']);

            commit(FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_SUCCESS, {
                chartData,
                startDate,
                endDate,
                campaign,
                category,
                group,
                products,
            });
        } catch (e) {
            commit(FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_FAILURE);
            throw e;
        }
    },

    async generateProductsPriceExport({ commit, rootState }, payload = {}) {
        try {
            commit(GENERATE_PRICE_EXPORT_REPORT_REQUEST);
            const {
                startDate, endDate, compareStartDate, compareEndDate, campaign, category, group, products, entities,
            } = payload;

            const spaceId = rootState.space.currentSpaceId;
            const item = await reportsPriceDistributionService.generatePriceReport(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaign, category, group, products, entities);

            commit(GENERATE_PRICE_EXPORT_REPORT_SUCCESS, item);
        } catch (e) {
            commit(GENERATE_PRICE_EXPORT_REPORT_FAILURE);
            throw e;
        }
    },

    async fetchProductsPriceExport({ commit, rootState }, payload = {}) {
        try {
            commit(FETCH_PRICE_EXPORT_REPORTS_REQUEST);
            const spaceId = rootState.space.currentSpaceId;
            const { items } = await reportsPriceDistributionService.fetchPriceReports(spaceId, payload);
            commit(FETCH_PRICE_EXPORT_REPORTS_SUCCESS, items);
        } catch (e) {
            commit(FETCH_PRICE_EXPORT_REPORTS_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_REPORTS_PRICE_DISTRIBUTION
    // -----------------------------------------
    [CLEAN_REPORTS_PRICE_DISTRIBUTION](state) {
        state.productPrices = {};
        state.averagePrice = {};
        state.productsAggregatedReport = {};
        state.averagePriceOverTime = {};
        state.quantityOverTime = {};
        state.producerTransactionsItemsQuantityPrice = {};
        state.loading.productPrices = false;
        state.loading.averagePrice = false;
        state.loading.productsAggregatedReport = false;
        state.loading.averagePriceOverTime = false;
        state.loading.quantityOverTime = false;
        state.loading.producerTransactionsItemsQuantityPrice = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT
    // -----------------------------------------
    [FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_REQUEST](state) {
        state.loading.productsAggregatedReport = true;
    },
    [FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_SUCCESS](state, payload) {
        const {
            reportData, startDate, endDate, groupBy, campaign, category, group, products,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, groupBy, ...campaign, ...category, ...group, ...products);
        state.productsAggregatedReport = {
            ...state.productsAggregatedReport,
            [storageKey]: reportData,
        };
        state.loading.productsAggregatedReport = false;
    },
    [FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_FAILURE](state) {
        state.loading.productsAggregatedReport = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE
    // -----------------------------------------
    [FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_REQUEST](state) {
        state.loading.producerTransactionsItemsQuantityPrice = true;
    },
    [FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_SUCCESS](state, payload) {
        const {
            chartData, startDate, endDate, campaign, category, group, products,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category, ...group, ...products);
        state.producerTransactionsItemsQuantityPrice = {
            ...state.producerTransactionsItemsQuantityPrice,
            [storageKey]: chartData,
        };
        state.loading.producerTransactionsItemsQuantityPrice = false;
    },
    [FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_FAILURE](state) {
        state.loading.producerTransactionsItemsQuantityPrice = false;
    },

    [GENERATE_PRICE_EXPORT_REPORT_REQUEST](state) {
        state.loading.generateRequest = true;
    },
    [GENERATE_PRICE_EXPORT_REPORT_SUCCESS](state) {
        state.loading.generateRequest = false;
    },
    [GENERATE_PRICE_EXPORT_REPORT_FAILURE](state) {
        state.loading.generateRequest = false;
    },
    [FETCH_PRICE_EXPORT_REPORTS_REQUEST](state) {
        state.loading.fetchRequest = true;
    },
    [FETCH_PRICE_EXPORT_REPORTS_SUCCESS](state, payload) {
        state.loading.fetchRequest = false;
        state.priceExportReports = payload;
    },
    [FETCH_PRICE_EXPORT_REPORTS_FAILURE](state) {
        state.loading.fetchRequest = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
