var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 grey lighten-4"},[_c('v-card-title',[_c('div',[_c('h4',{staticClass:"subheading font-weight-bold"},[_vm._v(_vm._s(_vm.$t('categoryList')))])]),_c('v-spacer')],1),_c('v-card-text',[_c('v-alert',{attrs:{"value":_vm.error,"dismissible":"","type":"error","transition":"scale-transition"},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),(_vm.items.length)?_c('v-data-table',{staticClass:"elevation-0 table--transparent",attrs:{"headers":_vm.headers,"items":_vm.items,"no-data-text":_vm.$t('noResults'),"no-results-text":_vm.$t('noResults'),"rows-per-page-text":_vm.$t('table.rowsPerPage'),"rows-per-page-items":_vm.rowsPerPageItems,"pagination":_vm.pagination,"disable-initial-sort":"","sort-icon":"fa-angle-up","loading":_vm.loading},on:{"update:pagination":[function($event){_vm.pagination=$event},_vm.updatePagination]},scopedSlots:_vm._u([{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }))+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-xs-center"},[(_vm.loading)?_c('span',[_c('v-progress-circular',{staticClass:"ma-2",attrs:{"indeterminate":"","color":"primary"}})],1):_c('span',[_vm._v(_vm._s(_vm.$t('noResults')))])])]},proxy:true},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"width":"100px"}},[(_vm.loading)?_c('VueSkeleton',{attrs:{"type":"rect","rounded":"","height":16,"width":50}}):_c('span',[_vm._v(_vm._s(item.id))])],1),_c('td',[(_vm.loading)?_c('VueSkeleton',{attrs:{"type":"rect","rounded":"","height":16}}):_vm._e(),_c('v-treeview',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"tree",staticClass:"products-tree",attrs:{"items":_vm.treeItem(item),"return-object":"","load-children":_vm.fetchItems,"active-class":"tree-node--active","item-key":"id","item-text":"text","item-children":"children","loading-icon":"fa-circle-notch","transition":"","open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
var active = ref.active;
return [(item.type === 'category')?_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":active ? 'secondary' : '',"small":""}},[_vm._v(_vm._s(open ? 'fa-folder-open' : 'fa-folder'))]):_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":active ? 'secondary' : '',"small":""}},[_vm._v("fa-file")])]}}],null,true)})],1),_c('td',{staticStyle:{"width":"50px"}},[(_vm.loading)?_c('VueSkeleton',{attrs:{"type":"circle","width":16,"height":16}}):[(_vm.isCategoryDeleting((_vm.groupId + "-" + (item.id))))?_c('div',{staticClass:"ma-auto text-xs-center"},[_c('v-progress-circular',{attrs:{"size":24,"indeterminate":"","color":"primary"}})],1):_c('v-btn',{attrs:{"flat":"","icon":"","disabled":_vm.isDemo},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]],2)]}}],null,false,59814735)}):_c('div',{staticClass:"layout ma-4 column text-xs-center"},[(_vm.loading)?_c('div',{staticClass:"ma-auto"},[_c('v-progress-circular',{staticClass:"ma-4",attrs:{"indeterminate":"","color":"primary"}})],1):_c('h6',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('noCategoryAdded')))])]),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-auto",style:(_vm.items.length ? 'margin-top:-74px;' : ''),attrs:{"color":"tertiary","dark":"","round":"","disabled":_vm.loading}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa-plus")]),_vm._v(" "+_vm._s(_vm.$t('add'))+" ")],1)]}}]),model:{value:(_vm.showTree),callback:function ($$v) {_vm.showTree=$$v},expression:"showTree"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('add')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"tertiary"},on:{"click":function($event){_vm.showTree = false}}},[_c('v-icon',[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_c('ProductsTreeSelect',{attrs:{"group":_vm.group},on:{"productAdded":function (data) { return _vm.$emit('productAdded', data); },"categoryAdded":function (data) { return _vm.$emit('categoryAdded', data); }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }