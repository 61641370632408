<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title class="price-filters grey lighten-4">
            <v-layout
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                    {{ $t('productPrices.title') }}
                    <div class="left-filters">
                        <filter-summary :filters="filtersSummary" />
                        <v-btn
                            @click="generatePriceReport"
                            flat
                            icon
                        ><v-icon small>fa-download</v-icon>
                        </v-btn>
                        <Chip
                            :disabled="loadingCharts"
                            :label="$t('filters.reset')"
                            :active="!isLocalFilterDefault"
                            @click="reset"
                        />
                    </div>
                </h3>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <v-flex shrink>
                        <select-all
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="campaign"
                            :items="campaignOptions"
                            :disabled="loadingCharts"
                            :label="$t('productPrices.shop')"
                            :all-label="$t('productPrices.allShops')"
                            require-selection
                            @blur="handleCampaignBlur"
                        />
                    </v-flex>
                    <v-flex v-if="!showProductGroupsResults" shrink>
                        <select-all
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="category"
                            :items="categoryOptions"
                            :itemsTree="categoryOptionsTree"
                            :disabled="loadingCharts"
                            :label="$t('productPrices.category')"
                            :all-label="$t('productPrices.allCategories')"
                            @blur="handleCategoryBlur"
                        />
                    </v-flex>
                    <v-flex v-if="showProductGroupsResults" shrink>
                        <select-all
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="group"
                            :items="groupOptions"
                            :disabled="loadingCharts"
                            :label="$t('productPrices.group')"
                            :all-label="$t('productPrices.allGroups')"
                            @blur="handleGroupBlur"
                        />
                    </v-flex>
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
                <v-layout
                    class="full-width"
                    wrap
                    align-center
                    justify-end
                >
                    <v-flex shrink>
                        <product-selector
                            class-name="mr-3 mb-3"
                            v-model="products"
                            :default-items="defaultProducts"
                            component-id="productPrices"
                            multiple
                            :disabled="loadingCharts"
                            @blur="handleProductsBlur"
                        />
                    </v-flex>
                    <v-flex class="ml-3" shrink>
                        <v-switch
                            v-model="showProductGroupsResults"
                            :label="$t('filters.showProductGroupsResults')"
                            :disabled="loadingCharts"
                            @change="handleShowProductGroupsResultsChange"
                        />
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>

            <product-prices
                ref="productPrices"
                :data="aggregatedReportData"
                :is-common-loading="loadingCharts"
                :is-common-filter-default="isLocalFilterDefault"
                :common-filters-summary="filtersSummary"
                :tooltip="$t('tooltipNewReportIsCreated')"
            />
            <average-price
                ref="averagePrice"
                :data="aggregatedReportData"
                :date-filter="dateFilter"
                :campaign="commonCampaign"
                :category="commonCategory"
                :group="commonGroup"
                :products="commonProducts"
                :is-common-loading="loadingCharts"
                :is-common-filter-default="isLocalFilterDefault"
                :common-filters-summary="filtersSummary"
                :tooltip="$t('tooltipNewReportIsCreated')"
                @filterDefaultChange="handleFilterDefaultChange('averagePrice', $event)"
                @resetLocalFilters="reset()"
            />
            <average-price-over-time
                ref="averagePriceOverTime"
                :data="aggregatedReportData"
                :date-filter="dateFilter"
                :is-common-loading="loadingCharts"
                :is-common-filter-default="isLocalFilterDefault"
                :common-filters-summary="filtersSummary"
                :tooltip="$t('tooltipNewReportIsCreated')"
                @filterDefaultChange="handleFilterDefaultChange('averagePriceOverTime', $event)"
                @resetLocalFilters="reset()"
            />
            <average-quantity-over-time
                ref="averageQuantityOverTime"
                :date-filter="dateFilter"
                :campaign="commonCampaign"
                :category="commonCategory"
                :group="commonGroup"
                :products="commonProducts"
                :is-common-loading="loadingCharts"
                :is-common-filter-default="isLocalFilterDefault"
                :common-filters-summary="filtersSummary"
                :has-all-campaigns-selected="hasAllCampaignsSelected"
                :tooltip="$t('tooltipNewReportIsCreated')"
                @loadingChange="handleLoadingChange('averageQuantityOverTime', $event)"
                @filterDefaultChange="handleFilterDefaultChange('averageQuantityOverTime', $event)"
                @resetLocalFilters="reset()"
            />
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { sum } from 'lodash';
import campaignFilterMixin from '@/mixins/campaignFilterMixin';
import categoryFilterMixin from '@/mixins/categoryFilterMixin';
import productsFilterMixin from '@/mixins/productsFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import dateService from '@/services/dateService';
import numberService from '@/services/numberService';
import AverageQuantityOverTime from '@/components/producer/reports/priceDistribution/AverageQuantityOverTime.vue';
import AveragePriceOverTime from '@/components/producer/reports/priceDistribution/AveragePriceOverTime.vue';
import AveragePrice from '@/components/producer/reports/priceDistribution/AveragePrice.vue';
import SelectAll from '@/components/common/SelectAll.vue';
import FilterSummary from '@/components/common/FilterSummary.vue';
import Chip from '@/components/common/Chip.vue';
import ProductSelector from '@/components/common/ProductSelector.vue';
import ProductPrices from '@/components/producer/reports/priceDistribution/ProductPrices.vue';

export default {
    name: 'price-distribution-section',
    components: {
        ProductPrices,
        ProductSelector,
        Chip,
        FilterSummary,
        SelectAll,
        AveragePrice,
        AveragePriceOverTime,
        AverageQuantityOverTime,

    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
        productsFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isTestEnvironment: process.env.NODE_ENV === 'development',
            isLocalFilterDefault: true,
            isProductPricesFilterDefault: true,
            isAveragePriceFilterDefault: true,
            isAveragePriceOverTimeFilterDefault: true,
            isAverageQuantityOverTimeFilterDefault: true,
            commonCampaign: [],
            commonCategory: [],
            commonProducts: [],
            commonGroup: [],
            hasAllCampaignsSelected: false,
            defaultProducts: [],
            showProductGroupsResults: false,
            aggregatedReportData: null,
            isAverageQuantityOverTimeLoading: false,
            error: null,
        };
    },
    computed: {
        canInitProducts() {
            return this.defaultProducts.length;
        },
        loadingCharts() {
            return this.productsAggregatedReportLoading || this.isAverageQuantityOverTimeLoading;
        },
        filtersSummary() {
            const summary = [...this.campaignFilterSummary];
            if (this.showProductGroupsResults) {
                summary.push(this.groupFilterSummary);
            } else {
                summary.push(this.categoryFilterSummary);
            }
            summary.push(this.productsFilterSummary);
            return summary;
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            categoriesIds: state => state.space.categoriesIds,
            categories: state => state.space.categories,
            productsAggregatedReportLoading: state => state.reportsPriceDistribution.loading.productsAggregatedReport,
        }),
        ...mapGetters({
            getProductDetails: 'products/getProductDetails',
            getterProductsAggregatedReport: 'reportsPriceDistribution/getProductsAggregatedReport',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
            ) {
                this.getProductsAggregatedReport();
            }
        },
        loadingCharts(value) {
            this.campaignSelectDisabled = value;
        },
        canInitProducts() {
            if (this.canInitProducts && !this.products.length) this.$nextTick(this.initProducts);
        },
    },
    methods: {
        initProducts() {
            this.products = [...this.defaultProducts];
            this.handleProductsBlur();
        },
        async loadProduct() {
            try {
                const entityId = this.$route.query.product;
                if (!entityId) return;
                const productId = `product-${entityId}`;
                this.error = null;
                await this.fetchProductDetails({ entityId, productId });
                const productDetails = this.getProductDetails(productId);
                const product = {
                    id: productId,
                    text: productDetails.name,
                    entityId: productDetails.id,
                    productId: productDetails.productId,
                    ean: productDetails.ean,
                    sku: productDetails.sku,
                    img: productDetails.images[0] || '',
                    categories: productDetails.categories,
                };
                this.defaultProducts = [product];
            } catch (e) {
                this.error = e.message;
            }
        },
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'productPrices') {
                this.isProductPricesFilterDefault = isDefault;
            } else if (type === 'averagePrice') {
                this.isAveragePriceFilterDefault = isDefault;
            } else if (type === 'averagePriceOverTime') {
                this.isAveragePriceOverTimeFilterDefault = isDefault;
            } else if (type === 'averageQuantityOverTime') {
                this.isAverageQuantityOverTimeFilterDefault = isDefault;
            } else if (type === 'current') {
                this.isLocalFilterDefault = !this.category.length && this.isDefaultCampaign() && this.isDefaultProducts() && this.isDefaultGroup(true) && !this.showProductGroupsResults;
            }
            this.commonCampaign = this.campaign;
            this.commonCategory = this.showProductGroupsResults ? [] : this.category;
            this.commonProducts = this.products;
            this.commonGroup = this.showProductGroupsResults ? this.group : [];
        },
        handleLoadingChange(type, isLoading) {
            if (type === 'averageQuantityOverTime') {
                this.isAverageQuantityOverTimeLoading = isLoading;
            }
        },
        handleCampaignBlur() {
            this.handleFilterDefaultChange('current');
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.getProductsAggregatedReport();
        },
        handleCategoryBlur() {
            this.handleFilterDefaultChange('current');
            this.getProductsAggregatedReport();
        },
        handleProductsBlur() {
            this.handleFilterDefaultChange('current');
            this.getProductsAggregatedReport();
        },
        handleGroupBlur() {
            this.handleFilterDefaultChange('current');
            this.getProductsAggregatedReport();
        },
        handleShowProductGroupsResultsChange() {
            if (this.showProductGroupsResults) this.category = [];
            if (!this.showProductGroupsResults) this.group = [];
            this.handleFilterDefaultChange('current');
            this.getProductsAggregatedReport();
        },
        reset() {
            this.category = [];
            this.setDefaultCampaign();
            this.setDefaultProducts();
            this.group = [];
            this.handleFilterDefaultChange('current');
            this.hasAllCampaignsSelected = this.hasAllCampaigns();
            this.showProductGroupsResults = false;
            this.getProductsAggregatedReport();
        },
        redo() {
            this.getProductsAggregatedReport(true);
        },
        async getProductsAggregatedReport(force = false) {
            try {
                this.error = null;

                if (this.category?.length || this.products?.length || (this.group?.length && this.showProductGroupsResults)) {
                    const { startDate, endDate } = this.dateFilter;
                    const products = this.products.map(product => product.id);
                    const entities = this.products.map(product => product.entityId);
                    const campaign = [...this.campaign];
                    const category = !this.showProductGroupsResults ? [...this.category] : [];
                    const group = this.showProductGroupsResults ? [...this.group] : [];
                    const groupBy = dateService.getRangeType(startDate, endDate);
                    await this.fetchProductsPriceAggregatedReport({
                        force,
                        startDate,
                        endDate,
                        groupBy,
                        campaign,
                        category,
                        group,
                        products,
                        entities,
                    });
                    const data = this.getterProductsAggregatedReport(startDate, endDate, groupBy, campaign, category, group, products);
                    this.aggregatedReportData = this.parseData(data);
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        parseData(data) {
            if (!data) {
                return undefined;
            }
            // fill missing avg prices and calculate avg price
            const completeData = {};
            Object.keys(data).forEach(productId => {
                const product = {
                    product: data[productId].product,
                    report: {},
                    custom: {
                        minPrice: null,
                        avgPrice: null,
                        maxPrice: null,
                        quantity: 0,
                    },
                };
                const avgPrices = [];
                Object.keys(data[productId].report).forEach(campaignName => {
                    const report = data[productId].report[campaignName];
                    product.report[campaignName] = {
                        min: report.min,
                        max: report.max,
                        chart: this.fillProductMissingPrices(report.chart),
                    };
                    // calculate avg price
                    const campaignAvgPrices = [];
                    product.report[campaignName].chart.forEach(item => {
                        if (item.avg) {
                            campaignAvgPrices.push(item.avg);
                        }
                    });
                    const avgPrice = campaignAvgPrices.length ? sum(campaignAvgPrices) / campaignAvgPrices.length : 0;
                    product.report[campaignName].avg = avgPrice;
                    if (avgPrice) {
                        avgPrices.push(avgPrice);
                    }
                    if (report.min) {
                        product.custom.minPrice = product.custom.minPrice === null ? report.min : Math.min(product.custom.minPrice, report.min);
                    }
                    if (report.max) {
                        product.custom.maxPrice = product.custom.maxPrice === null ? report.max : Math.max(product.custom.maxPrice, report.max);
                    }
                });
                product.custom.minPrice = product.custom.minPrice ?? null;
                product.custom.minPriceFormatted = numberService.formatCurrency(product.custom.minPrice);
                product.custom.avgPrice = avgPrices.length ? sum(avgPrices) / avgPrices.length : null;
                product.custom.avgPriceFormatted = numberService.formatCurrency(product.custom.avgPrice);
                product.custom.maxPrice = product.custom.maxPrice ?? null;
                product.custom.maxPriceFormatted = numberService.formatCurrency(product.custom.maxPrice);
                // TODO: add quantity once backend adds support in aggregated report
                completeData[productId] = product;
            });
            return completeData;
        },
        fillProductMissingPrices(chart) {
            const lastCertainValues = {};
            return chart.reduce((completeItemsByDay, item) => {
                const completeItem = Object.keys(item).reduce((dayItem, key) => {
                    let value = item[key];
                    if (['data', 'aval'].includes(key)) {
                        // do nothing, leave value as it is
                    } else if (value != null) {
                        lastCertainValues[key] = value;
                    } else {
                        // use assumed avg price if available
                        value = lastCertainValues[key] ?? null;
                    }
                    return {
                        ...dayItem,
                        [key]: value,
                    };
                }, {});
                return [
                    ...completeItemsByDay,
                    completeItem,
                ];
            }, []);
        },
        async generatePriceReport() {
            try {
                this.error = null;

                if (this.category?.length || this.products?.length || (this.group?.length && this.showProductGroupsResults)) {
                    const {
                        startDate, endDate, compareStartDate, compareEndDate,
                    } = this.dateFilter;
                    const products = this.products.map(product => product.id);
                    const entities = this.products.map(product => product.entityId);
                    const campaign = [...this.campaign];
                    const category = !this.showProductGroupsResults ? [...this.category] : [];
                    const group = this.showProductGroupsResults ? [...this.group] : [];
                    const groupBy = dateService.getRangeType(startDate, endDate);
                    await this.generateProductsPriceExport({
                        startDate,
                        endDate,
                        compareStartDate,
                        compareEndDate,
                        groupBy,
                        campaign,
                        category,
                        group,
                        products,
                        entities,
                    });
                    this.$toast.info(this.$t('dataExport.generationFeedback'), { timeout: 7500, color: 'tertiary' });
                    this.$router.push({ name: 'producerDataExport' });
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchProductsPriceAggregatedReport: 'reportsPriceDistribution/fetchProductsPriceAggregatedReport',
            generateProductsPriceExport: 'reportsPriceDistribution/generateProductsPriceExport',
        }),
    },
    async created() {
        this.category = [];
        this.setDefaultCampaign();
        this.setDefaultProducts();
        this.group = [];
        this.hasAllCampaignsSelected = this.hasAllCampaigns();
        await this.loadProduct();
        await this.getProductsAggregatedReport();
    },
};
</script>

<style lang="scss">
.price-filters {
    position: sticky;
    top: 64px;
    z-index: 7;

    @media (max-width: 959px) {
        top: 56px;
    }
}
</style>
