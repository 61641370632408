/* eslint-disable no-unused-vars */
import demoService from '@/services/demoService';
import exportService from '@/services/exportService';
import dateService from '@/services/dateService';
import dateExportXlsx from '@/assets/demo/data-export.xlsx';
import xlsxPopulate from 'xlsx-populate';
import { decode as base64ArraybufferDecode } from 'base64-arraybuffer';
import { endOfMonth as getEndOfMonth, getDaysInMonth, addDays } from 'date-fns';
import { i18n } from '@/i18n/i18n';

const generatedReports = {};

const generateReportDemo = report => {
    const xlsxBase64DataType = `data:${xlsxPopulate.MIME_TYPE};base64,`;
    const xlsxArrayBuffer = base64ArraybufferDecode(dateExportXlsx.replace(xlsxBase64DataType, ''));
    const startOfMonth = `${report.year}-${report.month.toString().padStart(2, '0')}-01`;
    const endOfMonth = dateService.formatDate(getEndOfMonth(startOfMonth));
    const dateRange = `${startOfMonth}-${endOfMonth}`;
    const daysInMonth = getDaysInMonth(startOfMonth);

    return xlsxPopulate.fromDataAsync(xlsxArrayBuffer)
        .then(workbook => {
            workbook.sheets().forEach(sheet => {
                const sheetCountArray = {
                    'By product': 447,
                    'By shop': 179,
                    'By category': 447,
                    'By shop& by product': 447,
                    'By shop&category': 447,
                    Sales: 22,
                };
                const sheetCount = sheetCountArray[sheet.name().trim()];
                const qty = Math.ceil(sheetCount / daysInMonth);
                const dateArray = Array(daysInMonth).fill(undefined).map((item, index) => Array(qty).fill(dateService.formatDate(addDays(startOfMonth, index)))).flat(2).map(item => [item]); // eslint-disable-line
                const clearFrom = sheetCount + 5;
                const clearTo = sheetCount + 100;
                sheet.cell('A2').value(dateRange);
                sheet.cell('A5').value(dateArray);
                sheet.range(`A${clearFrom}:Z${clearTo}`).value('');
                if (i18n.locale === 'pl' && sheet.name().trim() === 'By shop') sheet.range(`E1:F${clearTo}`).value('');
            });

            return workbook.outputAsync('base64').then(base64 => {
                const fileData = `${xlsxBase64DataType}${base64}`;
                return fileData;
            });
        });
};

export default {
    async generateReport(spaceId, region, year, month) {
        const { default: producerReports } = await import('../../mocks/producerReports.json');
        const report = { ...producerReports.items[0] };
        report.id = year + month;
        report.year = year;
        report.month = month;
        report.progress = 100;
        report.status = 'GENERATED';
        generatedReports[report.id] = report;
        return report;
    },

    async fetchReports(spaceId, year, month) {
        if (Object.keys(generatedReports).length > 0) {
            return {
                items: Object.values(generatedReports),
                max: generatedReports.length,
            };
        }
        const { default: producerReports } = await import('../../mocks/producerReports.json');
        producerReports.items.forEach(report => {
            if (!generatedReports[report.id]) {
                generatedReports[report.id] = report;
            }
        });
        return producerReports;
    },

    async fetchReport(reportId) {
        return generatedReports[reportId];
    },

    async deleteReport(reportId) {
        delete generatedReports[reportId];
    },

    async downloadReport(report) {
        generateReportDemo(report).then(data => {
            exportService.exportData(data, this.formatReportName(report), 'xlsx');
        });
    },

    formatReportName: demoService.USE_ORIGINAL_PROPERTY,
};
