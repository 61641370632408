<template>
    <line-with-percentage-graph
        :title="$t('shopsTraffic.title')"
        :percentage-section-title="$t('shopsTraffic.percentageSectionTitle')"
        :line-data="lineData"
        :line-options="lineOptions"
        :area-data="lineData"
        :area-options="areaOptions"
        :pie-data="pieData"
        :loading="loading"
        :error="error"
        @redo="getData(true)"
    >
        <template #filters>
            <v-flex shrink>
                <select-all
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="campaign"
                    :items="campaignOptions"
                    :disabled="loading"
                    :label="$t('shopsTraffic.shop')"
                    :all-label="$t('shopsTraffic.allShops')"
                    require-selection
                    @blur="handleCampaignBlur"
                />
            </v-flex>
            <v-flex shrink>
                <select-all
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="category"
                    :items="categoryOptions"
                    :itemsTree="categoryOptionsTree"
                    :disabled="loading"
                    :label="$t('shopsTraffic.category')"
                    :all-label="$t('shopsTraffic.allCategories')"
                    require-selection
                    @blur="handleCategoryBlur"
                />
            </v-flex>
            <v-flex shrink>
                <v-select
                    class="d-inline-block ml-3 mb-3 select--responsive"
                    v-model="dataType"
                    @change="getData()"
                    :items="dataTypeOptions"
                    :disabled="loading"
                    placeholder=" "
                    :label="$t('shopsTraffic.dataType')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
        </template>
    </line-with-percentage-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import LineWithPercentageGraph from '../../../graphs/LineWithPercentageGraph.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';
import categoryFilterMixin from '../../../../mixins/categoryFilterMixin';
import numberService from '../../../../services/numberService';

export default {
    name: 'shops-traffic',
    components: {
        SelectAll,
        LineWithPercentageGraph,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            data: null,
            dataType: 'impressions',
            error: null,
            lineData: null,
            pieData: null,
        };
    },
    computed: {
        loading() {
            return this.impressionsChartLoading || this.clicksChartLoading || this.ordersChartLoading;
        },
        dataTypeOptions() {
            const options = [
                {
                    value: 'impressions',
                    text: this.$t('shopsTraffic.impressionsOffer'),
                },
                {
                    value: 'clicks',
                    text: this.$t('shopsTraffic.clicks'),
                },
            ];
            if (process.env.VUE_APP_REGION_MODE !== 'PL') {
                options.push({
                    value: 'orders',
                    text: this.$t('shopsTraffic.orders'),
                });
            }
            return options;
        },
        lineOptions() {
            return {
                vAxes: {
                    0: {
                        title: this.$t(`shopsTraffic.${this.dataType === 'impressions' ? 'impressionsOffer' : this.dataType}`),
                        format: 'short',
                        viewWindow: {
                            max: !this.hasValueOverZero ? 1 : null,
                        },
                    },
                },
            };
        },
        areaOptions() {
            return {
                vAxes: {
                    0: {
                        viewWindow: {
                            max: !this.hasValueOverZero ? 1 : null,
                        },
                    },
                },
            };
        },
        hasValueOverZero() {
            if (this.data && this.data.chart && this.data.chart.length > 0) {
                const hasValueOverZero = this.data.chart.some(item => Object.keys(item).filter(key => key !== 'date').some(key => item[key] > 0));
                if (hasValueOverZero) {
                    return true;
                }
            }
            return false;
        },
        ...mapState({
            impressionsChartLoading: state => state.reportsShops.loading.impressionsChart,
            clicksChartLoading: state => state.reportsShops.loading.clicksChart,
            ordersChartLoading: state => state.reportsShops.loading.ordersChart,
        }),
        ...mapGetters({
            getterImpressionsChart: 'reportsShops/getImpressionsChart',
            getterClicksChart: 'reportsShops/getClicksChart',
            getterOrdersChart: 'reportsShops/getOrdersChart',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.setDefaultCategory();
        this.setDefaultCampaign();
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign();
            this.dataType = 'impressions';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.dataType === 'impressions' && this.isDefaultCategory() && this.isDefaultCampaign();
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getChart(force);
        },
        handleCategoryBlur() {
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        parseChartData(data, campaigns) {
            if (!data || data.length === 0) {
                return null;
            }
            const parsedData = data.map((item) => {
                const dataItem = [this.$d(new Date(item.date), 'short')];
                Object.keys(item).forEach((key) => {
                    if (key !== 'date') {
                        dataItem.push({ v: item[key], f: numberService.formatNumber(item[key]) });
                    }
                });
                return dataItem;
            });
            return [['Date', ...campaigns], ...parsedData];
        },
        parsePieChartData(data) {
            if (!data || data.length === 0) {
                return null;
            }
            const parsedData = data.map(row => row.map((item, itemIndex) => {
                if (itemIndex === 0) {
                    return item;
                }
                return { v: item, f: numberService.formatNumber(item) };
            }));
            return [['Name', 'Value'], ...parsedData];
        },
        async getChart(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;

                if (this.dataType === 'impressions') {
                    await this.fetchImpressionsChart({
                        force,
                        startDate,
                        endDate,
                        campaign: this.campaign,
                        category: this.category,
                    });
                    this.data = this.getterImpressionsChart(startDate, endDate, this.campaign, this.category);
                } else if (this.dataType === 'clicks') {
                    await this.fetchClicksChart({
                        force,
                        startDate,
                        endDate,
                        campaign: this.campaign,
                        category: this.category,
                    });
                    this.data = this.getterClicksChart(startDate, endDate, this.campaign, this.category);
                } else if (this.dataType === 'orders') {
                    await this.fetchOrdersChart({
                        force,
                        startDate,
                        endDate,
                        campaign: this.campaign,
                        category: this.category,
                    });
                    this.data = this.getterOrdersChart(startDate, endDate, this.campaign, this.category);
                }

                this.lineData = this.parseChartData(this.data.chart, this.data.chartCampaigns);
                this.pieData = this.parsePieChartData(this.data.pieChart);
            } catch (e) {
                this.error = e.message;
                this.data = null;
                this.lineData = null;
                this.pieData = null;
            }
        },
        ...mapActions({
            fetchImpressionsChart: 'reportsShops/fetchImpressionsChart',
            fetchClicksChart: 'reportsShops/fetchClicksChart',
            fetchOrdersChart: 'reportsShops/fetchOrdersChart',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
