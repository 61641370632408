<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                @click="handleClick()"
                flat
                icon
            >
                <v-icon :color="color" small>fa-filter</v-icon>
                <v-icon class="x-icon" size="10" :color="color">far fa-times-circle</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('resetFiltersButton.label') }}</span>
    </v-tooltip>
</template>

<script>
import eventBus from '../../services/eventBus';

export default {
    name: 'reset-filters-button',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        color() {
            return this.active ? 'secondary' : 'tertiary';
        },
    },
    methods: {
        handleClick() {
            eventBus.$emit('resetFilters');
        },
    },
};
</script>

<style lang="scss" scoped>
    .x-icon {
        position: absolute;
        bottom: 1px;
        right: 6px;
        border-radius: 50%;
        background: #fafafa;
    }
</style>
