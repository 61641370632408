<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <v-layout
                class="mb-4"
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                    {{ title }}
                    <v-tooltip v-if="!loading && lineChartData" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="$refs.lineChart.exportData(title)"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                    <InfoTooltip v-if="tooltip" :text="tooltip" class="ml-1" />
                </h3>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <slot name="filters" />
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo()"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{ error }}
            </v-alert>

            <line-chart-graph
                v-if="!loading && lineChartData"
                ref="lineChart"
                :data="lineChartData"
                :options="lineOptions"
            />
            <data-placeholder v-if="!loading && !lineChartData" :title="$t('noResults')" />

            <div v-if="!loading && !error && (areaChartData || pieChartData) && percentageSectionTitle" class="mt-5 mb-4">
                <h4 class="subheading font-weight-bold">{{ percentageSectionTitle }}</h4>
            </div>

            <v-layout v-if="!loading && !error && (areaChartData || pieChartData)" row wrap>
                <v-flex xs12 lg8>
                    <v-tooltip v-if="!loading && areaChartData" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="$refs.areaChart.exportData(percentageSectionTitle)"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                    <stepped-area-chart-graph
                        v-if="areaChartData"
                        ref="areaChart"
                        :data="areaChartData"
                        :options="areaChartOptions"
                    />
                    <data-placeholder v-if="!areaChartData" :title="$t('noResults')" />
                </v-flex>
                <v-flex xs12 lg4>
                    <v-tooltip v-if="!loading && pieChartData" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error"
                                @click="$refs.pieChart.exportData(percentageSectionTitle)"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                    <pie-chart-graph
                        v-if="pieChartData"
                        ref="pieChart"
                        :showTotal="showTotal"
                        :data="pieChartData"
                        :options="pieChartOptions"
                        :total-type="pieTotalType"
                    ></pie-chart-graph>
                    <data-placeholder v-if="!pieChartData" :title="$t('noResults')"></data-placeholder>
                </v-flex>
            </v-layout>
            <slot name="footer" />
        </v-card-text>
    </div>
</template>

<script>
import { merge } from 'lodash';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import LineChartGraph from './core/LineChartGraph.vue';
import SteppedAreaChartGraph from './core/SteppedAreaChartGraph.vue';
import PieChartGraph from './core/PieChartGraph.vue';
import DataPlaceholder from '../common/DataPlaceholder.vue';

export default {
    name: 'line-with-percentage-graph',
    components: {
        DataPlaceholder,
        PieChartGraph,
        SteppedAreaChartGraph,
        LineChartGraph,
        InfoTooltip,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        showTotal: {
            type: Boolean,
            default: true,
        },
        permissionName: {
            type: String,
        },
        percentageSectionTitle: {
            type: String,
        },
        lineData: {
            type: Array,
            default: null,
        },
        lineOptions: {
            type: Object,
            default() {
                return {};
            },
        },
        areaData: {
            type: Array,
            default: null,
        },
        areaOptions: {
            type: Object,
            default() {
                return {};
            },
        },
        pieData: {
            type: Array,
            default: null,
        },
        pieOptions: {
            type: Object,
            default() {
                return {};
            },
        },
        pieTotalType: {
            type: String,
            validator: value => ['decimal', 'percent', 'currency'].includes(value),
            default: 'decimal',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    computed: {
        lineChartData() {
            return this.lineData;
        },
        areaChartData() {
            return this.areaData;
        },
        areaChartOptions() {
            const options = {
                isStacked: 'percent',
                vAxes: {
                    0: {
                        format: 'percent',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
            };

            return merge(options, this.areaOptions);
        },
        pieChartData() {
            return this.pieData;
        },
        pieChartOptions() {
            const options = {
                pieHole: 0.4,
            };

            return merge(options, this.pieOptions);
        },
    },
    methods: {
        redo() {
            this.$emit('redo');
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
