<template>
    <div>
        <no-access-placeholder
            v-if="!hasPermission"
            :permission-name="permissionName"
            :title="$t('permissionContent.title.reportsPriceDistribution')"
            :description="$t('permissionContent.description.reportsPriceDistribution')"
            :image="require('../../../assets/noAccessPlaceholders/product-price-distribution.jpg')"
            @activated="redo"
        />
        <line-graph
            v-if="hasPermission"
            :title="$t('priceDistribution.title')"
            :permissionName="permissionName"
            :data="items"
            :options="options"
            :loading="loading"
            :error="error"
            :no-results-title="noResultsTitle"
            @redo="redo"
        >
            <template #filters>
                <v-flex shrink>
                    <v-select
                        class="d-inline-block mr-3 mb-3 select--responsive"
                        v-model="pluginCampaigns"
                        @change="handlePluginCampaignsChange"
                        :items="pluginCampaignsOptions"
                        :disabled="loading"
                        placeholder=" "
                        :label="$t('priceDistribution.pluginCampaigns')"
                        outline
                        hide-details
                        dense
                    />
                </v-flex>
            </template>
            <template #footer>
                <more-button :to="{ name: 'producerReportsPriceDistribution', query: { product: productEntityId } }" />
            </template>
        </line-graph>
    </div>
</template>

<script>
import { max } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import LineGraph from '../../graphs/LineGraph.vue';
import permissionMixin from '../../../mixins/permissionMixin';
import numberService from '../../../services/numberService';
import eventBus from '../../../services/eventBus';
import spaceService from '../../../services/spaceService';
import NoAccessPlaceholder from '../../common/NoAccessPlaceholder.vue';
import MoreButton from '../../common/MoreButton.vue';

export default {
    name: 'price-distribution',
    components: {
        NoAccessPlaceholder,
        LineGraph,
        MoreButton,
    },
    mixins: [
        permissionMixin,
    ],
    props: {
        productId: {
            type: String,
            required: true,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            permissionName: spaceService.permissions.STATS_PRICE_DISTRIBUTION,
            product: null,
            data: null,
            error: null,
            pluginCampaigns: 'in',
            pluginCampaignsOptions: [
                {
                    value: 'in',
                    text: this.$t('priceDistribution.inPluginCampaigns'),
                },
                {
                    value: 'notIn',
                    text: this.$t('priceDistribution.notInPluginCampaigns'),
                },
                {
                    value: 'all',
                    text: this.$t('priceDistribution.allPluginCampaigns'),
                },
            ],
        };
    },
    computed: {
        productEntityId() {
            return (this.product && this.product.entityId) || null;
        },
        loading() {
            return this.priceDistributionLoading;
        },
        items() {
            if (!this.data || !this.data.campaigns || this.data.campaigns.length === 0 || !this.data.chart || this.data.chart.length === 0) {
                return null;
            }

            const header = ['Date'];
            this.data.campaigns.forEach(campaign => {
                header.push({ type: 'number', label: campaign });
                header.push({ type: 'boolean', role: 'certainty' });
                header.push({ type: 'string', role: 'tooltip', p: { html: true } });
            });
            const lastCertainValues = {};
            const items = this.data.chart.map(item => {
                const row = [this.$d(new Date(item.date), 'short')];
                this.data.campaigns.forEach(campaign => {
                    if (item[campaign]) {
                        row.push(item[campaign]);
                        row.push(true);
                        row.push(this.createTooltip(item.date, campaign, item[campaign], false));
                        lastCertainValues[campaign] = item[campaign];
                    } else {
                        const assumedValue = lastCertainValues[campaign] ? lastCertainValues[campaign] : null;
                        row.push(assumedValue);
                        row.push(false);
                        row.push(this.createTooltip(item.date, campaign, assumedValue, true));
                    }
                });
                return row;
            });

            return [header, ...items];
        },
        options() {
            const options = {
                selectionMode: 'single',
                tooltip: {
                    isHtml: true,
                },
                vAxes: {
                    0: {
                        title: this.$t('priceDistribution.price'),
                        format: '#',
                        viewWindow: {
                            min: null,
                        },
                    },
                },
            };

            if (this.items && this.items.length > 0) {
                // ignore first row with columns definition
                const filteredData = this.items.slice(1).map(row => row.slice(1));
                // flatten array and get max value
                const maxValue = max([].concat(...filteredData));
                if (maxValue > 0) {
                    options.vAxes[0].format = 'short';
                }
            }

            return options;
        },
        noResultsTitle() {
            let transTag = 'noResults';
            if (this.pluginCampaigns === 'in') {
                transTag = 'priceDistribution.noResultsPluginCampaignsIn';
            } else if (this.pluginCampaigns === 'notIn') {
                transTag = 'priceDistribution.noResultsPluginCampaignsNotIn';
            }
            return this.$t(transTag);
        },
        ...mapState({
            priceDistributionLoading: state => state.products.loading.priceDistribution,
        }),
        ...mapGetters({
            getterProduct: 'products/getProduct',
            getterPriceDistribution: 'products/getProductPriceDistribution',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.product = this.getterProduct(this.productId);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.pluginCampaigns = 'in';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.pluginCampaigns === 'in';
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) {
                return;
            }
            this.emitFilterDefaultChange();
            if (this.product) {
                this.getPriceDistribution(force);
            }
        },
        handlePluginCampaignsChange() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        createTooltip(date, campaign, value, isAssumed) {
            const formattedValue = value !== null ? numberService.formatCurrency(value) : '';
            const assumedLabel = isAssumed ? `<div style="font-style: italic;">${this.$t('priceDistribution.assumedPrice')}</div>` : '';

            return `<div style="padding: 5px; white-space: nowrap;">
                        <strong>${this.$d(new Date(date), 'short')}</strong>
                        <div>${campaign}: <strong>${formattedValue}</strong></div>
                        ${assumedLabel}
                    </div>`;
        },
        async getPriceDistribution(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;

                await this.fetchPriceDistribution({
                    force,
                    productId: this.productId,
                    entityId: this.product.entityId,
                    type: this.product.type,
                    pluginCampaigns: this.pluginCampaigns,
                    startDate,
                    endDate,
                });
                this.data = this.getterPriceDistribution(this.productId, startDate, endDate, this.pluginCampaigns);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            fetchPriceDistribution: 'products/fetchPriceDistribution',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
