import axios from 'axios';
import store from '../store/store';
import { i18n } from '../i18n/i18n';

// axios default settings
axios.defaults.timeout = 1000 * 60 * 5; // 5 min

export default {
    handleError(e) {
        if (e.response && e.response.status && e.response.status === 401) {
            if (e.response.config.url.includes('login-as')) {
                throw new Error(i18n.t('error.unauthorized'));
            } else {
                store.dispatch('auth/logout');
            }
        }

        throw e;
    },

    authHeader({ withAuth } = { withAuth: true }) {
        const headers = {};

        if (withAuth) {
            const accessToken = window.localStorage.getItem('accessToken');

            if (accessToken) {
                headers.Authorization = `Bearer ${accessToken}`;
            }
        }

        return headers;
    },

    async get(url, data, headerOptions, responseType) {
        try {
            return await axios.get(url, {
                params: data,
                headers: this.authHeader(headerOptions),
                responseType,
            });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async post(url, data, headerOptions) {
        try {
            return await axios.post(url, data, { headers: this.authHeader(headerOptions) });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async put(url, data) {
        try {
            return await axios.put(url, data, { headers: this.authHeader() });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async patch(url, data) {
        try {
            return await axios.patch(url, data, { headers: this.authHeader() });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async delete(url, data) {
        try {
            return await axios.delete(url, {
                data,
                headers: this.authHeader(),
            });
        } catch (e) {
            return this.handleError(e);
        }
    },
};
