<template>
    <base-layout>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md5>
                    <v-card class="elevation-0 pa-4">
                        <v-card-text class="text-xs-left">
                            <h3 class="mb-4 title font-weight-bold">{{ $t('acceptTerms.title') }}</h3>

                            <i18n path="acceptTerms.description" tag="p" class="font-weight-medium">
                                <template v-slot:link>
                                    <a :href="termsUrl" target="_blank">{{ $t('acceptTerms.descriptionLink') }}</a>
                                </template>
                            </i18n>

                            <v-alert
                                :value="error"
                                dismissible
                                type="error"
                                transition="scale-transition">
                                {{ error }}
                            </v-alert>

                            <div class="mb-3 text-xs-center">
                                <div class="mb-3 text-xs-right">
                                    <v-btn
                                        :disabled="submitting"
                                        round
                                        @click="cancel"
                                    >
                                        {{ $t('acceptTerms.cancel') }}
                                    </v-btn>
                                    <v-btn
                                        class="btn-wide"
                                        color="tertiary"
                                        :loading="submitting"
                                        :disabled="submitting"
                                        dark
                                        round
                                        @click="accept"
                                    >
                                        {{ $t('acceptTerms.accept') }}
                                    </v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </base-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseLayout from '../components/layout/BaseLayout.vue';

export default {
    name: 'accept-terms',
    components: {
        BaseLayout,
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        termsUrl() {
            return `${process.env.VUE_APP_MARKETING_PAGE_URL}/${this.userLanguage}/terms-of-service`;
        },
        ...mapState({
            userLanguage: state => state.auth.user.language,
            submitting: state => state.auth.loading.acceptTerms,
        }),
    },
    methods: {
        async cancel() {
            this.logout();
        },
        async accept() {
            this.error = null;

            try {
                await this.acceptTerms({
                    redirect: this.$route.query.redirect,
                });
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            logout: 'auth/logout',
            acceptTerms: 'auth/acceptTerms',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
