<template>
    <scatter-graph
        class="mt-3"
        :title="$t('averagePrice.title')"
        type="small"
        :data="items"
        :options="options"
        :loading="isCommonLoading"
        :error="error"
        :no-results-title="noResultsTitle"
        :tooltip="tooltip"
    >
        <template #leftFilters>
            <div class="left-filters">
                <filter-summary :filters="filtersSummary" />
                <Chip
                    :disabled="isCommonLoading"
                    :label="$t('filters.reset')"
                    :active="!isFilterDefault || !isCommonFilterDefault"
                    @click="resetLocalFilters"
                />
            </div>
        </template>
        <template #filters>
            <v-flex shrink>
                <v-select
                    class="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="measure"
                    :items="measureOptions"
                    :disabled="isCommonLoading"
                    placeholder=" "
                    :label="$t('averagePrice.measure')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
            <!--<v-flex shrink>
                <v-select
                    class="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="type"
                    @change="handleTypeChange"
                    :items="typeOptions"
                    :disabled="isCommonLoading"
                    placeholder=" "
                    :label="$t('averagePrice.type')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>-->
        </template>
    </scatter-graph>
</template>

<script>
import { sum } from 'lodash';
import FilterSummary from '../../../common/FilterSummary.vue';
import Chip from '../../../common/Chip.vue';
import eventBus from '../../../../services/eventBus';
import numberService from '../../../../services/numberService';
import ScatterGraph from '../../../graphs/ScatterGraph.vue';

export default {
    name: 'average-price',
    components: {
        ScatterGraph,
        Chip,
        FilterSummary,
    },
    props: {
        data: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
        campaign: {
            type: Array,
            default: () => [],
        },
        category: {
            type: Array,
            default: () => [],
        },
        group: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        isCommonLoading: {
            type: Boolean,
            default: false,
        },
        isCommonFilterDefault: {
            type: Boolean,
            default: true,
        },
        commonFiltersSummary: {
            type: Array,
            default: () => [],
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            error: null,
            measure: 'all',
            measureOptions: [
                {
                    value: 'all',
                    text: this.$t('averagePrice.allMeasures'),
                },
                {
                    value: 'minPrice',
                    text: this.$t('averagePrice.minPrice'),
                },
                {
                    value: 'avgPrice',
                    text: this.$t('averagePrice.avgPrice'),
                },
                {
                    value: 'maxPrice',
                    text: this.$t('averagePrice.maxPrice'),
                },
            ],
            isFilterDefault: true,
        };
    },
    computed: {
        filtersSummary() {
            const measure = this.measureOptions.find(option => option.value === this.measure);
            return [
                ...this.commonFiltersSummary,
                {
                    name: this.$t('averagePrice.measure'),
                    items: [
                        measure && measure.text,
                    ],
                },
            ];
        },
        items() {
            if (!this.data) {
                return [];
            }
            const header = [
                { type: 'string', label: 'Shop', id: 'Shop' },
            ];
            if (this.measure === 'all' || this.measure === 'minPrice') {
                header.push({ type: 'number', label: this.$t('averagePrice.minPrice'), id: 'minPrice' });
                header.push({ type: 'string', role: 'tooltip', p: { html: true } });
            }
            if (this.measure === 'all' || this.measure === 'avgPrice') {
                header.push({ type: 'number', label: this.$t('averagePrice.avgPrice'), id: 'avgPrice' });
                header.push({ type: 'string', role: 'tooltip', p: { html: true } });
            }
            if (this.measure === 'all' || this.measure === 'maxPrice') {
                header.push({ type: 'number', label: this.$t('averagePrice.maxPrice'), id: 'maxPrice' });
                header.push({ type: 'string', role: 'tooltip', p: { html: true } });
            }

            const pricesByCampaign = {};
            Object.keys(this.data).forEach(productId => {
                const { report } = this.data[productId];
                Object.keys(report).forEach(campaignName => {
                    if (!pricesByCampaign[campaignName]) {
                        pricesByCampaign[campaignName] = {
                            min: [],
                            max: [],
                            avg: [],
                        };
                    }
                    if (report[campaignName].min) {
                        pricesByCampaign[campaignName].min.push(report[campaignName].min);
                    }
                    if (report[campaignName].max) {
                        pricesByCampaign[campaignName].max.push(report[campaignName].max);
                    }
                    if (report[campaignName].avg) {
                        pricesByCampaign[campaignName].avg.push(report[campaignName].avg);
                    }
                });
            });

            const items = Object.keys(pricesByCampaign).reduce((acc, campaignName) => {
                const minPrice = pricesByCampaign[campaignName].min.length > 0 ? sum(pricesByCampaign[campaignName].min) / pricesByCampaign[campaignName].min.length : 0;
                const maxPrice = pricesByCampaign[campaignName].max.length > 0 ? sum(pricesByCampaign[campaignName].max) / pricesByCampaign[campaignName].max.length : 0;
                const avgPrice = pricesByCampaign[campaignName].avg.length > 0 ? sum(pricesByCampaign[campaignName].avg) / pricesByCampaign[campaignName].avg.length : 0;
                const itemData = [];
                const tooltip = this.createTooltip({
                    name: campaignName,
                    minPrice,
                    maxPrice,
                    avgPrice,
                }, this.measure);
                if ((this.measure === 'all' || this.measure === 'minPrice') && minPrice) {
                    itemData.push({ v: minPrice || 0, f: numberService.formatCurrency(minPrice) });
                    itemData.push(tooltip);
                }
                if ((this.measure === 'all' || this.measure === 'avgPrice') && avgPrice) {
                    itemData.push({ v: avgPrice || 0, f: numberService.formatCurrency(avgPrice) });
                    itemData.push(tooltip);
                }
                if ((this.measure === 'all' || this.measure === 'maxPrice') && maxPrice) {
                    itemData.push({ v: maxPrice || 0, f: numberService.formatCurrency(maxPrice) });
                    itemData.push(tooltip);
                }
                if (itemData.length) {
                    itemData.unshift(campaignName);
                    acc.push(itemData);
                }
                return acc;
            }, []);
            return [header, ...items];
        },
        options() {
            const series = [];
            if (this.measure === 'all' || this.measure === 'minPrice') {
                series.push({ pointShape: 'diamond', pointSize: 12, color: '#e1ccdd' });
            }
            if (this.measure === 'all' || this.measure === 'avgPrice') {
                series.push({ pointShape: 'circle', pointSize: 22, color: this.$vuetify.theme.primary });
            }
            if (this.measure === 'all' || this.measure === 'maxPrice') {
                series.push({ pointShape: 'diamond', pointSize: 12, color: '#b381a9' });
            }
            const options = {
                selectionMode: 'single',
                tooltip: {
                    isHtml: true,
                },
                vAxes: {
                    0: {
                        title: `${this.$t('averagePrice.price')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]`,
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
                series,
            };

            let hasValueOverZero = false;
            if (this.items && this.items.length > 1) {
                hasValueOverZero = this.items.some((item, index) => {
                    if (index === 0) {
                        return false;
                    }
                    return item.some(value => typeof value === 'object' && value.v && value.v > 0);
                });
            }

            options.vAxes[0].viewWindow.max = hasValueOverZero ? null : 1;

            return options;
        },
        noResultsTitle() {
            const transTag = !this.products || this.products.length === 0 ? 'averagePrice.noProductSelected' : 'averagePrice.noResults';
            return this.$t(transTag);
        },
    },
    created() {
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        resetLocalFilters() {
            this.$emit('resetLocalFilters');
            this.measure = 'all';
            this.emitFilterDefaultChange();
        },
        reset() {
            this.measure = 'all';
            this.emitFilterDefaultChange();
        },
        emitFilterDefaultChange() {
            this.isFilterDefault = this.measure === 'all';
            this.$emit('filterDefaultChange', this.isFilterDefault);
        },
        createTooltip(item) {
            const minIcon = `
                <svg width="14" height="14" class="average-price__tooltip-icon">
                    <path d="M 7 0 L 14 7 L 7 14 L 0 7" stroke="none" stroke-width="0" fill="#e1ccdd" />
                </svg>
            `;
            const avgIcon = `
                <svg width="14" height="14" class="average-price__tooltip-icon">
                    <circle cx="7" cy="7" r="7" stroke="none" stroke-width="0" fill="#00e08f"/>
                </svg>
            `;
            const maxIcon = `
                <svg width="14" height="14" class="average-price__tooltip-icon">
                    <path d="M 7 0 L 14 7 L 7 14 L 0 7" stroke="none" stroke-width="0" fill="#b381a9" />
                </svg>
            `;
            const unavailablePrice = `<span style="font-style: italic;">${this.$t('averagePrice.unavailablePrice')}</span>`;
            let minLabel = '';
            let avgLabel = '';
            let maxLabel = '';
            if (this.measure === 'all' || this.measure === 'minPrice') {
                const minValue = !item.minPrice || item.minPrice === 0 ? unavailablePrice : numberService.formatCurrency(item.minPrice);
                minLabel = `<div>${minIcon} ${this.$t('averagePrice.minPrice')}: <strong>${minValue}</strong></div>`;
            }
            if (this.measure === 'all' || this.measure === 'avgPrice') {
                const avgValue = !item.avgPrice || item.avgPrice === 0 ? unavailablePrice : numberService.formatCurrency(item.avgPrice);
                avgLabel = `<div>${avgIcon} ${this.$t('averagePrice.avgPrice')}: <strong>${avgValue}</strong></div>`;
            }
            if (this.measure === 'all' || this.measure === 'maxPrice') {
                const maxValue = !item.maxPrice || item.maxPrice === 0 ? unavailablePrice : numberService.formatCurrency(item.maxPrice);
                maxLabel = `<div>${maxIcon} ${this.$t('averagePrice.maxPrice')}: <strong>${maxValue}</strong></div>`;
            }
            return `<div style="padding: 5px; white-space: nowrap;">
                        <strong>${item.name}</strong>
                        ${minLabel}
                        ${avgLabel}
                        ${maxLabel}

                    </div>`;
        },
    },
};
</script>

<style lang="scss">
    .average-price__tooltip-icon {
        position: relative;
        top: 3px;
        margin-right: 2px;
    }
</style>

<style lang="scss" scoped>
    .left-filters {
        display: inline-flex;
        align-items: center;
    }
</style>
