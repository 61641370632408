<template>
    <line-with-percentage-graph
        :title="$t('shopsSales.title')"
        :percentage-section-title="$t('shopsSales.percentageSectionTitle')"
        :line-data="lineData"
        :line-options="lineOptions"
        :area-data="lineData"
        :area-options="areaOptions"
        :pie-data="pieData"
        :pie-total-type="pieTotalType"
        :loading="loading"
        :error="error"
        @redo="getData(true)"
    >
        <template #filters>
            <v-flex shrink>
                <v-select
                    class="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="conversion"
                    @change="handleConversionChange"
                    :items="conversionOptions"
                    :disabled="loading"
                    placeholder=" "
                    :label="$t('shopsSales.conversion')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
            <v-flex shrink>
                <select-all
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="campaign"
                    :items="campaignOptions"
                    :disabled="loading"
                    :label="$t('shopsSales.shop')"
                    :all-label="$t('shopsSales.allShops')"
                    require-selection
                    @blur="handleCampaignBlur"
                />
            </v-flex>
            <v-flex shrink>
                <select-all
                    class-name="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="category"
                    :items="categoryOptions"
                    :itemsTree="categoryOptionsTree"
                    :disabled="loading"
                    :label="$t('shopsSales.category')"
                    :all-label="$t('shopsSales.allCategories')"
                    require-selection
                    @blur="handleCategoryBlur"
                />
            </v-flex>
            <v-flex shrink>
                <v-select
                    class="d-inline-block ml-3 mb-3 select--responsive"
                    v-model="dataType"
                    @change="getData()"
                    :items="dataTypeOptions"
                    :disabled="loading"
                    placeholder=" "
                    :label="$t('shopsSales.dataType')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
        </template>
    </line-with-percentage-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { max } from 'lodash';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import LineWithPercentageGraph from '../../../graphs/LineWithPercentageGraph.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';
import categoryFilterMixin from '../../../../mixins/categoryFilterMixin';
import numberService from '../../../../services/numberService';

export default {
    name: 'shops-sales',
    components: {
        SelectAll,
        LineWithPercentageGraph,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            data: null,
            dataType: 'quantity',
            dataTypeOptions: [
                {
                    value: 'quantity',
                    text: this.$t('shopsSales.quantity'),
                },
                {
                    value: 'amount',
                    text: this.$t('shopsSales.amount'),
                },
            ],
            conversionOptions: [
                {
                    value: 'all',
                    text: this.$t('shopsSales.allConversion'),
                },
                {
                    value: 24,
                    text: this.$t('shopsSales.nConversion', { hours: 24 }),
                },
            ],
            conversion: 'all',
            error: null,
            lineData: null,
            pieData: null,
            pieTotalType: 'decimal',
        };
    },
    computed: {
        loading() {
            return this.quantityChartLoading || this.amountChartLoading;
        },
        lineOptions() {
            let vAxisTitle = this.$t(`shopsSales.${this.dataType}`);
            if (this.dataType === 'amount') {
                vAxisTitle += ` [${process.env.VUE_APP_SYSTEM_CURRENCY}]`;
            }

            const options = {
                vAxes: {
                    0: {
                        title: vAxisTitle,
                        viewWindow: {
                            max: !this.hasValueOverZero ? 1 : null,
                        },
                    },
                },
            };

            if (this.hasValueOverZero) {
                // get all values
                const filteredData = this.data.chart.map(item => Object.keys(item).filter(key => key !== 'date').map(key => item[key]));
                // flatten array and get max value
                const maxValue = max([].concat(...filteredData));
                if ((this.dataType !== 'amount' && maxValue < 1000) || (this.dataType === 'amount' && maxValue === 0)) {
                    options.vAxes[0].format = '#';
                }
            }

            return options;
        },
        areaOptions() {
            return {
                vAxes: {
                    0: {
                        viewWindow: {
                            max: !this.hasValueOverZero ? 1 : null,
                        },
                    },
                },
            };
        },
        hasValueOverZero() {
            if (this.data && this.data.chart && this.data.chart.length > 0) {
                const hasValueOverZero = this.data.chart.some(item => Object.keys(item).filter(key => key !== 'date').some(key => item[key] > 0));
                if (hasValueOverZero) {
                    return true;
                }
            }
            return false;
        },
        ...mapState({
            quantityChartLoading: state => state.reportsShops.loading.quantityChart,
            amountChartLoading: state => state.reportsShops.loading.amountChart,
        }),
        ...mapGetters({
            getterQuantityChart: 'reportsShops/getQuantityChart',
            getterAmountChart: 'reportsShops/getAmountChart',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.setDefaultCategory();
        this.setDefaultCampaign();
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign();
            this.dataType = 'quantity';
            this.conversion = 'all';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.dataType === 'quantity' && this.conversion === 'all' && this.isDefaultCategory() && this.isDefaultCampaign();
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getChart(force);
        },
        handleConversionChange() {
            this.getData();
        },
        handleCategoryBlur() {
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        parseChartData(data, campaigns) {
            if (!data || data.length === 0) {
                return null;
            }
            const formatType = this.dataType === 'amount' ? 'currency' : 'decimal';
            const parsedData = data.map((item) => {
                const dataItem = [this.$d(new Date(item.date), 'short')];
                Object.keys(item).forEach((key) => {
                    if (key !== 'date') {
                        dataItem.push({ v: item[key], f: numberService.format(formatType, item[key]) });
                    }
                });
                return dataItem;
            });
            return [['Date', ...campaigns], ...parsedData];
        },
        parsePieChartData(data) {
            if (!data || data.length === 0) {
                return null;
            }
            const formatType = this.dataType === 'amount' ? 'currency' : 'decimal';
            const parsedData = data.map(row => row.map((item, itemIndex) => {
                if (itemIndex === 0) {
                    return item;
                }
                return { v: item, f: numberService.format(formatType, item) };
            }));
            return [['Name', 'Value'], ...parsedData];
        },
        async getChart(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;

                if (this.dataType === 'quantity') {
                    await this.fetchQuantityChart({
                        force,
                        startDate,
                        endDate,
                        conversion: this.conversion,
                        campaign: this.campaign,
                        category: this.category,
                    });
                    this.data = this.getterQuantityChart(startDate, endDate, this.conversion, this.campaign, this.category);
                } else if (this.dataType === 'amount') {
                    await this.fetchAmountChart({
                        force,
                        startDate,
                        endDate,
                        conversion: this.conversion,
                        campaign: this.campaign,
                        category: this.category,
                    });
                    this.data = this.getterAmountChart(startDate, endDate, this.conversion, this.campaign, this.category);
                }

                this.lineData = this.parseChartData(this.data.chart, this.data.chartCampaigns);
                this.pieData = this.parsePieChartData(this.data.pieChart);
                this.pieTotalType = this.dataType === 'amount' ? 'currency' : 'decimal';
            } catch (e) {
                this.error = e.message;
                this.data = null;
                this.lineData = null;
                this.pieData = null;
            }
        },
        ...mapActions({
            fetchQuantityChart: 'reportsShops/fetchQuantityChart',
            fetchAmountChart: 'reportsShops/fetchAmountChart',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
