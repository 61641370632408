<template>
    <div>

        <NoAccessContactFormModal :permission="spaceService.permissions.CAMPAIGN_ANALYSIS" />

        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Raport Sprzedaży" />
            </template>
        </page-header-filters>

        <no-access-placeholder
            v-if="!hasPermission && !spacesLoading"
            :permission-name="permissionName"
            :title="$t('permissionContent.title.reportsSales')"
            :description="$t('permissionContent.description.reportsSales')"
            :image="require('../../assets/noAccessPlaceholders/reports-sales.jpg')"
            cta-position="top"
            @activated="getData"
        />

        <v-card v-if="hasPermission" class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('producerReportsSales.noSpace')" />

                <div v-if="!loading && spaceId">
                    <sales-overview ref="overview" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('overview', $event)" />
                    <sales-sales-summary ref="salesSummary" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('salesSummary', $event)" />
                    <sales-by-source ref="bySource" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('bySource', $event)" />
                    <sales-summary ref="summary" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('summary', $event)" />
                    <sales-transactions ref="transactions" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('transactions', $event)" />
                    <sales-price-range ref="priceRange" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('priceRange', $event)" />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NoAccessContactFormModal from '@/components/common/NoAccessContactFormModal.vue';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DateRangePicker from '../../components/common/DateRangePicker.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import SalesOverview from '../../components/producer/reports/sales/SalesOverview.vue';
import SalesSalesSummary from '../../components/producer/reports/sales/SalesSalesSummary.vue';
import SalesBySource from '../../components/producer/reports/sales/SalesBySource.vue';
import SalesSummary from '../../components/producer/reports/sales/SalesSummary.vue';
import SalesTransactions from '../../components/producer/reports/sales/SalesTransactions.vue';
import SalesPriceRange from '../../components/producer/reports/sales/SalesPriceRange.vue';
import ResetFiltersButton from '../../components/common/ResetFiltersButton.vue';
import permissionMixin from '../../mixins/permissionMixin';
import spaceService from '../../services/spaceService';
import NoAccessPlaceholder from '../../components/common/NoAccessPlaceholder.vue';
import numberService from '../../services/numberService';

export default {
    name: 'producer-reports-sales',
    components: {
        ResetFiltersButton,
        NoAccessPlaceholder,
        SalesTransactions,
        SalesPriceRange,
        SalesBySource,
        SalesSummary,
        SalesOverview,
        SalesSalesSummary,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
        NoAccessContactFormModal,
    },
    mixins: [
        permissionMixin,
    ],
    data() {
        return {
            spaceService,
            numberService,
            permissionName: spaceService.permissions.STATS_SALES,
            spaceInitialized: false,
            loadingConfig: false,
            error: null,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            isDatepickerFilterDefault: true,
            isSalesFilterDefault: true,
            isSummaryFilterDefault: true,
            isTransactionsFilterDefault: true,
            isPriceRangeFilterDefault: true,
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isSalesFilterDefault || !this.isSummaryFilterDefault || !this.isTransactionsFilterDefault || !this.isPriceRangeFilterDefault;
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaceId: state => state.space.currentSpaceId,
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            const valueChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue);
            if (this.spaceInitialized && valueChanged) this.getData();
        },
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'sales') {
                this.isSalesFilterDefault = isDefault;
            } else if (type === 'summary') {
                this.isSummaryFilterDefault = isDefault;
            } else if (type === 'transactions') {
                this.isTransactionsFilterDefault = isDefault;
            } else if (type === 'priceRange') {
                this.isPriceRangeFilterDefault = isDefault;
            }
        },
        async getData() {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) return;
            this.loadingConfig = true;
            if (process.env.VUE_APP_REGION_MODE !== 'PL') {
                await this.getCampaigns();
            }
            this.loadingConfig = false;
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        handleSpaceChange() {
            this.getData();

            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
        },
        ...mapActions({
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
        }),
    },
};
</script>
