import { render, staticRenderFns } from "./CompetitionPrice.vue?vue&type=template&id=9496aabc&scoped=true&"
import script from "./CompetitionPrice.vue?vue&type=script&lang=js&"
export * from "./CompetitionPrice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9496aabc",
  null
  
)

export default component.exports