<template>
    <div>
        <div v-if="!isReady" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <v-tooltip v-if="isReady" bottom>
            <template v-slot:activator="{ on }">
                <span v-if="showTotal" v-on="on" class="pie-chart__total">{{ $t('pieChart.total') }}: {{ total }}</span>
            </template>
            {{ $t('pieChart.totalTooltip') }}
        </v-tooltip>
        <span v-if="somethingSelected" class="reset-action" @click="resetSelection">
            {{ $t('resetSelection') }}
        </span>
        <GChart
            type="PieChart"
            :settings="{ packages: ['corechart'], language: $i18n.locale }"
            :data="data"
            :options="chartOptions"
            :events="chartEvents"
            @ready="onChartReady"
        />
    </div>
</template>

<script>
import { merge } from 'lodash';
import { GChart } from 'vue-google-charts';
import exportService from '../../../services/exportService';
import numberService from '../../../services/numberService';

export default {
    name: 'pie-chart-graph',
    components: {
        GChart,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        totalType: {
            type: String,
            validator: value => ['decimal', 'percent', 'currency'].includes(value),
            default: 'decimal',
        },
        showTotal: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selections: [],
            baseOptions: {
                legend: {
                    alignment: 'center',
                    position: 'right',
                    maxLines: 3,
                    pagingTextStyle: {
                        color: this.$vuetify.theme.tertiary,
                    },
                    scrollArrows: {
                        activeColor: this.$vuetify.theme.tertiary,
                        inactiveColor: `${this.$vuetify.theme.tertiary}4c`,
                    },
                },
                chartArea: {
                    top: 35,
                    left: 10,
                    right: 0,
                    width: '100%',
                    height: '100%',
                },
                height: 300,
                backgroundColor: '#f5f5f5',
                selectionMode: 'multiple',
                tooltip: {
                    trigger: 'selection',
                },
            },
            chartEvents: {
                ready: () => {
                    this.isReady = true;
                },
                select: () => {
                    this.selections = this.chart.getSelection();
                },
            },
            isReady: false,
            chart: null,
            google: null,
        };
    },
    computed: {
        chartOptions() {
            return merge(this.baseOptions, this.options);
        },
        total() {
            const total = this.data.reduce((acc, cur, index) => {
                if (index === 0 || (this.selections && this.selections.length > 0 && !this.selections.some(selection => selection.row === index - 1))) {
                    return acc;
                }
                const currentValue = typeof cur[1] === 'object' ? cur[1].v : cur[1];
                return acc + currentValue;
            }, 0);
            return numberService.format(this.totalType, total);
        },
        somethingSelected() {
            return this.selections.length !== 0;
        },
    },
    methods: {
        onChartReady(chart, google) {
            this.chart = chart;
            this.google = google;
        },
        resetSelection() {
            if (this.chart) {
                this.chart.setSelection([]);
                this.selections = [];
            }
        },
        exportData(title = 'export') {
            exportService.exportDataImage(this.chart.getImageURI(), title);
        },
    },
};
</script>

<style lang="scss" scoped>
    .pie-chart__total {
        font-size: 14px;
        font-weight: 700;
    }

    .reset-action {
        cursor: pointer;
        margin-left: 20px;
        font-size: 12px;

        &:before {
            font-family: "Font Awesome 5 Free";
            content: "\f0e2";
            font-weight: 900;
            margin-right: 5px;
            display: inline-block;
            font-size: 12px;
        }

        &:hover {
            border-bottom: 1px solid rgba(0, 0, 0, .5);
        }
    }
</style>
