<template>
    <div>
        <v-data-table
            class="elevation-0 table--transparent"
            :headers="headers"
            :key="showAvailability"
            :items="data || []"
            :no-data-text="$t('noResults')"
            :no-results-text="$t('noResults')"
            :rows-per-page-text="$t('table.rowsPerPage')"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            sort-icon="fa-angle-up"
        >
            <template v-slot:headers="props">
                <tr>
                    <th
                        v-for="header in props.headers"
                        :key="header.text"
                        :class="['column sortable', header.value === pagination.sortBy ? 'active' : '']"
                        @click="changeSort(header)"
                    >
                        <v-layout :justify-start="header.align === 'left'" :justify-end="header.align === 'right'" align-center>
                            <v-tooltip v-if="header.iconPath" top>
                                <template v-slot:activator="{ on }">
                                    <img v-on="on" class="header-campaign-logo" :src="header.iconPath" :alt="header.text" />
                                </template>
                                {{ header.text }}
                            </v-tooltip>
                            <span v-else>{{ header.text }}</span>

                            <sort-order v-if="header.sortable" :direction="getSortOrder(header.value)" />
                        </v-layout>
                    </th>
                </tr>
            </template>

            <template v-slot:pageText="props">
                {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
            </template>

            <template v-slot:items="props">
                <td :key="props.item.product.id">
                    <v-tooltip v-if="props.item.custom.categoryTree" bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">{{ props.item.custom.mainCategory }}</span>
                        </template>
                        <v-treeview :items="props.item.custom.categoryTree" class="category-tree" open-all open-on-click dark></v-treeview>
                    </v-tooltip>
                </td>
                <td>
                    {{ props.item.product.name }}
                </td>
                <td>
                    {{ props.item.product.sku }}
                </td>
                <td>
                    {{ props.item.product.ean }}
                </td>
                <td v-for="campaignId in campaign" :key="campaignId" class="text-xs-center">
                    <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on }">
                            <template v-if="!showAvailability">
                                <v-icon v-on="on" v-if="isAvailable(props.item.product.id, campaignId)" color="success">fa-check</v-icon>
                                <v-icon v-on="on" v-else color="error">fa-times</v-icon>
                            </template>
                            <template v-else>
                                <span v-on="on">{{ getAvailabilityStatusesText(props.item.availabilityStatuses, campaignId, true) }}</span>
                            </template>
                        </template>

                        <span>{{ getAvailabilityStatusesText(props.item.availabilityStatuses, campaignId, showAvailability) }}</span>
                    </v-tooltip>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import exportService from '@/services/exportService';
import SortOrder from '@/components/common/SortOrder.vue';

export default {
    name: 'current-products-availability',
    components: { SortOrder },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        campaign: {
            type: Array,
            default: () => [],
        },
        availabilityStatuses: {
            type: Array,
            default: () => [],
        },
        showAvailability: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'custom.mainCategory',
                descending: false,
            },
        };
    },
    computed: {
        headers() {
            const headers = [
                {
                    text: this.$t('category'),
                    align: 'left',
                    sortable: true,
                    value: 'custom.mainCategory',
                },
                {
                    text: this.$t('product'),
                    align: 'left',
                    sortable: true,
                    value: 'product.name',
                },
                {
                    text: this.$t('model'),
                    align: 'left',
                    sortable: true,
                    value: 'product.sku',
                },
                {
                    text: this.$t('ean'),
                    align: 'left',
                    sortable: true,
                    value: 'product.ean',
                },
            ];
            this.campaign.forEach(campaignId => {
                const campaignDetails = this.getterActiveCampaigns.find(campaignItem => campaignItem.id === campaignId)
                    ?? this.getterAvailableCampaigns.find(campaignItem => campaignItem.id === campaignId);
                headers.push({
                    text: campaignDetails.name,
                    iconPath: campaignDetails.iconPath,
                    align: 'center',
                    sortable: false,
                });
            });
            return headers;
        },
        ...mapGetters({
            getterActiveCampaigns: 'plugin/activeCampaignsByName',
            getterAvailableCampaigns: 'plugin/availableCampaignsNotActive',
        }),
    },
    methods: {
        isAvailable(productId, campaignId) {
            return this.data.find(item => item.product.id === productId)?.availabilities[campaignId];
        },
        getAvailabilityStatusesText(statuses, campaignId, showOnlyValues) {
            const campaignStatuses = statuses[campaignId];

            if (showOnlyValues) {
                return campaignStatuses.join(', ') || 'NULL';
            }

            return (
                campaignStatuses.map(status => this.availabilityStatuses.find(availabilityStatus => availabilityStatus.value === status).label).join(', ')
                || this.$t('noDataAvailable')
            );
        },
        changeSort(header) {
            if (!header.sortable) {
                return;
            }

            let sortBy = null;
            let descending = true;

            if (this.pagination.sortBy !== header.value) {
                sortBy = header.value;
                descending = true;
            } else if (this.pagination.sortBy === header.value && this.pagination.descending === true) {
                sortBy = header.value;
                descending = false;
            }

            this.pagination = {
                ...this.pagination,
                page: 1,
                sortBy,
                descending,
            };
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('category'))}"`);
            header.push(`"${exportService.parseText(this.$t('product'))}"`);
            header.push(`"${exportService.parseText(this.$t('model'))}"`);
            header.push(`"${exportService.parseText(this.$t('ean'))}"`);

            header.push(`"${exportService.parseText(this.$t('subcategory'))} 1"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 2"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 3"`);
            header.push(`"${exportService.parseText(this.$t('subcategory'))} 4"`);

            this.campaign.forEach(campaignId => {
                const campaignDetails = this.getterActiveCampaigns.find(campaignItem => campaignItem.id === campaignId)
                    ?? this.getterAvailableCampaigns.find(campaignItem => campaignItem.id === campaignId);
                header.push(`"${exportService.parseText(campaignDetails.name)}"`);
            });

            const rows = [];

            (this.data || []).forEach(item => {
                const row = [];
                const categories = [...(item.product?.categories?.[0] || [])]; // Make a shallow copy

                // Sort by `lvl` in ascending order
                categories.sort((a, b) => a.lvl - b.lvl);

                row.push(`"${exportService.parseText(categories[0]?.name || item.custom.mainCategory)}"`);
                row.push(`"${exportService.parseText(item.product.name)}"`);
                row.push(`"${exportService.parseText(item.product.sku)}"`);
                // tab fixes excel issue with long numbers notation
                // eslint-disable-next-line no-tabs
                row.push(`"${exportService.parseText(item.product.ean)}	"`); // Tab fix for Excel

                // Add up to 4 categories
                for (let i = 0; i < 4; i += 1) {
                    row.push(`"${exportService.parseText(categories[i + 1]?.name || '')}"`);
                }

                this.campaign.forEach(campaignId => {
                    if (this.showAvailability) {
                        const availabilityStatuses = this.getAvailabilityStatusesText(item.availabilityStatuses, campaignId, true);
                        row.push(`"${exportService.parseText(availabilityStatuses)}"`);
                    } else {
                        const isAvailableText = this.$t(this.isAvailable(item.product.id, campaignId) ? 'yes' : 'no');
                        row.push(`"${exportService.parseText(isAvailableText)}"`);
                    }
                });

                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('currentAvailability.title'));
        },
    },
};
</script>

<style lang="scss" scoped>
.header-campaign-logo {
    height: 32px;
}
</style>

<style lang="scss">
.category-tree {
    .v-treeview-node__root {
        min-height: 24px !important;
        height: 24px !important;
    }

    .v-treeview-node--leaf {
        margin-left: 25px;
    }

    .v-treeview-node__toggle {
        font-size: 16px !important;
    }

    .v-treeview-node__label {
        font-size: 13px !important;
    }
}
</style>
