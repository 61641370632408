<template>
    <v-data-table
        class="table--transparent"
        :headers="headers"
        :items="items"
        :no-data-text="$t('noResults')"
        :no-results-text="$t('noResults')"
        :pagination.sync="pagination"
        hide-actions
        item-key="custom.set.id"
        expand
    >
        <template v-slot:headers="props">
            <th
                v-for="header in props.headers"
                :key="header.text"
                :class="[
                    'column sortable',
                    pagination.descending ? 'desc' : 'asc',
                    header.value === pagination.sortBy ? 'active' : '',
                    header.align ? `text-xs-${header.align}` : '',
                ]"
            >
                <div>
                    {{ header.text }}
                    <InfoTooltip v-if="header.tooltip" :text="header.tooltip" class="ml-1" forceShow />
                </div>
            </th>
        </template>
        <template v-slot:items="props">
            <tr class="competition-sets__row" @click="props.expanded = !props.expanded">
                <td class="competition-sets__name">
                    {{ props.item.product.name }}
                    <v-icon class="ml-1" small>{{ props.expanded ? 'fa-caret-up' : 'fa-caret-down' }}</v-icon>
                </td>
                <td>{{ props.item.product.sku }}</td>
                <td>{{ props.item.product.ean }}</td>
                <td class="text-xs-right">{{ props.item.custom.priceAvgFormatted }}</td>
                <td>
                    {{ props.item.custom.priceRankingFormatted }}
                    <InfoTooltip v-if="!props.item.custom.priceRanking" :text="$t('competitiveAnalysis.productUnavailable')" class="ml-1" />
                </td>
                <td>
                    <div class="d-flex align-center justify-end">
                        {{ props.item.custom.priceMinFormatted }}
                        <competition-price-details priceType="min" :product="props.item" />
                    </div>
                </td>
                <td>
                    <div class="d-flex align-center justify-end">
                        {{ props.item.custom.priceMaxFormatted }}
                        <competition-price-details priceType="max" :product="props.item" />
                    </div>
                </td>
                <td>{{ props.item.custom.offersCountFormatted }}</td>
                <td>
                    {{ props.item.custom.offersRankingFormatted }}
                    <RankingItemBar
                        class="ml-2"
                        hideLabel
                        v-if="props.item.custom.offersRanking"
                        :maxTotal="parseInt(props.item.custom.offersRankingFormatted.split(' / ')[1])"
                        :total="parseInt(props.item.custom.offersRankingFormatted.split(' / ')[1]) - parseInt(props.item.custom.offersRankingFormatted.split(' / ')[0]) * 0.9"
                        :barWidth="100" />
                    <InfoTooltip v-if="!props.item.custom.offersRanking" :text="$t('competitiveAnalysis.productUnavailable')" class="ml-1" />
                </td>
            </tr>
        </template>

        <template v-slot:expand="props">
            <div class="pl-5">
                <competition-aggregations-list :data="data" :set="props.item.custom.set" />
                <v-btn
                    class="btn-wide mt-3 mb-3"
                    :disabled="!sets.length"
                    color="tertiary"
                    dark
                    round
                    @click="$emit('editSet', props.item.custom.set.id)"
                >
                    {{ $t('competitionSets.editSet') }}
                </v-btn>
                <v-btn
                    class="btn-wide mt-3 mb-3"
                    :disabled="!sets.length"
                    color="tertiary"
                    dark
                    round
                    @click="$emit('removeSet', props.item.custom.set.id)"
                >
                    {{ $t('competitionSets.removeSet') }}
                </v-btn>

                <competition-avg-price-in-time
                    class="pt-2"
                    :set="props.item.custom.set"
                    :data="data"
                    :date-filter="dateFilter"
                    :dates-in-range="datesInRange"
                    :is-common-filter-default="isCommonFilterDefault"
                    :common-filters-summary="commonFiltersSummary"
                    :campaign-names="campaignNames"
                    @resetLocalFilters="reset"
                />
                <competition-price
                    :set="props.item.custom.set"
                    :data="data"
                    :date-filter="dateFilter"
                    :dates-in-range="datesInRange"
                />
                <competition-price-range
                    :set="props.item.custom.set"
                    :data="data"
                    :compareData="compareData"
                    :date-filter="dateFilter"
                />

                <div class="grey lighten-4 pa-4">
                    <v-card-title class="price-filters grey lighten-4">
                        <v-layout
                            row
                            wrap
                            align-center
                            justify-space-between
                        >
                            <h3 class="title font-weight-bold mb-3 d-flex align-center">
                                {{ $t('competitionSets.availability') }}
                                <div class="left-filters">
                                    <filter-summary :filters="commonFiltersSummary" />
                                    <Chip
                                        :label="$t('filters.reset')"
                                        :active="isCommonFilterDefault"
                                        @click="reset"
                                    />
                                </div>
                            </h3>
                        </v-layout>
                    </v-card-title>
                    <v-card-text>
                        <competition-offers-count :set="props.item.custom.set" :data="data" :date-filter="dateFilter" />
                        <competition-shop-availability
                            :set="props.item.custom.set"
                            :data="data"
                            :date-filter="dateFilter"
                            :campaign="campaign"
                        />
                    </v-card-text>
                </div>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import {
    findIndex,
    findLastIndex,
    orderBy,
} from 'lodash';
import numberService from '@/services/numberService';
import exportService from '@/services/exportService';
import CompetitionAggregationsList from '@/components/producer/reports/competitiveAnalysis/CompetitionAggregationsTable.vue';
import CompetitionAvgPriceInTime from '@/components/producer/reports/competitiveAnalysis/CompetitionAvgPriceInTime.vue';
import CompetitionPriceRange from '@/components/producer/reports/competitiveAnalysis/CompetitionPriceRange.vue';
import CompetitionOffersCount from '@/components/producer/reports/competitiveAnalysis/CompetitionOffersCount.vue';
import CompetitionShopAvailability from '@/components/producer/reports/competitiveAnalysis/CompetitionShopAvailability.vue';
import FilterSummary from '@/components/common/FilterSummary.vue';
import InfoTooltip from '@/components/common/InfoTooltip.vue';
import Chip from '@/components/common/Chip.vue';
import CompetitionPrice from '@/components/producer/reports/competitiveAnalysis/CompetitionPrice.vue';
import CompetitionPriceDetails from '@/components/producer/reports/competitiveAnalysis/CompetitionPriceDetails.vue';
import RankingItemBar from '@/components/graphs/RankingItemBar.vue';

export default {
    name: 'competition-sets',
    components: {
        CompetitionPriceDetails,
        CompetitionPrice,
        Chip,
        FilterSummary,
        CompetitionShopAvailability,
        CompetitionOffersCount,
        CompetitionPriceRange,
        CompetitionAvgPriceInTime,
        CompetitionAggregationsList,
        InfoTooltip,
        RankingItemBar,
    },
    props: {
        sets: {
            type: Array,
        },
        data: {
            type: Object,
        },
        compareData: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
        datesInRange: {
            type: Array,
            default: () => [],
        },
        isCommonFilterDefault: {
            type: Boolean,
            default: true,
        },
        commonFiltersSummary: {
            type: Array,
            default: () => [],
        },
        campaignNames: {
            type: Array,
            default: () => [],
        },
        campaign: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            pagination: {
                sortBy: 'name',
                rowsPerPage: -1,
            },
            headers: [
                {
                    text: this.$t('name'),
                    align: 'left',
                    sortable: false,
                    value: 'product.name',
                },
                {
                    text: this.$t('sku'),
                    align: 'left',
                    sortable: false,
                    value: 'product.sku',
                },
                {
                    text: this.$t('ean'),
                    align: 'left',
                    sortable: false,
                    value: 'product.ean',
                },
                {
                    text: this.$t('avgPrice'),
                    align: 'right',
                    sortable: false,
                    value: 'custom.priceAvg',
                    tooltip: this.$t('competitionSets.avgPriceTooltip'),
                },
                {
                    text: this.$t('competitionSets.priceRanking'),
                    tooltip: this.$t('competitionSets.priceRankingTooltip'),
                    align: 'left',
                    sortable: false,
                    value: 'custom.priceRanking',
                },
                {
                    text: this.$t('minPrice'),
                    align: 'right',
                    tooltip: this.$t('competitionSets.minPriceTooltip'),
                    sortable: false,
                    value: 'custom.priceMin',
                },
                {
                    text: this.$t('maxPrice'),
                    align: 'right',
                    tooltip: this.$t('competitionSets.maxPriceTooltip'),
                    sortable: false,
                    value: 'custom.priceMax',
                },
                {
                    text: this.$t('competitionSets.offersCount'),
                    tooltip: this.$t('competitionSets.offersCountTooltip'),
                    align: 'left',
                    sortable: false,
                    value: 'custom.offersCount',
                },
                {
                    text: this.$t('competitionSets.offersRanking'),
                    tooltip: this.$t('competitionSets.offersRankingTooltip'),
                    align: 'left',
                    sortable: false,
                    value: 'custom.offersRanking',
                },
            ],
        };
    },
    computed: {
        items() {
            if (!this.sets?.length || !this.data) {
                return [];
            }

            return this.sets.map(set => {
                const productData = this.data.products[set.producerProduct.id];
                const producerProductsData = set.producerProducts.map(product => this.data.products[product.id]);
                const globalProductsData = set.globalProducts.map(product => this.data.aggregations[product.id]);
                const productForRanking = [productData, ...producerProductsData, ...globalProductsData].filter(product => Boolean(product.custom.priceAvg));
                const competitionAvgPricesOrdered = this.orderProductsByAvgPrice(productForRanking);
                const competitionOffersCountOrdered = this.orderProductsByOrderCount(productForRanking);
                const isAvailable = Boolean(productData.custom.priceAvg);
                const priceRanking = isAvailable ? findIndex(competitionAvgPricesOrdered, item => item.custom.id === productData.custom.id) + 1 : 0;
                const offersRanking = isAvailable ? findLastIndex(competitionOffersCountOrdered, item => item.custom.id === productData.custom.id) + 1 : 0;

                // offset of all offers count, manages / x in offers count
                let competitionOffersCountOrderedOffset = 0;
                competitionOffersCountOrdered.map((item, index) => {
                    const offer = item;
                    if (index === 0) {
                        return offer;
                    }
                    const previousItem = competitionOffersCountOrdered[index - 1];

                    // detect if there is more than one item with the same offer count
                    // if offer count is the same as previous item, add offset
                    if (offer.custom.offersCount === previousItem?.custom.offersCount) {
                        offer.custom.offersCountOffset = 1 + competitionOffersCountOrderedOffset;

                        competitionOffersCountOrderedOffset += 1;
                    } else {
                        offer.custom.offersCountOffset = competitionOffersCountOrderedOffset;
                    }
                    return offer;
                });

                const offersRankingOffset = competitionOffersCountOrdered[offersRanking - 1]?.custom.offersCountOffset || 0;

                return {
                    ...productData,
                    custom: {
                        ...productData.custom,
                        priceRanking,
                        priceRankingFormatted: isAvailable ? `${numberService.formatNumber(priceRanking, 0)} / ${numberService.formatNumber(competitionAvgPricesOrdered.length, 0)}` : '- / -',
                        offersRanking,
                        offersRankingFormatted: isAvailable ? `${numberService.formatNumber(offersRanking - offersRankingOffset, 0)} / ${numberService.formatNumber(competitionOffersCountOrdered.length, 0)}` : '- / -',
                        set,
                    },
                };
            });
        },
    },
    methods: {
        orderProductsByAvgPrice(productsData = []) {
            return orderBy(productsData, ['custom.priceAvg', 'custom.isProducerProduct'], ['asc', 'desc']);
        },
        orderProductsByOrderCount(productsData = []) {
            return orderBy(productsData, ['custom.offersCount', 'custom.isProducerProduct'], ['desc', 'desc']);
        },
        reset() {
            this.$emit('resetLocalFilters');
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('name'))}"`);
            header.push(`"${exportService.parseText(this.$t('sku'))}"`);
            header.push(`"${exportService.parseText(this.$t('ean'))}"`);
            header.push(`"${exportService.parseText(this.$t('avgPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('competitionSets.priceRanking'))}"`);
            header.push(`"${exportService.parseText(this.$t('minPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('maxPrice'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('competitionSets.offersCount'))}"`);
            header.push(`"${exportService.parseText(this.$t('competitionSets.offersRanking'))}"`);

            const rows = [];
            this.items.forEach(item => {
                const row = [];
                row.push(`"${exportService.parseText(item.product.name)}"`);
                row.push(`"${exportService.parseText(item.product.sku)}"`);
                row.push(`"${exportService.parseText(item.product.ean)}"`);
                row.push(`"${numberService.formatNumber(item.custom.priceAvg, 2, undefined, true)}"`);
                row.push(`"${exportService.parseText(item.custom.priceRankingFormatted)}"`);
                row.push(`"${numberService.formatNumber(item.custom.priceMin, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.custom.priceMax, 2, undefined, true)}"`);
                row.push(`"${exportService.parseText(item.custom.offersCountFormatted)}"`);
                row.push(`"${exportService.parseText(item.custom.offersRankingFormatted)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.title);
        },
    },
};
</script>

<style lang="scss" scoped>
    .competition-sets__row {
        cursor: pointer;
    }

    .competition-sets__name {
        font-weight: 700;
        color: $primary-color;
    }
</style>
