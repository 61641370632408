<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <v-layout
                class="mb-4"
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                    {{ $t('productsSummary.title') }}
                    <v-tooltip v-if="!loading" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error || !items || items.length === 0"
                                @click="exportData"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                </h3>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <v-flex shrink>
                        <select-all
                            v-if="region !== 'PL'"
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="campaign"
                            :items="campaignOptions"
                            :disabled="loading"
                            :label="$t('productsSummary.shop')"
                            :all-label="$t('productsSummary.allShops')"
                            require-selection
                            @blur="handleCampaignBlur"
                        />
                    </v-flex>
                    <v-flex shrink>
                        <select-all
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="category"
                            :items="categoryOptions"
                            :itemsTree="categoryOptionsTree"
                            :disabled="loading"
                            :label="$t('productsSummary.category')"
                            :all-label="$t('productsSummary.allCategories')"
                            require-selection
                            @blur="handleCategoryBlur"
                        />
                    </v-flex>
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>
            <div v-if="!loading && !error">
                <v-layout justify-end>
                    <v-text-field
                        class="mb-3 search"
                        v-model="search"
                        :label="$t('productsSummary.search')"
                        placeholder=" "
                        outline
                        hide-details
                        clearable
                        clear-icon="fa-times"
                        @keyup="handleSearch"
                        @click:clear="handleSearch"
                    />
                </v-layout>
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="headers"
                    :items="items"
                    :no-data-text="$t('productsSummary.noResults')"
                    :no-results-text="$t('productsSummary.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :pagination.sync="pagination"
                    :rows-per-page-items="rowsPerPageItems"
                    disable-initial-sort
                >
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="(header, index) in props.headers"
                                :key="header.text"
                                :class="[
                                    'column sortable',
                                    header.value === pagination.sortBy ? 'active' : '',
                                    header.align === 'left' ? 'text-xs-left' : '',
                                    header.align === 'right' ? 'text-xs-right' : '',
                                ]"
                                @click="changeSort(header.value)"
                            >
                                <v-layout align-center>
                                    <v-flex>
                                        {{ header.text }}
                                        <div>
                                            <span v-if="index === 0 || index === 1">&nbsp;</span>
                                            <span v-if="index === 2">{{ headerSummary.impressions }}</span>
                                            <span v-if="index === 3">{{ headerSummary.clicks }}</span>
                                            <span v-if="index === 4">{{ headerSummary.quantity }}</span>
                                            <span v-if="index === 5">{{ headerSummary.cr }}</span>
                                            <span v-if="index === 6">{{ headerSummary.amount }}</span>
                                        </div>
                                    </v-flex>
                                    <sort-order :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <td>
                            {{ props.item.name }}
                        </td>
                        <td>
                            {{ props.item.sku }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.impressionsFormatted }}
                            <change-label :change="props.item.impressionsChange" />
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.clicksFormatted }}
                            <change-label :change="props.item.clicksChange" />
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.quantityFormatted }}
                            <change-label :change="props.item.quantityChange" />
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.crFormatted }}
                            <change-label :change="props.item.crChange" />
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.amountFormatted }}
                            <change-label :change="props.item.amountChange" />
                        </td>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce, deburr } from 'lodash';
import numberService from '../../../../services/numberService';
import exportService from '../../../../services/exportService';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import SortOrder from '../../../common/SortOrder.vue';
import ChangeLabel from '../../../common/ChangeLabel.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';
import categoryFilterMixin from '../../../../mixins/categoryFilterMixin';

export default {
    name: 'products-summary',
    components: {
        SelectAll,
        ChangeLabel,
        SortOrder,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            region: process.env.VUE_APP_REGION_MODE,
            headers: [
                {
                    text: this.$t('productsSummary.productName'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('productsSummary.sku'),
                    align: 'left',
                    sortable: true,
                    value: 'sku',
                },
                {
                    text: this.$t('productsSummary.impressionsOffer'),
                    align: 'right',
                    sortable: true,
                    value: 'impressions',
                },
                {
                    text: this.$t('productsSummary.clicks'),
                    align: 'right',
                    sortable: true,
                    value: 'clicks',
                },
                {
                    text: this.$t('productsSummary.purchased'),
                    align: 'right',
                    sortable: true,
                    value: 'quantity',
                },
                {
                    text: this.$t('cr'),
                    align: 'right',
                    sortable: true,
                    value: 'cr',
                },
                {
                    text: this.$t('productsSummary.price'),
                    align: 'right',
                    sortable: true,
                    value: 'amount',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
            },
            data: null,
            items: [],
            search: null,
            error: null,
        };
    },
    computed: {
        loading() {
            return this.summaryLoading;
        },
        headerSummary() {
            const summary = this.items.reduce((acc, cur) => ({
                impressions: acc.impressions + cur.impressions,
                clicks: acc.clicks + cur.clicks,
                quantity: acc.quantity + cur.quantity,
                amount: acc.amount + cur.amount,
            }), {
                impressions: 0,
                clicks: 0,
                quantity: 0,
                amount: 0,
            });
            summary.cr = summary.clicks ? summary.quantity / summary.clicks * 100 : 0;

            return {
                impressions: numberService.formatNumber(summary.impressions),
                clicks: numberService.formatNumber(summary.clicks),
                quantity: numberService.formatNumber(summary.quantity),
                amount: numberService.formatCurrency(summary.amount),
                cr: numberService.formatPercent(summary.cr),
            };
        },
        ...mapState({
            summaryLoading: state => state.reportsProducts.loading.summary,
        }),
        ...mapGetters({
            getterSummary: 'reportsProducts/getSummary',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
    },
    created() {
        this.setDefaultCategory();
        this.setDefaultCampaign(['PL']);
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign(['PL']);
            this.search = null;
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = !this.search && this.isDefaultCategory() && this.isDefaultCampaign(['PL']);
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getSummary(force);
        },
        setDefaultCategory() {
            this.category = this.getterCategoryOptions.map(category => category.value);
        },
        handleCategoryBlur() {
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        async getSummary(force = false) {
            try {
                const {
                    startDate, endDate, compareStartDate, compareEndDate,
                } = this.dateFilter;
                this.error = null;
                await this.fetchSummary({
                    force,
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaign: this.campaign,
                    category: this.category,
                });
                let data = this.getterSummary(startDate, endDate, compareStartDate, compareEndDate, this.campaign, this.category);
                if (data) {
                    data = data.map(item => ({
                        ...item,
                        impressionsFormatted: numberService.formatNumber(item.impressions),
                        clicksFormatted: numberService.formatNumber(item.clicks),
                        quantityFormatted: numberService.formatNumber(item.quantity),
                        amountFormatted: numberService.formatCurrency(item.amount),
                    }));
                }
                this.data = data;
                this.setItems();
            } catch (e) {
                this.error = e.message;
            }
        },
        handleSearch: debounce(function handleSearch() {
            this.setItems();
        }, 500),
        setItems() {
            this.emitFilterDefaultChange();
            let { data } = this;

            if (!data) {
                this.items = [];
                return;
            }

            if (this.search) {
                const search = deburr(this.search).toLowerCase();
                data = data.filter(item => deburr(item.name).toLowerCase().includes(search) || deburr(item.sku).toLowerCase().includes(search));
            }

            this.items = data.map(item => {
                const cr = item.clicks ? item.quantity / item.clicks * 100 : 0;
                const crCompare = item.compareClicks ? item.compareQuantity / item.compareClicks * 100 : 0;
                const crChange = numberService.calculateChangePercentage(cr, crCompare);
                const crFormatted = numberService.formatPercent(cr);
                return {
                    ...item, cr, crCompare, crChange, crFormatted,
                };
            });
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        exportData() {
            const header = [];
            header.push(`"${exportService.parseText(this.$t('dataFor'))}"`);
            header.push(`"${exportService.parseText(this.$t('exportingOn'))}"`);
            header.push(`"${exportService.parseText(this.$t('selectedStores'))}"`);

            header.push(`"${exportService.parseText(this.$t('productsSummary.productName'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.impressionsOffer'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.impressionsOffer'))} (% ${exportService.parseText(this.$t('change'))})"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.clicks'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.clicks'))} (% ${exportService.parseText(this.$t('change'))})"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.purchased'))}"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.purchased'))} (% ${exportService.parseText(this.$t('change'))})"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.price'))} [${process.env.VUE_APP_SYSTEM_CURRENCY}]"`);
            header.push(`"${exportService.parseText(this.$t('productsSummary.price'))} (% ${exportService.parseText(this.$t('change'))})"`);

            const rows = [];
            this.items.forEach((item, index) => {
                const row = [];
                if (index === 0) {
                    row.push(`"${exportService.parseText(this.dateFilter.startDate)} - ${exportService.parseText(this.dateFilter.endDate)}"`);
                    row.push(`"${exportService.parseText(new Date().toLocaleDateString())}"`);
                    row.push(`"${exportService.parseText(this.campaign.map(id => this.getCampaignNameFromId(id)).join(', '))}"`);
                } else {
                    row.push('""');
                    row.push('""');
                    row.push('""');
                }
                row.push(`"${exportService.parseText(item.name)}"`);
                row.push(`"${exportService.parseText(item.impressions)}"`);
                row.push(`"${numberService.formatNumber(item.impressionsChange, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.clicks, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.clicksChange, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.quantity, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.quantityChange, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.amount, 2, undefined, true)}"`);
                row.push(`"${numberService.formatNumber(item.amountChange, 2, undefined, true)}"`);
                rows.push(row);
            });

            exportService.exportDataCsv([header, ...rows], this.$t('productsSummary.title'));
        },
        ...mapActions({
            fetchSummary: 'reportsProducts/fetchSummary',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .search {
        max-width: 250px;
    }
</style>
