/* eslint-disable no-unused-vars */
import { generateDates, generateSegmentsDates } from '@/services/demo-services/_utils';
import demoService from '@/services/demoService';

export default {
    async fetchCompetitionReport(spaceId, startDate, endDate, products, aggregations, campaigns) {
        const { default: spaceCompetitionReport } = await import('../../mocks/spaceCompetitionReport.json');
        Object.keys(spaceCompetitionReport.products).forEach(productId => {
            const product = spaceCompetitionReport.products[productId];
            product.price = generateDates(product.price, 'YYYY-MM-DD');

            Object.keys(product.availability).forEach(campaignId => {
                product.availability[campaignId].forEach(offer => {
                    // eslint-disable-next-line no-param-reassign,prefer-destructuring
                    offer.chart = generateSegmentsDates([offer.chart])[0];
                });
            });
        });
        return spaceCompetitionReport;
    },
    async fetchSets(speceId) {
        const { default: spaceCompetitionSets } = await import('../../mocks/spaceCompetitionSets.json');
        return {
            max: 1,
            items: spaceCompetitionSets,
        };
    },

    async createSet(speceId, producerProduct, producerProducts, globalProducts) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    async updateSet(setId, producerProduct, producerProducts, globalProducts) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    async removeSet(setId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    hasSetChanged: demoService.USE_ORIGINAL_PROPERTY,
    getSetsProductsAggregationsIds: demoService.USE_ORIGINAL_PROPERTY,
    generateCompetitionReport: demoService.USE_ORIGINAL_PROPERTY,
    fetchCompetitionReports: demoService.USE_ORIGINAL_PROPERTY,
    downloadCompetitionReport: demoService.USE_ORIGINAL_PROPERTY,
};
