<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('accountSettingsPassword.title') }}</h3>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" @submit.prevent="handleSubmit" novalidate>
                <v-layout row wrap>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.oldPassword"
                            :error-messages="oldPasswordErrors"
                            @blur="$v.model.oldPassword.$touch()"
                            name="oldPassword"
                            :label="$t('accountSettingsPassword.oldPassword')"
                            :disabled="submitting"
                            type="password"
                            required
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.newPassword"
                            :error-messages="newPasswordErrors"
                            @blur="$v.model.newPassword.$touch()"
                            name="newPassword"
                            :label="$t('accountSettingsPassword.newPassword')"
                            :disabled="submitting"
                            type="password"
                            required
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.confirmPassword"
                            :error-messages="confirmPasswordErrors"
                            @blur="$v.model.confirmPassword.$touch()"
                            name="confirmPassword"
                            :label="$t('accountSettingsPassword.confirmPassword')"
                            :disabled="submitting"
                            type="password"
                            required
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                </v-layout>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <v-btn
                    class="btn-wide"
                    type="submit"
                    color="tertiary"
                    :loading="submitting"
                    :disabled="submitting"
                    dark
                    round
                >
                    {{ $t('accountSettingsPassword.submit') }}
                </v-btn>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
    name: 'account-settings-password',
    validations: {
        model: {
            oldPassword: { required },
            newPassword: { required },
            confirmPassword: {
                required,
                sameAs: sameAs('newPassword'),
            },
        },
    },
    data() {
        return {
            model: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            error: null,
        };
    },
    computed: {
        oldPasswordErrors() {
            const errors = [];
            if (!this.$v.model.oldPassword.$dirty) {
                return errors;
            }
            if (!this.$v.model.oldPassword.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        newPasswordErrors() {
            const errors = [];
            if (!this.$v.model.newPassword.$dirty) {
                return errors;
            }
            if (!this.$v.model.newPassword.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.model.confirmPassword.$dirty) {
                return errors;
            }
            if (!this.$v.model.confirmPassword.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.confirmPassword.sameAs) {
                errors.push(this.$t('validation.sameAs'));
            }
            return errors;
        },
        ...mapState({
            submitting: state => state.auth.loading.changePassword,
        }),
    },
    methods: {
        async handleSubmit() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.changePassword({ ...this.model });
                this.$v.$reset();
                this.model = {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                };
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            changePassword: 'auth/changePassword',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
