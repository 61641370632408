<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipOn }">
                    <div v-on="tooltipOn" class="trigger-container">
                        <v-btn
                            v-on="on"
                            flat
                            icon
                        >
                            <v-icon small>fa-filter</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>{{ $t('graph.activeFilters') }}</span>
            </v-tooltip>
        </template>

        <v-card>
            <v-list v-if="parsedFilters.length > 0" class="filter-summary__list-container" dense>
                <template v-for="(group, groupIndex) in parsedFilters">
                    <v-subheader :key="`group-${groupIndex}`" class="filter-summary__group-header">
                        {{ group.name }}
                        <span v-if="group.items && group.items.length > 0" class="ml-1 grey--text">
                            ({{ group.items.length }})
                        </span>
                    </v-subheader>
                    <v-list-tile
                        v-if="group.items"
                        :key="`more-items-${groupIndex}`"
                        class="filter-summary__items"
                        :class="{'filter-summary__items-list--scrollable': group.items.length > 3}">
                        <v-list class="filter-summary__items-list" dense>
                            <v-list-tile
                                v-for="(item, itemIndex) in group.items"
                                :key="`all-item-${groupIndex}-${itemIndex}`"
                            >
                                {{ item }}
                            </v-list-tile>
                        </v-list>
                    </v-list-tile>
                </template>
            </v-list>
            <v-list v-if="parsedFilters.length === 0" dense>
                <v-list-tile>{{ $t('filterSummary.noResults') }}</v-list-tile>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>

export default {
    name: 'filter-summary',
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            menu: false,
        };
    },
    computed: {
        parsedFilters() {
            return this.filters.reduce((summary, group) => {
                if (!group.items || group.items.length === 0) {
                    return summary;
                }
                return [...summary, group];
            }, []);
        },
    },
};
</script>

<style lang="scss">
    .filter-summary__list-container {
        padding: 0;
    }

    .filter-summary__group-header {
        background: rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(0, 0, 0, .12);
        border-bottom: 1px solid rgba(0, 0, 0, .12);
    }

    .filter-summary__items > .v-list__tile {
        height: auto !important;
        padding: 0;
    }

    .filter-summary__items-list {
        position: relative;
        width: 100%;
        max-height: 120px;
        padding: 0;
        overflow: auto;
    }
</style>
