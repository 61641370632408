<template>
    <div>
        <v-card-title>
            <v-layout
                row
                wrap
                align-center
                justify-space-between
            >
                <h4 class="subheading font-weight-bold">
                    {{ $t('priceDeviationOverTime.title') }}
                    <div class="left-filters">
                        <filter-summary :filters="commonFiltersSummary" />
                        <Chip
                            :disabled="loading"
                            :label="$t('filters.reset')"
                            :active="!isCommonFilterDefault"
                            @click="resetLocalFilters"
                        />
                    </div>
                </h4>
            </v-layout>
            <v-layout
                wrap
                align-center
                justify-end
            >
                <v-flex shrink>
                    <v-select
                        class="d-inline-block mr-3 mb-3 select--rows"
                        v-model="rowsToShow"
                        :items="rowsToShowOptions"
                        placeholder=" "
                        :label="$t('rowsToShow')"
                        outline
                        hide-details
                        dense
                    />
                </v-flex>
                <v-flex shrink>
                    <v-select
                        class="d-inline-block mr-3 mb-3 select--responsive"
                        v-model="viewBy"
                        :items="viewByOptions"
                        placeholder=" "
                        :label="$t('priceDeviationOverTime.viewBy')"
                        outline
                        hide-details
                        dense
                    />
                </v-flex>
                <v-flex shrink>
                    <v-select
                        class="d-inline-block mr-3 mb-3 select--responsive"
                        v-model="period"
                        :items="periodOptions"
                        :disabled="loading"
                        placeholder=" "
                        :label="$t('filters.period')"
                        outline
                        hide-details
                        dense
                    />
                </v-flex>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <template v-if="!loading && items && items.length > 0">
                <div v-for="item in items" :key="item.product.id" class="ml-5 mb-5">
                    <h4 class="price-deviation-in-time__name">{{ item.product.name }}</h4>
                    <div>{{ item.product.ean }}</div>
                    <line-chart-graph
                        v-if="item.custom.chart"
                        class="mt-3"
                        :data="item.custom.chart"
                        :options="item.custom.chartOptions"
                    />
                    <data-placeholder v-else :title="$t('priceDeviations.noRecommendedPrice')" />
                </div>
            </template>
            <data-placeholder v-else-if="!loading" :title="noResultsTitle" />
        </v-card-text>
    </div>
</template>

<script>
import {
    deburr,
    max,
    orderBy,
    sortBy,
} from 'lodash';
import { mapGetters } from 'vuex';
import eventBus from '@/services/eventBus';
import dateService from '@/services/dateService';
import numberService from '@/services/numberService';
import Chip from '@/components/common/Chip.vue';
import DataPlaceholder from '@/components/common/DataPlaceholder.vue';
import FilterSummary from '@/components/common/FilterSummary.vue';
import LineChartGraph from '@/components/graphs/core/LineChartGraph.vue';

export default {
    name: 'price-deviation-in-time',
    components: {
        LineChartGraph,
        Chip,
        DataPlaceholder,
        FilterSummary,
    },
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        campaign: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        commonFiltersSummary: {
            type: Array,
            required: true,
        },
        isCommonFilterDefault: {
            type: Boolean,
            required: true,
        },
        noResultsTitle: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            viewBy: 'price',
            viewByOptions: [
                {
                    value: 'change',
                    text: this.$t('priceDeviationOverTime.viewByChange'),
                },
                {
                    value: 'price',
                    text: this.$t('priceDeviationOverTime.viewByPrice', { currency: process.env.VUE_APP_SYSTEM_CURRENCY }),
                },
            ],
            period: 'daily',
            rowsToShowOptions: [
                {
                    value: 10,
                    text: '10',
                },
                {
                    value: 20,
                    text: '20',
                },
                {
                    value: 50,
                    text: '50',
                },
                {
                    value: 0,
                    text: this.$t('showAll'),
                },
            ],
            rowsToShow: 10,
        };
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
            ) {
                this.setLowestPeriod();
            }
        },
        viewBy: {
            immediate: true,
            handler() {
                // changing viewBy with many rows can lead to ui freeze since there is a bunch of heavy charts to recreate
                // to prevent that we should limit amount of displayed rows on that change
                this.rowsToShow = 10;
            },
        },
    },
    computed: {
        periodOptions() {
            const { startDate, endDate } = this.dateFilter;
            const groupBy = dateService.getRangeType(startDate, endDate);
            return [
                {
                    value: 'daily',
                    text: this.$t('filters.periodDaily'),
                    disabled: ['week', 'month', 'quarter'].includes(groupBy),
                },
                {
                    value: 'monthly',
                    text: this.$t('filters.periodMonthly'),
                    disabled: ['quarter'].includes(groupBy),
                },
                {
                    value: 'quarterly',
                    text: this.$t('filters.periodQuarterly'),
                },
                {
                    value: 'annually',
                    text: this.$t('filters.periodAnnually'),
                },
            ];
        },
        items() {
            if (!this.data) {
                return null;
            }

            const items = this.data.map(item => {
                const chart = this.getChartData(item);
                const chartOptions = this.getChartOptions(chart);
                return {
                    ...item,
                    custom: {
                        ...item.custom,
                        chart,
                        chartOptions,
                    },
                };
            });

            return orderBy(items, [item => item.product.price ?? 0], ['desc']).slice(0, this.rowsToShow);
        },
        ...mapGetters({
            getterActiveCampaigns: 'plugin/activeCampaignsByName',
            getterAvailableCampaigns: 'plugin/availableCampaignsNotActive',
        }),
    },
    created() {
        this.setLowestPeriod();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        getChartData(item) {
            const recommendedPrice = item.product.price;

            if (this.viewBy === 'change' && !recommendedPrice) {
                return null;
            }

            const formatName = dateService.getFormatFromPeriod(this.period);
            const periodItems = new Set();
            const header = [{ type: 'string', label: 'Date' }];
            let campaignNames = this.campaign.map(campaignId => this.getCampaignNameFromId(parseInt(campaignId, 10)));
            campaignNames.forEach(campaignName => {
                header.push({ type: 'number', label: campaignName });
                header.push({ type: 'string', role: 'tooltip', p: { html: true } });
            });
            campaignNames = sortBy(campaignNames, campaignName => deburr(campaignName.toLowerCase()));
            header.push({ type: 'number', label: this.$t('recommendedPrice', { currency: process.env.VUE_APP_SYSTEM_CURRENCY }) });
            header.push({ type: 'string', role: 'tooltip', p: { html: true } });
            const items = item.price
                .filter(data => {
                    // display all items with daily period
                    if (this.period === 'daily') {
                        return true;
                    }
                    // display only a single item from given period
                    const date = dateService.formatI18nDate(data.date, formatName);
                    if (periodItems.has(date)) {
                        return false;
                    }
                    periodItems.add(date);
                    return true;
                })
                .map(data => {
                    const date = dateService.formatI18nDate(data.date, formatName);
                    const row = [date];
                    campaignNames.forEach(campaignName => {
                        const value = data[campaignName];
                        const deviation = value !== null && recommendedPrice !== null ? (value - recommendedPrice) / recommendedPrice : null;
                        if (this.viewBy === 'price') {
                            row.push({ v: value || null, f: numberService.formatCurrency(value) });
                        } else {
                            row.push({ v: deviation || null, f: numberService.formatPercent(deviation * 100) });
                        }
                        row.push(this.createTooltip(date, campaignName, deviation, value, recommendedPrice));
                    });
                    if (this.viewBy === 'price') {
                        row.push({ v: recommendedPrice, f: recommendedPrice !== null ? numberService.formatCurrency(recommendedPrice) : this.$t('priceDeviations.noRecommendedPrice') });
                    } else {
                        row.push({ v: recommendedPrice !== null ? 0 : null, f: recommendedPrice !== null ? numberService.formatCurrency(recommendedPrice) : this.$t('priceDeviations.noRecommendedPrice') });
                    }
                    row.push(this.createRecommendedPriceTooltip(date, recommendedPrice));
                    return row;
                });
            return [header, ...items];
        },
        getChartOptions(chartItems) {
            if (!chartItems) {
                return null;
            }
            // we take first item which is headers declaration
            // ignore first element which is date
            // divide by 2 because each item has both value and tooltip element
            // minus 1 because series have 0 based index
            const recommendedPriceSeriesNo = ((chartItems[0].length - 1) / 2) - 1;

            const options = {
                selectionMode: 'single',
                tooltip: {
                    isHtml: true,
                },
                vAxes: {
                    0: {
                        title: this.viewBy === 'price' ? `${this.$t('price')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]` : `${this.$t('deviation')} [%]`,
                        format: this.viewBy === 'price' ? '#' : '#%',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
                series: {
                    [recommendedPriceSeriesNo]: {
                        color: '#000000',
                        lineWidth: 4,
                    },
                },
            };

            let hasValueOverZero = false;

            if (this.viewBy === 'price') {
                if (chartItems && chartItems.length > 1) {
                    const filteredData = chartItems.slice(1).map(row => row.slice(1).map(rowItem => rowItem.v));
                    // flatten array and get max value
                    const flatData = [].concat(...filteredData);
                    hasValueOverZero = flatData.some(value => value > 0);
                    const maxValue = max(flatData);
                    if (maxValue > 0) {
                        options.vAxes[0].format = 'short';
                    }
                }
                options.vAxes[0].viewWindow.max = hasValueOverZero ? null : 1;
            } else {
                options.vAxes[0].viewWindow.min = null;
                options.vAxes[0].viewWindow.max = null;
            }

            return options;
        },
        createTooltip(date, campaignName, deviation, price, recommendedPrice) {
            const deviationContent = recommendedPrice
                ? `${numberService.formatPercent(deviation * 100)} (${numberService.formatCurrency(price - recommendedPrice)})`
                : this.$t('priceDeviations.noRecommendedPrice');
            const recommendedPriceContent = recommendedPrice !== null ? numberService.formatCurrency(recommendedPrice) : this.$t('priceDeviations.noRecommendedPrice');
            return `<div style="padding: 5px; white-space: nowrap;">
                <strong>${campaignName}</strong>
                <div>${this.$t('price')}: <strong>${numberService.formatCurrency(price)}</strong></div>
                <div>${this.$t('recommendedPrice', { currency: process.env.VUE_APP_SYSTEM_CURRENCY })}: <strong>${recommendedPriceContent}</strong></div>
                <div>${this.$t('deviation')}: <strong>${deviationContent}</strong></div>
                <div>${this.$t('date')}: <strong>${date}</strong></div>
            </div>`;
        },
        createRecommendedPriceTooltip(date, recommendedPrice) {
            const recommendedPriceContent = recommendedPrice !== null ? numberService.formatCurrency(recommendedPrice) : this.$t('priceDeviations.noRecommendedPrice');
            return `<div style="padding: 5px; white-space: nowrap;">
                <div>${this.$t('recommendedPrice', { currency: process.env.VUE_APP_SYSTEM_CURRENCY })}: <strong>${recommendedPriceContent}</strong></div>
                <div>${this.$t('date')}: <strong>${date}</strong></div>
            </div>`;
        },
        getCampaignNameFromId(campaignId) {
            const campaign = this.getterActiveCampaigns.find(item => item.id === campaignId) || this.getterAvailableCampaigns.find(item => item.id === campaignId);
            return campaign?.name;
        },
        setLowestPeriod(force = false) {
            const rangeTypeToLowestPeriodMap = {
                day: 'daily',
                month: 'monthly',
                quarter: 'quarterly',
            };
            const { startDate, endDate } = this.dateFilter;
            const groupBy = dateService.getRangeType(startDate, endDate);

            if (
                force
                || !(
                    groupBy === 'day'
                    || (groupBy === 'month' && ['monthly', 'quarterly', 'annually'].includes(this.period))
                    || (groupBy === 'quarter' && ['quarterly', 'annually'].includes(this.period))
                )
            ) {
                this.period = rangeTypeToLowestPeriodMap[groupBy];
            }
        },
        resetLocalFilters() {
            this.$emit('resetLocalFilters');
            this.reset();
        },
        reset() {
            this.setLowestPeriod(true);
        },
    },
};
</script>

<style lang="scss">
    .price-deviation-in-time__name {
        font-size: 16px;
    }

    .select--rows {
        width: 190px;
    }
</style>
