import reportsCompetitionService from '../../services/reportsCompetitionService';
import {
    GENERATE_COMPETITION_EXPORT_REPORT_REQUEST,
    GENERATE_COMPETITION_EXPORT_REPORT_SUCCESS,
    GENERATE_COMPETITION_EXPORT_REPORT_FAILURE,
    FETCH_COMPETITION_EXPORT_REPORTS_REQUEST,
    FETCH_COMPETITION_EXPORT_REPORTS_SUCCESS,
    FETCH_COMPETITION_EXPORT_REPORTS_FAILURE,
} from '../mutationTypes';

const initialState = {
    competitionExportReports: [],
    loading: {
        generateRequest: false,
    },
};

const getters = {
    getCompetitionExport: state => state.competitionExportReports,
    getPendingCompetitionExport: state => state.competitionExportReports.filter(report => report.status.toUpperCase() === 'PENDING' || report.status.toUpperCase() === 'GENERATING'),
};

const actions = {
    async generateCompetitionExport({ commit, rootState }, payload = {}) {
        try {
            commit(GENERATE_COMPETITION_EXPORT_REPORT_REQUEST);
            const {
                startDate, endDate, compareStartDate, compareEndDate, campaign, sets,
            } = payload;

            const spaceId = rootState.space.currentSpaceId;
            const item = await reportsCompetitionService.generateCompetitionReport(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaign, sets);

            commit(GENERATE_COMPETITION_EXPORT_REPORT_SUCCESS, item);
        } catch (e) {
            commit(GENERATE_COMPETITION_EXPORT_REPORT_FAILURE);
            throw e;
        }
    },

    async fetchCompetitionExport({ commit, rootState }, payload = {}) {
        try {
            commit(FETCH_COMPETITION_EXPORT_REPORTS_REQUEST);
            const spaceId = rootState.space.currentSpaceId;
            const { items } = await reportsCompetitionService.fetchCompetitionReports(spaceId, payload);
            commit(FETCH_COMPETITION_EXPORT_REPORTS_SUCCESS, items);
        } catch (e) {
            commit(FETCH_COMPETITION_EXPORT_REPORTS_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    [GENERATE_COMPETITION_EXPORT_REPORT_REQUEST](state) {
        state.loading.generateRequest = true;
    },
    [GENERATE_COMPETITION_EXPORT_REPORT_SUCCESS](state) {
        state.loading.generateRequest = false;
    },
    [GENERATE_COMPETITION_EXPORT_REPORT_FAILURE](state) {
        state.loading.generateRequest = false;
    },
    [FETCH_COMPETITION_EXPORT_REPORTS_REQUEST](state) {
        state.loading.fetchRequest = true;
    },
    [FETCH_COMPETITION_EXPORT_REPORTS_SUCCESS](state, payload) {
        state.loading.fetchRequest = false;
        state.competitionExportReports = payload;
    },
    [FETCH_COMPETITION_EXPORT_REPORTS_FAILURE](state) {
        state.loading.fetchRequest = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
