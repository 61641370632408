import {
    sortBy, orderBy, deburr, uniq,
} from 'lodash';
import utilsService from '../../services/utilsService';
import dateService from '../../services/dateService';
import reportsSalesService from '../../services/reportsSalesService';
import {
    CLEAN_REPORTS_SALES,
    FETCH_REPORTS_SALES_PRODUCT_DETAILS_FAILURE,
    FETCH_REPORTS_SALES_PRODUCT_DETAILS_REQUEST,
    FETCH_REPORTS_SALES_PRODUCT_DETAILS_SUCCESS,
    FETCH_REPORTS_SALES_SALES_CHART_FAILURE,
    FETCH_REPORTS_SALES_SALES_CHART_REQUEST,
    FETCH_REPORTS_SALES_SALES_CHART_SUCCESS,
    FETCH_REPORTS_SALES_SALES_ALL_CHART_FAILURE,
    FETCH_REPORTS_SALES_SALES_ALL_CHART_REQUEST,
    FETCH_REPORTS_SALES_SALES_ALL_CHART_SUCCESS,
    FETCH_REPORTS_SALES_SUMMARY_FAILURE,
    FETCH_REPORTS_SALES_SUMMARY_REQUEST,
    FETCH_REPORTS_SALES_SUMMARY_SUCCESS,
    FETCH_REPORTS_SALES_TRANSACTIONS_FAILURE,
    FETCH_REPORTS_SALES_TRANSACTIONS_REQUEST,
    FETCH_REPORTS_SALES_TRANSACTIONS_SUCCESS,
    FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_REQUEST,
    FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_SUCCESS,
    FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_FAILURE,
} from '../mutationTypes';

const initialState = {
    salesChart: {},
    allSalesChart: {},
    summary: {},
    productDetails: {},
    brands: [],
    brandsByKey: {},
    transactions: {},
    featureBySourceChart: {},
    loading: {
        salesChart: false,
        allSalesChart: false,
        summary: false,
        productDetails: false,
        transactions: false,
        featureBySourceChart: false,
    },
};

const getters = {
    getSalesChart: state => (startDate, endDate, period, sources, group) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, period, sources, group);
        return state.salesChart[storageKey];
    },

    getAllSalesChart: state => (startDate, endDate, period, sources, group, feature, conversionTime) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, period, sources, group, feature, conversionTime);

        return state.allSalesChart[storageKey];
    },

    getSummary: state => (startDate, endDate, conversion, campaign, period, sources, group) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, period, sources, group);
        return state.summary[storageKey];
    },

    getProductDetails: state => (startDate, endDate, conversion, campaign, sources, group) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, sources, group);
        return state.productDetails[storageKey];
    },

    getBrandOptions: state => state.brands.map(brand => ({
        value: brand,
        text: brand,
    })),

    getBrandByKeyOptions: state => storageKey => (state.brandsByKey[storageKey] || []).map(brand => ({
        value: brand,
        text: brand,
    })),

    getTransactions: state => (startDate, endDate, conversion, campaign, group) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, group);
        return state.transactions[storageKey];
    },

    getFeatureBySourceChart: state => (startDate, endDate, sources, feature, category, group, products) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, sources, feature, category, group, products);
        return state.featureBySourceChart[storageKey];
    },
};

const actions = {
    clean({ commit }) {
        commit(CLEAN_REPORTS_SALES);
    },

    async fetchSalesChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, period, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            const storageKey = utilsService.getStorageKey(startDate, endDate, period, sources, group);
            if (!force && state.salesChart && state.salesChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SALES_SALES_CHART_REQUEST);
            const groupBy = dateService.getFormatFromPeriod(period);
            let chartData = await reportsSalesService.fetchSalesChart(spaceId, startDate, endDate, groupBy, sources, group);
            const missingDates = dateService.findMissingDates(chartData, startDate, endDate, null, dateService.getFormatFromPeriod(period), 'date');
            chartData = utilsService.fillArray(chartData, missingDates, 'date', [{ key: 'amount', value: 0 }, { key: 'orders', value: 0 }, { key: 'quantity', value: 0 }, { key: 'clicks', value: 0 }, { key: 'widget_impressions', value: 0 }]);
            chartData = sortBy(chartData, ['date']);

            commit(FETCH_REPORTS_SALES_SALES_CHART_SUCCESS, {
                chartData,
                startDate,
                endDate,
                period,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SALES_SALES_CHART_FAILURE);
            throw e;
        }
    },

    async fetchAllSalesChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, period, sources, group, feature, conversionTime,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            const storageKey = utilsService.getStorageKey(startDate, endDate, period, sources, group, feature, conversionTime);

            if (!force && state.allSalesChart && state.allSalesChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SALES_SALES_ALL_CHART_REQUEST);
            const groupBy = dateService.getFormatFromPeriod(period);
            let chartData = await reportsSalesService.fetchAllSalesChart(spaceId, startDate, endDate, groupBy, sources, group, feature, conversionTime);
            const types = Object.keys(chartData?.[0] || {}).splice(1);
            if (types?.length) {
                const missingDates = dateService.findMissingDates(chartData, startDate, endDate, null, dateService.getFormatFromPeriod(period), 'date');
                chartData = utilsService.fillArray(chartData, missingDates, 'date', types.map(type => ({ key: type, value: 0 })));
                chartData = sortBy(chartData, ['date']);
            }

            commit(FETCH_REPORTS_SALES_SALES_ALL_CHART_SUCCESS, {
                chartData,
                startDate,
                endDate,
                period,
                sources,
                group,
                feature,
                conversionTime,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SALES_SALES_ALL_CHART_FAILURE);
            throw e;
        }
    },

    async fetchSummary({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, conversion, campaign, period, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, period, sources, group);
            if (!force && state.summary && state.summary[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SALES_SUMMARY_REQUEST);
            const reqConversion = conversion === 'all' ? null : Number.parseInt(conversion, 10);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const groupBy = dateService.getFormatFromPeriod(period);
            const summaryData = await reportsSalesService.fetchSummary(spaceId, startDate, endDate, reqConversion, reqCampaign, groupBy, sources, group);

            commit(FETCH_REPORTS_SALES_SUMMARY_SUCCESS, {
                summaryData,
                startDate,
                endDate,
                conversion,
                campaign,
                period,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SALES_SUMMARY_FAILURE);
            throw e;
        }
    },

    async fetchProductDetails({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, conversion, campaign, sources, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, sources, group);
            if (!force && state.productDetails && state.productDetails[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_SALES_PRODUCT_DETAILS_REQUEST);
            const reqConversion = conversion === 'all' ? null : Number.parseInt(conversion, 10);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const productDetailsData = await reportsSalesService.fetchProductDetails(spaceId, startDate, endDate, reqConversion, reqCampaign, sources, group);

            commit(FETCH_REPORTS_SALES_PRODUCT_DETAILS_SUCCESS, {
                productDetailsData,
                startDate,
                endDate,
                conversion,
                campaign,
                sources,
                group,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SALES_PRODUCT_DETAILS_FAILURE);
            throw e;
        }
    },

    async fetchTransactions({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, conversion, campaign, page, perPage, sort, order, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, group);
            const cleanKeyResults = state.transactions[storageKey]
                && (
                    state.transactions[storageKey].perPage !== perPage
                    || state.transactions[storageKey].sort !== sort
                    || state.transactions[storageKey].order !== order
                );

            if (
                !force
                && !cleanKeyResults
                && state.transactions
                && state.transactions[storageKey]
                && state.transactions[storageKey].pages
                && state.transactions[storageKey].pages[page]
            ) {
                return;
            }

            commit(FETCH_REPORTS_SALES_TRANSACTIONS_REQUEST);
            const reqConversion = conversion === 'all' ? null : Number.parseInt(conversion, 10);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const { items, max } = await reportsSalesService.fetchTransactions(spaceId, startDate, endDate, reqConversion, reqCampaign, page, perPage, sort, order, group);

            commit(FETCH_REPORTS_SALES_TRANSACTIONS_SUCCESS, {
                items,
                max,
                startDate,
                endDate,
                conversion,
                campaign,
                page,
                perPage,
                sort,
                order,
                group,
                cleanKeyResults,
            });
        } catch (e) {
            commit(FETCH_REPORTS_SALES_TRANSACTIONS_FAILURE);
            throw e;
        }
    },

    async fetchFeatureBySourceChart({
        commit, state, rootState, dispatch,
    }, payload = {}) {
        try {
            const checkIfDataExist = key => (state.featureBySourceChart[key]?.chart && state.featureBySourceChart[key]?.pie_chart);

            const {
                force, startDate, endDate, sources, feature, category, group, products,
            } = payload;
            const spaceId = payload.spaceId || rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !feature) {
                return;
            }

            const storageKey = utilsService.getStorageKey(startDate, endDate, sources, feature, category, group, products);
            if (!force && state.featureBySourceChart && checkIfDataExist(storageKey)) {
                return;
            }

            commit(FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_REQUEST, { params: payload });

            let data = null;

            if (feature === 'cr') {
                const crKey = utilsService.getStorageKey(startDate, endDate, sources, feature, category, group, products);
                if (!checkIfDataExist(crKey)) {
                    const ordersKey = utilsService.getStorageKey(startDate, endDate, sources, 'orders', category, group, products);
                    const clicksKey = utilsService.getStorageKey(startDate, endDate, sources, 'clicks', category, group, products);
                    const promises = [];
                    if (!checkIfDataExist(ordersKey)) promises.push(dispatch('fetchFeatureBySourceChart', { ...payload, feature: 'orders' }));
                    if (!checkIfDataExist(clicksKey)) promises.push(dispatch('fetchFeatureBySourceChart', { ...payload, feature: 'clicks' }));
                    await Promise.all(promises);
                }
            } else if (feature === 'ctr') {
                const ctrKey = utilsService.getStorageKey(startDate, endDate, sources, feature, category, group, products);
                if (!checkIfDataExist(ctrKey)) {
                    const impressionsKey = utilsService.getStorageKey(startDate, endDate, sources, 'widget_impressions', category, group, products);
                    const clicksKey = utilsService.getStorageKey(startDate, endDate, sources, 'clicks', category, group, products);
                    const promises = [];
                    if (!checkIfDataExist(impressionsKey)) promises.push(dispatch('fetchFeatureBySourceChart', { ...payload, feature: 'widget_impressions' }));
                    if (!checkIfDataExist(clicksKey)) promises.push(dispatch('fetchFeatureBySourceChart', { ...payload, feature: 'clicks' }));
                    await Promise.all(promises);
                }
            } else {
                data = await reportsSalesService.fetchFeatureBySourceChart(spaceId, startDate, endDate, sources, feature, category, group, products);
            }

            commit(FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_SUCCESS, { data, params: payload });

            return data; // eslint-disable-line consistent-return
        } catch (e) {
            commit(FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_REPORTS_SALES
    // -----------------------------------------
    [CLEAN_REPORTS_SALES](state) {
        state.salesChart = {};
        state.summary = {};
        state.productDetails = {};
        state.brands = [];
        state.transactions = {};
        state.featureBySourceChart = {};
        state.loading.salesChart = false;
        state.loading.summary = false;
        state.loading.productDetails = false;
        state.loading.transactions = false;
        state.loading.featureBySourceChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SALES_SALES_CHART
    // -----------------------------------------
    [FETCH_REPORTS_SALES_SALES_CHART_REQUEST](state) {
        state.loading.salesChart = true;
    },
    [FETCH_REPORTS_SALES_SALES_CHART_SUCCESS](state, payload) {
        const {
            chartData, startDate, endDate, period, sources, group,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, period, sources, group);

        state.loading.salesChart = false;
        state.salesChart = {
            ...state.salesChart,
            [storageKey]: chartData.map(item => ({
                ...item,
                ctr: item.widget_impressions ? item.clicks / item.widget_impressions * 100 : 0,
                cr: item.clicks ? item.orders / item.clicks * 100 : 0,
            })),
        };
    },
    [FETCH_REPORTS_SALES_SALES_CHART_FAILURE](state) {
        state.loading.salesChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SALES_SALES_ALL_CHART
    // -----------------------------------------
    [FETCH_REPORTS_SALES_SALES_ALL_CHART_REQUEST](state) {
        state.loading.allSalesChart = true;
    },
    [FETCH_REPORTS_SALES_SALES_ALL_CHART_SUCCESS](state, payload) {
        const {
            chartData, startDate, endDate, period, sources, group, feature, conversionTime,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, period, sources, group, feature, conversionTime);

        state.loading.allSalesChart = false;
        state.allSalesChart = {
            ...state.allSalesChart,
            [storageKey]: chartData,
        };
    },
    [FETCH_REPORTS_SALES_SALES_ALL_CHART_FAILURE](state) {
        state.loading.allSalesChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SALES_SUMMARY
    // -----------------------------------------
    [FETCH_REPORTS_SALES_SUMMARY_REQUEST](state) {
        state.loading.summary = true;
    },
    [FETCH_REPORTS_SALES_SUMMARY_SUCCESS](state, payload) {
        const {
            summaryData, startDate, endDate, conversion, campaign, period, sources, group,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, period, sources, group);
        const summaryParsed = summaryData.map(item => {
            const ctr = item.views ? item.clicks / item.views * 100 : 0;
            const cr = item.clicks ? item.transactions / item.clicks * 100 : 0;
            return {
                ...item,
                ctr,
                cr,
            };
        });

        state.loading.summary = false;
        state.summary = {
            ...state.summary,
            [storageKey]: summaryParsed,
        };
    },
    [FETCH_REPORTS_SALES_SUMMARY_FAILURE](state) {
        state.loading.summary = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SALES_PRODUCT_DETAILS
    // -----------------------------------------
    [FETCH_REPORTS_SALES_PRODUCT_DETAILS_REQUEST](state) {
        state.loading.productDetails = true;
    },
    [FETCH_REPORTS_SALES_PRODUCT_DETAILS_SUCCESS](state, payload) {
        const {
            productDetailsData, startDate, endDate, conversion, campaign, sources, group,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, sources, group);
        const brands = [];
        const products = productDetailsData.map(product => {
            const quantity = parseFloat(product.quantity);
            const value = parseFloat(product.value);

            brands.push(product.brand);

            return {
                date: product.date,
                type: product.type,
                id: product.id,
                product: product.product,
                sku: product.sku,
                ean: product.ean,
                brand: product.brand,
                conversionTime: product.conversionTime,
                campaign: product.campaign,
                quantity: Number.isNaN(quantity) ? null : quantity,
                value: Number.isNaN(value) ? null : value,
            };
        });
        const uniqBrands = uniq(brands.filter(brand => !!brand));
        const orderedBrands = orderBy(uniqBrands, [item => deburr(item.toLowerCase())], ['asc']);

        state.loading.productDetails = false;
        state.brands = orderedBrands;
        state.productDetails = {
            ...state.productDetails,
            [storageKey]: products,
        };
        state.brandsByKey = {
            ...(state.brandsByKey || {}),
            [storageKey]: orderedBrands,
        };
    },
    [FETCH_REPORTS_SALES_PRODUCT_DETAILS_FAILURE](state) {
        state.loading.productDetails = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SALES_TRANSACTIONS
    // -----------------------------------------
    [FETCH_REPORTS_SALES_TRANSACTIONS_REQUEST](state) {
        state.loading.transactions = true;
    },
    [FETCH_REPORTS_SALES_TRANSACTIONS_SUCCESS](state, payload) {
        const {
            items, max, startDate, endDate, conversion, campaign, page, perPage, sort, order, group, cleanKeyResults,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, conversion, ...campaign, group);
        const keyTransactions = (cleanKeyResults || !state.transactions[storageKey]) ? {} : state.transactions[storageKey];
        keyTransactions.total = max;
        keyTransactions.perPage = perPage;
        keyTransactions.sort = sort;
        keyTransactions.order = order;
        keyTransactions.group = group;
        keyTransactions.pages = keyTransactions.pages ? keyTransactions.pages : {};
        keyTransactions.pages[page] = items?.map(item => ({
            ...item,
            ownQuantity: (item.share || 0) / 100 * item.quantity,
            otherQuantity: (item.otherShare || 0) / 100 * item.quantity,
            unknownQuantity: (100 - ((item.share || 0) + (item.otherShare || 0))) / 100 * item.quantity,
        }));

        state.loading.transactions = false;
        state.transactions = {
            ...state.transactions,
            [storageKey]: keyTransactions,
        };
    },
    [FETCH_REPORTS_SALES_TRANSACTIONS_FAILURE](state) {
        state.loading.transactions = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART
    // -----------------------------------------
    [FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_REQUEST](state, { params }) {
        state.loading.featureBySourceChart = true;
        const {
            startDate, endDate, sources, feature, category, group, products,
        } = params;
        const storageKey = utilsService.getStorageKey(startDate, endDate, sources, feature, category, group, products);
        state.featureBySourceChart[storageKey] = {};
    },
    [FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_SUCCESS](state, { data, params }) {
        state.loading.featureBySourceChart = false;
        const {
            startDate, endDate, sources, feature, category, group, products,
        } = params;
        const storageKey = utilsService.getStorageKey(startDate, endDate, sources, feature, category, group, products);
        state.featureBySourceChart[storageKey] = data || {};

        const ordersKey = utilsService.getStorageKey(startDate, endDate, sources, 'orders', category, group, products);
        const clicksKey = utilsService.getStorageKey(startDate, endDate, sources, 'clicks', category, group, products);
        const impressionsKey = utilsService.getStorageKey(startDate, endDate, sources, 'widget_impressions', category, group, products);
        const checkIfDataExist = key => (state.featureBySourceChart[key]?.chart && state.featureBySourceChart[key]?.pie_chart);

        // CR
        if (checkIfDataExist(ordersKey) && checkIfDataExist(clicksKey)) {
            const dataChart = state.featureBySourceChart[clicksKey].chart.map((item, index) => {
                const ordersData = state.featureBySourceChart[ordersKey];
                const clicksData = state.featureBySourceChart[clicksKey];
                const chartSources = (sources && sources?.split(',')) || Object.keys(item).filter(source => source !== 'date');
                const chartItem = {};
                chartItem.date = clicksData.chart[index]?.date;
                chartSources.forEach(source => {
                    chartItem[source] = clicksData.chart[index]?.[source] ? (ordersData.chart[index][source] / clicksData.chart[index][source] * 100) : 0;
                });
                return chartItem;
            });
            const dataPieChart = state.featureBySourceChart[clicksKey].pie_chart.map((item, index) => {
                const ordersData = state.featureBySourceChart[ordersKey];
                const clicksData = state.featureBySourceChart[clicksKey];
                return [
                    clicksData.pie_chart[index]?.[0] || ordersData.pie_chart[index]?.[0],
                    clicksData.pie_chart[index]?.[1] ? (ordersData.pie_chart[index][1] / clicksData.pie_chart[index][1] * 100) : 0,
                ];
            });
            const crKey = utilsService.getStorageKey(startDate, endDate, sources, 'cr', category, group, products);
            state.featureBySourceChart[crKey] = { chart: dataChart, pie_chart: dataPieChart };
        }

        // CTR
        if (checkIfDataExist(impressionsKey) && checkIfDataExist(clicksKey)) {
            const dataChart = state.featureBySourceChart[clicksKey].chart.map((item, index) => {
                const impressionsData = state.featureBySourceChart[impressionsKey];
                const clicksData = state.featureBySourceChart[clicksKey];
                const chartSources = (sources && sources?.split(',')) || Object.keys(item).filter(source => source !== 'date');
                const chartItem = {};
                chartItem.date = clicksData.chart[index]?.date;
                chartSources.forEach(source => {
                    chartItem[source] = clicksData.chart[index]?.[source] ? (impressionsData.chart[index][source] / clicksData.chart[index][source] * 100) : 0;
                });
                return chartItem;
            });
            const dataPieChart = state.featureBySourceChart[clicksKey].pie_chart.map((item, index) => {
                const impressionsData = state.featureBySourceChart[impressionsKey];
                const clicksData = state.featureBySourceChart[clicksKey];
                return [
                    clicksData.pie_chart[index]?.[0] || impressionsData.pie_chart[index]?.[0],
                    clicksData.pie_chart[index]?.[1] ? (impressionsData.pie_chart[index][1] / clicksData.pie_chart[index][1] * 100) : 0,
                ];
            });
            const ctrKey = utilsService.getStorageKey(startDate, endDate, sources, 'ctr', category, group, products);
            state.featureBySourceChart[ctrKey] = { chart: dataChart, pie_chart: dataPieChart };
        }
    },
    [FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_FAILURE](state) {
        state.loading.featureBySourceChart = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
