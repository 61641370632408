import { render, staticRenderFns } from "./PluginCampaignsList.vue?vue&type=template&id=bd9e8566&scoped=true&"
import script from "./PluginCampaignsList.vue?vue&type=script&lang=js&"
export * from "./PluginCampaignsList.vue?vue&type=script&lang=js&"
import style0 from "./PluginCampaignsList.vue?vue&type=style&index=0&id=bd9e8566&lang=scss&scoped=true&"
import style1 from "./PluginCampaignsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd9e8566",
  null
  
)

export default component.exports