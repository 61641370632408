import {
    deburr, orderBy, sortBy, uniq, upperFirst,
} from 'lodash';
import reportsProductsService from '../../services/reportsProductsService';
import utilsService from '../../services/utilsService';
import dateService from '../../services/dateService';
import numberService from '../../services/numberService';
import {
    CLEAN_REPORTS_PRODUCTS,
    FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_FAILURE,
    FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_REQUEST,
    FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_SUCCESS,
    FETCH_REPORTS_PRODUCTS_RANKING_FAILURE,
    FETCH_REPORTS_PRODUCTS_RANKING_REQUEST,
    FETCH_REPORTS_PRODUCTS_RANKING_SUCCESS,
    FETCH_REPORTS_PRODUCTS_SALES_CHART_FAILURE,
    FETCH_REPORTS_PRODUCTS_SALES_CHART_REQUEST,
    FETCH_REPORTS_PRODUCTS_SALES_CHART_SUCCESS,
    FETCH_REPORTS_PRODUCTS_SUMMARY_FAILURE,
    FETCH_REPORTS_PRODUCTS_SUMMARY_REQUEST,
    FETCH_REPORTS_PRODUCTS_SUMMARY_SUCCESS,
} from '../mutationTypes';

const getRankingStoreName = rankingType => `ranking${upperFirst(rankingType)}`;

const initialState = {
    salesChart: {},
    summary: {},
    cartAnalysis: {},
    brands: [],
    rankingImpressions: {},
    rankingClicks: {},
    rankingQuantity: {},
    rankingAmount: {},
    loading: {
        salesChart: false,
        summary: false,
        cartAnalysis: false,
        ranking: false,
    },
};

const getters = {
    getSalesChart: state => (startDate, endDate, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
        return state.salesChart[storageKey];
    },

    getSummary: state => (startDate, endDate, compareStartDate, compareEndDate, campaign, category) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category);
        return state.summary[storageKey];
    },

    getCartAnalysis: state => (startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns);
        return state.cartAnalysis[storageKey];
    },

    getBrandOptions: state => state.brands.map(brand => ({
        value: brand,
        text: brand,
    })),

    getRanking: state => (startDate, endDate, compareStartDate, compareEndDate, rankingType, campaign, category, group) => {
        const rankingStoreName = getRankingStoreName(rankingType);
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category, ...group);
        return state[rankingStoreName][storageKey];
    },
};

const actions = {
    clean({ commit }) {
        commit(CLEAN_REPORTS_PRODUCTS);
    },

    async fetchSalesChart({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);
            if (!force && state.salesChart && state.salesChart[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_PRODUCTS_SALES_CHART_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            let salesChartData = await reportsProductsService.fetchSalesChart(spaceId, startDate, endDate, reqCampaign, reqCategory);
            const missingDates = dateService.findMissingDates(salesChartData, startDate, endDate);
            salesChartData = utilsService.fillArray(salesChartData, missingDates, 'date', [{ key: 'amount', value: 0 }, { key: 'quantity', value: 0 }]);
            salesChartData = sortBy(salesChartData, ['date']);

            commit(FETCH_REPORTS_PRODUCTS_SALES_CHART_SUCCESS, {
                salesChartData,
                startDate,
                endDate,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_PRODUCTS_SALES_CHART_FAILURE);
            throw e;
        }
    },

    async fetchSummary({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, campaign, category,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            category.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category);
            if (!force && state.summary && state.summary[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_PRODUCTS_SUMMARY_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const summaryData = await reportsProductsService.fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory);

            commit(FETCH_REPORTS_PRODUCTS_SUMMARY_SUCCESS, {
                summaryData,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                campaign,
                category,
            });
        } catch (e) {
            commit(FETCH_REPORTS_PRODUCTS_SUMMARY_FAILURE);
            throw e;
        }
    },

    async fetchCartAnalysis({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns);
            if (!force && state.cartAnalysis && state.cartAnalysis[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_REQUEST);
            const reqConversion = conversion === 'all' ? null : Number.parseInt(conversion, 10);
            const cartAnalysisData = await reportsProductsService.fetchCartAnalysis(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqConversion, campaigns);

            commit(FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_SUCCESS, {
                cartAnalysisData,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                conversion,
                campaigns,
            });
        } catch (e) {
            commit(FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_FAILURE);
            throw e;
        }
    },

    async fetchRanking({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, compareStartDate, compareEndDate, rankingType, campaign, category, group,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate || !compareStartDate || !compareEndDate || !rankingType) {
                return;
            }

            campaign.sort();
            category.sort();
            group.sort();
            const rankingStoreName = getRankingStoreName(rankingType);
            const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category, ...group);
            if (!force && state[rankingStoreName] && state[rankingStoreName][storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_PRODUCTS_RANKING_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const reqCategory = category.length > 0 ? category.join(',') : null;
            const reqGroup = group.length > 0 ? group.join(',') : null;

            let rankingData;
            switch (rankingType) {
                case 'impressions':
                    rankingData = await reportsProductsService.fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                case 'clicks':
                    rankingData = await reportsProductsService.fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                case 'quantity':
                    rankingData = await reportsProductsService.fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                case 'amount':
                    rankingData = await reportsProductsService.fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, reqCampaign, reqCategory, reqGroup);
                    break;
                default:
                    break;
            }

            commit(FETCH_REPORTS_PRODUCTS_RANKING_SUCCESS, {
                rankingData,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                rankingType,
                campaign,
                category,
                group,
            });
        } catch (e) {
            commit(FETCH_REPORTS_PRODUCTS_RANKING_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_REPORTS_PRODUCTS
    // -----------------------------------------
    [CLEAN_REPORTS_PRODUCTS](state) {
        state.salesChart = {};
        state.summary = {};
        state.cartAnalysis = {};
        state.brands = [];
        state.rankingImpressions = {};
        state.rankingClicks = {};
        state.rankingQuantity = {};
        state.rankingAmount = {};
        state.loading.salesChart = false;
        state.loading.summary = false;
        state.loading.cartAnalysis = false;
        state.loading.ranking = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_PRODUCTS_SALES_CHART
    // -----------------------------------------
    [FETCH_REPORTS_PRODUCTS_SALES_CHART_REQUEST](state) {
        state.loading.salesChart = true;
    },
    [FETCH_REPORTS_PRODUCTS_SALES_CHART_SUCCESS](state, payload) {
        const {
            salesChartData, startDate, endDate, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign, ...category);

        state.loading.salesChart = false;
        state.salesChart = {
            ...state.salesChart,
            [storageKey]: salesChartData,
        };
    },
    [FETCH_REPORTS_PRODUCTS_SALES_CHART_FAILURE](state) {
        state.loading.salesChart = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_PRODUCTS_SUMMARY
    // -----------------------------------------
    [FETCH_REPORTS_PRODUCTS_SUMMARY_REQUEST](state) {
        state.loading.summary = true;
    },
    [FETCH_REPORTS_PRODUCTS_SUMMARY_SUCCESS](state, payload) {
        const {
            summaryData, startDate, endDate, compareStartDate, compareEndDate, campaign, category,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category);
        const summaryParsed = summaryData.map(product => {
            const impressionsChange = numberService.calculateChangePercentage(product.impressions, product.compareImpressions);
            const clicksChange = numberService.calculateChangePercentage(product.clicks, product.compareClicks);
            const quantityChange = numberService.calculateChangePercentage(product.quantity, product.compareQuantity);
            const amountChange = numberService.calculateChangePercentage(product.amount, product.compareAmount);

            return {
                ...product,
                impressionsChange,
                clicksChange,
                quantityChange,
                amountChange,
            };
        });

        state.loading.summary = false;
        state.summary = {
            ...state.summary,
            [storageKey]: summaryParsed,
        };
    },
    [FETCH_REPORTS_PRODUCTS_SUMMARY_FAILURE](state) {
        state.loading.summary = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_PRODUCTS_CART_ANALYSIS
    // -----------------------------------------
    [FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_REQUEST](state) {
        state.loading.cartAnalysis = true;
    },
    [FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_SUCCESS](state, payload) {
        const {
            cartAnalysisData, startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, conversion, campaigns);
        const brands = [];
        const products = cartAnalysisData.map(product => {
            const quantity = parseFloat(product.quantity);
            const quantityCompare = parseFloat(product.quantityCompare);
            const quantityChange = numberService.calculateChangePercentage(quantity, quantityCompare);
            const amount = parseFloat(product.amount);
            const amountCompare = parseFloat(product.amountCompare);
            const amountChange = numberService.calculateChangePercentage(amount, amountCompare);

            brands.push(product.brand);

            return {
                id: product.id,
                name: product.name,
                sku: product.sku,
                brand: product.brand,
                campaign: product.campaign,
                quantity: Number.isNaN(quantity) ? null : quantity,
                quantityCompare: Number.isNaN(quantityCompare) ? null : quantityCompare,
                quantityChange,
                amount: Number.isNaN(amount) ? null : amount,
                amountCompare: Number.isNaN(amountCompare) ? null : amountCompare,
                amountChange,
            };
        });
        const uniqBrands = uniq(brands);
        const orderedBrands = orderBy(uniqBrands, [item => deburr((item || '').toLowerCase())], ['asc']);

        state.loading.cartAnalysis = false;
        state.brands = orderedBrands;
        state.cartAnalysis = {
            ...state.cartAnalysis,
            [storageKey]: products,
        };
    },
    [FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_FAILURE](state) {
        state.loading.cartAnalysis = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_PRODUCTS_RANKING
    // -----------------------------------------
    [FETCH_REPORTS_PRODUCTS_RANKING_REQUEST](state) {
        state.loading.ranking = true;
    },
    [FETCH_REPORTS_PRODUCTS_RANKING_SUCCESS](state, payload) {
        const {
            rankingData, startDate, endDate, compareStartDate, compareEndDate, rankingType, campaign, category, group,
        } = payload;
        const rankingStoreName = getRankingStoreName(rankingType);
        const storageKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate, ...campaign, ...category, ...group);
        const parsedRankingData = rankingData.map(item => {
            const summary = parseFloat(item.summary);
            const summaryComparison = parseFloat(item.summaryComparison);
            const summaryChange = numberService.calculateChangePercentage(summary, summaryComparison);

            return {
                ...item,
                summary: Number.isNaN(summary) ? null : summary,
                summaryComparison: Number.isNaN(summaryComparison) ? null : summaryComparison,
                summaryChange,
            };
        });

        state.loading.ranking = false;
        state[rankingStoreName] = {
            ...state[rankingStoreName],
            [storageKey]: parsedRankingData,
        };
    },
    [FETCH_REPORTS_PRODUCTS_RANKING_FAILURE](state) {
        state.loading.ranking = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
