import demoService from '@/services/demoService';
import reportsShopsServiceDemo from '@/services/demo-services/reportsShopsServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const reportsShopsService = {

    async fetchImpressionsReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/impressions-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchClicksReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/clicks-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchOrdersReport(spaceId, startDate, endDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-orders-report`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchQuantityReport(spaceId, startDate, endDate, conversion, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-quantity-report`,
                {
                    startDate,
                    endDate,
                    conversion,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAmountReport(spaceId, startDate, endDate, conversion, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/sales-amount-report`,
                {
                    startDate,
                    endDate,
                    conversion,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/campaign-reports`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/impressions-campaign-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/clicks-campaign-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/amount-campaign-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/quantity-campaign-ranking`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    campaigns,
                    categories,
                    groups,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(reportsShopsService, reportsShopsServiceDemo, 'reportsShopsService');
export default reportsShopsService;
