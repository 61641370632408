<template>
    <span class="chip" :class="{'chip--active': active}" @click="handleClick">
        {{ label }}
        <v-icon class="ml-2 chip__icon">fas fa-times-circle</v-icon>
    </span>
</template>

<script>

export default {
    name: 'chip',
    props: {
        label: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick(e) {
            e.stopPropagation();
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
    .chip {
        display: inline-flex;
        align-items: center;
        height: 18px;
        padding: 0 7px;
        background: $tertiary-color;
        border-radius: 10px;
        font-size: 12px;;
        color: $text-light-color;
        cursor: pointer;
    }

    .chip--active {
        background: $secondary-color;
    }

    .chip__icon {
        font-size: 12px !important;
        color: $text-light-color !important;
        opacity: 1 !important;
    }
</style>
