export default {
    validation: {
        required: 'This field is required.',
        maxLength: 'Value cannot be longer than {limit} characters.',
        minLength: 'Value cannot be shorter than {limit} characters.',
        email: 'Please enter a valid email address.',
        url: 'Please enter a valid URL.',
        sameAs: 'Please enter the same value again.',
        regex: 'Invalid format.',
        numeric: 'Please enter a valid number.',
        hexColor: 'Please enter a valid HEX color (e.g. #000000).',
        decimal: 'Please enter a valid number.',
        minValue: 'Value cannot be smaller than {min}.',
    },
    error: {
        unavailableInDemo: 'This function is unavailable in demo.',
        default: 'Something went wrong. Please try again or contact support.',
        network: 'Unable to connect to the server.',
        notFound: 'Unable to connect to the server.',
        unauthorized: 'No required permissions.',
        ERROR: '@:error.default',
        BAD_CREDENTIALS: 'Incorrect credentials.',
        BAD_REQUEST: 'Bad Request.',
        INVALID_REQUEST_DATA: 'Invalid data.',
        USER_LOCKED: 'User account is inactive.',
    },
    api_errors: {
        bad_credentials: {
            base: 'Incorrect credentials.',
        },
        conflict: {
            transaction: 'Transaction already verified.',
        },
        not_found: {
            base: 'Not found',
            brand: "Brand doesn't exist",
            buybox: "Widget doesn't exist",
            campaign: "Campaign doesn't exist",
            campaign_api_token: "Campaign doesn't have API Token. Generate one first.",
            campaign_not_related_buybox: "Campaign isn't related to Buybox!",
            campaign_space: "Relation doesn't exist!",
            category: "Category doesn't exist",
            commission: "Commission doesn't exist",
            cookie: "Cookie doesn't exist",
            document: "Document doesn't exist",
            manufacturer: "Manufacturer doesn't exist",
            offer: "Offer doesn't exist",
            offer_campaign: "Offer campaign doesn't exist",
            offer_feed: "Offer feed doesn't exist",
            product: "Product doesn't exist",
            report: "Report doesn't exist",
            space: "Space doesn't exist",
            space_no_buybox: 'Space has no Buybox!',
            space_no_category: 'Space has no category!',
            space_no_token: "Space doesn't have API Token",
            space_token: 'Unable to find space',
            subscription: "Subscription doesn't exist",
            token: 'Token is expired!',
            transaction: "Transaction doesn't exist",
            transaction_invalid_amount: 'Invalid transaction amount!',
            user: "User doesn't exist",
        },
        unprocessable_entity: {
            amount_below_minimum: 'Amount below minimum',
            campaign_assign_buybox: "Campaign can't be assign to Buybox",
            campaign_assign_commission: "Commission isn't assigned to Campaign!",
            email_not_confirmed: 'Email is not confirmed!',
            locked_user: 'User Account is locked!',
            missing_personal_data: 'Missing personal data',
            space_accept: "You can't accept that Space!",
            space_reject: "You can't reject that Space!",
            space_subscription: 'Space has active subscription',
            report_not_generated: "Report isn't generated!",
            user: {
                email: {
                    exist: 'User with username/email already exists!',
                },
                not: {
                    advertiser: 'User is not an advertiser!',
                    publisher: 'User is not an publisher!',
                },
            },
            wrong_document_type: 'Wrong document type',
        },
        access_denied: {
            base: 'Access Denied.',
            feed: "You can't create feed for that Buybox!",
            feature_disabled: 'Feature disabled',
            functionality_inactive: "Functionality isn't active!",
            token: 'Token is expired!',
        },
        http: {
            bi_api: 'BI API Problem',
            database: 'Database error',
            not_implemented: 'Not Implemented',
        },
        bad_request: {
            amount_gross: "'gross' can't be negative!",
            amount_net: "'net' can't be negative!",
            amount_price_limit: 'Price bottom limit should not exceed the top limit',
            campaign_id: 'Campaign ID not set!',
            cookie: 'Cookie ID not set!',
            email: "Email can't be empty!",
            image_url: 'Malformed image URL',
            order: 'Order ID not set!',
            product: {
                commission: {
                    id: 'Product commission ID not set!',
                },
                gross: 'Product gross value not set!',
                id: 'Product ID not set!',
                net: 'Product net value not set!',
                quantity: 'Product quantity not set!',
                specification: 'Incorrect products specification!',
            },
            quantity: {
                value: 'Quantity has negative value!',
            },
            query: 'Empty query',
            refresh_token: 'Missing search parameter',
            required_parameters: 'Required parameters are missing!',
            response_format: 'Unsupported response format!',
            search: 'Missing search parameter',
            space_id: 'Space ID not set!',
            url_missing: 'Missing url parameter',
        },
        form: {
            should_be_false: 'This value should be false.',
            should_be_true: 'This value should be true.',
            should_be_type: 'This value should be of type {type}.',
            should_be_blank: 'This value should be blank.',
            select_wrong_choice: 'The value you selected is not a valid choice.',
            select_wrong_min_limit: 'You must select at least {limit}.',
            select_wrong_max_limit: 'You must select at most {limit}.',
            wrong_values: 'One or more of the given values is invalid.',
            field_not_expected: 'This field was not expected.',
            field_missing: 'This field is missing.',
            not_valid_date: 'This value is not a valid date.',
            not_valid_datetime: 'This value is not a valid datetime.',
            not_valid_email: 'This value is not a valid email address.',
            file_not_found: 'The file could not be found.',
            file_not_readable: 'The file is not readable.',
            file_not_readable_too_large: 'The file is too large ({size} {suffix}). Allowed maximum size is {limit} {suffix}.',
            file_invalid_mimetype: 'The mime type of the file is invalid ({type}). Allowed mime types are {types}.',
            should_be_limit_max: 'This value should be {limit} or less.',
            should_be_long_max: 'This value is too long. It should have {limit} characters or less.',
            should_be_limit_min: 'This value should be {limit} or more.',
            should_be_long_min: 'This value is too short. It should have {limit} characters or more.',
            not_blank: 'This value should not be blank.',
            not_null: 'This value should not be null.',
            null: 'This value should be null.',
            not_valid: 'This value is not valid.',
            not_valid_time: 'This value is not a valid time.',
            not_valid_url: 'This value is not a valid URL.',
            two_values_equal: 'The two values should be equal.',
            file_large_limit: 'The file is too large. Allowed maximum size is {limit} {suffix}.',
            file_large: 'The file is too large.',
            file_not_uploaded: 'The file could not be uploaded.',
            not_valid_number: 'This value should be a valid number.',
            not_valid_image: 'This file is not a valid image.',
            not_valid_ip: 'This is not a valid IP address.',
            not_valid_language: 'This value is not a valid language.',
            not_valid_locale: 'This value is not a valid locale.',
            not_valid_country: 'This value is not a valid country.',
            already_used: 'This value is already used.',
            image_size_not_detected: 'The size of the image could not be detected.',
            image_width_too_big: 'The image width is too big ({width}px). Allowed maximum width is {max_width}px.',
            image_width_too_small: 'The image width is too small ({width}px). Minimum width expected is {min_width}px.',
            image_height_too_big: 'The image height is too big ({height}px). Allowed maximum height is {max_height}px.',
            image_height_too_small: 'The image height is too small ({height}px). Minimum height expected is {min_height}px.',
            should_be_user_current_password: "This value should be the user's current password.",
            should_have_exactly_limit: 'This value should have exactly {limit} characters.',
            file_only_partially_uploaded: 'The file was only partially uploaded.',
            file_no_uploaded: 'No file was uploaded.',
            no_temporary_folder: 'No temporary folder was configured in php.ini.',
            temporary_cant_write: 'Cannot write temporary file to disk.',
            PHP_extension_caused_upload_fail: 'A PHP extension caused the upload to fail.',
            should_contain_max: 'This collection should contain {limit} elements or more.',
            should_contain_min: 'This collection should contain {limit} elements or less.',
            should_contain_exactly: 'This collection should contain exactly {limit} elements.',
            not_valid_card_number: 'Invalid card number.',
            not_valid_card_number_or_type: 'Unsupported card type or invalid card number.',
            not_valid_IBAN: 'This is not a valid International Bank Account Number (IBAN).',
            'not_valid_ISBN-10': 'This value is not a valid ISBN-10.',
            'not_valid_ISBN-13': 'This value is not a valid ISBN-13.',
            'not_valid_ISBN-10_SBN-13': 'This value is neither a valid ISBN-10 nor a valid ISBN-13.',
            not_valid_ISSN: 'This value is not a valid ISSN.',
            not_valid_currency: 'This value is not a valid currency.',
            should_be_equal: 'This value should be equal to {compared_value}.',
            should_be_greater: 'This value should be greater than {compared_value}.',
            should_be_greater_or_equal: 'This value should be greater than or equal to {compared_value}.',
            should_be_identical: 'This value should be identical to {compared_value_type} {compared_value}.',
            should_be_less: 'This value should be less than {compared_value}.',
            should_be_less_or_equal: 'This value should be less than or equal to {compared_value}.',
            should_not_be_qual: 'This value should not be equal to {compared_value}.',
            should_not_be_identical: 'This value should not be identical to {compared_value_type} {compared_value}.',
            image_ratio_too_big: 'The image ratio is too big ({ratio}). Allowed maximum ratio is {max_ratio}.',
            image_ratio_too_small: 'The image ratio is too small ({ratio}). Minimum ratio expected is {min_ratio}.',
            image_square: 'The image is square ({width}x{height}px). Square images are not allowed.',
            image_landscape_oriented: 'The image is landscape oriented ({width}x{height}px). Landscape oriented images are not allowed.',
            image_portrait_oriented: 'The image is portrait oriented ({width}x{height}px). Portrait oriented images are not allowed.',
            not_empty: 'An empty file is not allowed.',
            wrong_host: 'The host could not be resolved.',
            wrong_charset: 'This value does not match the expected {charset} charset.',
            not_valid_BIC: 'This is not a valid Business Identifier Code (BIC).',
            error: 'Error',
            not_valid_UUID: 'This is not a valid UUID.',
            should_be_multiple: 'This value should be a multiple of {compared_value}.',
            BIC_not_associated_IBAN: 'This Business Identifier Code (BIC) is not associated with IBAN {iban}.',
            should_be_JSON: 'This value should be valid JSON.',
            collection_should_contain_unique_elements: 'This collection should contain only unique elements.',
            should_be_positive: 'This value should be positive.',
            should_be_positive_or_zero: 'This value should be either positive or zero.',
            should_be_negative: 'This value should be negative.',
            should_be_negative_or_zero: 'This value should be either negative or zero.',
            not_valid_timezone: 'This value is not a valid timezone.',
            password_leaked_use_another: 'This password has been leaked in a data breach, it must not be used. Please use another password.',
            should_be_between: 'This value should be between {min} and {max}.',
            not_valid_hostname: 'This value is not a valid hostname.',
            should_be_multiply: 'The number of elements in this collection should be a multiple of {compared_value}.',
            should_satisfy_following_constraints: 'This value should satisfy at least one of the following constraints',
            elements_should_have_own_constraints: 'Each element of this collection should satisfy its own set of constraints.',
            not_valid_ISIN: 'This value is not a valid International Securities Identification Number (ISIN).',
            should_not_contain_extra_fields: 'This form should not contain extra fields.',
            not_valid_EAN: 'This value is not a valid European Article Number (EAN).',
            not_valid_regexp: "The value isn't a valid regexp.",
            value_already_used: 'This value is already used.',
        },
        form_type: {
            birthdate: 'Please enter a valid birthdate.',
            checkbox: 'The checkbox has an invalid value.',
            choice: 'The selected choice is invalid.',
            collection: 'The collection is invalid.',
            color: 'Please select a valid color.',
            country: 'Please select a valid country.',
            currency: 'Please select a valid currency.',
            date_interval: 'Please choose a valid date interval.',
            date_time: 'Please enter a valid date and time.',
            date: 'Please enter a valid date.',
            email: 'Please enter a valid email address.',
            file: 'Please select a valid file.',
            form: 'This value is not valid.',
            hidden: 'The hidden field is invalid.',
            integer: 'Please enter an integer.',
            language: 'Please select a valid language.',
            locale: 'Please select a valid locale.',
            money: 'Please enter a valid money amount.',
            password: 'The password is invalid.',
            percentage: 'Please enter a percentage value.',
            radio: 'Please select a valid option.',
            range: 'Please choose a valid range.',
            repeated: 'The values do not match.',
            search: 'Please enter a valid search term.',
            time: 'Please enter a valid time.',
            timezone: 'Please select a valid timezone.',
            ulid: 'Please enter a valid ULID.',
            url: 'Please enter a valid URL.',
            week: 'Please enter a valid week.',
        },
        producer_product: {
            ean_already_exists: 'EAN number already exists in product catalog.',
            sku_already_exists: 'SKU number already exists in product catalog.',
        },
        products_used_brand: 'There are products assigned to this brand',
        category_parent: "You can't remove parent category",
        products_used_category: 'There are products assigned to this category',
        brands_used_manufacturer: 'There are brand assigned to this manufacturer',
        variants_used_manufacturer: 'There are variant assigned to this manufacturer',
        variants_used_product: 'There are variant assigned to this product',
    },
    language: {
        pl: 'Polish',
        en: 'English',
        lt: 'Lithuanian',
        lv: 'Latvian',
        et: 'Estonian',
        de: 'German',
        dk: 'Danish',
        fi: 'Finnish',
        fr: 'French',
        nl: 'Dutch',
    },
    topNavBar: {
        accountSettings: 'Account Settings',
        logout: 'Logout',
    },
    adminPanelButton: {
        label: 'Back to admin panel',
    },
    status404: {
        title: '404: Page not found',
        description: 'We are sorry but the page you are looking for does not exist or has been moved.',
        goHome: 'Return to the main page',
        login: 'Login',
    },
    login: {
        title: 'Log in to the 100shoppers account',
        username: 'Username / E-mail',
        password: 'Password',
        submit: 'Login',
        resetPassword: 'Forgot your password? Click ',
        resetPasswordLink: 'here',
        registerBefore: "You don't have an account yet?",
        registerLink: 'Register',
        registerUrl: 'https://100shoppers.com/register',
    },
    register: {
        title: 'Create your 100shoppers account',
        headline: 'Take your business to the ',
        headlineSpan: 'next level',
        subheadline: 'Perfect solution for manufacturers',
        label: 'No credit card required and no commitment',
        createAccount: 'Create account',
        loginBefore: 'Already have an account?',
        loginLink: 'Log in',
        firstName: 'First name',
        lastName: 'Last name',
        businessEmail: 'Business e-mail',
        password: 'Password',
        country: 'Choose your country',
        iAgreeTo: 'I agree to the',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        and: 'and',
        termsError: 'You must accept the Terms of Service and Privacy Policy to continue.',
        countries: {
            pl: 'Poland',
            at: 'Austria',
            ee: 'Estonia',
            lt: 'Lithuania',
            lv: 'Latvia',
        },

        links: {
            tos: 'https://100shoppers.com/terms-of-service/',
            privacy: 'https://100shoppers.com/privacy-policy/',
        },
    },
    loginAs: {
        title: '@:login.title',
        missingToken: 'No authorization token.',
    },
    acceptTerms: {
        title: 'Terms of Service',
        description: 'To continue using your 100shoppers account, you must accept the {link}.',
        descriptionLink: 'Terms of Service',
        accept: 'Accept',
        cancel: 'Cancel',
    },
    resetPassword: {
        title: 'Reset password',
        description: 'Enter the e-mail address to start the password reset process.',
        email: 'E-mail',
        submit: 'Next',
        back: '@:back',
        success: 'An email has been sent to your email address, {email}. Follow the directions in the email to reset your password.',
    },
    resetPasswordComplete: {
        title: 'New password',
        description: 'Enter new password to complete the password reset process.',
        password: 'Password',
        confirmPassword: 'Confirm password',
        submit: '@:save',
        cancel: '@:cancel',
        success: 'New password has been set. You can now login to your account using it.',
    },
    confirmEmail: {
        title: 'Confirm email',
        missingToken: 'No confirmation token.',
        invalidToken: 'The account has already been activated or the activation link was incorrectly copied into the browser.',
        success: 'Email has been confirmed.',
    },
    accountSettingsPersonal: {
        title: 'Personal settings',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'E-mail',
        phone: 'Phone',
        sectionNotifications: 'Notifications',
        notifications: 'Notifications package',
        sectionLanguage: 'Language',
        language: 'Language',
        demo: 'Demo website',
        demoEnabled: 'Enabled',
        demoDisabled: 'Disabled',
        submit: '@:save',
        saveSuccess: 'Updated personal settings.',
    },
    accountSettingsCompany: {
        title: 'Company settings',
        companyName: 'Company name',
        companyNip: 'VAT number',
        street: 'Street',
        streetNumber: 'Street number',
        apartmentNumber: 'Apartment number',
        city: 'City',
        postcode: 'Post code',
        country: 'Country',
        submit: '@:save',
        saveSuccess: 'Updated company settings.',
    },
    accountSettingsPassword: {
        title: 'Password settings',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        submit: '@:save',
        saveSuccess: 'Changed password.',
    },
    producer: {
        title: 'Producer',
    },
    producerSidebar: {
        dashboard: '@:producerDashboard.title',
        products: 'Product Catalog',
        reports: 'Reports',
        reportsShops: 'Shops',
        reportsCategory: 'Category',
        reportsProducts: 'Products',
        reportsAvailability: 'Availability',
        reportsPriceDistribution: 'Price Distribution',
        reportsSales: 'Sales',
        campaignAnalytics: 'Campaign Analytics',
        plugin: 'Plugin',
        settings: 'Settings',
        yourPages: 'Your Websites',
        subscriptions: 'Subscriptions',
        api: 'API',
        dataExport: 'Data Export',
        help: 'Help',
        helpUrl: 'http://help.100shoppers.com',
        soon: '@:soon',
        productGroups: 'Product Groups',
        competitiveAnalysis: 'Competitive analysis',
        campaignAnalysis: 'Campaign analysis',
        webhook: 'Webhook',
    },
    producerAccountSettings: {
        title: 'Account Settings',
    },
    producerDashboard: {
        title: 'Dashboard',
        impressionsWidget: '@:impressionsWidget',
        impressionsWidgetTooltip:
            'Depending on the version of the widget, views are counted when a user has interacted with the button that triggers the widget, such as in the popover/overlay version.',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:clicks',
        clicksTooltip: 'The number of redirects to stores.',
        orders: '@:orders',
        quantity: '@:quantity',
        quantityTooltip: "The number of manufacturer's products sold",
        salesAmountOther: '@:salesAmount (other products)',
        salesAmountOtherTooltip: "Amount of other manufacturers' products sold",
        ctr: 'CTR',
        ctrTooltip: 'CTR rate shows the number of redirects to the stores/widget views',
        cr: 'CR',
        crTooltip: 'CR ratio shows the number of transactions/number of redirects to stores*100',
        noSpace: '@:spaceSwitch.noResults',
        averageCartValue: 'Average cart value (own)',
        averageCartValueTooltip: 'Value of own sales / Number of own transactions',
        transactions: 'Transactions',
        transactionsTooltip: 'The number of all transactions carried out via the widget.',
        trafficGraphTooltip: '',
        salesGraphTooltip: '',
        quantityOwnProducts: 'Quantity (own products)',
        quantityOwnProductsTooltip: '',
        quantityOtherProducts: 'Quantity (other products)',
        quantityOtherProductsTooltip: '',
        quantityUnknownProducts: 'Quantity (unknown products)',
        quantityUnknownProductsTooltip: '',
        salesAmountOwn: 'Sales amount (own)',
        salesAmountOwnTooltip: "The amount of manufacturer's products sold",
        salesAmountOtherProducts: 'Sales amount (other products)',
        salesAmountOtherProductsTooltip: '',
        salesAmountUnknownProducts: 'Sales amount (unknown products)',
        salesAmountUnknownProductsTooltip: '',
        salesAmountUnknown: 'Sales amount (unknown products)',
        salesAmountUnknownTooltip: '',
    },
    producerPlugin: {
        title: 'Plugin',
        noPlugin: 'There is no plugin configuration available.',
    },
    pluginCampaignsList: {
        title: 'Shop list settings',
        noCampaigns: 'No available shops.',
        noActiveCampaigns: 'All stores selected',
        noAvailableCampaigns: 'All stores selected',
        activeCampaigns: 'Shops displayed on your website',
        activeCampaignsDescription: 'Reverse orders of importance next to the shop',
        availableCampaigns: 'Available shops',
        availableCampaignsDescription: 'Select the shops you want to display and drag them to the left',
        createSuccess: 'Added shop "{name}".',
        updateSuccess: 'Updated priority of shop "{name}".',
        deleteSuccess: 'Removed shop "{name}".',
    },
    pluginAppearance: {
        title: 'Appearance & sorting settings',
        helpText: 'See how you can customize the appearance of the plugin',
        helpUrl: 'https://help.100shoppers.com/en/articles/4399144-appearance-setting',
        sectionSorting: 'Sorting the shops',
        noSortTypes: 'No available sort types',
        sectionShoppingList: 'Drop down shopping list',
        shoppingListDescription: 'Number of displayed shops. Additional offers will be visible after clicking on "Show more".',
        displayedShops: 'Number of displayed shops',
        leadColor: 'Lead color',
        buttonLabel: '"Go to shop" button label',
        showProduct: 'Show product data',
        availability: 'Availability',
        showPrices: 'Show prices',
        shopStyle: 'Show shop as',
        shopStyleIconName: 'Icon and name',
        shopStyleLogo: 'Logo only',
        additionalColumnSection: 'Additional column',
        showAdditionalColumn: 'Show additional column',
        additionalColumn: 'Additional column content (HTML)',
        additionalColumnOnlyForSorted: 'Show only for sorted',
        version: 'Widget version',
        language: 'Widget language',
        latestVersion: 'Latest',
        submit: '@:saveSettings',
        saveSuccess: 'Updated appearance & sorting settings.',
    },
    pluginCode: {
        title: 'Plugin',
        description: 'Insert your plugin-ID in selected code and automate the display of the Where To Buy tool.',
        urlAnchor: 'How to implement the plugin?',
        url: 'https://help.100shoppers.com/en/articles/4726202-plugin-implementation',
        pluginId: 'Unique plugin identifier',
        htmlHelper: '@:code.htmlHelper',
        jsonHelper: '@:code.jsonHelper',
        htmlHelperUrl: '@:code.htmlHelperUrl',
        jsonHelperUrl: '@:code.jsonHelperUrl',
        html: '@:code.html',
        json: '@:code.json',
        copy: '@:code.copy',
        copySuccess: '@:code.copySuccess',
        gtinPlaceholder: '@:code.gtinPlaceholder',
        skuPlaceholder: '@:code.skuPlaceholder',
        namePlaceholder: '@:code.namePlaceholder',
        categoryPlaceholder: '@:code.categoryPlaceholder',
        imagePlaceholder: '@:code.imagePlaceholder',
        pricePlaceholder: '@:code.pricePlaceholder',
        descriptionPlaceholder: '@:code.descriptionPlaceholder',
    },
    pluginExtension: {
        title: 'Extension',
        selectPlaceholder: 'Selected extension',
        noExtension: 'None',
        submit: '@:saveSettings',
        saveSuccess: 'Updated extension settings.',
        rotatingGroups: {
            extensionHint: 'Add groups of partners and set positions range.',
            noPrioritiesWarning: 'Please assign at least one positive position to a shop above',
            excludeNonStrategicCampaigns: 'Do not show shops without position',
            addGroup: 'Add group',
            nthGroup: 'Group',
            removeGroup: 'Remove',
            positionFrom: 'Position from',
            positionTo: 'Position to',
        },
    },
    producerSpaces: {
        title: 'Your Websites',
        subtitle: 'Websites',
        total: 'Total',
        create: 'Add new website',
        table: {
            name: 'Name',
            url: 'URL',
            category: 'Category',
        },
        noResults: '@:noResults',
        createSuccess: 'Website "{name}" has been created.',
        updateSuccess: 'Website "{name}" has been updated.',
        updateWebhookSuccess: 'Webhook for website "{name}" has been updated.',
    },
    producerWebhook: {
        title: 'Your Webhooks',
        subtitle: 'Webhooks',
        total: 'Total',
        create: 'Add new webhook',
        table: {
            name: 'Name',
            url: 'Webhook URL',
        },
        noResults: '@:noResults',
        createSuccess: 'Webhook "{name}" has been created.',
        updateSuccess: 'Webhook "{name}" has been updated.',
    },
    producerProducts: {
        title: 'Products',
        filter: 'Find a product',
        filterPlaceholder: 'Product name, EAN or model',
        noResults: 'No products found.',
        noResultsDescription: '',
        noSelectedItem: 'Please select category or product to see details.',
        createProduct: 'Add new product',
    },
    producerProductDetails: {
        id: 'ID:',
        gtin: 'GTIN:',
        sku: 'SKU:',
        suggestedPrice: '@:price',
        impressionsWidget: '@:impressionsWidget',
        impressionsWidgetTooltip:
            'Depending on the version of the widget, views are counted when a user has interacted with the button that triggers the widget, such as in the popover/overlay version.',
        clicks: '@:clicks',
        clicksTooltip: 'The number of redirects to stores.',
        quantity: '@:quantity',
        quantityTooltip: "The number of manufacturer's products sold",
        salesAmount: '@:salesAmount',
        salesAmountTooltip: "The amount of manufacturer's products sold",
        ctr: 'CTR',
        ctrTooltip: 'CTR rate shows the number of redirects to the stores/widget views',
        cr: 'CR',
        crTooltip: 'CR rate shows the number of items sold/number of to stores redirects*100',
        deleteProduct: 'Delete product',
        confirmDelete: 'Are you sure you want to delete product "{name}"?',
        deleteSuccess: 'Deleted product "{name}".',
        editProduct: 'Edit product',
        addToGroup: 'Add to group',
    },
    updateProductModal: {
        updateTitle: 'Editing product "{name}"',
        createTitle: 'Create product',
        name: 'Product name',
        description: 'Description',
        ean: '@:ean',
        sku: '@:sku',
        price: '@:price',
        images: 'Images',
        image: 'Image',
        addImage: 'Add image',
        category: 'Category',
        categoryRequired: 'Field "Category" is required.',
        updateSuccess: 'Saved changes in product "{name}".',
        createSuccess: 'Added product {name}.',
    },
    priceDistribution: {
        title: 'Price Distribution',
        price: '@:price',
        assumedPrice: 'Last known price',
        pluginCampaigns: '@:filters.pluginCampaigns',
        inPluginCampaigns: '@:filters.inPluginCampaigns',
        notInPluginCampaigns: '@:filters.notInPluginCampaigns',
        allPluginCampaigns: '@:filters.allPluginCampaigns',
        noResultsPluginCampaignsIn: 'Price is not available for stores displayed in plugin. Change settings.',
        noResultsPluginCampaignsNotIn: 'The price is not available for stores not displayed in plugin. Change settings.',
    },
    priceDeviations: {
        title: 'Price Deviations',
        product: 'Product',
        recommendedPrice: 'Recommended price [{currency}]',
        deviationFromRecommendedPrice: 'Deviation from recommended price',
        priceDeviationsInTime: 'Price Deviations in time',
        editPrice: 'Edit price',
        minPrice: 'Min price',
        maxPrice: 'Max price',
        avgPrice: 'Price',
        deviation: 'Deviation',
        noRecommendedPrice: 'No recommended price',
    },
    priceDeviationOverTime: {
        title: 'Price deviations over time',
        viewBy: 'View by',
        viewByPrice: 'Price [{currency}]',
        viewByChange: 'Change %',
        noRecommendedPrice: 'No recommended price',
    },
    productAvailability: {
        title: 'Availability',
        pluginCampaigns: '@:filters.pluginCampaigns',
        inPluginCampaigns: '@:filters.inPluginCampaigns',
        notInPluginCampaigns: '@:filters.notInPluginCampaigns',
        allPluginCampaigns: '@:filters.allPluginCampaigns',
    },
    categoryAvailability: {
        title: 'Availability',
        offers: 'Offer list',
        pluginCampaigns: '@:filters.pluginCampaigns',
        inPluginCampaigns: '@:filters.inPluginCampaigns',
        notInPluginCampaigns: '@:filters.notInPluginCampaigns',
        allPluginCampaigns: '@:filters.allPluginCampaigns',
    },
    productOffers: {
        title: 'Current offer list',
        displayedOffersHeader: 'Displayed on your website',
        nonDisplayedOffersHeader: 'Non-displayed on your website',
        noResults: 'No offers found.',
        campaign: 'Shop',
        offer: 'Offer',
        price: 'Price',
        date: 'Date',
        showAll: 'Show all offers',
    },
    productCode: {
        title: '@:code.title',
        description: '@:code.description',
        htmlHelper: '@:code.htmlHelper',
        jsonHelper: '@:code.jsonHelper',
        htmlHelperUrl: '@:code.htmlHelperUrl',
        jsonHelperUrl: '@:code.jsonHelperUrl',
        html: '@:code.html',
        json: '@:code.json',
        copy: '@:code.copy',
        copySuccess: '@:code.copySuccess',
        gtinPlaceholder: '@:code.gtinPlaceholder',
        skuPlaceholder: '@:code.skuPlaceholder',
        namePlaceholder: '@:code.namePlaceholder',
        categoryPlaceholder: '@:code.categoryPlaceholder',
        imagePlaceholder: '@:code.imagePlaceholder',
        pricePlaceholder: '@:code.pricePlaceholder',
        descriptionPlaceholder: '@:code.descriptionPlaceholder',
    },
    productLandingPage: {
        title: 'Product landing page',
        description: 'Use the link below in your social media, mailings, etc.',
        helperText: 'Find out more, how to use a product landing page?',
        helperUrl: 'https://docs.100shoppers.com/how-to-use-a-product-landing-page/',
        previewText: 'Product page preview',
        copyLink: 'Copy link',
        openLink: 'Open link',
        copySuccess: 'Link has been copied to clipboard.',
    },
    widgetPreview: {
        show: 'Check where to buy',
        noOffers: 'No offers',
    },
    producerReportsProducts: {
        title: 'Products Reports',
        noSpace: '@:spaceSwitch.noResults',
    },
    producerReportsAvailability: {
        title: 'Availability Reports',
        noSpace: '@:spaceSwitch.noResults',
    },
    availabilityStats: {
        avgAvailability: 'Average availability',
        avgAvailabilityTooltip:
            "Average availability [%] of the manufacturer's products in the stores displayed (by default) in the widget. To change the set of stores, use the Store filter.",
        avgAvailabilityQuantitatively: 'Average availability (quantitatively)',
        avgAvailabilityQuantitativelyTooltip:
            "Average availability [quantity] of manufacturer's products in the stores displayed (by default) in the widget. To change the set of stores, use the Store filter.  ",
        products: 'Numbers of products',
        productsTooltip: "Number of manufacturer's products.",
    },
    availabilityAnalysis: {
        title: 'Availability Analysis',
        offersInTime: 'Number of offers in time',
        offersInTimeTooltip:
            'The chart shows the number of offers in the stores displayed in the widget (by default). To check the number of offers in other stores, use the Store filter.',
        offers: 'Number of offers',
        avgAvailabilityPerShop: 'Average products availability per shop',
        avgAvailabilityPerShopTooltip:
            "The chart shows the ranking according to the average availability of the manufacturer's product listings per store [% and quantity] in the stores displayed in the widget (by default). To check the number of offers in other stores, use the Store filter.",
        sortBySummaryDesc: 'Average % descending',
        sortBySummaryAsc: 'Average % ascending',
        sortByChangeDesc: 'Change % (to previous period) descending',
        sortByChangeAsc: 'Change % (to previous period) ascending',
        avgAvailabilityPerProduct: 'Average availability per product',
        avgAvailabilityPerProductTooltip:
            "The chart shows the ranking of products according to the average availability of the manufacturer's product offers in the stores displayed in the widget (by default). To check the number of offers in other stores, use the Store filter.",
        avgAvailabilityPerProductFirstItemTooltip: 'Click on the product name to check product availability per store over time.',
        byProductExportProductName: 'Product name',
        byProductExportAvgAvailability: 'Availability',
        byShopExportShopName: 'Shop name',
        byShopExportAvgAvailability: 'Availability',
        searchProduct: 'Search product',
    },
    availabilityPerShop: {
        title: 'Availability over time per shop',
        availabilityInShops: 'Availability in shops (days)',
    },
    currentAvailability: {
        title: 'Current availability',
        titleTooltip: 'The summary presents an overview of current availability in stores and, in addition, a list of unavailable products.',
    },
    currentUnavailableProducts: {
        title: 'Current unavailable products',
        lastAvailableAt: 'Last availability',
        unavailableFor: 'Unavailable for (days)',
        productUnavailableTooltip: "There is no data regarding the product's offers.",
    },
    producerReportsPriceDistribution: {
        title: 'Price Distribution Reports',
        noSpace: '@:spaceSwitch.noResults',
    },
    producerReportsShops: {
        title: 'Shops Reports',
        noSpace: '@:spaceSwitch.noResults',
    },
    producerReportsCategory: {
        title: 'Category Reports',
        noSpace: '@:spaceSwitch.noResults',
    },
    productsSummary: {
        title: 'Summary',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        search: '@:search',
        shop: '@:filters.shop',
        category: '@:filters.category',
        productName: 'Product',
        shopName: 'Shop',
        sku: 'Model',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:clicks',
        purchased: 'Quantity',
        price: 'Sales amount',
        noResults: 'No products found.',
        shopsEntrances: 'Shops entrances',
    },
    categorySummary: {
        title: 'Summary',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        search: '@:search',
        shop: '@:filters.shop',
        category: '@:filters.category',
        sku: 'Model',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:clicks',
        purchased: 'Purchased items',
        price: '@:price',
        noResults: 'No categories found.',
    },
    productsCartAnalysis: {
        title: "Other products' [cart analysis]",
        allShops: '@:filters.allShops',
        allConversion: '@:filters.allConversion',
        allBrands: '@:filters.allBrands',
        nConversion: '@:filters.nConversion',
        search: '@:search',
        shop: '@:filters.shop',
        conversion: '@:filters.conversion',
        productName: 'Product',
        sku: 'Model',
        brand: '@:brand',
        quantity: '@:quantity',
        amount: '@:salesAmount',
        noResults: 'No products found.',
    },
    productsSales: {
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
    },
    productsRanking: {
        title: 'Products ranking',
        allShops: '@:filters.allShops',
        allGroups: '@:filters.allGroups',
        search: 'Find product',
        rankingType: 'Ranking type',
        shop: '@:filters.shop',
        group: '@:filters.group',
        rowsToShow: 'Show',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
        sortBy: 'View by',
        sortBySummary: 'Summary',
        sortByChange: 'Change %',
        noResults: 'No products found.',
        exportName: 'Product',
        find: 'Find',
    },
    shopsRanking: {
        title: 'Shops ranking',
    },
    categoryRanking: {
        title: 'Category ranking',
        allShops: '@:filters.allShops',
        search: 'Find product',
        rankingType: 'Ranking type',
        shop: '@:filters.shop',
        rowsToShow: 'Show',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
        sortBy: 'View by',
        sortBySummary: 'Summary',
        sortByChange: 'Change %',
        noResults: 'No categories found.',
        exportName: 'Product',
        find: 'Find',
    },
    shopsTraffic: {
        title: '@:traffic',
        percentageSectionTitle: 'Percentage share',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        orders: '@:filters.orders',
    },
    shopsSales: {
        title: '@:sales',
        percentageSectionTitle: 'Percentage share',
        allConversion: '@:filters.allConversion',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        conversion: '@:filters.conversion',
        nConversion: '@:filters.nConversion',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        quantity: '@:quantity',
        amount: '@:filters.salesAmount',
    },
    categoryTraffic: {
        title: '@:traffic',
        percentageSectionTitle: 'Category percentage share',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        tooltip: 'The data presented is narrowed by default to the stores displayed in the widget.',
    },
    categorySales: {
        title: '@:sales',
        percentageSectionTitle: 'Category percentage share',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
    },
    categoryHeatmap: {
        title: 'Heatmap category/shops',
        dataType: '@:filters.type',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
        category: 'Category',
        total: 'Total',
    },
    producerReportsSales: {
        title: 'Sales Reports',
        noSpace: '@:spaceSwitch.noResults',
        impressionsStatsTooltip: 'Depending on the widget version, views are counted when a user interacts with the widget launch button, e.g. in the popover/overlay version',
        clicksStatsTooltip: 'The number of redirects to stores.',
        ordersStatsTooltip: 'Transactions carried out via the widget.',
        ownOrdersStatsTooltip: "Transactions in which at least one product is a manufacturer's product.",
        quantityTooltip: 'The number of manufacturer products sold.',
        salesAmountOwnTooltip: "The amount of manufacturer's products sold",
        averageCartValueTooltip: 'The amount of own sales/Number of own transactions',
    },
    salesSummary: {
        title: 'Summary',
        allConversion: '@:filters.allConversion',
        allShops: '@:filters.allShops',
        conversion: '@:filters.conversion',
        nConversion: '@:filters.nConversion',
        shop: '@:filters.shop',
        noResults: '@:noResults',
        date: '@:date',
        views: '@:impressionsOffer',
        clicks: 'Clicks',
        ctr: 'CTR',
        transaction: 'Transaction',
        cr: 'CR',
        quantity: 'Quantity',
        value: 'Value',
        amount: 'Sales amount',
    },
    salesProductDetails: {
        title: 'Product details',
        allShops: '@:filters.allShops',
        allConversion: '@:filters.allConversion',
        allBrands: '@:filters.allBrands',
        nConversion: '@:filters.nConversion',
        search: '@:search',
        shop: '@:filters.shop',
        conversion: '@:filters.conversion',
        clearTransactionFilterLabel: '@:reset',
        date: '@:date',
        transactionId: 'Transaction ID',
        transactionIdShort: 'Trans. ID',
        productName: 'Product',
        sku: 'Model',
        ean: 'EAN',
        brand: '@:brand',
        conversionTime: 'Conversion',
        quantity: 'Qty.',
        amount: 'Sales amount',
        noResults: 'No products found.',
        unknownProduct: 'Unknown',
        unknownProductDescription: 'Unknown product. It can be a service of bringing, unpacking, additional warranty, other.',
        otherProducerProduct: 'Unavailable',
        otherProducerProductDescription: 'Product of another producer.',
    },
    salesTransactions: {
        title: 'Transactions',
        allConversion: '@:filters.allConversion',
        allShops: '@:filters.allShops',
        conversion: '@:filters.conversion',
        nConversion: '@:filters.nConversion',
        shop: '@:filters.shop',
        noResults: '@:noResults',
        date: '@:date',
        transactionId: 'Transaction ID',
        campaign: 'Shop',
        conversionTime: 'Conversion Time',
        value: 'Value',
        quantity: '@:quantity',
        share: 'Share %',
        shareOwn: 'own products',
        shareOther: 'other products',
        shareExport: 'Share % of own products',
        offerPosition: 'Offer position',
        details: 'Transaction details',
        details2: 'Transaction<br>details',
        details3: 'Details',
        source: 'Source',
        amount: 'Sales amount',
    },
    conversionPath: {
        title: 'Transaction ID {id} - Conversion path',
        clickTime: 'Click',
        buyTime: 'Purchase',
        producerPage: 'Producer page',
        showPage: 'Shop page',
        clickedOffer: 'Clicked offer',
        ean: '@:ean',
        transaction: 'Transaction',
        product: 'Product',
        brand: '@:brand',
        quantity: '@:quantity',
        unidentifiedProduct: 'Unidentified product',
        unknownProduct: 'Unknown',
        unknownProductDescription: 'Unknown product. It can be a service of bringing, unpacking, additional warranty, other.',
        source: 'Source',
        transactionSource: 'Transaction source',
    },
    availabilityProductModal: {
        title: 'Availability for {name}',
    },
    productPrices: {
        title: 'Product prices',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        allGroups: '@:filters.allGroups',
        shop: '@:filters.shop',
        category: '@:filters.category',
        group: '@:filters.group',
        noResults: '@:noResults',
        noProductSelected: 'Choose the product',
        name: 'Product name',
        ean: 'EAN',
        minPrice: '@:minPrice',
        avgPrice: '@:avgPrice',
        maxPrice: '@:maxPrice',
        quantity: 'Qty sold',
    },
    averagePrice: {
        title: 'Average price',
        allMeasures: 'All measures',
        measure: 'Measure',
        type: 'Type',
        byCampaign: 'By shops',
        byCategory: 'By category',
        noResults: '@:noResults',
        noProductSelected: 'Choose the product',
        price: '@:price',
        minPrice: '@:minPrice',
        avgPrice: '@:avgPrice',
        maxPrice: '@:maxPrice',
        unavailablePrice: 'Unavailable at this time range',
    },
    averagePriceOverTime: {
        title: 'Average price over time',
        price: '@:price',
        assumedPrice: 'Last known price',
        noProductSelected: 'Choose the product',
    },
    quantityOverTime: {
        title: 'Quantity sold over time',
        quantity: '@:quantity',
        noProductSelected: 'Choose the product',
        requiredAllCampaignsSelected: 'Please select all shops to see data',
    },
    filters: {
        reset: '@:reset',
        allShops: 'All shops',
        allCategories: 'All categories',
        allGroups: 'All product groups',
        allAvailability: 'All availability',
        availability: 'Availability',
        allConversion: 'All conversions',
        allBrands: 'All brands',
        showSummarized: 'Show summarized',
        n1Conversion: 'Within an hour',
        nConversion: 'Within {hours} hours',
        pluginShops: 'Displayed in plugin',
        otherShops: 'Other shops',
        showAvailability: 'Show availability',
        shop: 'Shop',
        category: 'Category',
        group: 'Product group',
        conversion: 'Convertion',
        type: 'Type',
        clicks: '@:clicks',
        orders: '@:orders',
        quantity: '@:quantity',
        salesAmount: '@:salesAmount',
        period: 'Period',
        periodDaily: 'Daily',
        periodMonthly: 'Monthly',
        periodQuarterly: 'Quarterly',
        periodAnnually: 'Annually',
        pluginCampaigns: 'Shops',
        inPluginCampaigns: 'Displayed in plugin',
        notInPluginCampaigns: 'Not displayed in plugin',
        allPluginCampaigns: 'All shops',
        top: 'Top',
        other: 'Other',
        showOnlyTopCategories: 'Only show the top 10 categories in the chart',
        showProductGroupsResults: 'Show results for product groups',
        sortBy: 'View by',
        sortBySummary: 'Summary',
        sortByChange: 'Change %',
        sources: 'Sources',
    },
    filterSummary: {
        noResults: 'No filters',
        types: {
            activeCampaigns: 'Shops displayed in plugin',
            availableCampaigns: 'Other shops',
            categories: 'Categories',
            products: 'Products',
            conversion: '@:filters.conversion',
            brand: '@:brand',
            transactions: 'Transactions',
            groups: 'Product groups',
            aggregations: 'Competition products',
        },
    },
    table: {
        rowsPerPage: 'Results per page',
        rowsPerPageAll: 'All',
        pageText: '{pageStart}-{pageStop} of {itemsLength}',
        noResults: '@:noResults',
    },
    spaceSwitch: {
        label: 'Page',
        noResults: 'No pages found',
    },
    dateRangePicker: {
        startDateLabel: 'Start date',
        endDateLabel: 'End date',
        notAvailable: 'n/a',
        invalidRange: 'The start date can not be later than the end date.',
        apply: 'Apply',
        cancel: '@:cancel',
        range: 'Period',
        custom: 'Custom',
        days7: 'Last 7 days',
        days14: 'Last 14 days',
        days30: 'Last 30 days',
        today: 'Today',
        yesterday: 'Yesterday',
        currentWeek: 'Current week',
        lastWeek: 'Last week',
        currentMonth: 'Current month',
        lastMonth: 'Last month',
        currentQuarter: 'Current quarter',
        lastQuarter: 'Last quarter',
        currentYear: 'Current year',
        lastYear: 'Last year',
        comparePrevRange: 'Compare to previous period',
        compareLastYear: 'Compare to last year',
    },
    graph: {
        export: 'Export',
        fullScreen: 'Full screen',
        exitFullScreen: 'Exit full screen',
        activeFilters: 'Active filters',
    },
    salesGraph: {
        title: '@:sales',
        allShops: '@:filters.allShops',
        shop: '@:filters.shop',
        date: '@:date',
        salesAmount: '@:salesAmount',
        quantity: '@:quantity',
        noResults: '@:noResults',
        tooltip: "The charts show data for the price ranges in which the manufacturer's products are found.",
    },
    trafficGraph: {
        title: '@:traffic',
        allShops: '@:filters.allShops',
        shop: '@:filters.shop',
        date: '@:date',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:clicks',
        noResults: '@:noResults',
    },
    tableHeatmapGraph: {
        title: 'Heatmap',
        noResults: '@:noResults',
    },
    code: {
        title: 'Code',
        description: 'Insert selected code and automate the display of the Where To Buy tool or fully customize the plugin using JSON URL.',
        htmlHelper: 'How to implement JS code?',
        jsonHelper: 'How to use JSON format?',
        htmlHelperUrl: 'https://help.100shoppers.com/en/articles/2814129-javascript-standard',
        jsonHelperUrl: 'https://help.100shoppers.com/en/articles/3036892-jsonJSON ',
        html: 'HTML',
        json: 'JSON',
        copy: 'Copy',
        copySuccess: 'Code has been copied to clipboard.',
        gtinPlaceholder: '_product_GTIN_number_',
        skuPlaceholder: '_product_producer_unique_ID_',
        namePlaceholder: '_product_name_',
        categoryPlaceholder: '_product_category_path_',
        imagePlaceholder: '_optional_product_image_URL_',
        pricePlaceholder: '_optional_product_suggested_retail_price_',
        descriptionPlaceholder: '_optional_product_short_description_',
    },
    timeShort: {
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
        quarter: 'Q',
    },
    selectAll: {
        all: 'Select all',
        others: 'others',
    },
    productSelector: {
        label: 'Find product',
        ean: 'EAN',
        sku: 'SKU',
        productId: 'ID',
        offerId: 'Offer ID',
        noData: 'The product was not found.',
        noDataLink: 'Find out what you can do.',
        noDataLinkUrl: 'https://docs.100shoppers.com/how-to-update-your-product-catalogue/',
    },
    resetFiltersButton: {
        label: 'Restore filters to default settings',
    },
    pieChart: {
        total: 'Total',
        totalTooltip: 'Sum for a data series',
    },
    producerSubscriptions: {
        title: 'Subscriptions',
        table: {
            permissionName: 'Subscription Name',
            status: 'Status',
        },
        noResults: 'No subscriptions found.',
    },
    permissionContent: {
        title: {
            reportsPriceDistribution: 'See how prices fluctuate depending on various factors.',
            reportsAvailability: 'Know your availability better.',
            reportsShops: 'See what traffic is generated by your distributors.',
            reportsCategories: 'Be up to date with the categories which have the highest conversion rate.',
            reportsProducts: 'Get detailed reports regarding your product sales:',
            reportsSales: 'Let’s make an overview of your sales results',
        },
        description: {
            reportsPriceDistribution:
                'Analyse:<ul><li>What are the minimum and maximum prices for selected products in your online partner shops?</li><li>What is the percentage of price increase or decrease in comparison with the previous time period?</li><li>What is the average product price as shown on the timeline.</li></ul>',
            reportsAvailability:
                'We are absolutely aware that the availability of your products has a significant impact on your sales and the way your customers perceive your brand.\n\nThanks to our solution, you will be able, for instance, to display our timeline charts and get an overview of the number of offers available in selected shops/specific shops.',
            reportsShops: "<ul><li>Observe the traffic trends on your brand's website.</li><li>Analyse the percentage of selected shops in particular categories.</li></ul>",
            reportsCategories: '<ul><li>Traffic & sales categories</li><li>Shop and category heatmaps</li></ul>The data we deliver will show you the most recent changes.',
            reportsProducts:
                '<ul><li>Choose specific criteria and display your product ranking according to those criteria</li><li>Display the number of items sold along with the cart analysis</li><li>And more</li></ul>',
            reportsSales:
                'You can verify what your product percentage share is in specific transactions.\n\n<ul><li>Access the summary of your sales volume</li><li>Read a detailed analysis of your transactions and sold items</li><li>Have a look at the most important indicators: CR, CTR and conversion time</li></ul>One look at the data we display like sales figures, (yours or those of other brands) helps you to draw a conclusions right away.',
            competitiveAnalysis:
                'A competitive report will be available soon that will enable you to take an in-depth analysis of selected competitive products and examine their position in the market.',
            campaignAnalysis:
                'You can already <strong>order a campaign analysis</strong> by contacting your consultant. With our report you can review your campaign by checking <strong>wich sources and ads generate the most sales</strong>. It is helpful to optimize your advertising budget.\n\nThe report will be delivered to you in an interactive form, and soon the analysis will be available from the panel interface.',
        },
        name: {
            stats_price_distribution: 'Price distribution reports',
            stats_availability: 'Availability reports',
            stats_shops: 'Shops reports',
            stats_categories: 'Categories reports',
            stats_products: 'Products reports',
            stats_sales: 'Sales reports',
            stats_competition_report: 'Competition report',
            campaign_analysis: 'Campaign analysis',
        },
        status: {
            active: 'Active',
            canceled: 'Active (until this month)',
            inactive: 'Inactive',
        },
        ctaText: {
            competitiveAnalysis: 'We will notify you when the report is available.',
            campaignAnalysis: 'We will notify you when the report is available.',
        },
    },
    activateReportModal: {
        ctaLabel: 'Learn More',
        activateTitle: 'Activate the access to this report.',
        activatePayment: 'The payment will be\ncharged every month.',
        submit: 'Activate',
        success: 'Activated subscription "{name}".',
    },
    cancelSubscriptionModal: {
        title: 'Cancel subscription',
        ctaLabel: '@:cancelSubscriptionModal.title',
        description: 'The subscription will be canceled at the end of the month.',
        cancel: 'Cancel',
        submit: '@:save',
        success: 'Canceled subscription "{name}".',
    },
    dataExport: {
        title: 'Export XLS data',
        description:
            'Data presented in the reports can be downloaded to the XLS file. After ordering data generation you will receive a list of available files for download. The file for each month will contain all available data in tabs, including purchased reports.',
        generationFeedback: 'It may take a while to generate a report. Please wait or come back in a moment.',
        availableReports: 'Available reports',
        availablePriceReports: 'Available price reports',
        availableAvailabilityReports: 'Available availability reports',
        availableCompetitionReports: 'Available competition reports',
        noAvailableReports: 'No generated reports',
        generate: 'Generate',
        download: 'Download',
        errorTooltip: 'An unexpected error has occurred. Please try to generate the report again in a few minutes.',
    },
    producerProductGroup: {
        title: 'Product Groups',
        createSuccess: 'Group "{name}" has been created.',
        updateSuccess: 'Group "{name}" has been updated.',
        addProductSuccess: 'Added a product to the group.',
        deleteProductSuccess: 'Deleted a product from the group.',
        addCategorySuccess: 'Added a category to the group.',
        addCategoryNestedSuccess: 'Category "{added}" has been added. Category "{existing}" already exists in the created group.',
        deleteCategorySuccess: 'Deleted a category from the group.',
    },
    groups: {
        title: 'Groups',
        contains: 'Contains',
        containsCategories: 'Categories',
        containsProducts: 'Products',
        addToGroup: 'Add to group',
        removeFromGroup: 'Remove from group',
    },
    competitiveAnalysis: {
        selectedProducts: 'Selected products',
        createSet: 'Create set',
        saveSetSuccess: 'Saved set',
        confirmDelete: 'Are you sure you want to delete set?',
        productUnavailable: 'Product unavailable',
    },
    competitionProductsSelector: {
        title: 'Create a product set',
        producerProduct: '1. Search your product',
        selectProduct: 'Select product',
        addProduct: 'Add next product',
        competitiveProductList: '2. Search competitive products',
        selectCompetitiveProduct: 'Select competitive product',
        selectGlobal: 'Global product',
        selectProducer: 'Your product',
        save: 'Save set',
        saveAndCreateNew: 'Save set and create new',
    },
    competitionSets: {
        priceRanking: 'Ranking by price',
        priceRankingTooltip: "The position of the manufacturer's product in relation to competing products by average price.",
        avgPriceTooltip: 'The average price of the product from the selected time range in the stores displayed in the widget (by default).',
        offersRankingTooltip: "The position of the manufacturer's product in relation to competing products by number of offers.",
        minPriceTooltip: 'The minimum price that occurred in the selected period.',
        maxPriceTooltip: 'The maximum price that occurred in the selected period.',
        offersCount: 'Number of offers',
        offersCountTooltip: 'The number of available offers on the last day of the selected period.',
        offersRanking: 'Ranking by offers count',
        selectedCompetitiveProducts: 'Selected competitive products',
        availability: 'Availability',
        editSet: 'Edit set',
        removeSet: 'Remove set',
    },
    competitionAveragePriceOverTime: {
        title: 'Average price change over time',
    },
    competitionPriceRange: {
        title: 'Price range and deviation',
        titleTooltip: 'The chart shows values for the minimum and maximum price, as well as deviations from the average price.',
    },
    competitionOffersCount: {
        title: 'Number of offers',
        exportName: 'Product name',
        exportValue: 'Number of offers',
    },
    competitionShopAvailability: {
        title: 'Availability in shops',
        titleTooltip: 'The ranking by the number of available offers in stores for the product.',
    },
    save: 'Save',
    saveSettings: 'Save settings',
    apply: 'Apply',
    cancel: 'Cancel',
    delete: 'Delete',
    back: 'Back',
    soon: 'soon',
    more: 'More',
    yes: 'Yes',
    no: 'No',
    date: 'Date',
    sales: 'Sales',
    traffic: 'Traffic',
    price: 'Price',
    minPrice: 'Min price',
    avgPrice: 'Avg price',
    maxPrice: 'Max price',
    priceRange: 'Price range',
    impressionsWidget: 'Widget impressions',
    impressionsOffer: 'Offer impressions',
    clicks: 'Clicks',
    quantity: 'Quantity',
    salesAmount: 'Sales amount',
    salesValue: 'Sales value',
    orders: 'Orders',
    ctr: 'CTR',
    cr: 'CR',
    noResults: 'No matching results found.',
    search: 'Search',
    findProduct: 'Find product',
    ean: 'EAN',
    sku: 'SKU',
    brand: 'Brand',
    change: 'change',
    reset: 'reset',
    close: 'Close',
    resetSelection: 'Reset selection',
    selectAllFrom: 'Select all from {date}',
    export: 'Export',
    find: 'Find',
    addProductGroup: 'Product Group',
    groupName: 'Group name',
    groupType: 'Group type',
    productGroup: 'Product group',
    categoryGroup: 'Category group',
    category: 'Category',
    description: 'Description',
    findOrSelect: 'Find or Select',
    add: 'Add',
    categoryList: 'Category list',
    productList: 'Product list',
    id: 'ID',
    name: 'Name',
    impressions: 'Impressions',
    ownTransactions: 'Transactions (own)',
    allTransactions: 'Transactions (all)',
    salesByPriceRange: 'Sales by price range',
    priceRanges: 'Price ranges',
    manufacturerProducts: 'Manufacturer Products',
    others: 'Others',
    everyXCurrency: 'every {value} {currency}',
    numberOfItems: 'The number of items',
    product: 'Product',
    model: 'Model',
    details: 'Details',
    noProductsAdded: 'No products added',
    noCategoryAdded: 'No category added',
    confirmDelete: 'Are you sure you want to delete "{name}"?',
    productGroupsAddCategoryChildExistError:
        'Select the entire "{name}" category if you want to display products for the entire category in reports, or add only individual products.',
    productGroupsAddProductCategoryExistError: 'The product "{name}" is already in the added category',
    productGroupsRemoveProductCategoryExistError: 'The product "{name}" is already in the added category. You must remove entire category.',
    tooltipNewReportIsCreated: 'A NEW REPORT IS CREATED',
    rowsToShow: 'Show',
    showAll: 'Show all',
    new: 'new',
    lackOf: 'Lack of',
    unknownSource: 'unknown',
    subscriptionNotIncludeSourceAnalytics: 'Your subscription doesn’t include the <strong>Sources analysis</strong> section.',
    leaveUsYourDetailsAndWeWillContactYou: 'Please leave us your contact details and we will get back to you.',
    subscriptionNotIncludeCompetitiveAnalytics: 'Your subscription doesn’t include the <strong>Competitive report</strong>',
    contactSalesDepartment: 'Please contact the sales department',
    na: 'N/A',
    yourEmail: 'Your e-mail',
    yourPhoneNumber: 'Your phone number',
    pleaseContact: 'Please contact',
    pleaseContactSent: 'The request has been sent',
    transactions: 'Transactions',
    salesBySource: 'Sales by source',
    noProductSelected: 'Choose the product',
    recommendedPrice: 'Recommended price [{currency}]',
    deviation: 'Deviation',
    yourSubscriptionDoesNotIncludeThisSection: 'Your subscription does not include this section',
    availableCurrently: 'Currently available',
    notAvailableCurrently: 'Not available currently',
    failedToLoadProductData: 'Failed to load product data',
    noDefinedPrice: 'No defined price',
    edit: 'Edit',
    overview: 'Overview',
    salesSummary2: 'Sales summary',
    ownProducts: 'Own products',
    otherProducts: 'Other products',
    unknownProducts: 'Unknown products',
    percent_of_total: '{percent}',
    salesTypeList: {
        own: 'own',
        others: 'others',
        unknown: 'unknown',
        orders: 'orders',
    },
    subscriptionNotIncludeCampaignAnalytics: 'Your subscription doesn’t include the <strong>Campaign analysis</strong>',
    salesSummaryQuantityItem: 'item',
    quantity2: 'Quantity',
    noData: 'No data',
    noDataAvailable: 'No data about time availability',
    dataFor: 'Data for',
    exportingOn: 'Exporting on',
    selectedStores: 'Selected stores',
    subcategory: 'Subcategory',
};
