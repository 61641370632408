<template>
    <router-view />
</template>

<script>

export default {
    name: 'abstract-route',
};
</script>

<style lang="scss" scoped>
</style>
