/* eslint-disable no-unused-vars */
import {
    filterDates,
    filterKeysByCampaignName, filterPieChartByCampaignName,
    generateDates,
} from '@/services/demo-services/_utils';

export default {
    async fetchImpressionsReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceImpressionsReport } = await import('../../mocks/spaceImpressionsReport.json');
        return {
            chart: filterKeysByCampaignName(filterDates(generateDates(spaceImpressionsReport.chart)), campaigns),
            pie_chart: filterPieChartByCampaignName(spaceImpressionsReport.pie_chart, campaigns),
        };
    },

    async fetchClicksReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceClicksReport } = await import('../../mocks/spaceClicksReport.json');
        return {
            chart: filterKeysByCampaignName(filterDates(generateDates(spaceClicksReport.chart)), campaigns),
            pie_chart: filterPieChartByCampaignName(spaceClicksReport.pie_chart, campaigns),
        };
    },

    async fetchOrdersReport(spaceId, startDate, endDate, campaigns, categories) {
        const { default: spaceOrdersReport } = await import('../../mocks/spaceOrdersReport.json');
        return {
            chart: filterKeysByCampaignName(filterDates(generateDates(spaceOrdersReport.chart)), campaigns),
            pie_chart: filterPieChartByCampaignName(spaceOrdersReport.pie_chart, campaigns),
        };
    },

    async fetchQuantityReport(spaceId, startDate, endDate, conversion, campaigns, categories) {
        const { default: spaceQuantityReport } = await import('../../mocks/spaceQuantityReport.json');
        return {
            chart: filterKeysByCampaignName(filterDates(generateDates(spaceQuantityReport.chart)), campaigns),
            pie_chart: filterPieChartByCampaignName(spaceQuantityReport.pie_chart, campaigns),
        };
    },

    async fetchAmountReport(spaceId, startDate, endDate, conversion, campaigns, categories) {
        const { default: spaceAmountReport } = await import('../../mocks/spaceAmountReport.json');
        return {
            chart: filterKeysByCampaignName(filterDates(generateDates(spaceAmountReport.chart)), campaigns),
            pie_chart: filterPieChartByCampaignName(spaceAmountReport.pie_chart, campaigns),
        };
    },

    async fetchSummary(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories) {
        const { default: spaceShopReports } = await import('../../mocks/spaceShopReports.json');
        return spaceShopReports;
    },

    async fetchImpressionsRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceImpressionsRanking } = await import('../../mocks/spaceImpressionsCampaignRanking.json');
        return spaceImpressionsRanking;
    },

    async fetchClicksRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceClicksRanking } = await import('../../mocks/spaceClicksCampaignRanking.json');
        return spaceClicksRanking;
    },

    async fetchAmountRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceAmountRanking } = await import('../../mocks/spaceAmountCampaignRanking.json');
        return spaceAmountRanking;
    },

    async fetchQuantityRanking(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, groups) {
        const { default: spaceQuantityRanking } = await import('../../mocks/spaceQuantityCampaignRanking.json');
        return spaceQuantityRanking;
    },
};
