<template>
    <v-select
        :value="selected"
        :items="finalItems"
        :label="label"
        :class="className"
        :disabled="disabled"
        :loading="loading"
        multiple
        placeholder=" "
        outline
        hide-details
        dense
        :menu-props="{
            contentClass: useTreeview ? 'treeview-select-menu' : '',
        }"
        style="min-width:230px;"
        @input="handleInput"
        @change="handleChange"
        @blur="handleBlur"
    >
        <template v-slot:prepend-item>
            <v-list-tile ripple @click="toggle" :disabled="disabled">
                <v-list-tile-action>
                    <v-icon :color="selected.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title>{{ allLabel }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2" />

            <!-- Workaround: show treeview instead of items list if tree structured items are provided -->
            <v-treeview
                v-if="useTreeview"
                :class="{ 'no-pointer-events': disabled }"
                :value="selected"
                :items="itemsTreeFinal"
                item-key="id"
                item-text="text"
                item-children="children"
                multiple-active
                selectable
                transition
                hoverable
                selected-color="primary"
                @input="handleInput"
                @change="handleChange"
            >
                <template v-slot:label="{ item, selected }">
                    <span :class="{ 'primary--text': selected, 'text--disabled': item.disabled }">
                        {{ (typeof item === 'object') ? item.text : item }}
                    </span>
                    <div
                        @click="handleTreeviewItemClick"
                        v-ripple="{ class: 'primary--text'}"
                        class="treeview-item-click-layer"
                        :class="{ 'text--disabled': item.disabled }"
                    />
                </template>
            </v-treeview>
        </template>

        <!-- Workaround: hide actual items if tree structured items are provided  -->
        <template v-if="useTreeview" v-slot:item="{}">
            <span />
        </template>
        <template v-else v-slot:item="{ item }">
            <v-list-tile-action>
                <v-checkbox color="primary" :input-value="selected.includes((typeof item === 'object') ? item.value : item)" :disabled="disabled || item.disabled"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-title>
                <span v-if="item.html" v-html="item.html"></span>
                <span v-else>{{ (typeof item === 'object') ? item.text : item }}</span>
            </v-list-tile-title>
        </template>

        <template v-slot:selection="{ index }">
            <span v-if="selectedAll && index === 0">
                <span>{{ allLabel }}</span>
            </span>
            <span v-if="selectedSome && index === 0">
                <span v-html="selectedSomeItemLabel"></span>
            </span>
            <span v-if="selectedSome && index === 1" class="grey--text caption ml-1">
                (+{{ selected.length - 1 }} {{ $t('selectAll.others') }})
            </span>
        </template>
    </v-select>
</template>

<script>
import {
    debounce, deburr, difference, orderBy,
} from 'lodash';

export default {
    name: 'select-all',
    props: {
        value: {
            type: Array,
        },
        label: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        itemsTree: {
            type: Array,
            required: false,
        },
        allLabel: {
            type: String,
            default() {
                return this.$t('selectAll.all');
            },
        },
        className: {
            type: String,
            default: '',
        },
        requireSelection: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selected: [],
            skipBlur: false,
        };
    },
    computed: {
        finalItems() {
            if (this.disabled) {
                return this.items.map(item => ({
                    ...item,
                    disabled: true,
                }));
            }
            return this.items;
        },
        itemsTreeFinal() {
            if (this.disabled) {
                return this.itemsTree.map(item => ({
                    ...item,
                    disabled: true,
                }));
            }
            return this.itemsTree;
        },
        selectedAll() {
            return this.selected.length === this.items.filter(item => !item.header).length;
        },
        selectedSome() {
            return this.selected.length > 0 && !this.selectedAll;
        },
        selectedSomeItemLabel() {
            if (!this.selectedSome) {
                return '';
            }
            const selectedItems = this.items.filter(item => this.selected.includes((typeof item === 'object') ? item.value : item));
            const sortedItems = orderBy(selectedItems, [item => deburr((typeof item === 'object') ? item.text : item).toLowerCase()], ['asc']);
            return (typeof sortedItems[0] === 'object') ? (sortedItems[0].html || sortedItems[0].text) : sortedItems[0];
        },
        icon() {
            if (this.selectedAll) return 'fa-window-close';
            if (this.selectedSome) return 'fa-minus-square';
            return 'far fa-square';
        },
        hasGroups() {
            return this.items.some(item => item.header);
        },
        primaryItems() {
            if (!this.hasGroups) {
                return null;
            }
            const groups = [];
            let group = [];
            this.items.forEach((item, index) => {
                if (item.header) {
                    if (group.length > 0) {
                        groups.push([...group]);
                    }
                    group = [];
                } else {
                    group.push((typeof item === 'object') ? item.value : item);
                }
                if (index === this.items.length - 1 && group.length > 0) {
                    groups.push([...group]);
                }
            });
            return groups.find(item => item.length > 0);
        },
        useTreeview() {
            return Boolean(this.itemsTree) && this.itemsTree.length !== this.items.filter(item => ((typeof item === 'object') ? item.value : item) !== undefined).length;
        },
    },
    watch: {
        value(newValue, oldValue) {
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 > 0 || diff2 > 0) {
                this.handleInput(newValue);
            }
        },
    },
    created() {
        this.selected = [...this.value];
    },
    methods: {
        toggle() {
            this.skipBlur = true;
            setTimeout(() => {
                this.skipBlur = false;
            }, 50);
            this.$nextTick(() => {
                if (this.selectedAll) {
                    this.handleInput([]);
                } else {
                    this.handleInput(this.items.map(item => ((typeof item === 'object') ? item.value : item)));
                }
            });
        },
        handleInput(value) {
            // filter out group labels
            const filteredValue = value.filter(val => typeof val !== 'undefined');
            this.selected = value;
            this.$emit('input', filteredValue.sort());
        },
        handleChange() {
            this.$emit('change');
        },
        // workaround for double blur event being triggered in v-select
        // fixed in Vuetify v2.1.6
        handleBlur: debounce(function handleBlur() {
            // workaround for blur event being triggered after clicking on "select all" option
            if (this.skipBlur) {
                return;
            }
            // if input requires selection and no data selected, then select all from first group with any items if provided or just all automatically
            if (this.requireSelection && (!this.selected || this.selected.length === 0)) {
                let itemsToSelect = [];
                if (this.hasGroups && this.primaryItems && this.primaryItems.length > 0) {
                    itemsToSelect = this.primaryItems;
                } else {
                    itemsToSelect = this.items.map(item => ((typeof item === 'object') ? item.value : item));
                }
                this.handleInput(itemsToSelect);
            }
            this.$emit('blur');
        }, 100),
        handleTreeviewItemClick(e) {
            this.skipBlur = true;
            setTimeout(() => {
                this.skipBlur = false;
            }, 50);
            this.$nextTick(() => {
                // Workaround: make whole v-treeview items selectable, like in v-select
                const treeviewItem = e.target.closest('.v-treeview-node__root');
                if (treeviewItem) {
                    const treeviewItemCheckbox = treeviewItem.querySelector('.v-treeview-node__checkbox');
                    if (treeviewItemCheckbox) {
                        treeviewItemCheckbox.click();
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    .no-pointer-events {
        pointer-events: none;
    }
</style>
