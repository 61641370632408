<template>
    <column-graph
        class="mt-3"
        :title="$t('quantityOverTime.title')"
        type="small"
        :data="items"
        :options="options"
        :loading="loading"
        :error="error"
        :no-results-title="noResultsTitle"
        :tooltip="tooltip"
    >
        <template #leftFilters>
            <div class="left-filters">
                <filter-summary :filters="commonFiltersSummary" />
                <Chip
                    :disabled="loading || isCommonLoading"
                    :label="$t('filters.reset')"
                    :active="!isCommonFilterDefault"
                    @click="resetLocalFilters"
                />
            </div>
        </template>
        <template #filters>
            <v-flex shrink>
                <v-select
                    class="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="period"
                    @change="handlePeriodChange"
                    :items="periodOptions"
                    :disabled="isCommonFilterDefault"
                    placeholder=" "
                    :label="$t('filters.period')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
        </template>
    </column-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { difference, sumBy } from 'lodash';
import dateService from '@/services/dateService';
import FilterSummary from '../../../common/FilterSummary.vue';
import Chip from '../../../common/Chip.vue';
import eventBus from '../../../../services/eventBus';
import numberService from '../../../../services/numberService';
import ColumnGraph from '../../../graphs/ColumnGraph.vue';

export default {
    name: 'average-quantity-over-time',
    components: {
        ColumnGraph,
        Chip,
        FilterSummary,
    },
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        campaign: {
            type: Array,
            default: () => [],
        },
        category: {
            type: Array,
            default: () => [],
        },
        group: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        isCommonLoading: {
            type: Boolean,
            default: false,
        },
        isCommonFilterDefault: {
            type: Boolean,
            default: true,
        },
        commonFiltersSummary: {
            type: Array,
            default: () => [],
        },
        hasAllCampaignsSelected: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            region: process.env.VUE_APP_REGION_MODE,
            data: null,
            error: null,
            options: {
                vAxes: {
                    0: {
                        title: `${this.$t('quantityOverTime.quantity')}`,
                    },
                },
            },
            period: 'daily',
        };
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
            ) {
                this.setLowestPeriod();
                this.getData();
            }
        },
        campaign(newValue, oldValue) {
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 !== 0 || diff2 !== 0) {
                this.getData();
            }
        },
        category(newValue, oldValue) {
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 !== 0 || diff2 !== 0) {
                this.getData();
            }
        },
        group(newValue, oldValue) {
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 !== 0 || diff2 !== 0) {
                this.getData();
            }
        },
        products(newValue, oldValue) {
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 !== 0 || diff2 !== 0) {
                this.getData();
            }
        },
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('loadingChange', this.loading);
            }
        },
    },
    computed: {
        loading() {
            return this.producerTransactionsItemsQuantityPriceLoading;
        },
        items() {
            if (!this.data || this.data.length === 0) {
                return null;
            }

            const items = [
                [this.$t('date'), this.$t('quantityOverTime.quantity')],
            ];
            const formatName = dateService.getFormatFromPeriod(this.period);

            // group data by period
            const dataByPeriod = {};
            this.data.forEach(item => {
                const period = dateService.formatI18nDate(item.date, formatName);
                if (!dataByPeriod[period]) {
                    dataByPeriod[period] = [];
                }
                dataByPeriod[period].push(item);
            });

            Object.keys(dataByPeriod).forEach(period => {
                // sum data in period
                const periodQuantitySum = sumBy(dataByPeriod[period], 'quantity') || 0;

                // group quantity by price
                const quantityByPrice = {};
                dataByPeriod[period].forEach(item => {
                    item.summary?.forEach(summaryItem => {
                        if (!quantityByPrice[summaryItem.price]) {
                            quantityByPrice[summaryItem.price] = 0;
                        }
                        quantityByPrice[summaryItem.price] += summaryItem.quantity || 0;
                    });
                });

                // create period summary
                const summary = Object.keys(quantityByPrice)
                    .map(price => `${numberService.formatNumber(quantityByPrice[price] || 0)} (${numberService.formatCurrency(parseFloat(price))})`)
                    .join(' | ');

                // create chart item
                items.push([period, { v: periodQuantitySum, f: summary }]);
            });

            return items;
        },
        noResultsTitle() {
            let transTag = !this.product ? 'quantityOverTime.noProductSelected' : 'noResults';
            if (this.region === 'PL' && !this.hasAllCampaignsSelected) {
                transTag = 'quantityOverTime.requiredAllCampaignsSelected';
            }
            return this.$t(transTag);
        },
        periodOptions() {
            const { startDate, endDate } = this.dateFilter;
            const groupBy = dateService.getRangeType(startDate, endDate);
            return [
                {
                    value: 'daily',
                    text: this.$t('filters.periodDaily'),
                    disabled: ['week', 'month', 'quarter'].includes(groupBy),
                },
                {
                    value: 'monthly',
                    text: this.$t('filters.periodMonthly'),
                    disabled: ['quarter'].includes(groupBy),
                },
                {
                    value: 'quarterly',
                    text: this.$t('filters.periodQuarterly'),
                },
                {
                    value: 'annually',
                    text: this.$t('filters.periodAnnually'),
                },
            ];
        },
        ...mapState({
            producerTransactionsItemsQuantityPriceLoading: state => state.reportsPriceDistribution.loading.producerTransactionsItemsQuantityPrice,
        }),
        ...mapGetters({
            getterProducerTransactionsItemsQuantityPrice: 'reportsPriceDistribution/getProducerTransactionsItemsQuantityPrice',
        }),
    },
    created() {
        this.setLowestPeriod();
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        setLowestPeriod(force = false) {
            const rangeTypeToLowestPeriodMap = {
                day: 'daily',
                month: 'monthly',
                quarter: 'quarterly',
            };
            const { startDate, endDate } = this.dateFilter;
            const groupBy = dateService.getRangeType(startDate, endDate);

            if (
                force
                || !(
                    groupBy === 'day'
                    || (groupBy === 'month' && ['monthly', 'quarterly', 'annually'].includes(this.period))
                    || (groupBy === 'quarter' && ['quarterly', 'annually'].includes(this.period))
                )
            ) {
                this.period = rangeTypeToLowestPeriodMap[groupBy];
            }
        },
        resetLocalFilters() {
            this.$emit('resetLocalFilters');
        },
        reset() {
            this.setLowestPeriod(true);
            this.getData();
        },
        getData(force = false) {
            this.getProducerTransactionsItemsQuantityPrice(force);
        },
        handlePeriodChange() {
            this.emitFilterDefaultChange();
        },
        redo() {
            this.getData(true);
        },
        async getProducerTransactionsItemsQuantityPrice(force = false) {
            try {
                const {
                    startDate, endDate,
                } = this.dateFilter;
                this.error = null;
                if (((!this.products || this.products.length === 0) && (!this.group || this.group.length === 0) && (!this.category || this.category.length === 0)) || (this.region === 'PL' && !this.hasAllCampaignsSelected)) {
                    this.data = null;
                    return;
                }
                const products = this.products.map(product => product.id);
                const entities = this.products.map(product => product.entityId);
                await this.fetchProducerTransactionsItemsQuantityPrice({
                    force,
                    startDate,
                    endDate,
                    campaign: this.campaign,
                    category: this.category,
                    group: this.group,
                    products,
                    entities,
                });
                this.data = this.getterProducerTransactionsItemsQuantityPrice(startDate, endDate, this.campaign, this.category, this.group, products);
            } catch (e) {
                this.data = null;
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchProducerTransactionsItemsQuantityPrice: 'reportsPriceDistribution/fetchProducerTransactionsItemsQuantityPrice',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .left-filters {
        display: inline-flex;
        align-items: center;
    }
</style>
