import dictionaryService from '../../services/dictionaryService';
import {
    CLEAN_DICTIONARY,
    FETCH_CATEGORIES_FAILURE,
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_SPACE_STATUSES_FAILURE,
    FETCH_SPACE_STATUSES_REQUEST,
    FETCH_SPACE_STATUSES_SUCCESS,
    FETCH_SPACE_TYPES_FAILURE,
    FETCH_SPACE_TYPES_REQUEST,
    FETCH_SPACE_TYPES_SUCCESS,
} from '../mutationTypes';

const initialState = {
    categoriesIds: [],
    categories: {},
    spaceTypesIds: [],
    spaceTypes: {},
    spaceStatusesIds: [],
    spaceStatuses: {},
    loading: {
        categories: false,
        spaceTypes: false,
        spaceStatuses: false,
    },
};

const getters = {
    getCategory: state => categoryId => state.categories[Number.parseInt(categoryId, 10)],
    getSpaceType: state => typeId => state.spaceTypes[Number.parseInt(typeId, 10)],
    getSpaceStatus: state => statusId => state.spaceStatuses[Number.parseInt(statusId, 10)],

    getCategoryOptions: state => state.categoriesIds.map(id => ({
        value: state.categories[id].id,
        text: state.categories[id].name,
    })),
};

const actions = {
    cleanDictionary({ commit }) {
        commit(CLEAN_DICTIONARY);
    },

    async fetchCategories({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.categoriesIds && state.categoriesIds.length > 0) {
                return;
            }

            commit(FETCH_CATEGORIES_REQUEST);
            let categories = await dictionaryService.fetchCategories();

            if (categories && categories.length > 0) {
                categories = categories.filter(cat => cat.isActive === true);
            }

            commit(FETCH_CATEGORIES_SUCCESS, {
                items: categories,
            });
        } catch (e) {
            commit(FETCH_CATEGORIES_FAILURE);
            throw e;
        }
    },

    async fetchSpaceTypes({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.spaceTypesIds && state.spaceTypesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_TYPES_REQUEST);
            const spaceTypes = await dictionaryService.fetchSpaceTypes();

            commit(FETCH_SPACE_TYPES_SUCCESS, {
                items: spaceTypes,
            });
        } catch (e) {
            commit(FETCH_SPACE_TYPES_FAILURE);
            throw e;
        }
    },

    async fetchSpaceStatuses({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.spaceStatusesIds && state.spaceStatusesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_STATUSES_REQUEST);
            const spaceStatuses = await dictionaryService.fetchSpaceStatuses();

            commit(FETCH_SPACE_STATUSES_SUCCESS, {
                items: spaceStatuses,
            });
        } catch (e) {
            commit(FETCH_SPACE_STATUSES_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN
    // -----------------------------------------
    [CLEAN_DICTIONARY](state) {
        state.categoriesIds = [];
        state.categories = {};
        state.spaceTypesIds = [];
        state.spaceTypes = {};
        state.spaceStatusesIds = [];
        state.spaceStatuses = {};
        state.categories = false;
        state.spaceTypes = false;
        state.spaceStatuses = false;
    },

    // -----------------------------------------
    // FETCH_CATEGORIES
    // -----------------------------------------
    [FETCH_CATEGORIES_REQUEST](state) {
        state.loading.categories = true;
    },
    [FETCH_CATEGORIES_SUCCESS](state, payload) {
        const { items } = payload;
        const categoriesIds = [];
        const categories = {};
        items.forEach((category) => {
            categoriesIds.push(category.id);
            categories[category.id] = category;
        });
        state.loading.categories = false;
        state.categoriesIds = categoriesIds;
        state.categories = categories;
    },
    [FETCH_CATEGORIES_FAILURE](state) {
        state.loading.categories = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_TYPES
    // -----------------------------------------
    [FETCH_SPACE_TYPES_REQUEST](state) {
        state.loading.spaceTypes = true;
    },
    [FETCH_SPACE_TYPES_SUCCESS](state, payload) {
        const { items } = payload;
        const spaceTypesIds = [];
        const spaceTypes = {};
        items.forEach((type) => {
            spaceTypesIds.push(type.value);
            spaceTypes[type.value] = type;
        });
        state.loading.spaceTypes = false;
        state.spaceTypesIds = spaceTypesIds;
        state.spaceTypes = spaceTypes;
    },
    [FETCH_SPACE_TYPES_FAILURE](state) {
        state.loading.spaceTypes = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_STATUSES
    // -----------------------------------------
    [FETCH_SPACE_STATUSES_REQUEST](state) {
        state.loading.spaceStatuses = true;
    },
    [FETCH_SPACE_STATUSES_SUCCESS](state, payload) {
        const { items } = payload;
        const spaceStatusesIds = [];
        const spaceStatuses = {};
        items.forEach((status) => {
            spaceStatusesIds.push(status.value);
            spaceStatuses[status.value] = status;
        });
        state.loading.spaceStatuses = false;
        state.spaceStatusesIds = spaceStatusesIds;
        state.spaceStatuses = spaceStatuses;
    },
    [FETCH_SPACE_STATUSES_FAILURE](state) {
        state.loading.spaceStatuses = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
