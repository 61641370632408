<template>
    <div class="info-tooltip-wrapper">
        <v-tooltip top  max-width="300">
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#393C41" size="8" class="info-tooltip-icon" :class="{'info-tooltip-icon-force-show': forceShow}">fa-info</v-icon>
            </template>
            <span>{{ text }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: 'InfoTooltip',
    props: {
        text: {
            type: String,
            required: true,
        },
        forceShow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
    .info-tooltip-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        .info-tooltip-icon {
            vertical-align: middle;
            width: 16px;
            height: 16px;
            line-height: 1;
            background: #f5f5f5;
            border-radius: 100%;
            border: 1px solid #393C41;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px;
            // Stops the icon from being rotated in table header when sorting
            transform: rotate(0deg) !important;
        }
        .info-tooltip-icon-force-show {
            opacity: 1 !important;
        }
    }
</style>
