<template>
    <div v-if="hasPermissions" class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <div class="full-width mb-4">
                <v-layout row wrap align-center justify-space-between>
                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('salesBySource') }}
                    </h3>
                    <v-layout
                        wrap
                        align-center
                        justify-end
                    >
                        <v-flex shrink>
                            <SelectAll
                                class="d-inline-block ml-3 mb-3 select--responsive"
                                v-model="sources"
                                @blur="getData()"
                                :items="sourcesOptions"
                                :disabled="loading"
                                placeholder=" "
                                :label="$t('filters.sources')"
                                outline
                                hide-details
                                dense
                                multiple
                                require-selection
                                :loading="uniqueSourcesLoading"
                            />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block ml-3 mb-3 select--responsive"
                                v-model="types"
                                @change="getData"
                                :items="typesOptions"
                                :disabled="loading"
                                placeholder=" "
                                :label="$t('filters.type')"
                                outline
                                hide-details
                                dense
                            />
                        </v-flex>
                        <v-flex v-show="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block ml-3 mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="loading"
                                :label="$t('productsRanking.group')"
                                :all-label="$t('productsRanking.allGroups')"
                                require-selection
                                @blur="getData()"
                            />
                        </v-flex>
                    </v-layout>
                </v-layout>

                <div>
                    <v-layout wrap align-center justify-end>
                        <v-flex v-show="!showProductGroupsResults" shrink>
                            <product-selector
                                style="min-width:320px;"
                                class="mb-3"
                                component-id="filterSalesBySource"
                                v-model="products"
                                :disabled="loading"
                                multiple
                                @blur="getData()"
                            />
                        </v-flex>
                        <v-flex class="mb-3 ml-3" shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="loading"
                                @change="getData()"
                            />
                        </v-flex>
                    </v-layout>
                </div>

            </div>
        </v-card-title>

        <div class="layout row wrap">
            <div class="line-chart" style="">
                <line-graph
                    ref="lineChart"
                    title=""
                    :data="chartData"
                    :options="chartOptions"
                    :loading="loading"
                    :error="error"
                    :no-results-title="$t('salesGraph.noResults')"
                    @redo="redo"
                />
            </div>
            <div v-if="pieChartData" class="pie-chart" style="">
                <pie-graph
                    ref="pieChart"
                    title=""
                    :data="pieChartData"
                    :options="pieChartOptions"
                    :total-type="pieTotalType"
                />
            </div>
        </div>

    </div>

    <div v-else class="section-placeholder mb-4">
        <img class="section-placeholder-img" :src="require('../../../../assets/noAccessPlaceholders/sales-by-source.png')" alt="">
        <NoAccessContactForm class="section-placeholder-form" :permission="spaceService.permissions.CAMPAIGN_ANALYSIS" />
    </div>
</template>

<script>
import { sortBy } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import spaceService from '@/services/spaceService';
import SelectAll from '@/components/common/SelectAll.vue';
import NoAccessContactForm from '@/components/common/NoAccessContactForm.vue';
import ProductSelector from '@/components/common/ProductSelector.vue';
import categoryFilterMixin from '@/mixins/categoryFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import sourcesFilterMixin from '@/mixins/sourcesFilterMixin';
import dateService from '../../../../services/dateService';
import LineGraph from '../../../graphs/LineGraph.vue';
import PieGraph from '../../../graphs/PieGraph.vue';
import eventBus from '../../../../services/eventBus';

export default {
    name: 'SalesBySource',
    components: {
        LineGraph,
        PieGraph,
        SelectAll,
        NoAccessContactForm,
        ProductSelector,
    },
    mixins: [
        categoryFilterMixin,
        groupsFilterMixin,
        sourcesFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            spaceService,
            data: null,
            error: null,
            types: 'amount', // single value
            typesOptions: [
                {
                    value: 'amount',
                    text: this.$t('salesAmount'),
                },
                {
                    value: 'quantity',
                    text: this.$t('quantity'),
                },
                {
                    value: 'orders',
                    text: this.$t('transactions'),
                },
                {
                    value: 'widget_impressions',
                    text: this.$t('impressions'),
                },
                {
                    value: 'clicks',
                    text: this.$t('clicks'),
                },
                {
                    value: 'ctr',
                    text: this.$t('ctr'),
                },
                {
                    value: 'cr',
                    text: this.$t('cr'),
                },
            ],
            products: [],
            showProductGroupsResults: false,
        };
    },
    computed: {
        chartOptions() {
            return {
                vAxes: {
                    0: {
                        title: this.chartYAxesTitle,
                    },
                },
                vAxis: {
                    minValue: 0,
                    viewWindow: {
                        min: 0,
                    },
                },
                curveType: 'function',
            };
        },
        pieChartOptions() {
            return {
                pieHole: 0.5,
                legend: {
                    position: 'labeled',
                },
                chartArea: {
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                    width: '50%',
                    height: '50%',
                },
                pieSliceText: 'none',
                height: 300,
            };
        },
        pieTotalType() {
            return 'decimal';
        },
        chartYAxesTitle() {
            return this.typesOptions.find(item => item.value === this.types).text;
        },
        loading() {
            return this.featureBySourceChartLoading || this.filtersLoading;
        },
        chartData() {
            if (!this.data?.chart?.length) {
                return null;
            }

            const sources = Object.keys(this.data.chart[0]).filter(source => source !== 'date');

            const items = [
                [this.$t('date'), ...sources],
            ];

            this.data.chart.forEach(item => {
                const data = sources.map(source => ({ v: item[source], f: item[source] }));
                const date = dateService.formatI18nDate(item.date);
                items.push([date, ...data]);
            });

            return items;
        },
        pieChartData() {
            if (!this.data?.pie_chart?.length) {
                return null;
            }

            const items = [
                ['Name', 'Value'],
            ];

            this.data.pie_chart.forEach(item => {
                items.push([item[0], { v: item[1], f: item[1] }]);
            });

            return items;
        },
        filtersLoading() {
            return this.uniqueSourcesLoading || this.producerProductCategoriesLoading || this.groupsLoading;
        },
        hasPermissions() {
            return spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS);
        },
        ...mapState({
            featureBySourceChartLoading: state => state.reportsSales.loading.featureBySourceChart,
            userId: state => state.auth.user?.id,
        }),
        ...mapGetters({
            getterFeatureBySourceChart: 'reportsSales/getFeatureBySourceChart',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        filtersLoading(loading) {
            if (!loading) this.reset();
        },
        sourcesOptions: {
            immediate: true,
            handler: 'setDefaultSources',
        },
    },
    async created() {
        await this.getCategories();
        this.setDefaultCategory();
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        exportData() {},
        reset() {
            this.setDefaultSources();
            this.setDefaultCategory();
            this.setDefaultGroup();
            this.getData();
        },
        getData(force = false) {
            if (!this.hasPermissions) return;
            this.getFeatureBySourceChart(force);
        },
        redo() {
            this.getData(true);
        },
        async getFeatureBySourceChart(force = false) {
            if (this.filtersLoading) return;
            try {
                this.data = null;
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                const feature = this.types;
                const params = {
                    force,
                    startDate,
                    endDate,
                    feature,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                } else {
                    if (this.category.length) params.category = sortBy(this.category).join(','); // eslint-disable-line no-lonely-if
                    if (this.products.length) params.products = sortBy(this.products.map(item => item.entityId)).join(',');
                }
                await this.fetchFeatureBySourceChart(params);
                this.data = this.getterFeatureBySourceChart(params.startDate, params.endDate, params.sources, params.feature, params.category, params.group, params.products);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            fetchFeatureBySourceChart: 'reportsSales/fetchFeatureBySourceChart',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .line-chart {
        flex-basis: 60%;
        width: 500px;
        max-width: 100%;
        flex-grow: 1;
    }

    .pie-chart {
        flex-basis: 40%;
        width: 500px;
        max-width: Min(600px, 100%);
        flex-grow: 1;
    }

    .section-placeholder {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-form {
            position:absolute;
            z-index:10;
            left:50%;
            top:50%;
            transform:translate(-50%, -50%);
        }
    }
</style>
