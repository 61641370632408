<template>

    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <div class="full-width">
                <v-layout row wrap align-center justify-space-between>

                    <h3 class="title font-weight-bold mb-3 d-flex align-center">
                        {{ $t('salesSummary2') }}
                    </h3>

                    <v-layout wrap align-center justify-end>
                        <v-flex shrink>
                            <SelectAll
                                v-if="spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)"
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="sources"
                                @blur="getData()"
                                :items="sourcesOptions"
                                :disabled="allSalesChartLoading || filtersLoading"
                                placeholder=" "
                                :label="$t('filters.sources')"
                                outline
                                hide-details
                                dense
                                multiple
                                :require-selection="false"
                                :loading="uniqueSourcesLoading"
                            />
                            <FormSourcesPlaceholder v-else />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="type"
                                :items="typeOptions"
                                :disabled="allSalesChartLoading || filtersLoading"
                                placeholder=" "
                                :label="$t('filters.type')"
                                outline
                                hide-details
                                dense
                                @change="getData()"
                            />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="conversion"
                                @change="getData()"
                                :items="conversionOptions"
                                :disabled="allSalesChartLoading || filtersLoading"
                                placeholder=" "
                                :label="$t('salesSummary.conversion')"
                                outline
                                hide-details
                                dense
                            />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="period"
                                @change="getData()"
                                :items="periodOptions"
                                :disabled="allSalesChartLoading || filtersLoading"
                                placeholder=" "
                                :label="$t('filters.period')"
                                outline
                                hide-details
                                dense
                            />
                        </v-flex>
                        <v-flex v-show="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="allSalesChartLoading || filtersLoading"
                                :label="$t('productsRanking.group')"
                                :all-label="$t('productsRanking.allGroups')"
                                require-selection
                                @blur="getData()"
                            />
                        </v-flex>
                    </v-layout>

                </v-layout>

                <div>
                    <v-layout wrap align-center justify-end>
                        <v-flex class="mb-3 ml-3" shrink>
                            <v-switch
                                v-model="showProductGroupsResults"
                                :label="$t('filters.showProductGroupsResults')"
                                :disabled="allSalesChartLoading"
                                @change="getData()"
                            />
                        </v-flex>
                    </v-layout>
                </div>

                <v-alert
                    v-if="errorAllSalesChart"
                    :value="errorAllSalesChart"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{errorAllSalesChart}}
                </v-alert>

                <div v-else class="layout row wrap">
                    <template>
                        <div class="column-chart" style="">
                            <column-graph
                                ref="columnGraph"
                                title=""
                                :data="columnChartData"
                                :options="columnChartOptions"
                                :loading="allSalesChartLoading || filtersLoading"
                                :no-results-title="$t('salesGraph.noResults')"
                                :events="chartEvents"
                                @redo="redo"
                            />
                        </div>
                        <div class="pie-chart" style="">
                            <pie-graph
                                ref="pieChart"
                                title=""
                                :data="pieChartData"
                                :options="pieChartOptions"
                                :loading="allSalesChartLoading || filtersLoading"
                                total-type="decimal"
                            />
                        </div>
                    </template>
                </div>

            </div>
        </v-card-title>

        <v-card-text>
            <div v-if="summaryLoading || filtersLoading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                v-if="errorProducerSummary"
                :value="errorProducerSummary"
                dismissible
                type="error"
                transition="scale-transition">
                {{errorProducerSummary}}
            </v-alert>
            <div v-if="!(summaryLoading || filtersLoading) && !errorProducerSummary">
                <v-data-table
                    class="elevation-0 table--transparent"
                    :headers="headers"
                    :items="summaryData || []"
                    :no-data-text="$t('salesSummary.noResults')"
                    :no-results-text="$t('salesSummary.noResults')"
                    :rows-per-page-text="$t('table.rowsPerPage')"
                    :pagination.sync="pagination"
                    :rows-per-page-items="rowsPerPageItems"
                    disable-initial-sort
                    sort-icon="fa-angle-up"
                >
                    <template v-slot:headers="props">
                        <tr>
                            <th
                                v-for="header in props.headers"
                                :key="header.value"
                                :class="[
                                    'column',
                                    header.value === pagination.sortBy ? 'active' : '',
                                    header.sortable ? 'sortable' : '',
                                ]"
                                @click="header.sortable ? changeSort(header.value) : () => {}"
                            >
                                <v-layout
                                    :justify-start="header.align === 'left'"
                                    :justify-end="header.align === 'right'"
                                    align-center
                                >
                                    {{ header.text }}
                                    <sort-order v-if="header.sortable" :direction="getSortOrder(header.value)" />
                                </v-layout>
                            </th>
                        </tr>
                    </template>

                    <template v-slot:pageText="props">
                        {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                    </template>

                    <template v-slot:items="props">
                        <td class="no-wrap-text">
                            {{ formatDate(props.item.date) }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.viewsFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.clicksFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.ctrFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.transactionsFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.crFormatted }}
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.quantityFormatted }}
                        </td>
                        <td class="text-xs-right">
                            <share-multiple
                                :values="[props.item.ownQuantity, props.item.otherQuantity, props.item.unknownQuantity]"
                                :labels="[
                                    `${$t('quantity2')}: {{value}} ${$t('salesSummaryQuantityItem')} ({{valuePercentFormatted}}) ${$t('ownProducts').toLowerCase()}`,
                                    `${$t('quantity2')}: {{value}} ${$t('salesSummaryQuantityItem')} ({{valuePercentFormatted}}) ${$t('otherProducts').toLowerCase()}`,
                                    `${$t('quantity2')}: {{value}} ${$t('salesSummaryQuantityItem')} ({{valuePercentFormatted}}) ${$t('unknownProducts').toLowerCase()}`,
                                ]"
                                :colors="colors"
                                :valueFormatter="numberService.formatCurrency"
                            />
                        </td>
                        <td class="text-xs-right">
                            {{ props.item.amountFormatted }}
                        </td>
                        <td class="text-xs-right">
                            <share-multiple
                                :values="[props.item.ownAmount, props.item.otherAmount, props.item.unknownAmount]"
                                :labels="[
                                    `${$t('salesAmount')}: {{valueFormatted}} ({{valuePercentFormatted}}) ${$t('ownProducts').toLowerCase()}`,
                                    `${$t('salesAmount')}: {{valueFormatted}} ({{valuePercentFormatted}}) ${$t('otherProducts').toLowerCase()}`,
                                    `${$t('salesAmount')}: {{valueFormatted}} ({{valuePercentFormatted}}) ${$t('unknownProducts').toLowerCase()}`,
                                ]"
                                :colors="colors"
                                :valueFormatter="numberService.formatCurrency"
                            />
                        </td>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>

    </div>

</template>

<script>
import { sortBy, sumBy, round } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import spaceService from '@/services/spaceService';
import dateService from '@/services/dateService';
import numberService from '@/services/numberService';
import sourcesFilterMixin from '@/mixins/sourcesFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import ColumnGraph from '@/components/graphs/ColumnGraph.vue';
import PieGraph from '@/components/graphs/PieGraph.vue';
import SelectAll from '@/components/common/SelectAll.vue';
import SortOrder from '@/components/common/SortOrder.vue';
import ShareMultiple from '@/components/common/ShareMultiple.vue';
import FormSourcesPlaceholder from '@/components/common/FormSourcesPlaceholder.vue';

export default {
    name: 'SalesSalesSummary',
    components: {
        ColumnGraph,
        PieGraph,
        SelectAll,
        SortOrder,
        ShareMultiple,
        FormSourcesPlaceholder,
    },
    mixins: [
        sourcesFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        const colors = ['#00e08f', '#FF0059', '#2d3136'];
        return {
            colors,
            spaceService,
            dateService,
            numberService,
            chartData: null,
            summaryData: null,
            period: 'daily',
            periodOptions: [
                {
                    value: 'daily',
                    text: this.$t('filters.periodDaily'),
                },
                {
                    value: 'monthly',
                    text: this.$t('filters.periodMonthly'),
                },
                {
                    value: 'quarterly',
                    text: this.$t('filters.periodQuarterly'),
                },
                {
                    value: 'annually',
                    text: this.$t('filters.periodAnnually'),
                },
            ],
            conversion: 24,
            conversionOptions: [
                {
                    value: 'all',
                    text: this.$t('salesSummary.allConversion'),
                },
                {
                    value: 24,
                    text: this.$t('salesSummary.nConversion', { hours: 24 }),
                },
            ],
            type: 'amount',
            typeOptions: [
                {
                    value: 'amount',
                    text: this.$t('salesAmount'),
                },
                {
                    value: 'quantity',
                    text: this.$t('quantity'),
                },
                {
                    value: 'orders',
                    text: this.$t('transactions'),
                },
            ],
            chartEvents: {
                ready: () => {
                    const chart = this.$refs.columnGraph?.$refs.chart;
                    if (chart) {
                        chart.isReady = true;
                        chart.addChartActionReset();
                        chart.addChartTooltipPosition();
                    }
                },
                select: () => {},
            },
            showProductGroupsResults: false,
            columnChartOptions: {
                colors,
                isStacked: true,
            },
            pieChartOptions: {
                colors,
                pieHole: 0.5,
                legend: {
                    position: 'labeled',
                },
                chartArea: {
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                    width: '50%',
                    height: '50%',
                },
                pieSliceText: 'none',
                height: 300,
            },
            headers: [
                {
                    text: this.$t('salesSummary.date'),
                    align: 'left',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: this.$t('salesSummary.views'),
                    align: 'right',
                    sortable: true,
                    value: 'views',
                },
                {
                    text: this.$t('salesSummary.clicks'),
                    align: 'right',
                    sortable: true,
                    value: 'clicks',
                },
                {
                    text: this.$t('salesSummary.ctr'),
                    align: 'right',
                    sortable: true,
                    value: 'ctr',
                },
                {
                    text: this.$t('salesSummary.transaction'),
                    align: 'right',
                    sortable: true,
                    value: 'transactions',
                },
                {
                    text: this.$t('salesSummary.cr'),
                    align: 'right',
                    sortable: true,
                    value: 'cr',
                },
                {
                    text: this.$t('salesSummary.quantity'),
                    align: 'right',
                    sortable: true,
                    value: 'quantity',
                },
                {
                    text: this.$t('salesTransactions.share'),
                    align: 'right',
                    sortable: false,
                    value: 'shareQuantity',
                },
                {
                    text: this.$t('salesSummary.amount'),
                    align: 'right',
                    sortable: true,
                    value: 'amount',
                },
                {
                    text: this.$t('salesTransactions.share'),
                    align: 'right',
                    sortable: false,
                    value: 'shareAmount',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'date',
                descending: true,
            },
            errorAllSalesChart: null,
            errorProducerSummary: null,
        };
    },
    computed: {
        columnChartData() {
            if (!this.chartData || this.chartData.length === 0 || !this.type) {
                return null;
            }

            const types = Object.keys(this.chartData?.[0] || {}).splice(1);

            const items = [
                [this.$t('date'), ...types.map(type => `${this.mapTypeProp(this.type, 'text')} (${this.$t(`salesTypeList.${type}`)})`)],
            ];
            const formatName = dateService.getFormatFromPeriod(this.period);
            this.chartData.forEach(item => {
                const date = dateService.formatI18nDate(item.date, formatName);
                const data = types.map(type => {
                    const v = item[type] || 0;
                    const f = item[type] || 0;
                    return { v, f };
                });
                items.push([
                    date,
                    ...data,
                ]);
            });
            return items;
        },
        pieChartData() {
            if (!this.chartData?.length) {
                return null;
            }

            const items = [
                ['Name', 'Value'],
            ];

            const types = Object.keys(this.chartData?.[0] || {}).splice(1);

            types.forEach(type => {
                const val = round(sumBy(this.chartData, type), 2);
                const label = `${this.mapTypeProp(this.type, 'text')} (${this.$t(`salesTypeList.${type}`)})`;
                items.push([label, { v: val, f: val }]);
            });

            return items;
        },
        filtersLoading() {
            return this.uniqueSourcesLoading || this.groupsLoading;
        },
        ...mapState({
            summaryLoading: state => state.reportsSales.loading.summary,
            allSalesChartLoading: state => state.reportsSales.loading.allSalesChart,
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            getterAllSalesChart: 'reportsSales/getAllSalesChart',
            getterSummary: 'reportsSales/getSummary',
        }),
    },
    watch: {
        // sourcesOptions: {
        //     immediate: true,
        //     handler: 'setDefaultSources',
        // },
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        filtersLoading(loading) {
            if (!loading) this.reset();
        },
    },
    methods: {
        mapTypeProp(type, prop) {
            return this.typeOptions.find(t => t.value === type)?.[prop];
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.period === 'daily';
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        redo() {
            this.getData(true);
        },
        reset() {
            this.period = 'daily';
            this.conversion = 24;
            // this.setDefaultSources();
            this.setDefaultGroup();
            this.getData();
        },
        getData(force = false) {
            if (this.filtersLoading) return;
            this.emitFilterDefaultChange();
            this.getAllSalesChart(force);
            this.getSummary(force);
        },
        async getAllSalesChart(force = false) {
            if (this.filtersLoading) return;
            try {
                const { startDate, endDate } = this.dateFilter;
                this.errorAllSalesChart = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    period: this.period,
                    feature: this.type,
                    conversionTime: this.conversion !== 'all' ? this.conversion : null,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                // if (this.showProductGroupsResults) {
                //     if (this.group.length) params.group = sortBy(this.group).join(',');
                // }
                await this.fetchAllSalesChart(params);
                this.chartData = this.getterAllSalesChart(params.startDate, params.endDate, params.period, params.sources, params.group, params.feature, params.conversionTime);
            } catch (e) {
                this.errorAllSalesChart = e.message;
                this.chartData = null;
            }
        },
        async getSummary(force = false) {
            if (this.filtersLoading) return;
            try {
                const { startDate, endDate } = this.dateFilter;
                this.errorProducerSummary = null;
                const params = {
                    force,
                    startDate,
                    endDate,
                    conversion: this.conversion,
                    campaign: [],
                    period: this.period,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchSummary(params);
                let data = this.getterSummary(params.startDate, params.endDate, params.conversion, params.campaign, params.period, params.sources, params.group);
                if (data) {
                    data = data.map(item => ({
                        ...item,
                        viewsFormatted: numberService.formatNumber(item.views),
                        clicksFormatted: numberService.formatNumber(item.clicks),
                        ctrFormatted: numberService.formatPercent(item.ctr),
                        transactionsFormatted: numberService.formatNumber(item.transactions),
                        crFormatted: numberService.formatPercent(item.cr),
                        quantityFormatted: numberService.formatNumber(item.quantity),
                        shareQuantity: null,
                        amountFormatted: numberService.formatCurrency(item.amount),
                        shareAmount: null,
                    }));
                }
                this.summaryData = data;
            } catch (e) {
                this.errorProducerSummary = e.message;
                this.summaryData = null;
            }
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                if (this.pagination.descending === true) {
                    this.pagination.descending = false;
                } else {
                    this.pagination.sortBy = null;
                    this.pagination.descending = null;
                }
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = true;
            }
        },
        getSortOrder(headerValue) {
            if (!headerValue || headerValue !== this.pagination.sortBy || this.pagination.descending === null) {
                return null;
            }
            if (this.pagination.descending === true) {
                return 'desc';
            }
            if (this.pagination.descending === false) {
                return 'asc';
            }
            return null;
        },
        formatDate(date) {
            return dateService.formatI18nDate(date, this.dateFormatName);
        },
        ...mapActions({
            fetchAllSalesChart: 'reportsSales/fetchAllSalesChart',
            fetchSummary: 'reportsSales/fetchSummary',
        }),
    },
    created() {
        this.getData();
    },
};
</script>

<style lang="scss" scoped>
    .column-chart {
        flex-basis: 60%;
        width: 500px;
        max-width: 100%;
        flex-grow: 1;
    }

    .pie-chart {
        flex-basis: 40%;
        width: 500px;
        max-width: Min(600px, 100%);
        flex-grow: 1;
    }
</style>
