<template>
    <column-graph
        class="mt-3"
        title=""
        type="small"
        :data="items"
        :options="options"
        :no-results-title="$t('noResults')"
        :style-text-node="styleTextNode"
    />
</template>

<script>
import { max } from 'lodash';
import ColumnGraph from '@/components/graphs/ColumnGraph.vue';
import numberService from '@/services/numberService';

export default {
    name: 'competition-price',
    components: {
        ColumnGraph,
    },
    props: {
        set: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        productNames() {
            return Object
                .keys(this.data.products ?? {})
                .map(productId => this.data.products[productId].product.name);
        },
        items() {
            if (!this.set || !this.data) {
                return null;
            }
            const header = ['Product', 'Min price', 'Average price', 'Max price'];
            const items = [];
            const unavailableItems = [];

            [this.set.producerProduct, ...this.set.producerProducts, ...this.set.globalProducts].forEach(setProduct => {
                const productData = this.data.aggregations[setProduct.id] ?? this.data.products[setProduct.id];
                const isAvailable = Boolean(productData.custom.priceAvg);
                const item = [
                    productData.aggregation?.name ?? productData.product?.name,
                    { v: productData.custom.priceMin, f: numberService.formatCurrency(productData.custom.priceMin) },
                    { v: productData.custom.priceAvg, f: numberService.formatCurrency(productData.custom.priceAvg) },
                    { v: productData.custom.priceMax, f: numberService.formatCurrency(productData.custom.priceMax) },
                ];
                if (isAvailable) {
                    items.push(item);
                } else {
                    unavailableItems.push(item);
                }
            });
            if (!items && !unavailableItems) {
                return null;
            }
            return [header, ...items, ...unavailableItems];
        },
        options() {
            const options = {
                colors: ['#d4c1d4', '#77497a', '#a57a7b'],
                hAxis: {
                    slantedText: false,
                },
                vAxes: {
                    0: {
                        title: `${this.$t('price')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]`,
                        format: '#',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
            };

            let hasValueOverZero = false;

            if (this.items && this.items.length > 0) {
                // ignore first row with columns definition
                const filteredData = this.items.slice(1).map(row => row.slice(1).map(rowItem => rowItem.v));
                // flatten array and get max value
                const flatData = [].concat(...filteredData);
                hasValueOverZero = flatData.some(value => value > 0);
                const maxValue = max(flatData);
                if (maxValue > 0) {
                    options.vAxes[0].format = 'short';
                }
            }

            options.vAxes[0].viewWindow.max = hasValueOverZero ? null : 1;

            return options;
        },
    },
    methods: {
        styleTextNode(node) {
            // each line of product name is a separate <text> node grouped under a single <g> node
            // merging all <text> nodes text content into a single string value
            const parentGroup = node.closest('g');
            const label = Array
                .from(parentGroup?.getElementsByTagName('text') ?? [])
                .map(textNode => textNode.textContent).join(' ');

            // potentially can have false positive if competition products have exactly same names as producer product
            if (this.productNames.includes(label)) {
                /* eslint-disable no-param-reassign */
                node.style.fill = this.$vuetify.theme.primary;
                node.style.fontWeight = 700;
                /* eslint-enable no-param-reassign */
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
