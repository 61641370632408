import { deburr, orderBy } from 'lodash';
import { i18n } from '../../i18n/i18n';
import spaceService from '../../services/spaceService';
import {
    ACTIVATE_PERMISSION_FAILURE,
    ACTIVATE_PERMISSION_REQUEST,
    ACTIVATE_PERMISSION_SUCCESS,
    CLEAN_SPACES,
    CREATE_SPACE_FAILURE,
    CREATE_SPACE_REQUEST,
    CREATE_SPACE_SUCCESS,
    DEACTIVATE_PERMISSION_FAILURE,
    DEACTIVATE_PERMISSION_REQUEST,
    DEACTIVATE_PERMISSION_SUCCESS,
    FETCH_PERMISSIONS_FAILURE,
    FETCH_PERMISSIONS_REQUEST,
    FETCH_PERMISSIONS_SUCCESS,
    FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE,
    FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST,
    FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS,
    FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE,
    FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST,
    FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS,
    FETCH_SPACES_FAILURE,
    FETCH_SPACES_REQUEST,
    FETCH_SPACES_SUCCESS,
    SET_CURRENT_SPACE,
    UPDATE_SPACE_FAILURE,
    UPDATE_SPACE_REQUEST,
    UPDATE_SPACE_SUCCESS,
    FETCH_UNIQUE_SOURCES_REQUEST,
    FETCH_UNIQUE_SOURCES_SUCCESS,
    FETCH_UNIQUE_SOURCES_FAILURE,
} from '../mutationTypes';

const spaceIdFromStorage = Number.parseInt(window.localStorage.getItem('spaceId'), 10);
const initialSpaceId = !Number.isNaN(spaceIdFromStorage) ? spaceIdFromStorage : null;

const initialState = {
    spacesIds: [],
    spaces: {},
    total: 0,
    currentSpaceId: initialSpaceId,
    allowedCampaignsIds: [],
    allowedCampaigns: {},
    producerProductCategoriesIds: [],
    producerProductCategories: {},
    permissionsIds: [],
    permissions: {},
    uniqueSources: [],
    loading: {
        fetchSpaces: false,
        createSpace: false,
        updateSpace: false,
        fetchAllowedCampaigns: false,
        fetchProducerProductCategories: false,
        activatePermission: false,
        deactivatePermission: false,
        fetchPermissions: false,
        fetchUniqueSources: false,
    },
};

const getters = {
    spaceCount: state => state.total,
    getSpace: state => spaceId => state.spaces[spaceId],

    currentSpace: state => {
        if (!state.currentSpaceId) {
            return null;
        }
        return state.spaces[state.currentSpaceId];
    },

    // eslint-disable-next-line no-shadow
    isDemo: (state, getters) => {
        const space = getters.currentSpace;
        if (!space) {
            return false;
        }
        return space.id === spaceService.demoSpace.id;
    },

    // eslint-disable-next-line no-shadow
    currentSpacePermissions: (state, getters) => {
        const space = getters.currentSpace;
        if (!space) {
            return [];
        }
        return space.permissions || [];
    },

    getAllowedCampaignOptions: state => state.allowedCampaignsIds.map(campaignId => ({
        value: state.allowedCampaigns[campaignId].id,
        text: state.allowedCampaigns[campaignId].name,
    })),

    getProducerProductCategories: state => state.producerProductCategoriesIds.map(
        categoryId => state.producerProductCategories[categoryId],
    ),

    // eslint-disable-next-line no-shadow
    getProducerProductCategoriesOptions: (state, getters) => getters.getProducerProductCategories.map(category => ({
        value: category.id,
        text: category.name,
        lvl: category.lvl,
    })),

    // eslint-disable-next-line no-shadow
    getProducerProductCategoriesOptionsTree: (state, getters) => {
        const categoriesOptions = getters.getProducerProductCategories.map(category => ({
            ...category,
            value: category.id,
            text: category.name,
            children: [],
        }));

        // lvl descending order
        categoriesOptions.sort((a, b) => b.lvl - a.lvl);

        return categoriesOptions.reduce((categories, currentCategory) => {
            const categoryParentId = currentCategory.parent && currentCategory.parent.id;
            const parent = categoryParentId && categories.find(cat => cat.id === categoryParentId);
            if (parent) {
                parent.children.push(currentCategory);
            }
            return categories;
        }, categoriesOptions)
            .filter(category => category.lvl === 0);
    },
};

const actions = {
    cleanSpaces({ commit }) {
        commit(CLEAN_SPACES);
    },

    async fetchSpaces({
        commit, state, rootState, dispatch,
    }, payload = {}) {
        try {
            // prevent multiple concurrent requests
            if (state.loading.fetchSpaces) {
                return;
            }

            const { force } = payload;

            if (!force && state.spacesIds && state.spacesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACES_REQUEST);
            const requestData = {
                statuses: '1,2', // 1 - new, 2 - accepted, 3 - rejected
            };
            const spacesResult = await spaceService.fetchSpaces(rootState.auth.user.id, requestData);
            const { items } = spacesResult;
            let { max } = spacesResult;

            const demoEnabled = rootState.auth.user && rootState.auth.user.demo;
            if (demoEnabled) {
                items.push(spaceService.demoSpace);
                max += 1;
            }

            commit(FETCH_SPACES_SUCCESS, {
                items,
                total: max,
            });

            // check if spaces list contains existing currentSpaceId
            if (!items || items.length === 0) {
                // if no spaces found then make currentSpaceId null
                dispatch('setCurrentSpace', { spaceId: null });
            } else if (!items.find(space => space.id === state.currentSpaceId)) {
                // if currentSpaceId is not found in spaces list then set it to first available space
                dispatch('setCurrentSpace', { spaceId: items[0].id });
            }
        } catch (e) {
            commit(FETCH_SPACES_FAILURE);
            throw e;
        }
    },

    async createSpace({ commit, rootState, dispatch }, payload = {}) {
        try {
            const {
                name, url, description = '...', type = 1, category,
            } = payload;

            commit(CREATE_SPACE_REQUEST);

            await spaceService.createSpace(rootState.auth.user.id, {
                name, url, description, type, categories: [category],
            });

            commit(CREATE_SPACE_SUCCESS);

            dispatch('fetchSpaces', { force: true });
            this._vm.$toast.info(i18n.t('producerSpaces.createSuccess', { name }), { color: 'success' });
        } catch (e) {
            commit(CREATE_SPACE_FAILURE);
            throw e;
        }
    },

    async updateSpace({ commit }, payload = {}) {
        try {
            const {
                id, name, url, category,
            } = payload;

            commit(UPDATE_SPACE_REQUEST);

            const space = await spaceService.updateSpace(id, {
                name, url, categories: [category],
            });

            commit(UPDATE_SPACE_SUCCESS, { space });

            this._vm.$toast.info(i18n.t('producerSpaces.updateSuccess', { name }), { color: 'success' });
        } catch (e) {
            commit(UPDATE_SPACE_FAILURE);
            throw e;
        }
    },

    async updateSpaceWebhook({ commit }, payload = {}) {
        try {
            commit(UPDATE_SPACE_REQUEST);

            const space = await spaceService.updateSpace(payload.id, {
                webhook: payload.webhook,
            });

            commit(UPDATE_SPACE_SUCCESS, { space });

            this._vm.$toast.info(i18n.t('producerSpaces.updateWebhookSuccess', { name: space.name }), { color: 'success' });
        } catch (e) {
            commit(UPDATE_SPACE_FAILURE);
            throw e;
        }
    },

    setCurrentSpace({ commit, dispatch }, payload) {
        const { spaceId } = payload;

        commit(SET_CURRENT_SPACE, {
            spaceId,
        });

        dispatch('dictionary/cleanDictionary', null, { root: true });
        dispatch('products/cleanProducts', null, { root: true });
        dispatch('dashboard/cleanDashboard', null, { root: true });
        dispatch('plugin/cleanPlugin', null, { root: true });
        dispatch('reportsProducts/clean', null, { root: true });
        dispatch('reportsCategory/clean', null, { root: true });
        dispatch('reportsSales/clean', null, { root: true });
        dispatch('reportsShops/clean', null, { root: true });
        dispatch('reportsAvailability/clean', null, { root: true });
        dispatch('reportsPriceDistribution/clean', null, { root: true });
        dispatch('dataExport/clean', null, { root: true });
        dispatch('producerProductGroup/clearGroups', null, { root: true });

        // store space ID for use between page refresh
        if (spaceId) {
            window.localStorage.setItem('spaceId', spaceId);
        } else {
            window.localStorage.removeItem('spaceId');
        }
    },

    async fetchAllowedCampaigns({ commit, state }, payload = {}) {
        try {
            const { force } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if (!force && state.allowedCampaignsIds && state.allowedCampaignsIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST);

            const allowedCampaigns = await spaceService.fetchAllowedCampaigns(spaceId);

            commit(FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS, {
                items: allowedCampaigns,
            });
        } catch (e) {
            commit(FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE);
            throw e;
        }
    },

    async fetchProducerProductCategories({ commit, state }, payload = {}) {
        try {
            const { force } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if (!force && state.producerProductCategoriesIds && state.producerProductCategoriesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST);

            const producerProductCategories = await spaceService.fetchProducerProductCategories(spaceId);

            commit(FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS, {
                items: producerProductCategories,
            });
        } catch (e) {
            commit(FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE);
            throw e;
        }
    },

    async activatePermission({ commit, state }, payload = {}) {
        try {
            const { permissionName } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            commit(ACTIVATE_PERMISSION_REQUEST);
            const permissionData = await spaceService.activatePermission(spaceId, permissionName);
            commit(ACTIVATE_PERMISSION_SUCCESS, {
                spaceId,
                permissionData,
            });
            this._vm.$toast.info(i18n.t('activateReportModal.success', { name: i18n.t(`permissionContent.name.${permissionName}`) }), { color: 'success' });
        } catch (e) {
            commit(ACTIVATE_PERMISSION_FAILURE);
            throw e;
        }
    },

    async deactivatePermission({ commit, state }, payload = {}) {
        try {
            const { permissionId, permissionName } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId || !permissionId || !permissionName) {
                return;
            }

            commit(DEACTIVATE_PERMISSION_REQUEST);
            const permissionData = await spaceService.deactivatePermission(permissionId, permissionName);
            commit(DEACTIVATE_PERMISSION_SUCCESS, {
                permissionData,
            });
            this._vm.$toast.info(i18n.t('cancelSubscriptionModal.success', { name: i18n.t(`permissionContent.name.${permissionName}`) }), { color: 'success' });
        } catch (e) {
            commit(DEACTIVATE_PERMISSION_FAILURE);
            throw e;
        }
    },

    async fetchPermissions({ commit, state, rootState }) {
        try {
            const userId = rootState.auth.user.id;
            const spaceId = state.currentSpaceId;

            if (!spaceId || !userId) {
                return;
            }

            commit(FETCH_PERMISSIONS_REQUEST);
            const reqStatuses = 'active,canceled';
            const response = await spaceService.fetchPermissions(userId, reqStatuses);
            const spacePermissions = response.items.filter(permission => permission.space.id === spaceId);
            const permissions = Object.keys(spaceService.permissions).map(key => {
                const name = spaceService.permissions[key];
                const userPermission = spacePermissions.find(item => item.permission === name);
                if (userPermission) {
                    return userPermission;
                }
                return {
                    permission: name,
                    status: 'inactive',
                };
            });
            commit(FETCH_PERMISSIONS_SUCCESS, {
                permissions,
            });
        } catch (e) {
            commit(FETCH_PERMISSIONS_FAILURE);
            throw e;
        }
    },

    async fetchUniqueSources({ commit, state }, payload = {}) {
        try {
            const spaceId = payload.spaceId || state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if ((!payload.force && state.uniqueSources.length > 0) || state.loading.fetchUniqueSources) {
                return;
            }

            const { startDate, endDate } = payload;

            commit(FETCH_UNIQUE_SOURCES_REQUEST);

            const data = await spaceService.fetchUniqueSources(spaceId, startDate, endDate);

            commit(FETCH_UNIQUE_SOURCES_SUCCESS, data);

            return data; // eslint-disable-line consistent-return
        } catch (e) {
            commit(FETCH_UNIQUE_SOURCES_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_SPACES
    // -----------------------------------------
    [CLEAN_SPACES](state) {
        state.spacesIds = [];
        state.spaces = {};
        state.total = 0;
        state.currentSpaceId = null;
        state.allowedCampaignsIds = [];
        state.allowedCampaigns = {};
        state.producerProductCategoriesIds = [];
        state.producerProductCategories = {};
        state.permissionsIds = [];
        state.permissions = {};
        state.uniqueSources = [];
        state.loading.fetchSpaces = false;
        state.loading.createSpace = false;
        state.loading.updateSpace = false;
        state.loading.fetchAllowedCampaigns = false;
        state.loading.fetchProducerProductCategories = false;
        state.loading.activatePermission = false;
        state.loading.deactivatePermission = false;
        state.loading.fetchPermissions = false;
        state.loading.fetchUniqueSources = false;
    },

    // -----------------------------------------
    // FETCH_SPACES
    // -----------------------------------------
    [FETCH_SPACES_REQUEST](state) {
        state.loading.fetchSpaces = true;
    },
    [FETCH_SPACES_SUCCESS](state, payload) {
        const { items, total } = payload;
        const spacesIds = [];
        const spaces = {};
        items.forEach(space => {
            spacesIds.push(space.id);
            spaces[space.id] = space;
        });
        state.loading.fetchSpaces = false;
        state.spacesIds = spacesIds;
        state.spaces = spaces;
        state.total = total;
    },
    [FETCH_SPACES_FAILURE](state) {
        state.loading.fetchSpaces = false;
    },

    // -----------------------------------------
    // SET_CURRENT_SPACE
    // -----------------------------------------
    [SET_CURRENT_SPACE](state, payload) {
        const { spaceId } = payload;
        state.currentSpaceId = spaceId;
        state.allowedCampaignsIds = [];
        state.allowedCampaigns = {};
        state.producerProductCategoriesIds = [];
        state.producerProductCategories = {};
    },

    // -----------------------------------------
    // CREATE_SPACE
    // -----------------------------------------
    [CREATE_SPACE_REQUEST](state) {
        state.loading.createSpace = true;
    },
    [CREATE_SPACE_SUCCESS](state) {
        state.loading.createSpace = false;
    },
    [CREATE_SPACE_FAILURE](state) {
        state.loading.createSpace = false;
    },

    // -----------------------------------------
    // UPDATE_SPACE
    // -----------------------------------------
    [UPDATE_SPACE_REQUEST](state) {
        state.loading.updateSpace = true;
    },
    [UPDATE_SPACE_SUCCESS](state, payload) {
        const { space } = payload;
        state.loading.updateSpace = false;
        state.spaces = {
            ...state.spaces,
            [space.id]: space,
        };
    },
    [UPDATE_SPACE_FAILURE](state) {
        state.loading.updateSpace = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_ALLOWED_CAMPAIGNS
    // -----------------------------------------
    [FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST](state) {
        state.loading.fetchAllowedCampaigns = true;
    },
    [FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS](state, payload) {
        const { items } = payload;
        const allowedCampaignsIds = [];
        const allowedCampaigns = {};
        const orderedItems = orderBy(items, [item => deburr(item.name.toLowerCase())], ['asc']);
        orderedItems.forEach(campaign => {
            allowedCampaignsIds.push(campaign.id);
            allowedCampaigns[campaign.id] = campaign;
        });
        state.loading.fetchAllowedCampaigns = false;
        state.allowedCampaignsIds = allowedCampaignsIds;
        state.allowedCampaigns = allowedCampaigns;
    },
    [FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE](state) {
        state.loading.fetchAllowedCampaigns = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES
    // -----------------------------------------
    [FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST](state) {
        state.loading.fetchProducerProductCategories = true;
    },
    [FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS](state, payload) {
        const { items } = payload;
        const producerProductCategoriesIds = [];
        const producerProductCategories = {};
        const orderedItems = orderBy(items, [item => deburr(item.name.toLowerCase())], ['asc']);
        orderedItems.forEach(campaign => {
            producerProductCategoriesIds.push(campaign.id);
            producerProductCategories[campaign.id] = campaign;
        });
        state.loading.fetchProducerProductCategories = false;
        state.producerProductCategoriesIds = producerProductCategoriesIds;
        state.producerProductCategories = producerProductCategories;
    },
    [FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE](state) {
        state.loading.fetchProducerProductCategories = false;
    },

    // -----------------------------------------
    // ACTIVATE_PERMISSION
    // -----------------------------------------
    [ACTIVATE_PERMISSION_REQUEST](state) {
        state.loading.activatePermission = true;
    },
    [ACTIVATE_PERMISSION_SUCCESS](state, payload) {
        const { spaceId, permissionData } = payload;
        const { space, ...permission } = permissionData;
        const permissions = state.spaces[spaceId].permissions || [];
        state.loading.activatePermission = false;
        state.spaces[spaceId] = {
            ...state.spaces[spaceId],
            permissions: [...permissions, permission.permission],
        };
        state.permissions = {
            ...state.permissions,
            [permission.permission]: permission,
        };
    },
    [ACTIVATE_PERMISSION_FAILURE](state) {
        state.loading.activatePermission = false;
    },

    // -----------------------------------------
    // DEACTIVATE_PERMISSION
    // -----------------------------------------
    [DEACTIVATE_PERMISSION_REQUEST](state) {
        state.loading.deactivatePermission = true;
    },
    [DEACTIVATE_PERMISSION_SUCCESS](state, payload) {
        const { permissionData } = payload;
        const { space, ...permission } = permissionData;
        state.loading.deactivatePermission = false;
        state.permissions = {
            ...state.permissions,
            [permission.permission]: permission,
        };
    },
    [DEACTIVATE_PERMISSION_FAILURE](state) {
        state.loading.deactivatePermission = false;
    },

    // -----------------------------------------
    // FETCH_PERMISSIONS
    // -----------------------------------------
    [FETCH_PERMISSIONS_REQUEST](state) {
        state.loading.fetchPermissions = true;
    },
    [FETCH_PERMISSIONS_SUCCESS](state, payload) {
        const { permissions } = payload;
        const permissionsIds = [];
        const permissionsById = {};
        permissions.forEach(permission => {
            const { space, ...permissionData } = permission;
            permissionsIds.push(permission.permission);
            permissionsById[permission.permission] = permissionData;
        });
        state.loading.fetchPermissions = false;
        state.permissionsIds = permissionsIds;
        state.permissions = permissionsById;
    },
    [FETCH_PERMISSIONS_FAILURE](state) {
        state.loading.fetchPermissions = false;
    },

    // -----------------------------------------
    // FETCH_UNIQUE_SOURCES
    // -----------------------------------------
    [FETCH_UNIQUE_SOURCES_REQUEST](state) {
        state.loading.fetchUniqueSources = true;
        state.uniqueSources = [];
    },
    [FETCH_UNIQUE_SOURCES_SUCCESS](state, payload) {
        state.loading.fetchUniqueSources = false;
        state.uniqueSources = payload;
    },
    [FETCH_UNIQUE_SOURCES_FAILURE](state) {
        state.loading.fetchUniqueSources = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
