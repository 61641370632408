<template>
    <v-dialog
        v-model="isOpen"
        max-width="1000"
    >

        <v-form v-if="isOpen && product.id" @submit.prevent="handleSubmit" novalidate>
            <v-card>

                <v-card-title class="headline">
                    {{ $t('updateProductModal.updateTitle', { name: product.name }) }}
                </v-card-title>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{ error }}
                </v-alert>

                <v-card-text>
                    <v-flex class="pr-4" xs12>
                        <v-text-field
                            v-model.trim="model.price"
                            :error-messages="priceErrors"
                            @blur="$v.model.price.$touch()"
                            name="price"
                            :label="$t('updateProductModal.price')"
                            :disabled="submitting"
                            type="number"
                            min="0"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat :disabled="submitting" @click="isOpen = false">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        type="submit"
                        color="tertiary"
                        dark
                        round
                        :loading="submitting"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-form>

        <v-alert
            v-if="isOpen && !product.id"
            :value="true"
            dismissible
            type="error"
            transition="scale-transition">
            {{ $t('failedToLoadProductData') }}
        </v-alert>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, decimal, minValue } from 'vuelidate/lib/validators';

export default {
    name: 'update-product-price-modal',
    validations: {
        model: {
            price: {
                required,
                decimal,
                minValue: minValue(0),
            },
        },
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
            required: true,
            validator: value => {
                if (!value.id || !value.name) return false;
                return true;
            },
        },
        callback: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            model: {
                price: null,
            },
            error: null,
        };
    },
    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        priceErrors() {
            const errors = [];
            if (!this.$v.model.price.$dirty) {
                return errors;
            }
            if (!this.$v.model.price.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.price.decimal) {
                errors.push(this.$t('validation.decimal'));
            }
            if (!this.$v.model.price.minValue) {
                errors.push(this.$t('validation.minValue', { min: 0 }));
            }
            return errors;
        },
        submitting() {
            return this.updateProductsLoading.includes(this.product.id);
        },
        ...mapState({
            updateProductsLoading: state => state.products.loading.updateProducts,
        }),
    },
    watch: {
        isOpen: {
            immediate: true,
            handler() {
                if (this.isOpen) {
                    this.resetData();
                }
            },
        },
    },
    methods: {
        resetData() {
            this.error = null;
            this.resetForm();
        },
        resetForm() {
            this.model = {
                price: this.product.price ?? null,
            };
            this.$v.$reset();
        },
        async handleSubmit() {
            try {
                this.error = null;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                const { price } = this.model;
                let numericPrice = Number.parseFloat(price);
                if (Number.isNaN(numericPrice)) {
                    numericPrice = null;
                }
                const saveData = {
                    entityId: this.product.id,
                    data: {
                        name: this.product.name,
                        price: numericPrice,
                    },
                };
                const response = await this.updateProduct(saveData);
                this.$toast.success(this.$t('updateProductModal.updateSuccess', { name: this.product.name }));
                if (this.callback) this.callback(response);
                this.isOpen = false;
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            updateProduct: 'products/updateProduct',
        }),
    },
};
</script>
