<template>
    <v-card class="elevation-0 mb-4">
        <v-card-title>
            <div>
                <h3 class="title">{{ $t('producerSpaces.subtitle') }}</h3>
                <span class="font-weight-medium grey--text text--darken-1">{{ $t('producerSpaces.total') }}: {{ spaceCount }}</span>
            </div>
            <v-spacer />
            <v-btn
                color="tertiary"
                @click="handleCreate()"
                dark
                round
            >
                <v-icon class="mr-2" small>fa-plus</v-icon>
                {{ $t('producerSpaces.create') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>

            <v-data-table
                v-if="!loading"
                class="elevation-0"
                :headers="headers"
                :items="items"
                :no-data-text="$t('producerSpaces.noResults')"
                :no-results-text="$t('producerSpaces.noResults')"
                :rows-per-page-text="$t('table.rowsPerPage')"
                :rows-per-page-items="rowsPerPageItems"
                :pagination.sync="pagination"
                disable-initial-sort
                @update:pagination="updatePagination"
                sort-icon="fa-angle-up"
            >
                <template v-slot:pageText="props">
                    {{ $t('table.pageText', { pageStart: props.pageStart, pageStop: props.pageStop, itemsLength: props.itemsLength }) }}
                </template>

                <template v-slot:items="props">
                    <td style="width: 27%;" v-if="!editModel || (editModel && editModel.id !== props.item.id)">
                        {{ props.item.name }}
                    </td>
                    <td style="width: 27%;" v-if="editModel && editModel.id === props.item.id">
                        <v-text-field
                            ref="name"
                            v-model.trim="editModel.name"
                            :error-messages="nameErrors"
                            @blur="$v.editModel.name.$touch()"
                            @keyup.native.enter="handleEditConfirm"
                            name="name"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                        />
                    </td>

                    <td style="width: 27%;" v-if="!editModel || (editModel && editModel.id !== props.item.id)">
                        <a :href="props.item.url" target="_blank">{{ props.item.url }}</a>
                        <v-icon class="ml-1 external-link-icon">fa-external-link-alt</v-icon>
                    </td>
                    <td style="width: 27%;" v-if="editModel && editModel.id === props.item.id">
                        <v-text-field
                            v-model.trim="editModel.url"
                            :error-messages="urlErrors"
                            @blur="$v.editModel.url.$touch()"
                            @keyup.native.enter="handleEditConfirm"
                            name="url"
                            type="url"
                            required
                            maxlength="255"
                            placeholder=" "
                        />
                    </td>

                    <td style="width: 27%;" v-if="!editModel || (editModel && editModel.id !== props.item.id)">
                        {{ props.item.categoryName }}
                    </td>
                    <td style="width: 27%;" v-if="editModel && editModel.id === props.item.id">
                        <v-select
                            :items="categoryOptions"
                            :itemsTree="categoryOptionsTree"
                            v-model="editModel.category"
                            :error-messages="categoryErrors"
                            @blur="$v.editModel.category.$touch()"
                            name="category"
                            required
                            placeholder=" "
                            dense
                        />
                    </td>
                    <td>
                        <v-layout justify-end>
                            <v-btn
                                v-if="!editModel || (editModel && editModel.id !== props.item.id)"
                                :disabled="submitting || demoSpaceId === props.item.id"
                                @click="handleUpdate(props.item)"
                                flat
                                icon
                            >
                                <v-icon small>fa-pen</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="editModel && editModel.id === props.item.id && !submitting"
                                :disabled="demoSpaceId === props.item.id"
                                @click="handleEditConfirm()"
                                flat
                                icon
                            >
                                <v-icon small>fa-check</v-icon>
                            </v-btn>
                            <v-progress-circular
                                v-if="editModel && editModel.id === props.item.id && submitting"
                                class="mt-2"
                                indeterminate
                                color="primary"
                            />
                            <v-btn
                                v-if="editModel && editModel.id === props.item.id"
                                :disabled="submitting || demoSpaceId === props.item.id"
                                @click="handleEditCancel()"
                                flat
                                icon
                            >
                                <v-icon small>fa-times</v-icon>
                            </v-btn>
                        </v-layout>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { required, url } from 'vuelidate/lib/validators';
import categoryFilterMixin from '@/mixins/categoryFilterMixin';
import spaceService from '../../services/spaceService';

export default {
    name: 'producer-spaces',
    mixins: [
        categoryFilterMixin,
    ],
    validations: {
        editModel: {
            name: { required },
            url: { required, url },
            category: { required },
        },
    },
    data() {
        return {
            demoSpaceId: spaceService.demoSpace.id,
            headers: [
                {
                    text: this.$t('producerSpaces.table.name'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('producerSpaces.table.url'),
                    align: 'left',
                    sortable: true,
                    value: 'url',
                },
                {
                    text: this.$t('producerSpaces.table.category'),
                    align: 'left',
                    sortable: true,
                    value: 'categoryName',
                },
                {
                    text: '',
                    align: 'left',
                    sortable: false,
                    value: 'id',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: this.$t('table.rowsPerPageAll'), value: -1 },
            ],
            pagination: {
                rowsPerPage: 10,
            },
            error: null,
            editType: null,
            editModel: null,
            paginationPrev: null,
        };
    },
    computed: {
        loading() {
            return this.categoriesLoading || this.spacesLoading;
        },
        submitting() {
            return this.createSubmitting || this.updateSubmitting;
        },
        items() {
            const items = this.spacesIds.map(id => {
                const space = this.spaces[id];
                const categoryId = space.categories && space.categories.length > 0 ? space.categories[0].id : null;
                let categoryName = '';

                if (categoryId) {
                    const category = this.getCategory(categoryId);
                    if (category) {
                        categoryName = category.name;
                    }
                }

                return {
                    ...this.spaces[id],
                    categoryId,
                    categoryName,
                };
            });

            if (this.editType === 'create') {
                items.unshift(this.editModel);
            }

            return items;
        },
        categoryOptions() {
            return this.categoriesIds.map(id => ({
                value: this.categories[id].id,
                text: this.categories[id].name,
            }));
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.editModel.name.$dirty) {
                return errors;
            }
            if (!this.$v.editModel.name.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        urlErrors() {
            const errors = [];
            if (!this.$v.editModel.url.$dirty) {
                return errors;
            }
            if (!this.$v.editModel.url.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.editModel.url.url) {
                errors.push(this.$t('validation.url'));
            }
            return errors;
        },
        categoryErrors() {
            const errors = [];
            if (!this.$v.editModel.category.$dirty) {
                return errors;
            }
            if (!this.$v.editModel.category.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        ...mapState({
            categoriesLoading: state => state.dictionary.loading.categories,
            spacesLoading: state => state.space.loading.fetchSpaces,
            categoriesIds: state => state.dictionary.categoriesIds,
            categories: state => state.dictionary.categories,
            spacesIds: state => state.space.spacesIds,
            spaces: state => state.space.spaces,
            createSubmitting: state => state.space.loading.createSpace,
            updateSubmitting: state => state.space.loading.updateSpace,
        }),
        ...mapGetters({
            getCategory: 'dictionary/getCategory',
            spaceCount: 'space/spaceCount',
        }),
    },
    created() {
        this.getCategories();
        this.getSpaces();

        document.addEventListener('keyup', this.keyListener);
    },
    destroyed() {
        document.removeEventListener('keyup', this.keyListener);
    },
    methods: {
        keyListener(e) {
            if ((e.key === 'Escape' || e.key === 'Esc') && (this.editType || this.editModel)) {
                this.handleEditCancel();
            }
        },
        async getCategories() {
            try {
                await this.fetchCategories();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getSpaces() {
            try {
                await this.fetchSpaces();
            } catch (e) {
                this.error = e.message;
            }
        },
        updatePagination(event) {
            this.editType = null;
            this.editModel = null;
            this.paginationPrev = {
                descending: event.descending,
                page: event.page,
                rowsPerPage: event.rowsPerPage,
                sortBy: event.sortBy,
            };
        },
        handleCreate() {
            this.$v.$reset();
            this.pagination.page = 1;
            this.editType = 'create';
            this.editModel = {
                name: null,
                url: null,
                category: null,
            };
            this.$nextTick(() => {
                this.$refs.name.focus();
            });
        },
        handleUpdate(item) {
            this.$v.$reset();
            this.editType = 'update';
            this.editModel = {
                id: item.id,
                name: item.name,
                url: item.url,
                category: item.categoryId,
            };
            this.$nextTick(() => {
                this.$refs.name.focus();
            });
        },
        handleEditCancel() {
            this.editType = null;
            this.editModel = null;
        },
        async handleEditConfirm() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                if (this.editType === 'create') {
                    await this.createSpace(this.editModel);
                } else if (this.editType === 'update') {
                    await this.updateSpace(this.editModel);
                }
                this.editType = null;
                this.editModel = null;
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchCategories: 'dictionary/fetchCategories',
            fetchSpaces: 'space/fetchSpaces',
            createSpace: 'space/createSpace',
            updateSpace: 'space/updateSpace',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
