import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css';
import Vuelidate from 'vuelidate';
import VuetifyToast from 'vuetify-toast-snackbar';
import VueClipboard from 'vue-clipboard2';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import { i18n } from './i18n/i18n';
import feature from './feature';
import 'fix-date';

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    release: process.env.GIT_COMMIT_HASH_SHORT_7,
});

Vue.use(VueClipboard);
Vue.use(Vuetify, {
    iconfont: 'fa',
    options: {
        customProperties: true,
    },
    theme: {
        primary: '#00e08f',
        secondary: '#ff0059',
        tertiary: '#2d3136',
        accent: '#66BB6A',
    },
});
Vue.use(Vuelidate);
Vue.use(VuetifyToast, {
    x: 'center',
    y: 'top',
    color: 'info',
    icon: null,
    timeout: 3000,
    dismissable: true,
    autoHeight: false,
    multiLine: false,
    vertical: false,
    property: '$toast',
});
Vue.config.productionTip = false;

Vue.prototype.$feature = feature;

const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

if (window.Cypress) {
    window.app = app;
}

export default app;
