import 'fix-date';
import {
    format,
    startOfYesterday,
    addDays,
    subDays,
    startOfWeek,
    endOfWeek,
    subWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,
    subMonths,
    getQuarter,
    startOfQuarter,
    endOfQuarter,
    addQuarters,
    subQuarters,
    endOfYear,
    startOfYear,
    addYears,
    subYears,
    differenceInDays,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    differenceInCalendarQuarters,
    differenceInCalendarYears,
} from 'date-fns';
import { i18n } from '../i18n/i18n';

export default {
    supportedRanges: [
        '7days',
        '14days',
        '30days',
        'today',
        'yesterday',
        'currentWeek',
        'lastWeek',
        'currentMonth',
        'lastMonth',
        'currentQuarter',
        'lastQuarter',
        'currentYear',
        'lastYear',
    ],
    supportedCompareRanges: [
        'prevRange',
        'lastYear',
    ],
    formatDate(date, dateFormat = 'YYYY-MM-DD') {
        return format(date, dateFormat);
    },
    getToday() {
        return this.formatDate(new Date());
    },
    getYesterday() {
        return this.formatDate(startOfYesterday());
    },
    subDays(days) {
        return this.formatDate(subDays(new Date(), days));
    },
    startOfCurrentWeek() {
        return this.formatDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
    },
    endOfCurrentWeek() {
        return this.formatDate(endOfWeek(new Date(), { weekStartsOn: 1 }));
    },
    startOfLastWeek() {
        return this.formatDate(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));
    },
    endOfLastWeek() {
        return this.formatDate(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));
    },
    previoustMonth() {
        return this.formatDate(subMonths(startOfMonth(new Date()), 1, 'YYYY-MM'));
    },
    currentMonth() {
        return this.formatDate(startOfMonth(new Date()), 'YYYY-MM');
    },
    startOfCurrentMonth() {
        return this.formatDate(startOfMonth(new Date()));
    },
    endOfCurrentMonth() {
        return this.formatDate(endOfMonth(new Date()));
    },
    startOfLastMonth() {
        return this.formatDate(startOfMonth(subMonths(new Date(), 1)));
    },
    endOfLastMonth() {
        return this.formatDate(endOfMonth(subMonths(new Date(), 1)));
    },
    startOfCurrentQuarter() {
        return this.formatDate(startOfQuarter(new Date()));
    },
    endOfCurrentQuarter() {
        return this.formatDate(endOfQuarter(new Date()));
    },
    startOfLastQuarter() {
        return this.formatDate(startOfQuarter(subQuarters(new Date(), 1)));
    },
    endOfLastQuarter() {
        return this.formatDate(endOfQuarter(subQuarters(new Date(), 1)));
    },
    startOfCurrentYear() {
        return this.formatDate(startOfYear(new Date()));
    },
    endOfCurrentYear() {
        return this.formatDate(endOfYear(new Date()));
    },
    startOfLastYear() {
        return this.formatDate(startOfYear(subYears(new Date(), 1)));
    },
    endOfLastYear() {
        return this.formatDate(endOfYear(subYears(new Date(), 1)));
    },
    getDatesByRange(range, compareRange) {
        let startDate = null;
        let endDate = null;
        let compareStartDate = null;
        let compareEndDate = null;

        switch (range) {
            case '7days':
                startDate = this.subDays(7);
                endDate = this.getYesterday();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.subDays(7), 1));
                    compareEndDate = this.formatDate(subYears(this.getYesterday(), 1));
                } else {
                    compareStartDate = this.subDays(14);
                    compareEndDate = this.subDays(8);
                }
                break;
            case '14days':
                startDate = this.subDays(14);
                endDate = this.getYesterday();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.subDays(14), 1));
                    compareEndDate = this.formatDate(subYears(this.getYesterday(), 1));
                } else {
                    compareStartDate = this.subDays(28);
                    compareEndDate = this.subDays(15);
                }
                break;
            case '30days':
                startDate = this.subDays(30);
                endDate = this.getYesterday();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.subDays(30), 1));
                    compareEndDate = this.formatDate(subYears(this.getYesterday(), 1));
                } else {
                    compareStartDate = this.subDays(60);
                    compareEndDate = this.subDays(31);
                }
                break;
            case 'today':
                startDate = this.getToday();
                endDate = this.getToday();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.getToday(), 1));
                    compareEndDate = this.formatDate(subYears(this.getToday(), 1));
                } else {
                    compareStartDate = this.getYesterday();
                    compareEndDate = this.getYesterday();
                }
                break;
            case 'yesterday':
                startDate = this.getYesterday();
                endDate = this.getYesterday();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.getYesterday(), 1));
                    compareEndDate = this.formatDate(subYears(this.getYesterday(), 1));
                } else {
                    compareStartDate = this.subDays(2);
                    compareEndDate = this.subDays(2);
                }
                break;
            case 'currentWeek':
                startDate = this.startOfCurrentWeek();
                endDate = this.endOfCurrentWeek();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfCurrentWeek(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfCurrentWeek(), 1));
                } else {
                    compareStartDate = this.startOfLastWeek();
                    compareEndDate = this.endOfLastWeek();
                }
                break;
            case 'lastWeek':
                startDate = this.startOfLastWeek();
                endDate = this.endOfLastWeek();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfLastWeek(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfLastWeek(), 1));
                } else {
                    compareStartDate = this.formatDate(startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 1 }));
                    compareEndDate = this.formatDate(endOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 1 }));
                }
                break;
            case 'currentMonth':
                startDate = this.startOfCurrentMonth();
                endDate = this.endOfCurrentMonth();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfCurrentMonth(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfCurrentMonth(), 1));
                } else {
                    compareStartDate = this.startOfLastMonth();
                    compareEndDate = this.endOfLastMonth();
                }
                break;
            case 'lastMonth':
                startDate = this.startOfLastMonth();
                endDate = this.endOfLastMonth();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfLastMonth(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfLastMonth(), 1));
                } else {
                    compareStartDate = this.formatDate(startOfMonth(subMonths(new Date(), 2)));
                    compareEndDate = this.formatDate(endOfMonth(subMonths(new Date(), 2)));
                }
                break;
            case 'currentQuarter':
                startDate = this.startOfCurrentQuarter();
                endDate = this.endOfCurrentQuarter();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfCurrentQuarter(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfCurrentQuarter(), 1));
                } else {
                    compareStartDate = this.startOfLastQuarter();
                    compareEndDate = this.endOfLastQuarter();
                }
                break;
            case 'lastQuarter':
                startDate = this.startOfLastQuarter();
                endDate = this.endOfLastQuarter();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfLastQuarter(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfLastQuarter(), 1));
                } else {
                    compareStartDate = this.formatDate(startOfQuarter(subQuarters(new Date(), 2)));
                    compareEndDate = this.formatDate(endOfQuarter(subQuarters(new Date(), 2)));
                }
                break;
            case 'currentYear':
                startDate = this.startOfCurrentYear();
                endDate = this.endOfCurrentYear();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfCurrentYear(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfCurrentYear(), 1));
                } else {
                    compareStartDate = this.startOfLastYear();
                    compareEndDate = this.endOfLastYear();
                }
                break;
            case 'lastYear':
                startDate = this.startOfLastYear();
                endDate = this.endOfLastYear();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.startOfLastYear(), 1));
                    compareEndDate = this.formatDate(subYears(this.endOfLastYear(), 1));
                } else {
                    compareStartDate = this.formatDate(startOfYear(subYears(new Date(), 2)));
                    compareEndDate = this.formatDate(endOfYear(subYears(new Date(), 2)));
                }
                break;
            default:
                startDate = this.getToday();
                endDate = this.getToday();

                if (compareRange === 'lastYear') {
                    compareStartDate = this.formatDate(subYears(this.getToday(), 1));
                    compareEndDate = this.formatDate(subYears(this.getToday(), 1));
                } else {
                    compareStartDate = this.getYesterday();
                    compareEndDate = this.getYesterday();
                }
                break;
        }

        return {
            startDate, endDate, compareStartDate, compareEndDate,
        };
    },
    /**
     * Converts seconds into a formatted string value
     * @param value
     * @return {string}
     */
    secondsToString(value) {
        let seconds = value;
        const days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        const parts = [];
        if (days > 0) {
            parts.push(`${days}${i18n.t('timeShort.days')}`);
        }
        if (hours > 0) {
            parts.push(`${hours}${i18n.t('timeShort.hours')}`);
        }
        if (minutes > 0) {
            parts.push(`${minutes}${i18n.t('timeShort.minutes')}`);
        }
        if (seconds > 0) {
            parts.push(`${seconds}${i18n.t('timeShort.seconds')}`);
        }

        return parts.length > 0 ? parts.join(' ') : `0${i18n.t('timeShort.seconds')}`;
    },
    /**
     * Format date from timestamp according to current locale settings
     * @param {number} date - Date timestamp
     * @param {('day'|'month'|'monthName'|'quarter'|'year')} formatName - Format name, e.g. day, month
     * @return {string}
     */
    formatI18nDate(date, formatName = 'day') {
        switch (formatName) {
            case 'month':
                return i18n.d(new Date(date), 'month');
            case 'monthName':
                return i18n.d(new Date(date), 'monthName');
            case 'quarter': {
                const quarterYear = i18n.d(new Date(date), 'year');
                const quarterNumber = getQuarter(new Date(date));
                return `${i18n.t('timeShort.quarter')}${quarterNumber} ${quarterYear}`;
            }
            case 'year':
                return i18n.d(new Date(date), 'year');
            default:
                return i18n.d(new Date(date), 'short');
        }
    },
    /**
     * Get date format name from range type name
     * @param {('daily'|'monthly'|'quarterly'|'annually')} periodName - Type of period interval to convert, e.g. daily, monthly
     * @return {('day'|'month'|'quarter'|'year')}
     */
    getFormatFromPeriod(periodName) {
        switch (periodName) {
            case 'monthly':
                return 'month';
            case 'quarterly':
                return 'quarter';
            case 'annually':
                return 'year';
            default:
                return 'day';
        }
    },
    getDatesByCompareRange(startDate, endDate, compareRange) {
        let compareStartDate = null;
        let compareEndDate = null;

        if (compareRange === 'lastYear') {
            compareStartDate = this.formatDate(subYears(startDate, 1));
            compareEndDate = this.formatDate(subYears(endDate, 1));
        } else {
            const difference = differenceInDays(endDate, startDate);
            compareStartDate = this.formatDate(subDays(startDate, difference + 1));
            compareEndDate = this.formatDate(subDays(startDate, 1));
        }

        return {
            startDate, endDate, compareStartDate, compareEndDate,
        };
    },
    /**
     * Generate list of days in provided range
     * @param {string} startDate - Range start date in format YYYY-MM-DD
     * @param {string} endDate - Range end date in format YYYY-MM-DD
     * @return {string[]} - Returns array of dates in format YYYY-MM-DD
     */
    getDaysInRange(startDate, endDate) {
        const daysCountInRange = differenceInDays(endDate, startDate);
        const daysInRange = [];
        const today = this.getToday();
        [...Array(daysCountInRange + 1).keys()].forEach(i => {
            const day = this.formatDate(addDays(startDate, i));
            if (day <= today) {
                daysInRange.push(day);
            }
        });
        return daysInRange;
    },
    /**
     * Generate list of months in provided range
     * @param {string} startDate - Range start date in format YYYY-MM-DD
     * @param {string} endDate - Range end date in format YYYY-MM-DD
     * @return {string[]} - Returns array of dates in format YYYY-MM-DD. Each date will represent first day of month.
     */
    getMonthsInRange(startDate, endDate) {
        const monthsCountInRange = differenceInCalendarMonths(endDate, startDate);
        const monthsInRange = [];
        const currentMonth = this.startOfCurrentMonth();
        [...Array(monthsCountInRange + 1).keys()].forEach(i => {
            const month = this.formatDate(addMonths(startOfMonth(startDate), i));
            if (month <= currentMonth) {
                monthsInRange.push(month);
            }
        });
        return monthsInRange;
    },
    /**
     * Generate list of quarters in provided range
     * @param {string} startDate - Range start date in format YYYY-MM-DD
     * @param {string} endDate - Range end date in format YYYY-MM-DD
     * @return {string[]} - Returns array of dates in format YYYY-MM-DD. Each date will represent first day of quarter.
     */
    getQuartersInRange(startDate, endDate) {
        const quartersCountInRange = differenceInCalendarQuarters(endDate, startDate);
        const quartersInRange = [];
        const currentQuarter = this.startOfCurrentQuarter();
        [...Array(quartersCountInRange + 1).keys()].forEach(i => {
            const quarter = this.formatDate(addQuarters(startOfQuarter(startDate), i));
            if (quarter <= currentQuarter) {
                quartersInRange.push(quarter);
            }
        });
        return quartersInRange;
    },
    /**
     * Generate list of years in provided range
     * @param {string} startDate - Range start date in format YYYY-MM-DD
     * @param {string} endDate - Range end date in format YYYY-MM-DD
     * @return {string[]} - Returns array of dates in format YYYY-MM-DD. Each date will represent first day of year.
     */
    getYearsInRange(startDate, endDate) {
        const yearsCountInRange = differenceInCalendarYears(endDate, startDate);
        const yearsInRange = [];
        const currentYear = this.startOfCurrentQuarter();
        [...Array(yearsCountInRange + 1).keys()].forEach(i => {
            const year = this.formatDate(addYears(startOfYear(startDate), i));
            if (year <= currentYear) {
                yearsInRange.push(year);
            }
        });
        return yearsInRange;
    },
    /**
     * Generate list of missing dates from data in a provided range
     * @param {object[]} data - Array of objects with date as timestamps
     * @param {string} startDate - Range start date in format YYYY-MM-DD
     * @param {string} endDate - Range end date in format YYYY-MM-DD
     * @param {string[]} [datesInRange] - List of all dates in range. Should be used in place of startDate and endDate if we execute this method for the same range multiple times.
     * @param {('day'|'month'|'quarter'|'year')} [rangeType] - Type of range interval to generate, e.g. daily, monthly
     * @param {string} [datePropertyName=date] - Name of object property with date field
     * @param {boolean} [returnAsString=false] - Dates will be returned as timestamp by default. We can change it to return as string YYYY-MM-DD
     * @return {string[]|number[]}
     */
    findMissingDates(data = [], startDate, endDate, datesInRange, rangeType = 'day', datePropertyName = 'date', returnAsString = false) {
        let allDatesInRange = [];

        // generate list of dates in given range
        // skip generation if datesInRange is provided
        if (datesInRange) {
            allDatesInRange = datesInRange;
        } else if (rangeType === 'day') {
            allDatesInRange = this.getDaysInRange(startDate, endDate);
        } else if (rangeType === 'month') {
            allDatesInRange = this.getMonthsInRange(startDate, endDate);
        } else if (rangeType === 'quarter') {
            allDatesInRange = this.getQuartersInRange(startDate, endDate);
        } else if (rangeType === 'year') {
            allDatesInRange = this.getYearsInRange(startDate, endDate);
        }

        let sourceDataFormatted;

        // convert source dates (aside from day type) to first day of month/quarter/year
        // such approach will increase performance, since it won't have to compare for each pair if two dates are in the same month/quarter/year
        if (rangeType === 'day') {
            sourceDataFormatted = data.map(item => this.formatDate(item[datePropertyName]));
        } else if (rangeType === 'month') {
            sourceDataFormatted = data.map(item => this.formatDate(startOfMonth(item[datePropertyName])));
        } else if (rangeType === 'quarter') {
            sourceDataFormatted = data.map(item => this.formatDate(startOfQuarter(item[datePropertyName])));
        } else if (rangeType === 'year') {
            sourceDataFormatted = data.map(item => this.formatDate(startOfYear(item[datePropertyName])));
        }

        // filter out dates that already exists in data and leave only missing dates
        const missingDates = allDatesInRange.filter(item => !sourceDataFormatted.includes(item));

        if (!returnAsString) {
            // return dates as timestamps
            return missingDates.map(date => new Date(date).valueOf());
        }

        // return dates as formatted string YYYY-MM-DD
        return missingDates;
    },
    mergeDates(dates) {
        if (!dates?.length) {
            return [];
        }
        const merged = [{ start: dates[0], end: dates[0] }];
        for (let i = 1; i < dates.length; i += 1) {
            const prev = merged[merged.length - 1];
            if (differenceInCalendarDays(dates[i], prev.end) === 1) {
                // if the current segment is next day, update the previous segment accordingly
                prev.end = dates[i];
            } else {
                // if the current is not next day, put the segment in the result
                merged.push({ start: dates[i], end: dates[i] });
            }
        }
        return merged;
    },
    getRangeType(startDate, endDate) {
        const diffInDays = differenceInCalendarDays(endDate, startDate);
        let rangeType = '';
        if (diffInDays <= 30) {
            rangeType = 'day';
        } else if (diffInDays <= 366) {
            rangeType = 'month';
        } else {
            rangeType = 'quarter';
        }
        return rangeType;
    },
};
