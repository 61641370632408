<template>
    <div>
        <div v-if="!isReady" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <GChart
            type="LineChart"
            :settings="{ packages: ['corechart'], language: $i18n.locale }"
            :data="data"
            :options="chartOptions"
            :events="chartEvents"
            @ready="onChartReady"
        />
    </div>
</template>

<script>
import { merge } from 'lodash';
import { GChart } from 'vue-google-charts';
import chartActionResetMixin from '@/mixins/chartActionResetMixin';
import chartActionSelectDateMixin from '@/mixins/chartActionSelectDateMixin';
import chartTooltipPositionMixin from '@/mixins/chartTooltipPositionMixin';
import exportService from '@/services/exportService';

export default {
    name: 'line-chart-graph',
    mixins: [
        chartActionResetMixin,
        chartActionSelectDateMixin,
        chartTooltipPositionMixin,
    ],
    components: {
        GChart,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            baseOptions: {
                legend: {
                    position: 'top',
                    maxLines: 3,
                    pagingTextStyle: {
                        color: this.$vuetify.theme.tertiary,
                    },
                    scrollArrows: {
                        activeColor: this.$vuetify.theme.tertiary,
                        inactiveColor: `${this.$vuetify.theme.tertiary}4c`,
                    },
                },
                hAxis: {
                    slantedText: true,
                },
                vAxes: {
                    0: {
                        title: '',
                        format: 'short',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
                chartArea: {
                    top: 35,
                    left: 75,
                    right: 50,
                    width: '90%',
                    height: '65%',
                },
                backgroundColor: '#f5f5f5',
                selectionMode: 'multiple',
                tooltip: {
                    trigger: 'selection',
                    isHtml: true,
                },
            },
            chartEvents: {
                ready: () => {
                    this.isReady = true;
                    this.addChartActionReset();
                    this.addChartActionSelectDate();
                    this.addChartTooltipPosition();
                },
                select: () => {
                    this.addChartActionSelectDate();
                },
            },
            isReady: false,
            chart: null,
            google: null,
        };
    },
    computed: {
        chartOptions() {
            const baseHeight = this.options.height ? this.options.height : 300;
            const height = this.expanded ? window.outerHeight - 60 : baseHeight;
            return merge(this.baseOptions, this.options, { height });
        },
    },
    methods: {
        onChartReady(chart, google) {
            this.chart = chart;
            this.google = google;
        },
        exportData(title = 'export') {
            exportService.exportDataImage(this.chart.getImageURI(), title);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
