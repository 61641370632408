import {
    deburr, sortBy,
} from 'lodash';
import reportsAvailabilityService from '../../services/reportsAvailabilityService';
import utilsService from '../../services/utilsService';
import {
    CLEAN_REPORTS_AVAILABILITY,
    FETCH_AVAILABILITY_CHARTS_FAILURE,
    FETCH_AVAILABILITY_CHARTS_REQUEST,
    FETCH_AVAILABILITY_CHARTS_SUCCESS,
    FETCH_CURRENT_PRODUCTS_AVAILABILITY_FAILURE,
    FETCH_CURRENT_PRODUCTS_AVAILABILITY_REQUEST,
    FETCH_CURRENT_PRODUCTS_AVAILABILITY_SUCCESS,
    FETCH_CURRENT_UNAVAILABLE_PRODUCTS_FAILURE,
    FETCH_CURRENT_UNAVAILABLE_PRODUCTS_REQUEST,
    FETCH_CURRENT_UNAVAILABLE_PRODUCTS_SUCCESS,
    FETCH_REPORTS_AVAILABILITY_PRODUCT_FAILURE,
    FETCH_REPORTS_AVAILABILITY_PRODUCT_REQUEST,
    FETCH_REPORTS_AVAILABILITY_PRODUCT_SUCCESS,
    GENERATE_AVAILABILITY_EXPORT_REPORT_REQUEST,
    GENERATE_AVAILABILITY_EXPORT_REPORT_SUCCESS,
    GENERATE_AVAILABILITY_EXPORT_REPORT_FAILURE,
    FETCH_AVAILABILITY_EXPORT_REPORTS_REQUEST,
    FETCH_AVAILABILITY_EXPORT_REPORTS_SUCCESS,
    FETCH_AVAILABILITY_EXPORT_REPORTS_FAILURE,
} from '../mutationTypes';

const initialState = {
    productAvailability: {},
    availabilityCharts: {},
    currentUnavailableProducts: {},
    currentProductsAvailability: {},
    availabilityExportReports: [],
    loading: {
        productAvailability: false,
        availabilityCharts: false,
        currentUnavailableProducts: false,
        currentProductsAvailability: false,
    },
};

const getters = {
    getProductAvailability: state => (productId, startDate, endDate, campaign) => {
        const storageKey = utilsService.getStorageKey(productId, startDate, endDate, ...campaign);
        return state.productAvailability[storageKey];
    },
    getAvailabilityCharts: state => (startDate, endDate, campaign) => {
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
        return state.availabilityCharts[storageKey];
    },
    getCurrentUnavailableProducts: state => campaign => {
        const storageKey = utilsService.getStorageKey(...campaign);
        return state.currentUnavailableProducts[storageKey];
    },
    getCurrentProductsAvailability: state => campaign => {
        const storageKey = utilsService.getStorageKey(...campaign);
        return state.currentProductsAvailability[storageKey];
    },
    getAvailabilityExport: state => state.availabilityExportReports,
    getPendingAvailabilityExport: state => state.availabilityExportReports.filter(report => report.status.toUpperCase() === 'PENDING' || report.status.toUpperCase() === 'GENERATING'),
};

const actions = {
    clean({ commit }) {
        commit(CLEAN_REPORTS_AVAILABILITY);
    },

    async generateAvailabilityExport({ commit, rootState }, payload = {}) {
        try {
            commit(GENERATE_AVAILABILITY_EXPORT_REPORT_REQUEST);
            const {
                startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, group, products, entities,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;
            const item = await reportsAvailabilityService.generateAvailabilityReport(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, categories, group, products, entities);
            commit(GENERATE_AVAILABILITY_EXPORT_REPORT_SUCCESS, item);
        } catch (e) {
            commit(GENERATE_AVAILABILITY_EXPORT_REPORT_FAILURE);
            throw e;
        }
    },
    async fetchAvailabilityExport({ commit, rootState }, payload = {}) {
        try {
            commit(FETCH_AVAILABILITY_EXPORT_REPORTS_REQUEST);
            const spaceId = rootState.space.currentSpaceId;
            const { items } = await reportsAvailabilityService.fetchAvailabilityReports(spaceId, payload);
            commit(FETCH_AVAILABILITY_EXPORT_REPORTS_SUCCESS, items);
        } catch (e) {
            commit(FETCH_AVAILABILITY_EXPORT_REPORTS_FAILURE);
            throw e;
        }
    },

    async fetchProductAvailability({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, startDate, endDate, campaign,
            } = payload;

            if (!productId || !entityId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(productId, startDate, endDate, ...campaign);
            if (!force && state.productAvailability && state.productAvailability[storageKey]) {
                return;
            }

            commit(FETCH_REPORTS_AVAILABILITY_PRODUCT_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            let productAvailabilityData = await reportsAvailabilityService.fetchProductAvailability(entityId, startDate, endDate, reqCampaign);
            productAvailabilityData = sortBy(productAvailabilityData, item => deburr(item.label.toLowerCase()));

            commit(FETCH_REPORTS_AVAILABILITY_PRODUCT_SUCCESS, {
                productAvailabilityData,
                productId,
                startDate,
                endDate,
                campaign,
            });
        } catch (e) {
            commit(FETCH_REPORTS_AVAILABILITY_PRODUCT_FAILURE);
            throw e;
        }
    },

    async fetchAvailabilityCharts({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, startDate, endDate, campaign,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
            if (!force && state.availabilityCharts && state.availabilityCharts[storageKey]) {
                return;
            }

            commit(FETCH_AVAILABILITY_CHARTS_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const items = await reportsAvailabilityService.fetchAvailabilityCharts(spaceId, startDate, endDate, reqCampaign);

            commit(FETCH_AVAILABILITY_CHARTS_SUCCESS, {
                items,
                startDate,
                endDate,
                campaign,
            });
        } catch (e) {
            commit(FETCH_AVAILABILITY_CHARTS_FAILURE);
            throw e;
        }
    },

    async fetchCurrentUnavailableProducts({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, campaign,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(...campaign);
            if (!force && state.currentUnavailableProducts && state.currentUnavailableProducts[storageKey]) {
                return;
            }

            commit(FETCH_CURRENT_UNAVAILABLE_PRODUCTS_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const { items } = await reportsAvailabilityService.fetchCurrentUnavailableProducts(spaceId, reqCampaign);

            commit(FETCH_CURRENT_UNAVAILABLE_PRODUCTS_SUCCESS, {
                items,
                campaign,
            });
        } catch (e) {
            commit(FETCH_CURRENT_UNAVAILABLE_PRODUCTS_FAILURE);
            throw e;
        }
    },

    async fetchCurrentProductsAvailability({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, campaign,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId) {
                return;
            }

            campaign.sort();
            const storageKey = utilsService.getStorageKey(...campaign);
            if (!force && state.currentProductsAvailability && state.currentProductsAvailability[storageKey]) {
                return;
            }

            commit(FETCH_CURRENT_PRODUCTS_AVAILABILITY_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            const { items } = await reportsAvailabilityService.fetchCurrentProductsAvailability(spaceId, reqCampaign);

            commit(FETCH_CURRENT_PRODUCTS_AVAILABILITY_SUCCESS, {
                items,
                campaign,
            });
        } catch (e) {
            commit(FETCH_CURRENT_PRODUCTS_AVAILABILITY_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_REPORTS_AVAILABILITY
    // -----------------------------------------
    [CLEAN_REPORTS_AVAILABILITY](state) {
        state.productAvailability = {};
        state.availabilityCharts = {};
        state.currentUnavailableProducts = {};
        state.currentProductsAvailability = {};
        state.loading.productAvailability = false;
        state.loading.availabilityCharts = false;
        state.loading.currentUnavailableProducts = false;
        state.loading.currentProductsAvailability = false;
    },

    // -----------------------------------------
    // FETCH_REPORTS_AVAILABILITY_PRODUCT
    // -----------------------------------------
    [FETCH_REPORTS_AVAILABILITY_PRODUCT_REQUEST](state) {
        state.loading.productAvailability = true;
    },
    [FETCH_REPORTS_AVAILABILITY_PRODUCT_SUCCESS](state, payload) {
        const {
            productAvailabilityData, productId, startDate, endDate, campaign,
        } = payload;
        const storageKey = utilsService.getStorageKey(productId, startDate, endDate, ...campaign);
        state.productAvailability = {
            ...state.productAvailability,
            [storageKey]: productAvailabilityData,
        };
        state.loading.productAvailability = false;
    },
    [FETCH_REPORTS_AVAILABILITY_PRODUCT_FAILURE](state) {
        state.loading.productAvailability = false;
    },

    // -----------------------------------------
    // FETCH_AVAILABILITY_CHARTS
    // -----------------------------------------
    [FETCH_AVAILABILITY_CHARTS_REQUEST](state) {
        state.loading.availabilityCharts = true;
    },
    [FETCH_AVAILABILITY_CHARTS_SUCCESS](state, payload) {
        const {
            items,
            startDate,
            endDate,
            campaign,
        } = payload;
        const storageKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
        state.availabilityCharts = {
            ...state.availabilityCharts,
            [storageKey]: items,
        };
        state.loading.availabilityCharts = false;
    },
    [FETCH_AVAILABILITY_CHARTS_FAILURE](state) {
        state.loading.availabilityCharts = false;
    },

    // -----------------------------------------
    // FETCH_CURRENT_UNAVAILABLE_PRODUCTS
    // -----------------------------------------
    [FETCH_CURRENT_UNAVAILABLE_PRODUCTS_REQUEST](state) {
        state.loading.currentUnavailableProducts = true;
    },
    [FETCH_CURRENT_UNAVAILABLE_PRODUCTS_SUCCESS](state, payload) {
        const { items, campaign } = payload;
        const storageKey = utilsService.getStorageKey(...campaign);
        state.currentUnavailableProducts = {
            ...state.currentUnavailableProducts,
            [storageKey]: items,
        };
        state.loading.currentUnavailableProducts = false;
    },
    [FETCH_CURRENT_UNAVAILABLE_PRODUCTS_FAILURE](state) {
        state.loading.currentUnavailableProducts = false;
    },

    // -----------------------------------------
    // FETCH_CURRENT_PRODUCTS_AVAILABILITY
    // -----------------------------------------
    [FETCH_CURRENT_PRODUCTS_AVAILABILITY_REQUEST](state) {
        state.loading.currentProductsAvailability = true;
    },
    [FETCH_CURRENT_PRODUCTS_AVAILABILITY_SUCCESS](state, payload) {
        const { items, campaign } = payload;
        const storageKey = utilsService.getStorageKey(...campaign);
        state.currentProductsAvailability = {
            ...state.currentProductsAvailability,
            [storageKey]: items,
        };
        state.loading.currentProductsAvailability = false;
    },
    [FETCH_CURRENT_PRODUCTS_AVAILABILITY_FAILURE](state) {
        state.loading.currentProductsAvailability = false;
    },

    [GENERATE_AVAILABILITY_EXPORT_REPORT_REQUEST](state) {
        state.loading.generateRequest = true;
    },
    [GENERATE_AVAILABILITY_EXPORT_REPORT_SUCCESS](state) {
        state.loading.generateRequest = false;
    },
    [GENERATE_AVAILABILITY_EXPORT_REPORT_FAILURE](state) {
        state.loading.generateRequest = false;
    },
    [FETCH_AVAILABILITY_EXPORT_REPORTS_REQUEST](state) {
        state.loading.fetchRequest = true;
    },
    [FETCH_AVAILABILITY_EXPORT_REPORTS_SUCCESS](state, payload) {
        state.loading.fetchRequest = false;
        state.availabilityExportReports = payload;
    },
    [FETCH_AVAILABILITY_EXPORT_REPORTS_FAILURE](state) {
        state.loading.fetchRequest = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
