/* eslint-disable no-unused-vars */
import {
    availableCampaigns,
    campaigns,
    getLang,
    setProducerAvailableCampaigns,
    setProducerCampaigns,
} from '@/services/demo-services/_utils';
import demoService from '../demoService';

export default {
    async fetchProducerPlugin(spaceId) {
        const { default: producerPlugin } = await import('../../mocks/producerPlugin.json');
        return producerPlugin;
    },

    async fetchActiveCampaigns(buyboxId) {
        await setProducerCampaigns();
        return campaigns;
    },

    async fetchAvailableCampaigns(buyboxId) {
        await setProducerAvailableCampaigns();
        const availableCampaignsObj = {};
        availableCampaigns.forEach(campaign => {
            availableCampaignsObj[campaign.id] = campaign;
        });
        return availableCampaignsObj;
    },

    async fetchAvailableExtensions() {
        if (getLang() === 'pl') {
            const { default: extensions } = await import('../../mocks/extensions.pl.json');
            return extensions;
        }
        const { default: extensions } = await import('../../mocks/extensions.en.json');
        return extensions;
    },

    async fetchSortTypes() {
        if (demoService.lang === 'pl') {
            const { default: buyboxSortTypes } = await import('../../mocks/buyboxSortTypes.pl.json');
            return buyboxSortTypes;
        }
        const { default: buyboxSortTypes } = await import('../../mocks/buyboxSortTypes.en.json');
        return buyboxSortTypes;
    },

    async updatePlugin(buyboxId, data) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    async createCampaign(buyboxId, campaignId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    async updateCampaign(buyboxId, campaignId, data) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    async deleteCampaign(buyboxId, campaignId) {
        throw new Error(demoService.unavailableInDemoMsg);
    },

    async fetchAvailabilities() {
        throw new Error(demoService.unavailableInDemoMsg);
    },
};
