<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('accountSettingsPersonal.title') }}</h3>
        </v-card-title>
        <v-card-text>
            <v-form @submit.prevent="handleSubmit" novalidate>
                <v-layout row wrap>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.firstName"
                            :error-messages="firstNameErrors"
                            @blur="$v.model.firstName.$touch()"
                            name="firstName"
                            :label="$t('accountSettingsPersonal.firstName')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.lastName"
                            :error-messages="lastNameErrors"
                            @blur="$v.model.lastName.$touch()"
                            name="lastName"
                            :label="$t('accountSettingsPersonal.lastName')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.email"
                            :error-messages="emailErrors"
                            @blur="$v.model.email.$touch()"
                            name="email"
                            :label="$t('accountSettingsPersonal.email')"
                            :disabled="submitting"
                            type="email"
                            required
                            maxlength="255"
                            placeholder=" "
                            outline
                        />
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-text-field
                            v-model.trim="model.phone"
                            :error-messages="phoneErrors"
                            @blur="$v.model.phone.$touch()"
                            name="phone"
                            :label="$t('accountSettingsPersonal.phone')"
                            :disabled="submitting"
                            type="text"
                            required
                            maxlength="50"
                            placeholder=" "
                            outline
                            mask="+## #########"
                            return-masked-value
                        />
                    </v-flex>

                    <v-flex class="my-2" xs12>
                        <h4 class="subheading font-weight-bold">{{ $t('accountSettingsPersonal.sectionNotifications') }}</h4>
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-select
                            :items="notificationsOptions"
                            v-model="model.notifications"
                            :error-messages="notificationsErrors"
                            @blur="$v.model.notifications.$touch()"
                            name="notifications"
                            :label="$t('accountSettingsPersonal.notifications')"
                            :disabled="submitting"
                            placeholder=" "
                            outline
                            dense
                        />
                    </v-flex>

                    <v-flex class="my-2" xs12>
                        <h4 class="subheading font-weight-bold">{{ $t('accountSettingsPersonal.sectionLanguage') }}</h4>
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-select
                            :items="languageOptions"
                            v-model="model.language"
                            :error-messages="languageErrors"
                            @blur="$v.model.language.$touch()"
                            name="language"
                            :label="$t('accountSettingsPersonal.language')"
                            :disabled="submitting"
                            required
                            placeholder=" "
                            outline
                            dense
                        />
                    </v-flex>

                    <v-flex class="my-2" xs12>
                        <h4 class="subheading font-weight-bold">{{ $t('accountSettingsPersonal.demo') }}</h4>
                    </v-flex>
                    <v-flex class="pr-4" xs12 sm6>
                        <v-select
                            :items="demoOptions"
                            v-model="model.demo"
                            :error-messages="demoErrors"
                            @blur="$v.model.demo.$touch()"
                            name="demo"
                            :label="$t('accountSettingsPersonal.demo')"
                            :disabled="submitting"
                            placeholder=" "
                            outline
                            dense
                        />
                    </v-flex>
                </v-layout>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <v-btn
                    class="btn-wide"
                    type="submit"
                    color="tertiary"
                    :loading="submitting"
                    :disabled="submitting"
                    dark
                    round
                >
                    {{ $t('accountSettingsPersonal.submit') }}
                </v-btn>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, email as emailValidator } from 'vuelidate/lib/validators';
import { getLanguageOptions } from '../../i18n/i18n';

export default {
    name: 'account-settings-personal',
    validations: {
        model: {
            firstName: { required },
            lastName: { required },
            email: { required, email: emailValidator },
            phone: { required },
            notifications: {},
            demo: {},
            language: { required },
        },
    },
    data() {
        return {
            model: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                notifications: '',
                language: '',
                demo: '',
            },
            languageOptions: getLanguageOptions(),
            notificationsOptions: [
                {
                    value: true,
                    text: 'Notify me',
                },
                {
                    value: false,
                    text: 'Do not notify me',
                },
            ],
            demoOptions: [
                {
                    value: true,
                    text: this.$t('accountSettingsPersonal.demoEnabled'),
                },
                {
                    value: false,
                    text: this.$t('accountSettingsPersonal.demoDisabled'),
                },
            ],
            error: null,
        };
    },
    computed: {
        firstNameErrors() {
            const errors = [];
            if (!this.$v.model.firstName.$dirty) {
                return errors;
            }
            if (!this.$v.model.firstName.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        lastNameErrors() {
            const errors = [];
            if (!this.$v.model.lastName.$dirty) {
                return errors;
            }
            if (!this.$v.model.lastName.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.model.email.$dirty) {
                return errors;
            }
            if (!this.$v.model.email.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.email.email) {
                errors.push(this.$t('validation.email'));
            }
            return errors;
        },
        phoneErrors() {
            const errors = [];
            if (!this.$v.model.phone.$dirty) {
                return errors;
            }
            if (!this.$v.model.phone.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        notificationsErrors() {
            const errors = [];
            if (!this.$v.model.notifications.$dirty) {
                return errors;
            }
            return errors;
        },
        demoErrors() {
            const errors = [];
            if (!this.$v.model.demo.$dirty) {
                return errors;
            }
            return errors;
        },
        languageErrors() {
            const errors = [];
            if (!this.$v.model.language.$dirty) {
                return errors;
            }
            if (!this.$v.model.language.required) {
                errors.push(this.$t('validation.required'));
            }
            return errors;
        },
        ...mapState({
            user: state => state.auth.user,
            submitting: state => state.auth.loading.updatePersonalSettings,
        }),
    },
    created() {
        this.setInitialValues();
    },
    methods: {
        setInitialValues() {
            const {
                firstName, lastName, email, phone, notifications, language, demo,
            } = this.user;
            this.model = {
                firstName,
                lastName,
                email,
                phone,
                notifications: notifications && notifications.news,
                language,
                demo,
            };
        },
        async handleSubmit() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.updatePersonalSettings({ ...this.model });
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            updatePersonalSettings: 'auth/updatePersonalSettings',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
