import { deburr, orderBy } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            group: [],
            error: null,
        };
    },
    computed: {
        groupOptions() {
            return this.getterGroupOptions;
        },
        groupFilterSummary() {
            let items = [];
            this.group.forEach(groupId => {
                const groupOption = this.groupOptions.find(option => option.value === groupId);
                if (groupOption) {
                    items.push(groupOption.text);
                }
            });
            items = orderBy(items, [item => deburr(item).toLowerCase()], ['asc']);
            return {
                name: this.$t('filterSummary.types.groups'),
                items,
            };
        },
        ...mapGetters({
            getterGroupOptions: 'producerProductGroup/getGroupOptions',
        }),
        ...mapState({
            groupsLoading: state => state.producerProductGroup.loading.fetchGroups,
        }),
    },
    methods: {
        /**
         * Set default group items
         */
        setDefaultGroup() {
            this.group = this.getterGroupOptions.map(group => group.value);
        },
        /**
         * Returns if currently selected group items are matching default list
         * @return {boolean}
         */
        isDefaultGroup() {
            return this.group.length === 0;
        },
        async getGroups(force) {
            try {
                this.error = null;
                await this.fetchGroups(force);
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchGroups: 'producerProductGroup/fetchGroups',
        }),
    },
    async created() {
        await this.getGroups();
        this.setDefaultGroup();
    },
};
