export default {
    getStorageKey(...args) {
        return args.join('-');
    },

    /**
     * Fill array with data
     * @param {Array} sourceArray - Array containing source data
     * @param {Array} newData - Array containing new data to be added
     * @param {string} [propertyName] - If not defined then new data will be added as array item,
     *                                  otherwise new data will be added as object with propertyName as key
     * @param {Array} [additionalProperties] - optional properties to be added as array item,
     *                                          e.g. [{ key: 'sampleName', value: 'sampleValue'}]
     * @return {string[]|object[]}
     */
    fillArray(sourceArray, newData, propertyName, additionalProperties) {
        const filledData = newData.map((item) => {
            if (propertyName && additionalProperties) {
                const parsedItem = {
                    [propertyName]: item,
                };
                additionalProperties.forEach((prop) => {
                    parsedItem[prop.key] = prop.value;
                });
                return parsedItem;
            }
            if (propertyName) {
                return {
                    [propertyName]: item,
                };
            }
            return item;
        });
        return [...filledData, ...sourceArray];
    },
};
