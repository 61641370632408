<template>
    <v-text-field
        v-model="color"
        :style="{ width }"
        :label="label"
        :error-messages="errorMessages"
        :disabled="disabled"
        type="text"
        required
        maxlength="7"
        placeholder=" "
        outline
        @input="handleInput"
        @change="handleChange"
        @blur="handleBlur"
    >
        <template v-slot:append>
            <v-menu
                v-model="pickerVisible"
                :close-on-content-click="false"
                offset-y
            >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="colorPicker__trigger" flat>
                        <div class="colorPicker__trigger-preview" :style="{ background: color }"></div>
                    </v-btn>
                </template>

                <Picker :value="color" disableAlpha @input="onPickerInput" />
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
import { Chrome } from 'vue-color';

export default {
    name: 'color-picker',
    components: {
        Picker: Chrome,
    },
    props: {
        value: {
            type: String,
        },
        label: {
            type: String,
            default() {
                return this.$t('colorPicker.label');
            },
        },
        errorMessages: {
            type: Array,
        },
        disabled: {
            type: Boolean,
        },
        width: {
            type: String,
            default: '200px',
        },
    },
    data() {
        return {
            color: this.value,
            pickerVisible: false,
        };
    },
    methods: {
        handleInput(value) {
            this.$emit('input', value);
        },
        handleChange() {
            this.$emit('change');
        },
        handleBlur() {
            this.$emit('blur');
        },
        onPickerInput(color) {
            const { hex } = color;
            this.color = hex;
            this.handleInput(hex);
        },
    },
};
</script>

<style lang="scss">
    .colorPicker__trigger {
        position: relative;
        top: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        min-width: 0;
        height: 20px;
        padding: 0;
        border: 1px solid #c8c8c8;
        border-radius: 2px;
    }

    .colorPicker__trigger-preview {
        width: 12px;
        height: 12px;
    }
</style>
