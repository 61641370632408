<template>
    <v-card class="price-filters grey lighten-4 elevation-0">
        <v-card-text class="text-xs-center">
            <h1>{{ $t('competitionProductsSelector.title') }}</h1>
        </v-card-text>
        <v-card-text>
            <h2 class="mt-5 mb-3">{{ $t('competitionProductsSelector.producerProduct') }}</h2>
            <v-data-table
                class="table--transparent"
                :headers="headers"
                :items="producerProduct ? [producerProduct] : []"
                :no-data-text="$t('noResults')"
                :no-results-text="$t('noResults')"
                hide-actions
            >
                <template v-slot:items="props">
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.sku }}</td>
                    <td>{{ props.item.ean }}</td>
                    <td class="col-actions">
                        <v-btn @click="handleRemoveProduct(props.item)" flat icon>
                            <v-icon small>fa-trash</v-icon>
                        </v-btn>
                    </td>
                </template>
            </v-data-table>
            <div v-if="!producerProduct">
                <h4 class="mt-2 mb-2">{{ $t('competitionProductsSelector.selectProduct') }}</h4>
                <v-layout
                    wrap
                    align-center
                    justify-start
                >
                    <v-flex class="ml-3 mb-3" shrink>
                        <product-selector
                            component-id="productSearch"
                            @input="handleProductInput"
                        />
                    </v-flex>
                </v-layout>
            </div>

            <h2 class="mt-5 mb-3">{{ $t('competitionProductsSelector.competitiveProductList') }}</h2>
            <v-data-table
                class="table--transparent"
                :headers="headers"
                :items="aggregations"
                :no-data-text="$t('noResults')"
                :no-results-text="$t('noResults')"
                :rows-per-page-text="$t('table.rowsPerPage')"
                :rows-per-page-items="rowsPerPageItems"
                :pagination.sync="aggregationsPagination"
            >
                <template v-slot:items="props">
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.sku }}</td>
                    <td>{{ props.item.ean }}</td>
                    <td class="col-actions">
                        <v-btn @click="handleRemoveCompetitiveProduct(props.item)" flat icon>
                            <v-icon small>fa-trash</v-icon>
                        </v-btn>
                    </td>
                </template>
            </v-data-table>
            <v-btn
                v-if="!addCompetitiveProduct"
                color="tertiary"
                dark
                round
                small
                @click="handleAddCompetitiveProduct"
            >
                <v-icon class="mr-2" small>fa-plus</v-icon>
                {{ $t('competitionProductsSelector.addProduct') }}
            </v-btn>
            <div v-if="addCompetitiveProduct">
                <h4 class="mt-2 mb-2">{{ $t('competitionProductsSelector.selectCompetitiveProduct') }}</h4>
                <v-btn-toggle class="mb-3" v-model="addCompetitiveProductType" mandatory>
                    <v-btn flat value="global">
                        {{ $t('competitionProductsSelector.selectGlobal') }}
                    </v-btn>
                    <v-btn flat value="producer">
                        {{ $t('competitionProductsSelector.selectProducer') }}
                    </v-btn>
                </v-btn-toggle>
                <v-layout wrap align-center>
                    <v-flex class="mla-3" shrink>
                        <product-selector
                            component-id="competitiveProductSearch"
                            :publisherId="addCompetitiveProductType === 'global' ? userId : undefined"
                            @input="handleCompetitiveProductInput"
                        />
                    </v-flex>
                    <v-flex class="ml-3" shrink>
                        <v-btn
                            color="tertiary"
                            dark
                            round
                            @click="addCompetitiveProduct = false"
                        >
                            {{ $t('cancel') }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </div>

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{ error }}
            </v-alert>

            <div class="layout wrap justify-center mt-5">
                <v-btn
                    class="btn-wide"
                    :disabled="!producerProduct || !aggregations.length || savingNew"
                    :loading="savingClose"
                    color="tertiary"
                    dark
                    round
                    @click="saveProducts"
                >
                    {{ $t('competitionProductsSelector.save') }}
                </v-btn>
                <v-btn
                    v-if="setId == null"
                    class="btn-wide"
                    :disabled="!producerProduct || !aggregations.length || savingClose"
                    :loading="savingNew"
                    color="tertiary"
                    dark
                    round
                    @click="saveProductsAndCreateNew"
                >
                    {{ $t('competitionProductsSelector.saveAndCreateNew') }}
                </v-btn>
                <v-btn
                    v-if="setId != null || sets.length > 0 || savingClose || savingNew"
                    class="btn-wide"
                    round
                    @click="cancel"
                >
                    {{ $t('cancel') }}
                </v-btn>
            </div>
        </v-card-text>
        <confirm-delete-modal v-model="confirmDeleteModalOpen" :item="deleteProduct" nameKey="text" @cancel="handleCancelDelete" @confirm="handleConfirmDelete" />
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import demoService from '@/services/demoService';
import reportsCompetitionService from '@/services/reportsCompetitionService';
import ProductSelector from '@/components/common/ProductSelector.vue';
import ConfirmDeleteModal from '@/components/common/ConfirmDeleteModal.vue';

export default {
    name: 'competition-products-selector',
    components: {
        ConfirmDeleteModal,
        ProductSelector,
    },
    props: {
        sets: {
            type: Array,
        },
        setId: {
            type: Number,
        },
    },
    data() {
        return {
            producerProduct: null,
            producerProducts: [],
            globalProducts: [],
            addCompetitiveProduct: false,
            addCompetitiveProductType: 'global',
            headers: [
                {
                    text: this.$t('id'),
                    align: 'left',
                    sortable: true,
                    value: 'entityId',
                },
                {
                    text: this.$t('name'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('sku'),
                    align: 'left',
                    sortable: true,
                    value: 'sku',
                },
                {
                    text: this.$t('ean'),
                    align: 'left',
                    sortable: true,
                    value: 'ean',
                },
                {
                    text: '',
                    align: 'right',
                    sortable: false,
                    value: 'actions',
                },
            ],
            rowsPerPageItems: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
            ],
            aggregationsPagination: {
                page: 1,
                rowsPerPage: 10,
                totalItems: 0,
            },
            deleteProduct: null,
            deleteProductType: null,
            confirmDeleteModalOpen: false,
            error: null,
            savingClose: false,
            savingNew: false,
        };
    },
    computed: {
        isDemo() {
            return this.$store.getters['space/isDemo'];
        },
        aggregations() {
            return [
                ...this.producerProducts,
                ...this.globalProducts,
            ];
        },
        ...mapState({
            userId: state => state.auth.user?.id,
            spaceId: state => state.space.currentSpaceId,
        }),
    },
    methods: {
        handleProductInput(product) {
            // eslint-disable-next-line no-underscore-dangle
            this.producerProduct = product.__raw;
        },
        handleRemoveProduct(product) {
            if (this.isDemo) {
                this.$toast.error(demoService.unavailableInDemoMsg);
                return;
            }
            this.deleteProduct = product;
            this.deleteProductType = 'producer';
            this.confirmDeleteModalOpen = true;
        },
        handleConfirmDelete() {
            if (this.deleteProductType === 'producer') {
                this.removeProduct(this.deleteProduct);
            } else {
                this.removeCompetitiveProduct(this.deleteProduct);
            }
        },
        handleCancelDelete() {
            this.deleteProductType = null;
            this.deleteProduct = null;
        },
        removeProduct() {
            this.producerProduct = null;
            this.deleteProductType = null;
            this.deleteProduct = null;
        },
        handleAddCompetitiveProduct() {
            if (this.isDemo) {
                this.$toast.error(demoService.unavailableInDemoMsg);
                return;
            }
            this.addCompetitiveProduct = true;
        },
        handleCompetitiveProductInput(product) {
            // eslint-disable-next-line no-underscore-dangle
            const rawProduct = product.__raw;
            if (this.addCompetitiveProductType === 'global') {
                const productAlreadyAdded = this.globalProducts.some(prod => prod.id === rawProduct.id);
                if (!productAlreadyAdded) {
                    this.globalProducts = [
                        ...this.globalProducts,
                        rawProduct,
                    ];
                }
            } else {
                const productAlreadyAdded = this.producerProducts.some(prod => prod.id === rawProduct.id);
                if (!productAlreadyAdded) {
                    this.producerProducts = [
                        ...this.producerProducts,
                        rawProduct,
                    ];
                }
            }
            this.addCompetitiveProduct = false;
        },
        handleRemoveCompetitiveProduct(product) {
            if (this.isDemo) {
                this.$toast.error(demoService.unavailableInDemoMsg);
                return;
            }
            this.deleteProduct = product;
            this.deleteProductType = 'competitive';
            this.confirmDeleteModalOpen = true;
        },
        removeCompetitiveProduct() {
            if (this.deleteProduct.offerId) {
                this.globalProducts = this.globalProducts.filter(prod => prod.id !== this.deleteProduct.id);
            } else {
                this.producerProducts = this.producerProducts.filter(prod => prod.id !== this.deleteProduct.id);
            }
            this.deleteProductType = null;
            this.deleteProduct = null;
        },
        async saveProducts() {
            try {
                this.savingClose = true;
                const set = await this.saveSet();
                this.$emit('change', set);
                this.$emit('close');
            } catch (e) {
                this.error = e.message;
            }
            this.savingClose = false;
        },
        async saveProductsAndCreateNew() {
            try {
                if (this.isDemo) {
                    this.$toast.error(demoService.unavailableInDemoMsg);
                    return;
                }
                this.savingNew = true;
                const set = await this.saveSet();
                this.$emit('change', set);
                this.producerProduct = null;
                this.producerProducts = [];
                this.globalProducts = [];
            } catch (e) {
                this.error = e.message;
            }
            this.savingNew = false;
        },
        async saveSet() {
            const producerProductId = this.producerProduct.id;
            const producerProductsIds = this.producerProducts.map(product => product.id);
            const globalProductsIds = this.globalProducts.map(product => product.id);

            if (this.setId) {
                // update set
                const oldSet = this.sets.find(setItem => setItem.id === this.setId);
                const newSet = {
                    id: this.setId,
                    producerProduct: this.producerProduct,
                    producerProducts: this.producerProducts,
                    globalProducts: this.globalProducts,
                };
                const setChanged = reportsCompetitionService.hasSetChanged(oldSet, newSet);
                if (setChanged) {
                    return reportsCompetitionService.updateSet(this.setId, producerProductId, producerProductsIds, globalProductsIds);
                }
                // no change, return set without saving
                return newSet;
            }
            // create set
            return reportsCompetitionService.createSet(this.spaceId, producerProductId, producerProductsIds, globalProductsIds);
        },
        cancel() {
            this.$emit('close');
        },
        parseDemoProductData(demoData, productId) {
            const product = demoData.products[productId];
            return {
                categories: product.product.categories,
                ean: product.product.ean,
                entityId: product.product.id,
                id: `product-${product.product.id}`,
                img: product.product.images?.[0],
                productId: product.product.productId,
                sku: product.product.sku,
                text: product.product.name,
            };
        },
    },
    async created() {
        const set = this.setId
            ? this.sets.find(setItem => setItem.id === this.setId)
            : null;
        this.producerProduct = cloneDeep(set?.producerProduct ?? null);
        this.producerProducts = cloneDeep(set?.producerProducts ?? []);
        this.globalProducts = cloneDeep(set?.globalProducts ?? []);

        if (this.isDemo) {
            const demoData = await reportsCompetitionService.fetchCompetitionReport();
            const productsIds = Object.keys(demoData?.products);
            if (productsIds.length >= 2) {
                this.producerProduct = this.parseDemoProductData(demoData, productsIds[0]);
                this.producerProducts = [this.parseDemoProductData(demoData, productsIds[1])];
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .col-actions {
        width: 50px;
    }
</style>
