<template>
    <div class="mt-4 text-xs-right">
        <v-btn
            :to="to"
            class="mb-0 text-transform-off"
            color="tertiary"
            dark
            round
            small
        >
            {{ label }}
            <v-icon class="ml-2 icon-smallest">fa-chevron-right</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'more-button',
    props: {
        to: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            default() {
                return this.$t('more');
            },
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
