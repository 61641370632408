<template>
    <v-carousel cycle hide-controls class="carousel" height="420">
        <v-carousel-item v-for="(testimonial, index) in testimonialsList" :key="index" class=" carousel-item">
            <div>
                <p class="text-white font-italic carousel-item-text">{{ testimonial.text }}</p>

                <div>
                    <v-img :src="testimonial.image" :alt="testimonial.name" contain width="64" height="64"></v-img>

                    <div>
                        <p class="text-white font-weight-bold mb-0">{{ testimonial.name }}</p>
                        <p class="text-white mt-0">{{ testimonial.title }}</p>
                    </div>
                </div>
            </div>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
export default {
    name: 'testimonial-carousel',
    data() {
        return {
            testimonials: {
                en: [
                    {
                        name: 'Łukasz Sałański',
                        title: 'Omnichannel Development Manager',
                        image: 'https://100shoppers.com/wp-content/uploads/2022/06/11.png',
                        text:
                            'Thanks to the 100Shoppers plugin, managing customer information under the Where-To-Buy button is faster, easier and more efficient both on our brand sites and in our campaigns. The provided reports let us use the potential of our products more effectively.',
                    },
                    {
                        name: 'Rafał Czapski',
                        title: 'Digital & Trade Marketing Manager',
                        image: 'https://100shoppers.com/wp-content/uploads/2022/08/beko_white_v2-e1679054030900.webp',
                        text:
                            'The solution is very flexible, which let us create a Where-To-Buy solution customized to our brand website. Thanks to that, we are able to redirect our customers to chosen retailers leading them directly to the product fiche and assuring them that the product is available.',
                    },
                    {
                        name: 'Anna Pietrzak',
                        title: 'Marketing Director',
                        image: 'https://100shoppers.com/wp-content/uploads/2022/08/mpm_white-e1679054097399.webp',
                        text:
                            'Store lists displayed automatically next to every product is a customer solution that has a direct impact on our sales. Additionally, the detailed reports provide our marketing departments with necessary information about the popularity of our products.',
                    },
                ],
                pl: [
                    {
                        name: 'Łukasz Sałański',
                        title: 'Omnichannel Development Manager',
                        image: 'https://100shoppers.com/wp-content/uploads/2022/06/11.png',
                        text:
                            'Zastosowanie pluginu 100Shoppers pozwoliło nam na szybsze, prostsze i efektywniejsze zarządzanie informacjami dla konsumentów pod przyciskiem „Gdzie kupić” zarówno na naszych stronach brandowych, jak i kampaniach. Dzięki raportom, jesteśmy w stanie efektywniej wykorzystywać potencjał naszych produktów.',
                    },
                    {
                        name: 'Rafał Czapski',
                        title: 'Digital & Trade Marketing Manager',
                        image: 'https://100shoppers.com/wp-content/uploads/2022/08/beko_white_v2-e1679054030900.webp',
                        text:
                            'Zastosowanie pluginu 100Shoppers pozwoliło nam na szybsze, prostsze i efektywniejsze zarządzanie informacjami dla konsumentów pod przyciskiem „Gdzie kupić” zarówno na naszych stronach brandowych, jak i kampaniach. Dzięki raportom, jesteśmy w stanie efektywniej wykorzystywać potencjał naszych produktów.',
                    },
                    {
                        name: 'Anna Pietrzak',
                        title: 'Marketing Director',
                        image: 'https://100shoppers.com/wp-content/uploads/2022/08/mpm_white-e1679054097399.webp',
                        text:
                            'Automatyczne wyświetlanie listy sklepów przy każdym produkcie, to udogodnienie dla klientów, które realnie przekłada się na naszą sprzedaż.Dodatkowo szczegółowe raporty dostarczają niezbędnych informacji naszym działom marketingu na temat popularności naszych produktów.',
                    },
                ],
            },
        };
    },
    computed: {
        testimonialsList() {
            return this.testimonials[this.$i18n.locale] || this.testimonials.en;
        },
    },
};
</script>

<style>
.v-carousel__controls {
    background: transparent !important;
}

.v-carousel__controls__item i {
    font-size: 12px !important;
}
</style>

<!-- eslint-disable linebreak-style -->
<style lang="scss" scoped>
.carousel-item-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
}
.carousel {
    background-color: transparent;
    padding: 0 !important;
    box-shadow: none !important;

    margin-top: 4rem;
}
.carousel-item {
    background-color: transparent;
    padding: 0 !important;
    box-shadow: none !important;
}

.text-white {
    color: #fff;
}
</style>
