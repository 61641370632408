<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('pluginAppearance.title') }}</h3>
        </v-card-title>
        <!-- @todo change to v-card-subtitle upon vuetify 2 migration -->
        <p class="card-subtitle">
            <a :href="$t('pluginAppearance.helpUrl')" target="_blank">
                {{ $t('pluginAppearance.helpText') }}
            </a>
        </p>
        <v-card-text>
            <div v-if="fetchSortTypesLoading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-form @submit.prevent="handleSubmit" novalidate>
                <div v-if="!fetchSortTypesLoading">
                    <template v-if="$feature.newPluginAppearanceEnabled()">
                        <v-flex class="pr-4" xs12 sm6>
                            <v-select
                                :items="versionOptions"
                                v-model="model.version"
                                @blur="$v.model.version.$touch()"
                                name="version"
                                :label="$t('pluginAppearance.version')"
                                :disabled="submitting"
                                placeholder=" "
                                outline
                                dense />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-select
                                :items="languageOptions"
                                v-model="model.language"
                                @blur="$v.model.language.$touch()"
                                name="language"
                                :label="$t('pluginAppearance.language')"
                                :disabled="submitting"
                                placeholder=" "
                                outline
                                dense />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <color-picker
                                v-model.trim="model.leadColor"
                                :label="$t('pluginAppearance.leadColor')"
                                :error-messages="leadColorErrors"
                                @blur="$v.model.leadColor.$touch()"
                                :disabled="submitting"
                                required />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-text-field
                                v-model.trim="model.buttonLabel"
                                :error-messages="buttonLabelErrors"
                                @blur="$v.model.buttonLabel.$touch()"
                                name="buttonLabel"
                                :label="$t('pluginAppearance.buttonLabel')"
                                :disabled="submitting"
                                type="text"
                                :maxlength="buttonLabelMaxLength"
                                :counter="buttonLabelMaxLength"
                                placeholder=" "
                                outline />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-select
                                :items="shopStyleOptions"
                                v-model="model.shopStyle"
                                @blur="$v.model.shopStyle.$touch()"
                                name="shopStyle"
                                :label="$t('pluginAppearance.shopStyle')"
                                :disabled="submitting"
                                placeholder=" "
                                outline
                                dense />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-select
                                :items="availabilities"
                                multiple
                                v-model="model.availabilities"
                                @blur="$v.model.availabilities.$touch()"
                                name="availabilities"
                                item-text="label"
                                item-value="value"
                                :label="$t('pluginAppearance.availability')"
                                :disabled="submitting"
                                placeholder=" "
                                outline
                                dense />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-switch
                                v-model="model.showProduct"
                                @blur="$v.model.showProduct.$touch()"
                                name="showProduct"
                                :label="$t('pluginAppearance.showProduct')"
                                :disabled="submitting"
                                color="accent" />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-switch
                                v-model="model.showPrices"
                                @blur="$v.model.showPrices.$touch()"
                                name="showPrices"
                                :label="$t('pluginAppearance.showPrices')"
                                :disabled="submitting"
                                color="accent" />
                        </v-flex>

                        <h4 class="subheading font-weight-bold">{{ $t('pluginAppearance.additionalColumnSection') }}</h4>
                        <v-flex class="pr-4" xs12 sm6>
                            <v-switch
                                v-model="model.showAdditionalColumn"
                                @blur="$v.model.showAdditionalColumn.$touch()"
                                name="showAdditionalColumn"
                                :label="$t('pluginAppearance.showAdditionalColumn')"
                                :disabled="submitting"
                                color="accent" />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-textarea
                                v-if="model.showAdditionalColumn"
                                v-model.trim="model.additionalColumn"
                                :error-messages="additionalColumnErrors"
                                @blur="$v.model.additionalColumn.$touch()"
                                name="additionalColumn"
                                :label="$t('pluginAppearance.additionalColumn')"
                                :disabled="submitting"
                                :maxlength="additionalColumnMaxLength"
                                :counter="additionalColumnMaxLength"
                                required
                                placeholder=" "
                                outline />
                        </v-flex>

                        <v-flex class="pr-4" xs12 sm6>
                            <v-switch
                                v-if="model.showAdditionalColumn"
                                v-model="model.additionalColumnOnlyForSorted"
                                @blur="$v.model.additionalColumnOnlyForSorted.$touch()"
                                name="additionalColumnOnlyForSorted"
                                :label="$t('pluginAppearance.additionalColumnOnlyForSorted')"
                                :disabled="submitting"
                                color="accent" />
                        </v-flex>
                    </template>

                    <h4 class="subheading font-weight-bold">{{ $t('pluginAppearance.sectionSorting') }}</h4>
                    <v-radio-group
                        v-if="sortTypesIds && sortTypesIds.length > 0"
                        v-model="model.sortType"
                        :disabled="submitting">
                        <v-radio
                            v-for="sortTypeId in sortTypesIds"
                            :key="sortTypeId"
                            :label="sortTypes[sortTypeId].label"
                            :value="sortTypes[sortTypeId].value" />
                    </v-radio-group>
                    <data-placeholder
                        v-if="!sortTypesIds || sortTypesIds.length === 0"
                        :title="$t('pluginAppearance.noSortTypes')"
                        text-position="left" />

                    <h4 class="subheading font-weight-bold mt-4">{{ $t('pluginAppearance.sectionShoppingList') }}</h4>
                    <p class="caption">{{ $t('pluginAppearance.shoppingListDescription') }}</p>
                    <v-text-field
                        class="displayed-shops"
                        v-model="model.displayedShops"
                        type="number"
                        :label="$t('pluginAppearance.displayedShops')"
                        min="0"
                        outline
                        hide-details />
                </div>

                <v-alert :value="error" dismissible type="error" transition="scale-transition">
                    {{ error }}
                </v-alert>

                <div v-if="!fetchSortTypesLoading" class="mt-4 text-xs-center">
                    <v-btn
                        class="btn-wide"
                        type="submit"
                        color="tertiary"
                        :loading="submitting"
                        :disabled="submitting"
                        dark
                        round>
                        {{ $t('pluginAppearance.submit') }}
                    </v-btn>
                </div>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { translateWidgetVersion, widgetVersions } from '@/services/pluginService';
import { getLanguageFromLocale, getLanguageOptions, i18n } from '@/i18n/i18n';
import feature from '../../../feature';
import validatorService from '../../../services/validatorService';
import DataPlaceholder from '../../common/DataPlaceholder.vue';
import ColorPicker from '../../common/ColorPicker.vue';

const BUTTON_LABEL_MAX_LENGTH = 15;
const ADDITIONAL_COLUMN_MAX_LENGTH = 65535;

export default {
    name: 'plugin-appearance',
    components: { ColorPicker, DataPlaceholder },
    validations: {
        model: {
            sortType: {},
            displayedShops: {},
            leadColor: feature.newPluginAppearanceEnabled() ? {
                required,
                hexColor: validatorService.hexColor,
            } : {},
            buttonLabel: feature.newPluginAppearanceEnabled() ? {
                maxLength: maxLength(BUTTON_LABEL_MAX_LENGTH),
            } : {},
            showProduct: {},
            showPrices: {},
            shopStyle: {},
            showAdditionalColumn: {},
            additionalColumn: feature.newPluginAppearanceEnabled() ? {
                required: requiredIf(model => model.additionalColumn),
                maxLength: maxLength(ADDITIONAL_COLUMN_MAX_LENGTH),
            } : {},
            additionalColumnOnlyForSorted: {},
            version: { required },
            language: { required },
        },
    },
    data() {
        return {
            model: {
                sortType: null,
                displayedShops: null,
                leadColor: '',
                buttonLabel: '',
                showProduct: false,
                showPrices: false,
                shopStyle: null,
                showAdditionalColumn: false,
                additionalColumn: '',
                additionalColumnOnlyForSorted: false,
                version: 'latest',
                language: null,
                availabilities: [],
            },
            languageOptions: getLanguageOptions(true),
            shopStyleOptions: [
                {
                    value: 'icon_name',
                    text: this.$t('pluginAppearance.shopStyleIconName'),
                },
                {
                    value: 'logo',
                    text: this.$t('pluginAppearance.shopStyleLogo'),
                },
            ],
            error: null,
            buttonLabelMaxLength: BUTTON_LABEL_MAX_LENGTH,
            additionalColumnMaxLength: ADDITIONAL_COLUMN_MAX_LENGTH,
        };
    },
    computed: {
        leadColorErrors() {
            const errors = [];
            if (!this.$v.model.leadColor.$dirty) {
                return errors;
            }
            if (!this.$v.model.leadColor.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.leadColor.hexColor) {
                errors.push(this.$t('validation.hexColor'));
            }
            return errors;
        },
        buttonLabelErrors() {
            const errors = [];
            if (!this.$v.model.buttonLabel.$dirty) {
                return errors;
            }
            if (!this.$v.model.buttonLabel.maxLength) {
                errors.push(this.$t('validation.maxLength', { limit: BUTTON_LABEL_MAX_LENGTH }));
            }
            return errors;
        },
        additionalColumnErrors() {
            const errors = [];
            if (!this.$v.model.additionalColumn.$dirty) {
                return errors;
            }
            if (!this.$v.model.additionalColumn.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.model.additionalColumn.maxLength) {
                errors.push(this.$t('validation.maxLength', { limit: ADDITIONAL_COLUMN_MAX_LENGTH }));
            }
            return errors;
        },
        versionOptions() {
            return widgetVersions.map(version => ({
                value: version,
                text: translateWidgetVersion(version),
            }));
        },
        ...mapGetters({
            getterPlugin: 'plugin/plugin',
            availabilities: 'plugin/availabilities',
        }),
        ...mapState({
            sortTypesIds: state => state.plugin.sortTypesIds,
            sortTypes: state => state.plugin.sortTypes,
            fetchSortTypesLoading: state => state.plugin.loading.fetchSortTypes,
            submitting: state => state.plugin.loading.updateAppearance,
        }),
    },
    watch: {
        'model.showAdditionalColumn': function watchShowAdditionalColumn(showAdditionalColumn) {
            if (!showAdditionalColumn) {
                this.$set(this.model, 'additionalColumn', '');
                this.$set(this.model, 'additionalColumnOnlyForSorted', false);
            }
        },
    },
    created() {
        if (this.getterPlugin) {
            this.$set(this.model, 'displayedShops', this.getterPlugin.rowCount ?? 0);
            this.$set(this.model, 'leadColor', this.getterPlugin.leadColor ?? '#343a40');
            this.$set(this.model, 'buttonLabel', this.getterPlugin.buttonLabel ?? '');
            this.$set(this.model, 'showProduct', this.getterPlugin.showProduct ?? false);
            this.$set(this.model, 'showPrices', this.getterPlugin.showPrices ?? false);
            this.$set(this.model, 'shopStyle', this.getterPlugin.shopStyle ?? 'icon_name');
            this.$set(this.model, 'showAdditionalColumn', Boolean(this.getterPlugin.additionalColumn));
            this.$set(this.model, 'additionalColumn', this.getterPlugin.additionalColumn ?? '');
            this.$set(this.model, 'additionalColumnOnlyForSorted', this.getterPlugin.additionalColumnOnlyForSorted ?? false);
            this.$set(this.model, 'version', this.getterPlugin.version ?? 'latest');
            this.$set(this.model, 'language', this.getterPlugin.language ?? getLanguageFromLocale(i18n.locale));
            this.$set(this.model, 'availabilities', this.getterPlugin.availabilities ?? []);
        }
        this.getSortTypes();
        this.fetchAvailabilities();
    },
    methods: {
        async getSortTypes() {
            try {
                this.error = null;
                await this.fetchSortTypes();
                const defaultSortType = this.getterPlugin?.sortType || this.sortTypesIds?.[0];
                if (defaultSortType) {
                    this.$set(this.model, 'sortType', defaultSortType);
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async handleSubmit() {
            try {
                this.error = null;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                const { showAdditionalColumn, ...filteredModel } = this.model;
                await this.updateAppearance(
                    feature.newPluginAppearanceEnabled()
                        ? filteredModel
                        : {
                            sortType: filteredModel.sortType,
                            displayedShops: filteredModel.displayedShops,
                        },
                );
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchSortTypes: 'plugin/fetchSortTypes',
            updateAppearance: 'plugin/updateAppearance',
            fetchAvailabilities: 'plugin/fetchAvailabilities',
        }),
    },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
    padding-left: 16px;
}

.displayed-shops {
    min-width: 220px;
    max-width: 220px;
}
</style>
