<template>
    <v-list class="sidebar" dark>
        <template v-for="(item, itemIndex) in filteredItems">
            <v-list-tile
                v-if="item.type === 'item'"
                :key="`item-${itemIndex}`"
                :to="item.to"
                :href="item.href"
                :target="item.target"
                :disabled="item.disabled"
                :active-class="linkActiveClass"
                @click="handleItemClick(item)"
                ripple
            >
                <v-list-tile-action v-if="item.icon">
                    <v-icon class="primary--text" small>{{item.icon}}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title :style="item.color ? `color:${item.color}` : ''">
                        {{ $t(item.label) }}
                        <v-icon v-if="item.target === '_blank'" class="ml-1 external-icon">fa-external-link-alt</v-icon>
                    </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action v-if="item.chip">
                    <v-chip class="x-small-chip" color="secondary" dark>{{ $t(item.chip) }}</v-chip>
                </v-list-tile-action>
            </v-list-tile>

            <v-list-group
                class="group"
                v-if="item.type === 'group'"
                :key="`item-${itemIndex}`"
                :disabled="item.disabled"
                :group="item.group"
                :active-class="groupActiveClass"
                no-action
            >
                <template v-if="item.activator" #activator>
                    <v-list-tile>
                        <v-list-tile-action v-if="item.icon">
                            <v-icon class="primary--text" small>{{item.icon}}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>
                                {{ $t(item.activator.label) }}
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>

                <v-list-tile
                    v-for="(groupItem, groupItemIndex) in item.items"
                    :key="`item-${itemIndex}-${groupItemIndex}`"
                    :to="groupItem.to"
                    :href="groupItem.href"
                    :target="groupItem.target"
                    :disabled="groupItem.disabled"
                    :active-class="linkActiveClass"
                    @click="handleItemClick(groupItem)"
                    ripple
                >
                    <v-list-tile-action v-if="groupItem.icon">
                        <v-icon class="primary--text" small>{{groupItem.icon}}</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            {{ $t(groupItem.label) }}
                            <v-icon v-if="item.target === '_blank'" class="ml-1 external-icon">fa-external-link-alt</v-icon>
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list-group>
        </template>
    </v-list>
</template>

<script>
import usercomService from '@/services/usercomService';
import { mapState } from 'vuex';

export default {
    name: 'sidebar',
    props: {
        items: Array,
    },
    data() {
        return {
            region: process.env.VUE_APP_REGION_MODE,
            linkActiveClass: 'font-weight-black primary--text',
            groupActiveClass: 'font-weight-black primary--text',
        };
    },
    computed: {
        filteredItems() {
            return this.items
                // filter top level items
                .filter(item => !(item.disabledRegions && item.disabledRegions.includes(this.region)))
                // map through filtered items
                .map(item => {
                    // filter group level items
                    if (item.type === 'group' && item.items && item.items.length > 0) {
                        const groupItems = item.items.filter(groupItem => !(groupItem.disabledRegions && groupItem.disabledRegions.includes(this.region)));
                        return { ...item, items: groupItems };
                    }
                    return item;
                });
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            spaces: state => state.space.spaces,
        }),
    },
    methods: {
        handleItemClick(item) {
            usercomService.userengage(`event.menu-${this.$t(item.label)}`, { 'space-type': this.spaces?.[this.spaceId]?.name });
            if (item.onClick) {
                item.onClick();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .sidebar {
        padding: 13px;
    }

    .external-icon {
        line-height: 18px;
        font-size: 12px;
        color: $text-light-color;
    }

    .x-small-chip {
        height: 16px;
        font-size: 11px;
    }
</style>

<style lang="scss">
    .sidebar {
        .v-list__tile {
            padding: 13px;
        }

        .v-list__tile__action {
            min-width: 32px;
        }

        .v-list__group__items--no-action .v-list__tile {
            padding-left: 48px;
        }

        & > .v-list__group:before,
        & > .v-list__group:after {
            display: none;
        }

        .x-small-chip .v-chip__content {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
</style>
