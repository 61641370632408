import Line from 'sango-leader-line-new';

Line.positionByWindowResize = false;

export default class LeaderLine extends Line {
    element;

    parent;

    #onScroll;

    #onResize;

    #resizeObserver;

    constructor(options) {
        super(options);
        this.element = document.body.querySelector(':scope>.leader-line:last-of-type');
        const { parent } = options;
        this.parent = parent;
        this.parentToResizeObserve = parent || document.body;
        if (parent instanceof HTMLElement) {
            parent.appendChild(this.element);
        }
        this.#onScroll = () => this.position();
        this.#onResize = () => this.position();
        this.#resizeObserver = new ResizeObserver(this.#onResize);
        this.#resizeObserver.observe(this.parentToResizeObserve);
        window.addEventListener('scroll', this.#onScroll);
        this.position();
        setTimeout(() => this.position(), 100);
    }

    customPosition() {
        const { element, parent } = this;

        if (parent instanceof HTMLElement) {
            const { scrollLeft, scrollTop } = parent;
            const { left, top } = parent.getBoundingClientRect();
            const diffTop = (window.scrollY + scrollTop + top);
            const diffLeft = (window.scrollX + scrollLeft + left);
            element.style.transform = `translate(${-diffLeft}px,${-diffTop}px)`;
        }

        element.style.zIndex = 5000;
        element.style.position = 'absolute';
    }

    position() {
        try {
            super.position();
            this.customPosition();
        } catch (error) {}
    }

    show() {
        super.show.apply(this, arguments);
        this.position();
        setTimeout(() => this.position(), 100);
    }

    remove() {
        this.#resizeObserver.disconnect();
        window.removeEventListener('resize', this.#onScroll);
        document.body.appendChild(this.element);
        super.remove();
    }
}
