<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Raport Produktów" />
            </template>
        </page-header-filters>
        <no-access-placeholder
            v-if="!hasPermission && !spacesLoading"
            :permission-name="permissionName"
            :title="$t('permissionContent.title.reportsProducts')"
            :description="$t('permissionContent.description.reportsProducts')"
            :image="require('../../assets/noAccessPlaceholders/reports-sales.jpg')"
            cta-position="top"
            @activated="getData"
        />
        <v-card v-if="hasPermission" class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('producerReportsProducts.noSpace')" />

                <div v-if="!loading && spaceId">
                    <products-sales ref="sales" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('sales', $event)" />
                    <products-summary ref="summary" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('summary', $event)" />
                    <products-ranking ref="ranking" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('ranking', $event)" />
                    <products-cart-analysis ref="cartAnalysis" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('cartAnalysis', $event)" />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DateRangePicker from '../../components/common/DateRangePicker.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import ProductsSales from '../../components/producer/reports/products/ProductsSales.vue';
import ProductsRanking from '../../components/producer/reports/products/ProductsRanking.vue';
import ProductsSummary from '../../components/producer/reports/products/ProductsSummary.vue';
import ProductsCartAnalysis from '../../components/producer/reports/products/ProductsCartAnalysis.vue';
import ResetFiltersButton from '../../components/common/ResetFiltersButton.vue';
import NoAccessPlaceholder from '../../components/common/NoAccessPlaceholder.vue';
import permissionMixin from '../../mixins/permissionMixin';
import spaceService from '../../services/spaceService';

export default {
    name: 'producer-reports-products',
    components: {
        ResetFiltersButton,
        NoAccessPlaceholder,
        ProductsRanking,
        ProductsCartAnalysis,
        ProductsSales,
        ProductsSummary,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
    },
    mixins: [
        permissionMixin,
    ],
    data() {
        return {
            permissionName: spaceService.permissions.STATS_PRODUCTS,
            spaceInitialized: false,
            loadingConfig: false,
            error: null,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            isDatepickerFilterDefault: true,
            isSalesFilterDefault: true,
            isSummaryFilterDefault: true,
            isRankingFilterDefault: true,
            isCartAnalysisFilterDefault: true,
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isSalesFilterDefault || !this.isSummaryFilterDefault || !this.isRankingFilterDefault || !this.isCartAnalysisFilterDefault;
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaceId: state => state.space.currentSpaceId,
            categoriesIds: state => state.space.categoriesIds,
            categories: state => state.space.categories,
        }),
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'sales') {
                this.isSalesFilterDefault = isDefault;
            } else if (type === 'summary') {
                this.isSummaryFilterDefault = isDefault;
            } else if (type === 'ranking') {
                this.isRankingFilterDefault = isDefault;
            } else if (type === 'cartAnalysis') {
                this.isCartAnalysisFilterDefault = isDefault;
            }
        },
        async getData() {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) {
                return;
            }
            this.loadingConfig = true;
            await this.getCategories();
            await this.getCampaigns();
            await this.getGroups();
            this.loadingConfig = false;
        },
        async getCategories() {
            try {
                await this.fetchProducerProductCategories();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getGroups() {
            try {
                this.error = null;
                await this.fetchGroups();
            } catch (e) {
                this.error = e.message;
            }
        },
        handleSpaceChange() {
            this.getData();

            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
        },
        ...mapActions({
            fetchProducerProductCategories: 'space/fetchProducerProductCategories',
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
            fetchGroups: 'producerProductGroup/fetchGroups',
        }),
    },
};
</script>

<style lang="scss">
</style>
