<template>
    <div>

        <v-dialog
            v-if="contactForm"
            v-model="isOpen"
            max-width="600"
        >
            <template v-slot:activator="{ on }">
                <div class="text-xs-center">
                    <v-btn
                        v-on="on"
                        class="activate-report__cta"
                        :class="{'btn-wide': !ctaSmall, 'activate-report__cta--small': ctaSmall}"
                        color="tertiary"
                        dark
                        round
                        @click="$emit('activate')"
                    >
                        {{ ctaLabel }}
                    </v-btn>
                </div>
            </template>

            <ActivateReportContactForm :permission-name="permissionName" :text-first-line="title" :text-second-line="description" :usercom-report-type="usercomReportType" />
        </v-dialog>

        <div v-else-if="ctaUrl" class="text-xs-center">
            <v-btn
                :to="{ name: ctaUrl, query: { activate: true } }"
                class="activate-report__cta"
                :class="{'btn-wide': !ctaSmall, 'activate-report__cta--small': ctaSmall}"
                color="tertiary"
                dark
                round
            >
                {{ ctaLabel }}
            </v-btn>
        </div>

        <v-dialog
            v-else-if="!ctaUrl"
            v-model="isOpen"
            :persistent="activatePermissionLoading"
            max-width="600"
        >
            <template v-slot:activator="{ on }">
                <div :class="{'activate-report__cta_button_description': ctaButtonDescription}">
                    <div v-if="ctaButtonDescription" class="mb-3">
                        {{ ctaButtonDescription }}
                    </div>
                    <div class="text-xs-center">
                        <v-btn
                            v-on="on"
                            class="activate-report__cta"
                            :class="{'btn-wide': !ctaSmall, 'activate-report__cta--small': ctaSmall}"
                            color="tertiary"
                            dark
                            round
                            @click="$emit('activate')"
                        >
                            {{ ctaLabel }}
                        </v-btn>
                    </div>
                </div>
            </template>

            <v-card class="report-container">
                <img src="../../assets/chart.png" alt="Background chart" class="activate-report__background-chart" />
                <v-card-text class="activate-report__content">
                    <v-layout row wrap pa-3>
                        <v-flex
                            class="activate-report__sidebar"
                            d-flex
                            xs12
                            sm6
                            mb-2>
                            <img src="../../assets/rocket.png" alt="Rocket" class="activate-report__rocket" />
                        </v-flex>
                        <v-flex xs12 sm6>
                            <h2 v-if="title" class="activate-report__title">{{ title }}</h2>
                            <p v-if="description" class="pre-formatted activate-report__description" v-html="description" />
                            <div class="activate-report__action-container">
                                <template v-if="ctaText">
                                    <p class="activate-report__action-text"><strong v-html="ctaText"></strong></p>
                                </template>
                                <template v-else>
                                    <div class="activate-report__action-header">{{ $t('activateReportModal.activateTitle') }}</div>
                                    <v-btn
                                        class="btn-wide"
                                        color="tertiary"
                                        dark
                                        round
                                        :loading="activatePermissionLoading"
                                        :disabled="activatePermissionLoading"
                                        @click="activate"
                                    >
                                        {{ $t('activateReportModal.submit') }}
                                    </v-btn>
                                    <v-alert
                                        :value="error"
                                        dismissible
                                        type="error"
                                        transition="scale-transition">
                                        {{ error }}
                                    </v-alert>
                                    <p class="pre-formatted activate-report__action-payment">{{ $t('activateReportModal.activatePayment') }}</p>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ActivateReportContactForm from '@/components/common/ActivateReportContactForm.vue';

export default {
    name: 'activate-report-modal',
    components: {
        ActivateReportContactForm,
    },
    props: {
        permissionName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        ctaSmall: {
            type: Boolean,
            default: false,
        },
        ctaLabel: {
            type: String,
            default() {
                return this.$t('activateReportModal.ctaLabel');
            },
        },
        ctaUrl: {
            type: String,
        },
        ctaText: {
            type: String,
            default: null,
        },
        ctaButtonDescription: {
            type: String,
            default: null,
        },
        contactForm: {
            type: Boolean,
            default: false,
        },
        usercomReportType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isOpen: false,
            error: null,
        };
    },
    computed: {
        ...mapState({
            activatePermissionLoading: state => state.space.loading.activatePermission,
        }),
    },
    created() {
        this.$nextTick(() => {
            this.isOpen = !!this.$route.query.activate;
        });
    },
    methods: {
        async activate() {
            try {
                this.error = null;
                await this.activatePermission({ permissionName: this.permissionName });
                this.isOpen = false;
                this.$emit('activated');
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            activatePermission: 'space/activatePermission',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .activate-report__cta_button_description {
        z-index: 10;
        min-width: 500px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .5) !important;
        padding: 50px 70px;
        background: #fff;
    }

    .activate-report__cta {
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .5) !important;
    }

    .activate-report__content {
        position: relative;
    }

    .activate-report__sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-start;
    }

    .activate-report__rocket {
        width: 150px;
        margin-top: 60px;

        @media (max-width: 599px) {
            margin: 0 0 20px;
        }
    }

    .activate-report__title {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
    }

    .activate-report__description {
        li {
            margin-bottom: 10px;
        }
    }

    .activate-report__action-container {
        margin-top: 110px;
        text-align: center;

        @media (max-width: 599px) {
            margin-top: 60px;
        }
    }

    .activate-report__action-text {
        margin-top: 160px;
        margin-bottom: 50px;

        @media (max-width: 599px) {
            margin-top: 60px;
            margin-bottom: 0px;
        }
    }

    .activate-report__action-header {
        font-weight: 700;
    }

    .activate-report__action-payment {
        margin-top: 15px;
        font-size: 12px;
        font-weight: 300;
    }

    .activate-report__background-chart {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
</style>

<style lang="scss">
    .activate-report__description {
        li {
            margin-bottom: 10px;
        }
    }

    .activate-report__cta.activate-report__cta--small {
        font-size: 10px;
        height: 20px;
        padding: 0 8px;
        text-transform: none;
        box-shadow: none !important;
    }
</style>
