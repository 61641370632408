<template>
    <ranking-graph
        :title="$t('competitionOffersCount.title')"
        :data="items"
        id-key="id"
        name-key="rankingName"
        total-key="rankingValue"
        marked-key="rankingMarked"
        type="small"
        :tooltip="$t('competitionShopAvailability.titleTooltip')"
        :export-header="exportHeader"
        :show-line-chart="false"
        expandable
    >
        <template #expandableContent="contentProps">
            <product-offers-table
                :data="getOffersData(contentProps.item)"
                :max-campaign-length="getMaxCampaignLength(contentProps.item)"
                item-key="key"
            />
        </template>
    </ranking-graph>
</template>

<script>
import { orderBy } from 'lodash';
import RankingGraph from '@/components/graphs/RankingGraph.vue';
import ProductOffersTable from '@/components/producer/products/ProductOffersTable.vue';
import dateService from '@/services/dateService';
import numberService from '@/services/numberService';

export default {
    name: 'competition-offers-count',
    components: {
        ProductOffersTable,
        RankingGraph,
    },
    props: {
        set: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    computed: {
        datesInRange() {
            return dateService.getDaysInRange(this.dateFilter.startDate, this.dateFilter.endDate);
        },
        items() {
            if (!this.set || !this.data) {
                return null;
            }
            let items = [];

            [this.set.producerProduct, ...this.set.producerProducts, ...this.set.globalProducts].forEach(setProduct => {
                const productData = this.data.aggregations[setProduct.id] ?? this.data.products[setProduct.id];
                items.push({
                    ...productData,
                    rankingName: productData.aggregation?.name ?? productData.product?.name,
                    rankingValue: productData.custom.offersCount,
                    rankingMarked: productData.custom.isProducerProduct,
                });
            });
            items = orderBy(items, ['rankingValue'], ['desc']);
            return items;
        },
        exportHeader() {
            return [`"${this.$t('competitionOffersCount.exportName')}"`, `"${this.$t('competitionOffersCount.exportValue')}"`];
        },
    },
    methods: {
        getOffersData(item) {
            if (!item?.availability) {
                return null;
            }
            const lastRangeDay = this.datesInRange[this.datesInRange.length - 1];
            return Object.keys(item.availability).reduce((offers, campaignId) => {
                // include only offers available on last day of range
                const itemOffers = [];
                item.availability[campaignId].forEach((offer, index) => {
                    offer.chart.segments.forEach(segment => {
                        if (dateService.formatDate(segment.start) <= lastRangeDay && dateService.formatDate(segment.end) >= lastRangeDay) {
                            itemOffers.push({
                                ...offer.offer,
                                priceFormatted: numberService.formatCurrency(offer.offer.price),
                                key: `${offer.offer.id}-${index}`,
                            });
                        }
                    });
                });
                return [
                    ...offers,
                    ...itemOffers,
                ];
            }, []);
        },
        getMaxCampaignLength(item) {
            if (!item?.availability) {
                return 0;
            }
            return Object.keys(item.availability).reduce((max, campaignId) => Math.max(max, ...item.availability[campaignId].map(campaignItem => campaignItem.offer.campaign.length)), 0);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
