<template>
    <v-container class="grey lighten-4 pt-0 main" fluid>
        <top-nav-bar hasDrawer :toggleDrawer="toggleDrawer" :homePath="homePath">
            <template>
                <slot name="topNavBar" />
            </template>
        </top-nav-bar>
        <v-navigation-drawer
            class="transparent"
            v-model="isDrawerOpen"
            mobile-break-point="1100"
            app
            clipped
            floating
            overflow>
            <div class="elevation-0 sidebar-container" :class="{'ml-4': $vuetify.breakpoint.width >= 1100}">
                <slot name="sidebar" />
            </div>
        </v-navigation-drawer>
        <v-content>
            <slot />
        </v-content>
    </v-container>
</template>

<script>
import TopNavBar from '../common/TopNavBar.vue';

export default {
    name: 'base-nav-layout',
    components: {
        TopNavBar,
    },
    props: {
        homePath: [String, Object],
    },
    data() {
        return {
            isDrawerOpen: null,
        };
    },
    methods: {
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
.main {
    height: 100%;
}

.sidebar-container {
    height: 100%;
    background-color: $bg-dark-color;

    @media only screen and (min-width: 1100px) {
        height: calc(100% - 24px);
    }
}
</style>
