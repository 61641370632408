import { render, staticRenderFns } from "./AccountSettingsPassword.vue?vue&type=template&id=b2aafcb0&scoped=true&"
import script from "./AccountSettingsPassword.vue?vue&type=script&lang=js&"
export * from "./AccountSettingsPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2aafcb0",
  null
  
)

export default component.exports