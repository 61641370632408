<template>
    <v-menu transition="scale-transition" origin="top right" bottom left>
        <template #activator="{ on }">
            <v-btn :class="{ 'language-switch-dark': dark }" flat v-on="on">
                <v-icon small>fa-globe</v-icon>
                <span class="ml-2 language-name">{{ currentLanguage.name }}</span>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-tile v-for="lang in languages" :key="lang.locale" @click="changeLanguage(lang.locale)" active-class="font-weight-black">
                <v-list-tile-title>
                    {{ lang.name }}
                </v-list-tile-title>
            </v-list-tile>
        </v-list>
    </v-menu>
</template>

<script>
import { getSupportedLanguages, i18n, loadLanguageAsync } from '../../i18n/i18n';

export default {
    name: 'language-switch',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            languages: getSupportedLanguages(),
            currentLocale: window.localStorage.getItem('locale') || i18n.locale,
        };
    },
    computed: {
        currentLanguage() {
            return getSupportedLanguages().find(lang => lang.locale === this.currentLocale);
        },
    },
    methods: {
        async changeLanguage(locale) {
            this.currentLocale = await loadLanguageAsync(locale);
        },
    },
};
</script>

<style lang="scss" scoped>
.language-name {
    text-transform: none;
}

.language-switch-dark {
    color: #fff !important;
}
</style>
