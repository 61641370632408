<template>
    <table-heatmap-graph
        :title="$t('categoryHeatmap.title')"
        :data="items"
        :loading="loading"
        :error="error"
        has-export
        @redo="getData(true)"
        @export="exportData">
        <template #filters>
            <v-flex shrink>
                <v-select
                    class="d-inline-block ml-3 mb-3 select--responsive"
                    v-model="dataType"
                    @change="getData()"
                    :items="dataTypeOptions"
                    :disabled="loading"
                    placeholder=" "
                    :label="$t('categoryHeatmap.dataType')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
        </template>
    </table-heatmap-graph>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import numberService from '../../../../services/numberService';
import exportService from '../../../../services/exportService';
import TableHeatmapGraph from '../../../graphs/TableHeatmapGraph.vue';
import eventBus from '../../../../services/eventBus';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';

export default {
    name: 'category-heatmap',
    components: {
        TableHeatmapGraph,
    },
    mixins: [campaignFilterMixin],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            region: process.env.VUE_APP_REGION_MODE,
            items: null,
            data: null,
            dataType: 'impressions',
            error: null,
        };
    },
    computed: {
        loading() {
            return this.heatmapLoading;
        },
        dataTypeOptions() {
            const options = [
                {
                    value: 'impressions',
                    text: this.$t('categoryHeatmap.impressionsOffer'),
                },
                {
                    value: 'clicks',
                    text: this.$t('categoryHeatmap.clicks'),
                },
            ];

            if (this.region !== 'PL') {
                options.push({
                    value: 'quantity',
                    text: this.$t('categoryHeatmap.quantity'),
                });
                options.push({
                    value: 'amount',
                    text: this.$t('categoryHeatmap.amount'),
                });
            }

            return options;
        },
        ...mapState({
            heatmapLoading: state => state.reportsCategory.loading.heatmap,
        }),
        ...mapGetters({
            getterHeatmap: 'reportsCategory/getHeatmap',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (newValue.startDate !== oldValue.startDate || newValue.endDate !== oldValue.endDate) {
                this.getData();
            }
        },
    },
    created() {
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.dataType = 'impressions';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.dataType === 'impressions';
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getHeatmapChart(force);
        },
        setItems() {
            if (!this.data || !this.data.categories || this.data.categories.length === 0 || !this.data.heatmap || this.data.heatmap.length === 0) {
                this.items = null;
                return;
            }

            const columns = this.data.categories.map(category => ({ type: 'number', label: category, id: category }));
            const parsedHeatmap = this.data.heatmap.map(heatMapItem => {
                let rowTotal = 0;
                const parsedRow = heatMapItem.map((item, index) => {
                    if (index === 0) {
                        return item;
                    }
                    const parsedValue = Number.parseFloat(item);
                    if (!Number.isNaN(parsedValue) && parsedValue > 0) {
                        rowTotal += parsedValue;
                    }
                    return {
                        v: item,
                        f: this.dataType === 'amount' ? numberService.formatCurrency(item) : numberService.formatNumber(item),
                    };
                });
                parsedRow.push({
                    v: rowTotal,
                    f: this.dataType === 'amount' ? numberService.formatCurrency(rowTotal) : numberService.formatNumber(rowTotal),
                });
                return parsedRow;
            });

            this.items = [
                [
                    { type: 'string', label: this.$t('categoryHeatmap.category'), id: this.$t('categoryHeatmap.category') },
                    ...columns,
                    { type: 'number', label: this.$t('categoryHeatmap.total'), id: this.$t('categoryHeatmap.total') },
                ],
                ...parsedHeatmap,
            ];
        },
        async getHeatmapChart(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                await this.fetchHeatmap({
                    force,
                    startDate,
                    endDate,
                    dataType: this.dataType,
                });
                this.data = this.getterHeatmap(startDate, endDate, this.dataType);
                this.setItems();
            } catch (e) {
                this.error = e.message;
                this.data = null;
                this.setItems();
            }
        },
        exportData() {
            const csvData = this.items.map((row, itemIndex) => row.map((rowItem, rowIndex) => {
                if (itemIndex === 0) {
                    return `"${exportService.parseText(rowItem.label)}"`;
                }
                if (rowIndex === 0) {
                    return `"${exportService.parseText(rowItem)}"`;
                }
                return `"${numberService.formatNumber(rowItem.v, 2, undefined, true)}"`;
            }));

            csvData[0] = [`${this.$t('dataFor')}`, `${this.$t('exportingOn')}`, `${this.$t('selectedStores')}`, ...csvData[0]];

            csvData[1] = [
                `${this.dateFilter.startDate} - ${this.dateFilter.endDate}`, // Date range
                `${new Date().toLocaleDateString()}`, // Current date
                `${this.campaign.map(id => this.getCampaignNameFromId(id)).join(', ')}`, // Campaigns
                ...csvData[1],
            ];

            for (let i = 2; i < csvData.length; i += 1) {
                csvData[i] = ['', '', '', ...csvData[i]];
            }

            const maxColumns = csvData.reduce((max, row) => Math.max(max, row.length), 0);

            for (let i = 0; i < csvData.length; i += 1) {
                while (csvData[i].length < maxColumns) {
                    csvData[i].push('');
                }
            }

            exportService.exportDataCsv(csvData, this.$t('categoryHeatmap.title'));
        },
        ...mapActions({
            fetchHeatmap: 'reportsCategory/fetchHeatmap',
        }),
    },
};
</script>

<style lang="scss" scoped></style>
