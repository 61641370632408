<template>
    <div
        class="change-label"
        :class="{'primary--text': changeDirection === 'up', 'secondary--text': changeDirection === 'down'}">
        <v-icon
            v-if="changeDirection"
            :class="{'primary--text': changeDirection === 'up', 'secondary--text': changeDirection === 'down'}"
            small
        >
            {{ changeDirectionIcon }}
        </v-icon>
        {{ formattedChange }}
    </div>
</template>

<script>
import numberService from '../../services/numberService';

export default {
    name: 'change-label',
    props: {
        change: {
            type: Number,
        },
        changeType: {
            validator: value => ['decimal', 'percent'].includes(value),
            default: 'percent',
        },
    },
    computed: {
        changeDirection() {
            if (!this.change || this.change === 0 || Number.isNaN(Number.parseInt(this.change, 10))) {
                return null;
            }
            return this.change > 0 ? 'up' : 'down';
        },
        changeDirectionIcon() {
            if (!this.changeDirection) {
                return null;
            }
            return this.changeDirection === 'up' ? 'fa-caret-up' : 'fa-caret-down';
        },
        formattedChange() {
            return numberService.formatChange(this.change, this.changeType);
        },
    },
};
</script>

<style lang="scss" scoped>
    .change-label {
        font-size: 12px;
        white-space: nowrap;
    }
</style>
