import {
    cloneDeep,
    deburr, difference, sortBy, uniq,
} from 'lodash';
import productsService from '../../services/productsService';
import utilsService from '../../services/utilsService';
import numberService from '../../services/numberService';
import dateService from '../../services/dateService';
import {
    CLEAN_PRODUCTS,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCT_DETAILS_REQUEST,
    FETCH_PRODUCT_DETAILS_SUCCESS,
    FETCH_PRODUCT_DETAILS_FAILURE,
    FETCH_PRODUCT_CLICKS_STATS_REQUEST,
    FETCH_PRODUCT_CLICKS_STATS_SUCCESS,
    FETCH_PRODUCT_CLICKS_STATS_FAILURE,
    FETCH_PRODUCT_SALES_STATS_REQUEST,
    FETCH_PRODUCT_SALES_STATS_SUCCESS,
    FETCH_PRODUCT_SALES_STATS_FAILURE,
    FETCH_PRODUCT_IMPRESSIONS_STATS_REQUEST,
    FETCH_PRODUCT_IMPRESSIONS_STATS_SUCCESS,
    FETCH_PRODUCT_IMPRESSIONS_STATS_FAILURE,
    FETCH_PRODUCT_SALES_CHART_REQUEST,
    FETCH_PRODUCT_SALES_CHART_SUCCESS,
    FETCH_PRODUCT_SALES_CHART_FAILURE,
    FETCH_PRODUCT_TRAFFIC_CHART_REQUEST,
    FETCH_PRODUCT_TRAFFIC_CHART_SUCCESS,
    FETCH_PRODUCT_TRAFFIC_CHART_FAILURE,
    FETCH_PRODUCT_PRICE_DISTRIBUTION_REQUEST,
    FETCH_PRODUCT_PRICE_DISTRIBUTION_SUCCESS,
    FETCH_PRODUCT_PRICE_DISTRIBUTION_FAILURE,
    FETCH_PRODUCT_AVAILABILITY_REQUEST,
    FETCH_PRODUCT_AVAILABILITY_SUCCESS,
    FETCH_PRODUCT_AVAILABILITY_FAILURE,
    FETCH_PRODUCT_CATEGORY_AVAILABILITY_REQUEST,
    FETCH_PRODUCT_CATEGORY_AVAILABILITY_SUCCESS,
    FETCH_PRODUCT_CATEGORY_AVAILABILITY_FAILURE,
    FETCH_SEARCH_PRODUCTS_REQUEST,
    FETCH_SEARCH_PRODUCTS_SUCCESS,
    FETCH_SEARCH_PRODUCTS_FAILURE,
    FETCH_FIND_PRODUCTS_REQUEST,
    FETCH_FIND_PRODUCTS_SUCCESS,
    FETCH_FIND_PRODUCTS_FAILURE,
    FETCH_PRODUCT_OFFERS_REQUEST,
    FETCH_PRODUCT_OFFERS_SUCCESS,
    FETCH_PRODUCT_OFFERS_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
} from '../mutationTypes';

const initialState = {
    productsIdsByParentId: {
        root: [],
    },
    products: {},
    productDetails: {},
    productClicksStats: {},
    productSalesStats: {},
    productImpressionsStats: {},
    productSalesChart: {},
    productTrafficChart: {},
    productPriceDistribution: {},
    productAvailability: {},
    categoryAvailability: {},
    displayedOffers: {},
    nonDisplayedOffers: {},
    loading: {
        rootProducts: false,
        products: false,
        searchProducts: false,
        findProducts: {},
        productDetails: false,
        clicksStats: false,
        salesStats: false,
        impressionsStats: false,
        salesChart: false,
        trafficChart: false,
        priceDistribution: false,
        productAvailability: false,
        categoryAvailability: false,
        productDisplayedOffers: false,
        productNonDisplayedOffers: false,
        deleteProducts: [],
        updateProducts: [],
        createProduct: false,
    },
};

const getChildren = (state, parent, onlyCategories) => {
    let children;
    if (parent.children) {
        if (state.productsIdsByParentId[parent.id]) {
            const productsIdsByParentId = onlyCategories
                ? state.productsIdsByParentId[parent.id].filter(prodId => state.products[prodId].type === 'category')
                : state.productsIdsByParentId[parent.id];
            children = productsIdsByParentId.map(prodId => ({ ...state.products[prodId], items: getChildren(state, state.products[prodId], onlyCategories) }));
        } else {
            children = [];
        }
    }
    return children;
};

const getters = {
    getCategoryTree: state => state.productsIdsByParentId.root.map(categoryId => {
        const category = { ...state.products[categoryId] };
        category.items = getChildren(state, category, true);
        return category;
    }),
    getProductsTree: state => state.productsIdsByParentId.root.map(categoryId => {
        const category = { ...state.products[categoryId] };
        category.items = getChildren(state, category);
        return category;
    }),
    getProduct: state => productId => state.products[productId],
    getProductDetails: state => productId => state.productDetails[productId],
    getProductClicksStats: state => (productId, startDate, endDate, compareStartDate, compareEndDate) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
        return state.productClicksStats[productId] && state.productClicksStats[productId][dateRangeKey];
    },
    getProductSalesStats: state => (productId, startDate, endDate, compareStartDate, compareEndDate) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
        return state.productSalesStats[productId] && state.productSalesStats[productId][dateRangeKey];
    },
    getProductImpressionsStats: state => (productId, startDate, endDate, compareStartDate, compareEndDate) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
        return state.productImpressionsStats[productId] && state.productImpressionsStats[productId][dateRangeKey];
    },
    // eslint-disable-next-line no-shadow
    getProductCTRStats: (state, getters) => (productId, startDate, endDate, compareStartDate, compareEndDate) => {
        const clicksStats = getters.getProductClicksStats(productId, startDate, endDate, compareStartDate, compareEndDate);
        const impressionsStats = getters.getProductImpressionsStats(productId, startDate, endDate, compareStartDate, compareEndDate);
        if (!clicksStats || !impressionsStats) {
            return null;
        }
        const ctr = impressionsStats.impressions ? clicksStats.clicks / impressionsStats.impressions * 100 : 0;
        const ctrCompare = impressionsStats.impressionsCompare ? clicksStats.clicksCompare / impressionsStats.impressionsCompare * 100 : 0;
        const ctrChange = numberService.calculateChangePercentage(ctr, ctrCompare);
        return {
            ctr: Number.isNaN(ctr) ? null : ctr,
            ctrChange,
        };
    },

    // eslint-disable-next-line no-shadow
    getProductCRStats: (state, getters) => (productId, startDate, endDate, compareStartDate, compareEndDate) => {
        const clicksStats = getters.getProductClicksStats(productId, startDate, endDate, compareStartDate, compareEndDate);
        const salesStats = getters.getProductSalesStats(productId, startDate, endDate, compareStartDate, compareEndDate);
        if (!clicksStats || !salesStats) {
            return null;
        }
        const cr = clicksStats.clicks ? salesStats.quantity / clicksStats.clicks * 100 : 0;
        const crCompare = clicksStats.clicksCompare ? salesStats.quantityCompare / clicksStats.clicksCompare * 100 : 0;
        const crChange = numberService.calculateChangePercentage(cr, crCompare);
        return {
            cr: Number.isNaN(cr) ? null : cr,
            crChange,
        };
    },
    getProductSalesChart: state => (productId, startDate, endDate, campaign) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
        return state.productSalesChart[productId][dateRangeKey];
    },
    getProductTrafficChart: state => (productId, startDate, endDate, campaign) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
        return state.productTrafficChart[productId][dateRangeKey];
    },
    getProductPriceDistribution: state => (productId, startDate, endDate, pluginCampaigns) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns);
        return state.productPriceDistribution[productId][dateRangeKey];
    },
    getProductAvailability: state => (productId, startDate, endDate, pluginCampaigns, campaign) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns, ...(campaign ?? []));
        return state.productAvailability[productId][dateRangeKey];
    },
    getCategoryAvailability: state => (productId, startDate, endDate, pluginCampaigns) => {
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns);
        return state.categoryAvailability[productId][dateRangeKey];
    },
    getProductOffers: state => (productId, pluginCampaigns) => {
        const key = pluginCampaigns === 'in' ? 'displayedOffers' : 'nonDisplayedOffers';
        return state[key][productId];
    },
};

const actions = {
    cleanProducts({ commit }) {
        commit(CLEAN_PRODUCTS);
    },

    async fetchProducts({
        commit, state, rootState, dispatch,
    }, payload = {}) {
        try {
            const {
                force, categoryId = null, entityId,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if (force && !categoryId) {
                dispatch('cleanProducts');
            }

            if (!force && !categoryId && state.productsIdsByParentId.root && state.productsIdsByParentId.root.length > 0) {
                return;
            }

            if (!force && categoryId && state.productsIdsByParentId[categoryId] && state.productsIdsByParentId[categoryId].length > 0) {
                return;
            }

            commit(FETCH_PRODUCTS_REQUEST, {
                categoryId,
            });
            const products = await productsService.fetchProducts(spaceId, entityId);

            commit(FETCH_PRODUCTS_SUCCESS, {
                categoryId,
                products,
            });
        } catch (e) {
            commit(FETCH_PRODUCTS_FAILURE);
            throw e;
        }
    },

    async fetchSearchProducts({
        commit, rootState, dispatch,
    }, payload = {}) {
        try {
            const { search } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId) {
                return;
            }

            dispatch('cleanProducts');

            commit(FETCH_SEARCH_PRODUCTS_REQUEST);
            const products = await productsService.fetchSearchProducts(spaceId, search);

            commit(FETCH_SEARCH_PRODUCTS_SUCCESS, {
                products,
            });
        } catch (e) {
            commit(FETCH_SEARCH_PRODUCTS_FAILURE);
            throw e;
        }
    },

    async fetchFindProducts({ commit, rootState }, payload = {}) {
        const { componentId, search, publisherId } = payload;
        try {
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId && !publisherId) {
                return undefined;
            }

            commit(FETCH_FIND_PRODUCTS_REQUEST, { componentId });
            const searchResult = await productsService.fetchFindProducts(spaceId, search, publisherId);
            let products = [];
            if (publisherId) {
                products = searchResult.map(product => ({
                    __raw: product,
                    id: `product-${product.id}`,
                    text: product.name,
                    entityId: product.id,
                    offerId: product.offerId,
                    img: product.image,
                }));
            } else {
                products = searchResult.items.map(product => ({
                    __raw: product,
                    id: `product-${product.id}`,
                    text: product.name,
                    entityId: product.id,
                    productId: product.productId,
                    ean: product.ean,
                    sku: product.sku,
                    img: product.images && product.images[0],
                    categories: product.categories || [],
                }));
            }

            commit(FETCH_FIND_PRODUCTS_SUCCESS, { componentId });
            return products;
        } catch (e) {
            commit(FETCH_FIND_PRODUCTS_FAILURE, { componentId });
            throw e;
        }
    },

    async fetchProductDetails({ commit, state }, payload = {}) {
        try {
            const { force, productId, entityId } = payload;

            if (!force && state.productDetails[productId]) {
                return;
            }

            commit(FETCH_PRODUCT_DETAILS_REQUEST);
            const details = await productsService.fetchProductDetails(entityId);

            commit(FETCH_PRODUCT_DETAILS_SUCCESS, {
                productId,
                details,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_DETAILS_FAILURE);
            throw e;
        }
    },

    async fetchClicksStats({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, type, startDate, endDate, compareStartDate, compareEndDate,
            } = payload;

            if (!startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
            if (!force && state.productClicksStats[productId] && state.productClicksStats[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_CLICKS_STATS_REQUEST);
            let clicksStats;
            if (type === 'product') {
                clicksStats = await productsService.fetchProductClicksStats(entityId, startDate, endDate, compareStartDate, compareEndDate);
            } else {
                clicksStats = await productsService.fetchCategoryClicksStats(entityId, startDate, endDate, compareStartDate, compareEndDate);
            }

            commit(FETCH_PRODUCT_CLICKS_STATS_SUCCESS, {
                productId,
                clicksStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_CLICKS_STATS_FAILURE);
            throw e;
        }
    },

    async fetchSalesStats({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, type, startDate, endDate, compareStartDate, compareEndDate,
            } = payload;

            if (!startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
            if (!force && state.productSalesStats[productId] && state.productSalesStats[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_SALES_STATS_REQUEST);
            let salesStats;
            if (type === 'product') {
                salesStats = await productsService.fetchProductSalesStats(entityId, startDate, endDate, compareStartDate, compareEndDate);
            } else {
                salesStats = await productsService.fetchCategorySalesStats(entityId, startDate, endDate, compareStartDate, compareEndDate);
            }

            commit(FETCH_PRODUCT_SALES_STATS_SUCCESS, {
                productId,
                salesStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_SALES_STATS_FAILURE);
            throw e;
        }
    },

    async fetchImpressionsStats({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, type, startDate, endDate, compareStartDate, compareEndDate,
            } = payload;

            if (!startDate || !endDate || !compareStartDate || !compareEndDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
            if (!force && state.productImpressionsStats[productId] && state.productImpressionsStats[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_IMPRESSIONS_STATS_REQUEST);
            let impressionsStats;
            if (type === 'product') {
                impressionsStats = await productsService.fetchProductImpressionsStats(entityId, startDate, endDate, compareStartDate, compareEndDate);
            } else {
                impressionsStats = await productsService.fetchCategoryImpressionsStats(entityId, startDate, endDate, compareStartDate, compareEndDate);
            }

            commit(FETCH_PRODUCT_IMPRESSIONS_STATS_SUCCESS, {
                productId,
                impressionsStats,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_IMPRESSIONS_STATS_FAILURE);
            throw e;
        }
    },

    async fetchSalesChart({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, type, startDate, endDate, campaign,
            } = payload;

            if (!startDate || !endDate) {
                return;
            }

            campaign.sort();
            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
            if (!force && state.productSalesChart[productId] && state.productSalesChart[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_SALES_CHART_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            let salesChartData;
            if (type === 'product') {
                salesChartData = await productsService.fetchProductSalesChart(entityId, startDate, endDate, reqCampaign);
            } else {
                salesChartData = await productsService.fetchCategorySalesChart(entityId, startDate, endDate, reqCampaign);
            }

            const missingDates = dateService.findMissingDates(salesChartData, startDate, endDate);
            salesChartData = utilsService.fillArray(salesChartData, missingDates, 'date', [{ key: 'amount', value: 0 }, { key: 'quantity', value: 0 }]);
            salesChartData = sortBy(salesChartData, ['date']);

            commit(FETCH_PRODUCT_SALES_CHART_SUCCESS, {
                productId,
                salesChartData,
                startDate,
                endDate,
                campaign,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_SALES_CHART_FAILURE);
            throw e;
        }
    },

    async fetchTrafficChart({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, type, startDate, endDate, campaign,
            } = payload;

            if (!startDate || !endDate) {
                return;
            }

            campaign.sort();
            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);
            if (!force && state.productTrafficChart[productId] && state.productTrafficChart[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_TRAFFIC_CHART_REQUEST);
            const reqCampaign = campaign.length > 0 ? campaign.join(',') : null;
            let trafficChartData;
            if (type === 'product') {
                trafficChartData = await productsService.fetchProductTrafficChart(entityId, startDate, endDate, reqCampaign);
            } else {
                trafficChartData = await productsService.fetchCategoryTrafficChart(entityId, startDate, endDate, reqCampaign);
            }

            const missingDates = dateService.findMissingDates(trafficChartData, startDate, endDate);
            trafficChartData = utilsService.fillArray(trafficChartData, missingDates, 'date', [{ key: 'clicks', value: 0 }, { key: 'impressions', value: 0 }]);
            trafficChartData = sortBy(trafficChartData, ['date']);

            commit(FETCH_PRODUCT_TRAFFIC_CHART_SUCCESS, {
                productId,
                trafficChartData,
                startDate,
                endDate,
                campaign,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_TRAFFIC_CHART_FAILURE);
            throw e;
        }
    },

    async fetchPriceDistribution({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, type, pluginCampaigns, startDate, endDate,
            } = payload;

            if (!startDate || !endDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns);
            if (!force && state.productPriceDistribution[productId] && state.productPriceDistribution[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_PRICE_DISTRIBUTION_REQUEST);
            const reqPluginCampaigns = pluginCampaigns === 'all' ? null : pluginCampaigns;
            let priceDistributionData;
            if (type === 'product') {
                priceDistributionData = await productsService.fetchProductPriceDistribution(entityId, startDate, endDate, reqPluginCampaigns);
            } else {
                priceDistributionData = await productsService.fetchCategoryPriceDistribution(entityId, startDate, endDate, reqPluginCampaigns);
            }

            let campaigns = [];
            let chartData = [];

            if (priceDistributionData && priceDistributionData.length > 0) {
                // get unique campaigns
                campaigns = priceDistributionData
                    .reduce((acc, row) => uniq([...acc, ...Object.keys(row)]), [])
                    .filter(item => item !== 'date');
                campaigns = sortBy(campaigns, campaign => deburr(campaign.toLowerCase()));
            }

            if (campaigns.length > 0) {
                // fill missing campaigns on existing data
                chartData = priceDistributionData.map(row => {
                    const missingData = {};
                    const missingCampaigns = difference(campaigns, Object.keys(row));
                    if (missingCampaigns.length > 0) {
                        missingCampaigns.forEach(campaign => {
                            missingData[campaign] = 0;
                        });
                    }
                    return { ...row, ...missingData };
                });

                // fill missing dates
                const additionalProperties = campaigns.map(campaign => ({ key: campaign, value: null }));
                const missingDates = dateService.findMissingDates(chartData, startDate, endDate);
                chartData = utilsService.fillArray(chartData, missingDates, 'date', additionalProperties);
                chartData = sortBy(chartData, ['date']);
            }

            commit(FETCH_PRODUCT_PRICE_DISTRIBUTION_SUCCESS, {
                productId,
                campaigns,
                chartData,
                startDate,
                endDate,
                pluginCampaigns,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_PRICE_DISTRIBUTION_FAILURE);
            throw e;
        }
    },

    async fetchProductAvailability({ commit, state, rootState }, payload = {}) {
        try {
            const {
                force, productId, entityId, globalEntityId, pluginCampaigns, campaign, startDate, endDate,
            } = payload;
            const spaceId = rootState.space.currentSpaceId;

            if (!spaceId || !startDate || !endDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns, ...(campaign ?? []));
            if (!force && state.productAvailability[productId] && state.productAvailability[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_AVAILABILITY_REQUEST);
            const reqPluginCampaigns = pluginCampaigns === 'all' || (campaign && campaign.length > 0) ? null : pluginCampaigns;
            const reqCampaign = campaign && campaign.length > 0 ? campaign.join(',') : null;
            const productAvailabilityData = globalEntityId
                ? await productsService.fetchGlobalProductAvailability(spaceId, globalEntityId, startDate, endDate, reqPluginCampaigns, reqCampaign)
                : await productsService.fetchProductAvailability(entityId, startDate, endDate, reqPluginCampaigns, reqCampaign);

            commit(FETCH_PRODUCT_AVAILABILITY_SUCCESS, {
                productId,
                productAvailabilityData,
                startDate,
                endDate,
                pluginCampaigns,
                campaign,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_AVAILABILITY_FAILURE);
            throw e;
        }
    },

    async fetchCategoryAvailability({ commit, state }, payload = {}) {
        try {
            const {
                force, productId, entityId, pluginCampaigns, startDate, endDate,
            } = payload;

            if (!startDate || !endDate) {
                return;
            }

            const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns);
            if (!force && state.categoryAvailability[productId] && state.categoryAvailability[productId][dateRangeKey]) {
                return;
            }

            commit(FETCH_PRODUCT_CATEGORY_AVAILABILITY_REQUEST);
            const reqPluginCampaigns = pluginCampaigns === 'all' ? null : pluginCampaigns;
            const categoryAvailabilityData = await productsService.fetchCategoryAvailability(entityId, startDate, endDate, reqPluginCampaigns);

            let campaigns = [];
            let chartData = [];

            if (categoryAvailabilityData && categoryAvailabilityData.length > 0) {
                // get unique campaigns
                campaigns = categoryAvailabilityData
                    .reduce((acc, row) => uniq([...acc, ...Object.keys(row)]), [])
                    .filter(item => item !== 'date');
                campaigns = sortBy(campaigns, campaign => deburr(campaign.toLowerCase()));
            }

            if (campaigns.length > 0) {
                // fill missing campaigns on existing data
                chartData = categoryAvailabilityData.map(row => {
                    const missingData = {};
                    const missingCampaigns = difference(campaigns, Object.keys(row));
                    if (missingCampaigns.length > 0) {
                        missingCampaigns.forEach(campaign => {
                            missingData[campaign] = 0;
                        });
                    }
                    return { ...row, ...missingData };
                });

                // fill missing dates
                const additionalProperties = campaigns.map(campaign => ({ key: campaign, value: 0 }));
                const missingDates = dateService.findMissingDates(chartData, startDate, endDate);
                chartData = utilsService.fillArray(chartData, missingDates, 'date', additionalProperties);
                chartData = sortBy(chartData, ['date']);
            }

            commit(FETCH_PRODUCT_CATEGORY_AVAILABILITY_SUCCESS, {
                productId,
                campaigns,
                chartData,
                startDate,
                endDate,
                pluginCampaigns,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_CATEGORY_AVAILABILITY_FAILURE);
            throw e;
        }
    },

    async fetchProductOffers({ commit, state }, payload = {}) {
        const {
            force, productId, entityId, pluginOffers,
        } = payload;
        try {
            if (!productId || !entityId || !pluginOffers) {
                return;
            }

            const key = pluginOffers === 'in' ? 'displayedOffers' : 'nonDisplayedOffers';
            if (!force && state[key][productId]) {
                return;
            }

            commit(FETCH_PRODUCT_OFFERS_REQUEST, { pluginOffers });
            const offers = await productsService.fetchProductOffers(entityId, pluginOffers);

            commit(FETCH_PRODUCT_OFFERS_SUCCESS, {
                productId,
                offers: offers.items,
                pluginOffers,
            });
        } catch (e) {
            commit(FETCH_PRODUCT_OFFERS_FAILURE, { pluginOffers });
            throw e;
        }
    },

    async deleteProduct({ commit }, payload = {}) {
        const { entityId } = payload;
        try {
            if (!entityId) {
                return;
            }

            commit(DELETE_PRODUCT_REQUEST, { entityId });
            await productsService.deleteProduct(entityId);

            commit(DELETE_PRODUCT_SUCCESS, { entityId });
        } catch (e) {
            commit(DELETE_PRODUCT_FAILURE, { entityId });
            throw e;
        }
    },

    async updateProduct({ commit }, payload = {}) {
        const { entityId, data } = payload;
        try {
            if (!entityId || !data) {
                return;
            }

            commit(UPDATE_PRODUCT_REQUEST, { entityId });
            const response = await productsService.updateProduct(entityId, data);

            commit(UPDATE_PRODUCT_SUCCESS, { entityId, data: response });

            return response; // eslint-disable-line consistent-return
        } catch (e) {
            commit(UPDATE_PRODUCT_FAILURE, { entityId });
            throw e;
        }
    },

    async createProduct({ rootState, commit }, payload = {}) {
        const { data } = payload;
        try {
            const spaceId = rootState.space.currentSpaceId;
            if (!spaceId || !data) {
                return;
            }

            commit(CREATE_PRODUCT_REQUEST);
            const newProduct = await productsService.createProduct(spaceId, data);

            commit(CREATE_PRODUCT_SUCCESS, { newProduct });
        } catch (e) {
            commit(CREATE_PRODUCT_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_PRODUCTS
    // -----------------------------------------
    [CLEAN_PRODUCTS](state) {
        state.productsIdsByParentId = {
            root: [],
        };
        state.products = {};
        state.productDetails = {};
        state.productClicksStats = {};
        state.productSalesStats = {};
        state.productImpressionsStats = {};
        state.productSalesChart = {};
        state.productTrafficChart = {};
        state.productPriceDistribution = {};
        state.productAvailability = {};
        state.categoryAvailability = {};
        state.displayedOffers = {};
        state.nonDisplayedOffers = {};
        state.loading.rootProducts = false;
        state.loading.products = false;
        state.loading.searchProducts = false;
        state.loading.findProducts = {};
        state.loading.clicksStats = false;
        state.loading.salesStats = false;
        state.loading.impressionsStats = false;
        state.loading.salesChart = false;
        state.loading.trafficChart = false;
        state.loading.priceDistribution = false;
        state.loading.productAvailability = false;
        state.loading.categoryAvailability = false;
        state.loading.productDisplayedOffers = false;
        state.loading.productNonDisplayedOffers = false;
        state.loading.deleteProducts = [];
        state.loading.updateProducts = [];
        state.loading.createProduct = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCTS
    // -----------------------------------------
    [FETCH_PRODUCTS_REQUEST](state, payload) {
        const { categoryId } = payload;
        if (!categoryId) {
            state.loading.rootProducts = true;
        } else {
            state.loading.products = true;
        }
    },
    [FETCH_PRODUCTS_SUCCESS](state, payload) {
        const { categoryId, products } = payload;
        const productsIdsByParentId = {};
        const productsById = {};
        if (products && products.length > 0) {
            products.forEach(prod => {
                const parentId = categoryId || 'root';
                if (!productsIdsByParentId[parentId]) {
                    productsIdsByParentId[parentId] = [];
                }
                productsIdsByParentId[parentId].push(prod.id);
                productsById[prod.id] = prod;

                if (prod.items && prod.items.length > 0) {
                    prod.items.forEach(prodItem => {
                        if (!productsIdsByParentId[prod.id]) {
                            productsIdsByParentId[prod.id] = [];
                        }
                        productsIdsByParentId[prod.id].push(prodItem.id);
                        productsById[prodItem.id] = prodItem;
                    });
                }
            });
        } else {
            productsIdsByParentId.root = [];
        }

        const productsIdsByParentIdFinal = categoryId ? { ...state.productsIdsByParentId } : {};
        const productsBase = categoryId ? { ...state.products } : {};

        Object.keys(productsIdsByParentId).forEach(key => {
            productsIdsByParentIdFinal[key] = productsIdsByParentId[key];
        });
        state.productsIdsByParentId = productsIdsByParentIdFinal;
        state.products = { ...productsBase, ...productsById };
        state.loading.rootProducts = false;
        state.loading.products = false;
    },
    [FETCH_PRODUCTS_FAILURE](state) {
        state.loading.rootProducts = false;
        state.loading.products = false;
    },

    // -----------------------------------------
    // FETCH_SEARCH_PRODUCTS
    // -----------------------------------------
    [FETCH_SEARCH_PRODUCTS_REQUEST](state) {
        state.loading.searchProducts = true;
    },
    [FETCH_SEARCH_PRODUCTS_SUCCESS](state, payload) {
        const { products } = payload;
        const productsIdsByParentId = {};
        const productsById = {};

        const parseProduct = (product, parentId) => {
            if (!productsIdsByParentId[parentId]) {
                productsIdsByParentId[parentId] = [];
            }
            const { items, ...prod } = product;
            productsIdsByParentId[parentId].push(prod.id);
            productsById[prod.id] = prod;
            if (items && items.length > 0) {
                items.forEach(prodItem => {
                    parseProduct(prodItem, prod.id);
                });
            }
        };

        if (products && products.length > 0) {
            products.forEach(prod => {
                parseProduct(prod, 'root');
            });
        } else {
            productsIdsByParentId.root = [];
        }

        const productsIdsByParentIdUnique = {};
        Object.keys(productsIdsByParentId).forEach(key => {
            productsIdsByParentIdUnique[key] = uniq(productsIdsByParentId[key]);
        });
        state.productsIdsByParentId = productsIdsByParentIdUnique;
        state.products = productsById;
        state.loading.searchProducts = false;
    },
    [FETCH_SEARCH_PRODUCTS_FAILURE](state) {
        state.loading.searchProducts = false;
    },

    // -----------------------------------------
    // FETCH_FIND_PRODUCTS
    // -----------------------------------------
    [FETCH_FIND_PRODUCTS_REQUEST](state, payload) {
        const { componentId } = payload;
        state.loading.findProducts = {
            ...state.loading.findProducts,
            [componentId]: true,
        };
    },
    [FETCH_FIND_PRODUCTS_SUCCESS](state, payload) {
        const { componentId } = payload;
        state.loading.findProducts = {
            ...state.loading.findProducts,
            [componentId]: false,
        };
    },
    [FETCH_FIND_PRODUCTS_FAILURE](state, payload) {
        const { componentId } = payload;
        state.loading.findProducts = {
            ...state.loading.findProducts,
            [componentId]: false,
        };
    },

    // -----------------------------------------
    // FETCH_PRODUCT_DETAILS
    // -----------------------------------------
    [FETCH_PRODUCT_DETAILS_REQUEST](state) {
        state.loading.productDetails = true;
    },
    [FETCH_PRODUCT_DETAILS_SUCCESS](state, payload) {
        const {
            productId, details,
        } = payload;
        state.loading.productDetails = false;
        state.productDetails = {
            ...state.productDetails,
            [productId]: details,
        };
    },
    [FETCH_PRODUCT_DETAILS_FAILURE](state) {
        state.loading.productDetails = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_CLICKS_STATS
    // -----------------------------------------
    [FETCH_PRODUCT_CLICKS_STATS_REQUEST](state) {
        state.loading.clicksStats = true;
    },
    [FETCH_PRODUCT_CLICKS_STATS_SUCCESS](state, payload) {
        const {
            productId, clicksStats, startDate, endDate, compareStartDate, compareEndDate,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
        const clicks = parseFloat(clicksStats.clicks);
        const clicksCompare = parseFloat(clicksStats.clicks_compare);
        const clicksChange = numberService.calculateChangePercentage(clicks, clicksCompare);

        state.loading.clicksStats = false;
        state.productClicksStats = {
            ...state.productClicksStats,
            [productId]: {
                ...state.productClicksStats[productId],
                [dateRangeKey]: {
                    clicks: Number.isNaN(clicks) ? null : clicks,
                    clicksCompare: Number.isNaN(clicksCompare) ? null : clicksCompare,
                    clicksChange,
                },
            },
        };
    },
    [FETCH_PRODUCT_CLICKS_STATS_FAILURE](state) {
        state.loading.clicksStats = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_SALES_STATS
    // -----------------------------------------
    [FETCH_PRODUCT_SALES_STATS_REQUEST](state) {
        state.loading.salesStats = true;
    },
    [FETCH_PRODUCT_SALES_STATS_SUCCESS](state, payload) {
        const {
            productId, salesStats, startDate, endDate, compareStartDate, compareEndDate,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
        const quantity = parseFloat(salesStats.quantity);
        const quantityCompare = parseFloat(salesStats.quantity_compare);
        const quantityChange = numberService.calculateChangePercentage(quantity, quantityCompare);
        const amount = parseFloat(salesStats.amount);
        const amountCompare = parseFloat(salesStats.amount_compare);
        const amountChange = numberService.calculateChangePercentage(amount, amountCompare);
        const orders = parseFloat(salesStats.orders);
        const ordersCompare = parseFloat(salesStats.orders_compare);
        const ordersChange = numberService.calculateChangePercentage(orders, ordersCompare);

        state.loading.salesStats = false;
        state.productSalesStats = {
            ...state.productSalesStats,
            [productId]: {
                ...state.productSalesStats[productId],
                [dateRangeKey]: {
                    quantity: Number.isNaN(quantity) ? null : quantity,
                    quantityCompare: Number.isNaN(quantityCompare) ? null : quantityCompare,
                    quantityChange,
                    amount: Number.isNaN(amount) ? null : amount,
                    amountCompare: Number.isNaN(amountCompare) ? null : amountCompare,
                    amountChange,
                    orders: Number.isNaN(orders) ? null : orders,
                    ordersCompare: Number.isNaN(ordersCompare) ? null : ordersCompare,
                    ordersChange,
                },
            },
        };
    },
    [FETCH_PRODUCT_SALES_STATS_FAILURE](state) {
        state.loading.salesStats = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_IMPRESSIONS_STATS
    // -----------------------------------------
    [FETCH_PRODUCT_IMPRESSIONS_STATS_REQUEST](state) {
        state.loading.impressionsStats = true;
    },
    [FETCH_PRODUCT_IMPRESSIONS_STATS_SUCCESS](state, payload) {
        const {
            productId, impressionsStats, startDate, endDate, compareStartDate, compareEndDate,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, compareStartDate, compareEndDate);
        const impressions = parseFloat(impressionsStats.impressions);
        const impressionsCompare = parseFloat(impressionsStats.impressions_compare);
        const impressionsChange = numberService.calculateChangePercentage(impressions, impressionsCompare);

        state.loading.impressionsStats = false;
        state.productImpressionsStats = {
            ...state.productImpressionsStats,
            [productId]: {
                ...state.productImpressionsStats[productId],
                [dateRangeKey]: {
                    impressions: Number.isNaN(impressions) ? null : impressions,
                    impressionsCompare: Number.isNaN(impressionsCompare) ? null : impressionsCompare,
                    impressionsChange,
                },
            },
        };
    },
    [FETCH_PRODUCT_IMPRESSIONS_STATS_FAILURE](state) {
        state.loading.impressionsStats = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_SALES_CHART
    // -----------------------------------------
    [FETCH_PRODUCT_SALES_CHART_REQUEST](state) {
        state.loading.salesChart = true;
    },
    [FETCH_PRODUCT_SALES_CHART_SUCCESS](state, payload) {
        const {
            productId, salesChartData, startDate, endDate, campaign,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);

        state.loading.salesChart = false;
        state.productSalesChart = {
            ...state.productSalesChart,
            [productId]: {
                ...state.productSalesChart[productId],
                [dateRangeKey]: salesChartData,
            },
        };
    },
    [FETCH_PRODUCT_SALES_CHART_FAILURE](state) {
        state.loading.salesChart = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_TRAFFIC_CHART
    // -----------------------------------------
    [FETCH_PRODUCT_TRAFFIC_CHART_REQUEST](state) {
        state.loading.trafficChart = true;
    },
    [FETCH_PRODUCT_TRAFFIC_CHART_SUCCESS](state, payload) {
        const {
            productId, trafficChartData, startDate, endDate, campaign,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, ...campaign);

        state.loading.trafficChart = false;
        state.productTrafficChart = {
            ...state.productTrafficChart,
            [productId]: {
                ...state.productTrafficChart[productId],
                [dateRangeKey]: trafficChartData,
            },
        };
    },
    [FETCH_PRODUCT_TRAFFIC_CHART_FAILURE](state) {
        state.loading.trafficChart = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_PRICE_DISTRIBUTION
    // -----------------------------------------
    [FETCH_PRODUCT_PRICE_DISTRIBUTION_REQUEST](state) {
        state.loading.priceDistribution = true;
    },
    [FETCH_PRODUCT_PRICE_DISTRIBUTION_SUCCESS](state, payload) {
        const {
            productId, campaigns, chartData, startDate, endDate, pluginCampaigns,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns);

        state.loading.priceDistribution = false;
        state.productPriceDistribution = {
            ...state.productPriceDistribution,
            [productId]: {
                ...state.productPriceDistribution[productId],
                [dateRangeKey]: {
                    campaigns,
                    chart: chartData,
                },
            },
        };
    },
    [FETCH_PRODUCT_PRICE_DISTRIBUTION_FAILURE](state) {
        state.loading.priceDistribution = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_AVAILABILITY
    // -----------------------------------------
    [FETCH_PRODUCT_AVAILABILITY_REQUEST](state) {
        state.loading.productAvailability = true;
    },
    [FETCH_PRODUCT_AVAILABILITY_SUCCESS](state, payload) {
        const {
            productId, productAvailabilityData, startDate, endDate, pluginCampaigns, campaign,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns, ...(campaign ?? []));

        state.loading.productAvailability = false;
        state.productAvailability = {
            ...state.productAvailability,
            [productId]: {
                ...state.productAvailability[productId],
                [dateRangeKey]: productAvailabilityData,
            },
        };
    },
    [FETCH_PRODUCT_AVAILABILITY_FAILURE](state) {
        state.loading.productAvailability = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_CATEGORY_AVAILABILITY
    // -----------------------------------------
    [FETCH_PRODUCT_CATEGORY_AVAILABILITY_REQUEST](state) {
        state.loading.categoryAvailability = true;
    },
    [FETCH_PRODUCT_CATEGORY_AVAILABILITY_SUCCESS](state, payload) {
        const {
            productId, campaigns, chartData, startDate, endDate, pluginCampaigns,
        } = payload;
        const dateRangeKey = utilsService.getStorageKey(startDate, endDate, pluginCampaigns);

        state.loading.categoryAvailability = false;
        state.categoryAvailability = {
            ...state.categoryAvailability,
            [productId]: {
                ...state.categoryAvailability[productId],
                [dateRangeKey]: {
                    campaigns,
                    chart: chartData,
                },
            },
        };
    },
    [FETCH_PRODUCT_CATEGORY_AVAILABILITY_FAILURE](state) {
        state.loading.categoryAvailability = false;
    },

    // -----------------------------------------
    // FETCH_PRODUCT_OFFERS
    // -----------------------------------------
    [FETCH_PRODUCT_OFFERS_REQUEST](state, payload) {
        const { pluginOffers } = payload;
        if (pluginOffers === 'in') {
            state.loading.productDisplayedOffers = true;
        } else if (pluginOffers === 'notIn') {
            state.loading.productNonDisplayedOffers = true;
        }
    },
    [FETCH_PRODUCT_OFFERS_SUCCESS](state, payload) {
        const {
            productId, offers, pluginOffers,
        } = payload;
        const key = pluginOffers === 'in' ? 'displayedOffers' : 'nonDisplayedOffers';
        state[key] = {
            ...state[key],
            [productId]: offers,
        };

        if (pluginOffers === 'in') {
            state.loading.productDisplayedOffers = false;
        } else if (pluginOffers === 'notIn') {
            state.loading.productNonDisplayedOffers = false;
        }
    },
    [FETCH_PRODUCT_OFFERS_FAILURE](state, payload) {
        const { pluginOffers } = payload;
        if (pluginOffers === 'in') {
            state.loading.productDisplayedOffers = false;
        } else if (pluginOffers === 'notIn') {
            state.loading.productNonDisplayedOffers = false;
        }
    },

    // -----------------------------------------
    // DELETE_PRODUCT
    // -----------------------------------------
    [DELETE_PRODUCT_REQUEST](state, payload) {
        const { entityId } = payload;
        state.loading.deleteProducts.push(entityId);
    },
    [DELETE_PRODUCT_SUCCESS](state, payload) {
        const { entityId } = payload;
        const productsIdsByParentId = cloneDeep(state.productsIdsByParentId);
        Object.keys(productsIdsByParentId).forEach(categoryId => {
            const productIdx = productsIdsByParentId[categoryId].indexOf(`product-${entityId}`);
            if (productIdx !== -1) {
                productsIdsByParentId[categoryId].splice(productIdx, 1);
            }
        });
        state.productsIdsByParentId = productsIdsByParentId;
        state.loading.deleteProducts = state.loading.deleteProducts.filter(deletingId => deletingId !== entityId);
    },
    [DELETE_PRODUCT_FAILURE](state, payload) {
        const { entityId } = payload;
        state.loading.deleteProducts = state.loading.deleteProducts.filter(deletingId => deletingId !== entityId);
    },

    // -----------------------------------------
    // UPDATE_PRODUCT
    // -----------------------------------------
    [UPDATE_PRODUCT_REQUEST](state, payload) {
        const { entityId } = payload;
        state.loading.updateProducts.push(entityId);
    },
    [UPDATE_PRODUCT_SUCCESS](state, payload) {
        const { entityId, data } = payload;
        const { categories, ...productDetails } = data;
        const newCategoryId = `category-${categories[0]}`;
        const productId = `product-${entityId}`;
        state.products = {
            ...state.products,
            [productId]: {
                ...state.products[productId],
                text: productDetails.name,
            },
        };
        state.productDetails = {
            ...state.productDetails,
            [productId]: {
                ...state.productDetails[productId],
                ...productDetails,
            },
        };
        // check if category changed
        if (newCategoryId && !state.productsIdsByParentId[newCategoryId]?.includes(productId)) {
            const productsIdsByParentId = cloneDeep(state.productsIdsByParentId);
            // find previous parent category
            const oldCategory = Object.keys(productsIdsByParentId).find(parentId => productsIdsByParentId[parentId].includes(productId));
            // remove product from old category
            productsIdsByParentId[oldCategory] = (productsIdsByParentId[oldCategory] || []).filter(parentProductId => parentProductId !== productId);
            // add product to a new category only if that category was fetched before
            // otherwise it will prevent from pooling that category's children
            if (productsIdsByParentId[newCategoryId]) {
                productsIdsByParentId[newCategoryId].push(productId);
            }
            state.productsIdsByParentId = productsIdsByParentId;
        }
        state.loading.updateProducts = state.loading.updateProducts.filter(updatingId => updatingId !== entityId);
    },
    [UPDATE_PRODUCT_FAILURE](state, payload) {
        const { entityId } = payload;
        state.loading.updateProducts = state.loading.updateProducts.filter(updatingId => updatingId !== entityId);
    },

    // -----------------------------------------
    // CREATE_PRODUCT
    // -----------------------------------------
    [CREATE_PRODUCT_REQUEST](state) {
        state.loading.createProduct = true;
    },
    [CREATE_PRODUCT_SUCCESS](state, payload) {
        const { newProduct } = payload;
        const { categories } = newProduct;
        const categoryId = `category-${categories[0][categories[0].length - 1].id}`;
        const productId = `product-${newProduct.id}`;
        state.products = {
            ...state.products,
            [productId]: {
                children: false,
                entityId: newProduct.id,
                id: productId,
                text: newProduct.name,
                type: 'product',
            },
        };
        state.productDetails = {
            ...state.productDetails,
            [productId]: {
                categories,
                description: newProduct.description,
                ean: newProduct.ean,
                id: newProduct.id,
                images: newProduct.images,
                name: newProduct.name,
                productId: newProduct.id,
                sku: newProduct.sku,
            },
        };

        // add product to a new category only if that category was fetched before
        // otherwise it will prevent from pooling other category children without force mode
        if (state.productsIdsByParentId[categoryId]) {
            const productsIdsByParentId = cloneDeep(state.productsIdsByParentId);
            productsIdsByParentId[categoryId].push(productId);
            state.productsIdsByParentId = productsIdsByParentId;
        }

        state.loading.createProduct = false;
    },
    [CREATE_PRODUCT_FAILURE](state) {
        state.loading.createProduct = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
