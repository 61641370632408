<template>
    <div>
        <PageHeaderFilters>
            <template #leftFilters>
                <SpaceSwitch />
            </template>
        </PageHeaderFilters>
        <div class="white pa-4">
            <div class="grey lighten-4 pa-4">
                <v-card class="elevation-0 grey lighten-4">
                    <div v-if="spacesLoading" class="text-xs-center">
                        <v-progress-circular class="ma-4" indeterminate color="primary" />
                    </div>
                    <template v-else>
                        <Groups @change="setGroupId" :group="group" @productAdded="loadGroup" @categoryAdded="loadGroup" />
                        <div>
                            <v-card-title v-if="groupId">
                                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                                    <span>{{ $t('details') }}</span>: "<span v-if="group && group.id">{{ group.name }}</span>"
                                </h3>
                            </v-card-title>
                            <Products
                                v-if="groupId"
                                :group-id="groupId"
                                :group="group"
                                @deleted="deleteProduct"
                                @productAdded="loadGroup"
                                @categoryAdded="loadGroup"
                            />
                            <Categories
                                v-if="groupId"
                                :group-id="groupId"
                                :group="group"
                                @deleted="deleteCategory"
                                @productAdded="loadGroup"
                                @categoryAdded="loadGroup"
                            />
                        </div>
                    </template>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageHeaderFilters from '@/components/common/PageHeaderFilters.vue';
import SpaceSwitch from '@/components/common/SpaceSwitch.vue';
import Groups from '@/components/producer/product-groups/Groups.vue';
import Categories from '@/components/producer/product-groups/Categories.vue';
import Products from '@/components/producer/product-groups/Products.vue';

export default {
    name: 'ProducerProductGroups',
    components: {
        SpaceSwitch,
        PageHeaderFilters,
        Groups,
        Categories,
        Products,
    },
    data() {
        return {
            groupId: null,
            error: null,
            group: null,
        };
    },
    computed: {
        products() {
            return this.group?.products;
        },
        categories() {
            return this.group?.categories;
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            spacesLoading: state => state.space.loading.fetchSpaces,
        }),
    },
    watch: {
        groupId(id) {
            this.group = null;
            if (id) this.loadGroup();
        },
        spaceId() {
            this.group = null;
        },
    },
    methods: {
        setGroupId(id) {
            this.groupId = id || null;
        },
        async loadGroup() {
            try {
                this.fetchGroup({ id: this.groupId }).then(data => (this.group = (this.groupId && this.groupId === data.id) ? data : null)); // eslint-disable-line no-return-assign
            } catch (e) {
                this.error = e.message;
                this.group = null;
            }
        },
        deleteProduct({ groupId, productId }) {
            if (this.group.id === groupId) {
                const products = this.group.products || [];
                const index = products.findIndex(item => item.id === productId);
                if (index !== -1) products.splice(index, 1);
            }
        },
        deleteCategory({ groupId, categoryId }) {
            if (this.group.id === groupId) {
                const categories = this.group.categories || [];
                const index = categories.findIndex(item => item.id === categoryId);
                if (index !== -1) categories.splice(index, 1);
            }
        },
        ...mapActions({
            fetchGroup: 'producerProductGroup/fetchGroup',
        }),
    },
};
</script>
