<template>
    <v-layout class="mb-2 page-header-filters sticky" row justify-space-between align-content-start wrap>
        <div class="filters-group">
            <slot name="leftFilters" />
        </div>
        <div class="filters-group">
            <slot name="rightFilters" />
        </div>
    </v-layout>
</template>

<script>
export default {
    name: 'page-header-filters',
};
</script>

<style lang="scss" scoped>
.page-header-filters {
    min-height: 40px;
}

.filters-group {
    display: flex;
}

.sticky {
    position: sticky;
    padding-top: 8px;
    top: 0;
    background-color: $bg-light-color;
    z-index: 7;

    margin-right: 48px;
}
</style>
