<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Raport Kategorii" />
            </template>
        </page-header-filters>
        <div v-if="!hasPermission && !spacesLoading" class="section-placeholder mb-4">
            <img class="section-placeholder-img" :src="require('../../assets/noAccessPlaceholders/campaign-analysis.png')" alt="">
            <NoAccessContactForm class="section-placeholder-form" :permission="permissionName" :description="$t('subscriptionNotIncludeCampaignAnalytics')" />
        </div>
        <v-card v-if="hasPermission" class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('producerReportsCategory.noSpace')" />

                <!-- CONTENT -->
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import usercomService from '@/services/usercomService';
import NoAccessContactForm from '@/components/common/NoAccessContactForm.vue';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DateRangePicker from '../../components/common/DateRangePicker.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import ResetFiltersButton from '../../components/common/ResetFiltersButton.vue';
import spaceService from '../../services/spaceService';

export default {
    name: 'CampaignAnalysis',
    components: {
        ResetFiltersButton,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
        NoAccessContactForm,
    },
    data() {
        return {
            permissionName: spaceService.permissions.CAMPAIGN_ANALYSIS,
            spaceInitialized: false,
            loadingConfig: false,
            isDatepickerFilterDefault: true,
            error: null,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault;
        },
        hasPermission() {
            return spaceService.hasPermission(this.permissionName);
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaceId: state => state.space.currentSpaceId,
            spaces: state => state.space.spaces,
        }),
    },
    methods: {
        onActivate() {
            usercomService.userengage(`event.menu-${this.$t('producerSidebar.campaignAnalysis')}`, { 'space-type': this.spaces?.[this.spaceId]?.name });
        },
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            }
        },
        async getData() {
            if (!this.hasPermission) {
                return;
            }
            this.loadingConfig = true;
            // await this.getXXX();
            this.loadingConfig = false;
        },
        handleSpaceChange() {
            this.getData();

            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .section-placeholder {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-form {
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 20%;
            transform: translate(-50%, -50%);
        }
    }
</style>
