<template>
    <candlestick-graph
        class="mt-3"
        :title="$t('competitionPriceRange.title')"
        :tooltip="$t('competitionPriceRange.titleTooltip')"
        type="small"
        :data="items"
        :options="options"
        :no-results-title="$t('noResults')"
        :style-text-node="styleTextNode"
    />
</template>

<script>
import { max } from 'lodash';
import CandlestickGraph from '@/components/graphs/CandlestickGraph.vue';
import numberService from '@/services/numberService';

export default {
    name: 'competition-price-range',
    components: {
        CandlestickGraph,
    },
    props: {
        set: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
        },
        compareData: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        productNames() {
            return Object
                .keys(this.data.products ?? {})
                .map(productId => this.data.products[productId].product.name);
        },
        items() {
            if (!this.set || !this.data) {
                return null;
            }
            const header = ['Product', 'Min price', 'Price range min', 'Price range max', 'Max price', { type: 'string', role: 'tooltip', p: { html: true } }];
            const items = [];
            const unavailableItems = [];

            [this.set.producerProduct, ...this.set.producerProducts, ...this.set.globalProducts].forEach(setProduct => {
                const productData = this.data.aggregations[setProduct.id] ?? this.data.products[setProduct.id];
                const isAvailable = Boolean(productData.custom.priceAvg);
                const item = [
                    productData.aggregation?.name ?? productData.product?.name,
                    productData.custom.priceMin,
                    productData.custom.priceRangeMin,
                    productData.custom.priceRangeMax,
                    productData.custom.priceMax,
                    this.createTooltip(productData),
                ];
                if (isAvailable) {
                    items.push(item);
                } else {
                    unavailableItems.push(item);
                }
            });
            if (!items && !unavailableItems) {
                return null;
            }
            return [header, ...items, ...unavailableItems];
        },
        options() {
            const options = {
                tooltip: {
                    isHtml: true,
                },
                candlestick: {
                    risingColor: {
                        strokeWidth: 0,
                        fill: '#77497a',
                    },
                },
                vAxes: {
                    0: {
                        title: `${this.$t('price')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]`,
                        format: '#',
                        viewWindow: {
                            min: 0,
                        },
                    },
                },
            };

            let hasValueOverZero = false;

            if (this.items && this.items.length > 0) {
                // each item's max price is at 4th index
                const filteredData = this.items.slice(1).map(row => row[4]);
                hasValueOverZero = filteredData.some(value => value > 0);
                // flatten array and get max value
                const maxValue = max(filteredData);
                if (maxValue > 0) {
                    options.vAxes[0].format = 'short';
                }
            }

            options.vAxes[0].viewWindow.max = hasValueOverZero ? null : 1;

            return options;
        },
    },
    methods: {
        styleTextNode(node) {
            // each line of product name is a separate <text> node grouped under a single <g> node
            // merging all <text> nodes text content into a single string value
            const parentGroup = node.closest('g');
            const label = Array
                .from(parentGroup?.getElementsByTagName('text') ?? [])
                .map(textNode => textNode.textContent).join(' ');

            // potentially can have false positive if competition products have exactly same names as producer product
            if (this.productNames.includes(label)) {
                /* eslint-disable no-param-reassign */
                node.style.fill = this.$vuetify.theme.primary;
                node.style.fontWeight = 700;
                /* eslint-enable no-param-reassign */
            }
        },
        createTooltip(product) {
            const compareProduct = this.compareData[product.custom.isProducerProduct ? 'products' : 'aggregations'][product.custom.id];
            const comparePriceAvg = compareProduct ? numberService.calculateChangePercentage(product.custom.priceAvg, compareProduct.custom.priceAvg) : 0;
            const comparePriceMin = compareProduct ? numberService.calculateChangePercentage(product.custom.priceMin, compareProduct.custom.priceMin) : 0;
            const comparePriceMax = compareProduct ? numberService.calculateChangePercentage(product.custom.priceMax, compareProduct.custom.priceMax) : 0;

            return `<div style="padding: 5px; font-size: 15px; white-space: nowrap;">
                <div style="margin-bottom: 10px;">
                    ${this.$t('product')}:
                    <span style="font-weight: 700;">${product.product?.name ?? product.aggregation?.name}</span>
                </div>
                <div>
                    ${this.$t('priceRange')}:
                    <span style="font-weight: 700;">${product.custom.priceRangeMinFormatted} - ${product.custom.priceRangeMaxFormatted}</span>
                </div>
                <div style="margin-bottom: 10px;">
                    ${this.$t('avgPrice')}:
                    <span style="font-weight: 700;">${product.custom.priceAvgFormatted}</span>
                    <div style="display: inline-block; margin-left: 7px;">
                        ${this.getChangeIndicator(comparePriceAvg)} <span class="${this.getChangeClass(comparePriceAvg)}">${numberService.formatChange(comparePriceAvg, 'percent')}</span>
                    </div>
                </div>
                <div>
                    ${this.$t('minPrice')}:
                    <span style="font-weight: 700;">${product.custom.priceMinFormatted}</span>
                    <div style="display: inline-block; margin-left: 7px;">
                        ${this.getChangeIndicator(comparePriceMin)} <span class="${this.getChangeClass(comparePriceMin)}">${numberService.formatChange(comparePriceMin, 'percent')}</span>
                    </div>
                </div>
                <div>
                    ${this.$t('maxPrice')}:
                    <span style="font-weight: 700;">${product.custom.priceMaxFormatted}</span>
                    <div style="display: inline-block; margin-left: 7px;">
                        ${this.getChangeIndicator(comparePriceMax)} <span class="${this.getChangeClass(comparePriceMax)}">${numberService.formatChange(comparePriceMax, 'percent')}</span>
                    </div>
                </div>
            </div>`;
        },
        getChangeClass(change) {
            if (!change || change === 0 || Number.isNaN(Number.parseInt(change, 10))) {
                return '';
            }
            return change > 0 ? 'primary--text' : 'secondary--text';
        },
        getChangeIndicator(change) {
            if (!change || change === 0 || Number.isNaN(Number.parseInt(change, 10))) {
                return '';
            }
            return `<i class="v-icon fa fa-caret-${change > 0 ? 'up' : 'down'} theme--light ${change > 0 ? 'primary--text' : 'secondary--text'}" style="font-size: 16px;"></i>`;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
