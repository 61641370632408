export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';
export const AUTHENTICATE_AS_REQUEST = 'AUTHENTICATE_AS_REQUEST';
export const AUTHENTICATE_AS_SUCCESS = 'AUTHENTICATE_AS_SUCCESS';
export const AUTHENTICATE_AS_FAILURE = 'AUTHENTICATE_AS_FAILURE';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_LIGHT_REQUEST = 'FETCH_USER_LIGHT_REQUEST';
export const FETCH_USER_LIGHT_SUCCESS = 'FETCH_USER_LIGHT_SUCCESS';
export const FETCH_USER_LIGHT_FAILURE = 'FETCH_USER_LIGHT_FAILURE';
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const FETCH_USER_FULL_REQUEST = 'FETCH_USER_FULL_REQUEST';
export const FETCH_USER_FULL_SUCCESS = 'FETCH_USER_FULL_SUCCESS';
export const FETCH_USER_FULL_FAILURE = 'FETCH_USER_FULL_FAILURE';
export const UPDATE_PERSONAL_SETTINGS_REQUEST = 'UPDATE_PERSONAL_SETTINGS_REQUEST';
export const UPDATE_PERSONAL_SETTINGS_SUCCESS = 'UPDATE_PERSONAL_SETTINGS_SUCCESS';
export const UPDATE_PERSONAL_SETTINGS_FAILURE = 'UPDATE_PERSONAL_SETTINGS_FAILURE';
export const UPDATE_COMPANY_SETTINGS_REQUEST = 'UPDATE_COMPANY_SETTINGS_REQUEST';
export const UPDATE_COMPANY_SETTINGS_SUCCESS = 'UPDATE_COMPANY_SETTINGS_SUCCESS';
export const UPDATE_COMPANY_SETTINGS_FAILURE = 'UPDATE_COMPANY_SETTINGS_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_COMPLETE_REQUEST = 'RESET_PASSWORD_COMPLETE_REQUEST';
export const RESET_PASSWORD_COMPLETE_SUCCESS = 'RESET_PASSWORD_COMPLETE_SUCCESS';
export const RESET_PASSWORD_COMPLETE_FAILURE = 'RESET_PASSWORD_COMPLETE_FAILURE';
export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'ACCEPT_TERMS_FAILURE';
export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const CLEAN_DICTIONARY = 'CLEAN_DICTIONARY';
export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_SPACE_TYPES_REQUEST = 'FETCH_SPACE_TYPES_REQUEST';
export const FETCH_SPACE_TYPES_SUCCESS = 'FETCH_SPACE_TYPES_SUCCESS';
export const FETCH_SPACE_TYPES_FAILURE = 'FETCH_SPACE_TYPES_FAILURE';
export const FETCH_SPACE_STATUSES_REQUEST = 'FETCH_SPACE_STATUSES_REQUEST';
export const FETCH_SPACE_STATUSES_SUCCESS = 'FETCH_SPACE_STATUSES_SUCCESS';
export const FETCH_SPACE_STATUSES_FAILURE = 'FETCH_SPACE_STATUSES_FAILURE';
export const CLEAN_SPACES = 'CLEAN_SPACES';
export const FETCH_SPACES_REQUEST = 'FETCH_SPACES_REQUEST';
export const FETCH_SPACES_SUCCESS = 'FETCH_SPACES_SUCCESS';
export const FETCH_SPACES_FAILURE = 'FETCH_SPACES_FAILURE';
export const SET_CURRENT_SPACE = 'SET_CURRENT_SPACE';
export const FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST = 'FETCH_SPACE_CAMPAIGNS_REQUEST';
export const FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS = 'FETCH_SPACE_CAMPAIGNS_SUCCESS';
export const FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE = 'FETCH_SPACE_CAMPAIGNS_FAILURE';
export const FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST = 'FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST';
export const FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE = 'FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE';
export const ACTIVATE_PERMISSION_REQUEST = 'ACTIVATE_PERMISSION_REQUEST';
export const ACTIVATE_PERMISSION_SUCCESS = 'ACTIVATE_PERMISSION_SUCCESS';
export const ACTIVATE_PERMISSION_FAILURE = 'ACTIVATE_PERMISSION_FAILURE';
export const DEACTIVATE_PERMISSION_REQUEST = 'DEACTIVATE_PERMISSION_REQUEST';
export const DEACTIVATE_PERMISSION_SUCCESS = 'DEACTIVATE_PERMISSION_SUCCESS';
export const DEACTIVATE_PERMISSION_FAILURE = 'DEACTIVATE_PERMISSION_FAILURE';
export const FETCH_PERMISSIONS_REQUEST = 'FETCH_PERMISSIONS_REQUEST';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILURE = 'FETCH_PERMISSIONS_FAILURE';
export const CLEAN_PRODUCTS = 'CLEAN_PRODUCTS';
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const FETCH_SEARCH_PRODUCTS_REQUEST = 'FETCH_SEARCH_PRODUCTS_REQUEST';
export const FETCH_SEARCH_PRODUCTS_SUCCESS = 'FETCH_SEARCH_PRODUCTS_SUCCESS';
export const FETCH_SEARCH_PRODUCTS_FAILURE = 'FETCH_SEARCH_PRODUCTS_FAILURE';
export const FETCH_FIND_PRODUCTS_REQUEST = 'FETCH_FIND_PRODUCTS_REQUEST';
export const FETCH_FIND_PRODUCTS_SUCCESS = 'FETCH_FIND_PRODUCTS_SUCCESS';
export const FETCH_FIND_PRODUCTS_FAILURE = 'FETCH_FIND_PRODUCTS_FAILURE';
export const FETCH_PRODUCT_DETAILS_REQUEST = 'FETCH_PRODUCT_DETAILS_REQUEST';
export const FETCH_PRODUCT_DETAILS_SUCCESS = 'FETCH_PRODUCT_DETAILS_SUCCESS';
export const FETCH_PRODUCT_DETAILS_FAILURE = 'FETCH_PRODUCT_DETAILS_FAILURE';
export const FETCH_PRODUCT_CLICKS_STATS_REQUEST = 'FETCH_PRODUCT_CLICKS_STATS_REQUEST';
export const FETCH_PRODUCT_CLICKS_STATS_SUCCESS = 'FETCH_PRODUCT_CLICKS_STATS_SUCCESS';
export const FETCH_PRODUCT_CLICKS_STATS_FAILURE = 'FETCH_PRODUCT_CLICKS_STATS_FAILURE';
export const FETCH_PRODUCT_SALES_STATS_REQUEST = 'FETCH_PRODUCT_SALES_STATS_REQUEST';
export const FETCH_PRODUCT_SALES_STATS_SUCCESS = 'FETCH_PRODUCT_SALES_STATS_SUCCESS';
export const FETCH_PRODUCT_SALES_STATS_FAILURE = 'FETCH_PRODUCT_SALES_STATS_FAILURE';
export const FETCH_PRODUCT_IMPRESSIONS_STATS_REQUEST = 'FETCH_PRODUCT_IMPRESSIONS_STATS_REQUEST';
export const FETCH_PRODUCT_IMPRESSIONS_STATS_SUCCESS = 'FETCH_PRODUCT_IMPRESSIONS_STATS_SUCCESS';
export const FETCH_PRODUCT_IMPRESSIONS_STATS_FAILURE = 'FETCH_PRODUCT_IMPRESSIONS_STATS_FAILURE';
export const FETCH_PRODUCT_SALES_CHART_REQUEST = 'FETCH_PRODUCT_SALES_CHART_REQUEST';
export const FETCH_PRODUCT_SALES_CHART_SUCCESS = 'FETCH_PRODUCT_SALES_CHART_SUCCESS';
export const FETCH_PRODUCT_SALES_CHART_FAILURE = 'FETCH_PRODUCT_SALES_CHART_FAILURE';
export const FETCH_PRODUCT_TRAFFIC_CHART_REQUEST = 'FETCH_PRODUCT_TRAFFIC_CHART_REQUEST';
export const FETCH_PRODUCT_TRAFFIC_CHART_SUCCESS = 'FETCH_PRODUCT_TRAFFIC_CHART_SUCCESS';
export const FETCH_PRODUCT_TRAFFIC_CHART_FAILURE = 'FETCH_PRODUCT_TRAFFIC_CHART_FAILURE';
export const FETCH_PRODUCT_PRICE_DISTRIBUTION_REQUEST = 'FETCH_PRODUCT_PRICE_DISTRIBUTION_REQUEST';
export const FETCH_PRODUCT_PRICE_DISTRIBUTION_SUCCESS = 'FETCH_PRODUCT_PRICE_DISTRIBUTION_SUCCESS';
export const FETCH_PRODUCT_PRICE_DISTRIBUTION_FAILURE = 'FETCH_PRODUCT_PRICE_DISTRIBUTION_FAILURE';
export const FETCH_PRODUCT_AVAILABILITY_REQUEST = 'FETCH_PRODUCT_AVAILABILITY_REQUEST';
export const FETCH_PRODUCT_AVAILABILITY_SUCCESS = 'FETCH_PRODUCT_AVAILABILITY_SUCCESS';
export const FETCH_PRODUCT_AVAILABILITY_FAILURE = 'FETCH_PRODUCT_AVAILABILITY_FAILURE';
export const FETCH_PRODUCT_CATEGORY_AVAILABILITY_REQUEST = 'FETCH_PRODUCT_CATEGORY_AVAILABILITY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_AVAILABILITY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_AVAILABILITY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_AVAILABILITY_FAILURE = 'FETCH_PRODUCT_CATEGORY_AVAILABILITY_FAILURE';
export const FETCH_PRODUCT_OFFERS_REQUEST = 'FETCH_PRODUCT_OFFERS_REQUEST';
export const FETCH_PRODUCT_OFFERS_SUCCESS = 'FETCH_PRODUCT_OFFERS_SUCCESS';
export const FETCH_PRODUCT_OFFERS_FAILURE = 'FETCH_PRODUCT_OFFERS_FAILURE';
export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const CLEAN_DASHBOARD = 'CLEAN_DASHBOARD';
export const FETCH_DASHBOARD_CLICKS_STATS_REQUEST = 'FETCH_DASHBOARD_CLICKS_STATS_REQUEST';
export const FETCH_DASHBOARD_CLICKS_STATS_SUCCESS = 'FETCH_DASHBOARD_CLICKS_STATS_SUCCESS';
export const FETCH_DASHBOARD_CLICKS_STATS_FAILURE = 'FETCH_DASHBOARD_CLICKS_STATS_FAILURE';
export const FETCH_DASHBOARD_SALES_STATS_REQUEST = 'FETCH_DASHBOARD_SALES_STATS_REQUEST';
export const FETCH_DASHBOARD_SALES_STATS_SUCCESS = 'FETCH_DASHBOARD_SALES_STATS_SUCCESS';
export const FETCH_DASHBOARD_SALES_STATS_FAILURE = 'FETCH_DASHBOARD_SALES_STATS_FAILURE';
export const FETCH_DASHBOARD_OWN_SALES_STATS_REQUEST = 'FETCH_DASHBOARD_OWN_SALES_STATS_REQUEST';
export const FETCH_DASHBOARD_OWN_SALES_STATS_SUCCESS = 'FETCH_DASHBOARD_OWN_SALES_STATS_SUCCESS';
export const FETCH_DASHBOARD_OWN_SALES_STATS_FAILURE = 'FETCH_DASHBOARD_OWN_SALES_STATS_FAILURE';
export const FETCH_DASHBOARD_ORDERS_STATS_REQUEST = 'FETCH_DASHBOARD_ORDERS_STATS_REQUEST';
export const FETCH_DASHBOARD_ORDERS_STATS_SUCCESS = 'FETCH_DASHBOARD_ORDERS_STATS_SUCCESS';
export const FETCH_DASHBOARD_ORDERS_STATS_FAILURE = 'FETCH_DASHBOARD_ORDERS_STATS_FAILURE';
export const FETCH_DASHBOARD_OWN_ORDERS_STATS_REQUEST = 'FETCH_DASHBOARD_OWN_ORDERS_STATS_REQUEST';
export const FETCH_DASHBOARD_OWN_ORDERS_STATS_SUCCESS = 'FETCH_DASHBOARD_OWN_ORDERS_STATS_SUCCESS';
export const FETCH_DASHBOARD_OWN_ORDERS_STATS_FAILURE = 'FETCH_DASHBOARD_OWN_ORDERS_STATS_FAILURE';
export const FETCH_DASHBOARD_IMPRESSIONS_STATS_REQUEST = 'FETCH_DASHBOARD_IMPRESSIONS_STATS_REQUEST';
export const FETCH_DASHBOARD_IMPRESSIONS_STATS_SUCCESS = 'FETCH_DASHBOARD_IMPRESSIONS_STATS_SUCCESS';
export const FETCH_DASHBOARD_IMPRESSIONS_STATS_FAILURE = 'FETCH_DASHBOARD_IMPRESSIONS_STATS_FAILURE';
export const FETCH_DASHBOARD_SALES_CHART_REQUEST = 'FETCH_DASHBOARD_SALES_CHART_REQUEST';
export const FETCH_DASHBOARD_SALES_CHART_SUCCESS = 'FETCH_DASHBOARD_SALES_CHART_SUCCESS';
export const FETCH_DASHBOARD_SALES_CHART_FAILURE = 'FETCH_DASHBOARD_SALES_CHART_FAILURE';
export const FETCH_DASHBOARD_TRAFFIC_CHART_REQUEST = 'FETCH_DASHBOARD_TRAFFIC_CHART_REQUEST';
export const FETCH_DASHBOARD_TRAFFIC_CHART_SUCCESS = 'FETCH_DASHBOARD_TRAFFIC_CHART_SUCCESS';
export const FETCH_DASHBOARD_TRAFFIC_CHART_FAILURE = 'FETCH_DASHBOARD_TRAFFIC_CHART_FAILURE';
export const CREATE_SPACE_REQUEST = 'CREATE_SPACE_REQUEST';
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS';
export const CREATE_SPACE_FAILURE = 'CREATE_SPACE_FAILURE';
export const UPDATE_SPACE_REQUEST = 'UPDATE_SPACE_REQUEST';
export const UPDATE_SPACE_SUCCESS = 'UPDATE_SPACE_SUCCESS';
export const UPDATE_SPACE_FAILURE = 'UPDATE_SPACE_FAILURE';
export const SET_DATE_RANGE_PICKER_RANGE = 'SET_DATE_RANGE_PICKER_RANGE';
export const SET_DATE_RANGE_PICKER_COMPARE_RANGE = 'SET_DATE_RANGE_PICKER_COMPARE_RANGE';
export const CLEAN_PLUGIN = 'CLEAN_PLUGIN';
export const FETCH_PLUGINS_REQUEST = 'FETCH_PLUGINS_REQUEST';
export const FETCH_PLUGINS_SUCCESS = 'FETCH_PLUGINS_SUCCESS';
export const FETCH_PLUGINS_FAILURE = 'FETCH_PLUGINS_FAILURE';
export const FETCH_ACTIVE_CAMPAIGNS_REQUEST = 'FETCH_ACTIVE_CAMPAIGNS_REQUEST';
export const FETCH_ACTIVE_CAMPAIGNS_SUCCESS = 'FETCH_ACTIVE_CAMPAIGNS_SUCCESS';
export const FETCH_ACTIVE_CAMPAIGNS_FAILURE = 'FETCH_ACTIVE_CAMPAIGNS_FAILURE';
export const FETCH_AVAILABLE_CAMPAIGNS_REQUEST = 'FETCH_AVAILABLE_CAMPAIGNS_REQUEST';
export const FETCH_AVAILABLE_CAMPAIGNS_SUCCESS = 'FETCH_AVAILABLE_CAMPAIGNS_SUCCESS';
export const FETCH_AVAILABLE_CAMPAIGNS_FAILURE = 'FETCH_AVAILABLE_CAMPAIGNS_FAILURE';
export const FETCH_AVAILABLE_EXTENSIONS_REQUEST = 'FETCH_AVAILABLE_EXTENSIONS_REQUEST';
export const FETCH_AVAILABLE_EXTENSIONS_SUCCESS = 'FETCH_AVAILABLE_EXTENSIONS_SUCCESS';
export const FETCH_AVAILABLE_EXTENSIONS_FAILURE = 'FETCH_AVAILABLE_EXTENSIONS_FAILURE';
export const UPDATE_PLUGIN_EXTENSION_REQUEST = 'UPDATE_PLUGIN_EXTENSION_REQUEST';
export const UPDATE_PLUGIN_EXTENSION_SUCCESS = 'UPDATE_PLUGIN_EXTENSION_SUCCESS';
export const UPDATE_PLUGIN_EXTENSION_FAILURE = 'UPDATE_PLUGIN_EXTENSION_FAILURE';
export const FETCH_SORT_TYPES_REQUEST = 'FETCH_SORT_TYPES_REQUEST';
export const FETCH_SORT_TYPES_SUCCESS = 'FETCH_SORT_TYPES_SUCCESS';
export const FETCH_SORT_TYPES_FAILURE = 'FETCH_SORT_TYPES_FAILURE';
export const UPDATE_PLUGIN_APPEARANCE_REQUEST = 'UPDATE_PLUGIN_APPEARANCE_REQUEST';
export const UPDATE_PLUGIN_APPEARANCE_SUCCESS = 'UPDATE_PLUGIN_APPEARANCE_SUCCESS';
export const UPDATE_PLUGIN_APPEARANCE_FAILURE = 'UPDATE_PLUGIN_APPEARANCE_FAILURE';
export const CREATE_PLUGIN_CAMPAIGN_REQUEST = 'CREATE_PLUGIN_CAMPAIGN_REQUEST';
export const CREATE_PLUGIN_CAMPAIGN_SUCCESS = 'CREATE_PLUGIN_CAMPAIGN_SUCCESS';
export const CREATE_PLUGIN_CAMPAIGN_FAILURE = 'CREATE_PLUGIN_CAMPAIGN_FAILURE';
export const UPDATE_PLUGIN_CAMPAIGN_REQUEST = 'UPDATE_PLUGIN_CAMPAIGN_REQUEST';
export const UPDATE_PLUGIN_CAMPAIGN_SUCCESS = 'UPDATE_PLUGIN_CAMPAIGN_SUCCESS';
export const UPDATE_PLUGIN_CAMPAIGN_FAILURE = 'UPDATE_PLUGIN_CAMPAIGN_FAILURE';
export const DELETE_PLUGIN_CAMPAIGN_REQUEST = 'DELETE_PLUGIN_CAMPAIGN_REQUEST';
export const DELETE_PLUGIN_CAMPAIGN_SUCCESS = 'DELETE_PLUGIN_CAMPAIGN_SUCCESS';
export const DELETE_PLUGIN_CAMPAIGN_FAILURE = 'DELETE_PLUGIN_CAMPAIGN_FAILURE';
export const CLEAN_REPORTS_PRODUCTS = 'CLEAN_REPORTS_PRODUCTS';
export const FETCH_REPORTS_PRODUCTS_SALES_CHART_REQUEST = 'FETCH_REPORTS_PRODUCTS_SALES_CHART_REQUEST';
export const FETCH_REPORTS_PRODUCTS_SALES_CHART_SUCCESS = 'FETCH_REPORTS_PRODUCTS_SALES_CHART_SUCCESS';
export const FETCH_REPORTS_PRODUCTS_SALES_CHART_FAILURE = 'FETCH_REPORTS_PRODUCTS_SALES_CHART_FAILURE';
export const FETCH_REPORTS_PRODUCTS_SUMMARY_REQUEST = 'FETCH_REPORTS_PRODUCTS_SUMMARY_REQUEST';
export const FETCH_REPORTS_PRODUCTS_SUMMARY_SUCCESS = 'FETCH_REPORTS_PRODUCTS_SUMMARY_SUCCESS';
export const FETCH_REPORTS_PRODUCTS_SUMMARY_FAILURE = 'FETCH_REPORTS_PRODUCTS_SUMMARY_FAILURE';
export const FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_REQUEST = 'FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_REQUEST';
export const FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_SUCCESS = 'FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_SUCCESS';
export const FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_FAILURE = 'FETCH_REPORTS_PRODUCTS_CART_ANALYSIS_FAILURE';
export const FETCH_REPORTS_PRODUCTS_RANKING_REQUEST = 'FETCH_REPORTS_PRODUCTS_RANKING_REQUEST';
export const FETCH_REPORTS_PRODUCTS_RANKING_SUCCESS = 'FETCH_REPORTS_PRODUCTS_RANKING_SUCCESS';
export const FETCH_REPORTS_PRODUCTS_RANKING_FAILURE = 'FETCH_REPORTS_PRODUCTS_RANKING_FAILURE';
export const CLEAN_REPORTS_CATEGORY = 'CLEAN_REPORTS_CATEGORY';
export const FETCH_REPORTS_CATEGORY_AMOUNT_REQUEST = 'FETCH_REPORTS_CATEGORY_AMOUNT_REQUEST';
export const FETCH_REPORTS_CATEGORY_AMOUNT_SUCCESS = 'FETCH_REPORTS_CATEGORY_AMOUNT_SUCCESS';
export const FETCH_REPORTS_CATEGORY_AMOUNT_FAILURE = 'FETCH_REPORTS_CATEGORY_AMOUNT_FAILURE';
export const FETCH_REPORTS_CATEGORY_QUANTITY_REQUEST = 'FETCH_REPORTS_CATEGORY_QUANTITY_REQUEST';
export const FETCH_REPORTS_CATEGORY_QUANTITY_SUCCESS = 'FETCH_REPORTS_CATEGORY_QUANTITY_SUCCESS';
export const FETCH_REPORTS_CATEGORY_QUANTITY_FAILURE = 'FETCH_REPORTS_CATEGORY_QUANTITY_FAILURE';
export const FETCH_REPORTS_CATEGORY_IMPRESSIONS_REQUEST = 'FETCH_REPORTS_CATEGORY_IMPRESSIONS_REQUEST';
export const FETCH_REPORTS_CATEGORY_IMPRESSIONS_SUCCESS = 'FETCH_REPORTS_CATEGORY_IMPRESSIONS_SUCCESS';
export const FETCH_REPORTS_CATEGORY_IMPRESSIONS_FAILURE = 'FETCH_REPORTS_CATEGORY_IMPRESSIONS_FAILURE';
export const FETCH_REPORTS_CATEGORY_CLICKS_REQUEST = 'FETCH_REPORTS_CATEGORY_CLICKS_REQUEST';
export const FETCH_REPORTS_CATEGORY_CLICKS_SUCCESS = 'FETCH_REPORTS_CATEGORY_CLICKS_SUCCESS';
export const FETCH_REPORTS_CATEGORY_CLICKS_FAILURE = 'FETCH_REPORTS_CATEGORY_CLICKS_FAILURE';
export const FETCH_REPORTS_CATEGORY_HEATMAP_REQUEST = 'FETCH_REPORTS_CATEGORY_HEATMAP_REQUEST';
export const FETCH_REPORTS_CATEGORY_HEATMAP_SUCCESS = 'FETCH_REPORTS_CATEGORY_HEATMAP_SUCCESS';
export const FETCH_REPORTS_CATEGORY_HEATMAP_FAILURE = 'FETCH_REPORTS_CATEGORY_HEATMAP_FAILURE';
export const CLEAN_REPORTS_SALES = 'CLEAN_REPORTS_SALES';
export const FETCH_REPORTS_SALES_SALES_CHART_REQUEST = 'FETCH_REPORTS_SALES_SALES_CHART_REQUEST';
export const FETCH_REPORTS_SALES_SALES_CHART_SUCCESS = 'FETCH_REPORTS_SALES_SALES_CHART_SUCCESS';
export const FETCH_REPORTS_SALES_SALES_CHART_FAILURE = 'FETCH_REPORTS_SALES_SALES_CHART_FAILURE';
export const FETCH_REPORTS_SALES_SALES_ALL_CHART_REQUEST = 'FETCH_REPORTS_SALES_SALES_ALL_CHART_REQUEST';
export const FETCH_REPORTS_SALES_SALES_ALL_CHART_SUCCESS = 'FETCH_REPORTS_SALES_SALES_ALL_CHART_SUCCESS';
export const FETCH_REPORTS_SALES_SALES_ALL_CHART_FAILURE = 'FETCH_REPORTS_SALES_SALES_ALL_CHART_FAILURE';
export const FETCH_REPORTS_SALES_SUMMARY_REQUEST = 'FETCH_REPORTS_SALES_SUMMARY_REQUEST';
export const FETCH_REPORTS_SALES_SUMMARY_SUCCESS = 'FETCH_REPORTS_SALES_SUMMARY_SUCCESS';
export const FETCH_REPORTS_SALES_SUMMARY_FAILURE = 'FETCH_REPORTS_SALES_SUMMARY_FAILURE';
export const FETCH_REPORTS_SALES_PRODUCT_DETAILS_SUCCESS = 'FETCH_REPORTS_SALES_PRODUCT_DETAILS_SUCCESS';
export const FETCH_REPORTS_SALES_PRODUCT_DETAILS_REQUEST = 'FETCH_REPORTS_SALES_PRODUCT_DETAILS_REQUEST';
export const FETCH_REPORTS_SALES_PRODUCT_DETAILS_FAILURE = 'FETCH_REPORTS_SALES_PRODUCT_DETAILS_FAILURE';
export const FETCH_REPORTS_SALES_TRANSACTIONS_REQUEST = 'FETCH_REPORTS_SALES_TRANSACTIONS_REQUEST';
export const FETCH_REPORTS_SALES_TRANSACTIONS_SUCCESS = 'FETCH_REPORTS_SALES_TRANSACTIONS_SUCCESS';
export const FETCH_REPORTS_SALES_TRANSACTIONS_FAILURE = 'FETCH_REPORTS_SALES_TRANSACTIONS_FAILURE';
export const CLEAN_REPORTS_SHOPS = 'CLEAN_REPORTS_SHOPS';
export const FETCH_REPORTS_SHOPS_IMPRESSIONS_REQUEST = 'FETCH_REPORTS_SHOPS_IMPRESSIONS_REQUEST';
export const FETCH_REPORTS_SHOPS_IMPRESSIONS_SUCCESS = 'FETCH_REPORTS_SHOPS_IMPRESSIONS_SUCCESS';
export const FETCH_REPORTS_SHOPS_IMPRESSIONS_FAILURE = 'FETCH_REPORTS_SHOPS_IMPRESSIONS_FAILURE';
export const FETCH_REPORTS_SHOPS_CLICKS_REQUEST = 'FETCH_REPORTS_SHOPS_CLICKS_REQUEST';
export const FETCH_REPORTS_SHOPS_CLICKS_SUCCESS = 'FETCH_REPORTS_SHOPS_CLICKS_SUCCESS';
export const FETCH_REPORTS_SHOPS_CLICKS_FAILURE = 'FETCH_REPORTS_SHOPS_CLICKS_FAILURE';
export const FETCH_REPORTS_SHOPS_ORDERS_REQUEST = 'FETCH_REPORTS_SHOPS_ORDERS_REQUEST';
export const FETCH_REPORTS_SHOPS_ORDERS_SUCCESS = 'FETCH_REPORTS_SHOPS_ORDERS_SUCCESS';
export const FETCH_REPORTS_SHOPS_ORDERS_FAILURE = 'FETCH_REPORTS_SHOPS_ORDERS_FAILURE';
export const FETCH_REPORTS_SHOPS_QUANTITY_REQUEST = 'FETCH_REPORTS_SHOPS_QUANTITY_REQUEST';
export const FETCH_REPORTS_SHOPS_QUANTITY_SUCCESS = 'FETCH_REPORTS_SHOPS_QUANTITY_SUCCESS';
export const FETCH_REPORTS_SHOPS_QUANTITY_FAILURE = 'FETCH_REPORTS_SHOPS_QUANTITY_FAILURE';
export const FETCH_REPORTS_SHOPS_AMOUNT_REQUEST = 'FETCH_REPORTS_SHOPS_AMOUNT_REQUEST';
export const FETCH_REPORTS_SHOPS_AMOUNT_SUCCESS = 'FETCH_REPORTS_SHOPS_AMOUNT_SUCCESS';
export const FETCH_REPORTS_SHOPS_AMOUNT_FAILURE = 'FETCH_REPORTS_SHOPS_AMOUNT_FAILURE';
export const CLEAN_REPORTS_AVAILABILITY = 'CLEAN_REPORTS_AVAILABILITY';
export const FETCH_REPORTS_AVAILABILITY_PRODUCT_REQUEST = 'FETCH_REPORTS_AVAILABILITY_PRODUCT_REQUEST';
export const FETCH_REPORTS_AVAILABILITY_PRODUCT_SUCCESS = 'FETCH_REPORTS_AVAILABILITY_PRODUCT_SUCCESS';
export const FETCH_REPORTS_AVAILABILITY_PRODUCT_FAILURE = 'FETCH_REPORTS_AVAILABILITY_PRODUCT_FAILURE';
export const FETCH_AVAILABILITY_CHARTS_REQUEST = 'FETCH_AVAILABILITY_CHARTS_REQUEST';
export const FETCH_AVAILABILITY_CHARTS_SUCCESS = 'FETCH_AVAILABILITY_CHARTS_SUCCESS';
export const FETCH_AVAILABILITY_CHARTS_FAILURE = 'FETCH_AVAILABILITY_CHARTS_FAILURE';
export const FETCH_CURRENT_UNAVAILABLE_PRODUCTS_REQUEST = 'FETCH_CURRENT_UNAVAILABLE_PRODUCTS_REQUEST';
export const FETCH_CURRENT_UNAVAILABLE_PRODUCTS_SUCCESS = 'FETCH_CURRENT_UNAVAILABLE_PRODUCTS_SUCCESS';
export const FETCH_CURRENT_UNAVAILABLE_PRODUCTS_FAILURE = 'FETCH_CURRENT_UNAVAILABLE_PRODUCTS_FAILURE';
export const FETCH_CURRENT_PRODUCTS_AVAILABILITY_REQUEST = 'FETCH_CURRENT_PRODUCTS_AVAILABILITY_REQUEST';
export const FETCH_CURRENT_PRODUCTS_AVAILABILITY_SUCCESS = 'FETCH_CURRENT_PRODUCTS_AVAILABILITY_SUCCESS';
export const FETCH_CURRENT_PRODUCTS_AVAILABILITY_FAILURE = 'FETCH_CURRENT_PRODUCTS_AVAILABILITY_FAILURE';
export const CLEAN_REPORTS_PRICE_DISTRIBUTION = 'CLEAN_REPORTS_PRICE_DISTRIBUTION';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_PRICES_REQUEST = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_PRICES_REQUEST';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_PRICES_SUCCESS = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_PRICES_SUCCESS';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_PRICES_FAILURE = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_PRICES_FAILURE';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_REQUEST = 'FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_REQUEST';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_SUCCESS = 'FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_SUCCESS';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_FAILURE = 'FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_FAILURE';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_REQUEST = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_AGGREGATED_REPORT_REQUEST';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_SUCCESS = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_AGGREGATED_REPORT_SUCCESS';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCTS_AGGREGATED_REPORT_FAILURE = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCT_AGGREGATED_REPORT_FAILURE';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_OVER_TIME_REQUEST = 'FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_OVER_TIME_REQUEST';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_OVER_TIME_SUCCESS = 'FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_OVER_TIME_SUCCESS';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_OVER_TIME_FAILURE = 'FETCH_REPORTS_PRICE_DISTRIBUTION_AVERAGE_PRICE_OVER_TIME_FAILURE';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_QUANTITY_OVER_TIME_REQUEST = 'FETCH_REPORTS_PRICE_DISTRIBUTION_QUANTITY_OVER_TIME_REQUEST';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_QUANTITY_OVER_TIME_SUCCESS = 'FETCH_REPORTS_PRICE_DISTRIBUTION_QUANTITY_OVER_TIME_SUCCESS';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_QUANTITY_OVER_TIME_FAILURE = 'FETCH_REPORTS_PRICE_DISTRIBUTION_QUANTITY_OVER_TIME_FAILURE';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_REQUEST = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_REQUEST';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_SUCCESS = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_SUCCESS';
export const FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_FAILURE = 'FETCH_REPORTS_PRICE_DISTRIBUTION_PRODUCER_TRANSACTIONS_ITEMS_QUANTITY_PRICE_FAILURE';
export const CLEAN_EXPORT_REPORTS = 'CLEAN_EXPORT_REPORTS';
export const FETCH_EXPORT_REPORTS_REQUEST = 'FETCH_EXPORT_REPORTS_REQUEST';
export const FETCH_EXPORT_REPORTS_SUCCESS = 'FETCH_EXPORT_REPORTS_SUCCESS';
export const FETCH_EXPORT_REPORTS_FAILURE = 'FETCH_EXPORT_REPORTS_FAILURE';
export const FETCH_EXPORT_REPORT_FAILURE = 'FETCH_EXPORT_REPORT_FAILURE';
export const GENERATE_EXPORT_REPORT_REQUEST = 'GENERATE_EXPORT_REPORT_REQUEST';
export const GENERATE_EXPORT_REPORT_SUCCESS = 'GENERATE_EXPORT_REPORT_SUCCESS';
export const GENERATE_EXPORT_REPORT_FAILURE = 'GENERATE_EXPORT_REPORT_FAILURE';
export const FETCH_EXPORT_REPORT_SUCCESS = 'FETCH_EXPORT_REPORT_SUCCESS';
export const DELETE_EXPORT_REPORT_REQUEST = 'DELETE_EXPORT_REPORT_REQUEST';
export const DELETE_EXPORT_REPORT_SUCCESS = 'DELETE_EXPORT_REPORT_SUCCESS';
export const DELETE_EXPORT_REPORT_FAILURE = 'DELETE_EXPORT_REPORT_FAILURE';
export const FETCH_REPORTS_CATEGORY_SUMMARY_REQUEST = 'FETCH_REPORTS_CATEGORY_SUMMARY_REQUEST';
export const FETCH_REPORTS_CATEGORY_SUMMARY_SUCCESS = 'FETCH_REPORTS_CATEGORY_SUMMARY_SUCCESS';
export const FETCH_REPORTS_CATEGORY_SUMMARY_FAILURE = 'FETCH_REPORTS_CATEGORY_SUMMARY_FAILURE';
export const FETCH_REPORTS_CATEGORY_RANKING_REQUEST = 'FETCH_REPORTS_CATEGORY_RANKING_REQUEST';
export const FETCH_REPORTS_CATEGORY_RANKING_SUCCESS = 'FETCH_REPORTS_CATEGORY_RANKING_SUCCESS';
export const FETCH_REPORTS_CATEGORY_RANKING_FAILURE = 'FETCH_REPORTS_CATEGORY_RANKING_FAILURE';
export const FETCH_PRODUCER_PRODUCT_GROUPS_REQUEST = 'FETCH_PRODUCER_PRODUCT_GROUPS_REQUEST';
export const FETCH_PRODUCER_PRODUCT_GROUPS_SUCCESS = 'FETCH_PRODUCER_PRODUCT_GROUPS_SUCCESS';
export const FETCH_PRODUCER_PRODUCT_GROUPS_FAILURE = 'FETCH_PRODUCER_PRODUCT_GROUPS_FAILURE';
export const CREATE_PRODUCER_PRODUCT_GROUP_REQUEST = 'CREATE_PRODUCER_PRODUCT_GROUP_REQUEST';
export const CREATE_PRODUCER_PRODUCT_GROUP_SUCCESS = 'CREATE_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const CREATE_PRODUCER_PRODUCT_GROUP_FAILURE = 'CREATE_PRODUCER_PRODUCT_GROUP_FAILURE';
export const UPDATE_PRODUCER_PRODUCT_GROUP_REQUEST = 'UPDATE_PRODUCER_PRODUCT_GROUP_REQUEST';
export const UPDATE_PRODUCER_PRODUCT_GROUP_SUCCESS = 'UPDATE_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const UPDATE_PRODUCER_PRODUCT_GROUP_FAILURE = 'UPDATE_PRODUCER_PRODUCT_GROUP_FAILURE';
export const ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST = 'ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST';
export const ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS = 'ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE = 'ADD_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE';
export const DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST = 'DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_REQUEST';
export const DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS = 'DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE = 'DELETE_PRODUCT_PRODUCER_PRODUCT_GROUP_FAILURE';
export const ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST = 'ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST';
export const ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS = 'ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE = 'ADD_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE';
export const DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST = 'DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_REQUEST';
export const DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS = 'DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE = 'DELETE_CATEGORY_PRODUCER_PRODUCT_GROUP_FAILURE';
export const FETCH_PRODUCER_PRODUCT_GROUP_REQUEST = 'FETCH_PRODUCER_PRODUCT_GROUP_REQUEST';
export const FETCH_PRODUCER_PRODUCT_GROUP_SUCCESS = 'FETCH_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const FETCH_PRODUCER_PRODUCT_GROUP_FAILURE = 'FETCH_PRODUCER_PRODUCT_GROUP_FAILURE';
export const DELETE_PRODUCER_PRODUCT_GROUP_REQUEST = 'DELETE_PRODUCER_PRODUCT_GROUP_REQUEST';
export const DELETE_PRODUCER_PRODUCT_GROUP_SUCCESS = 'DELETE_PRODUCER_PRODUCT_GROUP_SUCCESS';
export const DELETE_PRODUCER_PRODUCT_GROUP_FAILURE = 'DELETE_PRODUCER_PRODUCT_GROUP_FAILURE';
export const FETCH_PRODUCER_GROUP_PRODUCTS_REQUEST = 'FETCH_PRODUCER_GROUP_PRODUCTS_REQUEST';
export const FETCH_PRODUCER_GROUP_PRODUCTS_SUCCESS = 'FETCH_PRODUCER_GROUP_PRODUCTS_SUCCESS';
export const FETCH_PRODUCER_GROUP_PRODUCTS_FAILURE = 'FETCH_PRODUCER_GROUP_PRODUCTS_FAILURE';
export const CLEAR_PRODUCER_PRODUCT_GROUPS = 'CLEAR_PRODUCER_PRODUCT_GROUPS';
export const FETCH_REPORTS_SHOPS_SUMMARY_FAILURE = 'FETCH_REPORTS_SHOPS_SUMMARY_FAILURE';
export const FETCH_REPORTS_SHOPS_SUMMARY_REQUEST = 'FETCH_REPORTS_SHOPS_SUMMARY_REQUEST';
export const FETCH_REPORTS_SHOPS_SUMMARY_SUCCESS = 'FETCH_REPORTS_SHOPS_SUMMARY_SUCCESS';
export const FETCH_REPORTS_SHOPS_RANKING_REQUEST = 'FETCH_REPORTS_SHOPS_RANKING_REQUEST';
export const FETCH_REPORTS_SHOPS_RANKING_SUCCESS = 'FETCH_REPORTS_SHOPS_RANKING_SUCCESS';
export const FETCH_REPORTS_SHOPS_RANKING_FAILURE = 'FETCH_REPORTS_SHOPS_RANKING_FAILURE';
export const FETCH_UNIQUE_SOURCES_REQUEST = 'FETCH_UNIQUE_SOURCES_REQUEST';
export const FETCH_UNIQUE_SOURCES_SUCCESS = 'FETCH_UNIQUE_SOURCES_SUCCESS';
export const FETCH_UNIQUE_SOURCES_FAILURE = 'FETCH_UNIQUE_SOURCES_FAILURE';
export const FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_REQUEST = 'FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_REQUEST';
export const FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_SUCCESS = 'FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_SUCCESS';
export const FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_FAILURE = 'FETCH_REPORTS_SALES_FEATURE_BY_SOURCE_CHART_FAILURE';
export const GENERATE_PRICE_EXPORT_REPORT_REQUEST = 'GENERATE_PRICE_EXPORT_REPORT_REQUEST';
export const GENERATE_PRICE_EXPORT_REPORT_SUCCESS = 'GENERATE_PRICE_EXPORT_REPORT_SUCCESS';
export const GENERATE_PRICE_EXPORT_REPORT_FAILURE = 'GENERATE_PRICE_EXPORT_REPORT_FAILURE';
export const FETCH_PRICE_EXPORT_REPORTS_REQUEST = 'FETCH_PRICE_EXPORT_REPORTS_REQUEST';
export const FETCH_PRICE_EXPORT_REPORTS_SUCCESS = 'FETCH_PRICE_EXPORT_REPORTS_SUCCESS';
export const FETCH_PRICE_EXPORT_REPORTS_FAILURE = 'FETCH_PRICE_EXPORT_REPORTS_FAILURE';
export const GENERATE_COMPETITION_EXPORT_REPORT_REQUEST = 'GENERATE_COMPETITION_EXPORT_REPORT_REQUEST';
export const GENERATE_COMPETITION_EXPORT_REPORT_SUCCESS = 'GENERATE_COMPETITION_EXPORT_REPORT_SUCCESS';
export const GENERATE_COMPETITION_EXPORT_REPORT_FAILURE = 'GENERATE_COMPETITION_EXPORT_REPORT_FAILURE';
export const FETCH_COMPETITION_EXPORT_REPORTS_REQUEST = 'FETCH_COMPETITION_EXPORT_REPORTS_REQUEST';
export const FETCH_COMPETITION_EXPORT_REPORTS_SUCCESS = 'FETCH_COMPETITION_EXPORT_REPORTS_SUCCESS';
export const FETCH_COMPETITION_EXPORT_REPORTS_FAILURE = 'FETCH_COMPETITION_EXPORT_REPORTS_FAILURE';
export const GENERATE_AVAILABILITY_EXPORT_REPORT_REQUEST = 'GENERATE_AVAILABILITY_EXPORT_REPORT_REQUEST';
export const GENERATE_AVAILABILITY_EXPORT_REPORT_SUCCESS = 'GENERATE_AVAILABILITY_EXPORT_REPORT_SUCCESS';
export const GENERATE_AVAILABILITY_EXPORT_REPORT_FAILURE = 'GENERATE_AVAILABILITY_EXPORT_REPORT_FAILURE';
export const FETCH_AVAILABILITY_EXPORT_REPORTS_REQUEST = 'FETCH_AVAILABILITY_EXPORT_REPORTS_REQUEST';
export const FETCH_AVAILABILITY_EXPORT_REPORTS_SUCCESS = 'FETCH_AVAILABILITY_EXPORT_REPORTS_SUCCESS';
export const FETCH_AVAILABILITY_EXPORT_REPORTS_FAILURE = 'FETCH_AVAILABILITY_EXPORT_REPORTS_FAILURE';
export const FETCH_AVAILABILITIES_REQUEST = 'FETCH_AVAILABILITIES_REQUEST';
export const FETCH_AVAILABILITIES_SUCCESS = 'FETCH_AVAILABILITIES_SUCCESS';
export const FETCH_AVAILABILITIES_FAILURE = 'FETCH_AVAILABILITIES_FAILURE';
