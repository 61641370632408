<template>
    <base-layout>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md5>
                    <v-card class="elevation-0 pa-4">
                        <v-card-text class="text-xs-left">
                            <h3 class="mb-4 title font-weight-bold">{{ $t('resetPassword.title') }}</h3>

                            <v-form v-if="!isSuccess" @submit.prevent="handleSubmit" novalidate>
                                <p class="font-weight-medium">{{ $t('resetPassword.description') }}</p>
                                <v-text-field
                                    v-model.trim="email"
                                    :error-messages="emailErrors"
                                    @blur="$v.email.$touch()"
                                    name="email"
                                    :label="$t('resetPassword.email')"
                                    :disabled="submitting"
                                    type="email"
                                    placeholder=" "
                                    outline
                                />

                                <v-alert
                                    :value="error"
                                    dismissible
                                    type="error"
                                    transition="scale-transition">
                                    {{ error }}
                                </v-alert>

                                <div class="mb-3 text-xs-right">
                                    <v-btn :to="{ name: 'login' }" round>
                                        {{ $t('resetPassword.back') }}
                                    </v-btn>
                                    <v-btn
                                        class="btn-wide"
                                        type="submit"
                                        color="tertiary"
                                        :loading="submitting"
                                        :disabled="submitting"
                                        dark
                                        round
                                    >
                                        {{ $t('resetPassword.submit') }}
                                    </v-btn>
                                </div>
                            </v-form>

                            <div v-if="isSuccess">
                                <v-alert
                                    :value="isSuccess"
                                    type="success"
                                    transition="scale-transition">
                                    {{ $t('resetPassword.success', { email }) }}
                                </v-alert>
                                <div class="mb-3 text-xs-center">
                                    <v-btn :to="{ name: 'login' }" round>
                                        {{ $t('resetPassword.back') }}
                                    </v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                    <p class="font-weight-bold grey--text text--darken-1 mt-2 pa-2 text-xs-center">
                        <language-switch />
                    </p>
                </v-flex>
            </v-layout>
        </v-container>
    </base-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import BaseLayout from '../components/layout/BaseLayout.vue';
import LanguageSwitch from '../components/common/LanguageSwitch.vue';

export default {
    name: 'reset-password',
    components: {
        LanguageSwitch,
        BaseLayout,
    },
    validations: {
        email: { required, email },
    },
    data() {
        return {
            email: '',
            error: null,
            isSuccess: false,
        };
    },
    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) {
                return errors;
            }
            if (!this.$v.email.required) {
                errors.push(this.$t('validation.required'));
            }
            if (!this.$v.email.email) {
                errors.push(this.$t('validation.email'));
            }
            return errors;
        },
        ...mapState({
            submitting: state => state.auth.loading.resetPassword,
        }),
    },
    methods: {
        async handleSubmit() {
            this.error = null;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.resetPassword({
                    email: this.email,
                });
                this.isSuccess = true;
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPassword',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
