<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
            <template #rightFilters>
                <reset-filters-button :active="isResetActive" />
                <date-range-picker v-model="dateFilter" @filterDefaultChange="handleFilterDefaultChange('datepicker', $event)" ga-page="Raport Kategorii" />
            </template>
        </page-header-filters>
        <no-access-placeholder
            v-if="!hasPermission && !spacesLoading"
            :permission-name="permissionName"
            :title="$t('permissionContent.title.reportsCategories')"
            :description="$t('permissionContent.description.reportsCategories')"
            :image="require('../../assets/noAccessPlaceholders/reports-categories.jpg')"
            cta-position="top"
            @activated="getData"
        />
        <v-card v-if="hasPermission" class="elevation-0 mb-4">
            <v-card-text>
                <div v-if="loading" class="text-xs-center">
                    <v-progress-circular indeterminate color="primary" />
                </div>

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{error}}
                </v-alert>

                <data-placeholder v-if="!loading && !spaceId" :title="$t('producerReportsCategory.noSpace')" />

                <div v-if="!loading && spaceId">
                    <category-traffic ref="traffic" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('traffic', $event)" />
                    <category-sales ref="sales" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('sales', $event)" />
                    <category-summary ref="summary" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('summary', $event)" />
                    <category-ranking ref="ranking" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('ranking', $event)" />
                    <category-heatmap ref="heatmap" :date-filter="dateFilter" @filterDefaultChange="handleFilterDefaultChange('heatmap', $event)" />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DateRangePicker from '../../components/common/DateRangePicker.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import CategoryTraffic from '../../components/producer/reports/category/CategoryTraffic.vue';
import CategorySales from '../../components/producer/reports/category/CategorySales.vue';
import CategorySummary from '../../components/producer/reports/category/CategorySummary.vue';
import CategoryRanking from '../../components/producer/reports/category/CategoryRanking.vue';
import CategoryHeatmap from '../../components/producer/reports/category/CategoryHeatmap.vue';
import ResetFiltersButton from '../../components/common/ResetFiltersButton.vue';
import permissionMixin from '../../mixins/permissionMixin';
import spaceService from '../../services/spaceService';
import NoAccessPlaceholder from '../../components/common/NoAccessPlaceholder.vue';

export default {
    name: 'producer-reports-category',
    components: {
        ResetFiltersButton,
        NoAccessPlaceholder,
        CategoryHeatmap,
        CategoryTraffic,
        CategorySales,
        CategorySummary,
        CategoryRanking,
        DataPlaceholder,
        DateRangePicker,
        SpaceSwitch,
        PageHeaderFilters,
    },
    mixins: [
        permissionMixin,
    ],
    data() {
        return {
            permissionName: spaceService.permissions.STATS_CATEGORIES,
            spaceInitialized: false,
            loadingConfig: false,
            error: null,
            dateFilter: {
                startDate: null,
                endDate: null,
                compareStartDate: null,
                compareEndDate: null,
            },
            isDatepickerFilterDefault: true,
            isTrafficFilterDefault: true,
            isSalesFilterDefault: true,
            isHeatmapFilterDefault: true,
            isSummaryFilterDefault: true,
            isRankingFilterDefault: true,
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.loadingConfig;
        },
        isResetActive() {
            return !this.isDatepickerFilterDefault || !this.isTrafficFilterDefault || !this.isSalesFilterDefault || !this.isHeatmapFilterDefault;
        },
        ...mapState({
            spacesLoading: state => state.space.loading.fetchSpaces,
            spaceId: state => state.space.currentSpaceId,
            categoriesIds: state => state.space.categoriesIds,
            categories: state => state.space.categories,
        }),
    },
    methods: {
        handleFilterDefaultChange(type, isDefault) {
            if (type === 'datepicker') {
                this.isDatepickerFilterDefault = isDefault;
            } else if (type === 'traffic') {
                this.isTrafficFilterDefault = isDefault;
            } else if (type === 'sales') {
                this.isSalesFilterDefault = isDefault;
            } else if (type === 'heatmap') {
                this.isHeatmapFilterDefault = isDefault;
            } else if (type === 'summary') {
                this.isSummaryFilterDefault = isDefault;
            } else if (type === 'ranking') {
                this.isRankingFilterDefault = isDefault;
            }
        },
        async getData() {
            this.checkPermission(this.permissionName);
            if (!this.hasPermission) {
                return;
            }
            this.loadingConfig = true;
            await this.getCategories();
            await this.getCampaigns();
            this.loadingConfig = false;
        },
        async getCategories() {
            try {
                await this.fetchProducerProductCategories();
            } catch (e) {
                this.error = e.message;
            }
        },
        async getCampaigns() {
            try {
                this.error = null;
                await this.fetchPlugins();
                await this.fetchActiveCampaigns();
                await this.fetchAvailableCampaigns();
            } catch (e) {
                this.error = e.message;
            }
        },
        handleSpaceChange() {
            this.getData();

            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
        },
        ...mapActions({
            fetchProducerProductCategories: 'space/fetchProducerProductCategories',
            fetchPlugins: 'plugin/fetchPlugins',
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
        }),
    },
};
</script>

<style lang="scss">
</style>
