<template>
    <div>
        <div v-if="!isReady" class="text-xs-center">
            <v-progress-circular indeterminate color="primary" />
        </div>
        <GChart
            type="Table"
            :settings="{ packages: ['table'], language: $i18n.locale }"
            :data="data"
            :options="chartOptions"
            :events="chartEvents"
        />
    </div>
</template>

<script>
import { merge } from 'lodash';
import { GChart } from 'vue-google-charts';

export default {
    name: 'table-graph',
    components: {
        GChart,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            baseOptions: {
                allowHtml: true,
                alternatingRowStyle: false,
                width: '100%',
                sortColumn: -1,
                sortAscending: false,
            },
            chartEvents: {
                ready: () => {
                    this.isReady = true;
                },
                sort: (e) => {
                    if (this.baseOptions.sortColumn === e.column && this.baseOptions.sortAscending === false) {
                        this.baseOptions.sortAscending = !this.baseOptions.sortAscending;
                    } else if (this.baseOptions.sortColumn === e.column && this.baseOptions.sortAscending === true) {
                        this.baseOptions.sortAscending = false;
                        this.baseOptions.sortColumn = -1;
                    } else {
                        this.baseOptions.sortAscending = false;
                        this.baseOptions.sortColumn = e.column;
                    }
                },
            },
            isReady: false,
        };
    },
    computed: {
        chartOptions() {
            return merge(this.baseOptions, this.options);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
