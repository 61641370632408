<template>
    <v-dialog v-model="isOpen" :persistent="deactivatePermissionLoading" max-width="500">
        <template v-slot:activator="{ on }">
            <div v-on="on" class="trigger-container">
                <v-btn
                    v-on="on"
                    class="cta-button"
                    type="button"
                    color="grey"
                    flat
                    small
                >
                    {{ $t('cancelSubscriptionModal.ctaLabel') }} >
                </v-btn>
            </div>
        </template>

        <v-card>
            <v-card-title class="headline" primary-title>
                {{ $t('cancelSubscriptionModal.title') }}
            </v-card-title>

            <v-card-text>
                {{ $t('cancelSubscriptionModal.description') }}

                <v-alert
                    :value="error"
                    dismissible
                    type="error"
                    transition="scale-transition">
                    {{ error }}
                </v-alert>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="tertiary"
                    flat
                    :disabled="deactivatePermissionLoading"
                    @click="cancel"
                >
                    {{ $t('cancelSubscriptionModal.cancel') }}
                </v-btn>
                <v-btn
                    color="tertiary"
                    dark
                    round
                    :loading="deactivatePermissionLoading"
                    :disabled="deactivatePermissionLoading"
                    @click="deactivate"
                >
                    {{ $t('cancelSubscriptionModal.submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'cancel-subscription-modal',
    props: {
        permissionId: {
            type: Number,
            required: true,
        },
        permissionName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
            error: null,
        };
    },
    computed: {
        ...mapState({
            deactivatePermissionLoading: state => state.space.loading.deactivatePermission,
        }),
    },
    methods: {
        cancel() {
            this.isOpen = false;
        },
        async deactivate() {
            try {
                this.error = null;
                await this.deactivatePermission({
                    permissionId: this.permissionId,
                    permissionName: this.permissionName,
                });
                this.isOpen = false;
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            deactivatePermission: 'space/deactivatePermission',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .trigger-container {
        display: inline-block;
    }

    .cta-button {
        text-transform: none;
    }
</style>
