<template>
    <v-layout class="access-container mb-3">
        <img
            v-if="image"
            :src="image"
            alt="Sample report preview"
            class="access-image"
        />
        <div class="access-button-container" :class="{'access-button-container--top': ctaPosition === 'top', 'access-button-container--hovered': ctaHovered}">
            <activate-report-modal
                :permission-name="permissionName"
                :title="title"
                :description="description"
                :cta-url="ctaUrl"
                :cta-text="ctaText"
                :cta-label="ctaLabel"
                :cta-button-description="ctaButtonDescription"
                :contact-form="contactForm"
                :usercom-report-type="usercomReportType"
                @activated="handleActivated"
                @activate="$emit('activate')" />
        </div>
    </v-layout>
</template>

<script>
import ActivateReportModal from './ActivateReportModal.vue';

export default {
    name: 'no-access-placeholder',
    components: {
        ActivateReportModal,
    },
    props: {
        permissionName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        image: {
            type: String,
        },
        ctaPosition: {
            validator: value => ['top', 'center'].includes(value),
            default: 'center',
        },
        ctaUrl: {
            type: String,
        },
        ctaText: {
            type: String,
            default: null,
        },
        ctaLabel: {
            type: String,
            default() {
                return this.$t('activateReportModal.ctaLabel');
            },
        },
        ctaButtonDescription: {
            type: String,
            default: null,
        },
        ctaHovered: {
            type: Boolean,
            default: false,
        },
        contactForm: {
            type: Boolean,
            default: false,
        },
        usercomReportType: {
            type: String,
            default: null,
        },
    },
    methods: {
        handleActivated() {
            this.$emit('activated');
        },
    },
};
</script>

<style lang="scss" scoped>
    .access-container {
        position: relative;
    }

    .access-image {
        width: 100%;
        height: auto;
    }

    .access-button-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .access-button-container--top {
        height: 500px;
    }

    .access-button-container--hovered {
        display: none;
        z-index: 10;
    }

    .access-container {
        &:hover {
            .access-button-container--hovered {
                display: block;
            }
        }
    }
</style>
