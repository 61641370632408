/* eslint-disable */
import apiService from '@/services/apiService';
import errorService from '@/services/errorService';
import demoService from '@/services/demoService';
import reportsCompetitionServiceDemo from '@/services/demo-services/reportsCompetitionServiceDemo';
import { difference } from 'lodash';
import exportService from './exportService';

const reportsCompetitionService = {
    async fetchCompetitionReport(spaceId, startDate, endDate, products, aggregations, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/competition-report`,
                {
                    startDate,
                    endDate,
                    products,
                    aggregations,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSets(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/competition-report-set`);
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createSet(spaceId, producerProduct, producerProducts, globalProducts) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/competition-report-set`, {
                producerProduct,
                producerProducts,
                globalProducts,
            });
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateSet(setId, producerProduct, producerProducts, globalProducts) {
        try {
            const response = await apiService.patch(`${process.env.VUE_APP_API_URL}/api/v1/competition-report-set/${setId}`, {
                producerProduct,
                producerProducts,
                globalProducts,
            });
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
    async removeSet(setId) {
        try {
            const response = await apiService.delete(`${process.env.VUE_APP_API_URL}/api/v1/competition-report-set/${setId}`);
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    hasSetChanged(set1, set2) {
        if (set1?.length !== set2?.length) {
            return true;
        }
        if (set1.producerProduct?.id !== set2.producerProduct?.id) {
            return true;
        }
        if (set1.producerProducts?.length !== set2.producerProducts?.length) {
            return true;
        }
        if (set1.globalProducts?.length !== set2.globalProducts?.length) {
            return true;
        }
        const { products: set1Products, aggregations: set1Aggregations } = this.getSetsProductsAggregationsIds([set1]);
        const { products: set2Products, aggregations: set2Aggregations } = this.getSetsProductsAggregationsIds([set2]);
        const productsDiff1 = difference(set1Products, set2Products).length;
        const productsDiff2 = difference(set2Products, set1Products).length;
        if (productsDiff1 !== 0 || productsDiff2 !== 0) {
            return true;
        }
        const aggregationsDiff1 = difference(set1Aggregations, set2Aggregations).length;
        const aggregationsDiff2 = difference(set2Aggregations, set1Aggregations).length;
        if (aggregationsDiff1 !== 0 || aggregationsDiff2 !== 0) {
            return true;
        }
        return false;
    },

    getSetsProductsAggregationsIds(sets) {
        const products = new Set();
        const aggregations = new Set();
        sets.forEach(set => {
            products.add(set.producerProduct.id);
            set.producerProducts.forEach(prod => {
                products.add(prod.id);
            });
            set.globalProducts.forEach(prod => {
                aggregations.add(prod.id);
            });
        });
        return {
            products: Array.from(products),
            aggregations: Array.from(aggregations),
        };
    },

    async downloadCompetitionReport(report) {
        try {
            const reportId = report.id;
            const formattedFilename = `competition-report-${report.startDate}-${report.endDate}-${report.space.name}`;
            await exportService.exportDataAuthorized(
                `${process.env.VUE_APP_API_URL}/api/v1/competition-report-export/${reportId}/download`,
                formattedFilename,
                'xlsx',
            );
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
    async generateCompetitionReport(spaceId, startDate, endDate, compareStartDate, compareEndDate, campaigns, sets) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/competition-report-export`,
                {
                    startDate,
                    endDate,
                    campaigns,
                    sets,
                    compareStartDate,
                    compareEndDate
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
    async fetchCompetitionReports(spaceId, pagination) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/competition-report-export`,
                { perPage: pagination.perPage, page: pagination.page},
            );
            response.data.items.map(item => {
                // eslint-disable-next-line no-param-reassign
                item.max = response.data.max;
                return item;
            });
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};

demoService.demoProxy(reportsCompetitionService, reportsCompetitionServiceDemo, 'reportsCompetitionService');
export default reportsCompetitionService;
