import Vue from 'vue';
import Vuex from 'vuex';
import usercomService from '@/services/usercomService';
import authModule from './modules/authModule';
import dictionaryModule from './modules/dictionaryModule';
import spaceModule from './modules/spaceModule';
import productsModule from './modules/productsModule';
import dateRangePickerModule from './modules/dateRangePickerModule';
import dashboardModule from './modules/dashboardModule';
import pluginModule from './modules/pluginModule';
import reportsProductsModule from './modules/reportsProductsModule';
import reportsCategoryModule from './modules/reportsCategoryModule';
import reportsSalesModule from './modules/reportsSalesModule';
import reportsShopsModule from './modules/reportsShopsModule';
import reportsAvailabilityModule from './modules/reportsAvailabilityModule';
import reportsPriceDistributionModule from './modules/reportsPriceDistributionModule';
import reportsCompetitionModule from './modules/reportsCompetitionModule';
import dataExportModule from './modules/dataExportModule';
import producerProductGroupModule from './modules/producerProductGroupModule';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    modules: {
        auth: authModule,
        dictionary: dictionaryModule,
        space: spaceModule,
        products: productsModule,
        dashboard: dashboardModule,
        plugin: pluginModule,
        reportsProducts: reportsProductsModule,
        reportsCategory: reportsCategoryModule,
        reportsSales: reportsSalesModule,
        reportsShops: reportsShopsModule,
        reportsCompetition: reportsCompetitionModule,
        reportsAvailability: reportsAvailabilityModule,
        reportsPriceDistribution: reportsPriceDistributionModule,
        dateRangePicker: dateRangePickerModule,
        dataExport: dataExportModule,
        producerProductGroup: producerProductGroupModule,
    },
    strict: debug,
    plugins: [],
});

store.watch(
    () => store.getters['auth/getUser'],
    (user, prevUser) => {
        if (user?.id !== prevUser?.id || !window.UE?.isReady) {
            const userData = usercomService.getUserDataFromUser(user);
            usercomService.resetAuth(userData);
        } else if (user?.id) {
            const userData = usercomService.getUserDataFromUser(user);
            usercomService.clientUpdate(userData);
        }
    },
    { immediate: true },
);

store.watch(
    () => store.getters['auth/isAuthenticated'],
    isAuthenticated => {
        usercomService.user.isAuthenticated = isAuthenticated;
    },
    { immediate: true },
);

store.watch(
    () => store.getters['auth/isAdmin'],
    isAdmin => {
        usercomService.user.isAdmin = isAdmin;
    },
    { immediate: true },
);

export default store;
