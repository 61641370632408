/* eslint-disable no-unused-vars */
import { filterDates, generateDates } from '@/services/demo-services/_utils';

export default {
    async fetchSalesChart(spaceId, startDate, endDate, groupBy, sources, group) {
        if (groupBy === 'month') {
            const { default: spaceSalesChartMonth } = await import('../../mocks/spaceSalesChartMonth.json');
            return filterDates(generateDates(spaceSalesChartMonth, undefined, 'month'), undefined, 'month');
        }
        if (groupBy === 'quarter') {
            const { default: spaceSalesChartQuarter } = await import('../../mocks/spaceSalesChartQuarter.json');
            return filterDates(generateDates(spaceSalesChartQuarter, undefined, 'quarter'), undefined, 'quarter');
        }
        if (groupBy === 'year') {
            const { default: spaceSalesChartYear } = await import('../../mocks/spaceSalesChartYear.json');
            return filterDates(generateDates(spaceSalesChartYear, undefined, 'year'), undefined, 'year');
        }
        const { default: spaceSalesChart } = await import('../../mocks/spaceSalesChart.json');
        return filterDates(generateDates(spaceSalesChart));
    },

    async fetchAllSalesChart(spaceId, startDate, endDate, groupBy, sources, group, feature, conversionTime) {
        if (groupBy === 'month') {
            const { default: spaceSalesChartMonth } = await import('../../mocks/spaceSalesAllChartMonth.json');
            return filterDates(generateDates(spaceSalesChartMonth, undefined, 'month'), undefined, 'month');
        }
        if (groupBy === 'quarter') {
            const { default: spaceSalesChartQuarter } = await import('../../mocks/spaceSalesAllChartQuarter.json');
            return filterDates(generateDates(spaceSalesChartQuarter, undefined, 'quarter'), undefined, 'quarter');
        }
        if (groupBy === 'year') {
            const { default: spaceSalesChartYear } = await import('../../mocks/spaceSalesAllChartYear.json');
            return filterDates(generateDates(spaceSalesChartYear, undefined, 'year'), undefined, 'year');
        }
        const { default: spaceSalesChart } = await import('../../mocks/spaceSalesAllChart.json');
        return filterDates(generateDates(spaceSalesChart));
    },

    async fetchSummary(spaceId, startDate, endDate, conversion, campaigns, groupBy, sources, group) {
        if (groupBy === 'month') {
            const { default: spaceProducerSummaryMonth } = await import('../../mocks/spaceProducerSummaryMonth.json');
            return filterDates(generateDates(spaceProducerSummaryMonth, undefined, 'month'), undefined, 'month');
        }
        if (groupBy === 'quarter') {
            const { default: spaceProducerSummaryQuarter } = await import('../../mocks/spaceProducerSummaryQuarter.json');
            return filterDates(generateDates(spaceProducerSummaryQuarter, undefined, 'quarter'), undefined, 'quarter');
        }
        if (groupBy === 'year') {
            const { default: spaceProducerSummaryYear } = await import('../../mocks/spaceProducerSummaryYear.json');
            return filterDates(generateDates(spaceProducerSummaryYear, undefined, 'year'), undefined, 'year');
        }
        const { default: spaceProducerSummary } = await import('../../mocks/spaceProducerSummary.json');
        return filterDates(generateDates(spaceProducerSummary));
    },

    async fetchProductDetails(spaceId, startDate, endDate, conversion, campaigns, sources, group) {
        const { default: spaceProducerTransactionsItems } = await import('../../mocks/spaceProducerTransactionsItems.json');
        return spaceProducerTransactionsItems;
    },

    async fetchTransactions(spaceId, startDate, endDate, conversion, campaigns, page, perPage, sort, order, group) {
        const { default: spaceProducerTransactions } = await import('../../mocks/spaceProducerTransactions.json');
        return spaceProducerTransactions;
    },

    async fetchFeatureBySourceChart(spaceId, startDate, endDate, sources, feature = 'amount') {
        if (feature === 'amount') {
            const { default: data } = await import('../../mocks/featureBySourceChartAmount.json');
            return data;
        }
        if (feature === 'quantity') {
            const { default: data } = await import('../../mocks/featureBySourceChartQuantity.json');
            return data;
        }
        if (feature === 'orders') {
            const { default: data } = await import('../../mocks/featureBySourceChartOrders.json');
            return data;
        }
        if (feature === 'widget_impressions') {
            const { default: data } = await import('../../mocks/featureBySourceChartWidgetImpressions.json');
            return data;
        }
        if (feature === 'clicks') {
            const { default: data } = await import('../../mocks/featureBySourceChartClicks.json');
            return data;
        }
        if (feature === 'ctr') {
            const { default: data } = await import('../../mocks/featureBySourceChartCtr.json');
            return data;
        }
        if (feature === 'cr') {
            const { default: data } = await import('../../mocks/featureBySourceChartCr.json');
            return data;
        }
        return {};
    },
};
