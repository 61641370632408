<template>
    <v-app>
        <div id="app-wrapper">
            <splash-screen v-if="loading" />
            <router-view v-if="!loading" />
        </div>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import SplashScreen from './components/common/SplashScreen.vue';

export default {
    name: 'app',
    components: {
        SplashScreen,
    },
    mounted() {
        window.getVersion = function () { return { hash: process.env.GIT_HASH, commit: process.env.GIT_COMMIT_MESSAGE, date: process.env.GIT_COMMIT_DATE }; };
    },
    computed: {
        loading() {
            return !this.isSessionInitialized;
        },
        ...mapGetters({
            isSessionInitialized: 'auth/isSessionInitialized',
        }),
    },
};
</script>

<!--suppress CssUnusedSymbol -->
<style lang="scss">
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: $bg-dark-color;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    .theme--light.v-sheet,
    .theme--light.v-list {
        color: $bg-dark-color;
    }

    .theme--light.v-list .v-list__tile--link:hover,
    .theme--light.v-list .v-list__group__header:hover:hover {
        background: darken($bg-light-color, 10);
    }

    .v-list__group__header__append-icon .v-icon {
        font-size: 12px;
    }

    .v-text-field--outline > .v-input__control > .v-input__slot {
        background: #ffffff !important;
    }

    .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
        border: 1px solid #c8c8c8;
    }

    .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
        border: 1px solid #c8c8c8;
    }

    .theme--light.v-tabs__bar {
        background: transparent;
    }

    .v-tabs__container {
        .v-tabs__div:first-child .v-tabs__item {
            border-radius: 5px 0 0 0;
        }

        .v-tabs__div:last-child .v-tabs__item {
            border-radius: 0 5px 0 0;
        }
    }

    .v-tabs__item:not(.v-tabs__item--active) {
        opacity: 1;
    }

    .v-tabs__item {
        background: #ffffff;
    }

    .v-tabs__item--active {
        background-color: $primary-color;
        color: #ffffff;
    }

    .v-treeview-node__content {
        padding: 5px 0;
    }

    .v-treeview-node__content, .v-treeview-node__label {
        flex-shrink: 1;
        word-break: break-word;
    }
    .v-treeview-node__root {
        height: auto;
        min-height: 34px;
    }

    .v-datatable thead th.column.sortable .v-icon {
        margin-left: 5px;
    }

    .table--transparent {
        .v-table,
        .v-datatable .v-datatable__actions {
            background-color: transparent !important;
        }
    }

    .table--fixed {
        .v-table {
            table-layout: fixed;
        }
    }

    .table--clickable {
        tr {
            cursor: pointer;
        }
    }

    .select--responsive {
        min-width: 200px;

        .v-select__selection.v-select__selection--comma {
            white-space: nowrap !important;
        }

        input {
            width: 0 !important;
        }
    }

    .tooltip {
        padding: 10px;
        background: #ffffff;
        border: 1px solid $tertiary-color;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        color: $text-dark-color;
    }

    // disabled dark button on light background
    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
        background-color: rgba(0, 0, 0, .12) !important;
    }
    .theme--dark.v-btn.v-btn--disabled .v-btn__loading {
        color: rgba(0, 0, 0, .26) !important;
    }

    // google charts
    .google-chart--tooltip-left .google-visualization-tooltip {
        top: 35px !important;
        left: 80px !important;
        right: auto !important;
    }

    .google-chart--tooltip-right .google-visualization-tooltip {
        top: 35px !important;
        left: auto !important;
        right: 80px !important;
    }

    .google-visualization-table-table,
    .google-visualization-table-tr,
    .google-visualization-table-tr-even,
    .google-visualization-table-tr-odd,
    .google-visualization-table-tr-sel {
        background: transparent !important;
    }

    .google-visualization-table-th {
        background: $tertiary-color !important;
        border: none !important;
        color: $text-light-color !important;

        &:first-child {
            text-align: left;
        }

        &:not(first-child) {
            text-align: right;
        }
    }

    .google-visualization-table-td {
        border: none !important;
    }

    .google-visualization-tooltip-item-list {
        max-height: 400px;
        margin-top: 0 !important;
        overflow: auto;
    }

    .google-visualization-tooltip-item {
        padding-right: 20px !important;
        white-space: nowrap;
    }

    .google-visualization-tooltip-action-list {
        padding: 0 0 2px !important;
        overflow: hidden;
    }

    .google-visualization-tooltip-action {
        min-width: 275px;
        margin-right: 5px;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &[data-logicalname]:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 10px;
        }

        &[data-logicalname='action#reset']:before {
            content: "\f0e2";
        }

        &[data-logicalname='action#date']:before {
            content: "\f783";
        }
    }

    .icon-smallest {
        font-size: 10px;
    }

    .text-transform-off {
        text-transform: none;
    }

    .btn-wide {
        padding-left: 60px;
        padding-right: 60px;
    }

    .list--unstyled {
        padding-left: 0;
        list-style-type: none;
    }

    .external-link-icon {
        line-height: 16px !important;
        font-size: 12px !important;
    }

    .wrap-text {
        white-space: pre-wrap;
    }

    .no-wrap-text {
        white-space: nowrap;
    }

    .text--overflow-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .full-width {
        width: 100%;
    }

    #app-wrapper {
        height: 100%;
    }

    mark {
        background-color: var(--v-secondary-lighten3);
    }

    .pre-formatted {
        white-space: pre-wrap;
    }

    .left-filters {
        display: inline-flex;
        align-items: center;
    }

    .treeview-select-menu {
        div[role=listitem]:not(:first-child) {
            display: none !important;
        }

        .v-treeview-node__root {
            position: relative;
            min-height: 40px;
            cursor: pointer;
            padding-left: 12px;
            padding-right: 24px;
        }

        .treeview-item-click-layer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .v-treeview-node__toggle {
            z-index: 1;
            margin-right: 12px;
        }

        .v-treeview-node__checkbox {
            font-size: 22px;
        }

        .v-treeview-node__label {
            font-size: 13px;
            margin-left: 32px;
        }
    }
</style>
