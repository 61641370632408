<template>
    <div v-if="bbId">
        <div
            id="bb-widget-preview"
            class="bb-widget"
            data-bb-stats="0"
            :data-bb-type="bbType"
            :data-bb-language="bbLanguage"
            :data-bb-id="bbId"
            :data-bb-oid="bbOfferId"
            :data-bb-number="bbNumber"
            :data-bb-name="bbName"
            :data-bb-info="bbInfo"
            :data-bb-shop-style="bbShopStyle"
            :data-bb-lead-color="bbLeadColor"
            :data-bb-button-label="bbButtonLabel"
            :data-bb-row-count="bbRowCount"
            :data-bb-show-extra="bbShowExtraValue"
            :data-bb-show-prices="bbShowPricesValue"
            :data-bb-abpar1="bbAbpar1"
            :data-bb-abpar2="bbAbpar2"
            :data-bb-abpar3="bbAbpar3"
            data-bb-alt="bb-alt"
        >
            <a v-if="hasTrigger" href="#">
                {{ $t('widgetPreview.show') }}
            </a>
        </div>
        <div id="bb-alt" class="title text-xs-center" style="display: none;">{{ $t('widgetPreview.noOffers') }}</div>
    </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
    name: 'WidgetPreview',
    props: {
        bbType: {
            validator: (value) => ['popover', 'overlay', 'logos-only'].includes(value),
        },
        bbLanguage: {
            type: String,
        },
        bbId: {
            type: Number,
            required: true,
        },
        bbOfferId: {
            type: Number,
        },
        bbNumber: {
            type: [Number, String],
        },
        bbName: {
            type: String,
        },
        bbInfo: {
            type: String,
        },
        bbShopStyle: {
            type: String,
        },
        bbLeadColor: {
            type: String,
        },
        bbButtonLabel: {
            type: String,
        },
        bbRowCount: {
            type: Number,
        },
        bbShowExtra: {
            type: Boolean,
        },
        bbShowPrices: {
            type: Boolean,
        },
        bbAbpar1: {
            type: String,
        },
        bbAbpar2: {
            type: String,
        },
        bbAbpar3: {
            type: String,
        },
    },
    computed: {
        bbShowExtraValue() {
            if (!this.bbShowExtra && this.bbShowExtra !== 0) {
                return undefined;
            }
            return this.bbShowExtra ? 'product_name-info-img' : 'false';
        },
        bbShowPricesValue() {
            if (!this.bbShowPrices && this.bbShowPrices !== 0) {
                return undefined;
            }
            return this.bbShowPrices ? 'true' : 'false';
        },
        hasTrigger() {
            return ['popover', 'overlay'].includes(this.bbType);
        },
    },
    methods: {
        loadWidgetScript() {
            if (window.bb) {
                return;
            }
            const script = document.createElement('script');
            script.src = process.env.VUE_APP_WIDGET_SCRIPT_URL;
            script.async = true;
            document.body.appendChild(script);
        },
        reloadBB: debounce(function debouncedReloadBB() {
            const altEl = document.getElementById(`bb-alt${this.bbId}`);
            if (altEl) {
                altEl.remove();
            }
            if (window.bb) {
                window.bb.forceReload();
            }
        }, 1000),
    },
    mounted() {
        this.reloadBB();
        this.loadWidgetScript();
    },
    updated() {
        this.reloadBB();
    },
};
</script>

<style></style>
