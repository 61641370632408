<template>
    <v-autocomplete
        ref="input"
        v-model="selected"
        :class="fullClassName"
        :search-input.sync="search"
        :loading="loading"
        :items="items.length ? items : defaultItems"
        :filter="filter"
        :multiple="multiple"
        :label="label"
        :disabled="disabled"
        item-text="text"
        item-value="id"
        placeholder=" "
        hide-details
        outline
        chips
        small-chips
        deletable-chips
        :hide-no-data="!search || search.length < 3 || loading"
        dense
        return-object
        autocomplete="off"
        @input="handleInput"
        @change="handleChange"
        @blur="handleBlur"
        @focus="handleFocus"
    >
        <template v-slot:item="data">
            <v-list-tile-action v-if="multiple" class="product-selector__action">
                <v-checkbox :value="data.item" :value-comparator="compareValue" @click="handleCheckboxClick" />
            </v-list-tile-action>
            <v-list-tile-avatar>
                <img v-if="data.item.img" :src="data.item.img">
                <v-icon v-if="!data.item.img">fa-image</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
                <v-list-tile-title>{{ data.item.text }}</v-list-tile-title>
                <v-list-tile-sub-title v-if="publisherId">
                    {{ $t('productSelector.productId') }}: {{ data.item.entityId }}, {{ $t('productSelector.offerId') }}: {{ data.item.offerId }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title v-else>
                    {{ $t('productSelector.ean') }}: {{ data.item.ean }}, {{ $t('productSelector.sku') }}: {{ data.item.sku }}, {{ $t('productSelector.productId') }}: {{ data.item.productId }}
                </v-list-tile-sub-title>
            </v-list-tile-content>
        </template>

        <template v-slot:no-data>
            <section  class="product-selector__nodata ">
                <p>{{ $t('productSelector.noData') }} <a v-if="!publisherId" target="_blank" :href="$t('productSelector.noDataLinkUrl')" >{{ $t('productSelector.noDataLink') }}</a></p>
            </section>
        </template>
    </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
    debounce, deburr, difference, orderBy, trim,
} from 'lodash';

export default {
    name: 'product-selector',
    props: {
        // componentId used to differentiate between loading states
        componentId: {
            type: String,
            required: true,
        },
        value: {
            type: [Array, Object],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default() {
                return this.$t('productSelector.label');
            },
        },
        className: {
            type: String,
            default: '',
        },
        category: {
            type: Array,
            default: () => [],
        },
        defaultItems: {
            type: Array,
            default: () => [],
        },
        // will trigger search of products not belonging to producer
        publisherId: {
            type: Number,
        },
    },
    data() {
        return {
            search: null,
            items: [],
            selected: null,
            internalLoading: false,
        };
    },
    computed: {
        // internalLoading is used to mitigate no-data message flickering.
        // without it the message would be shown for a short time when search text is inputted but debounce is still waiting for real the loading to start
        loading() {
            return (this.loadingAll && this.loadingAll[this.componentId]) || this.internalLoading;
        },
        fullClassName() {
            const className = ['product-selector'];
            if ((!this.multiple && this.selected) || (this.multiple && this.selected && this.selected.length)) {
                className.push('product-selector--has-selected');
            }
            if (this.className) {
                className.push(this.className);
            }
            return className;
        },
        ...mapState({
            loadingAll: state => state.products.loading.findProducts,
        }),
    },
    watch: {
        value(newValue, oldValue) {
            if (this.multiple && Array.isArray(newValue) && Array.isArray(oldValue)) {
                const diff1 = difference(newValue, oldValue).length;
                const diff2 = difference(oldValue, newValue).length;
                if (diff1 > 0 || diff2 > 0) {
                    this.handleInput(newValue);
                }
            } else if (newValue !== oldValue) {
                this.handleInput(newValue);
            }
        },
        search() {
            this.$refs.input.cachedItems = [];
            this.items = [];
            const search = trim(this.search);
            if (search && search.length >= 3) {
                this.internalLoading = true;
            }
            this.handleSearch();
        },
        category(newValue, oldValue) {
            if (!this.selected) {
                return;
            }
            const diff1 = difference(newValue, oldValue).length;
            const diff2 = difference(oldValue, newValue).length;
            if (diff1 !== 0 || diff2 !== 0) {
                let newSelected;
                if (this.multiple) {
                    newSelected = this.selected.filter(item => this.hasValidCategory(item, newValue));
                } else {
                    newSelected = this.selected && this.hasValidCategory(this.selected, newValue) ? this.selected : null;
                }
                this.handleInput(newSelected);
                this.handleBlur();
            }
        },
        publisherId() {
            this.selected = this.multiple ? [] : null;
            this.$refs.input.cachedItems = [];
        },
    },
    created() {
        if (this.value) {
            this.selected = this.multiple && Array.isArray(this.value) ? [...this.value] : this.value;
        } else {
            this.selected = this.multiple ? [] : null;
        }
    },
    methods: {
        handleInput(value) {
            let sortedValue = value;
            if (this.multiple) {
                sortedValue = orderBy(value, [item => deburr(item.text).toLowerCase()], ['asc']);
            }
            this.selected = sortedValue;
            this.$emit('input', this.selected);
        },
        handleChange() {
            this.$emit('change');
        },
        handleBlur() {
            this.$emit('blur');
        },
        handleFocus() {
            // hacky workaround for autocomplete not triggering filtering on focus for internally cached items
            if (this.category.length > 0) {
                this.$refs.input.cachedItems = this.$refs.input.cachedItems.filter(item => this.hasValidCategory(item));
            }
        },
        handleSearch: debounce(function handleSearch() {
            this.getProducts();
        }, 450),
        async getProducts() {
            try {
                this.items = [];
                const search = trim(this.search);
                if (search.length < 3) {
                    return;
                }
                const items = await this.fetchFindProducts({
                    componentId: this.componentId,
                    publisherId: this.publisherId,
                    search,
                });
                // set items only if requested search is equal to current search
                if (search === trim(this.search)) {
                    this.items = items;
                }
            } catch (e) {
                this.error = e.message;
            }

            this.internalLoading = false;
        },
        hasValidCategory(item, categories) {
            const category = categories || this.category;
            // check only if there are any provided categories
            if (!category || category.length === 0) {
                return true;
            }
            // check if product belongs to at least one of the selected categories
            return category.some(categoryId => item.categories.some(categoryBranch => categoryBranch.some(branchCategory => branchCategory.id === categoryId)));
        },
        filter(item, queryText) {
            function isValidEAN(ean) {
                const re13 = /^\d{13}$/;
                const re8 = /^\d{8}$/;
                return re13.test(ean) || re8.test(ean);
            }
            // check if search query matches product name, sku, ean or id
            let isValid = (item.text && item.text.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())) || (item.sku && item.sku.includes(queryText)) || (item.ean && item.ean.includes(queryText)) || (item.productId && item.productId.toString().includes(queryText));

            // backend doesnt return ean for all products, so we need to check if it is valid
            if (isValidEAN(queryText)) {
                isValid = true;
            }

            // check product category matches filter
            if (isValid && this.category.length > 0 && !this.hasValidCategory(item)) {
                isValid = false;
            }
            return isValid;
        },
        handleCheckboxClick() {
            // block default click behaviour
        },
        compareValue(item) {
            return this.selected.some(sel => sel.id === item.id);
        },
        ...mapActions({
            fetchFindProducts: 'products/fetchFindProducts',
        }),
    },
};
</script>

<style lang="scss">
    .product-selector .v-progress-linear {
        height: 3px !important;
        border-radius: 3px;
    }

    .product-selector--has-selected input {
        width: 100px !important;
    }

    .product-selector__action {
        min-width: 0;
    }

    .product-selector__nodata {
        padding: 16px;
    }

    .product-selector__nodata p {
        margin-bottom: 0;
    }
</style>
