<template>
    <div>
        <column-graph
            ref="columnGraphValue"
            :title="$t('salesByPriceRange')"
            :data="chartValueDataFiltered"
            :events="chartEvents"
            :options="chartValueOptions"
            :loading="loading || filtersLoading"
            :error="error"
            :no-results-title="$t('salesGraph.noResults')"
            :tooltip="$t('salesGraph.tooltip')"
            style="margin-bottom:0 !important;"
        >
            <template #filters>
                <div>
                    <v-layout wrap align-center justify-end>
                        <v-flex shrink>
                            <SelectAll
                                v-if="spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)"
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="sources"
                                @blur="handleSourcesChange"
                                :items="sourcesOptions"
                                :disabled="loading || filtersLoading"
                                placeholder=" "
                                :label="$t('filters.sources')"
                                outline
                                hide-details
                                dense
                                multiple
                                :loading="uniqueSourcesLoading"
                            />
                            <FormSourcesPlaceholder v-else />
                        </v-flex>
                        <v-flex shrink>
                            <v-select
                                class="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="priceRange"
                                :items="[...priceRangesSelectFormat, { value: priceRange, text: priceRange, disabled: true }]"
                                :disabled="loading || filtersLoading"
                                placeholder=" "
                                :label="$t('priceRanges')"
                                outline
                                hide-details
                                dense
                                :menu-props="{ contentClass: 'sales-price-range-select' }"
                            >
                                <template v-slot:selection>
                                    {{ $t('everyXCurrency', { value: priceRange, currency: currencySymbol }) }}
                                </template>
                                <template v-slot:append-item>
                                    <div class="mx-2">
                                        <v-text-field
                                            v-model.number="priceRange"
                                            type="number"
                                            outline
                                            hide-details
                                            single-line
                                            class="pa-2"
                                            @keydown="validNumber" />
                                    </div>
                                </template>
                            </v-select>
                        </v-flex>
                        <v-flex v-show="showProductGroupsResults" shrink>
                            <select-all
                                class-name="d-inline-block mb-3 select--responsive"
                                v-model="group"
                                :items="groupOptions"
                                :disabled="loading"
                                :label="$t('productsRanking.group')"
                                :all-label="$t('productsRanking.allGroups')"
                                require-selection
                                @blur="getData()"
                            />
                        </v-flex>
                    </v-layout>
                    <div>
                        <v-layout wrap align-center justify-end>
                            <v-flex class="mb-3 ml-3" shrink>
                                <v-switch
                                    v-model="showProductGroupsResults"
                                    :label="$t('filters.showProductGroupsResults')"
                                    :disabled="loading || filtersLoading"
                                    @change="getData()"
                                />
                            </v-flex>
                        </v-layout>
                    </div>
                </div>
            </template>
        </column-graph>

        <column-graph
            ref="columnGraphQuantity"
            v-if="!(loading && filtersLoading) && chartQuantityDataFiltered"
            title=""
            no-results-title=""
            :data="chartQuantityDataFiltered"
            :events="chartEvents"
            :options="chartQuantityOptions"
        />
    </div>
</template>

<script>
import { sortBy } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import spaceService from '@/services/spaceService';
import FormSourcesPlaceholder from '@/components/common/FormSourcesPlaceholder.vue';
import sum from 'lodash/sum';
import SelectAll from '@/components/common/SelectAll.vue';
import sourcesFilterMixin from '@/mixins/sourcesFilterMixin';
import groupsFilterMixin from '@/mixins/groupsFilterMixin';
import numberService from '../../../../services/numberService';
import ColumnGraph from '../../../graphs/ColumnGraph.vue';

export default {
    name: 'sales-price-range',
    components: {
        ColumnGraph,
        SelectAll,
        FormSourcesPlaceholder,
    },
    mixins: [
        sourcesFilterMixin,
        groupsFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        const chartOptions = {
            isStacked: true,
            colors: [this.$vuetify.theme.primary, this.$vuetify.theme.secondary],
            tooltip: {
                trigger: 'hover',
            },
        };
        const chartValueOptions = {
            ...chartOptions,
            vAxes: {
                0: {
                    title: this.$t('salesValue'),
                },
            },
        };
        const chartQuantityOptions = {
            ...chartOptions,
            vAxes: {
                0: {
                    title: this.$t('numberOfItems'),
                },
            },
        };
        return {
            spaceService,
            error: null,
            chartOptions,
            chartEvents: {
                ready: () => {
                    if (this.$refs.columnGraphValue) this.$refs.columnGraphValue.$refs.chart.isReady = true;
                    if (this.$refs.columnGraphQuantity) this.$refs.columnGraphQuantity.$refs.chart.isReady = true;
                },
                select: () => {},
            },
            chartValueOptions,
            chartQuantityOptions,
            priceRange: 250,
            priceRanges: [10, 25, 50, 75, 100, 150, 200, 250],
            conversion: 'all',
            campaign: [],
            loading: false,
            productDetails: [],
            showProductGroupsResults: false,
        };
    },
    computed: {
        priceRangesSelectFormat() {
            return this.priceRanges.map(item => ({
                value: item,
                text: this.$t('everyXCurrency', { value: item, currency: this.currencySymbol }),
            }));
        },
        chartValueData() {
            return this.getChartData('value') || [];
        },
        chartValueDataFiltered() {
            const filtered = this.chartValueData.filter(item => item[1] !== 0);
            return filtered.length > 1 ? filtered : [];
        },
        chartQuantityData() {
            return this.getChartData('quantity') || [];
        },
        chartQuantityDataFiltered() {
            const filtered = this.chartQuantityData.filter(item => item[1] !== 0);
            return filtered.length > 1 ? filtered : [];
        },
        currencySymbol() {
            const locale = process.env.VUE_APP_SYSTEM_LOCALE || this.$i18n.locale;
            const currency = process.env.VUE_APP_SYSTEM_CURRENCY;
            return (0).toLocaleString(locale, {
                style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0,
            }).replace(/\d/g, '').trim();
        },
        filtersLoading() {
            return this.uniqueSourcesLoading || this.groupsLoading;
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
        }),
        ...mapGetters({
            getterProductDetails: 'reportsSales/getProductDetails',
        }),
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (!this.productDetails && JSON.stringify(newValue) !== JSON.stringify(oldValue)) this.getProductDetails();
        },
        filtersLoading(loading) {
            if (!loading) this.reset();
        },
    },
    methods: {
        reset() {
            this.setDefaultGroup();
            this.getProductDetails();
        },
        validNumber(e) {
            const val = Number.parseInt(e.key, 10);
            if (e.key !== 'Backspace' && e.key !== 'Enter' && (!Number.isInteger(val) || (!this.priceRange && val <= 0))) e.preventDefault();
        },
        getData(force = false) {
            this.getProductDetails(force);
        },
        getChartData(value = 'value') {
            const grouped = (this.productDetails || []).reduce((acc, cur) => {
                const data = acc;
                const rangeId = Math.ceil((cur.value) / this.priceRange) * this.priceRange;
                if (!data.range[rangeId]) data.range[rangeId] = {};
                if (!data.range[rangeId][cur.type]) data.range[rangeId][cur.type] = [];
                data.range[rangeId][cur.type].push(cur[value]);
                data.total += cur[value];
                return data;
            }, { range: {}, total: 0 });
            const chartData = Object.keys(grouped.range || {}).map(rangeIndex => {
                const label = `${rangeIndex - this.priceRange} - ${rangeIndex} ${this.currencySymbol}`;
                const productSum = sum(grouped.range[rangeIndex].product || []);
                const offerSum = sum(grouped.range[rangeIndex].offer || []);
                return [
                    label,
                    productSum,
                    `<div class="sales-price-range-tooltip">
                        <div class="item-color" style="background:${this.chartOptions.colors[0]}"></div>
                        <div class="item-label"${this.$t('manufacturerProducts')}</div>
                        <div class="item-value">${numberService.formatPercent(productSum * 100 / grouped.total)}</div>
                    </div>`,
                    offerSum,
                    `<div class="sales-price-range-tooltip">
                        <div class="item-color" style="background:${this.chartOptions.colors[1]}"></div>
                        <div class="item-label">${this.$t('others')}</div>
                        <div class="item-value">${numberService.formatPercent(offerSum * 100 / grouped.total)}</div>
                    </div>`,
                ];
            });
            if (!chartData.length) return null;
            return [
                ['Genre', this.$t('manufacturerProducts'), { type: 'string', role: 'tooltip', p: { html: true } }, this.$t('others'), { type: 'string', role: 'tooltip', p: { html: true } }],
                ...chartData,
            ];
        },
        async getProductDetails(force = false) {
            if (this.filtersLoading) return;
            this.loading = true;
            try {
                this.error = null;
                this.productDetails = [];
                const params = {
                    force,
                    startDate: this.dateFilter.startDate,
                    endDate: this.dateFilter.endDate,
                    conversion: this.conversion,
                    campaign: this.campaign,
                };
                if (this.sources.length) params.sources = sortBy(this.sources).join(',');
                if (this.showProductGroupsResults) {
                    if (this.group.length) params.group = sortBy(this.group).join(',');
                }
                await this.fetchProductDetails(params);
                this.productDetails = this.getterProductDetails(params.startDate, params.endDate, params.conversion, params.campaign, params.sources, params.group);
            } catch (e) {
                this.error = e.message;
            }
            this.loading = false;
        },
        handleSourcesChange() {
            this.getProductDetails();
        },
        ...mapActions({
            fetchProductDetails: 'reportsSales/fetchProductDetails',
        }),
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep {
        .google-visualization-tooltip {
            .sales-price-range-tooltip {
                display: flex;
                align-items: center;
                padding: 1rem;
                min-width: 100px;

                .item-color {
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                }

                .item-label {
                    margin-right: 5px;
                }
            }
        }
    }
</style>

<style lang="scss">
    .sales-price-range-select {
        .v-list--disabled {
            display: none;
        }
    }
</style>
