<template>
    <v-card v-if="!spaceService.hasPermission(permission)" elevation="5" class="source-no-permissions no-access-contact-form">
        <v-card-title>
            <div>
                <div v-html="description || $t('subscriptionNotIncludeSourceAnalytics')"></div>
                <div>{{ $t('leaveUsYourDetailsAndWeWillContactYou') }}</div>
            </div>
        </v-card-title>
        <div>
            <v-text-field v-model="formData.email" :label="$t('yourEmail')" outline />
            <v-text-field v-model="formData.phone" :label="$t('yourPhoneNumber')" outline />
        </div>
        <v-card-actions>
            <v-btn round color="tertiary" dark class="ma-auto" @click="contactSalesDepartment()">
                {{ $t('pleaseContact') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import spaceService from '@/services/spaceService';
import usercomService from '@/services/usercomService';

export default {
    name: 'NoAccessContactForm',
    props: {
        permission: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            spaceService,
            formData: {
                email: this.$store.state.auth.user.email,
                phone: this.$store.state.auth.user.phone,
            },
        };
    },
    methods: {
        contactSalesDepartment() {
            usercomService.userengage('event.platne-raporty', { 'raport-type': 'Sprzedaż', email: this.formData.email, telefon: this.formData.phone });
            this.$toast.success(this.$t('pleaseContactSent'));
            this.$emit('sent');
        },
    },
};
</script>

<style lang="scss" scoped>
    .no-access-contact-form {
        padding: 15px 20px;
    }
</style>
