<template>
    <div class="grey lighten-4 pa-4">
        <v-card-text>
            <h3 class="title font-weight-bold mb-2">{{ $t('pluginCode.title') }}</h3>
            <p class="caption description">{{ $t('pluginCode.description') }}</p>
            <p>
                <a :href="$t('pluginCode.url')" target="_blank">{{ $t('pluginCode.urlAnchor') }}</a>
            </p>
            <p class="identifier">{{ $t('pluginCode.pluginId') }} <span class="mx-3"></span> <span>{{ pluginId }}</span></p>
        </v-card-text>
    </div>
</template>

<script>
export default {
    name: 'plugin-code',
    data() {
        return {
            pluginId: this.$store.getters['plugin/plugin'].id,
        };
    },
};
</script>

<style lang="scss" scoped>
    .description {
        margin-bottom: 6px;
    }
    .identifier {
        font-size: 16px;
    }
</style>
