<template>
    <line-with-percentage-graph
        :title="$t('categoryTraffic.title')"
        :tooltip="(dataType === 'impressions') ? $t('categoryTraffic.tooltip') : ''"
        :percentage-section-title="$t('categoryTraffic.percentageSectionTitle')"
        :line-data="lineData"
        :line-options="lineOptions"
        :showTotal="!showSummarized"
        :area-data="lineData"
        :area-options="areaOptions"
        :pie-data="pieData"
        :loading="loading"
        :error="error"
        @redo="getData(true)">
        <template #filters>
            <v-layout column style="flex-grow:0;">
                <v-layout shrink>
                    <v-flex shrink>
                        <select-all
                            ref="categorySelect"
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="category"
                            :items="categoryOptions"
                            :itemsTree="showOnlyTopCategories ? undefined : categoryOptionsTree"
                            :disabled="loading"
                            :label="$t('categoryTraffic.category')"
                            :all-label="$t('categoryTraffic.allCategories')"
                            require-selection
                            @blur="handleCategoryBlur" />
                    </v-flex>
                    <v-flex shrink>
                        <select-all
                            class-name="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="campaign"
                            :items="campaignOptions"
                            :disabled="loading"
                            :label="$t('categoryTraffic.shop')"
                            :all-label="$t('categoryTraffic.allShops')"
                            require-selection
                            @blur="handleCampaignBlur" />
                    </v-flex>
                    <v-flex shrink>
                        <v-select
                            class="d-inline-block ml-3 mb-3 select--responsive"
                            v-model="dataType"
                            @change="getData()"
                            :items="dataTypeOptions"
                            :disabled="loading"
                            placeholder=" "
                            :label="$t('categoryTraffic.dataType')"
                            outline
                            hide-details
                            dense />
                    </v-flex>
                </v-layout>
                <v-layout shrink>
                    <v-switch
                        v-if="canGroupOthers"
                        v-model="showOnlyTopCategories"
                        :label="$t('filters.showOnlyTopCategories')"
                        :disabled="loading || showSummarized" />

                    <v-switch
                        v-model="showSummarized"
                        :label="$t('filters.showSummarized')"
                        :disabled="loading || showOnlyTopCategories" />
                </v-layout>
            </v-layout>
        </template>
    </line-with-percentage-graph>
</template>

<script>
import { max } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import LineWithPercentageGraph from '../../../graphs/LineWithPercentageGraph.vue';
import SelectAll from '../../../common/SelectAll.vue';
import eventBus from '../../../../services/eventBus';
import categoryFilterMixin from '../../../../mixins/categoryFilterMixin';
import numberService from '../../../../services/numberService';

export default {
    name: 'category-traffic',
    components: {
        SelectAll,
        LineWithPercentageGraph,
    },
    mixins: [
        campaignFilterMixin,
        categoryFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            data: null,
            dataType: 'impressions',
            dataTypeOptions: [
                {
                    value: 'impressions',
                    text: this.$t('categoryTraffic.impressionsOffer'),
                },
                {
                    value: 'clicks',
                    text: this.$t('categoryTraffic.clicks'),
                },
            ],
            error: null,
            lineData: null,
            pieData: null,
            showSummarized: false,
        };
    },
    computed: {
        loading() {
            return this.impressionsChartLoading || this.clicksChartLoading;
        },
        lineOptions() {
            const options = {
                vAxes: {
                    0: {
                        title: this.$t(`categoryTraffic.${this.dataType === 'impressions' ? 'impressionsOffer' : this.dataType}`),
                        viewWindow: {
                            max: !this.hasValueOverZero ? 1 : null,
                        },
                    },
                },
                height: 600,
            };

            if (this.hasValueOverZero) {
                // get all values
                const filteredData = this.data.chart.map(item => Object.keys(item).filter(key => key !== 'date').map(key => item[key]));
                // flatten array and get max value
                const maxValue = max([].concat(...filteredData));
                if (maxValue < 1000) {
                    options.vAxes[0].format = '#';
                }
            }

            return options;
        },
        areaOptions() {
            return {
                vAxes: {
                    0: {
                        viewWindow: {
                            max: !this.hasValueOverZero ? 1 : null,
                        },
                    },
                },
                height: 600,
            };
        },
        hasValueOverZero() {
            if (this.data && this.data.chart && this.data.chart.length > 0) {
                const hasValueOverZero = this.data.chart.some(item => Object.keys(item).filter(key => key !== 'date').some(key => item[key] > 0));
                if (hasValueOverZero) {
                    return true;
                }
            }
            return false;
        },
        ...mapState({
            impressionsChartLoading: state => state.reportsCategory.loading.impressionsChart,
            clicksChartLoading: state => state.reportsCategory.loading.clicksChart,
        }),
        ...mapGetters({
            getterImpressionsChart: 'reportsCategory/getImpressionsChart',
            getterClicksChart: 'reportsCategory/getClicksChart',
        }),
        categoryOptions() {
            return this.canGroupOthers && !this.$refs.categorySelect?.useTreeview ? this.categoryOptionsWithTopCategories : this.getterCategoryOptions;
        },
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        showOnlyTopCategories() {
            this.getData();
        },
        showSummarized() {
            this.getData();
        },
    },
    created() {
        this.setDefaultCategory();
        this.setDefaultCampaign();
        this.getData();
        eventBus.$on('resetFilters', this.reset);
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        reset() {
            this.setDefaultCategory();
            this.setDefaultCampaign();
            this.dataType = 'impressions';
            this.getData();
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.dataType === 'impressions' && this.isDefaultCategory() && this.isDefaultCampaign();
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        getData(force = false) {
            this.emitFilterDefaultChange();
            this.getChart(force);
        },
        handleCategoryBlur() {
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        parseChartData(data, categories) {
            if (!data || data.length === 0) {
                return null;
            }
            const parsedData = data.map(item => {
                const dataItem = [this.$d(new Date(item.date), 'short')];
                categories.forEach(key => {
                    dataItem.push({ v: item[key] || 0, f: numberService.formatNumber(item[key] || 0) });
                });
                return dataItem;
            });
            return [['Date', ...categories], ...parsedData];
        },
        parsePieChartData(data) {
            if (!data || data.length === 0) {
                return null;
            }
            const parsedData = data.map(row => row.map((item, itemIndex) => {
                if (itemIndex === 0) {
                    return item;
                }
                return { v: item, f: numberService.formatNumber(item) };
            }));
            return [['Name', 'Value'], ...parsedData];
        },
        async getChart(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;

                if (this.dataType === 'impressions') {
                    await this.fetchImpressionsChart({
                        force,
                        startDate,
                        endDate,
                        campaign: this.campaign,
                        category: [],
                    });
                    this.data = this.getterImpressionsChart(startDate, endDate, this.campaign, []);
                } else if (this.dataType === 'clicks') {
                    await this.fetchClicksChart({
                        force,
                        startDate,
                        endDate,
                        campaign: this.campaign,
                        category: [],
                    });
                    this.data = this.getterClicksChart(startDate, endDate, this.campaign, []);
                }

                this.data = this.filterByCategories(this.data);

                if (this.showOnlyTopCategories && this.canGroupOthers) this.data = this.groupOthers(this.data);
                if (!this.showOnlyTopCategories && this.showSummarized) this.data = this.sumCategories(this.data);

                this.lineData = this.parseChartData(this.data.chart, this.data.chartCategories);
                this.pieData = this.parsePieChartData(this.data.pieChart);
            } catch (e) {
                this.error = e.message;
                this.data = null;
                this.lineData = null;
                this.pieData = null;
            }
        },
        ...mapActions({
            fetchImpressionsChart: 'reportsCategory/fetchImpressionsChart',
            fetchClicksChart: 'reportsCategory/fetchClicksChart',
        }),
    },
};
</script>

<style lang="scss" scoped></style>
