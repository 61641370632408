<template>
    <v-toolbar
        class="lighten-4 top-nav-bar"
        color="grey"
        app
        clipped-left
        light
        flat>
        <v-toolbar-side-icon class="drawer-btn" v-if="hasDrawer" @click.stop="toggleDrawer" />
        <v-toolbar-title class="white--text">
            <router-link :to="homePath" class="logo">
                <img src="../../assets/logo.png" alt="100shoppers" class="logo" />
            </router-link>
        </v-toolbar-title>
        <v-spacer />
        <admin-panel-button />
        <slot>
            <default-top-navbar />
        </slot>
    </v-toolbar>
</template>

<script>
import DefaultTopNavbar from './DefaultTopNavbar.vue';
import AdminPanelButton from './AdminPanelButton.vue';

export default {
    name: 'top-nav-bar',
    components: {
        AdminPanelButton,
        DefaultTopNavbar,
    },
    props: {
        hasDrawer: Boolean,
        toggleDrawer: Function,
        homePath: {
            type: [String, Object],
            default: '/',
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    height: 20px;
    vertical-align: middle;
}

.drawer-btn {
    margin-top: 0;
    margin-bottom: 0;

    @media only screen and (min-width: 1100px) {
        display: none;
    }
}

@media only screen and (min-width: 1100px) {
    .top-nav-bar .v-toolbar__title {
        margin-left: 0 !important;
    }
}
</style>
