<template>
    <v-tooltip content-class="tooltip" left>
        <template v-slot:activator="{ on }">
            <div class="share-percent" v-on="on">
                <div v-for="(value, index) in values" :key="`${index}-${value}`" class="percent-indicator" :style="{ width: getValuePercentFormatted(index).replace(',','.'), background: getColor(index) }" />
            </div>
        </template>
        <div class="share-wrapper">
            <div v-for="(value, index) in values" :key="index" class="share-item">
                <div class="share-color" :style="{ background: getColor(index) }"></div>
                <div class="share-label"><span v-html="getLabel(index)"></span></div>
            </div>
        </div>
    </v-tooltip>
</template>

<script>
import numberService from '@/services/numberService';

export default {
    name: 'ShareMultiple',
    props: {
        values: {
            type: Array,
            required: true,
        },
        labels: {
            type: Array,
            default: () => [],
        },
        colors: {
            type: Array,
            default: () => [],
        },
        valueFormatter: {
            type: Function,
            default: null,
        },
        background: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            defaultColors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#3366cc', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411'],
        };
    },
    computed: {
        sum() {
            return this.values.reduce((sum, a) => sum + a, 0);
        },
    },
    methods: {
        getValue(index) {
            return this.values[index] ?? 0;
        },
        getValueFormatted(index) {
            if (typeof this.valueFormatter === 'function') return this.valueFormatter(this.getValue(index));
            return this.getValue(index);
        },
        getValuePercent(index) {
            return this.values[index] / (this.sum || 1) * 100;
        },
        getValuePercentFormatted(index) {
            return numberService.formatPercent(this.getValuePercent(index), 1);
        },
        getLabel(index) {
            return this.labels[index].replace('{{value}}', this.getValue(index)).replace('{{valuePercent}}', this.getValuePercent(index)).replace('{{valuePercentFormatted}}', this.getValuePercentFormatted(index)).replace('{{valueFormatted}}', this.getValueFormatted(index));
        },
        getColor(index) {
            return this.colors[index] || this.defaultColors[index];
        },
    },
};
</script>

<style lang="scss" scoped>
    .share-percent {
        display: inline-flex;
        flex-wrap: nowrap;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        width: 52px;
        height: 10px;
        background: #fff;
        box-shadow: inset 1px 1px #0001, inset -1px 1px #0001,inset 1px -1px #0001;
    }

    .percent-indicator {
        height: 100%;
    }

    .share-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .share-item {
        display: flex;
        align-items: baseline;
    }

    .share-color {
        flex-grow: 0;
        width: 10px;
        height: 10px;
        margin-right: 4px;
    }

    .share-label {
        flex-grow: 1;
    }
</style>
