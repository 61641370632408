import { SET_DATE_RANGE_PICKER_COMPARE_RANGE, SET_DATE_RANGE_PICKER_RANGE } from '../mutationTypes';
import dateService from '../../services/dateService';

const initialStartDate = window.localStorage.getItem('dateRangePickerStartDate') || null;
const initialEndDate = window.localStorage.getItem('dateRangePickerEndDate') || null;
const rangeFromStorage = window.localStorage.getItem('dateRangePickerRange');
const initialRange = (dateService.supportedRanges.includes(rangeFromStorage) || (!rangeFromStorage && initialStartDate && initialEndDate)) ? rangeFromStorage : '30days';

const compareRangeFromStorage = window.localStorage.getItem('dateRangePickerCompareRange');
const initialCompareRange = dateService.supportedCompareRanges.includes(compareRangeFromStorage) ? compareRangeFromStorage : 'prevRange';

const initialState = {
    range: initialRange,
    startDate: initialStartDate,
    endDate: initialEndDate,
    compareRange: initialCompareRange,
};

const getters = {
    getRange: state => state.range,
    getStartDate: state => state.startDate,
    getEndDate: state => state.endDate,
    getCompareRange: state => state.compareRange,
};

const actions = {
    setRange({ commit }, payload) {
        const { range, startDate, endDate } = payload;
        commit(SET_DATE_RANGE_PICKER_RANGE, {
            range,
            startDate,
            endDate,
        });

        // store date range picker range for use between page refresh
        window.localStorage.setItem('dateRangePickerRange', range);
        window.localStorage.setItem('dateRangePickerStartDate', startDate);
        window.localStorage.setItem('dateRangePickerEndDate', endDate);
    },

    setCompareRange({ commit }, payload) {
        const { compareRange } = payload;
        commit(SET_DATE_RANGE_PICKER_COMPARE_RANGE, {
            compareRange,
        });

        // store date range picker compare range for use between page refresh
        if (compareRange) {
            window.localStorage.setItem('dateRangePickerCompareRange', compareRange);
        } else {
            window.localStorage.removeItem('dateRangePickerCompareRange');
        }
    },
};

const mutations = {
    // -----------------------------------------
    // SET_DATE_RANGE_PICKER_RANGE
    // -----------------------------------------
    [SET_DATE_RANGE_PICKER_RANGE](state, payload) {
        const { range, startDate, endDate } = payload;
        state.range = range;
        state.startDate = startDate;
        state.endDate = endDate;
    },

    // -----------------------------------------
    // SET_DATE_RANGE_PICKER_COMPARE_RANGE
    // -----------------------------------------
    [SET_DATE_RANGE_PICKER_COMPARE_RANGE](state, payload) {
        const { compareRange } = payload;
        state.compareRange = compareRange;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
