<template>
    <div id="product-landing-page" class="grey lighten-4 pa-4">
        <v-card-text>
            <h3 class="title font-weight-bold mb-2">{{ $t('productLandingPage.title') }}</h3>
            <p class="caption description">{{ $t('productLandingPage.description') }}</p>
            <p class="caption">
                <a :href="$t('productLandingPage.helperUrl')" target="_blank">{{ $t('productLandingPage.helperText') }}</a>
            </p>

            <div v-if="!loading && productDetails && plugin.id" class="mt-5">
                <p class="caption mb-2">{{ $t('productLandingPage.previewText') }}</p>
                <widget-demo
                    v-if="isDemo"
                    :name="productName"
                    :image="productImage"
                />
                <widget-preview
                    v-else
                    :bb-id="plugin.id"
                    :bb-number="productDetails.ean"
                    :bb-name="productDetails.name"
                    class="widget-preview"
                />
            </div>

            <div v-if="loading" class="mt-3 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <div v-if="widgetLink" class="d-inline-flex align-center mt-4">
                <v-btn
                    class="btn-wide text-transform-off"
                    dark
                    round
                    @click="copy"
                >
                    <v-icon left small>fa-copy</v-icon> {{ $t('productLandingPage.copyLink') }}
                </v-btn>

                <div class="mx-1"></div>

                <v-hover>
                    <v-btn
                        class="btn-wide text-transform-off"
                        depressed
                        round
                        slot-scope="{ hover }"
                        :color="hover ? 'black' : 'white'"
                        :class="{'theme--dark': hover}"
                        style="border: 1px solid black !important"
                        :href="widgetLink"
                        target="_blank"
                    >
                        <v-icon left small>fa-external-link-alt</v-icon> {{ $t('productLandingPage.openLink') }}
                    </v-btn>
                </v-hover>
            </div>

        </v-card-text>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetPreview from '@/components/widgetGenerator/WidgetPreview.vue';
import WidgetDemo from '@/components/widgetGenerator/WidgetDemo.vue';

export default {
    name: 'product-landing-page',
    components: {
        WidgetPreview,
        WidgetDemo,
    },
    props: {
        productDetails: {
            type: Object,
            default: undefined,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isDemo() {
            return this.$store.getters['space/isDemo'];
        },
        productName() {
            return this.productDetails?.name || '';
        },
        productImage() {
            return this.productDetails?.images?.[0] || '';
        },
        widgetLink() {
            if (!this.plugin || !this.plugin.id || !this.productDetails || !this.productDetails.ean) return '';
            return encodeURI(`${process.env.VUE_APP_WIDGET_PREVIEW_URL}?id=${this.plugin.id}&number=${this.productDetails.ean}&name=${this.productDetails.name}`);
        },
        ...mapGetters({
            plugin: 'plugin/plugin',
        }),
    },
    methods: {
        async copy() {
            try {
                await this.$copyText(this.widgetLink);
                this.$toast.success(this.$t('productLandingPage.copySuccess'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .description {
        margin-bottom: 6px;
    }
    .widget-preview ::v-deep .buybox-widget {
        background: #fff;
        margin-left: 0;
    }
</style>
