import { render, staticRenderFns } from "./ShopsSummary.vue?vue&type=template&id=423f96be&scoped=true&"
import script from "./ShopsSummary.vue?vue&type=script&lang=js&"
export * from "./ShopsSummary.vue?vue&type=script&lang=js&"
import style0 from "./ShopsSummary.vue?vue&type=style&index=0&id=423f96be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423f96be",
  null
  
)

export default component.exports