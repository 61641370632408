<template>
    <div class="grey lighten-4 mb-4 pa-4">
        <v-card-title>
            <v-layout
                class="mb-4"
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="title font-weight-bold mb-3 d-flex align-center">
                    {{ title }}
                    <v-tooltip v-if="!loading && hasExport" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                type="button"
                                class="mb-2"
                                color="tertiary"
                                :disabled="!!error || !data || data.length === 0"
                                @click="exportData"
                                flat
                                icon
                            >
                                <v-icon small>fa-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('export') }}</span>
                    </v-tooltip>
                </h3>
                <v-layout
                    wrap
                    align-center
                    justify-end
                >
                    <slot name="filters" />
                    <v-btn
                        v-if="error"
                        class="mb-3"
                        @click="redo()"
                        flat
                        icon
                    >
                        <v-icon small>fa-redo-alt</v-icon>
                    </v-btn>
                </v-layout>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <table-graph v-if="!loading && chartData" :data="chartData" :options="chartOptions" />
            <data-placeholder v-if="!loading && !chartData" :title="noResults" />
            <div v-if="loading" class="mt-2 text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{ error }}
            </v-alert>
            <slot name="footer" />
        </v-card-text>
    </div>
</template>

<script>
import { uniq, orderBy, round } from 'lodash';
import TableGraph from './core/TableGraph.vue';
import DataPlaceholder from '../common/DataPlaceholder.vue';

export default {
    name: 'table-heatmap-graph',
    components: {
        TableGraph,
        DataPlaceholder,
    },
    props: {
        permissionName: {
            type: String,
        },
        data: {
            type: Array,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default() {
                return this.$t('tableHeatmapGraph.title');
            },
        },
        noResults: {
            type: String,
            default() {
                return this.$t('tableHeatmapGraph.noResults');
            },
        },
        hasExport: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        chartData() {
            if (!this.data || this.data.length === 0) {
                return null;
            }

            return this.data.map((row, rowIndex) => {
                if (rowIndex === 0) {
                    return row;
                }

                const rowHeatmap = this.getRowHeatmap(row.slice(1));

                return row.map((item, itemIndex) => {
                    if (itemIndex === 0) {
                        return item;
                    }

                    const itemValue = typeof item === 'object' ? item.v : item;
                    const itemLabel = typeof item === 'object' ? item.f : item;

                    return {
                        v: itemValue,
                        f: itemLabel,
                        p: {
                            style: itemIndex === row.length - 1 ? 'font-weight: 700;' : `background: rgba(0, 224, 143, ${rowHeatmap[itemValue]});`,
                        },
                    };
                });
            });
        },
        chartOptions() {
            return {};
        },
    },
    methods: {
        redo() {
            this.$emit('redo');
        },
        getRowHeatmap(values) {
            const parsedValues = values.map(item => {
                if (typeof item === 'object') {
                    return item.v;
                }
                return item;
            });
            const uniqueValues = orderBy(uniq(parsedValues), null, 'desc');
            const step = 1 / uniqueValues.length;
            const heatmap = {};
            uniqueValues.forEach((value, index) => {
                heatmap[value] = round(1 - (index * step), 2);
            });
            return heatmap;
        },
        exportData() {
            this.$emit('export');
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
