<template>
    <v-dialog v-model="opened" fullscreen transition="dialog-bottom-transition">
        <v-card>
            <v-card-actions>
                <v-btn
                    type="button"
                    color="tertiary"
                    @click.native="close"
                    flat
                >
                    <v-icon class="mr-2" small>fa-arrow-left</v-icon>
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
            <v-card-text>
                <timeline-graph
                    :title="$t('availabilityProductModal.title', { name: this.product.text })"
                    :data="items"
                    :loading="loading"
                    :error="error"
                    :no-results-title="$t('noResults')"
                    @redo="redo"
                >
                    <template #leftFilters>
                        <div class="left-filters">
                            <filter-summary :filters="filtersSummary" />
                            <Chip
                                :disabled="loading"
                                :label="$t('filters.reset')"
                                :active="!isFilterDefault"
                                @click="reset"
                            />
                        </div>
                    </template>
                    <template #filters>
                        <v-flex shrink>
                            <select-all
                                class-name="d-inline-block mr-3 mb-3 select--responsive"
                                v-model="campaign"
                                :items="campaignOptions"
                                :disabled="loading"
                                :label="$t('filters.shop')"
                                :all-label="$t('filters.allShops')"
                                require-selection
                                @blur="handleCampaignBlur"
                            />
                        </v-flex>
                    </template>
                    <template #footer>
                        <div v-if="hasMoreCampaigns" class="text-xs-center">
                            <v-btn
                                class="btn-wide mt-4"
                                type="submit"
                                color="tertiary"
                                dark
                                round
                                @click="showAll"
                            >
                                {{ $t('showAll') }}
                            </v-btn>
                        </div>
                    </template>
                </timeline-graph>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import campaignFilterMixin from '../../../../mixins/campaignFilterMixin';
import TimelineGraph from '../../../graphs/TimelineGraph.vue';
import SelectAll from '../../../common/SelectAll.vue';
import FilterSummary from '../../../common/FilterSummary.vue';
import Chip from '../../../common/Chip.vue';

export default {
    name: 'availability-product-modal',
    components: {
        Chip,
        FilterSummary,
        SelectAll,
        TimelineGraph,
    },
    mixins: [
        campaignFilterMixin,
    ],
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        entityId: {
            type: Number,
            required: true,
        },
        parentCampaign: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            opened: false,
            data: null,
            error: null,
            product: null,
            isFilterDefault: true,
        };
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
                || newValue.compareStartDate !== oldValue.compareStartDate
                || newValue.compareEndDate !== oldValue.compareEndDate
            ) {
                this.getData();
            }
        },
        entityId: {
            handler(newValue) {
                this.opened = !!newValue;
            },
            immediate: true,
        },
    },
    computed: {
        loading() {
            return this.productAvailabilityLoading || this.productDetailsLoading;
        },
        items() {
            if (!this.data || this.data.length === 0) {
                return null;
            }

            const header = [
                { type: 'string', label: 'Shop', id: 'Shop' },
                { type: 'string', id: 'Empty label' },
                { type: 'string', id: 'style', role: 'style' },
                {
                    type: 'string', id: 'tooltip', role: 'tooltip', p: { html: true },
                },
                { type: 'date', label: 'From', id: 'From' },
                { type: 'date', label: 'To', id: 'To' },
            ];
            const items = [];
            const emptyCampaigns = [];
            let emptyItems = [];
            let lowestStartDate = null;
            this.data.forEach(row => {
                if (row.segments && row.segments.length > 0) {
                    // add segments to items array
                    row.segments.forEach(segment => {
                        items.push([
                            row.label,
                            '',
                            null, // display original style
                            null, // display original tooltip
                            new Date(segment.start),
                            new Date(segment.end),
                        ]);
                        // get smallest start date from whole data set
                        lowestStartDate = lowestStartDate === null ? segment.start : Math.min(lowestStartDate, segment.start);
                    });
                } else {
                    // create list of campaigns without segments
                    emptyCampaigns.push(row.label);
                }
            });
            if (emptyCampaigns.length > 0) {
                // in case all campaigns had no segments, we need to set dummy range that looks good on chart
                // otherwise timeline chart will break
                let lowestEndDate = lowestStartDate;
                if (lowestStartDate === null) {
                    lowestEndDate = (new Date()).valueOf();
                    lowestStartDate = lowestEndDate - 604800000;
                }
                emptyItems = emptyCampaigns.map(campaign => [
                    campaign,
                    '',
                    'opacity: 0', // hide bar, it's displayed even if range is 0
                    '', // hide tooltip
                    lowestStartDate,
                    lowestEndDate,
                ]);
            }

            return [header, ...[...items, ...emptyItems]];
        },
        hasMoreCampaigns() {
            if (this.loading || this.error || !this.data || this.data.length === 0) {
                return false;
            }
            return !this.hasAllCampaigns();
        },
        filtersSummary() {
            const summary = [
                ...this.campaignFilterSummary,
            ];
            return summary;
        },
        ...mapState({
            productAvailabilityLoading: state => state.reportsAvailability.loading.productAvailability,
            productDetailsLoading: state => state.products.loading.productDetails,
        }),
        ...mapGetters({
            getterProductAvailability: 'reportsAvailability/getProductAvailability',
            getProductDetails: 'products/getProductDetails',
        }),
    },
    created() {
        this.product = null;
        // this.setDefaultCampaign();
        this.campaign = this.parentCampaign;
        this.loadProduct();
    },
    methods: {
        close() {
            this.opened = false;
            this.$emit('close');
        },
        async loadProduct() {
            try {
                const productId = `product-${this.entityId}`;
                this.error = null;
                await this.fetchProductDetails({ entityId: this.entityId, productId });
                const productDetails = this.getProductDetails(productId);
                const product = {
                    id: productId,
                    text: productDetails.name,
                    entityId: productDetails.id,
                    productId: productDetails.productId,
                    ean: productDetails.ean,
                    sku: productDetails.sku,
                    img: productDetails.images[0] || '',
                    categories: productDetails.categories,
                };
                this.product = product;
                this.getData(true);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        reset() {
            this.campaign = this.parentCampaign;
            this.getData();
        },
        getData(force = false) {
            this.isFilterDefault = this.isDefaultCampaign(undefined, this.parentCampaign);
            this.getProductAvailability(force);
        },
        showAll() {
            this.setAllCampaigns();
            this.getData();
        },
        handleCampaignBlur() {
            this.getData();
        },
        redo() {
            this.getData(true);
        },
        async getProductAvailability(force = false) {
            try {
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                if (!this.product) {
                    this.data = null;
                    return;
                }
                await this.fetchProductAvailability({
                    force,
                    productId: this.product.id,
                    entityId: this.product.entityId,
                    startDate,
                    endDate,
                    campaign: this.campaign,
                });
                this.data = this.getterProductAvailability(this.product.id, startDate, endDate, this.campaign);
            } catch (e) {
                this.error = e.message;
                this.data = null;
            }
        },
        ...mapActions({
            fetchProductAvailability: 'reportsAvailability/fetchProductAvailability',
            fetchProductDetails: 'products/fetchProductDetails',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .left-filters {
        display: inline-flex;
        align-items: center;
    }
</style>
