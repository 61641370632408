import demoService from '@/services/demoService';
import productsServiceDemo from '@/services/demo-services/productsServiceDemo';
import apiService from './apiService';
import errorService from './errorService';

const productsService = {
    async fetchProducts(spaceId, categoryId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-tree`,
                {
                    category: categoryId,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSearchProducts(spaceId, search) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products-tree-search`,
                {
                    search,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchFindProducts(spaceId, search, publisher) {
        function isValidEAN(ean) {
            const re13 = /^\d{13}$/;
            const re8 = /^\d{8}$/;
            return re13.test(ean) || re8.test(ean);
        }
        try {
            const url = publisher ? '/api/v1/described-products' : `/api/v1/spaces/${spaceId}/producer-products`;
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}${url}`,
                {
                    search: !isValidEAN(search) ? search : null,
                    publisher,
                    ean: isValidEAN(search) ? search : null,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductDetails(entityId) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductClicksStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/clicks-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategoryClicksStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/clicks-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductSalesStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/sales-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategorySalesStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/sales-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductImpressionsStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/impressions-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategoryImpressionsStats(entityId, startDate, endDate, compareStartDate, compareEndDate) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/impressions-stats`,
                {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductSalesChart(entityId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/sales-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategorySalesChart(entityId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/sales-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductTrafficChart(entityId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/traffic-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategoryTrafficChart(entityId, startDate, endDate, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/traffic-chart`,
                {
                    startDate,
                    endDate,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductPriceDistribution(entityId, startDate, endDate, pluginCampaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/price-chart`,
                {
                    startDate,
                    endDate,
                    pluginCampaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategoryPriceDistribution(entityId, startDate, endDate, pluginCampaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/price-chart`,
                {
                    startDate,
                    endDate,
                    pluginCampaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductAvailability(entityId, startDate, endDate, pluginCampaigns, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/availability-chart`,
                {
                    startDate,
                    endDate,
                    pluginCampaigns,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchGlobalProductAvailability(spaceId, entityId, startDate, endDate, pluginCampaigns, campaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/products/${entityId}/availability-chart`,
                {
                    startDate,
                    endDate,
                    pluginCampaigns,
                    campaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategoryAvailability(entityId, startDate, endDate, pluginCampaigns) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-product-categories/${entityId}/availability-chart`,
                {
                    startDate,
                    endDate,
                    pluginCampaigns,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProductOffers(entityId, pluginOffers) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}/offers`,
                {
                    sort: 'campaign_name',
                    pluginOffers,
                },
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteProduct(entityId) {
        try {
            const response = await apiService.delete(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}`,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateProduct(entityId, data) {
        try {
            const response = await apiService.patch(
                `${process.env.VUE_APP_API_URL}/api/v1/producer-products/${entityId}`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createProduct(spaceId, data) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-products`,
                data,
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    flattenProductsTree(products) {
        return products.reduce((acc, prod) => {
            if (prod.type === 'product') {
                return { ...acc, [prod.id]: prod };
            }
            if (prod.type === 'category' && prod.items && prod.items.length > 0) {
                return { ...acc, ...this.flattenProductsTree(prod.items) };
            }
            return acc;
        }, {});
    },
};

demoService.demoProxy(productsService, productsServiceDemo, 'productsService');
export default productsService;
