import { render, staticRenderFns } from "./AvailabilityPerShop.vue?vue&type=template&id=c821bda8&scoped=true&"
import script from "./AvailabilityPerShop.vue?vue&type=script&lang=js&"
export * from "./AvailabilityPerShop.vue?vue&type=script&lang=js&"
import style0 from "./AvailabilityPerShop.vue?vue&type=style&index=0&id=c821bda8&lang=scss&scoped=true&"
import style1 from "./AvailabilityPerShop.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c821bda8",
  null
  
)

export default component.exports