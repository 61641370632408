<template>
    <v-dialog
        v-model="isOpen"
        max-width="500"
        persistent
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ $t('confirmDelete', { name }) }}
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn flat @click="handleCancel">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn
                    color="error"
                    round
                    @click="handleConfirm"
                >
                    {{ $t('delete') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'confirm-delete-modal',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
        nameKey: {
            type: String,
        },
    },
    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        name() {
            return this.item?.[this.nameKey];
        },
    },
    methods: {
        handleConfirm() {
            this.$emit('confirm', this.item);
            this.isOpen = false;
        },
        handleCancel() {
            this.$emit('cancel');
            this.isOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
