import { mapState, mapActions } from 'vuex';
import spaceService from '@/services/spaceService';

export default {
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            sources: [],
            sourceError: null,
        };
    },
    computed: {
        sourcesOptions() {
            return this.uniqueSources || [];
        },
        ...mapState({
            spaceId: state => state.space.currentSpaceId,
            uniqueSourcesLoading: state => state.space.loading.fetchUniqueSources,
            uniqueSources: state => state.space.uniqueSources,
        }),
    },
    watch: {
        spaceId: {
            immediate: true,
            async handler() {
                await this.loadSources();
            },
        },
    },
    methods: {
        loadSources() {
            if (!spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)) return;
            const { startDate, endDate } = this.dateFilter;
            this.fetchUniqueSources({ force: false, startDate, endDate }).catch(error => {
                this.sourceError = error;
            });
        },
        setDefaultSources() {
            this.sources = [...this.sourcesOptions];
        },
        ...mapActions({
            fetchUniqueSources: 'space/fetchUniqueSources',
        }),
    },
};
