<template>
    <v-layout
        class="mb-2 stat-box__container"
        justify-space-between
        row
        wrap
    >
        <stat-box
            :title="$t('availabilityStats.avgAvailability')"
            :tooltip="$t('availabilityStats.avgAvailabilityTooltip')"
            :value="stats.avgAvailability"
            valueType="percent"
            :loading="loading"
            size="small"
        />
        <stat-box
            :title="$t('availabilityStats.avgAvailabilityQuantitatively')"
            :tooltip="$t('availabilityStats.avgAvailabilityQuantitativelyTooltip')"
            :value="stats.avgAvailabilityQuantitatively"
            :loading="loading"
            size="small"
        />
        <stat-box
            :title="$t('availabilityStats.products')"
            :tooltip="$t('availabilityStats.productsTooltip')"
            :value="stats.products"
            :loading="loading"
            size="small"
        />
    </v-layout>
</template>

<script>
import { sum } from 'lodash';
import StatBox from '@/components/common/StatBox.vue';
import { mapGetters, mapState } from 'vuex';
import ganttService from '@/services/ganttService';

export default {
    name: 'availability-stats',
    components: {
        StatBox,
    },
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        campaign: {
            type: Array,
            required: true,
        },
    },
    computed: {
        loading() {
            return this.availabilityChartsLoading;
        },
        stats() {
            const stats = {
                avgAvailability: 0,
                avgAvailabilityQuantitatively: 0,
                products: 0,
            };
            if (this.loading) {
                return stats;
            }
            const { startDate, endDate } = this.dateFilter;
            const startDateTime = new Date(startDate).valueOf();
            const endDateTime = new Date(endDate).valueOf();
            const products = this.getterAvailabilityCharts(startDate, endDate, this.campaign) ?? {};
            stats.products = Object.keys(products).length;
            const avgArr = [];
            Object.keys(products).forEach(productId => {
                const shops = products[productId].charts ?? {};
                // not all shops are included in API response, so we need to use campaigns from filter
                const shopsIds = this.campaign.map(String) ?? [];

                // get single product's total segments seconds in all offers in all shops
                const productSecondsPerShop = shopsIds.map(shopId => {
                    // handle shops missing from API response as ones with 0 availability
                    if (!shops[shopId]) {
                        return 0;
                    }
                    // put segments from all offers into a single array
                    const segments = shops[shopId].reduce((segmentsArr, offer) => {
                        segmentsArr.push(...offer.chart.segments);
                        return segmentsArr;
                    }, []);
                    // merge overlapping segments and limit to start and end time
                    const mergedSegments = ganttService.limitSegments(ganttService.mergeSegments(segments), startDateTime, endDateTime);
                    // calculate seconds sum from all segments
                    const segmentsSeconds = mergedSegments.reduce((seconds, segment) => seconds + (segment.end - segment.start), 0);
                    return segmentsSeconds;
                });
                // calc product seconds avg
                const productSecondsAvg = productSecondsPerShop.length ? sum(productSecondsPerShop) / productSecondsPerShop.length : 0;
                // calculate product avg availability
                const productAvg = (productSecondsAvg / (endDateTime - startDateTime)) * 100;
                avgArr.push(productAvg);
            });
            // calculate total avg availability
            stats.avgAvailability = sum(avgArr) / avgArr.length;
            stats.avgAvailabilityQuantitatively = Math.round(stats.products * stats.avgAvailability / 100);
            return stats;
        },
        ...mapState({
            availabilityChartsLoading: state => state.reportsAvailability.loading.availabilityCharts,
        }),
        ...mapGetters({
            getterAvailabilityCharts: 'reportsAvailability/getAvailabilityCharts',
        }),
    },
};

</script>
