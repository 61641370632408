var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{class:_vm.className,staticStyle:{"min-width":"230px"},attrs:{"value":_vm.selected,"items":_vm.finalItems,"label":_vm.label,"disabled":_vm.disabled,"loading":_vm.loading,"multiple":"","placeholder":" ","outline":"","hide-details":"","dense":"","menu-props":{
        contentClass: _vm.useTreeview ? 'treeview-select-menu' : '',
    }},on:{"input":_vm.handleInput,"change":_vm.handleChange,"blur":_vm.handleBlur},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-tile',{attrs:{"ripple":"","disabled":_vm.disabled},on:{"click":_vm.toggle}},[_c('v-list-tile-action',[_c('v-icon',{attrs:{"color":_vm.selected.length > 0 ? 'primary darken-4' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(_vm.allLabel))])],1)],1),_c('v-divider',{staticClass:"mt-2"}),(_vm.useTreeview)?_c('v-treeview',{class:{ 'no-pointer-events': _vm.disabled },attrs:{"value":_vm.selected,"items":_vm.itemsTreeFinal,"item-key":"id","item-text":"text","item-children":"children","multiple-active":"","selectable":"","transition":"","hoverable":"","selected-color":"primary"},on:{"input":_vm.handleInput,"change":_vm.handleChange},scopedSlots:_vm._u([{key:"label",fn:function(ref){
    var item = ref.item;
    var selected = ref.selected;
return [_c('span',{class:{ 'primary--text': selected, 'text--disabled': item.disabled }},[_vm._v(" "+_vm._s((typeof item === 'object') ? item.text : item)+" ")]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text'}),expression:"{ class: 'primary--text'}"}],staticClass:"treeview-item-click-layer",class:{ 'text--disabled': item.disabled },on:{"click":_vm.handleTreeviewItemClick}})]}}],null,false,1960691344)}):_vm._e()]},proxy:true},(_vm.useTreeview)?{key:"item",fn:function(ref){return [_c('span')]}}:{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-tile-action',[_c('v-checkbox',{attrs:{"color":"primary","input-value":_vm.selected.includes((typeof item === 'object') ? item.value : item),"disabled":_vm.disabled || item.disabled}})],1),_c('v-list-tile-title',[(item.html)?_c('span',{domProps:{"innerHTML":_vm._s(item.html)}}):_c('span',[_vm._v(_vm._s((typeof item === 'object') ? item.text : item))])])]}},{key:"selection",fn:function(ref){
    var index = ref.index;
return [(_vm.selectedAll && index === 0)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.allLabel))])]):_vm._e(),(_vm.selectedSome && index === 0)?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedSomeItemLabel)}})]):_vm._e(),(_vm.selectedSome && index === 1)?_c('span',{staticClass:"grey--text caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.selected.length - 1)+" "+_vm._s(_vm.$t('selectAll.others'))+") ")]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }