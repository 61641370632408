<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('pluginCampaignsList.title') }}</h3>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-layout v-if="!loading && (activeCampaigns.length > 0 || availableCampaigns.length > 0)" row wrap>
                <v-flex xs12 md5>
                    <v-layout column fill-height>
                        <h4 class="subheading">{{ $t('pluginCampaignsList.activeCampaigns') }}</h4>
                        <p class="caption">{{ $t('pluginCampaignsList.activeCampaignsDescription') }}</p>
                        <draggable
                            class="campaigns-list"
                            v-model="activeCampaigns"
                            :sort="false"
                            group="campaigns"
                            draggable=".campaigns-list__item"
                            @change="handleChangeActive"
                        >
                            <v-layout
                                class="campaigns-list__item"
                                :class="{'campaigns-list__item--disabled': isSubmitting(campaign.id)}"
                                v-for="campaign in activeCampaigns"
                                :key="campaign.id"
                                row
                                align-center
                            >
                                <v-flex class="campaigns-list__item-label">
                                    <v-layout justify-space-between align-center>
                                        {{ campaign.name }}
                                        <v-progress-circular
                                            class="ml-2 campaigns-list__item-loader"
                                            v-if="isSubmitting(campaign.id)"
                                            indeterminate
                                            color="primary"
                                            size="18"
                                            width="2"
                                        />
                                    </v-layout>
                                </v-flex>
                                <v-flex class="campaigns-list__priority" :class="{'ml-1': $vuetify.breakpoint.xs, 'ml-3': $vuetify.breakpoint.smAndUp}">
                                    <v-text-field
                                        v-model="campaign.priority"
                                        type="number"
                                        min="0"
                                        outline
                                        hide-details
                                        @blur="handlePriorityChange(campaign)"
                                        @keyup.enter="handlePriorityChange(campaign)"
                                    />
                                </v-flex>
                            </v-layout>
                            <data-placeholder slot="header" v-if="activeCampaigns.length === 0" :title="$t('pluginCampaignsList.noActiveCampaigns')" />
                        </draggable>
                    </v-layout>
                </v-flex>
                <v-flex class="mt-5 pa-4" align-self-center>
                    <v-layout column>
                        <v-icon size="32" color="primary">fa-long-arrow-alt-left</v-icon>
                        <v-icon size="32" color="grey">fa-long-arrow-alt-right</v-icon>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md5>
                    <v-layout column fill-height>
                        <h4 class="subheading">{{ $t('pluginCampaignsList.availableCampaigns') }}</h4>
                        <p class="caption">{{ $t('pluginCampaignsList.availableCampaignsDescription') }}</p>
                        <draggable
                            class="campaigns-list"
                            v-model="availableCampaigns"
                            :sort="false"
                            group="campaigns"
                            draggable=".campaigns-list__item"
                            @change="handleChangeAvailable"
                        >
                            <v-layout
                                class="campaigns-list__item"
                                :class="{'campaigns-list__item--disabled': isSubmitting(campaign.id)}"
                                v-for="campaign in availableCampaigns"
                                :key="campaign.id"
                                row
                                align-center
                            >
                                <v-flex class="campaigns-list__item-label" grow>
                                    <v-layout justify-space-between align-center>
                                        {{ campaign.name }}
                                        <v-progress-circular
                                            class="ml-2 campaigns-list__item-loader"
                                            v-if="isSubmitting(campaign.id)"
                                            indeterminate
                                            color="primary"
                                            size="18"
                                            width="2"
                                        />
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <data-placeholder slot="header" v-if="availableCampaigns.length === 0" :title="$t('pluginCampaignsList.noAvailableCampaigns')" />
                        </draggable>
                    </v-layout>
                </v-flex>
            </v-layout>

            <data-placeholder v-if="!loading && activeCampaigns.length === 0 && availableCampaigns.length === 0" :title="$t('pluginCampaignsList.noCampaigns')" />

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>
        </v-card-text>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { cloneDeep, deburr, orderBy } from 'lodash';
import Draggable from 'vuedraggable';
import DataPlaceholder from '../../common/DataPlaceholder.vue';
import numberService from '../../../services/numberService';

export default {
    name: 'plugin-campaigns-list',
    components: {
        DataPlaceholder,
        Draggable,
    },
    data() {
        return {
            activeCampaigns: [],
            availableCampaigns: [],
            error: null,
        };
    },
    computed: {
        loading() {
            return this.fetchActiveCampaignsLoading || this.fetchAvailableCampaignsLoading;
        },
        ...mapGetters({
            getterPlugin: 'plugin/plugin',
            getterActiveCampaigns: 'plugin/activeCampaigns',
            getterAvailableCampaigns: 'plugin/availableCampaigns',
        }),
        ...mapState({
            fetchActiveCampaignsLoading: state => state.plugin.loading.fetchActiveCampaigns,
            fetchAvailableCampaignsLoading: state => state.plugin.loading.fetchAvailableCampaigns,
            createSubmitting: state => state.plugin.loading.createCampaign,
            deleteSubmitting: state => state.plugin.loading.deleteCampaign,
            updateSubmitting: state => state.plugin.loading.updateCampaign,
        }),
    },
    methods: {
        async getData() {
            this.getActiveCampaigns();
            this.getAvailableCampaigns();
        },
        isSubmitting(campaignId) {
            return this.createSubmitting.includes(campaignId) || this.deleteSubmitting.includes(campaignId) || this.updateSubmitting.includes(campaignId);
        },
        handleChangeActive(e) {
            if (e.added) {
                const campaign = this.activeCampaigns.find(item => item.id === e.added.element.id);
                if (campaign) {
                    campaign.priority = 0;
                }
                this.sortActiveCampaigns();
                this.createCampaign(e.added.element);
            }
        },
        handleChangeAvailable(e) {
            if (e.added) {
                this.sortAvailableCampaigns();
                this.deleteCampaign(e.added.element);
            }
        },
        handleActiveClick(campaign, save = true) {
            this.availableCampaigns.push(campaign);
            this.sortAvailableCampaigns();
            this.activeCampaigns = this.activeCampaigns.filter(item => item.id !== campaign.id);
            if (save) {
                this.deleteCampaign(campaign);
            }
        },
        handleAvailableClick(campaign, priority = 0, save = true) {
            this.activeCampaigns.push({ ...campaign, priority });
            this.sortActiveCampaigns();
            this.availableCampaigns = this.availableCampaigns.filter(item => item.id !== campaign.id);
            if (save) {
                this.createCampaign(campaign);
            }
        },
        handlePriorityChange(campaign) {
            this.sortActiveCampaigns();
            this.updateCampaign(campaign);
        },
        sortActiveCampaigns() {
            this.activeCampaigns = orderBy(this.activeCampaigns, ['priority', item => deburr(item.name.toLowerCase())], ['desc', 'asc']);
        },
        sortAvailableCampaigns() {
            this.availableCampaigns = orderBy(this.availableCampaigns, item => deburr(item.name.toLowerCase()), 'asc');
        },
        filterAvailableCampaigns(item) {
            // remove active campaigns from list of available
            return !this.activeCampaigns.some(activeItem => activeItem.id === item.id);
        },
        async getActiveCampaigns() {
            try {
                this.error = null;
                await this.fetchActiveCampaigns();
                if (this.getterActiveCampaigns) {
                    this.activeCampaigns = cloneDeep(this.getterActiveCampaigns);
                    this.sortActiveCampaigns();
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async getAvailableCampaigns() {
            try {
                this.error = null;
                await this.fetchAvailableCampaigns();
                if (this.getterAvailableCampaigns) {
                    this.availableCampaigns = cloneDeep(this.getterAvailableCampaigns)
                        .filter(this.filterAvailableCampaigns);
                    this.sortAvailableCampaigns();
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        async createCampaign(campaign) {
            try {
                this.error = null;
                await this.createCampaignAction({
                    campaign: {
                        campaign: {
                            id: campaign.id,
                            name: campaign.name,
                        },
                        priority: numberService.zeroOrInteger(campaign),
                    },
                });
            } catch (e) {
                this.error = e.message;
                this.handleActiveClick(campaign, false);
            }
        },
        async updateCampaign(campaign) {
            const originalCampaign = this.getterActiveCampaigns.find(item => item.id === campaign.id);
            try {
                if (!originalCampaign || originalCampaign.priority === parseInt(campaign.priority, 10)) {
                    return;
                }
                this.error = null;
                await this.updateCampaignAction({
                    campaign: {
                        campaign: {
                            id: campaign.id,
                            name: campaign.name,
                        },
                        priority: numberService.zeroOrInteger(campaign.priority),
                    },
                });
            } catch (e) {
                this.error = e.message;
                const index = this.activeCampaigns.findIndex(item => item.id === campaign.id);
                if (originalCampaign && index !== -1) {
                    this.activeCampaigns[index].priority = originalCampaign.priority;
                    this.sortActiveCampaigns();
                }
            }
        },
        async deleteCampaign(campaign) {
            try {
                this.error = null;
                await this.deleteCampaignAction({
                    campaign: {
                        campaign: {
                            id: campaign.id,
                            name: campaign.name,
                        },
                        priority: numberService.zeroOrInteger(campaign),
                    },
                });
            } catch (e) {
                this.error = e.message;
                this.handleAvailableClick(campaign, campaign.priority, false);
            }
        },
        ...mapActions({
            fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
            fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
            createCampaignAction: 'plugin/createCampaign',
            updateCampaignAction: 'plugin/updateCampaign',
            deleteCampaignAction: 'plugin/deleteCampaign',
        }),
    },
};
</script>

<style lang="scss" scoped>
    .campaigns-list {
        height: 100%;
        min-height: 100px;
        max-height: 600px;
        padding: 10px 10px 0 10px;
        border: 1px solid #e8e8e8;
        overflow: auto;
    }

    .campaigns-list__item {
        margin-bottom: 10px;
        padding: 7px 10px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
    }

    .campaigns-list__item--disabled {
        pointer-events: none;
        opacity: .75;
    }

    .campaigns-list__item-label {
        cursor: pointer;
        word-break: break-word;
    }

    .campaigns-list__priority {
        min-width: 70px;
        max-width: 70px;
    }

    .campaigns-list__item-loader {
        min-width: 18px;
    }
</style>

<style lang="scss">
    .campaigns-list__priority {
        .v-input__slot {
            min-height: 43px !important;
        }

        input {
            margin-top: 5px;
        }
    }
</style>
