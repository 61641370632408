import { mapGetters, mapActions, mapState } from 'vuex';
import { deburr, difference, orderBy } from 'lodash';
import utilsService from '@/services/utilsService';

export default {
    data() {
        return {
            category: [],
            indexFromWitchOthersBegins: 10,
            showOnlyTopCategories: true,
            selectedCategoriesCache: {},
            sumCategoriesCache: {},
        };
    },
    computed: {
        categoryOptions() {
            return this.getterCategoryOptions;
        },
        categoryOptionsTree() {
            return this.getterCategoryOptionsTree;
        },
        categoryFilterSummary() {
            let items = this.category
                .map(categoryId => {
                    const categoryItem = this.categoryOptions.find(category => category.value === categoryId);
                    if (categoryItem) {
                        return categoryItem.text;
                    }
                    return null;
                })
                .filter(item => item);
            items = orderBy(items, [item => deburr(item).toLowerCase()], ['asc']);
            return {
                name: this.$t('filterSummary.types.categories'),
                items,
            };
        },
        ...mapGetters({
            getterCategoryOptions: 'space/getProducerProductCategoriesOptions',
            getterCategoryOptionsTree: 'space/getProducerProductCategoriesOptionsTree',
        }),
        categoriesSummaryValue() {
            return this.pieChartData.reduce((acc, cur) => acc + cur[1] || 0, 0);
        },
        pieChartData() {
            const reportsData = this.$store.state.reportsCategory[`${this.dataType}Chart`];
            if (!reportsData) return [];
            // if (this.category.length) return []; // reset grouping in the category filter after category selected
            // const chartKeys = Object.keys(reportsData);
            // const chartFirstKey = chartKeys[0];
            const storageKey = utilsService.getStorageKey(this.dateFilter.startDate, this.dateFilter.endDate, ...this.campaign, ...[]);
            // if (chartFirstKey !== storageKey) return []; // we have data for all categories only at the very beginning, when by default all categories are selected in the filter, after changing the selected categories in the filter, we do not have data for other unselected categories, so we cannot return the data
            const chartData = reportsData[storageKey] || {};
            return chartData.pieChart || [];
        },
        getterCategoryOptionsSorted() {
            const data = [...this.getterCategoryOptions].map(item => {
                const catItem = this.pieChartData.find(catData => catData[0] === `${item.value} ${item.text}`);
                const realValue = catItem && catItem[0] ? catItem[1] : 0;
                const realValuePercent = (realValue * 100) / this.categoriesSummaryValue || 0;
                return { ...item, realValue, realValuePercent };
            });
            return orderBy(data, 'realValue', 'desc');
        },
        categoryOptionsWithTopCategories() {
            return [
                { header: `${this.$t('filters.top')} ${this.indexFromWitchOthersBegins}` },
                ...this.getterCategoryOptionsSortedTop,
                { header: this.$t('filters.other') },
                ...this.getterCategoryOptionsSortedOthers,
            ];
        },
        getterCategoryOptionsSortedTop() {
            return this.getterCategoryOptionsSorted.slice(0, this.indexFromWitchOthersBegins);
        },
        getterCategoryOptionsSortedOthers() {
            return this.getterCategoryOptionsSorted.slice(this.indexFromWitchOthersBegins);
        },
        topCategoriesExists() {
            return this.indexFromWitchOthersBegins > 0;
        },
        canGroupOthers() {
            return this.getterCategoryOptions?.length > this.indexFromWitchOthersBegins;
        },
        ...mapState({
            producerProductCategoriesLoading: state => state.space.loading.fetchProducerProductCategories,
        }),
    },
    watch: {
        categoryOptionsTree: {
            immediate: true,
            handler() {
                this.categoriesFlatWithParents = this.flattenStructure(this.categoryOptionsTree);
                this.categoriesFlatWithParentsAsObject = this.categoriesFlatWithParents.reduce((acc, cur) => ({ [cur.chartKey]: cur, ...acc }), {});
            },
        },
    },
    methods: {
        /**
         * Get default category items
         */
        getDefaultCategory() {
            return this.getterCategoryOptions.map(category => category.value);
        },
        /**
         * Set default category items
         */
        setDefaultCategory() {
            this.category = this.getDefaultCategory();
        },
        /**
         * Returns if currently selected categories are matching default list
         * @return {boolean}
         */
        isDefaultCategory() {
            const defaultCategories = this.getDefaultCategory();
            const diff1 = difference(defaultCategories, this.category).length;
            const diff2 = difference(this.category, defaultCategories).length;
            const isDefault = diff1 === 0 && diff2 === 0;
            return isDefault;
        },
        filterByCategories(data) {
            if (!data) return data;

            return {
                chart: data.chart?.map(item => Object.fromEntries(
                    Object.keys(item)
                        .filter(key => this.category.includes(parseInt(key, 10)) || key === 'date')
                        .map(key => [key, item[key]]),
                )),
                chartCategories: data.chartCategories?.filter(item => this.category.includes(parseInt(item, 10))),
                pieChart: data.pieChart?.filter(item => this.category.includes(parseInt(item[0], 10))),
            };
        },
        sumCategories(data, level = 1) {
            if (!data) return data;

            const chart = (data.chart || []).map(item => Object.keys(item).reduce(
                (acc, key) => {
                    const current = { ...acc };
                    const currentCategory = this.categoriesFlatWithParentsAsObject[key];
                    const parentOrCurrent = currentCategory?.[`parentUp${level}`] || currentCategory;
                    if (parentOrCurrent) {
                        const currentValue = (current[parentOrCurrent.chartKey] || 0) + item[key];
                        current[parentOrCurrent.chartKey] = currentValue;
                    }
                    return current;
                },
                { date: item.date },
            ));

            const chartCategories = Object.keys(chart[0] || {}).filter(key => key !== 'date');

            const pieChart = chartCategories.map(chartKey => {
                const currentValue = chart.reduce((acc, item) => acc + item[chartKey], 0);
                return [chartKey, currentValue];
            });

            return {
                chart,
                chartCategories,
                pieChart,
            };
        },

        flattenStructure(node, level = 0, parents = []) {
            let result = [];

            node.forEach(currentNode => {
                const newNode = { ...currentNode, chartKey: `${currentNode.id} ${currentNode.name}` };

                parents.forEach((parent, idx) => {
                    newNode[`parentUp${level - idx}`] = parent;
                });

                result.push(newNode);

                if (newNode.children) {
                    const newParents = [...parents, newNode];
                    const children = this.flattenStructure(newNode.children, level + 1, newParents);
                    result = [...result, ...children];
                }
            });

            return result;
        },
        groupOthers(data) {
            if (!data) return data;

            const categoriesKeys = item => `${item.value} ${item.text}`;
            const topCategories = this.getterCategoryOptionsSortedTop.filter(item => this.category.includes(item.value)).map(categoriesKeys);
            const otherCategories = this.getterCategoryOptionsSortedOthers.filter(item => this.category.includes(item.value)).map(categoriesKeys);
            const otherText = this.$t('filters.other');

            const dataChart = !data.chart || !data.chart.length
                ? data.chart
                : data.chart.map(item => Object.keys(item).reduce((acc, cur) => {
                    const otherValue = acc[otherText] || 0;
                    const other = otherCategories.includes(cur) ? { [otherText]: otherValue + item[cur] } : { [otherText]: otherValue };
                    const top = topCategories.includes(cur) ? { [cur]: item[cur] } : {};
                    return {
                        ...acc,
                        ...top,
                        ...other,
                        date: item.date,
                    };
                }, {}));

            const dataChartCategories = !data.chartCategories || !data.chartCategories.length ? data.chartCategories : [...topCategories, otherText];

            const dataPieChart = !data.pieChart || !data.pieChart.length
                ? data.pieChart
                : [
                    ...data.pieChart.reduce((acc, cur) => {
                        if (topCategories.includes(cur[0])) acc.push(cur);
                        return acc;
                    }, []),
                    [otherText, data.pieChart.filter(item => otherCategories.includes(item[0])).reduce((acc, cur) => acc + cur[1], 0)],
                ];

            return {
                chart: dataChart,
                chartCategories: dataChartCategories,
                pieChart: dataPieChart,
            };
        },
        async getCategories() {
            try {
                await this.fetchProducerProductCategories();
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchProducerProductCategories: 'space/fetchProducerProductCategories',
        }),
    },
};
