<template>
    <div class="grey lighten-4 pa-4">
        <v-card-title>
            <h3 class="title font-weight-bold">{{ $t('pluginExtension.title') }}</h3>
        </v-card-title>
        <v-card-text>
            <div v-if="fetchAvailableExtensionsLoading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <v-layout
                v-if="!fetchAvailableExtensionsLoading"
                row
                wrap>
                <v-flex xs12>
                    <v-layout column fill-height>
                        <v-select
                            class="d-inline-block mr-3 mb-3 select--responsive"
                            v-model="selectedExtension"
                            :items="extensionsList"
                            placeholder=""
                            :label="$t('pluginExtension.selectPlaceholder')"
                            outline
                            hide-details
                            dense
                        />
                    </v-layout>
                </v-flex>
                <v-flex xs12 v-if="selectedExtensionComponent">
                    <component :is="selectedExtensionComponent" ref="extensionInstance" :settings="extensionSettings" />
                </v-flex>
                <v-flex xs12>
                    <v-alert
                        :value="error"
                        dismissible
                        type="error"
                        transition="scale-transition">
                        {{error}}
                    </v-alert>

                    <div class="mt-4 text-xs-center">
                        <v-btn
                            class="btn-wide"
                            type="submit"
                            color="tertiary"
                            dark
                            round
                            @click="save"
                        >
                            {{ $t('pluginExtension.submit') }}
                        </v-btn>
                    </div>
                </v-flex>
            </v-layout>
        </v-card-text>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { camelCase, upperFirst } from 'lodash';
import RotatingGroupsExtension from './extension/RotatingGroupsExtension.vue';

export default {
    name: 'plugin-extension',
    components: { RotatingGroupsExtension },
    data() {
        return {
            error: null,
            selectedExtension: null,
        };
    },
    computed: {
        extensionsList() {
            return [
                {
                    value: null,
                    text: this.$t('pluginExtension.noExtension'),
                },
                ...this.availableExtensions,
            ];
        },
        selectedExtensionComponent() {
            if (this.selectedExtension === null) return null;
            return `${upperFirst(camelCase(this.selectedExtension))}Extension`;
        },
        extensionType() {
            return this.getterPlugin && this.getterPlugin.extensionType ? this.getterPlugin.extensionType : null;
        },
        extensionSettings() {
            return this.getterPlugin && this.getterPlugin.extension ? this.getterPlugin.extension : null;
        },
        ...mapGetters({
            getterPlugin: 'plugin/plugin',
        }),
        ...mapState({
            fetchAvailableExtensionsLoading: state => state.plugin.loading.fetchAvailableExtensions,
            availableExtensions: state => state.plugin.availableExtensions,
            updateSubmitting: state => state.plugin.loading.updateExtension,
        }),
    },
    methods: {
        async loadList() {
            await this.getAvailableExtensions();
            this.selectedExtension = this.extensionType;
        },
        async getAvailableExtensions() {
            try {
                this.error = null;
                await this.fetchAvailableExtensions();
            } catch (e) {
                this.error = e.message;
            }
        },
        async save() {
            try {
                this.error = null;
                await this.updateExtensionSettings({
                    extension: this.selectedExtension,
                    settings: this.selectedExtension
                        ? this.$refs.extensionInstance.getExtensionSettings()
                        : null,
                });
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchAvailableExtensions: 'plugin/fetchAvailableExtensions',
            updateExtensionSettings: 'plugin/updateExtensionSettings',
        }),
    },
};
</script>
