<template>
    <line-graph
        class="mt-3"
        :title="$t('averagePriceOverTime.title')"
        type="small"
        :data="items"
        :options="options"
        :loading="isCommonLoading"
        :error="error"
        :no-results-title="noResultsTitle"
        :tooltip="tooltip"
    >
        <template #leftFilters>
            <div class="left-filters">
                <filter-summary :filters="filtersSummary" />
                <Chip
                    :disabled="isCommonLoading"
                    :label="$t('filters.reset')"
                    :active="!isCommonFilterDefault"
                    @click="resetLocalFilters"
                />
            </div>
        </template>
        <template #filters>
            <v-flex shrink>
                <v-select
                    class="d-inline-block mr-3 mb-3 select--responsive"
                    v-model="period"
                    @change="handlePeriodChange"
                    :items="periodOptions"
                    :disabled="isCommonFilterDefault"
                    placeholder=" "
                    :label="$t('filters.period')"
                    outline
                    hide-details
                    dense
                />
            </v-flex>
        </template>
    </line-graph>
</template>

<script>
import { mapGetters } from 'vuex';
import { max, sum } from 'lodash';
import FilterSummary from '../../../common/FilterSummary.vue';
import Chip from '../../../common/Chip.vue';
import eventBus from '../../../../services/eventBus';
import numberService from '../../../../services/numberService';
import LineGraph from '../../../graphs/LineGraph.vue';
import dateService from '../../../../services/dateService';

export default {
    name: 'average-price-over-time',
    components: {
        LineGraph,
        Chip,
        FilterSummary,
    },
    props: {
        data: {
            type: Object,
        },
        dateFilter: {
            type: Object,
            required: true,
        },
        isCommonLoading: {
            type: Boolean,
            default: false,
        },
        isCommonFilterDefault: {
            type: Boolean,
            default: true,
        },
        commonFiltersSummary: {
            type: Array,
            default: () => [],
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            period: 'daily',
            error: null,
        };
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (
                newValue.startDate !== oldValue.startDate
                || newValue.endDate !== oldValue.endDate
            ) {
                this.setLowestPeriod();
            }
        },
    },
    computed: {
        items() {
            if (!this.data) {
                return null;
            }

            const campaignsWithData = new Set();
            const dates = new Set();

            // get all avg prices from each campaign in all products and group them in arrays by campaign and date
            const campaignArrByDate = {};
            Object.keys(this.data).forEach(productId => {
                Object.keys(this.data[productId].report).forEach(campaignName => {
                    const { chart } = this.data[productId].report[campaignName];
                    if (chart.length) {
                        campaignsWithData.add(campaignName);
                        chart.forEach(chartItem => {
                            dates.add(chartItem.date);
                            if (!campaignArrByDate[campaignName]) {
                                campaignArrByDate[campaignName] = {};
                            }
                            if (!campaignArrByDate[campaignName][chartItem.date]) {
                                campaignArrByDate[campaignName][chartItem.date] = [];
                            }
                            if (chartItem.avg) {
                                campaignArrByDate[campaignName][chartItem.date].push(chartItem.avg);
                            }
                        });
                    }
                });
            });

            // convert campaign avg on each date from array to single numeric value
            Object.keys(campaignArrByDate).forEach(campaignName => {
                Object.keys(campaignArrByDate[campaignName]).forEach(date => {
                    const campaignDayPrices = campaignArrByDate[campaignName][date];
                    if (campaignDayPrices.length) {
                        campaignArrByDate[campaignName][date] = sum(campaignDayPrices) / campaignDayPrices.length;
                    } else {
                        campaignArrByDate[campaignName][date] = null;
                    }
                });
            });

            // convert data structure from by campaign to by date
            const campaignNames = Array.from(campaignsWithData);
            const avgByDate = Array.from(dates).map(date => {
                const item = { date };
                campaignNames.forEach(campaignName => {
                    item[campaignName] = campaignArrByDate[campaignName][date];
                });
                return item;
            });

            let hasAnyData = false;
            const header = ['Date'];

            campaignNames.forEach(campaign => {
                header.push({ type: 'number', label: campaign });
                header.push({ type: 'boolean', role: 'certainty' });
            });

            const formatName = dateService.getFormatFromPeriod(this.period);
            const periodItems = new Set();
            const items = avgByDate
                .filter(item => {
                    // display all items with daily period
                    if (this.period === 'daily') {
                        return true;
                    }
                    // display only a single item from given period
                    const date = dateService.formatI18nDate(item.date, formatName);
                    if (periodItems.has(date)) {
                        return false;
                    }
                    periodItems.add(date);
                    return true;
                })
                .map(item => {
                    const date = dateService.formatI18nDate(item.date, formatName);
                    const row = [date];
                    campaignNames.forEach(campaign => {
                        if (item[campaign] || item[campaign] === 0) {
                            hasAnyData = true;
                        }
                        row.push({ v: item[campaign], f: numberService.formatCurrency(item[campaign]) });
                        row.push(true);
                    });
                    return row;
                });

            if (!hasAnyData) {
                return null;
            }
            return [header, ...items];
        },
        options() {
            const options = {
                selectionMode: 'multiple',
                tooltip: {
                    isHtml: true,
                },
                vAxes: {
                    0: {
                        title: `${this.$t('averagePriceOverTime.price')} [${process.env.VUE_APP_SYSTEM_CURRENCY}]`,
                        format: '#',
                        viewWindow: {
                            min: null,
                        },
                    },
                },
            };

            if (this.items && this.items.length > 0) {
                // ignore first row with columns definition
                const filteredData = this.items.slice(1).map(row => row.slice(1));
                // flatten array and get max value
                const maxValue = max([].concat(...filteredData));
                if (maxValue > 0) {
                    options.vAxes[0].format = 'short';
                }
            }

            return options;
        },
        noResultsTitle() {
            const transTag = !this.products || this.products.length === 0 ? 'averagePriceOverTime.noProductSelected' : 'noResults';
            return this.$t(transTag);
        },
        filtersSummary() {
            const period = this.periodOptions.find(option => option.value === this.period);
            return [
                ...this.commonFiltersSummary,
                {
                    name: this.$t('filters.period'),
                    items: [
                        period && period.text,
                    ],
                },
            ];
        },
        periodOptions() {
            const { startDate, endDate } = this.dateFilter;
            const groupBy = dateService.getRangeType(startDate, endDate);
            return [
                {
                    value: 'daily',
                    text: this.$t('filters.periodDaily'),
                    disabled: ['week', 'month', 'quarter'].includes(groupBy),
                },
                {
                    value: 'monthly',
                    text: this.$t('filters.periodMonthly'),
                    disabled: ['quarter'].includes(groupBy),
                },
                {
                    value: 'quarterly',
                    text: this.$t('filters.periodQuarterly'),
                },
                {
                    value: 'annually',
                    text: this.$t('filters.periodAnnually'),
                },
            ];
        },
        ...mapGetters({
            getterAveragePriceOverTime: 'reportsPriceDistribution/getAveragePriceOverTime',
        }),
    },
    created() {
        eventBus.$on('resetFilters', this.reset);
        this.setLowestPeriod();
    },
    beforeDestroy() {
        eventBus.$off('resetFilters', this.reset);
    },
    methods: {
        setLowestPeriod(force = false) {
            const rangeTypeToLowestPeriodMap = {
                day: 'daily',
                month: 'monthly',
                quarter: 'quarterly',
            };
            const { startDate, endDate } = this.dateFilter;
            const groupBy = dateService.getRangeType(startDate, endDate);

            if (
                force
                || !(
                    groupBy === 'day'
                    || (groupBy === 'month' && ['monthly', 'quarterly', 'annually'].includes(this.period))
                    || (groupBy === 'quarter' && ['quarterly', 'annually'].includes(this.period))
                )
            ) {
                this.period = rangeTypeToLowestPeriodMap[groupBy];
            }
        },
        resetLocalFilters() {
            this.$emit('resetLocalFilters');
        },
        reset() {
            this.setLowestPeriod(true);
        },
        emitFilterDefaultChange() {
            const isFilterDefault = this.period === 'daily';
            this.$emit('filterDefaultChange', isFilterDefault);
        },
        handlePeriodChange() {
            this.emitFilterDefaultChange();
        },
    },
};
</script>

<style lang="scss" scoped>
    .left-filters {
        display: inline-flex;
        align-items: center;
    }
</style>
