/* eslint-disable no-unused-vars */
import {
    filterArrayByCampaignName,
    filterArrayByProductId,
    filterDates,
    filterKeysByCampaignName,
    generateDates,
    getCampaignNames,
} from '@/services/demo-services/_utils';
import { cloneDeep } from 'lodash';
import demoService from '../demoService';

export default {
    async fetchProductPrices(spaceId, startDate, endDate, campaigns, categories, groups, products) {
        const { default: spaceProductsPriceReport } = await import('../../mocks/spaceProductsPriceReport.json');
        return filterArrayByProductId(spaceProductsPriceReport, products, 'id');
    },

    async fetchAveragePrice(spaceId, startDate, endDate, campaigns, categories, groups, products) {
        const { default: spaceProductsPricePerCampaignReport } = await import('../../mocks/spaceProductsPricePerCampaignReport.json');
        return filterArrayByCampaignName(spaceProductsPricePerCampaignReport, campaigns, 'name');
    },

    async fetchProductsPriceAggregatedReport(spaceId, startDate, endDate, groupBy, campaigns, categories, groups, products) {
        /* eslint-disable no-param-reassign */
        const { default: spaceProductsPriceAggregatedReport } = await import('../../mocks/spaceProductsPriceAggregatedReport.json');
        const data = cloneDeep(spaceProductsPriceAggregatedReport);
        const campaignNames = getCampaignNames(campaigns);
        Object.keys(data).forEach(productId => {
            const product = data[productId];
            const report = {};
            Object.keys(product.report).forEach(campaignName => {
                if (campaignNames.includes(campaignName)) {
                    const campaignReport = product.report[campaignName];
                    campaignReport.chart = filterDates(generateDates(campaignReport.chart));
                    report[campaignName] = campaignReport;
                }
            });
            product.report = report;
        });
        return data;
    },

    async fetchAveragePriceOverTime(spaceId, startDate, endDate, campaigns, categories, groups, products, groupBy) {
        const { default: spacePriceChart } = await import('../../mocks/spacePriceChart.json');
        return filterKeysByCampaignName(filterDates(generateDates(spacePriceChart, 'YYYY-MM-DD'), 'YYYY-MM-DD'), campaigns);
    },

    async fetchProducerTransactionsItemsQuantityPrice(spaceId, startDate, endDate, campaigns, categories, groups, products) {
        const { default: spaceQuantityChart } = await import('../../mocks/producerTransactionsItemsQuantityPrice.json');
        return filterDates(generateDates(spaceQuantityChart));
    },

    async fetchProductsPriceChart(spaceId, startDate, endDate, campaigns, categories, groups, products) {
        const { default: productsPriceChart } = await import('../../mocks/productsPriceChart.json');
        const data = cloneDeep(productsPriceChart);
        Object.keys(data.products).forEach(productId => {
            const product = data.products[productId];
            product.price = filterDates(generateDates(product.price));
        });
        return data;
    },
    generatePriceReport: demoService.USE_ORIGINAL_PROPERTY,
    fetchPriceReports: demoService.USE_ORIGINAL_PROPERTY,
    downloadPriceReport: demoService.USE_ORIGINAL_PROPERTY,
};
