<template>
    <div class="mt-4">
        <v-card-title>
            <v-layout
                row
                wrap
                align-center
                justify-space-between
            >
                <h3 class="subheading font-weight-bold">
                    {{ $t('conversionPath.title', { id: transaction.id }) }}
                </h3>
                <v-btn
                    color="darken-1"
                    flat
                    icon
                    @click="close"
                >
                    <v-icon small>fa-times</v-icon>
                </v-btn>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>
            <div v-if="!loading" class="wrapper" style="position:relative;" ref="wrapper">

                <div class="timeline-wrapper">

                    <div class="timeline-item">
                        <div class="timeline-item-header">
                            <div class="timeline-item-header-title">
                                <div class="d-flex align-center">
                                    <img class="timeline-item-header-title-img" src="../../../../assets/source.png" :alt="$t('conversionPath.source')" />
                                    {{ $t('conversionPath.source') }}
                                </div>
                            </div>
                        </div>
                        <div class="timeline-item-bar">
                            <div class="timeline-item-bar-line">
                                <div class="timeline-item-bar-line-top"></div>
                                <div class="timeline-item-bar-line-point"></div>
                                <div class="timeline-item-bar-line-bottom"></div>
                            </div>
                        </div>
                        <div class="timeline-item-description">
                            <ActivateReportContactForm :permission-name="spaceService.permissions.CAMPAIGN_ANALYSIS" :text-first-line="$t('subscriptionNotIncludeSourceAnalytics')" :text-second-line="$t('leaveUsYourDetailsAndWeWillContactYou')" usercom-report-type="Sprzedaż" />
                            <div class="timeline-item-description-line"></div>
                            <div class="timeline-item-description-inner" :class="{'blur-item no-permissions': !spaceService.hasPermission(spaceService.permissions.CAMPAIGN_ANALYSIS)}">
                                <div class="timeline-item-description-header">
                                    <img src="../../../../assets/source2.png" :alt="$t('conversionPath.transactionSource')" />
                                    <span class="timeline-item-description-header-label">{{ $t('conversionPath.transactionSource') }}</span>
                                </div>
                                <span>utm_source: {{ transaction.source || $t('unknownSource').toLowerCase() }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="timeline-item">
                        <div class="timeline-item-header">
                            <div class="timeline-item-header-title">
                                <div class="d-flex align-center">
                                    <img class="timeline-item-header-title-img" src="../../../../assets/producer-page.png" :alt="$t('conversionPath.producerPage')" />
                                    {{ $t('conversionPath.producerPage') }}
                                </div>
                                <div class="timeline-item-header-title-info">{{ $t('conversionPath.clickTime') }}: {{ $d(new Date(transaction.createdAt - transaction.conversionTime), 'longNumeric') }}</div>
                            </div>
                        </div>
                        <div class="timeline-item-bar">
                            <div class="timeline-item-bar-line">
                                <div class="timeline-item-bar-line-top"></div>
                                <div class="timeline-item-bar-line-point"></div>
                                <div class="timeline-item-bar-line-bottom"></div>
                            </div>
                        </div>
                        <div class="timeline-item-description">
                            <div class="timeline-item-description-line" :class="{'short': (transaction.clickedModel && products.some(product => (transaction.clickedEan === product.ean)))}"></div>
                            <div class="timeline-item-description-inner">
                                <div class="timeline-item-description-header">
                                    <img src="../../../../assets/icon-clicked-offer.svg" :alt="$t('conversionPath.clickedOffer')" />
                                    <span class="timeline-item-description-header-label">{{ $t('conversionPath.clickedOffer') }}</span>
                                </div>
                                <span v-if="transaction.clickedModel" ref="conversionOfferProductModel" class="offer-model">{{ transaction.clickedModel }}</span>
                                <span v-if="transaction.clickedEan" ref="conversionOfferProductEan" :class="{'offer-ean': true, 'offer-ean--bold': !transaction.clickedModel}">{{ $t('conversionPath.ean') }}: {{ transaction.clickedEan }}</span>
                                <span v-if="!transaction.clickedModel && !transaction.clickedEan">{{ $t('conversionPath.unidentifiedProduct') }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="timeline-item">
                        <div class="timeline-item-time">
                            <img src="../../../../assets/icon-conversion-time.svg" alt="" />
                            <span class="timeline-item-time-value">{{ conversionTimeFormatted }}</span>
                        </div>
                        <div class="timeline-item-header">
                            <div class="timeline-item-header-title">
                                <div class="d-flex align-center">
                                    <img class="timeline-item-header-title-img" src="../../../../assets/shop-page.png" :alt="$t('conversionPath.showPage')" />
                                    {{ $t('conversionPath.showPage') }}
                                </div>
                                <div class="timeline-item-header-title-info">{{ $t('conversionPath.buyTime') }}: {{ $d(new Date(transaction.createdAt), 'longNumeric') }}</div>
                            </div>
                        </div>
                        <div class="timeline-item-bar">
                            <div class="timeline-item-bar-line">
                                <div class="timeline-item-bar-line-top"></div>
                                <div class="timeline-item-bar-line-point"></div>
                                <div class="timeline-item-bar-line-bottom"></div>
                            </div>
                        </div>
                        <div class="timeline-item-description">
                            <div class="timeline-item-description-line" :class="{'d-none': (transaction.clickedModel && products.some(product => (transaction.clickedEan === product.ean)))}"></div>
                            <div class="timeline-item-description-inner">
                                <div class="timeline-item-description-header">
                                    <img src="../../../../assets/icon-transactions.svg" :alt="$t('conversionPath.transaction')" />
                                    <span class="timeline-item-description-header-label">{{ $t('conversionPath.transaction') }}</span>
                                </div>
                                <table v-if="products" class="transaction-table">
                                    <thead>
                                        <tr>
                                            <td>{{ $t('conversionPath.product') }}</td>
                                            <td>{{ $t('conversionPath.ean') }}</td>
                                            <td>{{ $t('conversionPath.brand') }}</td>
                                            <td>{{ $t('conversionPath.quantity') }}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, index) in products" :key="index" :ref="`conversionTransactionProduct${index}`">
                                            <td :class="{'transaction-marked-product': transaction.clickedModel && transaction.clickedEan === product.ean}" v-bind="{ref: (transaction.clickedModel && transaction.clickedEan === product.ean) ? 'transactionMarkedProduct' : ''}">
                                                <div class="inner-wrapper">
                                                    <span v-if="product.product || product.ean">{{ product.product }}</span>
                                                    <span v-if="!product.product && !product.ean">
                                                        {{ $t('conversionPath.unknownProduct') }}
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon v-on="on" class="ml-1" small>fa-question-circle</v-icon>
                                                            </template>
                                                            {{ $t('conversionPath.unknownProductDescription') }}
                                                        </v-tooltip>
                                                    </span>
                                                </div>
                                            </td>
                                            <td :class="{'transaction-marked-product': !transaction.clickedModel && transaction.clickedEan === product.ean}">{{ product.ean }}</td>
                                            <td>{{ product.brand }}</td>
                                            <td>{{ product.quantity }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </v-card-text>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dateService from '@/services/dateService';
import spaceService from '@/services/spaceService';
import LeaderLine from '@/common/leader-line';
import ActivateReportContactForm from '@/components/common/ActivateReportContactForm.vue';

export default {
    name: 'conversion-path',
    components: {
        ActivateReportContactForm,
    },
    props: {
        dateFilter: {
            type: Object,
            required: true,
        },
        conversion: {
            type: [String, Number],
            required: true,
        },
        campaign: {
            type: Array,
            default: () => [],
        },
        transaction: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            error: null,
            detailsConnectorHeight: null,
            detailsConnectorStartY: null,
            detailsConnectorEndY: null,
            detailsConnectorColor: '#bdbdbd',
            products: null,
            spaceService,
            LeaderLine,
            leaderLineInstance: null,
        };
    },
    computed: {
        loading() {
            return this.productDetailsLoading;
        },
        conversionTimeFormatted() {
            return dateService.secondsToString(this.transaction.conversionTime / 1000);
        },
        ...mapState({
            productDetailsLoading: state => state.reportsSales.loading.productDetails,
        }),
        ...mapGetters({
            getterProductDetails: 'reportsSales/getProductDetails',
        }),
    },
    watch: {
        async transaction(newTransaction, oldTransaction) {
            if (newTransaction.id !== oldTransaction.id) {
                await this.getProducts();
            }
        },
        products() {
            setTimeout(() => this.positionDetailsConnector(), 300);
        },
    },
    async mounted() {
        await this.getProducts();
        this.$refs.wrapper?.closest('.v-dialog')?.addEventListener('transitionstart', this.removeDetailsConnector);
        this.$refs.wrapper?.closest('.v-dialog')?.addEventListener('transitionend', this.positionDetailsConnector);
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async getProducts() {
            try {
                this.detailsConnectorStartY = null;
                this.detailsConnectorEndY = null;
                const { startDate, endDate } = this.dateFilter;
                this.error = null;
                await this.fetchProductDetails({
                    startDate,
                    endDate,
                    conversion: this.conversion,
                    campaign: this.campaign,
                });
                const data = this.getterProductDetails(startDate, endDate, this.conversion, this.campaign);
                this.products = data ? data.filter(item => item.id === this.transaction.id) : null;
            } catch (e) {
                this.error = e.message;
            }
        },
        positionDetailsConnector(e) {
            if (e?.target && e.target !== this.$refs.wrapper?.closest('.v-dialog')) return;
            this.removeDetailsConnector();
            const { conversionOfferProductModel, transactionMarkedProduct, wrapper } = this.$refs;
            if (conversionOfferProductModel && transactionMarkedProduct) {
                this.leaderLineInstance = new this.LeaderLine({
                    start: conversionOfferProductModel,
                    end: transactionMarkedProduct.firstChild,
                    parent: wrapper,
                    dash: { len: 6, gap: 5 },
                    color: this.$vuetify.theme.primary,
                    size: 2,
                    path: 'grid',
                });
            }
        },
        removeDetailsConnector(e) {
            if (e?.target && e.target !== this.$refs.wrapper?.closest('.v-dialog')) return;
            this.leaderLineInstance?.remove();
            this.leaderLineInstance = null;
        },
        ...mapActions({
            fetchProductDetails: 'reportsSales/fetchProductDetails',
        }),
    },
    beforeDestroy() {
        this.$refs.wrapper?.closest('.v-dialog')?.removeEventListener('transitionstart', this.removeDetailsConnector);
        this.$refs.wrapper?.closest('.v-dialog')?.removeEventListener('transitionend', this.positionDetailsConnector);
        this.removeDetailsConnector();
    },
};
</script>

<style lang="scss" scoped>
$lineColor: #bdbdbd;
$textColor: #757575;
$timelineHederHeight: 130px;
$timelineBarHeight: 14px;
$timelineBarTopMargin: 30px;
$timelineBarBottomMargin: 120px;

.blur-item {
    filter: blur(5px);
}

::v-deep {
    .source-no-permissions {
        position: absolute;
        z-index: 10;
        left: 6px;
        top: -#{$timelineBarBottomMargin};
        transform: translateY(-40%);
        text-align: center;
        padding: 15px 20px;
    }
}

.timeline-wrapper {
    overflow: hidden;
    display: flex;
}
.timeline-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    position: relative;

    &-time {
        display: flex;
        flex-direction: column;
        padding: 7px 20px 3px;
        border: 1px solid $lineColor;
        position: absolute;
        left: 0;
        top: $timelineHederHeight;
        z-index: 1;
        background: #fff;
        text-align: center;
        font-weight: 600;
        color: $textColor;

        img {
            flex: 0 !important;
            width: 50px;
            margin: auto;
        }

        &-value {
            display: block;
            margin-top: 7px;
            white-space: nowrap;
            font-size: 16px;
        }
    }

    &-header {
        height: $timelineHederHeight;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &-title {
            background: #fff;

            &-img {
                flex: 0 !important;
                height: 100px;
            }

            &-info {
                font-size: 20px;
                font-weight: 600;
                color: $textColor;
            }
        }
    }

    &-bar {
        width: 100%;
        height: $timelineBarHeight;
        position: relative;
        background: $lineColor;
        margin-top: $timelineBarTopMargin;
        margin-bottom: $timelineBarBottomMargin;
        position: relative;
        z-index: 0;
        border-radius: 100px;

        &-line {
            width: 250px;
            position: absolute;
            top: 7.5px;
            transform: translateY(-50%) translateX(-50%);
            left: 50%;

            &-top {
                width: 0;
                height: 200px;
                border-right: 2px solid $lineColor;
                margin: auto;
            }

            &-point {
                width: 30px;
                height: 30px;
                background: #2d3136;
                border: 4px solid #ffffff;
                border-radius: 50%;
                margin: auto;
            }

            &-bottom {
                width: 0;
                height: 200px;
                border-right: 2px dashed $lineColor;
                margin: auto;
            }
        }
    }

    &-description {
        width: 100%;
        min-height: 100px;
        background: #fff;
        color: $textColor;
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;

        &-line {
            width: 100%;
            height: 0;
            border-bottom: 2px dashed $lineColor;
            position: absolute;
            left: 0;
            top: 50px;
            z-index: 0;

            &.short {
                width: 50%;
            }
        }

        &-inner {
            width: fit-content;
            min-width: 250px;
            border: 1px solid $lineColor;
            padding: 10px;
            background: #fff;
            z-index: 1;
            position: relative;

            .offer-model {
                margin-left: -11px;
                margin-right: -11px;
                padding-left: 11px;
                padding-right: 11px;
            }

            .transaction-marked-product .inner-wrapper {
                margin-left: -11px;
                padding-left: 11px;
            }

            &.no-permissions {
                top: 40px;
            }
        }

        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            font-weight: 600;

            img {
                height: 44px;
            }

            &-label {
                margin-left: 10px;
            }
        }
    }

    &:first-child {
        z-index: 10;
    }

    &:last-child {
        .timeline-item-bar {
            &-line {
                &-point {
                    background: $primary-color;
                }
            }
        }
    }
}

@media only screen and (min-width: 1101px) {
    .timeline-item {
        width: 30%;

        &-bar {
            border-radius: 0;
        }

        &:first-child {
            align-items: flex-start;

            .timeline-item-bar {
                border-radius: 0;
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;

                &-line {
                    left: 0;
                    right: auto;
                    transform: translateY(-50%) translateX(0);
                }
            }

            .timeline-item-description {
                justify-content: flex-start;
            }
        }

        &:last-child {
            align-items: flex-end;
            .timeline-item-bar {
                border-radius: 0;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;

                &-line {
                    left: auto;
                    right: 0;
                    transform: translateY(-50%) translateX(0);
                }
            }

            .timeline-item-description {
                justify-content: flex-end;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    ::v-deep {
        .source-no-permissions {
            left: auto;
            top: -50%;
        }
    }

    .timeline-wrapper {
        flex-direction: column;
    }

    .timeline-item {
        width: 100%;

        &-time {
            position: relative;
            top: 0;
            transform: translateY(-50%);
        }

        &-description {
            transform: translateY(-90px);

            &-line {
                display: none;
            }
        }
    }
}

.offer-model {
    display: block;
    font-weight: 600;
    color: $text-dark-color;
}
.offer-ean {
    display: block;
}
.offer-ean--bold {
    font-weight: 600;
    color: $text-dark-color;
}
.transaction-table {
    thead td {
        min-width: 60px;
        padding-bottom: 10px;
    }
    tbody td {
        padding-bottom: 5px;

        &:last-child {
            text-align: center;
        }

        &:not(:last-child) {
            padding-right: 30px;
        }
    }
}
.transaction-marked-product {
    font-weight: 600;
    color: $text-dark-color;
}
</style>
