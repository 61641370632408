import { cloneDeep } from 'lodash';

export default {
    mergeSegments(data) {
        if (!data?.length) {
            return [];
        }
        const segments = cloneDeep(data);
        // sort segments by start time ascending
        segments.sort((a, b) => a.start - b.start);
        const merged = [segments[0]];

        for (let i = 1; i < segments.length; i += 1) {
            const { start, end } = segments[i];
            const prev = merged[merged.length - 1];
            if (prev.end >= start) {
                // if the current segment overlap with the previous one, update the previous segment accordingly
                prev.end = Math.max(prev.end, end);
            } else {
                // if the current start time > the previous end time, put the segment in the result
                merged.push(segments[i]);
            }
        }
        return merged;
    },
    limitSegments(segments, limitStart, limitEnd) {
        return segments.reduce((limitedSegments, segment) => {
            // check if segment is in limited range
            if (segment.start <= limitEnd && segment.end >= limitStart) {
                // limit segment start and end
                const start = limitStart > segment.start ? limitStart : segment.start;
                const end = segment.end && segment.end < limitEnd ? segment.end : limitEnd;
                limitedSegments.push({ start, end });
            }
            return limitedSegments;
        }, []);
    },
};
