<template>
    <div>
        <page-header-filters>
            <template #leftFilters>
                <space-switch @change="handleSpaceChange" />
            </template>
        </page-header-filters>
        <v-card class="elevation-0 pa-5 mb-4">
            <div v-if="loading" class="text-xs-center">
                <v-progress-circular indeterminate color="primary" />
            </div>

            <data-placeholder v-if="!loading && !error && !getterPlugin" :title="$t('producerPlugin.noPlugin')" />

            <v-alert
                :value="error"
                dismissible
                type="error"
                transition="scale-transition">
                {{error}}
            </v-alert>

            <plugin-code v-if="!loading && !error && getterPlugin" class="mb-5" />
            <plugin-campaigns-list v-if="!loading && !error && getterPlugin" ref="campaignsList" class="mb-5" />
            <plugin-extension v-if="!loading && !error && getterPlugin" ref="extensions" class="mb-5" />
            <plugin-appearance v-if="!loading && !error && getterPlugin" />
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PageHeaderFilters from '../../components/common/PageHeaderFilters.vue';
import SpaceSwitch from '../../components/common/SpaceSwitch.vue';
import DataPlaceholder from '../../components/common/DataPlaceholder.vue';
import PluginCampaignsList from '../../components/producer/plugin/PluginCampaignsList.vue';
import PluginAppearance from '../../components/producer/plugin/PluginAppearance.vue';
import PluginCode from '../../components/producer/plugin/PluginCode.vue';
import PluginExtension from '../../components/producer/plugin/PluginExtension.vue';

export default {
    name: 'producer-plugin',
    components: {
        DataPlaceholder,
        PluginCode,
        PluginAppearance,
        PluginCampaignsList,
        PluginExtension,
        PageHeaderFilters,
        SpaceSwitch,
    },
    data() {
        return {
            spaceInitialized: false,
            error: null,
        };
    },
    computed: {
        loading() {
            return !this.spaceInitialized || this.fetchPluginsLoading;
        },
        ...mapState({
            fetchPluginsLoading: state => state.plugin.loading.fetchPlugins,
        }),
        ...mapGetters({
            getterPlugin: 'plugin/plugin',
        }),
    },
    methods: {
        async handleSpaceChange() {
            if (!this.spaceInitialized) {
                this.spaceInitialized = true;
            }
            await this.getPlugins();
        },
        async getPlugins() {
            try {
                this.error = null;
                await this.fetchPlugins();
                if (this.getterPlugin) {
                    this.$refs.campaignsList.getData();
                    await this.$refs.extensions.loadList();
                }
            } catch (e) {
                this.error = e.message;
            }
        },
        ...mapActions({
            fetchPlugins: 'plugin/fetchPlugins',
        }),
    },
};
</script>

<style lang="scss" scoped>
</style>
